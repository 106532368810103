import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import * as moment from 'moment'
import { ModalEstoqueNutricionalComponent } from 'src/app/pages/estoque/modal-estoque-nutricional/modal-estoque-nutricional.component'
import { EstoqueNutricional } from 'src/app/utils/interfaces/estoque.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'
import { opcoesFiltros } from '../vasta-tabela/opcoes-filtros'

@Component({
  selector: 'app-manejo-area-nutricional',
  templateUrl: './manejo-area-nutricional.component.html',
  styleUrls: ['./manejo-area-nutricional.component.scss']
})
export class ManejoAreaNutricionalComponent implements OnInit {
  @Input('dados') dados: {
    [key: string]: { quantidade: number; duracao: number; edited: boolean; data_final: string }
  } = {}
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  @Input('dataManejo') dataManejo: string = ''

  @Input('estoqueNutricional') estoqueNutricional: EstoqueNutricional[] = []
  @Output() estoqueNutricionalChange = new EventEmitter<EstoqueNutricional[]>()

  labelsTipo = opcoesFiltros.labelsEstoqueNutricionalTipo

  constructor(private popoverCtrl: PopoverController, private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  emitChange(dado: { quantidade: number; duracao: number; edited: boolean; data_final: string }): void {
    dado.edited = true
    this.emit()
  }

  emit(): void {
    this.dadosChange.emit(this.dados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      Object.keys(this.dados).forEach((key) => {
        this.dados[key].duracao = null
        this.dados[key].quantidade = null
        this.dados[key].edited = true
      })
      this.emit()
    }
  }

  async showPopoverOpcaoItemManejoArea(
    event: Event,
    dado: { quantidade: number; duracao: number; edited: boolean }
  ): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      componentProps: { manejoTipo: 'manejo-nutricional' },
      event,
      reference: 'event',
      cssClass: 'popover-opcao-item-manejo-area'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      dado.quantidade = null
      dado.duracao = null
      dado.edited = true
      this.emit()
    }
  }

  async adicionarItemNutricional(event: Event): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEstoqueNutricionalComponent,
      componentProps: {
        data_custo: this.dataManejo,
        hash_not_in: this.estoqueNutricional.map((a) => a.hash)
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      console.log({ data })

      if (data.itensSelecionados?.length) {
        this.estoqueNutricional = [...this.estoqueNutricional, ...data.itensSelecionados]
      } else {
        this.estoqueNutricional.push(data)
      }
      this.estoqueNutricionalChange.emit(this.estoqueNutricional)
    }
  }

  calcularDataFinal(dado: { quantidade: number; duracao: number; edited: boolean; data_final: string }): string {
    dado.data_final = moment(this.dataManejo)
      .add(dado.duracao || 0, 'days')
      .format('YYYY-MM-DD')
    return dado.data_final
  }
}
