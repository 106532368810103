import { Component, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'cta-buttons',
  template: `
    <div class="container-buttons">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./cta-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CtaButtonsComponent {
  constructor() {}
}
