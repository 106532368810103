import { Component, Input, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { PermissoesUsuarioService } from 'src/app/services/permissoes-usuario.service'
import { PlanosAssinaturaService } from 'src/app/services/plano-assinatura.service'
import { PermissaoCadastrarNovosAnimais } from 'src/app/utils/interfaces/permissoes.interface'

@Component({
  selector: 'app-modal-limite-animais-alcancado.page.',
  templateUrl: './modal-limite-animais-alcancado.page.html',
  styleUrls: ['./modal-limite-animais-alcancado.page.scss'],
})
export class ModalLimiteAnimaisAlcancado implements OnInit {
  @Input() qtdAnimaisTentandoCadastrar: number
  public vagasDisponiveis: number

  constructor(
    private planosAssinaturaCtrl: PlanosAssinaturaService,
    private modalCtrl: ModalController,
    private permissaoUsuarioCtrl: PermissoesUsuarioService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit(): void {
    this.vagasDisponiveis = this.permissaoUsuarioCtrl.vagasDeAnimaisDisponiveis
  }

  entrarEmContato(): void {
    this.planosAssinaturaCtrl.entrarEmContatoPorWhatsapp()
  }

  async fecharModal(): Promise<void> {
    const value: PermissaoCadastrarNovosAnimais = {
      isPossuiPermissaoCadastro: false
    }

    this.modalCtrl.dismiss(value)
  }

  async alertarSobreSair(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Sair',
      message: 'Tem certeza que deseja sair?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Sim',
          handler: (): void => {
            this.fecharModal()
          }
        }
      ]
    })

    await alert.present()
  }
}
