import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { ChecklistInicialComponent } from './checklist-inicial/checklist-inicial.component'
import { TimestampPipe } from '../pipes/timestamp.pipe'
import { NumpadPesosComponent } from './numpad-pesos/numpad-pesos.component'
import { ModalFiltrosTabelaComponent } from './modal-filtros-tabela/modal-filtros-tabela.component'
import { HeaderTabsComponent } from './header-tabs/header-tabs.component'
import { CtaButtonsComponent } from './cta-buttons/cta-buttons.component'
import { KpisCardsComponent } from './kpis-cards/kpis-cards.component'
import { OpcoesBotoesComponent } from './opcoes-botoes/opcoes-botoes.component'
import { PropriedadeNomeComponent } from './propriedade-nome/propriedade-nome.component'
import { VastaTabela } from './vasta-tabela/vasta-tabela'
import { PaginateComponent } from './paginate/paginate.component'
import { VastaMapaComponent } from './vasta-mapa/vasta-mapa.component'
import { GraficoComponent } from './grafico/grafico.component'
import { VastaInputComponent } from './vasta-input/vasta-input.component'

import { NgxMaskModule } from 'ngx-mask'
import { EnviarImagemComponent } from './enviar-imagem/enviar-imagem.component'
import { DataSeletorComponent } from './data-seletor/data-seletor.component'
import { ManejoPesagemComponent } from './manejo-pesagem/manejo-pesagem.component'
import { ManejoOrdenhaComponent } from './manejo-ordenha/manejo-ordenha.component'
import { ManejoReprodutivoCioComponent } from './manejo-reprodutivo-cio/manejo-reprodutivo-cio.component'
import { ManejoReprodutivoImplanteComponent } from './manejo-reprodutivo-implante/manejo-reprodutivo-implante.component'
import { ManejoReprodutivoRemocaoImplanteComponent } from './manejo-reprodutivo-remocao-implante/manejo-reprodutivo-remocao-implante.component'
import { ManejoReprodutivoDiagnosticoGestacaoComponent } from './manejo-reprodutivo-diagnostico-gestacao/manejo-reprodutivo-diagnostico-gestacao.component'
import { ManejoReprodutivoInseminacaoComponent } from './manejo-reprodutivo-inseminacao/manejo-reprodutivo-inseminacao.component'
import { ManejoReprodutivoMontaNaturalComponent } from './manejo-reprodutivo-monta-natural/manejo-reprodutivo-monta-natural.component'
import { ManejoReprodutivoPartoComponent } from './manejo-reprodutivo-parto/manejo-reprodutivo-parto.component'
import { ModalSelecionarAdicionarCriaComponent } from './modal-selecionar-adicionar-cria/modal-selecionar-adicionar-cria.component'
import { ManejoReprodutivoDesmameComponent } from './manejo-reprodutivo-desmame/manejo-reprodutivo-desmame.component'
import { ManejoReprodutivoAbortoComponent } from './manejo-reprodutivo-aborto/manejo-reprodutivo-aborto.component'
import { ManejoReprodutivoCastracaoComponent } from './manejo-reprodutivo-castracao/manejo-reprodutivo-castracao.component'
import { ManejoReprodutivoEscoreCorporalComponent } from './manejo-reprodutivo-escore-corporal/manejo-reprodutivo-escore-corporal.component'
import { ManejoSanitarioComponent } from './manejo-sanitario/manejo-sanitario.component'
import { PopoverDetalhamentoManejoComponent } from './popover-detalhamento-manejo/popover-detalhamento-manejo.component'
import { PopoverListaExposicoesComponent } from './popover-lista-exposicoes/popover-lista-exposicoes.component'
import { PopoverOpcoesManejosSelecionadosComponent } from './popover-opcoes-manejos-selecionados/popover-opcoes-manejos-selecionados.component'
import { PopoverOpcoesManejoComponent } from './popover-opcoes-manejo/popover-opcoes-manejo.component'
import { ManejoGeralMovimentacaoLoteComponent } from './manejo-geral-movimentacao-lote/manejo-geral-movimentacao-lote.component'
import { ManejoGeralMovimentacaoConfinamentoComponent } from './manejo-geral-movimentacao-confinamento/manejo-geral-movimentacao-confinamento.component'
import { ManejoGeralNumeroControleComponent } from './manejo-geral-numero-controle/manejo-geral-numero-controle.component'
import { ManejoGeralNumeroBrincoEletronicoComponent } from './manejo-geral-numero-brinco-eletronico/manejo-geral-numero-brinco-eletronico.component'
import { ManejoGeralPerdaComponent } from './manejo-geral-perda/manejo-geral-perda.component'
import { ManejoGeralMorteComponent } from './manejo-geral-morte/manejo-geral-morte.component'
import { CalendarioMesAnoComponent } from './calendario-mes-ano/calendario-mes-ano.component'
import { CalendarioComponent } from './calendario/calendario.component'
import { ManejoGeralTransferenciaPropriedadeComponent } from './manejo-geral-transferencia-propriedade/manejo-geral-transferencia-propriedade.component'
import { ManejoGeralVendaComponent } from './manejo-geral-venda/manejo-geral-venda.component'
import { PopoverSelecionarVendaComponent } from './popover-selecionar-venda/popover-selecionar-venda.component'
import { VastaTabelaFiltrosComponent } from './vasta-tabela-filtros/vasta-tabela-filtros.component'
import { ModalColunasImpressaoComponent } from './modal-colunas-impressao/modal-colunas-impressao.component'
import { VastaSegmentComponent } from './vasta-segment/vasta-segment.component'
import { PopoverSeletorCadastroAnimaisComponent } from './popover-seletor-cadastro-animais/popover-seletor-cadastro-animais.component'
import { PipesModule } from '../pipes/pipes.module'
import { ManejoAreaChuvaComponent } from './manejo-area-chuva/manejo-area-chuva.component'
import { ManejoAreaMovimentacaoLotesComponent } from './manejo-area-movimentacao-lotes/manejo-area-movimentacao-lotes.component'
import { ManejoAreaNutricionalComponent } from './manejo-area-nutricional/manejo-area-nutricional.component'
import { CardResumosComponent } from './card-resumo/card-resumo.component'
import { RecursoProComponent } from './recurso-pro/recurso-pro.component'
import { EnviarPdfComponent } from './enviar-pdf/enviar-pdf.component'
import { PopoverRecursoPremiumComponent } from './popover-recurso-premium/popover-recurso-premium.component'
import { ManejoDescarteComponent } from './manejo-descarte/manejo-descarte.component'
import { ManejoSanitarioNovoComponent } from './manejo-sanitario-novo/manejo-sanitario-novo.component'
import { ChatAudioPlayerComponent } from './chat-audio-player/chat-audio-player.component'
import { PopoverConfirmacaoComponent } from './popover-confirmacao/popover-confirmacao.component'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { ManejoAreaAlturaCapimComponent } from './manejo-area-altura-capim/manejo-area-altura-capim.component'
import { ManejoGeralSecagemComponent } from './manejo-geral-secagem/manejo-geral-secagem.component'
import { PopoverListaPiquetesComponent } from './popover-lista-piquetes/popover-lista-piquetes.component'
import { ManejoAreaPastejoRotacionadoComponent } from './manejo-area-pastejo-rotacionado/manejo-area-pastejo-rotacionado.component'
import { PopoverOpcoesFinanceiroPendentePesagemComponent } from './popover-opcoes-financeiro-pendente-pesagem/popover-opcoes-financeiro-pendente-pesagem.component'
import { ManejoGeralObservacaoComponent } from './manejo-geral-observacao/manejo-geral-observacao.component'
import { PopoverSeletorComponent } from './popover-seletor/popover-seletor.component'
import { ManejoLoteEscoreFezesComponent } from './manejo-lote-escore-fezes/manejo-lote-escore-fezes.component'
import { TooltipComponent } from './tooltip/tooltip.component'
import { ManejoGeneticaPerimetroEscrotalComponent } from './manejo-genetica-perimetro-escrotal/manejo-genetica-perimetro-escrotal.component'
import { ConteudosListaComponent } from './conteudos-lista/conteudos-lista.component'
import { ManejoGeneticaTemperamentoComponent } from './manejo-genetica-temperamento/manejo-genetica-temperamento.component'
import { ManejoGeneticaMusculosidadeComponent } from './manejo-genetica-musculosidade/manejo-genetica-musculosidade.component'
import { ManejoGeneticaUmbigoComponent } from './manejo-genetica-umbigo/manejo-genetica-umbigo.component'
import { ManejoGeneticaAprumosComponent } from './manejo-genetica-aprumos/manejo-genetica-aprumos.component'
import { ManejoGeneticaRacialComponent } from './manejo-genetica-racial/manejo-genetica-racial.component'
import { ManejoGeneticaBocaComponent } from './manejo-genetica-boca/manejo-genetica-boca.component'
import { ManejoGeneticaFrameComponent } from './manejo-genetica-frame/manejo-genetica-frame.component'
import { ManejoGeneticaGarupaComponent } from './manejo-genetica-garupa/manejo-genetica-garupa.component'
import { ManejoGeneticaInsercaoCaudaComponent } from './manejo-genetica-insercao-cauda/manejo-genetica-insercao-cauda.component'
import { ManejoGeneticaOssaturaComponent } from './manejo-genetica-ossatura/manejo-genetica-ossatura.component'
import { ManejoGeneticaPelagemComponent } from './manejo-genetica-pelagem/manejo-genetica-pelagem.component'
import { ManejoGeneticaProfundidadeComponent } from './manejo-genetica-profundidade/manejo-genetica-profundidade.component'
import { ManejoGeneticaReproducaoComponent } from './manejo-genetica-reproducao/manejo-genetica-reproducao.component'
import { ManejoGeneticaTorcaoTesticularComponent } from './manejo-genetica-torcao-testicular/manejo-genetica-torcao-testicular.component'
import { ManejoGeneticaCascoComponent } from './manejo-genetica-casco/manejo-genetica-casco.component'
import { ManejoGeneticaChanfroComponent } from './manejo-genetica-chanfro/manejo-genetica-chanfro.component'
import { ManejoGeneticaLinhaDorsoComponent } from './manejo-genetica-linha-dorso/manejo-genetica-linha-dorso.component'
import { ManejoGeneticaUbereComponent } from './manejo-genetica-ubere/manejo-genetica-ubere.component'
import { ModalFinancasSubcategoriasComponent } from './modal-lista-subcategoria/modal-lista-subcategoria.component'

@NgModule({
  declarations: [
    ChecklistInicialComponent,
    NumpadPesosComponent,
    ModalFiltrosTabelaComponent,
    HeaderTabsComponent,
    VastaSegmentComponent,
    CtaButtonsComponent,
    KpisCardsComponent,
    OpcoesBotoesComponent,
    PropriedadeNomeComponent,
    VastaTabela,
    GraficoComponent,
    VastaInputComponent,
    DataSeletorComponent,
    PaginateComponent,
    VastaMapaComponent,
    EnviarImagemComponent,
    ManejoGeralNumeroControleComponent,
    ManejoGeralNumeroBrincoEletronicoComponent,
    ManejoGeralMovimentacaoLoteComponent,
    ManejoGeralMovimentacaoConfinamentoComponent,
    ManejoGeralTransferenciaPropriedadeComponent,
    ManejoGeralVendaComponent,
    ManejoGeralPerdaComponent,
    ManejoGeralMorteComponent,
    ManejoGeralSecagemComponent,
    ManejoPesagemComponent,
    ManejoOrdenhaComponent,
    ManejoReprodutivoCioComponent,
    ManejoReprodutivoImplanteComponent,
    ManejoReprodutivoRemocaoImplanteComponent,
    ManejoReprodutivoDiagnosticoGestacaoComponent,
    ManejoReprodutivoInseminacaoComponent,
    ManejoReprodutivoMontaNaturalComponent,
    ManejoReprodutivoPartoComponent,
    ManejoReprodutivoDesmameComponent,
    ManejoReprodutivoAbortoComponent,
    ManejoReprodutivoCastracaoComponent,
    ManejoReprodutivoEscoreCorporalComponent,
    ManejoSanitarioComponent,
    CalendarioMesAnoComponent,
    CalendarioComponent,
    ModalSelecionarAdicionarCriaComponent,
    PopoverDetalhamentoManejoComponent,
    PopoverListaExposicoesComponent,
    PopoverOpcoesManejosSelecionadosComponent,
    PopoverOpcoesManejoComponent,
    PopoverSelecionarVendaComponent,
    VastaTabelaFiltrosComponent,
    ModalColunasImpressaoComponent,
    PopoverSeletorCadastroAnimaisComponent,
    ManejoAreaChuvaComponent,
    ManejoAreaMovimentacaoLotesComponent,
    CardResumosComponent,
    ManejoAreaNutricionalComponent,
    RecursoProComponent,
    EnviarPdfComponent,
    ChatAudioPlayerComponent,
    PopoverConfirmacaoComponent,
    PopoverRecursoPremiumComponent,
    ManejoDescarteComponent,
    ManejoSanitarioNovoComponent,
    ManejoAreaAlturaCapimComponent,
    PopoverListaPiquetesComponent,
    ManejoAreaPastejoRotacionadoComponent,
    PopoverOpcoesFinanceiroPendentePesagemComponent,
    ManejoGeralObservacaoComponent,
    ManejoLoteEscoreFezesComponent,
    PopoverSeletorComponent,
    ConteudosListaComponent,
    TooltipComponent,
    ManejoGeneticaPerimetroEscrotalComponent,
    ManejoGeneticaTemperamentoComponent,
    ManejoGeneticaMusculosidadeComponent,
    ManejoGeneticaUmbigoComponent,
    ManejoGeneticaAprumosComponent,
    ManejoGeneticaRacialComponent,
    ManejoGeneticaBocaComponent,
    ManejoGeneticaFrameComponent,
    ManejoGeneticaGarupaComponent,
    ManejoGeneticaInsercaoCaudaComponent,
    ManejoGeneticaOssaturaComponent,
    ManejoGeneticaPelagemComponent,
    ManejoGeneticaProfundidadeComponent,
    ManejoGeneticaReproducaoComponent,
    ManejoGeneticaTorcaoTesticularComponent,
    ManejoGeneticaCascoComponent,
    ManejoGeneticaChanfroComponent,
    ManejoGeneticaLinhaDorsoComponent,
    ManejoGeneticaUbereComponent,
    ModalFinancasSubcategoriasComponent
  ],
  imports: [IonicModule.forRoot(), CommonModule, FormsModule, PipesModule, NgxMaskModule.forChild(), ScrollingModule],
  providers: [TimestampPipe],
  exports: [
    ChecklistInicialComponent,
    NumpadPesosComponent,
    ModalFiltrosTabelaComponent,
    HeaderTabsComponent,
    VastaSegmentComponent,
    CtaButtonsComponent,
    KpisCardsComponent,
    OpcoesBotoesComponent,
    PropriedadeNomeComponent,
    VastaTabela,
    GraficoComponent,
    VastaInputComponent,
    DataSeletorComponent,
    PaginateComponent,
    VastaMapaComponent,
    EnviarImagemComponent,
    CalendarioMesAnoComponent,
    CalendarioComponent,
    ManejoGeralNumeroControleComponent,
    ManejoGeralNumeroBrincoEletronicoComponent,
    ManejoGeralMovimentacaoLoteComponent,
    ManejoGeralMovimentacaoConfinamentoComponent,
    ManejoGeralTransferenciaPropriedadeComponent,
    ManejoGeralVendaComponent,
    ManejoGeralPerdaComponent,
    ManejoGeralMorteComponent,
    ManejoGeralSecagemComponent,
    ManejoPesagemComponent,
    ManejoOrdenhaComponent,
    ManejoReprodutivoCioComponent,
    OpcoesBotoesComponent,
    ManejoReprodutivoImplanteComponent,
    ManejoReprodutivoRemocaoImplanteComponent,
    ManejoReprodutivoDiagnosticoGestacaoComponent,
    ManejoReprodutivoInseminacaoComponent,
    ManejoReprodutivoMontaNaturalComponent,
    ManejoReprodutivoPartoComponent,
    ManejoReprodutivoDesmameComponent,
    ManejoReprodutivoAbortoComponent,
    ManejoReprodutivoCastracaoComponent,
    ManejoReprodutivoEscoreCorporalComponent,
    ManejoSanitarioComponent,
    ModalSelecionarAdicionarCriaComponent,
    PopoverDetalhamentoManejoComponent,
    PopoverListaExposicoesComponent,
    PopoverOpcoesManejosSelecionadosComponent,
    PopoverOpcoesManejoComponent,
    VastaTabelaFiltrosComponent,
    ModalColunasImpressaoComponent,
    PopoverSeletorCadastroAnimaisComponent,
    ManejoAreaChuvaComponent,
    ManejoAreaMovimentacaoLotesComponent,
    CardResumosComponent,
    ManejoAreaNutricionalComponent,
    RecursoProComponent,
    PopoverRecursoPremiumComponent,
    EnviarPdfComponent,
    ChatAudioPlayerComponent,
    PopoverConfirmacaoComponent,
    ManejoDescarteComponent,
    ManejoSanitarioNovoComponent,
    ManejoAreaAlturaCapimComponent,
    ManejoAreaPastejoRotacionadoComponent,
    PopoverOpcoesFinanceiroPendentePesagemComponent,
    ManejoGeralObservacaoComponent,
    ManejoLoteEscoreFezesComponent,
    PopoverSeletorComponent,
    ConteudosListaComponent,
    TooltipComponent,
    ManejoGeneticaPerimetroEscrotalComponent,
    ManejoGeneticaTemperamentoComponent,
    ManejoGeneticaMusculosidadeComponent,
    ManejoGeneticaUmbigoComponent,
    ManejoGeneticaAprumosComponent,
    ManejoGeneticaRacialComponent,
    ManejoGeneticaBocaComponent,
    ManejoGeneticaFrameComponent,
    ManejoGeneticaGarupaComponent,
    ManejoGeneticaInsercaoCaudaComponent,
    ManejoGeneticaOssaturaComponent,
    ManejoGeneticaPelagemComponent,
    ManejoGeneticaProfundidadeComponent,
    ManejoGeneticaReproducaoComponent,
    ManejoGeneticaTorcaoTesticularComponent,
    ManejoGeneticaCascoComponent,
    ManejoGeneticaChanfroComponent,
    ManejoGeneticaLinhaDorsoComponent,
    ManejoGeneticaUbereComponent,
    ModalFinancasSubcategoriasComponent
  ]
})
export class ComponentsModule { }
