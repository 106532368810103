import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common'
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IEstacaoReprodutiva } from 'src/app/utils/interfaces/dashboard.interface'
import { EstoqueBiologico } from 'src/app/utils/interfaces/estoque.interface'
import { IKpisCard } from 'src/app/utils/interfaces/tabela-colunas'

@Component({
  selector: 'kpis-cards',
  templateUrl: './kpis-cards.component.html',
  styleUrls: ['./kpis-cards.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class KpisCardsComponent implements OnInit {
  @Input('cardsInfo') cards: IKpisCard[]
  @Input('wrap') wrap: boolean = false
  @Input('valores') valores: { [key: string]: number | string } | IEstacaoReprodutiva | EstoqueBiologico | unknown = {}
  @Input('tipo') tipo: 'cards' | 'comprovante' = 'cards'

  constructor(private currencyPipe: CurrencyPipe, private datePipe: DatePipe, private decimalPipe: DecimalPipe, public planoBasicoCtrl: PlanoBasicoService, public propriedadesCtrl: PropriedadesService) { }

  ngOnInit(): void {
    console.log('debug cards: ', this.cards)
  }

  clickCard(card: IKpisCard): void {
    if (card.acao) {
      card.acao()
    }
  }

  formatarValorCard(card: IKpisCard): string | number {
    if (card.formatacao === 'currency') {
      return this.currencyPipe.transform(card.valor || this.valores[card?.key] || 0, 'BRL') || ''
    }
    if (card.formatacao === 'date') {
      return this.datePipe.transform(card.valor || this.valores[card?.key], 'dd/MM/yyyy') || '-'
    }
    if (card.formatacao === 'number') {
      return this.decimalPipe.transform(card.valor || this.valores[card?.key] || 0, '1.0-2') || ''
    }
    if (card.formatacao === 'gmd') {
      return this.decimalPipe.transform(card.valor || this.valores[card?.key] || 0, '1.0-3') || ''
    }
    
    return card.valor || this.valores[card?.key] || ''
  }
}
