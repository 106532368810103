<div class="container-filtros">
  <div class="loader" *ngIf="status === 'carregando'">
    <ion-spinner color="primary"></ion-spinner>
    Buscando manejos...
  </div>
  <div class="loader" *ngIf="status === 'erro'">
    <ion-icon name="warning"></ion-icon>
    Erro ao buscar manejos
  </div>
  <ng-container *ngIf="status === 'sucesso'">
    <div
      class="filtro"
      [ngClass]="{ selecionado: !manejosSelecionadosStrings?.length }"
      *ngIf="manejosDisponiveis.length >= 1"
      (click)="selecionarFiltro($event)"
    >
      <div class="filtro-single-wrapper">
        <div class="filtro-tipo">
          <ion-icon name="list-outline"></ion-icon>
          <span>Todos os Manejos</span>
        </div>
        <div class="filtro-acoes">
          <button (click)="popoverOpcoes($event)">
            <ion-icon name="ellipsis-vertical"></ion-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="manejosDisponiveis.length">
      <div
        class="filtro"
        *ngFor="let filtro of manejosDisponiveis"
        [ngClass]="{ selecionado: manejosSelecionadosStrings?.includes(filtro.hash || filtro.procedimento) }"
        (click)="selecionarFiltro($event, filtro)"
      >
        <div class="filtro-single-wrapper">
          <div class="filtro-tipo">
            <ion-icon [name]="filtro.icon"></ion-icon>
            <span>
              <ng-template [ngIf]="filtro.descricao">
                <small>{{ filtro.nome }}</small>
                {{ filtro.descricao }}
              </ng-template>
              <ng-template [ngIf]="!filtro.descricao">
                {{ filtro.nome }}
              </ng-template>
            </span>
          </div>
          <div class="filtro-acoes">
            <div class="qtd-animais">{{ resumos[filtro.procedimento]?.qtd || '0' }}</div>
            <button
              (click)="popoverOpcoes($event, filtro)"
              *ngIf="salvando !== filtro.tipo && (popoverCampos[filtro.procedimento] || filtro.tipo == 'sanitario')"
            >
              <ion-icon name="ellipsis-vertical"></ion-icon>
            </button>
            <ion-spinner color="primary" *ngIf="salvando === filtro.tipo"></ion-spinner>
          </div>
        </div>

        <div class="totais" *ngIf="resumos[filtro.procedimento]?.totais?.length">
          <ng-template ngFor let-resumo [ngForOf]="resumos[filtro.procedimento]?.totais">
            <div class="dado-single">
              <b *ngIf="resumo.qtd">{{ resumo.qtd }}</b>
              <span>{{ resumo.chave }}</span>
              <b>{{ resumo.valor }}</b>
            </div>
          </ng-template>
        </div>

        <button 
          class="btn-selecionar-filtro"
          *ngIf="manejosSelecionadosStrings?.includes(filtro.hash || filtro.procedimento)"
          [ngClass]="{ 'ativo': verificarSeFiltroAtivo(filtro) }"
          (click)="selecionarFiltroManejo($event, filtro)">
          <ion-icon name="filter-outline"></ion-icon>
          <span>{{opcoesFiltrosManejos[filtro.hash || filtro.procedimento]?.label || 'Todos os Animais'}}</span>
          <div class="container-icone" (click)="removerFiltroManejo($event, filtro)">
            <ion-icon [name]="verificarSeFiltroAtivo(filtro)? 'close-outline' : 'caret-down-outline'"></ion-icon>
          </div>
        </button>
      </div>
    </ng-container>

    <button
      class="btn-adicionar-manejo"
      [ngClass]="{ 'filtros-ativos': manejosDisponiveis.length }"
      (click)="adicionarManejoTrigger()"
      [class.disabled]="!!salvando"
    >
      <ion-icon name="add-circle-sharp"></ion-icon>
      <span>Adicionar Manejo</span>

      <div class="container-popover vertical" *ngIf="!manejosDisponiveis.length">
        <div class="custom-popover">
          Adicione um manejo
          <div class="popover-arrow-up"></div>
        </div>
      </div>
    </button>
  </ng-container>
</div>
