import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController, RefresherEventDetail } from '@ionic/angular'
import { NotificacoesService } from './../../../services/notificacoes.service'
import { ModalAssinaturaPage } from '../modal-assinatura/modal-assinatura.page'
import { Browser } from '@capacitor/browser'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import moment from 'moment'

@Component({
  selector: 'app-modal-notificacoes',
  templateUrl: './modal-notificacoes.page.html',
  styleUrls: ['./modal-notificacoes.page.scss'],
})
export class ModalNotificacoesPage implements OnInit {

  public notificacoes = []
  public carregando = true

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private utilsCtrl: IonicUtilsService,
    private notificacoesCtrl: NotificacoesService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.buscarNotificacoes()
    this.carregando = false
  }
  
  async buscarNotificacoes(): Promise<void> {
    const [response, error] = await this.notificacoesCtrl.listarNotificacoes()
    if (error) {
      console.error(error)
      this.utilsCtrl.showToast('Houve um erro ao buscar notificações', 'top')
    }
    if (response) {
      const { notificacoes } = response
      notificacoes.map(a => {
        a.timestamp = moment.utc(a.timestamp, 'YYYY-MM-DD HH:mm:ss').local().format('DD/MM/YYYY HH:mm')
        if (a.timestamp == 'Data inválida') {
          a.timestamp = ''
        }
      })
      this.notificacoes = notificacoes
    }
  }

  modalClose(): void {
    this.modalCtrl.dismiss()
  }

  async abrirNotificacao(url: string, tipo: string): Promise<void> {
    if(tipo == 'link_interno'){
      this.router.navigateByUrl(url)
      this.modalClose()
    }
    if(tipo == 'modal' && url == '/assinatura'){
      await this.abrirModalAssinatura()
    }
    if(tipo == 'link_externo'){
      Browser.open({
        url,
        presentationStyle: 'popover'
      })
    }
    
  }

  async abrirModalAssinatura(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAssinaturaPage,
      cssClass: 'custom-modal',
      componentProps: {}
    })

    await modal.present()
  }

  async handleRefresh(event: { target: RefresherEventDetail }): Promise<void> {
    await this.buscarNotificacoes()
    event.target.complete()
  }
}

