<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Configuração de Tabela</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" [disabled]="atualizandoColunas">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let coluna of colunasFixas">
      <ion-checkbox
        [class.coluna-desabilitada]="coluna.desabilitada"
        [(ngModel)]="coluna.exibida"
        (ionChange)="toggleCheckbox(coluna)"
        class="checkbox__selecionar-coluna"
      ></ion-checkbox>
      <ion-label class="label__titulo-coluna" [class.coluna-desabilitada]="coluna.desabilitada">
        {{ coluna.titulo }}
      </ion-label>
      <ng-template [ngIf]="coluna.desabilitada">
        <span class="span__coluna-desabilitada">Desabilitada pela propriedade</span>
      </ng-template>
    </ion-item>
    <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReordenarTabela($event)">
      <ion-item *ngFor="let coluna of colunasModificaveis">
        <ion-checkbox
          class="checkbox__selecionar-coluna"
          [(ngModel)]="coluna.exibida"
          (ionChange)="toggleCheckbox(coluna)"
        ></ion-checkbox>
        <ion-label>{{ coluna.titulo }}</ion-label>
        <ion-reorder slot="end"></ion-reorder>
      </ion-item>
      <ion-item *ngFor="let coluna of colunaMais">
        <ion-checkbox
          [disabled]="true"
          [(ngModel)]="coluna.exibida"
          (ionChange)="toggleCheckbox(coluna)"
          class="checkbox__selecionar-coluna"
        ></ion-checkbox>
        <ion-label>{{ coluna.titulo }}</ion-label>
      </ion-item>
    </ion-reorder-group>
  </ion-list>
</ion-content>

<ion-footer>
  <cta-buttons>
    <button class="primary confirmar" (click)="confirmarAlteracaoTabela()" *ngIf="!atualizandoColunas">
      <ion-icon name="checkmark"></ion-icon>
      <span>Confirmar</span>
    </button>
    <button class="primary confirmar" [disabled]="true" *ngIf="atualizandoColunas">
      <ion-spinner color="light" style="width: 12px; height: 12px"></ion-spinner>
      <span>Salvando...</span>
    </button>
  </cta-buttons>
  <cta-buttons>
    <button class="danger voltar-para-padrao" (click)="alertVoltarParaPadrao()" [disabled]="atualizandoColunas" *ngIf="!!configProp">
      <ion-icon name="trash"></ion-icon>
      <span>Excluir Customização</span>
    </button>
  </cta-buttons>
</ion-footer>
