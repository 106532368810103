import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { AnimaisService } from 'src/app/services/animais.service'
import { IManejoGeralUnitario } from 'src/app/services/manejos.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-geral-morte',
  templateUrl: './manejo-geral-morte.component.html',
  styleUrls: ['./manejo-geral-morte.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManejoGeralMorteComponent implements OnInit {
  @Input('dados') dados: IManejoGeralUnitario = {
    morte_causa: '',
    morte_latitude: '',
    morte_longitude: '',
    morte_imagem_1: '',
    morte_imagem_2: '',
    morte_imagem_3: '',
    morte_imagem_4: '',
    morte_imagem_5: '',
    morte_observacoes: ''
  }
  @Input('data') data: string
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()
  enviandoImagem = false
  constructor(
    private animaisCtrl: AnimaisService,
    private popoverCtrl: PopoverController,
    private utilsCtrl: IonicUtilsService
  ) {}

  ngOnInit(): void {}

  segmentChange(): void {
    if (this.dados.novo_status == 'vivo') {
      this.limpar()
    }
    this.dados.edited = true
    this.emit()
  }

  emit(): void {
    this.dadosChange.emit(this.dados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.limpar()
      this.emit()
    }
  }

  limpar(): void {
    this.dados.edited = true
    this.dados.observacao = ''
    this.dados.morte_causa = ''
    this.dados.morte_latitude = ''
    this.dados.morte_longitude = ''
    this.dados.morte_imagem_1 = ''
    this.dados.morte_imagem_2 = ''
    this.dados.morte_imagem_3 = ''
    this.dados.morte_imagem_4 = ''
    this.dados.morte_imagem_5 = ''
    this.dados.morte_observacoes = ''
    this.dados.novo_status = 'vivo'
  }

  async enviarImagens(imagem: string): Promise<void> {
    this.enviandoImagem = true
    const [response, error] = await this.animaisCtrl.enviarImagem({ tipo: 'morte', imagem })

    if (response) {
      this.enviandoImagem = false
      this.dados.edited = true

      const { url } = response
      if (!this.dados.morte_imagem_1) {
        this.dados.morte_imagem_1 = url
        this.emit()
        return
      }
      if (!this.dados.morte_imagem_2) {
        this.dados.morte_imagem_2 = url
        this.emit()
        return
      }
      if (!this.dados.morte_imagem_3) {
        this.dados.morte_imagem_3 = url
        this.emit()
        return
      }
      if (!this.dados.morte_imagem_4) {
        this.dados.morte_imagem_4 = url
        this.emit()
        return
      }
      if (!this.dados.morte_imagem_5) {
        this.dados.morte_imagem_5 = url
        this.emit()
        return
      }
    }

    if (error) {
      this.enviandoImagem = false
      this.utilsCtrl.showToast('Erro no upload da imagem', 'bottom')
      console.log(error)
    }
  }

  removeImagem(index: number): void {
    this.dados[`morte_imagem_${index}`] = ''

    const images: string[] = []
    if (this.dados.morte_imagem_1) {
      images.push(this.dados.morte_imagem_1)
    }
    if (this.dados.morte_imagem_2) {
      images.push(this.dados.morte_imagem_2)
    }
    if (this.dados.morte_imagem_3) {
      images.push(this.dados.morte_imagem_3)
    }
    if (this.dados.morte_imagem_4) {
      images.push(this.dados.morte_imagem_4)
    }
    if (this.dados.morte_imagem_5) {
      images.push(this.dados.morte_imagem_5)
    }

    this.dados.morte_imagem_1 = images[0] || ''
    this.dados.morte_imagem_2 = images[1] || ''
    this.dados.morte_imagem_3 = images[2] || ''
    this.dados.morte_imagem_4 = images[3] || ''
    this.dados.morte_imagem_5 = images[4] || ''

    this.dados.edited = true
    this.emit()
  }
}
