/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output, ElementRef, SimpleChanges, ViewChild } from '@angular/core'

import { PopoverController } from '@ionic/angular'
import { CalendarioComponent } from '../calendario/calendario.component'
type VastaInputType =
  | 'text'
  | 'number'
  | 'int'
  | 'email'
  | 'textarea'
  | 'select'
  | 'button'
  | 'date'
  | 'checkbox'
  | 'time'
  | 'month'
  | 'password'
  | 'manejo-input'
  | 'manejo-int'
  | 'manejo-number'
  | 'manejo-button'
  | 'manejo-checkbox'
  | 'manejo-select'
  | 'manejo-segment'

export type VastaInputOpcoes = { label: string; value: string | number }[]

@Component({
  selector: 'vasta-input',
  templateUrl: './vasta-input.component.html',
  styleUrls: ['./vasta-input.component.scss']
})
export class VastaInputComponent implements OnInit {
  @ViewChild('inputFocus') inputRef: ElementRef

  @Input('type') type: VastaInputType = 'text'
  @Input('label') label: string = ''
  @Input('focus') focus: boolean = false
  @Input('icon') icon: string = ''
  @Input('max') max: string = ''
  @Input('mode') mode: 'clear' | '' = ''
  @Input('opcoes') opcoes: VastaInputOpcoes
  @Input('placeholder') placeholder: string = ''
  @Input('obrigatorio') obrigatorio: boolean = false
  @Input('opcional') opcional: boolean = false
  @Input('debounce') debounce: number = 0
  @Input('disabled') disabled: boolean = false
  @Input('value') value: any
  @Input('mask') mask: any
  @Input('maskPrefix') maskPrefix: string
  @Input('maskSuffix') maskSuffix: string
  @Input('allowNegativeNumbers') allowNegativeNumbers: boolean = false
  @Input('expand') expand: 'full' | '' = ''
  @Output() valueChange: EventEmitter<any> = new EventEmitter()
  @Input('valueCheckbox') valueCheckbox: boolean
  @Input('hideClear') hideClear: boolean

  @Input() 'tooltipType': 'help' | 'alert' | 'error' = 'help'
  @Input() 'tooltipLabel': string = ''
  @Input() 'tooltipPosition': 'top' | 'bottom' | 'right' | 'left'  = 'top'

  @Output() valueCheckboxChange: EventEmitter<boolean> = new EventEmitter()
  @Output() buttonEvent: EventEmitter<Event> = new EventEmitter()
  @Output() clear: EventEmitter<Event> = new EventEmitter()
  @Output() enter: EventEmitter<Event> = new EventEmitter()

  debounceTimeout

  numberInvalidChars = ['e', '+']
  isReloadingSelects = false

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {
    if (this.type == 'int' || this.type == 'manejo-int') {
      this.numberInvalidChars.push('.')
      this.numberInvalidChars.push(',')
    }
    if (this.obrigatorio) {
      this.label = `${this.label} *`
    }
  }

  reloadSelects(): void {
    this.isReloadingSelects = true
    
    setTimeout(() => {
      this.isReloadingSelects = false
    }, 120)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.focus?.currentValue) {
      this.triggerFocus()
    }
    if (changes?.opcoes?.currentValue) {
      this.reloadSelects()
    }
  }

  triggerFocus(): void {
    if (this.inputRef) {
      this.inputRef.nativeElement.focus()
    }
  }

  emitNumber(e: KeyboardEvent): void {
    if (this.numberInvalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  emitEnter(): void {
    this.enter.emit()
  }

  async abrirVastaCalendar(event: Event): Promise<void> {
    const vastaCalendario = await this.popoverCtrl.create({
      component: CalendarioComponent,
      event,
      reference: 'trigger',
      cssClass: 'popover-calendario'
    })

    vastaCalendario.present()

    const { data } = await vastaCalendario.onDidDismiss()

    if (data) {
      this.value = data.iso
      this.emit()
    }
  }

  emit(): void {
    if (
      this.type == 'number' ||
      this.type == 'manejo-number' ||
      (this.type == 'int' && this.value) ||
      (this.type == 'manejo-int' && this.value)
    ) {
      try {
        this.value =
          this.type == 'int' || this.type == 'manejo-int'
            ? this.value.replace(/[e\+\-\.\,]/gi, '')
            : this.value.replace(/[e\+\-]/gi, '')
      } catch (_) {}
    }

    if (
      this.type == 'text' ||
      this.type == 'textarea'
    ) {
      try {
        const caracteresEspeciais = /(['"`]|[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
        this.value = caracteresEspeciais.test(this.value) ? this.value.replace(caracteresEspeciais, '') : this.value
      } catch (e) {}
    }

    clearTimeout(this.debounceTimeout)

    this.debounceTimeout = setTimeout(() => {
      this.valueChange.emit(this.value)
    }, this.debounce)
  }

  emitCheckbox(): void {
    clearTimeout(this.debounceTimeout)

    this.debounceTimeout = setTimeout(() => {
      this.valueCheckboxChange.emit(this.valueCheckbox)
    }, this.debounce)
  }

  emitButton(event?: Event): void {
    this.buttonEvent.emit(event)
  }

  limparValorInput(): void {
    this.value = ''
    this.emit()
    this.clear.emit()
  }

  segmentSelect(value: string | number): void {
    this.value = `${value}`
    this.emit()
  }
}
