import { Injectable, NgZone } from '@angular/core'
import { AlertController, ModalController, NavController } from '@ionic/angular'
import { Preferences } from '@capacitor/preferences'
import {
  ILinkLoginPeao,
  IPayloadPermissoesUsuario,
  IPeao,
  IPeoes,
  IUsuario,
  IUsuarios,
  PermissoesPorPropriedade,
  StatusPermissaoUsuario
} from '../utils/interfaces/Usuarios.interface'
import { ApiService } from './api/api.service'
import { PropriedadesService } from './propriedades.service'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'
import { PushNotificationsService } from './push-notifications.service'
import { NovoQueryParamsModel } from '../utils/interfaces/query-params.interface'

export type HasEmail = { existe: boolean; id?: number }
export type StatusOperacaoUsuario = { success: boolean; id?: number }

declare const reloadChannel

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  public isUsuarioValido = false
  public statusPermissao: StatusPermissaoUsuario
  public permissoesPorPropriedade: PermissoesPorPropriedade = {}
  public usuarioCache: IUsuario = null

  constructor(
    private apiCtrl: ApiService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private ngZone: NgZone,
    private pushNotificationService: PushNotificationsService,
    private propriedadesCtrl: PropriedadesService
  ) {}

  async iniciarPlanoTrial(): Promise<[StatusRequest, Error?]> {
    try {
      const response = (await this.apiCtrl.post({
        url: 'usuario/assinatura/iniciar-trial'
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async iniciarPlanoBasico(): Promise<[StatusRequest, Error?]> {
    try {
      const response = (await this.apiCtrl.post({
        url: 'usuario/assinatura/iniciar-basico'
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getUsuarioSingleEAtualizaUsuarioCache(): Promise<[IUsuario, Error?]> {
    try {
      const response = (await this.apiCtrl.get({
        url: 'usuario'
      })) as IUsuario
      if (response) {
        this.usuarioCache = response
        await Preferences.set({
          key: 'usuario',
          value: JSON.stringify(response)
        })
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getUsuarioStorage(): Promise<IUsuario> {
    const usuarioSalvo = await Preferences.get({
      key: 'usuario'
    })

    if (usuarioSalvo) {
      this.usuarioCache = JSON.parse(usuarioSalvo.value)
      return this.usuarioCache
    }

    return null
  }

  async getUsuarios(): Promise<[IUsuarios, Error?]> {
    try {
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'roles/usuarios'
      })) as IUsuarios
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getUsuariosPeao(filtros?: NovoQueryParamsModel): Promise<[{ peoes: IPeoes, totais: { total: number } }, Error?]> {
    try {
      const response = (await this.apiCtrl.get({ url: 'peao', filtros })) as { peoes: IPeoes, totais: { total: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async verificarEmail(email: string): Promise<[HasEmail, Error?]> {
    try {
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'roles/confere-cadastro',
        body: { email }
      })) as HasEmail

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setPermissoesUsuario(usuarioPermissoes: IPayloadPermissoesUsuario): Promise<[StatusOperacaoUsuario, Error?]> {
    try {
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'roles/associa-usuario',
        body: usuarioPermissoes
      })) as StatusOperacaoUsuario

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setPeao(peao: IPeao): Promise<[StatusOperacaoUsuario, Error?]> {
    try {
      const response = (await this.apiCtrl.post({ url: 'peao', body: peao })) as StatusOperacaoUsuario

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async removerPeao(id: number): Promise<[StatusOperacaoUsuario, Error?]> {
    try {
      const response = (await this.apiCtrl.delete({ url: `peao/${id}` })) as StatusOperacaoUsuario

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async gerarLinkLoginPeao(id: number): Promise<[ILinkLoginPeao, Error?]> {
    try {
      const response = (await this.apiCtrl.post({ url: `peao/${id}/codigo-login` })) as ILinkLoginPeao

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async sairDaConta(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Sair da Conta?',
      message: 'Deseja realmente sair da sua conta?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Sair',
          handler: (): void => {
            this.modalCtrl.dismiss()
            this.logout()
          }
        }
      ]
    })

    await alert.present()
  }

  async logout(): Promise<void> {
    this.pushNotificationService.sendTokenToServer('unsubscribe')
    await Preferences.remove({ key: 'fertili-token' })
    await Preferences.remove({ key: 'usuario' })
    this.propriedadesCtrl.todasPropriedades = []
    this.propriedadesCtrl.propriedadeSelecionada = null
    this.ngZone.run(() => this.navCtrl.navigateBack('/login'))
    try {
      localStorage.clear()
    } catch (error) {
      console.log(error)
    }

    this.emitReload()
  }
  
  emitReload() {
    try {
      reloadChannel.postMessage({ action: 'reload' })
    } catch (error) {
      console.log(error)
    }
  }
}
