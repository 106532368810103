<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ animalProp ? 'Editar Animal' : 'Novo Animal' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button [disabled]="isLoading" (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div [ngClass]="{ hidden: abaAtiva !== 'Geral' }">
    <div class="modal-form">
      <ion-row>
        <ion-col size="6" style="padding-left: 0">
          <vasta-input
            [label]="'Sexo'"
            [type]="'select'"
            [icon]="animal.sexo == 'macho' ? 'male' : 'female'"
            [(value)]="animal.sexo"
            [opcoes]="[
              { label: 'Macho', value: 'macho' },
              { label: 'Fêmea', value: 'femea' }
            ]"
          ></vasta-input>
        </ion-col>

        <ion-col size="6" style="padding-right: 0">
          <vasta-input
            [obrigatorio]="true"
            [label]="'Data de Nascimento'"
            [type]="'date'"
            [(value)]="animal.data_nascimento"
            [disabled]="!!dataNascimentoProp"
          ></vasta-input>
        </ion-col>
      </ion-row>

      <ion-row>
        <ng-container *ngIf="animalProp">
          <ion-col style="padding: 0">
            <vasta-input
              *ngIf="animal.sexo === 'macho'"
              [label]="'Classificação Reprodutiva'"
              [(value)]="animal.situacao_reprodutiva"
              [opcoes]="[
                { label: 'Touro', value: 'touro' },
                { label: 'Rufião', value: 'rufiao' },
                { label: 'Castrado', value: 'castrado' },
                { label: 'Inteiro', value: 'inteiro' }
              ]"
              [placeholder]="'Selecionar'"
              [type]="'select'"
            ></vasta-input>
          </ion-col>
        </ng-container>
      </ion-row>

      <ion-row>
        <ion-col size="6" style="padding-left: 0">
          <vasta-input
            [label]="'Tipo de Cadastro'"
            [type]="'select'"
            [(value)]="animal.tipo_entrada"
            [opcoes]="[
              { label: 'Nascido na Fazenda', value: 'nascimento' },
              { label: 'Aquisição', value: 'aquisicao' }
            ]"
            [placeholder]="'Selecionar'"
          ></vasta-input>
        </ion-col>
        <ion-col size="6" style="padding-right: 0">
          <vasta-input
            [label]="'Aptidão'"
            [type]="'select'"
            [(value)]="animal.aptidao"
            [opcoes]="[
              { label: 'Corte', value: 'corte' },
              { label: 'Dupla Aptidão', value: 'dupla' },
              { label: 'Leite', value: 'leite' }
            ]"
            [placeholder]="'Selecionar'"
          ></vasta-input>
        </ion-col>
      </ion-row>

      <div class="container-alerta-numero">
        <vasta-input
          [obrigatorio]="animal.nome ? false : true"
          *ngIf="
            !animal.hash &&
            (!propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais ||
              propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais === 'numero-e-nome' ||
              propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais === 'numero')
          "
          [label]="'Número de Controle'"
          [type]="'text'"
          [(value)]="animal.numeracao"
          (valueChange)="verificarSeNumeroJaEstaCadastrado()"
        ></vasta-input>
        <ng-template [ngIf]="!animalProp">
          <span *ngIf="isNumeroJaCadastrado" class="alerta-numero numero-ja-cadastrado">Número já cadastrado</span>
          <span *ngIf="isNumeroIgualAoDaMae" class="alerta-numero numero-igual-da-mae">Mesmo número da mãe</span>
        </ng-template>
      </div>

      <vasta-input
        [obrigatorio]="animal.numeracao ? false : true"
        *ngIf="isMetadadosControleAnimaisIgualANome"
        [label]="'Nome'"
        [type]="'text'"
        [(value)]="animal.nome"
      ></vasta-input>

      <vasta-input
        *ngIf="!animal.hash"
        [label]="'Lote'"
        [type]="'button'"
        [(value)]="animal.extra_lote"
        (buttonEvent)="openModalListaLotes()"
        [placeholder]="'Selecionar'"
      ></vasta-input>

      <ion-row>
        <ion-col size="8" style="padding-left: 0">
          <vasta-input
            [obrigatorio]="true"
            label="Raça"
            type="button"
            [(value)]="racas[animal.raca] || animal.raca"
            placeholder="Selecionar"
            (buttonEvent)="openModalListaRacas('primaria')"
            (clear)="limpaRacas('primaria')"
          ></vasta-input>
        </ion-col>
        <ion-col size="4" style="padding-right: 0">
          <vasta-input
            label="Composição"
            type="select"
            [(value)]="animal.meta_raca_composicao"
            [opcoes]="[
              { label: '1/1', value: '1/1' },
              { label: '1/2', value: '1/2' },
              { label: '2/3', value: '2/3' },
              { label: '1/4', value: '1/4' },
              { label: '3/4', value: '3/4' },
              { label: '1/8', value: '1/8' },
              { label: '2/8', value: '2/8' },
              { label: '3/8', value: '3/8' },
              { label: '5/8', value: '5/8' },
            ]"
            [placeholder]="'Selecionar'"
          ></vasta-input>
        </ion-col>
      </ion-row>

      <vasta-input
        *ngIf="!animal.meta_raca_composicao || animal.meta_raca_composicao == '1/1'"
        [label]="'Puro de Origem?'"
        [type]="'checkbox'"
        [valueCheckbox]="!!animal.meta_puro_origem"
        (valueCheckboxChange)="toggleMetaPuroOrigem($event)"
      ></vasta-input>

      <ion-row *ngIf="animal.raca && animal.meta_raca_composicao !== '1/1'">
        <ion-col size="8" style="padding-left: 0">
          <vasta-input
            label="Raça 2"
            type="button"
            [(value)]="racas[animal.meta_raca_secundaria] || animal.meta_raca_secundaria"
            placeholder="Selecionar"
            (buttonEvent)="openModalListaRacas('secundaria')"
            (clear)="limpaRacas('secundaria')"
          ></vasta-input>
        </ion-col>
        <ion-col size="4" style="padding-right: 0">
          <vasta-input
            label="Composição"
            type="select"
            [(value)]="animal.meta_raca_secundaria_composicao"
            [opcoes]="[
              { label: '1/2', value: '1/2' },
              { label: '2/3', value: '2/3' },
              { label: '1/4', value: '1/4' },
              { label: '3/4', value: '3/4' },
              { label: '1/8', value: '1/8' },
              { label: '2/8', value: '2/8' },
              { label: '3/8', value: '3/8' },
              { label: '5/8', value: '5/8' },
            ]"
            [placeholder]="'Selecionar'"
          ></vasta-input>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="animal.meta_raca_secundaria && animal.meta_raca_composicao !== '1/1'">
        <ion-col size="8" style="padding-left: 0">
          <vasta-input
            label="Raça 3"
            type="button"
            [(value)]="racas[animal.meta_raca_terciaria] || animal.meta_raca_terciaria"
            placeholder="Selecionar"
            (buttonEvent)="openModalListaRacas('terciaria')"
            (clear)="limpaRacas('terciaria')"
          ></vasta-input>
        </ion-col>
        <ion-col size="4" style="padding-right: 0">
          <vasta-input
            label="Composição"
            type="select"
            [(value)]="animal.meta_raca_terciaria_composicao"
            [opcoes]="[
              { label: '1/2', value: '1/2' },
              { label: '2/3', value: '2/3' },
              { label: '1/4', value: '1/4' },
              { label: '3/4', value: '3/4' },
              { label: '1/8', value: '1/8' },
              { label: '2/8', value: '2/8' },
              { label: '3/8', value: '3/8' },
              { label: '5/8', value: '5/8' },
            ]"
            [placeholder]="'Selecionar'"
          ></vasta-input>
        </ion-col>
      </ion-row>


      <ng-template [ngIf]="!dataNascimentoProp">
        <ion-row>
          <h2>Genealogia</h2>
        </ion-row>
        <ion-row class="ion-justify-content-between">
          <ion-col [size]="6" style="padding-left: 0">
            <vasta-input
              [label]="'Reprodutor'"
              [type]="'button'"
              [value]="
                animal.genealogia_nome_pai || animal.genealogia_numero_pai
                  ? (animal.genealogia_nome_pai || '') + ' ' + (animal.genealogia_numero_pai || '')
                  : ''
              "
              [icon]="animal.genealogia_reprodutor_externo || animal.genealogia_reprodutor_interno ? 'male' : ''"
              [placeholder]="'Selecionar'"
              (clear)="limparCampo('pai')"
              (buttonEvent)="openModalReprodutores({ chave: 'sexo', valor: 'macho' })"
            ></vasta-input>
          </ion-col>
          <ion-col [size]="6" style="padding-right: 0">
            <vasta-input
              [label]="'Matriz'"
              [type]="'button'"
              [value]="
                animal.genealogia_nome_mae || animal.genealogia_numero_mae
                  ? (animal.genealogia_nome_mae || '') + ' ' + (animal.genealogia_numero_mae || '')
                  : ''
              "
              [icon]="animal.genealogia_matriz_externa || animal.genealogia_matriz_interna ? 'female' : ''"
              [placeholder]="'Selecionar'"
              (clear)="limparCampo('mae')"
              (buttonEvent)="openModalReprodutores({ chave: 'sexo', valor: 'femea' })"
            ></vasta-input>
          </ion-col>
        </ion-row>
      </ng-template>

      <ng-template [ngIf]="!animalProp">
        <ion-row>
          <h2>Pesagens</h2>
        </ion-row>

        <ion-row class="ion-justify-content-between">
          <ion-col style="padding: 0">
            <vasta-input
              [label]="'Peso ao Nascer'"
              [type]="'text'"
              [(value)]="animal.pesagens.peso_nascimento"
              mask="separator.2"
              maskSuffix=" kg"
              tooltipType="help"
              tooltipPosition="left"
              tooltipLabel="O peso ao nascer padrão pode ser alterado nas configurações da propriedade"
            ></vasta-input>
          </ion-col>
        </ion-row>

        <ion-row class="ion-justify-content-between">
          <ion-col [size]="6" style="padding-left: 0">
            <vasta-input
              [label]="'Peso do Desmame'"
              [type]="'text'"
              [(value)]="animal.pesagens.peso_desmame"
              mask="separator.2"
              maskSuffix=" kg"
            ></vasta-input>
          </ion-col>

          <ion-col [size]="6" style="padding-right: 0">
            <vasta-input [label]="'Data de Desmame'" [type]="'date'" [(value)]="animal.meta_data_desmame"></vasta-input>
          </ion-col>
        </ion-row>

        <ion-row class="ion-justify-content-between">
          <ion-col [size]="6" style="padding-left: 0">
            <vasta-input
              [label]="'Peso Extra'"
              [type]="'text'"
              [(value)]="animal.pesagens.peso_1_valor"
              mask="separator.2"
              maskSuffix=" kg"
            >
            </vasta-input>
          </ion-col>
          <ion-col [size]="6" style="padding-right: 0">
            <vasta-input [label]="'Data Peso Extra'" type="date" [(value)]="animal.pesagens.peso_1_data"> </vasta-input>
          </ion-col>
        </ion-row>
        <ng-template [ngIf]="animal.tipo_entrada === 'aquisicao'">
          <ion-row class="ion-justify-content-between">
            <ion-col [size]="6" style="padding-left: 0">
              <vasta-input
                [label]="'Peso na Compra'"
                [type]="'text'"
                [(value)]="animal.pesagens.peso_compra"
                mask="separator.2"
                maskSuffix=" kg"
              ></vasta-input>
            </ion-col>

            <ion-col style="padding-right: 0">
              <vasta-input label="Data de Entrada" [type]="'date'" [(value)]="animal.meta_data_entrada"></vasta-input>
            </ion-col>
          </ion-row>
        </ng-template>
      </ng-template>

      <ng-template [ngIf]="animalProp">
        <vasta-input label="Data de Entrada na Fazenda" [type]="'date'" [(value)]="animal.meta_data_entrada"></vasta-input>
      </ng-template>


      <ion-row>
        <h2>Outros</h2>
      </ion-row>

      <vasta-input [label]="'Número Sisbov'" [(value)]="animal.meta_n_sisbov"></vasta-input>

      <vasta-input [label]="'Observação'" [type]="'textarea'" [(value)]="animal.observacao"></vasta-input>

      <vasta-input
        *ngIf="animalProp && animal.sexo === 'femea'"
        [label]="'Quantidade de Partos não Registrados'"
        [type]="'select'"
        [(value)]="animal.meta_partos"
        [opcoes]="[
          { label: '0', value: '0' },
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' },
          { label: '6', value: '6' },
          { label: '7', value: '7' },
          { label: '8', value: '8' },
          { label: '9', value: '9' },
          { label: '10', value: '10' }
        ]"
        [placeholder]="'Selecionar'"
      ></vasta-input>

      <vasta-input
        label="RGN (Registro Genealógico de Nascimento)"
        type="text"
        [(value)]="animal.meta_registroDeNascimento"
      >
      </vasta-input>
      <vasta-input label="Data do RGN" type="date" [(value)]="animal.meta_dataRegistroDeNascimento"> </vasta-input>
      <vasta-input label="RGD (Registro Genealógico Definitivo)" type="text" [(value)]="animal.meta_registroDefinitivo">
      </vasta-input>
      <vasta-input label="Data do RGD" type="date" [(value)]="animal.meta_dataRegistroDefinitivo"> </vasta-input>

      <vasta-input
        [label]="'Pelagem'"
        [type]="'select'"
        [(value)]="animal.meta_pelagem"
        [placeholder]="'Selecionar'"
        [opcoes]="pelagensDisponiveis"
      ></vasta-input>
    </div>
  </div>
</ion-content>
<ion-footer>
  <cta-buttons>
    <button
      class="primary permissao-escrita-animais"
      (click)="submit()"
      [disabled]="isLoading || (!animal.nome && !animal.numeracao) || !animal.raca || !animal.data_nascimento"
    >
      <ion-icon slot="start" name="checkmark" *ngIf="!isLoading"></ion-icon>
      <ion-spinner name="dots" *ngIf="isLoading"></ion-spinner>
      <span>Salvar</span>
    </button>
  </cta-buttons>
</ion-footer>
