import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { ToastController } from '@ionic/angular'

type PdfSrc = {
  base64: string,
  name: string,
}

@Component({
  selector: 'enviar-pdf',
  templateUrl: './enviar-pdf.component.html',
  styleUrls: ['./enviar-pdf.component.scss'],
})
export class EnviarPdfComponent implements OnInit {
  @Output() pdfSrc: EventEmitter<PdfSrc> = new EventEmitter()

  constructor(private toastCtrl: ToastController) { }

  ngOnInit(): void { }

  onFileChange(fileInput: Event): void {
    const target = fileInput.target as HTMLInputElement
    const file = target.files[0]

    if (!file) {
      return
    }

    if (file.size > 15 * 1024 * 1024) {
      const message = 'O tamanho do arquivo excede 15 MB'
      console.error(message)
      this.toastMessage(message)
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (): void => {
      const base64 = reader.result
      this.pdfSrc.emit({
        base64: base64 as string,
        name: file.name,
      })
    }
    reader.onerror = (error): void => {
      console.log('Error: ', error)
    }
  }

  clickInput(): void {
    document.getElementById('pdf').click()
  }

  async toastMessage(message: string): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'bottom',
    })
    toast.present()
  }
}
