import { Component, Input } from '@angular/core'
import { CategoriaDeItensBloqueados, PlanoBasicoService } from 'src/app/services/plano-basico.service'

@Component({
  selector: 'recurso-pro',
  templateUrl: './recurso-pro.component.html',
  styleUrls: ['./recurso-pro.component.scss']
})
export class RecursoProComponent {
  @Input('label') labelItem: string
  @Input('item') slugItem: string
  @Input('categoria') categoriaItem: CategoriaDeItensBloqueados
  @Input() exibirDescricao = true
  @Input() exibirIcone = true

  constructor(public planoBasicoCtrl: PlanoBasicoService) {}
}
