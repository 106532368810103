<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="primary"></ion-menu-button>
    </ion-buttons>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Manejos
    </ion-title>
    <ion-buttons slot="end">
      <ion-button disabled>
        <ion-icon name="help" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <header-tabs
    [tabs]="abasDisponiveis"
    [(tabActive)]="abaAtiva"
    (tabActiveChange)="tabChange($event)"
  ></header-tabs>
</ion-header>

<ion-content>
  <cta-buttons>
    <data-seletor
    label="Adicionar Manejo"
    icon="mais"
    class="permissao-escrita-manejos acao-cadastro"
    (selecionado)="novoManejo($event)"
    ></data-seletor>
    <button (click)="selecionarTipoDeManejoImportacao($event)" class="permissao-escrita-manejos acao-cadastro">
      <ion-icon name="cloud-upload"></ion-icon>
      <span>Importar</span>
    </button>
  </cta-buttons>

  <div [ngClass]="{ hidden: abaAtiva !== 'Dias com Manejos' }">
  <vasta-segment
    [tabs]="['Todos', 'Animais', 'Lotes', 'Áreas']"
    [(tabActive)]="tabFiltro"
    (tabActiveChange)="tabFiltroChange()"
  ></vasta-segment>

  <vasta-tabela
    [labels]="todosManejosLabels"
    [campos]="todosManejos"
    [config]="todosManejosConfig"
    [status]="carregamentoTodosManejos"
    [totais]="totaisManejos"
    [filtrosExtras]="filtrosExtras"
    [optionsButtons]="optionsButtonsSelecionadosTabela"
    [(paginate)]="paginateDiasComManejo"
    (paginateChange)="paginaAlterada()"
    [(novoFiltro)]="novoFiltro"
    (novoFiltroChange)="getTodosManejos()"
    (retentar)="getTodosManejos()"
    (selecionado)="novoManejo($event, true)"
  >
    <ng-template #innerComponents let-linha>
      <div class="container-resumo-manejos">
        <ng-template let-item ngFor [ngForOf]="linha.original.manejos">
        <div
          class="item-manejo {{manejosDisponiveis[item?.procedimento]?.cor || ''}}"
          (click)="novoManejo(linha, true)"
        >
          <ion-icon [name]="manejosDisponiveis[item?.procedimento]?.icon"></ion-icon>
          <span class="tipo-manejo">{{manejosDisponiveis[item?.procedimento]?.nome}}</span>
          <span class="qtd-animais">{{item.qtd_animais}}</span>
        </div>
        </ng-template>
      </div>
    </ng-template>
  </vasta-tabela>
  </div>

  <div [ngClass]="{ hidden: abaAtiva !== 'Todos os Manejos' }">
    
    <div class="segment-wrapper">
      <vasta-segment
        [tabs]="['Todos', 'Animais', 'Lotes', 'Áreas']"
        [(tabActive)]="tabFiltroListaTodosManejos"
        (tabActiveChange)="tabActiveChange()"
      ></vasta-segment>
    </div>

    <vasta-tabela
      [status]="carregamentoListaTodosManejos"
      [labels]="labels"
      [config]="config"
      [campos]="dadosListaTodosManejos"
      [optionsSelecionados]="optionsButtonsSelecionadosListaTodosManejos"
      [optionsButtons]="optionsButtonsTodosManejos"
      [(hashsSelecionados)]="hashsSelecionados"
      (hashsSelecionadosChange)="getHashsSelecionados($event)"
      [(novaOrdenacao)]="novaOrdenacaoTodos"
      [(novoFiltro)]="novoFiltroTodos"
      [filtrosExtras]="filtrosExtrasTodos"
      [opcoesFiltros]="opcoesFiltros"
      (novoFiltroChange)="getListaTodosManejos()"
      (selecionado)="selecionouManejo($event)"
      (retentar)="getListaTodosManejos()"
      [(paginate)]="paginateTodosManejos"
      (paginateChange)="getListaTodosManejos()"
    ></vasta-tabela>
  </div>
</ion-content>
