import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { EstoqueService } from 'src/app/services/estoque.service'
import { ColunasEstoqueFarmaciaLista } from 'src/app/utils/configuracoes-padrao'
import { ColunasTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'

@Component({
  selector: 'app-modal-estoque-sanitario',
  templateUrl: './modal-estoque-sanitario.component.html',
  styleUrls: ['./modal-estoque-sanitario.component.scss'],
})
export class ModalEstoqueSanitarioComponent implements OnInit {
  public labelsItemsSanitarios: ColunasTabela[] = ColunasEstoqueFarmaciaLista
  public itemsSanitarios = []
  public configEstoqueSanitario = {
    toolbar: false,
    filtrosEspecificos: true,
    selecao: {
      ativar: true
    },
    exibeTotais: false,
    excluirColunas: ['mais']
  }

  public carregamentoEstoqueSanitario: StatusTabela = 'carregando'

  public hash_not_in = []
  public multiplos = true
  public hashsSelecionados: string[] = []

  public novoFiltro: NovoFiltro = {}
  public novaOrdenacao: Ordenacao = { coluna: 'titulo', direcao: 'asc' }
  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  constructor(private modalCtrl: ModalController, private estoqueCtrl: EstoqueService) { }

  ngOnInit(): void {
    if (this.multiplos) {
      this.labelsItemsSanitarios = [
        {
          tipo: 'checkbox'
        },
        ...ColunasEstoqueFarmaciaLista
      ]
    }
    this.carregarDadosEstoqueSanitario()
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  selecionarItemSanitario({ original }: DadosLinhaTabela): void {
    if (this.hashsSelecionados.length) return
    this.modalCtrl.dismiss(original)
  }

  async carregarDadosEstoqueSanitario(): Promise<void> {
    this.carregamentoEstoqueSanitario = 'carregando'

    const [response, error] = await this.estoqueCtrl.getEstoqueFarmacia({
      filtros: {
        hash_not_in: this.hash_not_in,
        ...this.novoFiltro
      },
      paginate: this.paginate,
      ordenacao: this.novaOrdenacao
    })

    if (response) {
      this.itemsSanitarios = response.estoques
      this.carregamentoEstoqueSanitario = 'sucesso'
      this.paginate.total = Number(response.totais.qtd)
    }

    if (error) {
      console.log({ error })
      this.carregamentoEstoqueSanitario = 'erro'
    }
  }

  confirmarItens(): void {
    const itensSelecionados = this.itemsSanitarios.filter((item) => this.hashsSelecionados.includes(item.hash))
    this.modalCtrl.dismiss({ itensSelecionados })
  }
}
