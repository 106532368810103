import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'

@Component({
  selector: 'header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class HeaderTabsComponent implements OnInit {
  @Input('tabs') tabs: string[]
  @Input('disabledTabs') disabledTabs: string[] = []
  @Input('tabActive') tabActive: string
  @Output() tabActiveChange: EventEmitter<string> = new EventEmitter()

  constructor(public planoBasicoCtrl: PlanoBasicoService) {}

  ngOnInit(): void {
    this.tabActive = this.tabActive || this.tabs[0]
  }

  async selectTab(tab: string, ev: Event): Promise<void> {
    const { acessoAoItemBloqueado } = await this.planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('tabs', tab, ev)
    if(acessoAoItemBloqueado) return

    this.tabActive = tab
    this.tabActiveChange.emit(this.tabActive)
  }
}
