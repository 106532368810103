<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados?.edited">
    <div class="manejo-nome">Altura de<br/> Capim</div>

    <div class="piquetes-container">
      <ng-template ngFor let-piqueteHash [ngForOf]="piquetes">
        <ng-template [ngIf]="piquetesAssociados[piqueteHash]?.piquete_selecionado_altura_capim">
        <button class="item-piquete" [class.edited]="dados[piqueteHash]?.edited">
          <div class="labels">
            <div class="icon">
              <ion-icon name="resize-outline"></ion-icon>
            </div>
            <div class="info">
              <small>Piquete</small>
              <span [title]="piquetesAssociados[piqueteHash]?.nome">{{ piquetesAssociados[piqueteHash]?.nome }}</span>
            </div>
          </div>
          <div class="inputs">
            <vasta-input
              type="manejo-number"
              label="Altura do capim"
              placeholder="0 cm"
              mask="separator.2"
              maskSuffix=" cm"
              [(value)]="dados[piqueteHash].altura_capim"
              (valueChange)="emitChange(dados[piqueteHash])"
            ></vasta-input>
          </div>
          <ion-icon name="ellipsis-vertical" (click)="showPopoverOpcaoItemManejoArea($event, dados[piqueteHash])"></ion-icon>
        </button>
      </ng-template>
    </ng-template>
        <button *ngIf="piquetes.length > 0" class="manejo-btn add" (click)="showPopoverPiquetes($event)">
        <div class="icon">
          <ion-icon name="mais"></ion-icon>
        </div>
        <span>Adicionar piquete</span>
      </button>
      <div *ngIf="piquetes.length == 0" >
        <span class="info-area">Nenhum piquete registrado nessa área</span>
      </div>
    </div>
    <div class="container-acoes">
      <!-- <button (click)="adicionarObservacao()" [disabled]="!dados.nutricional_hash_estoque">
        <ion-icon name="chatbox"></ion-icon>
      </button> -->
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>

