<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="primary"></ion-menu-button>
    </ion-buttons>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Lotes Bovinos
    </ion-title>
    <ion-buttons slot="end">
      <ion-button>
        <ion-icon name="help" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <cta-buttons style="margin-top: 0">
    <button class="primary permissao-escrita-lotes acao-cadastro" (click)="openModalLote()">
      <ion-icon name="mais"></ion-icon>
      <span>Novo Lote</span>
    </button>
  </cta-buttons>
  <kpis-cards [cardsInfo]="optionsKpisCard" [valores]="totais"></kpis-cards>
  <vasta-tabela
    [status]="carregamentoLotes"
    [labels]="labels"
    [config]="config"
    [campos]="lotes"
    [optionsButtons]="optionsButtonsLotes"
    [(novoFiltro)]="novoFiltro"
    [(novaOrdenacao)]="novaOrdenacao"
    (novoFiltroChange)="getLotes()"
    (retentar)="getLotes()"
    (selecionado)="irParaPaginaLoteSingle($event.original)"
  ></vasta-tabela>
</ion-content>
