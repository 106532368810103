import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertController, ModalController, NavController } from '@ionic/angular'
import { IOptionButtons } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { AreasService } from 'src/app/services/areas.service'
import { LotesService } from 'src/app/services/lotes.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import {
  ColunasAreasPiquetes,
  ColunasLotes,
  ColunasMovimentacoes,
  ColunasPastejosRotacionados
} from 'src/app/utils/configuracoes-padrao'
import { IArea, IAreaPiquete } from 'src/app/utils/interfaces/areas.interface'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { ConfigTabela, IKpisCard } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalListaLotesPage } from '../../bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { ModalEdicaoLoteComponent } from '../../lotes/modal-edicao-lote/modal-edicao-lote.component'
import { ModalCadastrarAreaComponent } from '../modal-cadastrar-area/modal-cadastrar-area.component'
import { ModalListaAreasPage } from '../modal-lista-areas/modal-lista-areas.page'
import { ModalCadastrarPiqueteComponent } from '../modal-cadastrar-piquete/modal-cadastrar-piquete.component'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { ImportarKMLPage } from '../importar-kml/importar-kml.page'
import { IManejoAreaPastejoRotacionado } from 'src/app/utils/interfaces/manejos.interface'
import { ManejosService } from 'src/app/services/manejos.service'

@Component({
  selector: 'area-single',
  templateUrl: './area-single.page.html',
  styleUrls: ['./area-single.page.scss']
})
export class AreaSinglePage implements OnInit {
  public abaAtiva = 'Lotes'
  public area: IArea = {}
  public labels = ColunasLotes
  public novoFiltroLotes: NovoFiltro = { meta_situacao: 'ativo' }
  public novaOrdenacaoLotes: Ordenacao = { coluna: 'qtd_animais', direcao: 'desc' }
  maximize = false

  config: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    impressaoTitulo: 'Área',
    buscaDados: async () => {
      const [response, error] = await this.lotesService.getLotes({
        filtros: this.novoFiltroLotes,
        paginate: { offset: 0, limit: 5000 }
      })
      return { dados: response.dados }
    },
    orderby: 'qtd_animais',
    orderbyDirection: 'asc',
    ativarDownload: true,
    ativarImpressao: true,
    exibeTotais: false
  }

  public optionsButtonsTabela: IOptionButtons[] = [
    {
      chave: 'editarLote',
      icone: 'pencil',
      titulo: 'Editar lote',
      classe: 'permissao-escrita-areas',
      acao: (linha: DadosLinhaTabela): void => {
        this.openModalEditarLote(linha.original)
      }
    },
    {
      chave: 'removerLote',
      icone: 'close',
      titulo: 'Remover lote da área',
      classe: 'permissao-escrita-areas',
      acao: (linha: DadosLinhaTabela): void => {
        this.removerLoteDaArea(linha.original)
      }
    },
    {
      chave: 'moverLote',
      icone: 'arrow-redo',
      titulo: 'Mover lote para outra área',
      classe: 'permissao-escrita-areas',
      acao: (linha: DadosLinhaTabela): void => {
        this.moverLoteParaArea(linha.original)
      }
    }
  ]

  public carregamentoLotes: StatusTabela = 'carregando'
  public lotes: ILote[]

  public optionsKpisCard: IKpisCard[] = [
    { key: 'lotes_qtd', titulo: 'Total de Lotes', valor: 0, unidade: '', formatacao: 'number' },
    {
      key: 'total_animais',
      titulo: 'Total de Animais',
      valor: 0,
      formatacao: 'number'
    },
    { key: 'tamanho', titulo: 'Tamanho total', valor: 0, unidade: 'ha', formatacao: 'number' },
    { key: 'max_lotacao', titulo: 'Lotação máxima', valor: 0, unidade: 'UA', formatacao: 'number' },
    { key: 'total_peso', titulo: 'Peso total', valor: 0, unidade: 'kg', formatacao: 'number' },
    { key: 'tx_ocupacao', titulo: 'Taxa de ocupação', valor: 0, unidade: '%', formatacao: 'number' },
    { key: 'ua', titulo: 'Unidades animais', valor: 0, unidade: 'UA', formatacao: 'number' },
    { key: 'tx_lotacao', titulo: 'Taxa de lotação', valor: 0, unidade: 'UA/ha', formatacao: 'number' }
  ]
  public kpisValores: { [key: string]: number | unknown } = {
    tamanho: 0,
    max_lotacao: 0,
    total_peso: 0,
    tx_ocupacao: 0,
    ua: 0,
    tx_lotacao: 0,
    total_animais: 0
  }

  // tabela historico
  public configTabelaHistorico = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    impressaoTitulo: 'Histórico',
    ocultarFiltros: true
  }
  public labelsHistorico = ColunasMovimentacoes
  public historicoAreas = []
  public carregamentoHistoricoAreas: StatusTabela = 'carregando'

  public hashArea: string = null

  public carregamentoPiquetes: StatusTabela = 'carregando'
  public piquetes: IAreaPiquete[] = []

  public areaMapa: IArea[] = []
  public piquetesPaginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  public paginateHistorico: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  public piquetesNovoFiltro: NovoFiltro = {}
  public piquetesNovaOrdenacao: Ordenacao = { coluna: 'nome', direcao: 'desc' }

  public piquetesConfig: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    orderby: 'nome',
    orderbyDirection: 'desc',
    impressaoTitulo: 'Áreas',
    ativarImpressao: true,
    ativarDownload: true,
    exibeTotais: false
  }

  public piquetesOptionsButtonsTabela: IOptionButtons[] = [
    {
      chave: 'editarPiquete',
      icone: 'pencil',
      titulo: 'Editar Piquete',
      classe: 'permissao-escrita-areas',
      acao: (linha: DadosLinhaTabela): void => {
        this.openModalCadastrarPiquete(linha.original)
      }
    },
    {
      chave: 'removerPiquete',
      icone: 'trash',
      titulo: 'Remover Piquete',
      classe: 'permissao-escrita-areas',
      acao: (linha: DadosLinhaTabela): void => {
        this.alertRemoverPiquete(linha.original)
      }
    }
  ]

  public piquetesLabels = ColunasAreasPiquetes

  public manejosPastejoRotacionadoLabels = ColunasPastejosRotacionados
  public manejosPastejoRotacionadoFiltro: NovoFiltro = {}
  public manejosPastejoRotacionadoNovaOrdenacao: Ordenacao = { coluna: 'data', direcao: 'desc' }
  public manejosPastejoRotacionadoCarregamento: StatusTabela = 'carregando'
  public manejosPastejoRotacionado: IManejoAreaPastejoRotacionado[] = []
  public manejosPastejosRotacionadosConfig: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    buscaDados: async () => {
      const [response, error] = await this.manejosService.getManejosPastejoRotacionado({
        filtros: this.manejosPastejoRotacionadoFiltro,
        ordenacao: this.manejosPastejoRotacionadoNovaOrdenacao,
        paginate: { offset: 0, limit: 5000 }
      })
      return { dados: response.manejos }
    },
    orderby: 'data',
    orderbyDirection: 'desc',
    impressaoTitulo: 'Movimentações entre piquetes',
    ativarImpressao: true,
    ativarDownload: true,
    exibeTotais: false,
    excluirColunas: ['nome_area']
  }

  public manejosPastejoRotacionadoPaginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  constructor(
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private areasService: AreasService,
    private lotesService: LotesService,
    private manejosService: ManejosService,
    private route: ActivatedRoute,
    private router: Router,
    private navCtrl: NavController
  ) {}

  async ngOnInit(): Promise<void> {
    this.hashArea = this.route.snapshot.paramMap.get('hash')
    const state = this.router.getCurrentNavigation().extras.state as IArea
    if (state) {
      this.area = state
    } else {
      if (this.hashArea) {
        this.area = await this.getArea(this.hashArea)
      } else {
        this.router.navigateByUrl('/areas')
      }
    }

    if (this.area) {
      this.config.impressaoTitulo = `Área ${this.area.nome}`
      this.novoFiltroLotes['hash_area'] = this.area.hash || this.hashArea
    }

    this.route.url.subscribe(() => {
      if (this.abaAtiva === 'Pastejo Rotacionado') {
        this.getManejosPastejoRotacionado()
      }
    })
  }

  async ionViewDidEnter(): Promise<void> {
    this.area = await this.getArea(this.hashArea)

    this.novoFiltroLotes['hash_area'] = this.novoFiltroLotes['hash_area'] || this.area.hash || this.hashArea

    this.getLotes()
  }

  async getArea(hash: string): Promise<IArea> {
    const [response, error] = await this.areasService.getArea(hash)

    if (response) {
      const [area] = response.dados
      this.config.impressaoKpiCardConfig = this.optionsKpisCard

      const areaSingleKpiValores = {
        ...area,
        ...response.totais
      }
      this.kpisValores = areaSingleKpiValores
      this.config.impressaoKpiValores = areaSingleKpiValores

      this.getPiquetes()

      return area
    }

    if (error) {
      console.error(error)
    }
  }

  async openModalCadastrarArea(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCadastrarAreaComponent,
      cssClass: 'custom-modal',
      componentProps: {
        propArea: this.area
      }
    })
    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      this.atualizaInfoArea()

      if (data.areaDeletada) {
        this.navCtrl.pop()
      }
    }
  }

  trocarAba(aba: string): void {
    this.abaAtiva = aba
  }

  async getLotes(): Promise<void> {
    this.carregamentoLotes = 'carregando'
    this.utilsCtrl.tick()

    const [response, error] = await this.lotesService.getLotes({ filtros: this.novoFiltroLotes })

    if (response) {
      this.lotes = response.dados
      this.carregamentoLotes = 'sucesso'
    }

    if (error) {
      this.carregamentoLotes = 'erro'
      console.log('error: ', error)
    }
    this.utilsCtrl.tick()
  }

  irParaPaginaLoteSingle(dadosLote: ILote): void {
    const state = dadosLote.hash ? dadosLote : null

    this.router.navigate([`areas/lotes/${encodeURIComponent(dadosLote.hash)}`], { state: { dadosLote: state } })
  }

  async associarLoteAArea(area: IArea): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaLotesPage,
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (!data?.lotes) return

    const [lote] = data?.lotes

    if (lote.hash_area == area.hash) {
      return this.utilsCtrl.showToast('O lote já está na área selecionada', 'bottom')
    }

    const [sucesso, error] = await this.lotesService.moverLoteParaArea({
      lote,
      hash_area: area.hash
    })

    if (sucesso) {
      this.utilsCtrl.showToast('Lote adicionado à área com sucesso', 'bottom')
      await this.getLotes()
    }

    if (error) {
      this.utilsCtrl.showToast('Erro ao adicionar lote à área', 'bottom')
    }
  }

  async openModalEditarLote(loteParams?: ILote): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEdicaoLoteComponent,
      componentProps: { loteParams },
      cssClass: 'custom-modal',
      backdropDismiss: false
    })

    await modal.present()
    await modal.onWillDismiss()
    this.getLotes()
  }

  async moverLoteParaArea(lote: ILote): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAreasPage,
      cssClass: 'custom-modal-seletor'
    })

    await modal.present()

    let { data } = await modal.onDidDismiss()

    if (!data) return

    const [area] = data.areas

    if (this.area.hash == area.hash) {
      return this.utilsCtrl.showToast('Selecione uma área diferente da atual', 'bottom')
    }

    const alert = await this.alertCtrl.create({
      header: `Mover lote para área ${area.nome}?`,
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Sim',
          handler: async (): Promise<void> => {
            this.moverLote(lote, area.hash)
          }
        }
      ]
    })

    await alert.present()
  }

  async removerLoteDaArea(lote: ILote): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Remover lote desta área?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Sim',
          handler: async (): Promise<void> => {
            this.moverLote(lote, '')
          }
        }
      ]
    })

    await alert.present()
  }

  async moverLote(lote: ILote, hash?: string): Promise<void> {
    const [sucesso, error] = await this.lotesService.moverLoteParaArea({
      lote,
      hash_area: hash
    })

    if (sucesso) {
      this.utilsCtrl.showToast(`Lote ${hash ? 'movido' : 'removido'} da área com sucesso`, 'bottom')
    }

    if (error) {
      this.utilsCtrl.showToast(`Erro ao ${hash ? 'mover' : 'remover'} lote da área`, 'bottom')
    }

    await this.atualizaInfoArea()
    this.getLotes()
  }

  async atualizaInfoArea(): Promise<void> {
    const [response, error] = await this.areasService.getAreas({
      filtros: {
        hash: this.area.hash
      }
    })

    if (response) {
      this.area = response.dados[0]
      // this.insereValoresCards()
    }

    if (error) {
      console.error(error)
    }
  }

  async getHistoricoAreas(): Promise<void> {
    this.carregamentoHistoricoAreas = 'carregando'
    this.utilsCtrl.tick()
    const [response, error] = await this.areasService.getHistorico({
      filtros: { hashArea: this.area.hash },
      paginate: this.paginateHistorico
    })

    if (response) {
      this.paginateHistorico.total = response.totais.total_logs
      this.historicoAreas = response.dados
      this.carregamentoHistoricoAreas = 'sucesso'
      console.log({ response })
    }

    if (error) {
      this.carregamentoHistoricoAreas = 'erro'
      console.error(error)
    }

    this.utilsCtrl.tick()
  }

  async tabChange(): Promise<void> {
    if (this.abaAtiva == 'Histórico') this.getHistoricoAreas()

    if (this.abaAtiva == 'Pastejo Rotacionado') await this.getManejosPastejoRotacionado()
  }

  async getPiquetes(): Promise<void> {
    this.carregamentoPiquetes = 'carregando'
    this.utilsCtrl.tick()
    const [response, error] = await this.areasService.getPiquetes({
      filtros: { hashs_areas: this.hashArea || this.area.hash },
      paginate: this.piquetesPaginate
    })

    if (response) {
      this.piquetes = response.piquetes
      this.carregamentoPiquetes = 'sucesso'
    }

    if (error) {
      this.carregamentoPiquetes = 'erro'
      console.log('error:', error)
    }
  }

  async openModalCadastrarPiquete(propPiquete?: IAreaPiquete): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCadastrarPiqueteComponent,
      componentProps: {
        propPiquete,
        area: this.area,
        hashAreaSelecionada: this.area.hash,
        qtdPiquetes: this.piquetes.length + 1
      },
      cssClass: 'custom-modal'
    })

    await modal.present()
    await modal.onWillDismiss()
    await this.getPiquetes()
  }

  async alertRemoverPiquete(piquete: IAreaPiquete): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Deseja realmente remover este piquete?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Remover',
          handler: (): void => {
            this.deletarPiquete(piquete)
          }
        }
      ]
    })

    await alert.present()
  }

  async deletarPiquete(piquete: IAreaPiquete): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('dots')

    const [response, error] = await this.areasService.deletarPiquete(piquete.hash)

    if (response) {
      this.utilsCtrl.showToast('Área deletada', 'bottom')
      await this.getPiquetes()
    }

    if (error) {
      console.error(error)
    }

    this.utilsCtrl.dismissLoading(loading)
  }

  async abrirImportacaoKML(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ImportarKMLPage,
      cssClass: 'modal-fullScreen',
      componentProps: {
        hashArea: this.area.hash
      }
    })
    modal.present()
    await modal.onWillDismiss()
    this.getPiquetes()
  }

  async getManejosPastejoRotacionado(): Promise<void> {
    this.manejosPastejoRotacionadoCarregamento = 'carregando'
    this.utilsCtrl.tick()

    this.manejosPastejoRotacionadoFiltro['hash_area'] = this.area.hash

    const [response, error] = await this.manejosService.getManejosPastejoRotacionado({
      filtros: this.manejosPastejoRotacionadoFiltro,
      ordenacao: this.manejosPastejoRotacionadoNovaOrdenacao,
      paginate: this.manejosPastejoRotacionadoPaginate
    })

    if (response) {
      this.manejosPastejoRotacionadoPaginate.total = response.totais.total
      this.manejosPastejoRotacionado = response.manejos
      this.manejosPastejoRotacionadoCarregamento = 'sucesso'
    }

    if (error) {
      this.manejosPastejoRotacionadoCarregamento = 'erro'
      console.log('error: ', error)
    }
    this.utilsCtrl.tick()
  }

  abrirManejosDoDia(dados: DadosLinhaTabela): void {
    const data = dados.original.data
    const { procedimento } = dados?.original || {}
    this.router.navigateByUrl(`/manejos/${data}`, {
      state: {
        data,
        abaAtivaParams: procedimento === 'movimentacao-entre-areas' ? 'Lotes' : 'Áreas'
      }
    })
  }
}
