import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { ManejoOrdenha } from 'src/app/services/manejos.service'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-ordenha',
  templateUrl: './manejo-ordenha.component.html',
  styleUrls: ['./manejo-ordenha.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManejoOrdenhaComponent implements OnInit {
  @Input('dados') dados: ManejoOrdenha = {}
  @Input('ordenhaAnterior') ordenhaAnterior: { quantidade: number; data: string }
  @Input('data') data: string
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()
  @Input('ultimaOrdenha') ultimaOrdenha: string
  @Input('ultimaOrdenhaData') ultimaOrdenhaData: string
  variacao = 0
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.calculaVariacao()
    }, 1000)
  }

  emit(): void {
    this.calculaVariacao()

    this.dadosChange.emit(this.dados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  calculaVariacao(): void {
    const { primeira_ordenha: primeira, segunda_ordenha: segunda, terceira_ordenha: terceira } = this.dados
    const total = Number(primeira || '0') + Number(segunda || '0') + Number(terceira || '0')

    if (!this.ultimaOrdenha || !total) {
      this.variacao = 0
      return
    }
    this.variacao = total - Number(this.ultimaOrdenha)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.primeira_ordenha = null
      this.dados.segunda_ordenha = null
      this.dados.terceira_ordenha = null
      this.emit()
    }
  }
}
