import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'

@Component({
  selector: 'enviar-imagem',
  templateUrl: './enviar-imagem.component.html',
  styleUrls: ['./enviar-imagem.component.scss']
})
export class EnviarImagemComponent implements OnInit {
  @ViewChild('campoImagem') campoImagem: ElementRef<HTMLInputElement>
  @Input('avatar') avatar: boolean = false
  @Output() imagemSrc: EventEmitter<string> = new EventEmitter()
  @Input('trigger') trigger: boolean = false

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.trigger?.currentValue) {
      this.selecionarImagem()
    }
  }

  async selecionarImagem(): Promise<void> {
    const image = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
      webUseInput: true,
      quality: 90
    })

    const imageBase64 = 'data:image/png;base64,' + image.base64String

    const imagemComprimida = await this.comprimir(imageBase64)
    this.imagemSrc.emit(imagemComprimida)
  }

  async comprimir(imagemAComprimir: string): Promise<string> {
    return new Promise((done) => {
      let imagemOtimizada = null
      const image = new Image()

      image.src = imagemAComprimir
      image.onload = (): void => {
        const canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d')
        canvas.width = image.naturalWidth
        canvas.height = image.naturalHeight

        if (canvas.width > 1080 && canvas.width > canvas.height) {
          console.log('width bigger:', canvas.width, canvas.height)

          let proporcao = (1080 * 100) / canvas.width / 100
          canvas.width = Math.round(image.naturalWidth * proporcao)
          canvas.height = Math.round(image.naturalHeight * proporcao)
        } else if (canvas.height > 1080 && canvas.height > canvas.width) {
          console.log('height bigger:', canvas.height, canvas.width)
          let proporcao = (1080 * 100) / canvas.height / 100
          canvas.height = Math.round(image.naturalHeight * proporcao)
          canvas.width = Math.round(image.naturalWidth * proporcao)
        }

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
        imagemOtimizada = canvas.toDataURL('image/jpeg', 0.5)
        done(imagemOtimizada)
      }
    })
  }
}
