<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Selecionar Manejos
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModalManejosDisponiveis()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <header-tabs [tabs]="['Animais', 'Lotes', 'Áreas']" [(tabActive)]="exibicaoAtiva"></header-tabs>
</ion-header>
  <ion-content>
    <div *ngIf="isManejosCampo" style="padding-top: 10px;">
      <vasta-input *ngIf="exibicaoAtiva === 'Animais'"
        [label]="manejosSelecionadosAnimais.length ? 'Desmarcar ' + manejosSelecionadosAnimais.length : 'Selecionar todos'"
        type="checkbox"
        [valueCheckbox]="!!manejosSelecionadosAnimais.length"
        (valueCheckboxChange)="selecionarTodos($event)"
      ></vasta-input>

      <vasta-input *ngIf="exibicaoAtiva === 'Lotes'"
        [label]="manejosSelecionadosLotes.length ? 'Desmarcar ' + manejosSelecionadosLotes.length : 'Selecionar todos'"
        type="checkbox"
        [valueCheckbox]="!!manejosSelecionadosLotes.length"
        (valueCheckboxChange)="selecionarTodos($event)"
      ></vasta-input>

      <vasta-input *ngIf="exibicaoAtiva === 'Áreas'"
        [label]="manejosSelecionadosAreas.length ? 'Desmarcar ' + manejosSelecionadosAreas.length : 'Selecionar todos'"
        type="checkbox"
        [valueCheckbox]="!!manejosSelecionadosAreas.length"
        (valueCheckboxChange)="selecionarTodos($event)"
      ></vasta-input>
    </div>
  <main>
    <ng-container *ngIf="exibicaoAtiva === 'Animais'">
      <div
        class="container-manejo"
        [ngClass]="{ desabilitado: tipoManejoSelecionado === 'sanitario' || tipoManejoSelecionado === 'reprodutivo' }"
      >
        <h6>Manejos</h6>
        <div class="lista-tipos-manejos">
          <ng-template let-item ngFor [ngForOf]="listaManejos">
            <button
              class="tipo-manejo {{ item.cor || '' }}"
              (click)="selecionarTipoManejo(item)"
              *ngIf="item.nome !== 'Venda'"
              [ngClass]="{ indisponivel: verificarFiltrosAtivos(item), active: item.classActive }"
              [class.hidden]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
              >
              <ion-icon [name]="item.icon"></ion-icon>
              <span>{{ item.nome }}</span>
            </button>
          </ng-template>
        </div>
      </div>
      <div
        class="container-manejo"
        [ngClass]="{ desabilitado: tipoManejoSelecionado === 'sanitario' || tipoManejoSelecionado === 'outros' }"
      >
        <h6>Manejos Reprodutivos</h6>
        <div class="lista-tipos-manejos">
            <button
              class="tipo-manejo {{ item.cor || '' }}"
              *ngFor="let item of listaManejosReprodutivos"
              (click)="selecionarTipoManejo(item)"
              [ngClass]="{ indisponivel: verificarFiltrosAtivos(item), active: item.classActive }"
              [class.hidden]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
            >
              <ion-icon [name]="item.icon"></ion-icon>
              <span>{{ item.nome }}</span>
            </button>
        </div>
      </div>
      <div
        class="container-manejo"
        [ngClass]="{ desabilitado: tipoManejoSelecionado === 'outros' || tipoManejoSelecionado === 'reprodutivo' }"
      >
        <h6>Manejos Sanitários</h6>
        <div class="lista-tipos-manejos">
          <button
            class="tipo-manejo {{ item.cor || '' }}"
            *ngFor="let item of listaManejosSanitarios"
            (click)="selecionarTipoManejo(item, $event)"
            [ngClass]="{ indisponivel: verificarFiltrosAtivos(item), active: (item.tipo == 'sanitarioEstoque' || isManejosCampo) && item.classActive }"
            [class.hidden]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
          >
            <ion-icon [name]="item.icon"></ion-icon>
            <span>
              {{ item.nome }}
              <ion-badge color="medium" *ngIf="item.qtd">{{ item.qtd }}</ion-badge>
            </span>
          </button>
        </div>
      </div>
      <ng-template
        [ngIf]="habilitaManejosGenetica && listaManejosGenetica.length"
      >
      <div
        class="container-manejo"
        [ngClass]="{ desabilitado: tipoManejoSelecionado === 'outros' || tipoManejoSelecionado === 'reprodutivo' }"
      >
          <h6>Manejos Genéticos</h6>
          <div class="lista-tipos-manejos">
            <button
              class="tipo-manejo {{ item.cor || '' }}"
              *ngFor="let item of listaManejosGenetica"
              (click)="selecionarTipoManejo(item, $event)"
              [ngClass]="{ indisponivel: verificarFiltrosAtivos(item) || !habilitaManejosGenetica, active: item.tipo == 'genetica' && item.classActive }"
              [class.hidden]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
            >
              <ion-icon [name]="item.icon"></ion-icon>
              <span>
                {{ item.nome }}
                <ion-badge color="medium" *ngIf="item.qtd">{{ item.qtd }}</ion-badge>
              </span>
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="exibicaoAtiva === 'Lotes'">
      <div class="container-manejo">
        <h6>Manejos de Lotes</h6>
        <div class="lista-tipos-manejos">
          <ng-template let-item ngFor [ngForOf]="listaManejosLotes">
            <button
              class="tipo-manejo {{ item.cor || '' }}"
              (click)="selecionarTipoManejo(item)"
              [ngClass]="{ indisponivel: verificarFiltrosAtivos(item), active: item.classActive }"
              [class.hidden]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
            >
              <ion-icon [name]="item.icon"> </ion-icon>
              <span>{{ item.nome }}</span>
            </button>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="exibicaoAtiva === 'Áreas'">
      <div class="container-manejo">
        <h6>Manejos de Áreas</h6>
        <div class="lista-tipos-manejos">
          <ng-template let-item ngFor [ngForOf]="listaManejosAreas">
            <button
              class="tipo-manejo {{ item.cor || '' }}"
              (click)="selecionarTipoManejo(item)"
              [ngClass]="{ indisponivel: verificarFiltrosAtivos(item), active: item.classActive }"
              [class.hidden]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
            >
              <ion-icon [name]="item.icon"> </ion-icon>
              <span>{{ item.nome }}</span>
            </button>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </main>
  <span *ngIf="isAlgumManejoAreaBloqueadoEmPlanoBasico" class="separador-fertili-pro item-premium">
    Recursos do Plano Premium
    <cta-buttons class="ion-float-right">
      <button class="primary" (click)="planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('cta', 'manejos', $event)">
        <span>Saiba Mais</span>
        <ion-icon name="diamond"></ion-icon>
      </button>
    </cta-buttons>
  </span>
  <main class="item-bloqueado-plano-basico" *ngIf="isAlgumManejoAreaBloqueadoEmPlanoBasico">
    <ng-container *ngIf="exibicaoAtiva === 'Animais'">
      <div
        class="container-manejo"
        [ngClass]="{ desabilitado: tipoManejoSelecionado === 'sanitario' || tipoManejoSelecionado === 'reprodutivo' }"
      >
        <h6>Manejos</h6>
        <div class="lista-tipos-manejos">
          <ng-template let-item ngFor [ngForOf]="listaManejos">
            <button
              class="tipo-manejo"
              (click)="selecionarTipoManejo(item)"
              *ngIf="item.nome !== 'Venda'"
              [ngClass]="{ indisponivel: verificarFiltrosAtivos(item), active: item.classActive }"
              [class.hidden]="!planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
              >
              <ion-icon [name]="item.icon"></ion-icon>
              <span>{{ item.nome }}</span>
            </button>
          </ng-template>
        </div>
      </div>
      <div
        class="container-manejo"
        [ngClass]="{ desabilitado: tipoManejoSelecionado === 'sanitario' || tipoManejoSelecionado === 'outros' }"
      >
        <h6>Manejos Reprodutivos</h6>
        <div class="lista-tipos-manejos">
            <button
              class="tipo-manejo"
              *ngFor="let item of listaManejosReprodutivos"
              (click)="selecionarTipoManejo(item)"
              [ngClass]="{ indisponivel: verificarFiltrosAtivos(item), active: item.classActive }"
              [class.hidden]="!planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
            >
              <ion-icon [name]="item.icon"></ion-icon>
              <span>{{ item.nome }}</span>
            </button>
        </div>
      </div>
      <div
        class="container-manejo"
        [ngClass]="{ desabilitado: tipoManejoSelecionado === 'outros' || tipoManejoSelecionado === 'reprodutivo' }"
      >
        <h6>Manejos Sanitários</h6>
        <div class="lista-tipos-manejos">
          <button
            class="tipo-manejo"
            *ngFor="let item of listaManejosSanitarios"
            (click)="selecionarTipoManejo(item, $event)"
            [ngClass]="{ indisponivel: verificarFiltrosAtivos(item) }"
            [class.hidden]="!planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
          >
            <ion-icon [name]="item.icon"></ion-icon>
            <span>
              {{ item.nome }}
              <ion-badge color="medium" *ngIf="item.qtd">{{ item.qtd }}</ion-badge>
            </span>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="exibicaoAtiva === 'Lotes'">
      <div class="container-manejo">
        <h6>Manejos de Lotes</h6>
        <div class="lista-tipos-manejos">
          <ng-template let-item ngFor [ngForOf]="listaManejosLotes">
            <button
              class="tipo-manejo"
              (click)="selecionarTipoManejo(item)"
              [ngClass]="{ indisponivel: verificarFiltrosAtivos(item), active: item.classActive }"
              [class.hidden]="!planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
            >
              <ion-icon [name]="item.icon"> </ion-icon>
              <span>{{ item.nome }}</span>
            </button>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="exibicaoAtiva === 'Áreas'">
      <div class="container-manejo">
        <h6>Manejos de Áreas</h6>
        <div class="lista-tipos-manejos">
          <ng-template let-item ngFor [ngForOf]="listaManejosAreas">
            <button
              class="tipo-manejo"
              (click)="selecionarTipoManejo(item)"
              [ngClass]="{ indisponivel: verificarFiltrosAtivos(item), active: item.classActive }"
              [class.hidden]="!planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', item.identificador)"
            >
              <ion-icon [name]="item.icon"> </ion-icon>
              <span>{{ item.nome }}</span>
            </button>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </main>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <cta-buttons class="ion-float-right">
      <button class="primary" (click)="confirmarSelecao()" [disabled]="!manejosSelecionadosAnimais.length && !manejosSelecionadosLotes.length && !manejosSelecionadosAreas.length">
        <ion-icon name="checkmark-outline"></ion-icon>
        <span>Confirmar</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
