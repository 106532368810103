<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <div class="dados-usuario">
        <h1 (click)="plusConfig()">{{usuarioCtrl?.usuarioCache?.nome || 'Nome'}}</h1>
        <h3 *ngIf="usuarioCtrl?.usuarioCache?.email">{{usuarioCtrl?.usuarioCache?.email}}</h3>
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>

  <ion-item-divider>
    <ion-label>Geral</ion-label>
  </ion-item-divider>
  <ion-item button (click)="abrirModalAssinatura()" lines="none">
    <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
    <ion-label>Meu Plano</ion-label>
  </ion-item>

  <ion-item button (click)="abrirAtualizacoes()" lines="none">
    <ion-icon name="download" class="ion-margin-end"></ion-icon>
    <ion-label>Últimas Atualizações</ion-label>
  </ion-item>

  <ion-item button (click)="openModalNotificacoes()" lines="none">
    <ion-icon name="notifications" class="ion-margin-end"></ion-icon>
    <ion-label>Notificações</ion-label>
  </ion-item>

  <!-- <ion-item button disabled>
    <ion-icon name="person" class="ion-margin-end"></ion-icon>
    <ion-label>Conta</ion-label>
  </ion-item> -->
  <ion-item button (click)="openLinkWhatsapp()" lines="none">
    <ion-icon name="logo-whatsapp" class="ion-margin-end"></ion-icon>
    <ion-label>Fale Conosco</ion-label>
  </ion-item>
          
  <ion-item button (click)="alertConfirmLogout()" lines="none">
    <ion-icon name="exit" class="ion-margin-end"></ion-icon>
    <ion-label>Sair da Conta</ion-label>
  </ion-item>

  <p>&nbsp;</p>
  <p>&nbsp;</p>
          
  <ion-item button (click)="abrirTermosDeUso()" lines="none">
    <ion-icon name="book-outline" class="ion-margin-end"></ion-icon>
    <ion-label>Termos de Uso</ion-label>
  </ion-item>

  <ion-item button (click)="abrirPoliticaDePrivacidade()" lines="none">
    <ion-icon name="shield-checkmark-outline" class="ion-margin-end"></ion-icon>
    <ion-label>Política de Privacidade</ion-label>
  </ion-item>

  <p>&nbsp;</p>
  <p>&nbsp;</p>

  <ion-item-divider>
    <ion-label>Manutenção</ion-label>
  </ion-item-divider>

  <ion-item button (click)="sincronizarCusto()" lines="none">
    <ion-icon name="sync" class="ion-margin-end"></ion-icon>
    <ion-label>Sincronizar Custos</ion-label>
  </ion-item>

  <ion-item button (click)="abrirManutencaoENascimento()" lines="none">
    <ion-icon name="calendar-clear" class="ion-margin-end"></ion-icon>
    <ion-label>Genealogia e Datas de Nascimento</ion-label>
  </ion-item>

  <ion-item button (click)="abrirImportacaoGenealogia()" lines="none">
    <ion-icon name="git-network" class="ion-margin-end"></ion-icon>
    <ion-label>Importar Genealogia</ion-label>
  </ion-item>
  
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>

  <ng-template [ngIf]="configCounter > 7">
    <ion-item-divider>
      <ion-label>Dev</ion-label>
    </ion-item-divider>
    <ion-item button (click)="limparStorageLog()">
      <ion-icon name="alert" class="ion-margin-end"></ion-icon>
      <ion-label>Limpar Storage Log</ion-label>
    </ion-item>
    <ion-item button (click)="devModalPlanoExpirado()">
      <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
      <ion-label>Tela Plano Expirado</ion-label>
    </ion-item>
    <ion-item button (click)="devModalPlanoExpirado(true)">
      <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
      <ion-label>Tela Trial Expirado</ion-label>
    </ion-item>
    <ion-item button (click)="devModalDeAssinaturaDePlano()">
      <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
      <ion-label>Tela Plano Assine Agora</ion-label>
    </ion-item>
    <ion-item button (click)="devModalContaBloqueada()">
      <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
      <ion-label>Usuário Primário Expirado</ion-label>
    </ion-item>
    <ion-item button (click)="devBemVindo()">
      <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
      <ion-label>Bem Vindo Inicial</ion-label>
    </ion-item>
    <ion-item button (click)="devBemVindo(true)">
      <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
      <ion-label>Bem Vindo Com Plano</ion-label>
    </ion-item>
    <ion-item button (click)="devMigrarManejosReprodutivos()">
      <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
      <ion-label>Migrar Manejos Reprodutivos</ion-label>
    </ion-item>
    <ion-item button (click)="devMigrarManejosSanitarios()">
      <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
      <ion-label>Migrar Manejos Sanitários</ion-label>
    </ion-item>
    <ion-item button (click)="devMigrarManejosGerais()">
      <ion-icon name="fertili" class="ion-margin-end"></ion-icon>
      <ion-label>Migrar Manejos Gerais</ion-label>
    </ion-item>
  </ng-template>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
</ion-content>
