<main>
  <div class="container-lista">
    <button (click)="prosseguirTransacao()">
      <ion-icon name="arrow-forward-outline"></ion-icon>
      <div class="conteudo">
        <span>Prosseguir com {{ tipoFinanceiro === 'compra' ? 'Compra' : 'Venda' }}</span>
      </div>
    </button>
    <button (click)="cancelarTransacao()">
      <ion-icon name="close-circle"></ion-icon>
      <div class="conteudo">
        <span>Cancelar {{ tipoFinanceiro === 'compra' ? 'Compra' : 'Venda' }}</span>
      </div>
    </button>
  </div>
</main>
