<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title> Limite de animais alcançado </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="alertarSobreSair()">
        Fechar
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content> 
  <p *ngIf="!vagasDisponiveis">
    Atenção: o limite de animais cadastrados para este proprietário já foi alcançado. Para continuar adicionando novos animais, é necessário atualizar para um plano superior.
  </p>

  <p *ngIf="vagasDisponiveis && qtdAnimaisTentandoCadastrar">
    Atenção: Você possui {{ vagasDisponiveis }} {{ vagasDisponiveis > 1 ? 'vagas disponíveis' : 'vaga disponível' }} e está tentando cadastrar {{ qtdAnimaisTentandoCadastrar }} {{ qtdAnimaisTentandoCadastrar > 1 ? 'animais' : 'animal' }}
  </p>

  <cta-buttons>
    <button (click)="entrarEmContato()">
      <ion-icon name="logo-whatsapp"></ion-icon>
      77 99839-1515
    </button>
  </cta-buttons>
</ion-content>
