import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { IArea, IAreaPiquete } from 'src/app/utils/interfaces/areas.interface'

@Component({
  selector: 'vasta-mapa',
  templateUrl: './vasta-mapa.component.html',
  styleUrls: ['./vasta-mapa.component.scss']
})
export class VastaMapaComponent implements OnInit {
  @ViewChild('map') mapElement: ElementRef
  public map: google.maps.Map
  @Input('areas') areas: IArea[] = null
  @Input('piquetes') piquetes: IAreaPiquete[] = null
  @Input('mostrarQtdAnimais') mostrarQtdAnimais: boolean = false

  boundsCentralizacao = new google.maps.LatLngBounds()
  polygons = []
  markers = []
  infowindowAberta = null
  centralizouAlgumaVez = false

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.exibirMapa()
  }

  ngOnChanges(): void {
    this.centralizar()
  }
  
  centralizar(): void {
    this.polygons.map(a => a.setMap(null))
    this.polygons = []
    this.markers.map(a => a.setMap(null))
    this.markers = []

    if (this.areas) {
      let index = 0
      for (const area of this.areas) {
        this.carregaOutrasAreas(area, index)
        index++
      }
      if (this.polygons.length) {
        this.map?.fitBounds(this.boundsCentralizacao)
        this.centralizouAlgumaVez = true
      } else {
        if (!this.centralizouAlgumaVez) {
          const latLng = new google.maps.LatLng(-14.2375431, -60.3424685)
          this.map?.panTo(latLng)
          this.centralizouAlgumaVez = true
        }
      }
    }

    if (this.piquetes?.length) {
      this.renderizarPiquetes()
    }
  }

  getRandomString(): string {
    return Math.random().toString(36).substring(7)
  }

  exibirMapa(): void {
    const latLng = new google.maps.LatLng(-14.2375431, -60.3424685)
    const mapOptions = {
      center: latLng,
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      disableDefaultUI: true,
      zoomControl: true,
      mapId: this.getRandomString()
    }

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions)
    this.centralizar()
  }

  carregaOutrasAreas(area: IArea, index: number): void {
    if (area.area && area.area.length > 0) {
      const shape = []
      for (let i = 0; i < area.area.length - 1; i = i + 2) {
        shape.push({ lat: area.area[i], lng: area.area[i + 1] })
      }

      const polygon = new google.maps.Polygon({
        paths: shape,
        strokeColor: '#ffffff',
        strokeOpacity: 0.7,
        fillOpacity: 0,
        strokeWeight: 2,
        clickable: false,
        editable: false,
        zIndex: 4
      })
      polygon.setMap(this.map) //Insere poligono no mapa
      this.polygons.push(polygon)

      // se tiver piquetes, não exibe titulo da área
      if (this.piquetes?.length) {
        return
      }

      const bounds = new google.maps.LatLngBounds()

      for (let k = 0; k < shape.length; k++) {
        this.boundsCentralizacao.extend(shape[k])
        bounds.extend(shape[k])
      }

      const areaNome = document.createElement('div')
      areaNome.className = 'map-label'
      areaNome.innerHTML = `${area.nome}`
      const markerCental = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        position: bounds.getCenter(),
        content: areaNome,
      })
      this.markers.push(markerCental)
        
      if(this.mostrarQtdAnimais) {
        const content = `
        <div id="content">
          <h1>${area.nome}</h1>

          <div class="content-area-data">
            ${area.qtd_animais ? `<div class="area-dado"><b>Animais</b><span>${area.qtd_animais}</span></div>` : ''}
            ${area.lotes_nomes ? `<div class="area-dado"><b>Lotes</b><span>${area.lotes_nomes}</span></div>` : ''}
            ${area.total_peso ? `<div class="area-dado"><b>Peso Total</b><span>${Number(area.total_peso).toFixed(1).replace('.', ',')} kg</span></div>` : ''}
            ${area.tx_ocupacao ? `<div class="area-dado"><b>% de Lotação</b><span>${Number(area.tx_lotacao).toFixed(1).replace('.', ',')} UA/ha</span></div>` : ''}
            ${area.tamanho ? `<div class="area-dado"><b>Tamanho</b><span>${area.tamanho}ha</span></div>` : ''}
            ${area.max_lotacao ? `<div class="area-dado"><b>Lot. Máxima</b><span>${area.max_lotacao}</span></div>` : ''}
            ${area.observacao ? `<div class="area-dado"><b>Observação</b><span>${area.observacao}</span></div>` : ''}
          </div>
        </div>
        `

        const info = new google.maps.InfoWindow({
          content: content,
          minWidth: 300,
          maxWidth: 300
        })
        markerCental.addEventListener('gmp-click', () => {
          if (this.infowindowAberta) {
            this.infowindowAberta.close()
          }
          this.infowindowAberta = info
          info.open(this.map, markerCental)
        })
      }
    }
  }
  
  renderizarPiquetes(): void {
    let index = 0
    for (const piquete of this.piquetes) {
      const shape = []
      for (let i = 0; i < piquete.area?.length - 1; i = i + 2) {
        const lat = Number(piquete.area[i])
        const lng = Number(piquete.area[i + 1])
        shape.push({ lat, lng })
      }

      const bounds = new google.maps.LatLngBounds()

      for (let k = 0; k < shape.length; k++) {
        this.boundsCentralizacao.extend(shape[k])
        bounds.extend(shape[k])
      }

      const areaNome = document.createElement('div')
      areaNome.className = 'map-label'
      areaNome.innerHTML = `${piquete.nome}`

      const markerCental = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        position: bounds.getCenter(),
        content: areaNome,
      })
      this.markers.push(markerCental)

      const polygon = new google.maps.Polygon({
        paths: shape,
        fillColor: '#fff',
        fillOpacity: 0.3,
        strokeColor: '#ffffff',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        clickable: false,
        editable: false,
        zIndex: 1
      })
      polygon.setMap(this.map) //Insere poligono no mapa
      this.polygons.push(polygon)

      index++
    }
  }
}
