<div class="classes-container" [class.vertical]="tipo == 'vertical'">
  <div class="class-container" *ngFor="let video of lista" (click)="clicked(video)" [class.active]="activeId === video.videoID">
    <div class="class-image-container">
      <ion-icon name="play" *ngIf="activeId === video.videoID"></ion-icon>
      <img [src]="video.imagem" alt="" />
      <span class="duracao" *ngIf="video.duracao">{{video.duracao}}</span>
    </div>
    <div class="class-info-container">
      <h4>{{video.titulo}}</h4>
      <div class="tags" *ngIf="video?.categorias?.length">
        <span *ngFor="let categoria of video.categorias">
          {{categoria}}
        </span>
      </div>
    </div>
  </div>
</div>