/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, TemplateRef, ContentChild, ChangeDetectorRef } from '@angular/core'
import { ViewChild, ElementRef, ViewEncapsulation, SimpleChanges } from '@angular/core'
import { ColunasTabela, ConfigTabela, Conta, Contas } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalController, Platform, PopoverController } from '@ionic/angular'
import { tabelaFormat } from './format'
import { DadosLinhaTabela, StatusTabela } from './tabela-types'
import { IOptionButtons, OpcoesBotoesComponent } from '../opcoes-botoes/opcoes-botoes.component'
import * as moment from 'moment'
import { ExportacaoService } from 'src/app/services/exportacao.service'
import { VastaTabelaFiltrosComponent } from '../vasta-tabela-filtros/vasta-tabela-filtros.component'
import { CalendarioComponent } from '../calendario/calendario.component'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { ModalListaLotesPage } from 'src/app/pages/bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { IArea } from 'src/app/utils/interfaces/areas.interface'
import { ModalListaAreasPage } from 'src/app/pages/areas/modal-lista-areas/modal-lista-areas.page'
import { ImpressaoService } from 'src/app/services/impressao.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { PopoverFinancasCategoriasComponent } from '../popover-financas-categorias/popover-financas-categorias.component'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { ConfiguracaoTabelaPage } from 'src/app/pages/configuracao-tabela/configuracao-tabela.page'
import {
  ColunasAnimais
} from './../../utils/configuracoes-padrao'
import { TabelaConfig, TabelaDismiss } from 'src/app/pages/configuracao-tabela/configuracao-tabela-types'
import { QualitasService } from 'src/app/services/qualitas.service'

export type NovoFiltro = { [key: string]: string | number | any }
export type TabelaFiltroExtra = {
  chave: string
  titulo: string
  tipo: 'animal' | 'lista' | 'data'
  lista?: { chave: string; valor: string }[]
  icone?: string
  valor?: string
  obrigatorio?: boolean
  acao?: (e: Event, filtro: TabelaFiltroExtra) => Promise<void> | void
}
export type Ordenacao = { coluna: string; direcao: 'asc' | 'desc' }

@Component({
  selector: 'vasta-tabela',
  templateUrl: 'vasta-tabela.html',
  styleUrls: ['vasta-tabela.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VastaTabela implements OnInit, OnChanges {
  @Input() status: StatusTabela = 'carregando'
  @ViewChild('s2') s2: ElementRef
  @ViewChild('tabela') tabela: ElementRef
  @Input() config: ConfigTabela = {}
  @Input() labels: ColunasTabela[] = []
  @Input() labelsId: string
  @Input() disableVirtualScroll: boolean = false
  @Input() optionsSelecionados: IOptionButtons[]
  @Output() optionsSelecionadosEmit = new EventEmitter()

  @Input('paginate') paginate: { limit: number; offset: number; total: number; currentPage?: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }
  @Output() paginateChange = new EventEmitter()
  @Input('optionsButtons') optionsButtons: IOptionButtons[]

  /*
   * ---------------- OLD -------------------- *
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() campos: Array<Record<string, string | number | boolean | any>> = []
  @Input() totais: { [x: string]: string | number }
  @Input() imprimir: number
  @Input() conta: Conta
  @Input() contas: Contas
  @Input() hashsSelecionados = []
  @Output() hashsSelecionadosChange: EventEmitter<string[]> = new EventEmitter()
  @Output() selecionarTodos: EventEmitter<boolean> = new EventEmitter()
  @Output() eventos: EventEmitter<{ acao: string; evento?: string; linha: DadosLinhaTabela; date?: string }> =
    new EventEmitter()
  @Output() selecionado: EventEmitter<DadosLinhaTabela> = new EventEmitter()
  @Output() periodo: EventEmitter<{ inicial: string; final: string } | void> = new EventEmitter()

  @Output() retentar: EventEmitter<boolean> = new EventEmitter()

  @Output('toggleChange') toggleChange: EventEmitter<DadosLinhaTabela> = new EventEmitter()

  @ContentChild('innerComponents') innerComponentsTmpl: TemplateRef<any>

  filtrosSelecionados = []

  excluidasPadrao: string[] = []
  controleNomeNumero: string
  exibirNBrinco: number | boolean
  atualizandoColunas: boolean = false

  colunasDisponiveis = {
    colunas_animais: ColunasAnimais,
  }

  camposFormatados: DadosLinhaTabela[] = []
  dadosBackup = []
  dadosBackupFormatados = [] // todos os campos, na ordem que veio, formatados
  dadosBackupFormatadosFiltrados = []
  exibindoTotais = false
  labelsChaveValor = {}
  tableFormat = tabelaFormat

  scrollandoManualmente = false

  public dadosSelecionados = []
  // Filtros

  @Input() novoFiltro: NovoFiltro = {}
  @Output() novoFiltroChange: EventEmitter<{ [key: string]: string | number }> = new EventEmitter()

  @Input() filtrosFixos: string[] = []

  @Input() filtrosExtras: TabelaFiltroExtra[] = []

  @Input() novaOrdenacao: Ordenacao
  @Output() novaOrdenacaoChange: EventEmitter<Ordenacao> = new EventEmitter()

  @Input() opcoesFiltros: { [key: string]: { [key: string]: string } } = {}

  public filtrosDisponiveis: boolean = false
  @Input() lotes: ILote[] = []
  @Input() areas: IArea[] = []

  public format = tabelaFormat

  public colunaCheckBoxMobile: ColunasTabela = {}

  constructor(
    private popoverCtrl: PopoverController,
    private cd: ChangeDetectorRef,
    private exportacaoCtrl: ExportacaoService,
    private modalCtrl: ModalController,
    private impressaoService: ImpressaoService,
    private plt: Platform,
    private utilsCtrl: IonicUtilsService,
    public propriedadesCtrl: PropriedadesService,
    public planoBasicoCtrl: PlanoBasicoService,
    private qualitasCtrl: QualitasService
  ) {}

  ngOnInit(): void {
    if (this.status === 'carregando') {
      this.formataCampos([
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true },
        { skeleton: true }
      ])
    }
    this.colunaCheckBoxMobile = this.labels.find((col) => col.tipo === 'checkbox')
  }

  ngOnChanges(alteracoes: SimpleChanges): void {
    if (this.labelsId) {
      this.checkConfiguracoesTabela()
    }

    if (alteracoes['campos'] && alteracoes.campos.currentValue) {
      this.dadosBackup = alteracoes.campos.currentValue
      this.formataCampos(alteracoes.campos.currentValue)
    }
    if (alteracoes['status'] && alteracoes.status.currentValue == 'sucesso') {
      if (this.campos.length == 0) this.formataCampos([])
    }
    if (alteracoes['hashsSelecionados']) {
      this.hashsSelecionados = alteracoes.hashsSelecionados.currentValue
      this.tick()
    }
    if (alteracoes['paginate']) {
      this.paginate = alteracoes.paginate.currentValue
      this.tick()
    }

    if (this.labelsId) {
      this.verificarColunasOcultadasPorPadrao()
    }
  }

  formataCampos(novosCampos = null): void {
    let camposAutilizar = novosCampos || this.campos

    if (this.config.excluirColunas && this.config.excluirColunas.length)
      this.labels = this.labels.filter((label) => !this.config.excluirColunas.includes(label.chave))

    if (this.config.ocultarColunas && this.config.ocultarColunas.length)
      this.labels = this.labels.filter((label) => !this.config.ocultarColunas.includes(label.chave))
    else
      this.labels = this.labels.filter((label) => !(this.excluidasPadrao || []).includes(label.chave))

    if (this.config.ordenacaoColunas && this.config.ordenacaoColunas.length) {
      const labelsOrdenados = []
      const colunaMais = []
      let novosCampos = []
      try {
        novosCampos = this.labels.filter(a => !this.config.ordenacaoColunas.includes(a.chave) && !this.config.ocultarColunas?.includes(a.chave))
      } catch (e) {
        console.warn('🚀 ~ formataCampos ~ e:', e)
      }
      this.config.ordenacaoColunas.map(chave => {
        const coluna = this.labels.find(a => a.chave == chave)
        if (coluna) {
          if (coluna.chave == 'mais') {
            colunaMais.push(coluna)
          } else {
            labelsOrdenados.push(coluna)
          }
        }
      })
      this.labels = [...labelsOrdenados, ...novosCampos, ...colunaMais]
    }

    let camposFormatados = []

    const labelsPorChaves = {}
    let order = 0
    this.labels.map((label) => {
      label['order'] = order++
      labelsPorChaves[label.chave || label.tipo] = label
    })

    const labelsOrdenados = Object.keys(labelsPorChaves)

    this.filtrosDisponiveis = this.labels.some((l) => !!l.tipoFiltro)

    this.labelsChaveValor = labelsPorChaves

    if (camposAutilizar?.length) {
      for (const linha of camposAutilizar) {
        const novaLinha = {}
        const novaLinhaOrdenada = {
          original: linha,
          formatado: {},
          esquerda: [],
          centro: []
        }

        for (const chave of labelsOrdenados) {
          const valorOriginal = linha[chave]
          const novaColuna = {
            valorOriginal,
            class: '',
            chave
          }

          const colunaConfig = labelsPorChaves[chave]
          let novoValor = ''

          if (colunaConfig) {
            if (colunaConfig.tamanho) {
              novaColuna['tamanho'] = colunaConfig.tamanho
              novaColuna['class'] += `coluna-${colunaConfig.tamanho} `
            }

            if (colunaConfig.tamanhoMinimo) {
              novaColuna['tamanhoMinimo'] = colunaConfig.tamanhoMinimo
              novaColuna['class'] += `coluna-min-${colunaConfig.tamanhoMinimo} `
            }

            if (colunaConfig.pointer) {
              novaColuna['class'] += 'pointer '
            }

            if (colunaConfig.centralizar) {
              novaColuna['centralizar'] = true
              novaColuna['class'] += 'centralizar '
            }

            if (colunaConfig.fixaFinal) {
              novaColuna['fixaFinal'] = true
              novaColuna['class'] += 'fixa-final '
            }

            if (colunaConfig.tipo) {
              novaColuna['tipo'] = colunaConfig.tipo
              novaColuna['class'] += `${colunaConfig.tipo} `
            }

            if (colunaConfig.evento) {
              novaColuna['evento'] = colunaConfig.evento
            }

            if (colunaConfig.bold) {
              novaColuna['bold'] = colunaConfig.bold
            }

            if (colunaConfig.format) {
              novaColuna['format'] = colunaConfig.format
            }

            if (colunaConfig.class) {
              novaColuna['class'] += `${colunaConfig.class} `
            }

            if (colunaConfig.small && linha[colunaConfig.small] && valorOriginal) {
              novaColuna['small'] = linha[colunaConfig.small]
            }

            if (colunaConfig.unidade && valorOriginal) {
              novaColuna['unidade'] = colunaConfig.unidade
            }

            if (colunaConfig.format && valorOriginal) {
              novoValor = tabelaFormat[colunaConfig.format](valorOriginal)
            }

            if (
              colunaConfig.labelFormat &&
              valorOriginal &&
              colunaConfig.tipo !== 'icon-only' &&
              colunaConfig.tipo !== 'toggle'
            ) {
              if (tabelaFormat[colunaConfig.labelFormat]) {
                novoValor = tabelaFormat[colunaConfig.labelFormat](valorOriginal)
              }
            }

            if (colunaConfig.assinaturaPremium && this.planoBasicoCtrl.isPlanoBasico && !this.propriedadesCtrl.isPropriedadeExemploAtivada) {
              novaColuna['icone'] = 'diamond'
            }

            if (colunaConfig.tipo == 'icone' || colunaConfig.tipo == 'icon-only') {
              if (colunaConfig.iconeCase) {
                if (colunaConfig.iconeCase['null'] && !valorOriginal) {
                  novaColuna['icone'] = colunaConfig.iconeCase['null'].class
                  novaColuna['color'] = colunaConfig.iconeCase['null'].color
                } else {
                  if (colunaConfig.iconeCase['notNull']) {
                    novaColuna['icone'] = colunaConfig.iconeCase['notNull'].class
                    novaColuna['color'] = colunaConfig.iconeCase['notNull'].color
                  } else {
                    novaColuna['icone'] = colunaConfig.iconeCase[valorOriginal]?.class
                    novaColuna['color'] = colunaConfig.iconeCase[valorOriginal]?.color
                  }
                }
              }
              if (!novaColuna['icone'] && (!colunaConfig.iconeCase || !colunaConfig.iconeCase[valorOriginal]))
                novaColuna['icone'] = colunaConfig.icone || valorOriginal
            }

            if (colunaConfig.tipo == 'toggle') {
              novaColuna['toggle'] = true
            }
            
            if (colunaConfig.chip) {
              novaColuna['chip'] = true
              novaColuna['titulo'] = colunaConfig.titulo
            }

            if (!novoValor && colunaConfig.chaveFallback && linha[colunaConfig.chaveFallback]) {
              novoValor = linha[colunaConfig.chaveFallback]
            }

            if (
              !novoValor &&
              valorOriginal &&
              !(novaColuna['icone'] && colunaConfig.tipo == 'icon-only') &&
              !novaColuna['toggle']
            ) {
              novoValor = valorOriginal
            }

            if (colunaConfig.placeholder && !novoValor) {
              novaColuna['placeholder'] = colunaConfig.placeholder
            }

            if (colunaConfig.classesExtra) {
              novaColuna['classesExtra'] = colunaConfig.classesExtra
            }
          } else {
            novoValor = valorOriginal
          }

          novaColuna['valor'] = novoValor

          if (this.planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('colunasTabela', colunaConfig.chave) && !this.propriedadesCtrl.isPropriedadeExemploAtivada) {
            delete novaColuna['placeholder']
            delete novaColuna['valor']
            delete novaColuna['unidade']
          }
          
          novaLinhaOrdenada.formatado[chave] = novoValor || valorOriginal

          if (colunaConfig.fixa) {
            novaLinhaOrdenada.esquerda.push(novaColuna)
          } else {
            novaLinhaOrdenada.centro.push(novaColuna)
          }
          novaLinha[chave] = novaColuna
        }

        camposFormatados.push(novaLinhaOrdenada)
      }
    }

    this.dadosBackupFormatados = camposFormatados

    this.dadosBackupFormatadosFiltrados = this.dadosBackupFormatados
    this.camposFormatados = this.dadosBackupFormatados

    const de = Number(moment().format('x'))

    this.tick()

    setTimeout(() => {
      const ate = Number(moment().format('x'))
      console.log('Tempo: ', ate - de)
    }, 10)
  }

  tickTimeout = null
  tick(): void {
    clearTimeout(this.tickTimeout)

    this.tickTimeout = setTimeout(() => {
      this.cd.detectChanges()
    }, 200)
  }

  async clickColuna(
    isHeader: boolean,
    coluna: ColunasTabela,
    linha?: DadosLinhaTabela,
    ev?: Event,
    rightClick = false,
    stopPropagation = false
  ): Promise<void> {
    if (stopPropagation) ev.stopPropagation()
    if (linha?.original?.skeleton) return

    if (coluna.tipo == 'checkbox' && this.campos.length) {
      if (isHeader) {
        // marca todos
        if (this.hashsSelecionados.includes(this.dadosBackupFormatadosFiltrados[0].original.hash)) {
          this.hashsSelecionados = []
        } else {
          // busca todos da api
          const todosOsDados = await this.selecionarTodasAsPaginas()
          const todosOsHashs = todosOsDados.map((item) => {
            return item.hash
          })

          this.hashsSelecionados = [...this.hashsSelecionados, ...todosOsHashs]
          this.hashsSelecionados = [...new Set(this.hashsSelecionados)]

          this.dadosSelecionados = [...todosOsDados]
        }

        this.hashsSelecionadosChange.emit(this.hashsSelecionados)
        this.selecionarTodos.emit(!!this.hashsSelecionados.length)
      } else {
        if (this.hashsSelecionados.includes(linha.original.hash)) {
          const index = this.hashsSelecionados.indexOf(linha.original.hash)
          this.hashsSelecionados.splice(index, 1)
        } else {
          this.hashsSelecionados.push(linha.original.hash)
        }

        // this.dadosSelecionados = this.dadosBackup.filter((item) => {
        //   return this.hashsSelecionados.includes(item.hash)
        // })

        this.hashsSelecionadosChange.emit(this.hashsSelecionados)

        document
          .querySelectorAll(`.linha[hash='${linha.original.hash}']`)
          .forEach((e) => e.classList.toggle('selecionado'))
      }
      this.tick()
    } else {
      if (isHeader) {
        if (!coluna.bloquearOrdenacao) {
          if (this.novaOrdenacao?.coluna == coluna.chave) {
            this.novaOrdenacao.direcao = this.novaOrdenacao.direcao == 'asc' ? 'desc' : 'asc'
          } else {
            if (this.novaOrdenacao) {
              this.novaOrdenacao.coluna = coluna.chave
              this.novaOrdenacao.direcao = this.novaOrdenacao.direcao || 'asc'
            } else return
          }
          this.novoFiltroChange.emit(this.novoFiltro)
        }
      } else {
        if (coluna.tipo == 'toggle') {
          const hash = linha.original.hash
          document.querySelectorAll(`.linha[hash='${hash}'] .toggle-elemento`)[0].classList.toggle('ativo')
          ev.stopPropagation()

          this.toggleChange.emit(linha)
          return
        }

        if (coluna.evento) {
          if (this.optionsButtons) {
            const popover = await this.popoverCtrl.create({
              component: OpcoesBotoesComponent,
              componentProps: { options: this.optionsButtons },
              event: ev,
              reference: rightClick ? 'event' : 'trigger',
              dismissOnSelect: false
            })

            await popover.present()

            const { data } = await popover.onDidDismiss()
            if (data) {
              if (data.acao) {
                data.acao(linha, data.date)
              } else this.eventos.emit({ acao: data.chave, linha, date: data?.date })
            }
          }
        } else {
          console.log('linha: ', linha)
          this.selecionado.emit(linha)
        }
      }
    }
  }

  async selecionarTodasAsPaginas(): Promise<Array<Record<string, string | number | boolean | any>>> {
    let todosOsDados = null
    if (this.paginate?.total > this.campos.length && this.config.buscaDados) {
      const loading = await this.utilsCtrl.showLoading('circular', 'Buscando dados...')
      const { dados } = await this.config.buscaDados()
      this.utilsCtrl.dismissLoading(loading)
      if (dados) {
        todosOsDados = dados
      }
    } else {
      todosOsDados = this.campos
    }
    return todosOsDados
  }

  async exportarConteudoPopover(ev: Event): Promise<void> {
    const { acessoAoItemBloqueado } = await this.planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('acoes', 'exportacao', ev)
    if(acessoAoItemBloqueado) return

    console.log('exportarConteudoPopover')
    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: {
        options: [{
          chave: 'exportarAnimaisXSLX',
          icone: 'apps-sharp',
          titulo: 'Exportar em .XLSX',
          classe: 'permissao-leitura-animais',
          disabled: false,
          acao: (): void => {
            this.exportarConteudo('xlsx')
          }
        },
        {
          chave: 'exportarAnimais',
          icone: 'newspaper',
          titulo: 'Exportar em .CSV',
          classe: 'permissao-leitura-animais',
          disabled: false,
          acao: (): void => {
            this.exportarConteudo('csv')
          }
        },
        ...(this.labelsId == 'colunas_animais' && Number(this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.participa_qualitas) === 1 ? [{
          chave: 'exportarAnimais',
          icone: 'qualitas',
          titulo: 'Exportar para Qualitas',
          classe: 'permissao-leitura-animais',
          acao: (): void => {
            this.exportarParaQualitas()
          }
        }] : [])
        ]
      },
      event: ev,
      reference: 'trigger',
      dismissOnSelect: false,
      cssClass: 'popoverOpcoesBotoes'
    })

    await popover.present()

    const { data } = await popover.onDidDismiss()
    const opcaoSelecionada = data as IOptionButtons

    if (opcaoSelecionada) {
      if (opcaoSelecionada.acao) {
        opcaoSelecionada.acao({ hashsSelecionados: this.hashsSelecionados, date: data?.date })
      }
    }
  }

  async imprimirConteudo(ev?: Event): Promise<void> {
    const { acessoAoItemBloqueado } = await this.planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('acoes', 'impressao', ev)
    if(acessoAoItemBloqueado) return

    if (this.paginate?.total > this.campos.length) {
      const loading = await this.utilsCtrl.showLoading('circular', 'Buscando dados...')
      const { dados } = await this.config.buscaDados()
      this.utilsCtrl.dismissLoading(loading)
      if (dados) {
        this.impressaoService.configurarImpressao(
          this.labels,
          dados,
          this.config.impressaoTitulo,
          this.config.impressaoKpiCardConfig,
          this.config.impressaoKpiValores,
          this.config.impressaoKpiTipo,
          this.config.impressaoCardsResumos
        )
      }
    } else {
      this.impressaoService.configurarImpressao(
        this.labels,
        this.campos,
        this.config.impressaoTitulo,
        this.config.impressaoKpiCardConfig,
        this.config.impressaoKpiValores,
        this.config.impressaoKpiTipo,
        this.config.impressaoCardsResumos
      )
    }
  }

  async exportarConteudo(extensaoArquivo: 'csv' | 'xlsx'): Promise<void> {
    if (this.hashsSelecionados.length || this.paginate?.total <= this.campos.length) {
      this.exportacaoCtrl.downloadFile(
        this.config.impressaoTitulo || 'Relatório',
        this.campos,
        this.labels,
        this.hashsSelecionados,
        extensaoArquivo
      )
    } else {
      const loading = await this.utilsCtrl.showLoading('circular', 'Buscando dados...')
      const { dados } = await this.config.buscaDados()
      this.utilsCtrl.dismissLoading(loading)
      this.exportacaoCtrl.downloadFile(
        this.config.impressaoTitulo || 'Relatório',
        dados,
        this.labels,
        this.hashsSelecionados,
        extensaoArquivo
      )
    }
  }

  async exportarParaQualitas(): Promise<void> {
    if (this.hashsSelecionados.length || this.paginate?.total <= this.campos.length) {
      const hashs = this.campos.map(item => item.hash)
      this.qualitasCtrl.exportacaoQualitas({ hashs : this.hashsSelecionados.length ? this.hashsSelecionados : hashs, filtros: this.novoFiltro })
    } else {
      this.qualitasCtrl.exportacaoQualitas({ filtros: this.novoFiltro })
    }
  }

  async showPopoverOpcoes(ev: Event): Promise<void> {
    if (!this.optionsSelecionados?.length) return

    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: { options: this.optionsSelecionados },
      event: ev,
      reference: 'trigger',
      dismissOnSelect: false,
      cssClass: 'popoverOpcoesBotoes'
    })

    await popover.present()

    const { data } = await popover.onDidDismiss()
    const opcaoSelecionada = data as IOptionButtons

    if (opcaoSelecionada) {
      if (opcaoSelecionada.chave === 'exportarAnimais') {
        this.exportarConteudo('xlsx')
        this.hashsSelecionados = []
        this.hashsSelecionadosChange.emit(this.hashsSelecionados)
        return
      }

      if (opcaoSelecionada.acao) {
        opcaoSelecionada.acao({ hashsSelecionados: this.hashsSelecionados, dadosSelecionados: this.dadosSelecionados, date: data?.date })
      }
    }
  }

  setPaginacao(novaPaginacao: { limit: number; offset: number; total: number; currentPage: number }): void {
    this.paginate = novaPaginacao
    this.paginateChange.emit(this.paginate)
  }

  onRightClick(event: PointerEvent, linha: DadosLinhaTabela): void {
    event.preventDefault()
    let coluna = linha.centro ? linha.centro[linha.centro.length - 1] : null

    if (coluna && coluna.evento == 'opcoes') this.clickColuna(false, coluna, linha, event, true)
  }

  emiteRetentativa(): void {
    this.retentar.emit(true)
  }

  // Novos filtros

  removerFiltroExtra(event: Event, filtro: TabelaFiltroExtra): void {
    event.stopPropagation()
    filtro.valor = ''
    delete this.novoFiltro[filtro.chave]
    this.novoFiltroChange.emit(this.novoFiltro)
  }

  removerNovoFiltro(event: Event, chave: string): void {
    event.stopPropagation()
    if (this.paginate.offset !== 0) {
      this.paginate.offset = 0
      this.paginate.currentPage = 1
    }

    delete this.novoFiltro[chave]
    this.novoFiltroChange.emit(this.novoFiltro)
  }

  async openPopoverFiltros(event: PointerEvent, coluna?: ColunasTabela, ordenacao = false, inlineProp = false, colunaChave?: string): Promise<void> {
    event.stopPropagation()

    if (colunaChave) {
      coluna = this.labels.find((col) => col.chave === colunaChave)
    }

    if (this.filtrosFixos.includes(coluna?.chave)) return

    if (coluna?.tipoFiltro === 'rangeDatas' && !ordenacao) {
      let tooLowOnTheScreen = false
      if ((window.innerHeight - event.clientY) < 500) {
        tooLowOnTheScreen = true
      }

      const popover = await this.popoverCtrl.create({
        component: CalendarioComponent,
        componentProps: { range: true, titulo: coluna.titulo },
        event: tooLowOnTheScreen ? undefined : event,
        reference: tooLowOnTheScreen ? 'trigger' : undefined,
        cssClass: 'popover-calendario'
      })

      await popover.present()
      const { data } = await popover.onWillDismiss()
      if (data) {
        this.novoFiltro[coluna.chave] = [data.de.iso, data.ate.iso]

        if (this.paginate.offset !== 0) {
          this.paginate.offset = 0
          this.paginate.currentPage = 1
        }

        this.novoFiltroChange.emit(this.novoFiltro)
      }

      this.popoverCtrl.dismiss()

      return
    }

    if (
      ['extra_lote', 'lotes_nomes', 'extra_lote_matriz', 'extra_hash_lote_label'].includes(coluna?.chave) &&
      !ordenacao
    ) {
      const modal = await this.modalCtrl.create({
        component: ModalListaLotesPage,
        componentProps: { multiplos: true },
        cssClass: 'custom-modal-lotes'
      })

      await modal.present()
      const { data } = await modal.onWillDismiss()
      if (data) {
        this.lotes = data.lotes

        if (this.paginate.offset !== 0) {
          this.paginate.offset = 0
          this.paginate.currentPage = 1
        }

        this.novoFiltro[coluna.chave] = data.lotes.map((lote) => lote.hash)
        this.novoFiltroChange.emit(this.novoFiltro)
      }

      return
    }

    if (['extra_area', 'nome_area'].includes(coluna?.chave) && !ordenacao) {
      const modal = await this.modalCtrl.create({
        component: ModalListaAreasPage,
        componentProps: { multiplos: true },
        cssClass: 'custom-modal-seletor'
      })

      await modal.present()
      const { data } = await modal.onWillDismiss()
      if (data) {
        this.areas = data.areas

        if (this.paginate.offset !== 0) {
          this.paginate.offset = 0
          this.paginate.currentPage = 1
        }

        this.novoFiltro[coluna.chave] = data.areas.map((area) => area.hash)
        this.novoFiltroChange.emit(this.novoFiltro)
      }

      return
    }

    if (coluna?.chave === 'extra_categorias_chave') {
      const idsCategoriasSelecionadas = this.novoFiltro[coluna.chave]
      const popover = await this.popoverCtrl.create({
        component: PopoverFinancasCategoriasComponent,
        componentProps: { tipo: 'todos', selecaoMultipla: true, idsCategoriasSelecionadas },
        event,
        reference: 'trigger',
        cssClass: 'popover-categorias'
      })

      await popover.present()
      const { data } = await popover.onWillDismiss()
      if (data) {
        if (this.paginate.offset !== 0) {
          this.paginate.offset = 0
          this.paginate.currentPage = 1
        }

        this.novoFiltro[coluna.chave] = data.categorias.map((item) => item.id)
        this.novoFiltroChange.emit(this.novoFiltro)
      }

      await this.popoverCtrl.dismiss()

      return
    }

    if (['propriedade_nome', 'extra_propriedade', 'extra_propriedade_nome'].includes(coluna?.chave) && coluna?.tipoFiltro === 'preenchidosMultiplos') {
      this.opcoesFiltros[coluna?.chave] = {}

      this.propriedadesCtrl.todasPropriedades.filter(a => Number(a.id_usuario) == Number(this.propriedadesCtrl?.propriedadeSelecionada?.id_usuario)).forEach((propriedade) => {
        this.opcoesFiltros[coluna?.chave][propriedade.id] = propriedade.nome
      })
    }

    const popover = await this.popoverCtrl.create({
      component: VastaTabelaFiltrosComponent,
      componentProps: {
        colunas: this.labels,
        filtros: this.novoFiltro,
        coluna,
        eventRef: event,
        ordenacao,
        inlineProp,
        opcoesExtrasFiltros: this.opcoesFiltros,
        filtrosFixos: this.filtrosFixos,
      },
      event: this.plt.is('capacitor') ? undefined : event,
      reference: this.plt.is('capacitor') ? 'trigger' : undefined
    })

    await popover.present()

    const { data } = await popover.onWillDismiss()
    if (data) {
      if (data.colunaOrdenacao) {
        this.novaOrdenacao.coluna = data.colunaOrdenacao.chave
      }

      if (data.lotes) {
        this.lotes = data.lotes
      }

      if (data.areas) {
        this.areas = [...this.areas, ...data.areas]
      }

      if (this.paginate.offset !== 0) {
        this.paginate.offset = 0
        this.paginate.currentPage = 1
      }

      this.novoFiltroChange.emit(this.novoFiltro)
    }
  }

  async executaAcaoFiltroExtra(ev: Event, filtro: TabelaFiltroExtra): Promise<void> {
    if (filtro.acao) {
      filtro.acao(ev, filtro)
    }
  }

  formatarData(data: string | number): string {
    const [de, ate] = String(data).split(',')
    if (moment(de).isValid() && moment(ate).isValid()) {
      if (moment(de).format('YYYY') == moment(ate).format('YYYY')) {
        return `${moment(de).format('DD/MM')} a ${moment(ate).format('DD/MM/YYYY')}`
      } else {
        return `${moment(de).format('DD/MM/YYYY')} a ${moment(ate).format('DD/MM/YYYY')}`
      }
    } else {
      return 'Selecionar Data'
    }
  }

  formatarLabel(labelFormat: string, valor: string | string[] | number, colunaChave?: string): string {
    try {
      let valorFormatado = ''
      if (this.format[labelFormat]) {
        valorFormatado = String(valor)
          .split(',')
          .map((item) => this.format[labelFormat](item))
          .join(',')
        return valorFormatado
      } else {
        if (typeof valor === 'object') {
          valorFormatado = valor.map((item) => this.opcoesFiltros[colunaChave][item]).join(',')
        } else {
          valorFormatado = this.opcoesFiltros[colunaChave]?.[valor]
        }
      }

      return valorFormatado
    } catch (error) {
      throw error
    }
  }

  formatarLabelLote(hashs: string[]): string {
    const labelLote = this.lotes
      .filter((lote) => hashs.includes(lote?.hash))
      .map((lote) => lote.nome)
      .join(',')

    return labelLote
  }

  formatarLabelArea(hashs: string[]): string {
    const labelArea = this.areas
      .filter((area) => hashs.includes(area?.hash))
      .map((area) => area.nome)
      .join(',')

    return labelArea
  }

  mudarDirecaoOrdenacao(event: Event): void {
    event.stopPropagation()
    this.novaOrdenacao.direcao = this.novaOrdenacao.direcao === 'asc' ? 'desc' : 'asc'
    this.novoFiltroChange.emit(this.novoFiltro)
  }

  keyLength(objeto: { [key: string]: string }): number {
    return Object.keys(objeto).length
  }

  checkConfiguracoesTabela(): void {
    this.controleNomeNumero = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais
    this.exibirNBrinco = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.mostra_brinco
    const config = this.obterConfiguracoesTabela()

    this.labels = JSON.parse(JSON.stringify(this.colunasDisponiveis[this.labelsId]))

    if (config) {
      if (config.colunas?.length) {
        this.config.ordenacaoColunas = config.colunas
      }
      if (config.ocultas?.length) {
        this.config.ocultarColunas = config.ocultas
      }

      this.formataCampos(this.dadosBackup)
    }
  }

  verificarConfiguracaoSalva(configSalva: ColunasTabela[]): void {
    const remanescentes = configSalva.map((col) => {
      if (col.chave === 'meta_nbrinco' && this.exibirNBrinco == 0) return col.chave

      if (col.chave === 'numeracao' && this.controleNomeNumero === 'nome') return col.chave

      if (col.chave === 'nome' && this.controleNomeNumero === 'numero' && this.labelsId === 'colunas_animais')
        return col.chave
    })

    this.config.excluirColunas = remanescentes

    this.formataCampos(this.dadosBackup)
    this.tick()
  }

  verificarColunasOcultadasPorPadrao(): void {
    const labels = this.labelsId ? this.colunasDisponiveis[this.labelsId] : this.labels
    this.excluidasPadrao = labels.map((col) => {
      if (col.exibida === false) {
        return col.chave
      }

      if (col.chave === 'meta_nbrinco' && this.exibirNBrinco == 0) return col.chave

      if (col.chave === 'numeracao' && this.controleNomeNumero === 'nome') return col.chave

      if (col.chave === 'nome' && this.controleNomeNumero === 'numero' && this.labelsId === 'colunas_animais')
        return col.chave
    })

    this.formataCampos(this.dadosBackup)
  }

  async abrirConfiguracaoTabela(): Promise<void> {
    if(!this.propriedadesCtrl?.propriedadeSelecionada) return

    const configProp = this.obterConfiguracoesTabela()
    const colunasOriginais = JSON.parse((JSON.stringify(this.colunasDisponiveis[this.labelsId]))) as ColunasTabela[]
    const modal = await this.modalCtrl.create({
      component: ConfiguracaoTabelaPage,
      componentProps: {
        configProp,
        colunasOriginais,
        labelsId: this.labelsId,
        controleNomeNumero: this.controleNomeNumero,
        exibirNBrinco: this.exibirNBrinco
      },
      cssClass: 'custom-modal'
    })

    modal.present()

    modal.onDidDismiss().then(async (data) => {
      if (data && data.data) {
        const dados: TabelaDismiss = data.data as TabelaDismiss
        const { config } = dados

        if (dados.deVoltaPadrao === true) {
          return this.apagarConfiguracaoDeColunas()
        }

        this.config.ocultarColunas = config.ocultas
        this.config.ordenacaoColunas = config.colunas
        this.labels = JSON.parse(JSON.stringify(this.colunasDisponiveis[this.labelsId]))

        this.formataCampos(this.dadosBackup)
      }
    })
  }

  async apagarConfiguracaoDeColunas(): Promise<void> {
    this.labels = JSON.parse(JSON.stringify(this.colunasDisponiveis[this.labelsId]))
    this.config.ocultarColunas = []
    this.config.ordenacaoColunas = []

    await this.removerDadosTabela(this.labelsId)

    this.verificarConfiguracaoSalva(this.labels)
    this.propriedadesCtrl.getPropriedades()
    this.tick()

    if (this.labelsId) {
      this.verificarColunasOcultadasPorPadrao()
    }
  }

  obterConfiguracoesTabela(): TabelaConfig {
    if (this.propriedadesCtrl?.propriedadeSelecionada?.metadados[this.labelsId]) {
      const config = JSON.parse(this.propriedadesCtrl?.propriedadeSelecionada?.metadados[this.labelsId])
      return config
    }
  }
  
  async removerDadosTabela(chave: string): Promise<void> {
    this.atualizandoColunas = true
    const [_, error] = await this.propriedadesCtrl.removerMetaPropriedade(chave)
    if(error) {
      console.error('error', error)
    }
    this.atualizandoColunas = false
  }
}
