import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  ActionSheetController,
  AlertController,
  IonContent,
  LoadingController,
  ModalController,
  PopoverController
} from '@ionic/angular'
import { ChartDataset } from 'chart.js'
import * as moment from 'moment'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { AnimaisService } from 'src/app/services/animais.service'
import {
  labelsManejosGerais,
  labelsSituacoesSanitarias,
  ManejosService
} from 'src/app/services/manejos.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import {
  EvolucaoAnimal,
  HistoricoAnimal,
  IGenealogia,
  IAnimalDetalhado,
  IPesagem,
  IAnimal
} from 'src/app/utils/interfaces/animais.interface'
import { IArvoreGenealogica } from 'src/app/utils/interfaces/genealogia.interface'
import { IListaTodosManejos, ListaManejoReprodutivo, ManejoSanitario } from 'src/app/utils/interfaces/manejos.interface'
import { Racas } from 'src/app/utils/racas'
import { ModalBovinoComponent } from '../modal-bovino/modal-bovino.component'
import { ModalEvolucaoAnimalComponent } from '../modal-evolucao-animal/modal-evolucao-animal.component'
import { opcoesFiltros } from '../../../components/vasta-tabela/opcoes-filtros'
import { ColunasAnimalSingleHistorico, ColunasCustoDoAnimal, ColunasListaTodosManejosAnimais } from 'src/app/utils/configuracoes-padrao'
import { listaManejosDisponiveis } from '../../manejos/manejos-disponiveis/lista-manejos-disponiveis'
import { ConfigTabela, IKpisCard } from 'src/app/utils/interfaces/tabela-colunas'
import { NovoFiltro, Ordenacao, TabelaFiltroExtra } from 'src/app/components/vasta-tabela/vasta-tabela'
import { IOptionButtons, OpcoesBotoesComponent } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { ModalAmpliarFotoComponent } from './modal-ampliar-foto/modal-ampliar-foto.component'
import { StatusRequest } from 'src/app/utils/interfaces/statusRequest.interface'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { RelatoriosService } from 'src/app/services/relatorios.service'
import { ModalCompraVendaComponent } from '../../financas/modal-compra-venda/modal-compra-venda.component'
import { FinanceiroFormularioTransacaoPage } from '../../financas/financeiro-formulario-transacao/financeiro-formulario-transacao.page'
import { ManejosTodosColunas } from '../../manejos/manejos-todos/manejos-todos.page'
declare const MiniMasonry
@Component({
  selector: 'app-bovino-single',
  templateUrl: './bovino-single.page.html',
  styleUrls: ['./bovino-single.page.scss']
})
export class BovinoSingleComponent implements OnInit {
  @ViewChild(IonContent) content: IonContent

  public defaultHref = '/animais'
  public animal: IAnimalDetalhado
  public genealogia: IGenealogia
  public historico: HistoricoAnimal[]
  public evolucao: EvolucaoAnimal[]
  public arvoreGenealogica: IArvoreGenealogica
  public isLoading = false
  public racas = Racas
  public abaAtiva = 'Geral'

  private masonry = null
  masonryTimestamp = new Date().getTime().toFixed(0)
  public blockResizeTrigger = false

  public idadeDoAnimal: string

  // Card Pesagens

  public labelsPesagens: string[] = []
  public dataSetPesagens: ChartDataset = {
    label: 'Pesagem',
    data: [180, 250, 210, 90, 240],
    type: 'line',
    borderWidth: 2,
    backgroundColor: ['rgba(80, 142, 181, 1.0)']
  }
  public showChartPesagens = false
  public dadosSimpleTablePesagens: IPesagem[] = []

  // tabelas

  public configHistorico = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: false,
    exibeTotais: false
  }
  public labelsHistorico = ColunasAnimalSingleHistorico

  public pesagens: IPesagem[] = []

  public carregamentoHistoricoAnimal: StatusTabela = 'carregando'

  public carregamentoManejosReprodutivos: StatusTabela = 'carregando'
  public labelsManejosReprodutivos = opcoesFiltros.labelsManejosReprodutivos
  public configManejosReprodutivos = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: true,
    exibeTotais: false
  }
  public manejosReprodutivos: ListaManejoReprodutivo[]
  public manejosReprodutivosCard: ListaManejoReprodutivo[]
  public situacoesReprodutivas = opcoesFiltros.labelsSituacaoReprodutiva
  public categorias = opcoesFiltros.labelsCategoriaAnimal

  public configManejosSanitario = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: true,
    selecao: {
      ativar: true
    },
    exibeTotais: false
  }
  public carregamentoManejosSanitarios: StatusTabela = 'carregando'
  public manejosSanitarios: ManejoSanitario[]
  public manejosSanitariosCard: ManejoSanitario[]

  public labelsSituacoesSanitarias = labelsSituacoesSanitarias

  public isModal = false
  public animalProp: IAnimalDetalhado

  public hash: string

  public labelsManejosGerais = labelsManejosGerais
  public carregamentoManejosGerais: StatusTabela = 'carregando'
  public manejosGerais
  public manejosGeraisCard

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (!this.blockResizeTrigger) this.updateMasonry(true)
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    this.updateMasonry()
  }

  carregamentoListaTodosManejos: StatusTabela = 'carregando'
  public labelsTodosManejos = ColunasListaTodosManejosAnimais
  public novoFiltroTodos: NovoFiltro = {}
  public novaOrdenacaoTodos: Ordenacao = { coluna: 'data', direcao: 'desc' }
  public dadosListaTodosManejos: IListaTodosManejos[] = []
  hashsSelecionadosTodosManejos: string[] = []
  configTodosManejos: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    impressaoTitulo: 'Manejos',
    selecao: {
      ativar: true
    },
    buscaDados: async () => {
      const [response, error] = await this.manejosCtrl.getListaTodosManejos({
        filtros: this.novoFiltroTodos,
        ordenacao: this.novaOrdenacaoTodos,
        paginate: { offset: 0, limit: 5000 }
      })
      return {
        dados: response?.manejos
      }
    },
    ativarDownload: true,
    ativarImpressao: true,
    selecionavel: true,
    excluirColunas: ['hash_lote', 'icone', 'nome'],
    exibeTotais: false
  }
  public dadosObjetoTodosManejos: {[key: string]: IListaTodosManejos} = {}

  public optionsButtonsSelecionadosListaTodosManejos: IOptionButtons[] = [
    {
      icone: 'trash',
      chave: 'remover-manejo',
      titulo: 'Remover Manejo',
      acao: ({ hashsSelecionados }: DadosLinhaTabela): void => {

        const original = this.dadosListaTodosManejos.filter(item => (hashsSelecionados as string[]).includes(item.hash))

        this.confirmarRemoverManejo(original)
      }
    }
  ]
  public filtrosExtrasTodos: TabelaFiltroExtra[] = [
    {
      chave: 'procedimento',
      titulo: 'Manejos',
      tipo: 'lista',
      icone: 'pesagem-controle',
      acao: async (event: Event, filtro: TabelaFiltroExtra): Promise<void> => {
        console.log(event)
        const selecionado = await this.selecionarTipoDeManejoTodos(event)
        if (selecionado) {
          this.novoFiltroTodos['procedimento'] = selecionado.chave

          filtro.valor = selecionado.titulo

          this.paginateTodosManejos.offset = 0
          this.paginateTodosManejos.total = 0
          this.paginateTodosManejos.currentPage = 1

          this.getTodosManejos()
        }
      }
    }
  ]
  manejosResposta: { [key: string]: number } = {}

  public optionsButtonsTodosManejos: IOptionButtons[] = [
    {
      icone: 'trash',
      chave: 'remover-manejo',
      titulo: 'Remover Manejo',
      acao: ({ original }: DadosLinhaTabela): void => {
        this.confirmarRemoverManejo([original])
      }
    },
    {
      icone: 'arrow-forward-circle-outline',
      chave: 'ver-manejo',
      titulo: 'Ver Manejo',
      acao: ({ original }: DadosLinhaTabela): void => {
        this.abrirManejo(original, true)
      }
    }
  ]
  public animaisSelecionados: IAnimal[] = []
  public opcoesFiltros: { [key: string]: { [key: string]: string } } = {
    estoque_item_nome: {}
  }
  public paginateTodosManejos: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  custosCards: IKpisCard[] = [
    {
      titulo: 'Custo de Compra',
      formatacao: 'currency',
      key: 'placeholder',
      escondeSeVazio: true
    },
    {
      titulo: 'Custo de Compra',
      formatacao: 'currency',
      key: 'custo_compra',
      escondeSeVazio: true
    },
    {
      titulo: 'Custo de Nutrição',
      formatacao: 'currency',
      key: 'custo_nutricao',
      escondeSeVazio: false
    },
    {
      titulo: 'Custo Sanitários',
      formatacao: 'currency',
      key: 'custo_sanitario',
      escondeSeVazio: false
    },
    {
      titulo: 'Custo Atribuído',
      formatacao: 'currency',
      key: 'custo_atribuido',
      escondeSeVazio: false
    },
    {
      titulo: 'Custo Total',
      formatacao: 'currency',
      key: 'custo_total',
      escondeSeVazio: false
    },
    {
      titulo: 'Valor de Venda',
      formatacao: 'currency',
      key: 'valor_venda',
      escondeSeVazio: true
    },
  ]
  custosValores = {
    custo_total: 0,
    custo_atribuido: 0,
    custo_compra: 0,
    custo_nutricao: 0,
    custo_sanitario: 0,
    valor_venda: 0
  }
  custosConfig: ConfigTabela = {
    toolbar: false,
    filtrosEspecificos: false,
    selecao: {
      ativar: false
    },
    exibeTotais: false,
  }
  custosCampos = []
  custosLabels = ColunasCustoDoAnimal
  carregamentoCustos: StatusTabela = 'carregando'
  hash_compra: string = ''
  hash_venda: string = ''

  constructor(
    private route: ActivatedRoute,
    private actionSheetCtrl: ActionSheetController,
    private animaisCtrl: AnimaisService,
    private loadingCtrl: LoadingController,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController,
    private router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    public planoBasicoCtrl: PlanoBasicoService,
    public relatoriosCtrl: RelatoriosService,
    private manejosCtrl: ManejosService
  ) {}

  async ngOnInit(): Promise<void> {
    this.updateMasonry()
    if (this.isModal) {
      this.animal = { ...this.animalProp }
      this.hash = this.animal.hash
    } else {
      this.hash = this.route.snapshot.paramMap.get('hash')
      const state = this.router.getCurrentNavigation().extras.state
      if (state?.animal) {
        this.animal = { ...state.animal }
      }
    }

    this.route.url.subscribe(() => {
      this.carregarInformacoesBovino(this.hash)

      if (this.abaAtiva === 'Manejos') {
        this.getTodosManejos()
      }
      if (this.abaAtiva === 'Custos') {
        this.getCustos()
      }
    })
  }

  ionViewDidEnter(): void {
    this.initMasonry()

    this.updateMasonry()
  }

  async carregarInformacoesBovino(hash: string): Promise<void> {
    this.getAnimal(hash)
    this.getArvoreGenealogica(hash)
    this.getEvolucaoAnimal(hash)
    this.getPesagensAnimal(hash)
    this.getManejosReprodutivos(hash)
    this.getManejosSanitarios(hash)
    this.getManejosGerais(hash)
  }

  async getAnimal(hash: string): Promise<void> {
    this.isLoading = true
    const [response, error] = await this.animaisCtrl.getAnimal(hash)
    if (response) {
      this.animal = response
      this.getIdadeDoAnimal()

      this.updateMasonry()
      this.changeDetectorRef.detectChanges()
      console.log({ response })
    }
    if(!response && !error) {
      this.router.navigateByUrl('/animais')
    }
    if (error) {
      console.error(error)
    }
    this.isLoading = false
  }

  async getArvoreGenealogica(hash: string): Promise<void> {
    const [response, error] = await this.animaisCtrl.getArvoreGenealogica(hash)
    if (response) {
      this.arvoreGenealogica = response
      this.updateMasonry()
      this.changeDetectorRef.detectChanges()
    }
    if (error) {
      console.log(error)
    }
  }

  async getHistoricoAnimal(hash: string): Promise<void> {
    this.carregamentoHistoricoAnimal = 'carregando'
    const [response, error] = await this.animaisCtrl.getHistoricoAnimal(hash)

    if (response) {
      this.historico = response.historico
      this.carregamentoHistoricoAnimal = 'sucesso'
      this.changeDetectorRef.detectChanges()
    }

    if (error) {
      console.error(error)
      this.carregamentoHistoricoAnimal = 'erro'
    }
  }

  async getEvolucaoAnimal(hash: string): Promise<void> {
    const [response, error] = await this.animaisCtrl.getEvolucaoAnimal(hash)

    if (response) {
      this.evolucao = response.evolucao
      this.updateMasonry()
      this.changeDetectorRef.detectChanges()
    }

    if (error) {
      console.error(error)
    }
  }

  async getManejosReprodutivos(hash: string): Promise<void> {
    this.carregamentoManejosReprodutivos = 'carregando'
    const [response, error] = await this.manejosCtrl.getManejosReprodutivosPorAnimal(hash)

    if (response) {
      this.manejosReprodutivos = response.manejos
      this.manejosReprodutivosCard = response.manejos.slice(0, 6)
      this.carregamentoManejosReprodutivos = 'sucesso'
      this.updateMasonry()
      this.changeDetectorRef.detectChanges()
    }

    if (error) {
      console.error(error)
      this.carregamentoManejosReprodutivos = 'erro'
    }
  }

  async getManejosSanitarios(hash: string): Promise<void> {
    this.carregamentoManejosSanitarios = 'carregando'
    const [response, error] = await this.manejosCtrl.getManejosSanitariosPorAnimal(hash)

    if (response) {
      this.manejosSanitarios = response.manejos
      this.manejosSanitariosCard = response.manejos?.slice(0, 6)
      this.carregamentoManejosSanitarios = 'sucesso'
      this.updateMasonry()
      this.changeDetectorRef.detectChanges()
    }

    if (error) {
      console.error(error)
      this.carregamentoManejosSanitarios = 'erro'
    }
  }

  async getManejosGerais(hash: string): Promise<void> {
    this.carregamentoManejosGerais = 'carregando'

    const [response, error] = await this.manejosCtrl.getTodosManejosGerais(hash)

    if (response) {
      this.manejosGerais = response.manejos
      this.manejosGeraisCard = response.manejos.slice(0, 6)

      this.carregamentoManejosGerais = 'sucesso'
      this.updateMasonry()
      this.changeDetectorRef.detectChanges()
    }

    if (error) {
      console.error(error)
      this.carregamentoManejosGerais = 'erro'
    }
  }

  getIdadeDoAnimal(): void {
    if (this.animal) {
      this.idadeDoAnimal = this.parseIdadeAnimal(this.animal.data_nascimento)
    }
  }

  parseIdadeAnimal(dataNascimento: string): string {
    const animalIdadeMeses = moment().diff(dataNascimento, 'months')
    let idade = animalIdadeMeses > 1 ? animalIdadeMeses + ' meses' : animalIdadeMeses == 0 ? 'Recém Nascido' : '1 mês'

    if (animalIdadeMeses >= 24) {
      const anos = Math.floor(animalIdadeMeses / 12)
      const meses = animalIdadeMeses % 12
      idade = anos + ' anos'
      idade += meses ? (meses > 1 ? ' e ' + meses + ' meses' : ' e ' + 1 + ' mês') : ''
    }

    return idade
  }

  async openModalEvolucaoAnimal(evolucao?: EvolucaoAnimal): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEvolucaoAnimalComponent,
      cssClass: 'custom-modal',
      componentProps: { animal: this.animal, evolucaoProp: evolucao }
    })
    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.success) {
      this.getEvolucaoAnimal(this.hash)
    }
  }

  async opcoesHistoricoAnimal(): Promise<void> {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Modificar Item',
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: 'Alterar Data',
          icon: 'calendar',
          handler: (): void => {
            console.log('Alterar Data')
          }
        },
        {
          text: 'Remover Item do Histórico',
          icon: 'trash',
          handler: (): void => {
            console.log('Remover Item do Histórico')
          }
        },
        {
          text: 'Cancel',
          icon: 'close',
          handler: (): void => {
            console.log('Cancel clicked')
          }
        }
      ]
    })
    await actionSheet.present()
  }

  async openModalBovino(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalBovinoComponent,
      cssClass: 'custom-modal',
      backdropDismiss: false,
      componentProps: { animalProp: this.animal }
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()
    if (data?.success) {
      this.carregarInformacoesBovino(this.animal.hash)
    }
  }

  async removerAnimal(hash: string): Promise<StatusRequest> {
    const loading = await this.utilsCtrl.showLoading('dots')

    const [response, error] = await this.animaisCtrl.deletarAnimal([hash])

    if (response) {
      this.router.navigateByUrl('/animais')
      this.utilsCtrl.dismissLoading(loading)
      return response
    }

    if (error) {
      this.utilsCtrl.dismissLoading(loading)
      console.log(error)
    }
  }

  async alertConfirmDeleteAnimal({ hash, nome, numeracao }: IAnimalDetalhado): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Remover Animal',
      message: `Confirmar remoção do animal ${nome || numeracao}`,
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Confirmar',
          handler: (): void => {
            this.removerAnimal(hash)
          }
        }
      ]
    })
    await alert.present()
  }

  async mudarFotoAnimal(imagemBase64: string): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('dots')

    const { url } = await this.enviarImagens(imagemBase64)

    if (url) {
      const [response, error] = await this.animaisCtrl.atualizarAnimal({
        hash: this.animal.hash,
        meta_profile: url,
        profile: url
      })

      if (response) {
        this.animal.profile = url
        this.changeDetectorRef.detectChanges()
      }

      if (error) {
        console.log(error)
      }

      this.utilsCtrl.dismissLoading(loading)
    }
  }

  async enviarImagens(imagem: string): Promise<{ url: string }> {
    const [response, error] = await this.animaisCtrl.enviarImagem({ tipo: 'avatar', imagem })

    if (response) {
      console.log(response)
      return response
    }

    if (error) {
      this.utilsCtrl.showToast('Erro no upload da imagem', 'bottom')
      console.log(error)
      return { url: '' }
    }
  }

  async alertConfirmDeletarFotoAnimal(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Deseja remover a foto do animal?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Confirmar',
          handler: (): void => {
            this.removerFotoAnimal()
          }
        }
      ]
    })

    await alert.present()
  }

  async removerFotoAnimal(): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('dots')
    const [response, error] = await this.animaisCtrl.atualizarAnimal({ hash: this.animal.hash, profile: '' })
    if (response) {
      this.animal.profile = ''
      this.changeDetectorRef.detectChanges()
    }

    if (error) {
      console.log(error)
    }

    this.utilsCtrl.dismissLoading(loading)
  }

  async ampliarFotoAnimal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAmpliarFotoComponent,
      cssClass: 'modal-ampliar-img',
      componentProps: { imagemAnimal: this.animal.profile }
    })

    await modal.present()
  }

  getBase64Url(file: Blob): Promise<string> {
    const reader: FileReader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = (e: ProgressEvent<FileReader>): void => {
        e.target.result
        const imgElement = document.createElement('img')
        imgElement.src = e.target.result as string

        imgElement.onload = (e): void => {
          const canvas = document.createElement('canvas')

          canvas.width = 100
          canvas.height = 100

          const imageSource = e.target as CanvasImageSource
          const imageStringfy = JSON.stringify(imageSource)

          const ctx: CanvasRenderingContext2D = canvas.getContext('2d')
          ctx.drawImage(imageSource, 0, 0, canvas.width, canvas.height)

          const srcEncoded = ctx.canvas.toDataURL(imageStringfy, 'image/jpeg')
          resolve(srcEncoded)
        }

        reader.onerror = (error): void => reject(error)
      }
    })
  }

  async getPesagensAnimal(hashAnimal: string): Promise<void> {
    this.showChartPesagens = false
    const [response, error] = await this.animaisCtrl.getPesagensAnimal(hashAnimal)

    if (response) {
      this.pesagens = response
      this.labelsPesagens = response
        .map((item) => moment(item.data_pesagem).format('DD/MM/YYYY'))
        .slice(0, 5)
        .reverse()
      this.dataSetPesagens.data = response
        .map((item) => item.peso_total)
        .slice(0, 5)
        .reverse()
      this.dadosSimpleTablePesagens = response.slice(0, 5)

      if (this.dataSetPesagens.data.length >= 3) {
        this.showChartPesagens = true
      }
      this.utilsCtrl.tick()
      this.updateMasonry()
      this.changeDetectorRef.detectChanges()
    }

    if (error) {
      console.error(error)
    }
  }

  get dataCadastroAnimal(): string {
    const hora = moment.utc(this.animal?.created_at).local().format('HH:mm:ss')
    const data = moment.utc(this.animal?.created_at).local().format('DD/MM/YYYY')
    return hora + ' de ' + data
  }

  get dataUltimaAtualizacaoAnimal(): string {
    const range = moment.utc(this.animal?.created_at).local().fromNow()
    return range
  }

  initMasonry(): void {
    if (this.masonryTimestamp && document.querySelector('.masonry_animal' + this.masonryTimestamp)) {
      this.masonry = new MiniMasonry({
        container: '.masonry_animal' + this.masonryTimestamp,
        gutter: 0,
        ultimateGutter: 0,
        baseWidth: 330,
        surroundingGutter: false
      })
    }
  }

  masonryTimeout = null
  updateMasonry(resizeReal = false): void {
    if (!this.masonry) {
      this.initMasonry()
    }

    this.changeDetectorRef.detectChanges()

    if (this.abaAtiva !== 'Geral') return

    clearTimeout(this.masonryTimeout)

    this.masonryTimeout = setTimeout(() => {
      this.masonry?.layout()

      if (!resizeReal) {
        this.blockResizeTrigger = true

        window.dispatchEvent(new Event('resize'))

        setTimeout(() => {
          this.blockResizeTrigger = false
        }, 400)
      }
    }, 200)
  }

  changeTab(valor: string): void {
    if (this.abaAtiva !== valor) {
      if (valor === 'Histórico') {
        this.getHistoricoAnimal(this.animal.hash)
      }
      if (valor === 'Manejos') {
        this.getTodosManejos()
      }
      if (valor === 'Custos') {
        this.getCustos()
      }

      this.abaAtiva = valor
    }

    this.updateMasonry()
  }

  navegarPorBovinos(hash: string): void {
    if (hash) {
      this.router.navigateByUrl(`/animais/${encodeURIComponent(hash)}`)
    }
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  abrirManejo(dataParam: string | DadosLinhaTabela, editar = false): void {
    if (this.isModal) return
    const data = typeof dataParam === 'string' ? dataParam : dataParam.original.data
    this.router.navigateByUrl(`/manejos/${data}`, {
      state: { data, editar, hashsAnimais: [this.animal.hash], animais: [this.animal] }
    })
  }

  async getTotaisCustos(): Promise<void> {
    const [response1, error1] = await this.relatoriosCtrl.custosELucratividade({
      hashs: [this.hash]
    })

    if (!error1) {
      const {
        custo_compra,
        custo_nutricional,
        custo_sanitario,
        custo_atribuido,
        custo_total,
      } = response1.totais
      this.custosValores.custo_compra = custo_compra
      this.custosValores.custo_nutricao = custo_nutricional
      this.custosValores.custo_sanitario = custo_sanitario
      this.custosValores.custo_atribuido = custo_atribuido
      this.custosValores.custo_total = custo_total
    }
  }

  abrirManejosDoDia(dados: DadosLinhaTabela): void {
    const data = dados.original.data
    const { tipo, manejo } = dados?.original || {}

    if (manejo == 'atribuicao') {
      this.eventoTransacoes(dados)
      return
    }

    const tiposCase = {
      lote: 'Lotes',
      animal: 'Animais',
      area: 'Áreas'
    }

    this.router.navigateByUrl(`/manejos/${data}`, {
      state: {
        data,
        abaAtivaParams: tiposCase[tipo as string]
      }
    })
  }

  async eventoTransacoes(dados: DadosLinhaTabela): Promise<void> {
    const { original: lancamento } = dados
    const modal = await this.modalCtrl.create({
      cssClass: 'tall-modal',
      component: FinanceiroFormularioTransacaoPage,
      componentProps: {
        lancamentoProp: {
          hash: lancamento.hash_transacao
        },
      }
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.sucesso) {
      this.getCustos()
    }
  }

  async getCustos(): Promise<void> {
    this.carregamentoCustos = 'carregando'
    this.getTotaisCustos()
    const [response, error] = await this.relatoriosCtrl.custoDoAnimal({
      filtros: {
        hash_animal: this.hash
      }
    })

    if (response) {
      console.log('response', response)
      this.hash_compra = response.dados.hash_compra
      this.hash_venda = response.dados.hash_venda
      const custosCampos = [...response.dados.nutricional, ...response.dados.sanitario, ...response.dados.atribuicao]
      custosCampos.sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime())
      this.custosCampos = custosCampos
      this.carregamentoCustos = 'sucesso'
    }

    if (error) {
      console.log(error)
      this.carregamentoCustos = 'erro'
    }
  }

  async abrirTransacao(tipo: 'compra' | 'venda'): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCompraVendaComponent,
      componentProps: {
        contas: [],
        tipoTransacao: tipo,
        dadosTransacaoProp: {
          hash: tipo == 'venda' ? this.hash_venda : this.hash_compra
        },
      },
      cssClass: 'modal-compra-venda'
    })

    await modal.present()
    await modal.onWillDismiss()

    this.getCustos()
  }

  async paginaAlterada(): Promise<void> {
    this.content?.scrollToTop(500)
    await this.getTodosManejos()
  }

  async selecionarTipoDeManejoTodos(ev: Event): Promise<{ chave: string; titulo: string; identificador: string }> {
    let options = []

    const allManejos = listaManejosDisponiveis.manejos.concat(
      listaManejosDisponiveis.manejosReprodutivos,
      listaManejosDisponiveis.manejosSanitarios,
      listaManejosDisponiveis.manejosAreas,
      listaManejosDisponiveis.manejosLotes,
      listaManejosDisponiveis.manejosGenetica
    )

    Object.keys(opcoesFiltros.labelsListaTodosManejos).map((chave) => { 
      const manejo = opcoesFiltros.labelsListaTodosManejos[chave]

      const icone = allManejos.find((manejo) => manejo.identificador === chave || manejo.tipo === chave)?.icon
      const cor = allManejos.find((manejo) => manejo.identificador === chave || manejo.tipo === chave)?.cor

      if (this.manejosResposta[chave]) {
        options.push({
          chave: chave,
          titulo: manejo,
          identificador: chave,
          qtd: this.manejosResposta[chave],
          cor: cor,
          icone
        })
      }
    })

    options.sort((a, b) => b.qtd - a.qtd)

    if(!options.length) {
      this.utilsCtrl.showToast('Carregando manejos cadastrados...', 'bottom')
      return
    }


    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: { options },
      event: ev,
      reference: 'trigger'
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()
    if (data) {
      return data
    }
  }

  getHashsSelecionados(selecionados: string[]): void {
    this.hashsSelecionadosTodosManejos = selecionados

    if (!this.hashsSelecionadosTodosManejos.length) {
      this.animaisSelecionados = []
    } else {
      const novosAnimais = this.animaisSelecionados.filter(
        (a) => this.hashsSelecionadosTodosManejos.includes(a.hash) && !this.animaisSelecionados.map((a) => a.hash).includes(a.hash)
      )
      this.animaisSelecionados = [...this.animaisSelecionados, ...novosAnimais]

      this.animaisSelecionados = this.animaisSelecionados.filter((a) => this.hashsSelecionadosTodosManejos.includes(a.hash))
    }
  }

  async getTodosManejos(): Promise<void> {
    this.content?.scrollToTop(500)
    this.carregamentoListaTodosManejos = 'carregando'

    this.novoFiltroTodos['hash_animal'] = this.animal.hash

    const [response, error] = await this.manejosCtrl.getListaTodosManejos({
      filtros: this.novoFiltroTodos,
      ordenacao: this.novaOrdenacaoTodos,
      paginate: this.paginateTodosManejos
    })

    if (response) {
      const colunasChaves = ColunasListaTodosManejosAnimais.filter(coluna => !coluna.fixa && !coluna.fixaFinal).map((coluna) => coluna.chave)
      this.manejosResposta = response.manejosDisponiveis
      const colunasManejosExistentes = []
      Object.keys(this.manejosResposta).map((manejo) => {
        if (ManejosTodosColunas[manejo]) {
          colunasManejosExistentes.push(...ManejosTodosColunas[manejo])
        }
      })
      let colunasEsconder = colunasChaves.filter((coluna) => !colunasManejosExistentes.includes(coluna) && !['procedimento', 'data', 'geral_observacao', 'created_at', 'updated_at'].includes(coluna))
      this.configTodosManejos.excluirColunas = colunasEsconder

      this.dadosListaTodosManejos = response?.manejos
      this.dadosListaTodosManejos.map((manejo) => {
        this.dadosObjetoTodosManejos[manejo.hash] = manejo
      })
      this.paginateTodosManejos.total = response?.totais?.total
      this.carregamentoListaTodosManejos = 'sucesso'
    }

    if (error) {
      this.carregamentoListaTodosManejos = 'erro'
      console.error(error)
    }
  }


  async confirmarRemoverManejo(dado: IListaTodosManejos[]): Promise<void> {

    const alert = await this.alertCtrl.create({
      header: 'Remover Manejo',
      message: 'Deseja remover esse(s) manejo(s)?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Remover',
          handler: async (): Promise<void> => {
            await this.removerManejo(dado)
          }
        }
      ]
    })

    await alert.present()
  }

  async removerManejo(dados: IListaTodosManejos[]): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circular', 'Removendo manejo(s)...')

    const [response, error] = await this.manejosCtrl.deleteManejo(dados)

    if (response) {
      await this.getTodosManejos()
      this.hashsSelecionadosTodosManejos = []
      this.utilsCtrl.showToast('Manejo(s) removido(s) com sucesso', 'bottom')
    }

    if (error) {
      console.error(error)
      this.utilsCtrl.showToast('Erro ao remover manejo(s)', 'bottom')
    }
    this.utilsCtrl.dismissLoading(loading)
  }

}
