<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Venda</div>

    <div class="notice-vendido">
      <span *ngIf="dados.novo_status === 'vendido'"> <ion-icon name="checkmark"></ion-icon> Animal Vendido </span>
      <span *ngIf="dados.novo_status !== 'vendido'"> <ion-icon name="checkmark"></ion-icon> Venda não concluída </span>
      <cta-buttons>
        <button (click)="verTransacao()">
          <ion-icon name="cash"></ion-icon>
          <span>Ver Transação</span>
        </button>
      </cta-buttons>
    </div>

    <!-- <vasta-input
      type="manejo-segment"
      [(value)]="dados.novo_status"
      [opcoes]="[
        { label: 'Vendido', value: 'vendido' },
        { label: 'Não Vendido', value: '' }
      ]"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.novo_status == 'vendido'"
      label="Transação"
      type="manejo-button"
      [value]="vendas[dados.venda_hash_transacao] ? (vendas[dados.venda_hash_transacao]?.meta_tipoSaida || '') + ' - ' + vendas[dados.venda_hash_transacao]?.data : ''"
      (buttonEvent)="selecionarVenda($event)"
      style="width: 200px"
    ></vasta-input>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.novo_status">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div> -->
  </section>
</div>
