<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">{{ nomeProcedimento[dados.procedimento] }}</div>

    <vasta-input
      type="manejo-checkbox"
      [label]="dados.descricao"
      [(valueCheckbox)]="dados.aplicado"
      (valueCheckboxChange)="dados.edited = true; emit()"
    ></vasta-input>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.aplicado">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
