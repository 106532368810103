<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Alteração de Número</div>
    <div class="numero-alerta">
      <vasta-input
        label="Número"
        type="manejo-input"
        [(value)]="dados.numero_novo"
        (valueChange)="dados.edited = true; emit()"
      ></vasta-input>

      <span *ngIf="isNumeroJaCadastrado" class="numero-ja-cadastrado">Número já cadastrado</span>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.numero_novo">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
