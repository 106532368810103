import { Injectable } from '@angular/core'
import { Preferences } from '@capacitor/preferences'
import { CanActivate, Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  async canActivate(): Promise<boolean> {
    const { value: user } = await Preferences.get({ key: 'fertili-token' })
    if (!user) {
      this.router.navigate(['login'])
      return false
    }

    return true
  }
}
