<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-searchbar
      [(ngModel)]="termo"
      [debounce]="500"
      (ionInput)="pesquisar($event.detail.value)"
      placeholder="Pesquisar"
      clearIcon="none"
      #searchBar
    ></ion-searchbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon icon="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-bottom">
  <div class="container-loading" *ngIf="loading">
    <ion-spinner name="dots"></ion-spinner>
  </div>

  <section class="container" [class.hidden]="!itensPesquisa?.animais.length">
    <h4 class="title">Animais</h4>

    <vasta-tabela
      labelsId="colunas_animais"
      [campos]="itensPesquisa?.animais || []"
      [config]="configAnimais"
      (selecionado)="abrirAnimal($event)"
    ></vasta-tabela>
  </section>

  <section class="container" [class.hidden]="!itensPesquisa?.lotes.length">
    <h4 class="title">Lotes</h4>

    <vasta-tabela
      [labels]="labelsLotes"
      [campos]="itensPesquisa?.lotes || []"
      [config]="configLotes"
      (selecionado)="abrirLote($event)"
    ></vasta-tabela>
  </section>

  <section class="container" [class.hidden]="!itensPesquisa?.areas.length">
    <h4 class="title">Áreas</h4>

    <vasta-tabela
      [labels]="labelsAreas"
      [campos]="itensPesquisa?.areas || []"
      [config]="configAreas"
      (selecionado)="abrirArea($event)"
    ></vasta-tabela>
  </section>

  <div
    *ngIf="!itensPesquisa?.areas.length && !itensPesquisa?.lotes.length && !itensPesquisa?.animais.length && !loading && termo"
    class="container-vazio"
  >
    Nenhum item correspondente a pesquisa
  </div>

  <div *ngIf="!termo" class="container-vazio">Buscar animais, áreas ou lotes</div>
</ion-content>
