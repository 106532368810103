<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Evolução do Animal - {{ animal.nome || animal.numeracao }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row>
    <ion-col size="6" style="padding-left: 0">
      <vasta-input
        [label]="'Idade na foto'"
        [placeholder]="'Selecionar'"
        [type]="'select'"
        [(value)]="evolucao.idade_animal"
        [opcoes]="[
          { label: 'Recém Nascido', value: 0 },
          { label: '1 mês', value: 1 },
          { label: '2 meses', value: 2 },
          { label: '3 meses', value: 3 },
          { label: '4 meses', value: 4 },
          { label: '5 meses', value: 5 },
          { label: '6 meses', value: 6 },
          { label: '7 meses', value: 7 },
          { label: '8 meses', value: 8 },
          { label: '9 meses', value: 9 },
          { label: '10 meses', value: 10 },
          { label: '11 meses', value: 11 },
          { label: '12 meses', value: 12 }
        ]"
      ></vasta-input>
    </ion-col>
    <ion-col size="6" style="padding-left: 0">
      <vasta-input
        label="Peso na foto"
        placeholder="Ex: 150 kg"
        type="number"
        mask="separator.2"
        maskSuffix=" kg"
        [(value)]="evolucao.peso"
      ></vasta-input>
    </ion-col>
  </ion-row>

  <div *ngIf="!evolucao.link" class="card-selecionar-imagem permissao-escrita-animais">
    <enviar-imagem (imagemSrc)="adicionarImagem($event)"></enviar-imagem>
    <span>Selecionar Imagem</span>
  </div>

  <div *ngIf="evolucao.link" class="ion-padding ion-margin-top" ngClass="card-imagem">
    <img [src]="imagemAnimal" />
  </div>
  <cta-buttons *ngIf="imagemAnimal">
    <button class="primary-clear permissao-escrita-animais" style="position: relative">
      <enviar-imagem (imagemSrc)="adicionarImagem($event)"></enviar-imagem>
      <ion-icon name="pencil-outline"></ion-icon>
      <span>Editar Imagem</span>
    </button>
    <button class="danger-clear permissao-escrita-animais" (click)="removerImagemAnimal()">
      <ion-icon name="trash-outline"></ion-icon>
      <span>Remover Imagem</span>
    </button>
  </cta-buttons>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <cta-buttons class="ion-float-left" *ngIf="evolucaoProp">
      <button class="danger-clear permissao-escrita-animais" (click)="alertConfirmRemoverEvolucaoAnimal()">
        <ion-icon name="trash"></ion-icon>
        <span>Remover</span>
      </button>
    </cta-buttons>
    <cta-buttons class="ion-float-right">
      <button
        class="primary permissao-escrita-animais"
        (click)="salvarEvolucaoAnimal()"
        [disabled]="!evolucao.link || !evolucao.peso || !evolucao.idade_animal"
      >
        <ion-icon name="checkmark-outline"></ion-icon>
        <span>Salvar</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
