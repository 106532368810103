import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { EstoqueService } from 'src/app/services/estoque.service'
import { ColunasEstoqueNutricionalLista } from 'src/app/utils/configuracoes-padrao'
import { EstoqueNutricional } from 'src/app/utils/interfaces/estoque.interface'
import { EstoqueNutricionalCadastrarPage } from '../estoque-nutricional-cadastrar/estoque-nutricional-cadastrar.page'

@Component({
  selector: 'app-modal-estoque-nutricional',
  templateUrl: './modal-estoque-nutricional.component.html',
  styleUrls: ['./modal-estoque-nutricional.component.scss']
})
export class ModalEstoqueNutricionalComponent implements OnInit {
  public labelsEstoqueNutricional = ColunasEstoqueNutricionalLista
  public estoqueNutricional: EstoqueNutricional[] = []
  public carregamentoEstoqueNutricional: StatusTabela = 'carregando'
  public configEstoqueNutricional = {
    toolbar: false,
    filtrosEspecificos: true,
    selecao: {
      ativar: true
    },
    exibeTotais: false,
    excluirColunas: ['mais']
  }
  public hash_not_in = []
  public hashsSelecionados: string[] = []
  public multiplos = true
  public data_custo = ''
  public meta_tipo_do_estoque_not_in = ''

  public modalOverlay1: HTMLIonModalElement

  constructor(private modalCtrl: ModalController, private estoqueCtrl: EstoqueService) {}

  async ngOnInit(): Promise<void> {
    if (this.multiplos) {
      this.labelsEstoqueNutricional = [
        {
          tipo: 'checkbox'
        },
        ...ColunasEstoqueNutricionalLista
      ]
    }
    this.carregarDadosEstoqueNutricional()
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  async carregarDadosEstoqueNutricional(): Promise<void> {
    this.carregamentoEstoqueNutricional = 'carregando'
    const [response, error] = await this.estoqueCtrl.getEstoqueNutricional({
      filtros: {
        data_custo: this.data_custo,
        hash_not_in: this.hash_not_in,
        meta_tipo_do_estoque_not_in: this.meta_tipo_do_estoque_not_in
      }
    })

    if (response) {
      this.estoqueNutricional = response.estoques
      this.carregamentoEstoqueNutricional = 'sucesso'
      console.log(response)
    }

    if (error) {
      console.log(error)
      this.carregamentoEstoqueNutricional = 'erro'
    }
  }

  async selecionarItemEstoqueNutricional({ original }: DadosLinhaTabela): Promise<void> {
    await this.modalCtrl.dismiss(original)
  }

  async cadastrarItemNutricional(): Promise<void> {
    this.modalOverlay1 = await this.modalCtrl.getTop()

    const modal = await this.modalCtrl.create({
      component: EstoqueNutricionalCadastrarPage,
      cssClass: 'custom-modal'
    })
    modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.success) {
      const [response, error] = await this.estoqueCtrl.getEstoqueNutricional()

      if (response) {
        const [itemSalvo] = response.estoques.filter((item) => item.hash === data.hashs[0])
        this.modalOverlay1.dismiss(itemSalvo)
      }

      if (error) {
        console.log(error)
      }
    }
  }

  confirmarItens(): void {
    const itensSelecionados = this.estoqueNutricional.filter((item) => this.hashsSelecionados.includes(item.hash))

    this.modalCtrl.dismiss({ itensSelecionados })
  }
}
