<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ propPiquete ? 'Editar Piquete' : 'Cadastrar Novo Piquete' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <cta-buttons>
    <button (click)="openModalDesenharAreaPiquete()">
      <ion-icon name="pencil"></ion-icon>
      <span>{{ piquete.area ? 'Editar Demarcação do Piquete' : 'Demarcar Piquete' }}</span>
    </button>
  </cta-buttons>
</ion-header>

<ion-content>
  <vasta-input label="Nome" type="text" [(value)]="piquete.nome"></vasta-input>

  <vasta-input
    label="Tamanho em HA"
    type="number"
    maskSuffix=" HA"
    mask="separator.2"
    [(value)]="piquete.tamanho"
  ></vasta-input>

  <vasta-input
    label="Lotação Máxima em UA"
    type="int"
    [(value)]="piquete.max_lotacao"
    maskSuffix=" UA"
    mask="separator.0"
  ></vasta-input>

  <vasta-input label="Observação" type="textarea" [(value)]="piquete.observacao"></vasta-input>
</ion-content>

<ion-footer>
  <cta-buttons>
    <button
      class="primary permissao-escrita-areas"
      (click)="salvarPiquete()"
      [disabled]="!piquete.nome || loadingSalvarAreaPiquete"
    >
      <ion-icon *ngIf="!loadingSalvarAreaPiquete" name="checkmark"></ion-icon>
      <ion-spinner *ngIf="loadingSalvarAreaPiquete" name="dots"></ion-spinner>
      <span>{{ loadingSalvarAreaPiquete ? 'Salvando' : 'Salvar' }}</span>
    </button>
  </cta-buttons>
  <cta-buttons *ngIf="propPiquete">
    <button
      class="danger-clear permissao-escrita-areas"
      (click)="alertConfirmDeleteAreaPiquete(propPiquete)"
      [disabled]="loadingExcluirAreaPiquete"
    >
      <ion-icon *ngIf="!loadingExcluirAreaPiquete" name="trash"></ion-icon>
      <ion-spinner *ngIf="loadingExcluirAreaPiquete" name="dots"></ion-spinner>
      <span>Remover Piquete</span>
    </button>
  </cta-buttons>
</ion-footer>
