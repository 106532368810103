import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { ModalListaLotesPage } from 'src/app/pages/bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-geral-movimentacao-lote',
  templateUrl: './manejo-geral-movimentacao-lote.component.html',
  styleUrls: ['./manejo-geral-movimentacao-lote.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManejoGeralMovimentacaoLoteComponent implements OnInit {
  @Input('dados') dados = { hash_lote: '', observacao: '', edited: false }
  @Input('data') data: string

  @Input('lotesAssociados') lotesAssociados: { [key: string]: ILote } = {}
  @Output() lotesAssociadosChange = new EventEmitter<{ [key: string]: ILote }>()

  @Input('opcoesRapidasLotes') opcoesRapidasLotes: string[] = []
  @Output() opcoesRapidasLotesChange = new EventEmitter<string[]>()

  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  constructor(private popoverCtrl: PopoverController, private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  emit(): void {
    this.opcoesRapidasLotes = [...new Set(this.opcoesRapidasLotes)].slice(0, 3)
    this.opcoesRapidasLotesChange.emit(this.opcoesRapidasLotes)
    this.lotesAssociadosChange.emit(this.lotesAssociados)
    this.dadosChange.emit(this.dados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.hash_lote = ''
      this.emit()
    }
  }

  async selecionarLote(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaLotesPage,
      componentProps: { tabela: true },
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.lotes) {
      const [lote] = data.lotes

      this.opcoesRapidasLotes.unshift(lote.hash)
      this.lotesAssociados[lote.hash] = lote

      this.selecionarLoteRapido(lote.hash)
    }
  }

  selecionarLoteRapido(hash: string): void {
    this.dados.edited = true
    this.dados.hash_lote = hash
    this.emit()
  }
}
