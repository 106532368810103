import { Injectable } from '@angular/core'
import { ApiService } from './api/api.service'
import { Product, ProductDetailsResponse, PurchaseProductResponse, Subscriptions, Transaction } from 'capacitor-subscriptions'
import { IonicUtilsService } from './utils/ionic-utils.service'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'

@Injectable({
  providedIn: 'root'
})
export class InAppPurchasesService {
  constructor(
    private apiCtrl: ApiService,
    private utilsCtrl: IonicUtilsService,
  ) {}

  async getPreco(): Promise<Product> {
    console.warn("🐮 ~ GeralPage ~ getDataSubscription")

    let productData: ProductDetailsResponse = await Subscriptions.getProductDetails({
      productIdentifier:  "avancado_anual"
    })

    if(productData.responseCode == 0) {
      return productData.data
    } else {
      console.error(productData.responseMessage)
      this.utilsCtrl.showToast("Erro ao buscar informações do produto")
      throw new Error("Erro ao buscar informações do produto")
    }
  }

  async assinar(): Promise<boolean> {
    console.warn("🐮 ~ GeralPage ~ startSubscription")

    const response: PurchaseProductResponse = await Subscriptions.purchaseProduct({
      productIdentifier:  "avancado_anual",
    })
    
    console.warn("🐮 ~ GeralPage ~ startSubscription ~ response:", response)

    this.utilsCtrl.showToast(response.responseMessage)

    if(response.responseCode == 0) {
      return await this.checkValidSubscription()
    }

    return false
  }
  
  async checkValidSubscription(): Promise<boolean> {
    console.warn("🐮 ~ GeralPage ~ startSubscription")

    const response = await Subscriptions.getCurrentEntitlements()

    const ids = Object.keys(response.data)

    console.warn("🐮 ~ InAppPurchasesService ~ checkSubscriptions ~ response:", response)

    const entitlements: Transaction[] = []
    
    for (const id of ids) {
      const entitlement = response.data[id]
      entitlements.push(entitlement)
    }

    console.warn("🐮 ~ InAppPurchasesService ~ checkSubscriptions ~ entitlements:", entitlements)

    const validEntitlements = entitlements.filter(entitlement => {
      let valid = true

      if(entitlement.expiryDate) {
        if(!(new Date(entitlement.expiryDate) > new Date())) {
          valid = false
        }
      }

      if (entitlement.isTrial) {
        valid = false
      }

      return valid
    })
    
    const checkTransactionID = validEntitlements[0]?.transactionId

    if(checkTransactionID) {
      try {
        const response = (await this.apiCtrl.post({
          url: "usuario/assinatura/appstore/validar",
          body: {
            transactionId: checkTransactionID
          }
        })) as StatusRequest

        return !!response.success
        
      } catch (error) {
        console.error(error)
      }
    } else {
      return false
    }
  }
}
