import { Injectable } from '@angular/core'
import { IAnimalPayload, IReprodutorExterno, ParamsPesagem } from '../utils/interfaces/animais.interface'
import { ResponseHashs, ResponseImportacaoAnimais } from '../utils/interfaces/responseImportacao.interface'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'
import { ApiService } from './api/api.service'
import {
  IManejoGeneticaUnitario,
  IManejoGeralUnitario,
  IManejoLote,
  ManejoNutricional,
  ManejoOrdenha,
  ManejoReprodutivoUnitario,
  ManejoSanitarioEstoque,
  ManejoSanitarioUnitario
} from './manejos.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'
import { ManejoArea } from '../utils/interfaces/manejos.interface'

export interface ApiError extends Error {
  errors?: {
    field?: string
    message?: string
  }
  status?: number
}

@Injectable({
  providedIn: 'root'
})
export class ImportacaoService {
  constructor(private apiCtrl: ApiService, private permissoesCtrl: PermissoesUsuarioService) {}

  async salvarImportacaoAnimais(dados: {
    animais: IAnimalPayload[]
  }): Promise<[ResponseImportacaoAnimais, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'animais/importar',
        body: dados
      })) as ResponseImportacaoAnimais

      if (response) {
        this.apiCtrl.setPassoFunil('360_cadastrou_animal')
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoAnimaisExternos(dados: {
    animais: IReprodutorExterno[]
  }): Promise<[ResponseImportacaoAnimais, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'animais/externos/importar',
        body: dados
      })) as ResponseImportacaoAnimais

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }


  async getNumeracoesExistentes(): Promise<[string[], Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'animais/numero/todos'
      })) as string[]
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoPesagens(dadosPesagens: ParamsPesagem[]): Promise<[StatusRequest, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = await this.apiCtrl.postComPrefixoPropriedade({
        url: 'pesagens',
        body: dadosPesagens
      }) as StatusRequest
      if (response) {
        this.apiCtrl.setPassoFunil('360_cadastrou_pesagem')
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoNutricional(dadosNutricionais: ManejoNutricional[]): Promise<[ResponseHashs, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/nutricional/importar',
        body: { manejos: dadosNutricionais }
      }) as ResponseHashs
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoManejosSanitariosEstoque(dadosSanitarios: ManejoSanitarioEstoque[]): Promise<[ResponseHashs, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/sanitario-estoque/importar',
        body: { manejos: dadosSanitarios }
      })) as ResponseHashs
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoLeite(dadosOrdenhas: ManejoOrdenha[]): Promise<[StatusRequest, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = await this.apiCtrl.postComPrefixoPropriedade({
        url: 'leite',
        body: dadosOrdenhas
      }) as StatusRequest
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoManejosLotes(dadosManejos: { manejos: IManejoLote[] }): Promise<[ResponseHashs, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/lote/importar',
        body: dadosManejos
      })) as ResponseHashs
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoManejosReprodutivos(dadosManejos: { manejos: ManejoReprodutivoUnitario[] }): Promise<[ResponseHashs, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/reprodutivos/importar',
        body: dadosManejos
      })) as ResponseHashs
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoManejosSanitarios(dadosManejos: { manejos: ManejoSanitarioUnitario[] }): Promise<[ResponseHashs, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/sanitarios/importar',
        body: dadosManejos
      })) as ResponseHashs
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoManejosGerais(dadosManejos: {
    manejos: IManejoGeralUnitario[]
  }): Promise<[ResponseHashs, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/gerais/importar',
        body: dadosManejos
      })) as ResponseHashs
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async salvarImportacaoManejosAreas(dadosManejos: {
    manejos: ManejoArea[]
  }): Promise<[ResponseHashs, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/areas/importar',
        body: dadosManejos
      })) as ResponseHashs
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }

  }

  async salvarImportacaoManejosGeneticos(dadosManejos: {
    manejos: IManejoGeneticaUnitario[]
  }): Promise<[ResponseHashs, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/genetica/importar',
        body: dadosManejos
      })) as ResponseHashs
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }
}
