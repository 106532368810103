<div class="container-lista">
  <button (click)="confirmar('cadastro')">
    <ion-icon name="bovino"></ion-icon>
    <div class="conteudo">
      <span>Um Animal</span>
    </div>
  </button>
  <button (click)="confirmar('cadastro-multiplo')">
    <ion-icon name="rebanho"></ion-icon>
    <div class="conteudo">
      <span>Múltiplos Animais</span>
    </div>
  </button>
</div>
