import { Component, Input, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { listaManejosDisponiveis } from 'src/app/pages/manejos/manejos-disponiveis/lista-manejos-disponiveis'
import { Exposicao } from 'src/app/utils/interfaces/manejos.interface'

@Component({
  selector: 'app-popover-lista-exposicoes',
  templateUrl: './popover-lista-exposicoes.component.html',
  styleUrls: ['./popover-lista-exposicoes.component.scss']
})
export class PopoverListaExposicoesComponent implements OnInit {
  @Input('data') data = ''
  @Input('exposicoes') exposicoes = []
  public manejosDisponiveis = listaManejosDisponiveis.manejos
    .concat(listaManejosDisponiveis.manejosReprodutivos, listaManejosDisponiveis.manejosSanitarios)
    .reduce((acc, item) => {
      acc[item.identificador] = { icon: item.icon, nome: item.nome }
      return acc
    }, {})

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  async selecionarExposicao(exposicao: Exposicao): Promise<void> {
    await this.popoverCtrl.dismiss(exposicao)
  }
}
