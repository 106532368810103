import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'vasta-segment',
  templateUrl: './vasta-segment.component.html',
  styleUrls: ['./vasta-segment.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class VastaSegmentComponent implements OnInit {
  @Input('tabs') tabs: string[]
  @Input('disabledTabs') disabledTabs: string[] = []
  @Input('tabActive') tabActive: string
  @Output() tabActiveChange: EventEmitter<string> = new EventEmitter()

  constructor() {}

  ngOnInit(): void {
    this.tabActive = this.tabActive || this.tabs[0]
  }

  selectTab(tab: string): void {
    this.tabActive = tab
    this.tabActiveChange.emit(this.tabActive)
  }
}
