import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertController, ModalController } from '@ionic/angular'
import { IOptionButtons } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { EstoqueService } from 'src/app/services/estoque.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ColunasEstoqueBiologicoLancamento } from 'src/app/utils/configuracoes-padrao'
import { EstoqueBiologico } from 'src/app/utils/interfaces/estoque.interface'
import { ConfigTabela, IKpisCard } from 'src/app/utils/interfaces/tabela-colunas'
import { EstoqueCadastrarPage } from '../estoque-cadastrar/estoque-cadastrar.page'
import { EstoqueLancamentoPage } from '../estoque-lancamento/estoque-lancamento.page'

@Component({
  selector: 'app-estoque-single',
  templateUrl: './estoque-single.page.html',
  styleUrls: ['./estoque-single.page.scss']
})
export class EstoqueSinglePage implements OnInit {
  public defaultHref = '/estoque'
  item: EstoqueBiologico = null
  estoqueBiologicoColunas = ColunasEstoqueBiologicoLancamento
  estoquesBiologicos = []
  estoqueBiologicoConfig: ConfigTabela = {
    toolbar: true,
    ocultarFiltros: true,
    filtrosEspecificos: false
  }
  carregamentoEstoque: StatusTabela = 'carregando'
  public optionsButtonsItens: IOptionButtons[] = [
    {
      icone: 'pencil',
      chave: 'editarItem',
      titulo: 'Editar Lançamento',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        this.cadastroMovimentacao(linha)
      }
    },
    {
      chave: 'apagarItem',
      icone: 'trash',
      titulo: 'Excluir Lançamento',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        this.alertRemoverItem({ item: linha.original as unknown as EstoqueBiologico })
      }
    }
  ]
  optionsKpisCard: IKpisCard[] = [
    { key: 'touro_nome', titulo: 'Touro', escondeSeVazio: true },
    { key: 'doadora_nome', titulo: 'Doadora', escondeSeVazio: true },
    { key: 'quantidade', titulo: 'Doses', escondeSeVazio: false },
    { key: 'tipo_de_palheta', titulo: 'Palheta', escondeSeVazio: true },
    { key: 'fornecedor_nome', titulo: 'Fornecedor', escondeSeVazio: true },
    { key: 'extra_propriedade_nome', titulo: 'Propriedade', escondeSeVazio: true },
    { key: 'codigo_no_rack', titulo: 'Partida', escondeSeVazio: true },
    { key: 'botijao', titulo: 'Botijao', escondeSeVazio: true }
  ]

  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 20,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  rxState = null

  constructor(
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    private estoqueCtrl: EstoqueService,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController
  ) {}

  async ngOnInit(): Promise<void> {
    const hash = this.route.snapshot.paramMap.get('hash')
    const state = this.router.getCurrentNavigation().extras.state
    if (state?.item) {
      this.item = { ...state.item }
    }

    this.route.url.subscribe(() => {
      this.carregarDadosEstoqueBiologicoSingle(hash)
      this.buscarLancamentos(hash)
    })
  }

  async buscarLancamentos(hash?: string): Promise<void> {
    this.carregamentoEstoque = 'carregando'
    const [response, error] = await this.estoqueCtrl.getEstoqueBiologicoSingle({
      filtros: {
        hash_estoque: hash || this.item?.hash
      },
      paginate: this.paginate
    })

    if (response) {
      this.paginate.total = response.totais.total
      this.estoquesBiologicos = response.lancamentos
      this.carregamentoEstoque = 'sucesso'
      console.log(response)
    }

    if (error) {
      console.log(error)
      this.carregamentoEstoque = 'erro'
    }
  }

  async carregarDadosEstoqueBiologicoSingle(hash?: string): Promise<void> {
    this.carregamentoEstoque = 'carregando'
    const [response, error] = await this.estoqueCtrl.getEstoqueBiologico({
      filtros: {
        hash: hash || this.item?.hash
      },
      paginate: this.paginate
    })

    if (response) {
      this.item = response.estoques[0]
      this.carregamentoEstoque = 'sucesso'
      console.log(response)
    }

    if (error) {
      console.log(error)
      this.carregamentoEstoque = 'erro'
    }
  }

  async editarItem(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: EstoqueCadastrarPage,
      cssClass: 'custom-modal',
      componentProps: {
        itemProp: this.item
      }
    })
    modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      this.paginate.currentPage = 1
      this.paginate.offset = 0

      this.carregarDadosEstoqueBiologicoSingle()
    }
  }

  async cadastroMovimentacao(linha?: DadosLinhaTabela): Promise<void> {
    if (linha?.original?.tipo == 'manejo') {
      this.router.navigateByUrl(`/manejos/${linha.original.data_lancamento}`)
    } else {
      const modal = await this.modalCtrl.create({
        component: EstoqueLancamentoPage,
        cssClass: 'custom-modal',
        componentProps: { lancamentoProp: linha?.original, hashEstoqueProp: this.item.hash, estoqueProp: this.item }
      })
      modal.present()
      const { data } = await modal.onWillDismiss()
      if (data) {
        this.paginate.currentPage = 1
        this.paginate.offset = 0
  
        await this.buscarLancamentos()
      }
    }
  }

  async alertRemoverItem(opcoes?: { item?: EstoqueBiologico }): Promise<void> {
    const {
      item: { hash, tipo }
    } = opcoes || {}

    if (tipo == 'manejo') {
      this.utilsCtrl.showToast('Para excluir este lançamento remova-o do manejo', 'bottom')
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Remover lançamento',
        message: 'Confirmar remoção do lançamento?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: 'Remover',
            handler: async (): Promise<void> => {
              const loading = await this.utilsCtrl.showLoading('circular', 'Removendo lançamento...')

              const [response, error] = await this.estoqueCtrl.deleteEstoqueBiologicoLancamento({ hash })

              if (response) {
                this.carregarDadosEstoqueBiologicoSingle()
                await this.buscarLancamentos()
                this.utilsCtrl.showToast('Itens removidos com sucesso!', 'bottom')
                this.utilsCtrl.tick()
              }

              if (error) {
                this.utilsCtrl.showToast('Erro ao remover lançamento!', 'bottom')
                console.error(error)
              }

              this.utilsCtrl.dismissLoading(loading)
            }
          }
        ]
      })

      await alert.present()
    }
  }
}
