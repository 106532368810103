import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-modal-ampliar-foto',
  templateUrl: './modal-ampliar-foto.component.html',
  styleUrls: ['./modal-ampliar-foto.component.scss']
})
export class ModalAmpliarFotoComponent implements OnInit {
  public imagemAnimal: string

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  async dismiss(): Promise<void> {
    await this.modalCtrl.dismiss()
  }
}
