import { ApplicationRef, Component, OnInit } from '@angular/core'
import JSZip from 'jszip'
import { kmlToJson } from 'kml-to-json'
import { xml2json } from 'xml-js'
import { Kml } from 'kml-to-json/dist/interfaces/kml.interface'
import { IArea, IAreaPiquete, ICadastroArea } from '../../../utils/interfaces/areas.interface'
import { AreasService } from 'src/app/services/areas.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { AlertController, ModalController } from '@ionic/angular'
import { ColunasImportacaoKML } from 'src/app/utils/configuracoes-padrao'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { DadosLinhaTabela } from 'src/app/components/vasta-tabela/tabela-types'

interface Placemark {
  text: string
  type: 'point' | 'polygon'
  coordinates: string
}

interface KmlPlacemark {
  name: {
    _text: string
  }
  LineString: {
    coordinates: {
      _text: string
    }
  }
  Point: {
    coordinates: {
      _text: string
    }
  },
  Polygon: {
    outerBoundaryIs: {
      LinearRing: {
        coordinates: {
          _text: string
        }
      }
    }
  }
}

@Component({
  selector: 'app-importar-kml',
  templateUrl: './importar-kml.page.html',
  styleUrls: ['./importar-kml.page.scss']
})
export class ImportarKMLPage implements OnInit {

  areasMapa: IArea[] = []
  public placemarksList: Placemark[] = []
  public hashArea = ''

  public config: ConfigTabela = {
    selecionavel: true,
    selecao: {
      ativar: true
    },
    preload: true,
    toolbar: true,
    filtrosEspecificos: false,
    orderby: 'nome',
    orderbyDirection: 'desc',
    ativarImpressao: false,
    ativarDownload: false,
    exibeTotais: false
  }

  public labels = ColunasImportacaoKML
  public hashsSelecionados = []

  public linkArquivoKml: string = ''

  constructor(
    public alertCtrl: AlertController,
    public areasCtrl: AreasService,
    private utilsCtrl: IonicUtilsService,
    private ref: ApplicationRef,
    private modalCtrl: ModalController,
    private propriedadesCtrl: PropriedadesService
  ) {}

  ngOnInit(): void {
    this.hashsSelecionados = this.areasCtrl.importacaoKMLDados.map(a => a.hash)
    this.getHashsSelecionados(this.hashsSelecionados)
  }

  importarKml(): void {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.kml,.kmz'
    input.click()

    let areasData: {
      nome: string
      coordinates: string
    }[] = []

    input.onchange = async (event): Promise<void> => {
      const target = event.target as HTMLInputElement
      const fileType = target.files[0].name.split('.').pop()
      const file = target.files[0]

      let xml = null

      if (fileType === 'kml' && file) {
        areasData = await this.processaKMLAlternativo(file)
      }

      if (fileType === 'kml' && !areasData.length) {
        // converter kml para json
        const json = await kmlToJson(file)

        // pegar o xml dentro do json
        const innerXml = this.findXmlString(json)

        // regex para remover tags específicas que podem vir sem início ou fim
        const regex = /<\/?(kml-style|folder|document|Style|gx:cascadingstyle)[^>]*>/g
        const xmlClear = innerXml?.replace(regex, '')

        xml = `
            <?xml version="1.0" encoding="UTF-8"?>
              <kml xmlns="http://www.opengis.net/kml/2.2">
                ${xmlClear}
             </kml>`
      }

      if (fileType === 'kmz') {
        xml = await this.convertKmzToKml(file)
      }

      // converter xml para json
      const xmlToJson = xml2json(xml, { compact: true, spaces: 4 })

      // pegar placemarks do json
      const placemarks = this.findPlacemarks(JSON.parse(xmlToJson).kml)
      console.warn('🚀 ~ input.onchange= ~ placemarks:', placemarks)
      // formatar placemarks
      let i = 1
      this.placemarksList = placemarks.reduce((acc: Placemark[], placemark: KmlPlacemark) => {
        const text = placemark.name._text || ('Área sem nome ' + i++)
        const type: 'point' | 'polygon' = placemark.Polygon || placemark.LineString ? 'polygon' : placemark.Point ? 'point' : null
        const coordinates = JSON.stringify(placemark.Polygon?.outerBoundaryIs?.LinearRing?.coordinates?._text?.replace(/\n|\t/g, '') || placemark.LineString?.coordinates?._text?.replace(/\n|\t/g, '') || placemark.Point?.coordinates._text?.replace(/\n|\t/g, ''))

        acc.push({
          text,
          type,
          coordinates: coordinates ? JSON.parse(coordinates) : undefined
        })

        return acc
      }, []) as Placemark[]

      // filtrar apenas placemarks que sejam do tipo polygon
      this.placemarksList = this.placemarksList.filter((placemark) => placemark.type === 'polygon')

      areasData.map((area) => {
        if(this.placemarksList.find(p => p.text === area.nome)) return
        this.placemarksList.push({
          text: area.nome,
          type: 'polygon',
          coordinates: area.coordinates
        })
      })

      console.log({
        placemarks: this.placemarksList
      })

      this.processarAreas()

      // salva arquivo kml no banco
      if (file) {
        const reader = new FileReader()

        reader.onload = async (e): Promise<void> => {
          const loading = await this.utilsCtrl.showLoading('circles', 'Carregando KML')
          const conteudoArquivo = e.target.result as string
          this.salvarKml(file.name, conteudoArquivo)
          this.utilsCtrl.dismissLoading(loading)
        }

        reader.readAsText(file)
      }
    }
  }

  async salvarKml(arquivo: string, conteudoDoArquivo: string): Promise<void> {
    const [response, error] = await this.areasCtrl.enviarKml({ arquivo, conteudo: conteudoDoArquivo })

    if (error) {
      this.utilsCtrl.showToast('Erro ao carregar XML', 'bottom')
      return
    }

    this.linkArquivoKml = response.url
  }

  convertKmzToKml(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      JSZip.loadAsync(file).then(async (zip) => {
        const kml = await zip.file('doc.kml').async('string')
        resolve(kml)
      })
    })
  }

  findXmlString(obj: Record<string, string | { [key: string]: string }> | Kml): string {
    let xml = null
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'string' && (value.includes('<') || value.includes('>'))) {
        xml = value
      } else if (typeof value === 'object') {
        const nestedXml = this.findXmlString(value)
        if (nestedXml !== null) {
          xml = nestedXml
        }
      }
    }
    return xml
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  findPlacemarks(obj: { [key: string]: any }): KmlPlacemark[] {
    if (typeof obj !== 'object') {
      return []
    }
    if (obj.Placemark) {
      return obj.Placemark
    }
    let placemarks = []
    for (let key in obj) {
      placemarks = placemarks.concat(this.findPlacemarks(obj[key]))
    }
    return placemarks
  }

  close(): void {
    this.modalCtrl.dismiss()
  }

  processarAreas(): void {
    const areas: IArea[] = []
    this.placemarksList.map((placemark) => {
      const coordinatesArea = []

      const area = placemark.coordinates.split(' ').reduce((acc: number[], coord) => {
        const [lng, lat] = coord.split(',')
        if (!lat || !lng) return acc
        coordinatesArea.push(new google.maps.LatLng(Number(lat), Number(lng)))
        acc.push(Number(lat))
        acc.push(Number(lng))
        return acc
      }, [])

      const tamanho = ((google?.maps?.geometry?.spherical?.computeArea(coordinatesArea) || 0) * 0.0001).toFixed(2)

      areas.push({
        hash: this.getRandomString(),
        nome: placemark.text,
        area,
        tamanho,
        coordenadas_centrais: {
          lat: area[0],
          lng: area[1]
        }
      })
    })
    this.areasCtrl.importacaoKMLDados = areas
    this.hashsSelecionados = areas.map(a => a.hash)
    console.log(this.areasMapa)
    this.getHashsSelecionados(this.hashsSelecionados)
  }

  getHashsSelecionados(hashs: string[]): void {
    this.areasMapa = this.areasCtrl.importacaoKMLDados.filter(a => hashs.includes(a.hash))
  }

  getRandomString(): string {
    return Math.random().toString(36).substring(7)
  }

  selecionarHashArea(ev: DadosLinhaTabela): void {
    if (this.hashsSelecionados.includes(ev.original.hash)) {
      this.hashsSelecionados = this.hashsSelecionados.filter(h => h !== ev.original.hash)
    } else {
      this.hashsSelecionados.push(ev.original.hash)
    }
    this.getHashsSelecionados(this.hashsSelecionados)
  }

  async importarConfirmar(): Promise<void> {
    const confirm = await this.alertCtrl.create({
      header: this.hashArea ? 'Importar piquetes' : 'Importar áreas',
      message: `Deseja importar ${this.hashArea ? 'os piquetes selecionados' : 'as áreas selecionadas?'}`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Importar',
          handler: (): void => {
            if (this.hashArea) {
              // importar piquetes
              this.importarPiquetesSelecionados()
            } else {
              // importar areas
              this.importarAreasSelecionadas()
            }
          }
        }
      ]
    })
    confirm.present()
  }

  async importarAreasSelecionadas(): Promise<void> {
    const areas = this.areasCtrl.importacaoKMLDados.filter(a => this.hashsSelecionados.includes(a.hash))

    let loading: HTMLIonLoadingElement

    if (areas.length) {
      loading = await this.utilsCtrl.showLoading('circular', 'Cadastrando áreas...')
    }

    areas.map(async (area) => {
      const payloadArea: ICadastroArea = {
        id_propriedade: this.propriedadesCtrl?.propriedadeSelecionada.id,
        nome: area.nome,
        area: area.area,
        tamanho: area.tamanho,
        coordenadas_centrais: {
          lat: Number(area.area[0]),
          lng: Number(area.area[1])
        },
        meta_arquivo_kml: this.linkArquivoKml
      }
  
      const [response, error] = await this.areasCtrl.cadastrarArea(payloadArea)
  
      if (response) {
        this.areasCtrl.importacaoKMLDados = this.areasCtrl.importacaoKMLDados.filter(a => a.hash !== area.hash)
        this.hashsSelecionados = this.hashsSelecionados.filter(h => h !== area.hash)
        this.getHashsSelecionados(this.hashsSelecionados)
      }
  
      if (error) {
        console.error(error)
        const message = error.message ? error.message : 'Não foi possível cadastrar a área'
        this.utilsCtrl.showToast(message, 'bottom')
      }
    })

    if (loading) {
      loading.dismiss()
      this.ref.tick()
      this.utilsCtrl.showToast('Áreas cadastradas com sucesso', 'bottom')
    }
  }
  
  async importarPiquetesSelecionados(): Promise<void> {
    const areas = this.areasCtrl.importacaoKMLDados.filter(a => this.hashsSelecionados.includes(a.hash))

    let loading: HTMLIonLoadingElement

    if (areas.length) {
      loading = await this.utilsCtrl.showLoading('circular', 'Cadastrando piquetes...')
    }

    areas.map(async (area) => {
      const payloadPiquete: IAreaPiquete = {
        id_propriedade: this.propriedadesCtrl?.propriedadeSelecionada.id,
        nome: area.nome,
        area: area.area,
        hash_area: this.hashArea,
        tamanho: area.tamanho,
        coordenadas_centrais: {
          lat: Number(area.area[0]),
          lng: Number(area.area[1])
        }
      }
  
      const [response, error] = await this.areasCtrl.setPiquete([payloadPiquete])
  
      if (response) {
        this.areasCtrl.importacaoKMLDados = this.areasCtrl.importacaoKMLDados.filter(a => a.hash !== area.hash)
        this.hashsSelecionados = this.hashsSelecionados.filter(h => h !== area.hash)
        this.getHashsSelecionados(this.hashsSelecionados)
      }
  
      if (error) {
        console.error(error)
        const message = error.message ? error.message : 'Não foi possível cadastrar a área'
        this.utilsCtrl.showToast(message, 'bottom')
      }
    })

    if (loading) {
      loading.dismiss()
      this.ref.tick()
      this.utilsCtrl.showToast('Áreas cadastradas com sucesso', 'bottom')
    }
  }

  processaKMLAlternativo(file: File): Promise<{
    nome: string
    coordinates: string
  }[]> {
    return new Promise((resolve) => {

      const areasData: {
        nome: string
        coordinates: string
      }[] = []

      const parseKML = (kmlContent): void => {
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(kmlContent, 'text/xml')
        const placemarks = xmlDoc.querySelectorAll('Placemark')
        let i = 1
        placemarks.forEach((placemark) => {
          try {
            const name = placemark.querySelector('name')?.textContent.trim()
            const polygonCoordinates = placemark.querySelectorAll('Polygon coordinates, MultiGeometry Polygon coordinates')?.[0]?.textContent?.trim()
            if (polygonCoordinates) {
              areasData.push({
                nome: name || ('Área sem nome ' + i++),
                coordinates: polygonCoordinates,
              })
            }
          } catch (error) {
            console.error(error)
          }
        })
      }

      if (file) {
        const reader = new FileReader()

        reader.onload = (e): void => {
          const kmlContent = e.target.result
          parseKML(kmlContent)
          
          console.warn('🚀 ~ returnnewPromise ~ areasData:', areasData)

          resolve(areasData)
        }

        reader.readAsText(file)
      }
    })
  }
}
