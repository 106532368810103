import { Component, OnInit } from '@angular/core'
import { Browser } from '@capacitor/browser'
import { AlertController, ModalController, PopoverController } from '@ionic/angular'
import { PopoverFinancasCategoriasComponent } from 'src/app/components/popover-financas-categorias/popover-financas-categorias.component'
import { VastaInputOpcoes } from 'src/app/components/vasta-input/vasta-input.component'
import { opcoesFiltros } from 'src/app/components/vasta-tabela/opcoes-filtros'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { FinancasService } from 'src/app/services/financas.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import {
  CentrosDeCustoEPessoa,
  ICategoria,
  IConta,
  IFinancasTransacao,
  IDismissPropsPessoaSelecionada,
  TipoTransacoes,
  Pessoa,
  Subcategoria
} from 'src/app/utils/interfaces/financas.interface'
import { Categorias } from '../financas-categorias'
import { ModalCentroDeCustoComponent } from '../modal-centro-de-custo/modal-centro-de-custo.component'
import { ModalListaPessoasPage } from '../modal-lista-pessoas/modal-lista-pessoas.page'
import { PopoverSeletorComponent } from 'src/app/components/popover-seletor/popover-seletor.component'
import { IAnimal } from 'src/app/utils/interfaces/animais.interface'
import { ModalListaAnimaisPage } from '../../bovinos/modal-lista-animais/modal-lista-animais.page'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { IOptionButtons } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { ModalListaAreasPage } from '../../areas/modal-lista-areas/modal-lista-areas.page'
import { IArea } from 'src/app/utils/interfaces/areas.interface'
import { ColunasAreas } from 'src/app/utils/configuracoes-padrao'
import { ModalFinanceiroContasPage } from '../modal-financeiro-contas/modal-financeiro-contas.page'
import { ModalFinancasSubcategoriasComponent } from 'src/app/components/modal-lista-subcategoria/modal-lista-subcategoria.component'

type PessoaCustosValor = {
  hash: string
  nome: string
  valor: string
  percentual?: string
  marcado?: boolean
}

@Component({
  selector: 'app-financeiro-formulario-transacao',
  templateUrl: './financeiro-formulario-transacao.page.html',
  styleUrls: ['./financeiro-formulario-transacao.page.scss']
})
export class FinanceiroFormularioTransacaoPage implements OnInit {
  public isSalvandoTransacao = false
  public tipoTransacao: TipoTransacoes
  public tituloPagina: string
  public categoriaProp: string
  public lancamentoProp: IFinancasTransacao
  public lancamento: IFinancasTransacao = {
    hash: '',
    titulo: '',
    meta_categorias: null,
    hash_conta: '',
    hash_conta_transferencia: '',
    valor: null,
    data: '',
    meta_data_pagamento: '',
    meta_pessoas: '',
    descricao: '',
    tipo_repeticao: '0',
    meta_qtd_parcelas: '',
    meta_repeticao: '',
    realizado: 0,
    tipo: null,
    meta_nf: '',
    meta_forma_pagamento: '',
    meta_litros_de_leite: null,
    meta_centrosDeCusto: {},
    meta_atribuir_valor: '',
    meta_is_despesa_manejo: false,
    meta_data_manejo: ''
  }
  centros_de_custo = {}

  public despesaManejo: boolean = false
  public dataManejo: string = ''

  isImportarXml = false

  public pessoasCentroDeCustosValores: PessoaCustosValor[] = []
  public pessoaLabelSelecionada: string
  public contaSelecionada: IConta
  public contas: IConta[] = []
  public contasPorHash: { [key: string]: IConta } = {
    nenhuma: {
      titulo: 'Sem Conta',
      hash: 'nenhuma',
      id_propriedade: 0,
      tipo: 'arquivo',
    }
  }
  public pessoas: Pessoa[] = []
  public pessoasPorHash = {}

  public opcoesFrequencia: VastaInputOpcoes = [
    {
      label: 'Mensal',
      value: 'mensal'
    },
    {
      label: 'Semanal',
      value: 'semanal'
    }
  ]

  public opcoesPagamento: VastaInputOpcoes = [
    {
      label: 'Boleto',
      value: 'boleto'
    },
    {
      label: 'Cartão de Crédito',
      value: 'cartao_credito'
    },
    {
      label: 'Cartão de Débito',
      value: 'cartao_debito'
    },
    {
      label: 'Cheque',
      value: 'cheque'
    },
    {
      label: 'Depósito',
      value: 'deposito'
    },
    {
      label: 'Dinheiro',
      value: 'dinheiro'
    },
    {
      label: 'Transferência',
      value: 'transferencia'
    },
    {
      label: 'Débito em Conta',
      value: 'debito_em_conta'
    },
    {
      label: 'Débito em Folha',
      value: 'debito_em_folha'
    },
    {
      label: 'Débito Automático',
      value: 'debito_automatico'
    },
    {
      label: 'Pix',
      value: 'pix'
    },
    {
      label: 'Crédito na Loja',
      value: 'credito_na_loja'
    },
  ]

  public opcoesDeDividir: VastaInputOpcoes = []
  public qtdPessoasMarcadas = 0

  public pessoasCentroDeCustos: CentrosDeCustoEPessoa[]

  categoriasLabels = {}

  public listaPdf: { name: string, url: string, chaveFinanceiro: string }[] = []

  public listaXml: { name: string, url: string, chaveFinanceiro: string }[] = []

  public abaAtiva = 'Geral'
  public animaisSelecionados: IAnimal[] = []
  public areasSelecionadas: IArea[] = []
  public carregamentoTabela: StatusTabela = 'carregando'
  public labelsAreas = ColunasAreas

  public optionsButtonsItensAnimais: IOptionButtons[] = [
    {
      chave: 'apagarItem',
      icone: 'trash',
      titulo: 'Remover animal',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        const {
          original: { hash }
        } = linha
        this.animaisSelecionados = this.animaisSelecionados.filter((a) => a.hash !== hash)
      }
    }
  ]

  public optionsButtonsItensAreas: IOptionButtons[] = [
    {
      chave: 'apagarItem',
      icone: 'trash',
      titulo: 'Remover área',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        const {
          original: { hash }
        } = linha
        this.areasSelecionadas = this.areasSelecionadas.filter((a) => a.hash !== hash)
      }
    }
  ]

  public configTabelaAnimais: ConfigTabela = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: false,
    impressaoTitulo: 'Animais',
    orderby: 'numeracao',
    orderbyDirection: 'asc',
    exibeTotais: false,
    excluirColunas: ['checkbox']
  }

  public configTabelaAreas: ConfigTabela = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: false,
    impressaoTitulo: 'Areas',
    orderby: 'nome',
    orderbyDirection: 'asc',
    exibeTotais: false,
    excluirColunas: ['checkbox']
  }

  public opcoesPopoverAtribuirCustos = {
    // animais_todos: { icone: 'rebanho', texto: 'Todos os Animais'},
    animais : { icone: 'rebanho', texto: 'Animais' },
    areas: { icone: 'location-sharp', texto: 'Áreas' },
    propriedade: { icone: 'home', texto: 'Propriedade' },
  }

  public isPorcentagemInvalida = false
  public somaPercentuais = 0
  public carregandoContas = false

  constructor(
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController,
    private financasCtrl: FinancasService
  ) {
    this.categoriasLabels = { ...opcoesFiltros.labelsCategoriasFinancas }
    Categorias.financeiro_entrada.map((item) => {
      this.categoriasLabels[item.id] = item.nome
    })
    Categorias.financeiro_saida.map((item) => {
      this.categoriasLabels[item.id] = item.nome
    })
  }

  async ngOnInit(): Promise<void> {
    if (this.lancamentoProp){
      this.lancamento = { ...this.lancamentoProp }
      if (this.lancamento?.hash_conta == 'nenhuma') {
        this.lancamento.hash_conta = ''
      }
      if (this.lancamento.meta_atribuir_valor) {
        await this.buscarAnimaisAreasTransacao()
      }
    }

    if (this.categoriaProp) this.lancamento.meta_categorias = this.categoriaProp

    if (this.lancamentoProp?.hash && !this.lancamentoProp?.created_at) {
      await this.buscarDetalhesLancamento()
    }

    this.preencherListaPdf()

    this.getTituloPagina()
    this.preecherOpcoesDeDividir()

    await this.getCentroDeCustos()
    if (this.contaSelecionada) {
      this.lancamento.hash_conta = this.contaSelecionada.hash
    }

    this.contas.map((c) => {
      this.contasPorHash[c.hash] = c
    })

    if (!this.pessoas.length) {
      await this.getPessoas()
    }

    if (!this.contas.length) {
      await this.getContas()
    }

    this.pessoas.map((c) => {
      this.pessoasPorHash[c.hash] = c
    })
    console.log('this.pessoasPorHash', this.pessoasPorHash)
  }

  async getContas(): Promise<void> {
    this.carregandoContas = true
    const [response, erro] = await this.financasCtrl.getContas()
    this.carregandoContas = false

    if (response) {
      this.contas = [...this.contas, ...response.contas]
      this.contas.map((c) => (this.contasPorHash[c.hash] = c))
    }

    if (erro) {
      console.error(erro)
    }
  }

  async buscarDetalhesLancamento(): Promise<void> {
    const [sucesso, erro] = await this.financasCtrl.getTransacao(this.lancamento.hash)
    if (sucesso) {
      this.lancamento = sucesso.lancamentos[0]
      if (this.lancamento.meta_atribuir_valor) {
        await this.buscarAnimaisAreasTransacao()
      }
    }
    if (erro) {
    }
  }

  async getPessoas(): Promise<void> {
    const [sucesso, erro] = await this.financasCtrl.getPessoas({
      paginate: {
        limit: 1000
      }
    })
    if (sucesso) {
      this.pessoas = sucesso.centros
    }
    if (erro) {
      console.error(erro)
    }
  }

  preecherOpcoesDeDividir(): void {
    for (let count = 1; count <= 100; count++) {
      this.opcoesDeDividir.push({
        label: `${count}x`,
        value: count
      })
    }
  }

  getTituloPagina(): void {
    if (!this.lancamento.hash) {
      this.lancamento.tipo = this.tipoTransacao
      if (this.tipoTransacao === 'entrada') {
        this.tituloPagina = 'Nova Receita'
      } else if (this.tipoTransacao === 'saida') {
        this.tituloPagina = 'Nova Despesa'
      } else {
        this.tituloPagina = 'Nova Transferência'
      }
    } else {
      if (this.tipoTransacao === 'entrada') {
        this.tituloPagina = 'Editar Receita'
      } else if (this.tipoTransacao === 'saida') {
        this.tituloPagina = 'Editar Despesa'
      } else {
        this.tituloPagina = 'Editar Transferência'
      }
    }
  }

  async getCentroDeCustos(): Promise<void> {
    const [response, erro] = await this.financasCtrl.getCentrosDeCusto()

    if (response) {
      this.pessoasCentroDeCustos = response.centros
      this.pessoasCentroDeCustosValores = []

      const {
        extra_centro_de_custo_1,
        extra_centro_de_custo_1_valor,
        extra_centro_de_custo_2,
        extra_centro_de_custo_2_valor,
        extra_centro_de_custo_3,
        extra_centro_de_custo_3_valor,
        extra_centro_de_custo_4,
        extra_centro_de_custo_4_valor,
        extra_centro_de_custo_5,
        extra_centro_de_custo_5_valor
      } = this.lancamento

      if (extra_centro_de_custo_1) {
        this.centros_de_custo[extra_centro_de_custo_1] = extra_centro_de_custo_1_valor
      }
      if (extra_centro_de_custo_2) {
        this.centros_de_custo[extra_centro_de_custo_2] = extra_centro_de_custo_2_valor
      }
      if (extra_centro_de_custo_3) {
        this.centros_de_custo[extra_centro_de_custo_3] = extra_centro_de_custo_3_valor
      }
      if (extra_centro_de_custo_4) {
        this.centros_de_custo[extra_centro_de_custo_4] = extra_centro_de_custo_4_valor
      }
      if (extra_centro_de_custo_5) {
        this.centros_de_custo[extra_centro_de_custo_5] = extra_centro_de_custo_5_valor
      }

      this.qtdPessoasMarcadas = Object.keys(this.centros_de_custo).filter(
        (key) => ![undefined, null, '', '0'].includes(this.centros_de_custo[key])
      ).length

      this.pessoasCentroDeCustos.forEach((pessoa) => {
        this.pessoasCentroDeCustosValores.push({
          hash: pessoa.hash,
          nome: pessoa.nome,
          marcado: ![undefined, null, '', '0'].includes(this.centros_de_custo[pessoa.hash]) ? true : false,
          valor: this.centros_de_custo[pessoa.hash] ? this.centros_de_custo[pessoa.hash] : '',
          percentual: '0'
        })
      })

      this.calcularValorCusto()
      this.calcularPercentualCusto()
    }

    if (erro) {
      console.error(erro)
    }
  }

  async abrirListaDeContas(tipoConta: 'debitar' | 'creditar'): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalFinanceiroContasPage,
      cssClass: 'modal-large'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()

    if (data) {
      const { conta } = data as { conta?: IConta }

      if (conta) {
        this.contas[conta.hash] = conta

        if (tipoConta === 'debitar') {
          this.lancamento.hash_conta_transferencia = conta.hash

          if(this.lancamento.hash_conta_transferencia === this.lancamento.hash_conta) {
            this.lancamento.hash_conta = ''
            this.utilsCtrl.showToast('Conta de débito não pode ser igual a conta de crédito', 'bottom')
          }
        } else {
          this.lancamento.hash_conta = conta.hash

          if(this.lancamento.hash_conta_transferencia === this.lancamento.hash_conta) {
            this.lancamento.hash_conta_transferencia = ''
            this.utilsCtrl.showToast('Conta de crédito não pode ser igual a conta de débito', 'bottom')
          }
        }
      }
    }
  }

  async abrirPopoverListaCategorias(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverFinancasCategoriasComponent,
      event,
      componentProps: {
        tipo: this.lancamento.tipo
      },
      cssClass: 'popover-categorias',
      reference: 'trigger'
    })

    popover.present()

    const { data } = await popover.onDidDismiss()
    if (data) {
      const categoria = data.categoria as ICategoria
      this.lancamento.meta_categorias = categoria.id
    }
  }

  async abrirModalListaSubcategorias(): Promise<void> {
    const popover = await this.modalCtrl.create({
      component: ModalFinancasSubcategoriasComponent,
      cssClass: 'custom-modal-seletor'
    })

    popover.present()

    const { data } = await popover.onDidDismiss()
    if (data) {
      const categoria = data.subcategoriaSelecionada as Subcategoria
      this.lancamento.meta_subcategoria = categoria.hash
      this.lancamento.extra_subcategoria = categoria.nome
    }
  }

  limparSubcategoria(): void {
    this.lancamento.meta_subcategoria = ''
    this.lancamento.extra_subcategoria = ''
  }

  async abrirModalListaPessoas(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaPessoasPage,
      componentProps: {
        listaPessoas: this.pessoas
      },
      backdropDismiss: false,
      cssClass: 'custom-modal-seletor'
    })

    modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      const propsPessoa = data as IDismissPropsPessoaSelecionada
      if (propsPessoa?.pessoaSelecionada) {
        this.pessoaLabelSelecionada = propsPessoa.pessoaSelecionada.nome
        this.lancamento.meta_pessoas = propsPessoa.pessoaSelecionada.hash
      }

      if (propsPessoa?.isPessoaNovaCadastrada) {
        this.pessoas = propsPessoa.listaPessoasAtualizada
      }
    }
  }

  setRealizado(isMacarcadoComoRecebido: boolean): void {
    this.lancamento.realizado = isMacarcadoComoRecebido ? 1 : 0
  }

  calcularValorCusto(valor?: string, pessoaCustos?: PessoaCustosValor): void {
    const valorTotal = this.lancamento.valor

    if (valor && pessoaCustos) {
      pessoaCustos.valor = valor
    }

    this.pessoasCentroDeCustosValores.forEach((pessoa) => {
      if (pessoa.marcado) {
        const porcentagemCustoPessoa = (Number(pessoa.valor) * 100) / Number(valorTotal)
        pessoa.percentual = porcentagemCustoPessoa.toFixed(2)
      }
    })

    this.calcularPercentualTotalCusto()
  }

  calcularPercentualCusto(valor?: string, pessoaCustos?: PessoaCustosValor): void {
    const valorTotal = this.lancamento.valor

    if (valor && pessoaCustos) {
      pessoaCustos.percentual = valor
    }

    this.calcularPercentualTotalCusto()

    this.pessoasCentroDeCustosValores.forEach((pessoa) => {
      if (pessoa.marcado) {
        const valorCustoPessoa = (Number(pessoa.percentual) * Number(valorTotal)) / 100
        pessoa.valor = valorCustoPessoa.toFixed(2)
      }
    })
  }

  calcularPercentualTotalCusto(): void {
    let somaPercentuais = 0
    let isPorcentagemInvalida = false
    if (this.qtdPessoasMarcadas > 1) {
      for (const pessoa of this.pessoasCentroDeCustosValores) {
        if (pessoa.marcado) {
          somaPercentuais += Number(pessoa.percentual)
        }
      }
      if (somaPercentuais > 100 || somaPercentuais < 100) isPorcentagemInvalida = true
      else isPorcentagemInvalida = false
    }
    this.somaPercentuais = somaPercentuais
    this.isPorcentagemInvalida = isPorcentagemInvalida
  }

  selecionarPessoaParaCentroDeCusto(isMarcado: boolean, pessoa: PessoaCustosValor): void {
    if (isMarcado) {
      this.qtdPessoasMarcadas++
      pessoa.marcado = true
    } else {
      this.qtdPessoasMarcadas--
      this.calcularValorCusto('0', pessoa)
      pessoa.marcado = false
      pessoa.valor = ''
    }
  }

  preencherCentroDeCustosValores(): void {
    this.centros_de_custo = {}
    this.pessoasCentroDeCustosValores.forEach((centro) => {
      if (centro.marcado) {
        this.centros_de_custo[centro.hash] = centro.valor || ''
      }
    })

    if (this.qtdPessoasMarcadas === 1) {
      const centroMarcado = this.pessoasCentroDeCustosValores.find((centro) => centro.marcado)
      this.centros_de_custo[centroMarcado.hash] = this.lancamento.valor
    }
  }

  async showModalNovoCentroDeCusto(centroDeCusto?: DadosLinhaTabela): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCentroDeCustoComponent,
      componentProps: { centroDeCustoProp: centroDeCusto },
      cssClass: 'custom-modal'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      if (data?.success) {
        this.getCentroDeCustos()
      }
    }
  }

  async confirmarExcluirCustoManejo(hash: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Excluir transação',
      message: 'Deseja excluir a transação?',
      buttons: [
        {
          text: 'Não',
          handler: (): void => { }
        },
        {
          text: 'Sim',
          handler: (): void => {
            this.removerLancamento(hash)
          }
        }
      ]
    })

    await alert.present()
  }

  async removerLancamento(hash: string): Promise<void> {
    this.isSalvandoTransacao = true
    const [response, erro] = await this.financasCtrl.excluirTransacao([hash])

    if (response) {
      this.modalCtrl.dismiss({ sucesso: true })
    }
    this.isSalvandoTransacao = false
  }

  async salvarLancamento(): Promise<void> {
    delete this.lancamento.meta_centrosDeCusto
    if (this.tipoTransacao === 'transferencia') {
      this.lancamento.realizado = 1
    } else {
      this.preencherCentroDeCustosValores()
    }

    if (this.despesaManejo) {
      this.lancamento.meta_is_despesa_manejo = true
      this.lancamento.meta_data_manejo = this.dataManejo
    }

    this.isSalvandoTransacao = true
    if (!this.isImportarXml) {
      this.lancamento.hash_conta = this.lancamento.hash_conta || 'nenhuma'
      const [sucesso, erro] = await this.financasCtrl.criarTransacao({
        transacao: this.lancamento,
        centros_de_custo: Object.keys(this.centros_de_custo).length > 0 ? this.centros_de_custo : null,
        hashsAtribuirValor: this.lancamento.meta_atribuir_valor == 'animais' ? this.animaisSelecionados.map(a => a.hash) : this.lancamento.meta_atribuir_valor == 'areas' ? this.areasSelecionadas.map(a => a.hash) : []
      })
      this.isSalvandoTransacao = false

      if (sucesso) {
        this.utilsCtrl.showToast('Transação salva com sucesso', 'bottom')
        this.closeModal(true, sucesso.hashs[0])
      }

      if (erro) {
        this.utilsCtrl.showToast('Erro ao salvar transação', 'bottom')
      }
    } else {
      const centros_de_custo =  Object.keys(this.centros_de_custo).length > 0 ? this.centros_de_custo : null
      const hashsAtribuirValor = this.lancamento.meta_atribuir_valor == 'animais' ? this.animaisSelecionados.map(a => a.hash) : this.lancamento.meta_atribuir_valor == 'areas' ? this.areasSelecionadas.map(a => a.hash) : []

      this.closeModal(true, 'importacao', centros_de_custo, hashsAtribuirValor)
    }
  }

  isFormularioValido(): boolean {
    if (
      (!this.lancamento.hash_conta && this.lancamento.tipo === 'transferencia') ||
      !this.lancamento.titulo ||
      (this.lancamento.meta_categorias !== 'receitas_pecuarias_venda_de_leite' && !this.lancamento.valor) ||
      !this.lancamento.data
    ) {
      return false
    }
    if (this.tipoTransacao === 'transferencia') {
      if (!this.lancamento.hash_conta_transferencia) return false
    }
    return true
  }

  closeModal(sucesso: boolean, hash?: string, centros_de_custo?: {[key: string]: number}, atribuicao_de_valores?: string[]): void {
    if (sucesso && hash !== 'importacao') this.modalCtrl.dismiss({ sucesso: true, pessoas: this.pessoas, hash })
    else if (hash === 'importacao' && this.isImportarXml) {
      this.modalCtrl.dismiss({ sucesso: true, lancamento: this.lancamento, pessoas: this.pessoas, centros_de_custo: centros_de_custo, atribuicao_de_valores: atribuicao_de_valores })
    } else if (!hash && this.isImportarXml) this.modalCtrl.dismiss({})
    else this.modalCtrl.dismiss({ pessoas: this.pessoas })
  }

  async getPdf(pdfSrc: { base64: string, name: string }): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circles', 'Carregando PDF')
    const tituloComTimestamp = `${pdfSrc.name.replace('.pdf', '')}.pdf`
    const [response, error] = await this.financasCtrl.enviarPdf({ tipo: 'financeiro', pdf: pdfSrc.base64, titulo: tituloComTimestamp })

    if (error) {
      this.utilsCtrl.showToast('Erro ao carregar PDF', 'bottom')
      return
    }

    this.listaPdf = [...this.listaPdf]
    this.listaPdf.push({
      chaveFinanceiro: `meta_pdf_${this.listaPdf.length + 1}`,
      name: pdfSrc.name,
      url: response.url
    })

    this.listaPdf.forEach((pdf) => {
      this.lancamento[pdf.chaveFinanceiro] = pdf.url
    })

    this.utilsCtrl.dismissLoading(loading)
  }

  viewPdf(url: string): void {
    Browser.open({ url, presentationStyle: 'popover' })
  }

  removerPdf(chave: string): void {
    this.listaPdf = this.listaPdf.filter((pdf) => pdf.chaveFinanceiro !== chave)
    this.lancamento[chave] = ''
  }

  preencherListaPdf(): void {
    const metasPdf = ['meta_pdf_1', 'meta_pdf_2', 'meta_pdf_3', 'meta_pdf_4', 'meta_pdf_5', 'meta_pdf_6', 'meta_pdf_7', 'meta_pdf_8', 'meta_pdf_9', 'meta_pdf_10']

    metasPdf.forEach((meta) => {
      if (this.lancamento[meta]) {
        let pdfName = this.lancamento[meta].split('/').pop().replace('.pdf.pdf', '.pdf')
        pdfName = (pdfName.split('.pdf__?')[0]) + '.pdf'
        const regexPattern = /^\d+_\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}_\d+__/
        pdfName = pdfName.replace(regexPattern, '')
        pdfName = decodeURIComponent(pdfName)


        this.listaPdf.push({ name: pdfName || this.lancamento[meta], url: this.lancamento[meta], chaveFinanceiro: meta })
      }
    })
  }

  async abrirPopoverAtribuirCustos(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverSeletorComponent,
      event,
      reference: 'trigger',
      componentProps: {
        opcoesKeys: Object.keys(this.opcoesPopoverAtribuirCustos),
        opcoes: this.opcoesPopoverAtribuirCustos,
      },
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data) {
      this.lancamento.meta_atribuir_valor = data

      if ( data == 'animais' && this.animaisSelecionados.length == 0 ) {
        await this.selecionarAnimal()
      } else if (data == 'areas' && this.areasSelecionadas.length == 0) {
        await this.selecionarAreas()
      }

    }

  }

  async selecionarAnimal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: {
        hashNotIn: this.animaisSelecionados.map((a) => a.hash),
        selecionarMultiplos: true,
        somenteInternos: true,
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      this.carregamentoTabela = 'sucesso'
      this.animaisSelecionados = [...(data.animais || [data]), ...this.animaisSelecionados]
    }
  }

  async selecionarAreas(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAreasPage,
      componentProps: {
        hashNotIn: this.areasSelecionadas.map((a) => a.hash),
        multiplos: true,
        permitirCadastro: false
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      this.carregamentoTabela = 'sucesso'

      const hashNotIn = this.areasSelecionadas.map((b) => b.hash)
      const novasAreas = data.areas.filter((a) => !hashNotIn.includes(a.hash))

      console.log({ novasAreas })

      this.areasSelecionadas = [...novasAreas, ...this.areasSelecionadas]
    }
  }

  async buscarAnimaisAreasTransacao(): Promise<void> {
    const [sucesso, erro] = await this.financasCtrl.getAnimaisAreasTransacao(this.lancamento.hash, this.lancamento.meta_atribuir_valor as 'animais' | 'areas')

    if (sucesso) {
      if ( this.lancamento.meta_atribuir_valor == 'animais' ) {
        this.animaisSelecionados = sucesso.animais
        this.carregamentoTabela = 'sucesso'
      }
      if ( this.lancamento.meta_atribuir_valor == 'areas' ) {
        this.areasSelecionadas = sucesso.areas
        this.carregamentoTabela = 'sucesso'
      }
    }

    if (erro) {
      console.error(erro)
    }
  }

  limparAtribuirValores(): void {
    this.lancamento.meta_atribuir_valor = ''
    this.areasSelecionadas = []
    this.animaisSelecionados = []
  }
}
