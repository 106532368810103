<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Manejo <br />Nutricional</div>

    <div class="nutricoes-container">
      <ng-template ngFor let-estoque [ngForOf]="estoqueNutricional">
        <button class="item-nutricional" [class.edited]="dados[estoque.hash].edited">
          <div class="labels">
            <div class="icon">
              <ion-icon name="leaf"></ion-icon>
            </div>
            <div class="info">
              <small>{{ labelsTipo[estoque.meta_tipoDoEstoque] || '' }}</small>
              <span [title]="estoque.titulo">{{ estoque.titulo }}</span>
            </div>
          </div>
          <div class="inputs">
            <vasta-input
              *ngIf="dados[estoque.hash]"
              type="manejo-number"
              label="Quantidade"
              placeholder="0 kg"
              mask="separator.2"
              maskSuffix=" kg"
              [(value)]="dados[estoque.hash].quantidade"
              (valueChange)="emitChange(dados[estoque.hash])"
            ></vasta-input>
            <vasta-input
              *ngIf="dados[estoque.hash]"
              type="manejo-number"
              label="Duração"
              placeholder="0 Dias"
              mask="separator.1"
              [opcional]="true"
              maskSuffix=" Dias"
              [(value)]="dados[estoque.hash].duracao"
              (valueChange)="emitChange(dados[estoque.hash])"
            ></vasta-input>
          </div>
          <div class="infos">
            <div class="manejo-info" *ngIf="dados[estoque.hash]?.quantidade && estoque?.extra_custo_na_data">
              <span class="label">Custo</span>
              <p class="content">R$ {{dados[estoque.hash].quantidade * estoque.extra_custo_na_data | number: '1.2-2'}}</p>
            </div>
            <div class="manejo-info" *ngIf="dados[estoque.hash].duracao">
              <span class="label">Até</span>
              <p class="content">{{calcularDataFinal(dados[estoque.hash]) | timestamp: 'data'}}</p>
            </div>
          </div>
          <ion-icon name="ellipsis-vertical" (click)="showPopoverOpcaoItemManejoArea($event, dados[estoque.hash])"></ion-icon>
        </button>
      </ng-template>

      <button class="manejo-btn add" (click)="adicionarItemNutricional($event)">
        <div class="icon">
          <ion-icon name="mais"></ion-icon>
        </div>
        <span>Adicionar</span>
      </button>
    </div>

    <div class="container-acoes">
      <!-- <button (click)="adicionarObservacao()" [disabled]="!dados.nutricional_hash_estoque">
        <ion-icon name="chatbox"></ion-icon>
      </button> -->
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
