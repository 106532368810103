import { Injectable } from '@angular/core'
import { Cobrancas, InformacoesAssinatura } from '../utils/interfaces/plano-assinatura.interface'
import { ApiService } from './api/api.service'
import { Browser } from '@capacitor/browser'

type LinkPagamento = {
  mensagem: string
  link: string
}

@Injectable({
  providedIn: 'root'
})

export class PlanosAssinaturaService {
  private urlBase = 'usuario/assinatura/planos'

  public isJaExistePropriedadeDisponivelParaUso = false
  public isVerificandoPermissoesDoUsuario = false

  constructor(
    private apiCtrl: ApiService
  ) { }

  entrarEmContatoPorWhatsapp(): void {
    this.abrirLink('https://api.whatsapp.com/send?phone=5577998391515&text=Ol%C3%A1%20Fertili')
  }

  abrirLink(url: string): void {
    Browser.open({ url, presentationStyle: 'fullscreen' })
  }

  async getPlanosDeAssinatura(): Promise<[InformacoesAssinatura, Error?]> {
    const informacoesPlanosAssinaturas = (await this.apiCtrl.get({
      url: this.urlBase
    })) as InformacoesAssinatura

    const { planos, mensagemAjuda, mensagemDesconto, mensagemPlanoCustom, tituloMaisDe } =
      informacoesPlanosAssinaturas

    const informacoesAssinatura: InformacoesAssinatura = {
      planos,
      mensagemAjuda,
      mensagemDesconto,
      mensagemPlanoCustom,
      tituloMaisDe
    }
    return [informacoesAssinatura]
  }

  async criarAssinatura(idPlano: number): Promise<[LinkPagamento, Error?]> {
    try {
      const linkPagamento = (await this.apiCtrl.post({
        url: `${this.urlBase}/${idPlano}/criar-assinatura`
      })) as LinkPagamento
      
      return [linkPagamento]
    } catch(error) {
      return [null, error]
    }
  }

  async getCobrancasUsuario(): Promise<[{ cobrancas: Cobrancas[] }, Error?]> {
    try {
      const response = (await this.apiCtrl.get({
        url: 'usuario/assinatura/cobrancas'
      })) as { cobrancas: Cobrancas[] }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }

  }
}