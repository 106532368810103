<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Ordenha</div>
    <vasta-input
      label="Ordenha 1"
      type="manejo-number"
      maskSuffix=" kg"
      mask="separator.3"
      [(value)]="dados.primeira_ordenha"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>
    <vasta-input
      *ngIf="dados.primeira_ordenha || dados.segunda_ordenha"
      label="Ordenha 2"
      maskSuffix=" kg"
      mask="separator.3"
      type="manejo-number"
      [(value)]="dados.segunda_ordenha"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>
    <vasta-input
      *ngIf="dados.segunda_ordenha || dados.terceira_ordenha"
      label="Ordenha 3"
      maskSuffix=" kg"
      mask="separator.3"
      type="manejo-number"
      [(value)]="dados.terceira_ordenha"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <div class="manejo-info" *ngIf="ultimaOrdenha">
      <span class="label">Última Ordenha</span>
      <p class="content">{{ ultimaOrdenha | number: '1.0-3' }} kg</p>
    </div>

    <div class="manejo-info" *ngIf="variacao">
      <span class="label">Variação</span>
      <p class="content">
        {{ variacao | number: '1.0-3' }} kg
        <ion-icon *ngIf="variacao >= 0" name="arrow-up-circle" color="primary"></ion-icon>
        <ion-icon *ngIf="variacao < 0" name="arrow-down-circle" color="danger"></ion-icon>
      </p>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button
        (click)="adicionarObservacao()"
        [disabled]="!dados.primeira_ordenha && !dados.segunda_ordenha && !dados.terceira_ordenha"
      >
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
