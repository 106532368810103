/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ImportacaoManejosComponent } from './importacao-manejos/importacao-manejos.component'
import { ImportacaoPesagemComponent } from './importacao-pesagem/importacao-pesagem.component'

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./manejos-todos/manejos-todos.module').then((m) => m.ManejosTodosPageModule)
  },
  {
    path: 'importar/manejos',
    component: ImportacaoManejosComponent
  },
  {
    path: 'importar/pesagens',
    component: ImportacaoPesagemComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManejosRoutingModule {}
