/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './guards/auth.guard'
import { ExitCadastroManejoGuard } from './guards/exit-cadastro-manejo.guard'
import { PermissaoesUsuarioGuard } from './guards/permissaoes-usuario.guard'
import { CadastrarManejos } from './pages/manejos/cadastrar-manejos/cadastrar-manejos.component'
import { MenuLateralPage } from './pages/menu-lateral/menu-lateral.page'

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/minha-conta/entrar/entrar.module').then((m) => m.EntrarPageModule)
  },
  {
    path: '',
    component: MenuLateralPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/geral/geral.module').then((m) => m.GeralPageModule)
      },
      {
        path: 'areas',
        loadChildren: () => import('./pages/areas/areas.module').then((m) => m.AreasPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'animais',
        loadChildren: () => import('./pages/bovinos/bovinos.module').then((m) => m.BovinosPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'calendario',
        loadChildren: () => import('./pages/calendario/calendario.module').then((m) => m.CalendarioPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'estoque',
        loadChildren: () => import('./pages/estoque/estoque.module').then((m) => m.EstoquePageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'aprovacoes',
        loadChildren: () => import('./pages/aprovacoes/aprovacoes.module').then((m) => m.AprovacoesPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'consultor',
        loadChildren: () => import('./pages/consultor/consultor.module').then((m) => m.ConsultorPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'educacao',
        loadChildren: () => import('./pages/conteudo/conteudo.module').then((m) => m.ConteudoPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'financeiro',
        loadChildren: () => import('./pages/financas/financas.module').then((m) => m.FinancasPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'relatorios',
        loadChildren: () => import('./pages/relatorios/relatorios.module').then((m) => m.RelatoriosPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'lotes/:hash',
        redirectTo: 'animais/lotes/:hash'
      },
      {
        path: 'lotes',
        redirectTo: 'animais'
      },
      {
        path: 'manejos',
        loadChildren: () => import('./pages/manejos/manejos.module').then((m) => m.ManejosModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'pluviometria',
        loadChildren: () =>
          import('./pages/controle-pluviometrico/controle-pluviometrico.module').then(
            (m) => m.ControlePluviometricoPageModule
          ),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./pages/usuarios/usuarios.module').then((m) => m.UsuariosPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'tutoriais',
        loadChildren: () => import('./pages/tutoriais/tutoriais.module').then((m) => m.TutoriaisPageModule),
        canActivate: [PermissaoesUsuarioGuard]
      },
      {
        path: 'estacoes-reprodutivas/:data',
        redirectTo: '/relatorios/estacoes-reprodutivas/:data'
      },
      {
        path: 'estacoes-reprodutivas',
        redirectTo: '/relatorios/estacoes-reprodutivas'
      },
      {
        path: 'bem-vindo',
        loadChildren: () => import('./pages/bem-vindo/bem-vindo.module').then((m) => m.BemVindoPageModule)
      }
    ]
  },
  {
    path: 'manejos/:data',
    canActivate: [AuthGuard, PermissaoesUsuarioGuard],
    canDeactivate: [ExitCadastroManejoGuard],
    component: CadastrarManejos
  },
  {
    path: '**',
    redirectTo: '/'
  },
  {
    path: 'configuracao-tabela',
    loadChildren: () =>
      import('./pages/configuracao-tabela/configuracao-tabela.module').then((m) => m.ConfiguracaoTabelaPageModule)
  },
  {
    path: 'importar-kml',
    loadChildren: () =>
      import('./pages/areas/importar-kml/importar-kml.module').then(m => m.ImportarKMLPageModule)
  },
  {
    path: 'conteudo-single',
    loadChildren: () => import('./pages/conteudo/conteudo-single/conteudo-single.module').then( m => m.ConteudoSinglePageModule)
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
