import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { IonicModule } from '@ionic/angular'

import { AreasPage } from './areas.page'
import { ComponentsModule } from 'src/app/components/components.module'
import { DesenharAreaComponent } from './desenhar-area/desenhar-area.component'
import { ModalCadastrarAreaComponent } from './modal-cadastrar-area/modal-cadastrar-area.component'
import { AreaSinglePage } from './area-single/area-single.page'
import { PipesModule } from 'src/app/pipes/pipes.module'
import { ModalLancamentoPluviometricoComponent } from './modal-lancamento-pluviometrico/modal-lancamento-pluviometrico.component'
import { AreasPageRoutingModule } from './areas-routing.module'
import { ModalListaAreasPage } from './modal-lista-areas/modal-lista-areas.page'
import { ModalEdicaoLoteComponent } from '../lotes/modal-edicao-lote/modal-edicao-lote.component'
import { ModalCadastrarPiqueteComponent } from './modal-cadastrar-piquete/modal-cadastrar-piquete.component'

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AreasPageRoutingModule, ComponentsModule, PipesModule],
  declarations: [
    AreasPage,
    DesenharAreaComponent,
    ModalCadastrarAreaComponent,
    ModalCadastrarPiqueteComponent,
    ModalEdicaoLoteComponent,
    AreaSinglePage,
    ModalLancamentoPluviometricoComponent,
    ModalListaAreasPage
  ]
})
export class AreasPageModule {}
