import { Component, OnInit, ViewChild } from '@angular/core'
import { AlertController, IonContent, ModalController } from '@ionic/angular'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { FinancasService } from 'src/app/services/financas.service'
import { ColunasFinancasSubcategorias } from 'src/app/utils/configuracoes-padrao'
import { StatusRequest } from 'src/app/utils/interfaces/statusRequest.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { IOptionButtons } from '../opcoes-botoes/opcoes-botoes.component'
import { IDismissPropsSubcategoriaSelecionada, Subcategoria } from 'src/app/utils/interfaces/financas.interface'
import { ModalSubcategoriaComponent } from 'src/app/pages/financas/modal-subcategoria/modal-subcategoria.component'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'

@Component({
  selector: 'app-modal-lista-subcategoria',
  templateUrl: './modal-lista-subcategoria.component.html',
  styleUrls: ['./modal-lista-subcategoria.component.scss']
})
export class ModalFinancasSubcategoriasComponent implements OnInit {
  @ViewChild(IonContent) content: IonContent
  public colunasSubcategorias = ColunasFinancasSubcategorias
  public statusCarregamentoSubcategorias: StatusTabela = 'carregando'
  public acontecimentosConfig: ConfigTabela = {
    toolbar: true,
    filtrosEspecificos: true,
    exibeTotais: false
  }

  public filtroSubcategorias: NovoFiltro = {}
  public ordenacaoSubcategorias: Ordenacao = { coluna: 'nome', direcao: 'asc' }
  public listaSubcategorias: Subcategoria[] = []
  private propsSubcategorias: IDismissPropsSubcategoriaSelecionada = {
    subcategoriaSelecionada: null,
    isSubcategoriaNovaCadastrada: false,
    listaSubcategoriaAtualizada: []
  }

  public optionsButtonsSelecionadosTabela: IOptionButtons[] = [
    {
      icone: 'pencil-outline',
      titulo: 'Editar subcategoria',
      acao: async (dadosTabela: DadosLinhaTabela): Promise<void> => {
        await this.abrirModalParaCadastrarSubcategoria(dadosTabela.original)
      }
    },
    {
      icone: 'trash-outline',
      titulo: 'Excluir subcategoria',
      acao: async (dadosTabela: DadosLinhaTabela): Promise<void> => {
        await this.alertExcluirSubcategoria(dadosTabela.original)
      }
    }
  ]

  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  constructor(
    private modalCtrl: ModalController,
    private financasCtrl: FinancasService,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getSubcategorias()
  }

  selecionarSubcategoria(dadosTabela: DadosLinhaTabela): void {
    this.propsSubcategorias.subcategoriaSelecionada = dadosTabela.original
    this.closeModal()
  }

  async alterouFiltrosSubcategorias(): Promise<void> {
    await this.getSubcategorias()
  }

  async getSubcategorias(): Promise<void> {
    this.statusCarregamentoSubcategorias = 'carregando'

    const [sucesso, erro] = await this.financasCtrl.getSubcategorias({
      filtros: this.filtroSubcategorias,
      ordenacao: this.ordenacaoSubcategorias,
      paginate: this.paginate
    })

    if (sucesso) {
      this.statusCarregamentoSubcategorias = 'sucesso'
      this.listaSubcategorias = sucesso.subcategorias
      this.paginate.total = Number(sucesso.totais.totais)
    }
    if (erro) {
      this.statusCarregamentoSubcategorias = 'erro'
      console.error(erro)
    }
  }

  async abrirModalParaCadastrarSubcategoria(subcategoria?: DadosLinhaTabela): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalSubcategoriaComponent,
      componentProps: { subcategoriaProp: subcategoria },
      cssClass: 'custom-modal'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    const subcategoriaCadastrada: StatusRequest = data

    if (subcategoriaCadastrada?.success) {
      await this.getSubcategorias()
      this.propsSubcategorias.isSubcategoriaNovaCadastrada = true
      this.propsSubcategorias.listaSubcategoriaAtualizada = this.listaSubcategorias
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss(this.propsSubcategorias)
  }

  paginateChange(): void {
    this.content?.scrollToTop(500)
    this.getSubcategorias()
  }

  async alertExcluirSubcategoria(subcategoria: Subcategoria): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Excluir Subcategoria',
      message: `Deseja realmente excluir a subcategoria ${subcategoria.nome}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Excluir',
          handler: async (): Promise<void> => {
            await this.excluirSubcategoria(subcategoria)
          }
        }
      ]
    })

    await alert.present()
  }

  async excluirSubcategoria(subcategoria: Subcategoria): Promise<void> {
    const [response, error] = await this.financasCtrl.excluirSubcategoria(subcategoria.hash)

    if (response) {
      await this.getSubcategorias()
      this.utilsCtrl.showToast('Subcategoria excluída', 'bottom')
    }

    if (error) {
      this.utilsCtrl.showToast('Erro ao excluir subcategoria', 'bottom')
    }
  }
}