<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Lançamento Pluviométrico
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="modal-form">
    <vasta-input
      [label]="'Data do Lançamento'"
      [type]="'date'"
      [(value)]="dadosPluviometricos.data"
      [placeholder]="'dd/mm/yyyy'"
    ></vasta-input>

    <vasta-input
      label="Índice"
      type="int"
      mask="separator.0"
      maskSuffix=" mm"
      [(value)]="dadosPluviometricos.indice"
    ></vasta-input>

    <vasta-input
      [label]="'Horário'"
      [type]="'time'"
      [(value)]="dadosPluviometricos.hora"
      [placeholder]="'Selecionar Horário'"
    ></vasta-input>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <cta-buttons class="ion-float-left">
      <button
        *ngIf="dadosPluviometricos.hash"
        class="danger-clear permissao-escrita-areas"
        (click)="alertExcluirLancamento()"
        [disabled]="salvando || !dadosPluviometricos.indice"
      >
        <ion-icon name="trash"></ion-icon>
        <span>Excluir Lançamento</span>
      </button>
    </cta-buttons>
    <cta-buttons class="ion-float-right">
      <button
        class="primary permissao-escrita-areas"
        (click)="enviarDadosPluviometricos()"
        [disabled]="salvando || !dadosPluviometricos.indice"
      >
        <ion-spinner *ngIf="salvando" name="dots" color="light"></ion-spinner>
        <ion-icon *ngIf="!salvando" slot="start" name="checkmark"></ion-icon>
        <span>{{ salvando ? 'Cadastrando' : 'Cadastrar' }}</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
