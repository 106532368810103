<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      Assinatura
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="modalClose()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <header-tabs
    [tabs]="['Gerenciar', 'Cobranças']"
    [(tabActive)]="segmento"
  ></header-tabs>
</ion-header>
<ion-content>

  <ng-template [ngIf]="segmento == 'Gerenciar'">
    <div class="carregamento" *ngIf="carregando">
      <ion-spinner></ion-spinner>
    </div>
    <div class="info-planos" *ngIf="!carregando && dadosUsuario">
      <div class="item-plano">
        <h2>Plano:</h2>
        <p>{{planoFormatado}}</p>
      </div>
      <div class="item-plano" *ngIf="planoFormatado == 'Premium' || planoFormatado == 'Teste Grátis' ">
        <h2>Válido até:</h2>
        <p>{{ validatePlano }}</p>
      </div>
      <div class="item-plano">
        <h2>Limite de animais ativos:</h2>
        <p>{{planoQtdAnimais}}</p>
      </div>
      <div class="item-plano">
        <h2>Animais cadastrados:</h2>
        <p>{{dadosUsuario?.quantidade_animais}} <span>({{percentualUtilizado}}% do limite)</span></p>
      </div>
      <div class="item-plano" *ngIf="planoFormatado == 'Premium'">
        <h2>Próxima cobrança:</h2>
        <p>{{validatePlano}} <span>({{tempoProximaCobranca}})</span></p>
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="segmento == 'Cobranças'">
    <div class="cobrancas-content">
    <ng-template ngFor let-cobranca [ngForOf]="cobrancas">
      <div class="cobranca" (click)="openModalCobranca(cobranca)">
        <div class="slot cobranca-data">
          <span>{{cobranca?.created_at | date: 'dd/MM/yyyy'  }}</span>
        </div>
        <div class="slot">
          <span class="valor">
            R${{(cobranca?.amount / 100).toFixed(2).replace('.',',')}} <span *ngIf="cobranca.installments">&nbsp;em {{cobranca?.installments}}x</span>
          </span>
        </div>
        <div class="slot">
          <span>Status: {{cobranca?.status}}</span>
        </div>
        <div class="slot">
          <span>Ciclo {{cobranca?.cycle?.cycle}}</span>
        </div>
        <div class="slot">
          <span>Válido por {{cobranca?.subscription?.interval_count}} {{cobranca?.subscription?.interval == 'year' ? 'ano' : 'mês'}}</span>
        </div>
        <div class="slot">
          <span>Próx. cobrança {{cobranca?.subscription?.next_billing_at | date: 'dd/MM/yyyy' }}</span>
        </div>
      </div>
    </ng-template>
  </div>
    
  </ng-template>
  
</ion-content>
