import { AfterViewInit, Component, OnInit } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { ModalListaAreasPage } from 'src/app/pages/areas/modal-lista-areas/modal-lista-areas.page'
import { ModalListaLotesPage } from 'src/app/pages/bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { ColunasTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { CalendarioComponent } from '../calendario/calendario.component'
import { PopoverFinancasCategoriasComponent } from '../popover-financas-categorias/popover-financas-categorias.component'
import { opcoesFiltros } from '../vasta-tabela/opcoes-filtros'
import { NovoFiltro } from '../vasta-tabela/vasta-tabela'

@Component({
  selector: 'app-vasta-tabela-filtros',
  templateUrl: './vasta-tabela-filtros.component.html',
  styleUrls: ['./vasta-tabela-filtros.component.scss']
})
export class VastaTabelaFiltrosComponent implements OnInit, AfterViewInit {
  public opcoesFiltros = opcoesFiltros

  public colunas: ColunasTabela[] = []
  public filtros: NovoFiltro
  public coluna: ColunasTabela
  public opcoes: string[]
  public opcoesCheckBox: { isChecked: boolean; valor: string }[]
  public eventRef: PointerEvent
  public ordenacao: boolean
  public opcoesExtrasFiltros: { [key: string]: { [key: string]: string } }
  public filtrosFixos: string[] = []

  public inputTextValue: string
  public inputRangeDeValue: number | string
  public inputRangeAteValue: number | string

  public inlineProp: boolean = false
  public focus: boolean = false

  constructor(private popoverCtrl: PopoverController, private modalCtrl: ModalController) {}

  async ngOnInit(): Promise<void> {
    this.colunas = this.colunas.filter(
      (coluna) => coluna.tipoFiltro && !this.filtrosFixos.includes(coluna.chave) && !coluna.bloquearOrdenacao
    )

    if (this.coluna && !this.ordenacao) {
      if (this.coluna.tipoFiltro === 'preenchidos') {
        if (!opcoesFiltros[this.coluna?.labelFormat]) {
          this.opcoes = Object.keys(this.opcoesExtrasFiltros[this.coluna.chave])
          return
        }

        this.opcoes = Object.keys(opcoesFiltros[this.coluna.labelFormat])
      }

      if (this.coluna.tipoFiltro === 'preenchidosMultiplos') {
        const valoresSelecionados = String(this.filtros[this.coluna.chave]).split(',')

        if (!opcoesFiltros[this.coluna?.labelFormat]) {
          this.opcoesCheckBox = Object.keys(this.opcoesExtrasFiltros[this.coluna.chave]).map((item) => {
            return {
              isChecked: valoresSelecionados.includes(item),
              valor: item
            }
          })
          return
        }

        this.opcoesCheckBox = Object.keys(opcoesFiltros[this.coluna.labelFormat]).map((item) => {
          return {
            isChecked: valoresSelecionados.includes(item),
            valor: item
          }
        })
      }

      if (this.coluna.tipoFiltro === 'texto') {
        this.inputTextValue = this.filtros[this.coluna.chave] as string
      }

      if (this.coluna.tipoFiltro === 'rangeNumeros' && this.filtros[this.coluna.chave]) {
        this.inputRangeDeValue = Number(this.filtros[this.coluna.chave][0])
        this.inputRangeAteValue = Number(this.filtros[this.coluna.chave][1])
      }
    }
  }

  ngAfterViewInit(): void {
    this.focusCampos()
  }

  async selecionarCategoria(_: PointerEvent, chave: string): Promise<void> {
    const colunaSelecionada = this.colunas.find((item) => item.chave === chave)

    if (this.ordenacao) {
      this.popoverCtrl.dismiss({ colunaOrdenacao: colunaSelecionada })

      return
    }

    if (colunaSelecionada?.tipoFiltro === 'rangeDatas') {
      let tooLowOnTheScreen = false
      if ((window.innerHeight - this.eventRef.clientY) < 500) {
        tooLowOnTheScreen = true
      }

      const popover = await this.popoverCtrl.create({
        component: CalendarioComponent,
        componentProps: { range: true, titulo: colunaSelecionada.titulo },
        event: tooLowOnTheScreen ? undefined : this.eventRef,
        reference: tooLowOnTheScreen ? 'trigger' : undefined,
        cssClass: 'popover-calendario'
      })

      await popover.present()
      const { data } = (await popover.onDidDismiss()) || {}
      if (data) {
        this.filtros[chave] = [data.de.iso, data.ate.iso]
      }

      this.popoverCtrl.dismiss({ filtrosChange: this.filtros })

      return
    }

    if (
      ['extra_lote', 'lotes_nomes', 'extra_lote_matriz', 'extra_hash_lote_label'].includes(colunaSelecionada?.chave)
    ) {
      const modal = await this.modalCtrl.create({
        component: ModalListaLotesPage,
        componentProps: { multiplos: true },
        cssClass: 'custom-modal-lotes'
      })

      await modal.present()
      const { data } = (await modal.onWillDismiss()) || {}
      if (data) {
        this.filtros[chave] = data.lotes.map((lote) => lote.hash)
      }

      this.popoverCtrl.dismiss({ lotes: data?.lotes })

      return
    }

    if (['extra_area', 'nome_area'].includes(colunaSelecionada?.chave)) {
      const modal = await this.modalCtrl.create({
        component: ModalListaAreasPage,
        componentProps: { multiplos: true },
        cssClass: 'custom-modal-seletor'
      })

      await modal.present()
      const { data } = (await modal.onWillDismiss()) || {}
      if (data) {
        this.filtros[chave] = data.areas.map((area) => area.hash)
      }

      this.popoverCtrl.dismiss({ areas: data?.areas })

      return
    }

    if (colunaSelecionada?.chave === 'extra_categorias_chave') {
      const popover = await this.popoverCtrl.create({
        component: PopoverFinancasCategoriasComponent,
        componentProps: { tipo: 'todos' },
        cssClass: 'popover-categorias'
      })

      await popover.present()
      const { data } = (await popover.onWillDismiss()) || {}
      if (data) {
        this.filtros[chave] = data.categoria.id
      }

      this.popoverCtrl.dismiss({ filtrosChange: this.filtros })

      return
    }

    this.coluna = colunaSelecionada

    if (this.coluna.tipoFiltro === 'preenchidos') {
      if (!opcoesFiltros[this.coluna?.labelFormat]) {
        this.opcoes = Object.keys(this.opcoesExtrasFiltros[this.coluna.chave])
        return
      }
      this.opcoes = Object.keys(opcoesFiltros[this.coluna.labelFormat])
    }

    if (this.coluna.tipoFiltro === 'preenchidosMultiplos') {
      if (!opcoesFiltros[this.coluna?.labelFormat]) {
        this.opcoesCheckBox = Object.keys(this.opcoesExtrasFiltros[this.coluna.chave]).map((item) => {
          return {
            isChecked: false,
            valor: item
          }
        })
        return
      }
      this.opcoesCheckBox = Object.keys(opcoesFiltros[this.coluna.labelFormat]).map((item) => {
        return {
          isChecked: false,
          valor: item
        }
      })
    }

    this.focusCampos()
  }

  voltar(): void {
    this.coluna = null
  }

  async selecionarOpcao(opcao: string): Promise<void> {
    this.filtros[this.coluna.chave] = opcao
    await this.popoverCtrl.dismiss({ filtrosChange: this.filtros })
  }

  async confirmar(): Promise<void> {
    if (this.coluna.tipoFiltro === 'texto' && this.inputTextValue) {
      this.filtros[this.coluna.chave] = this.inputTextValue
      await this.popoverCtrl.dismiss({ filtrosChange: this.filtros })
    }

    if (this.coluna.tipoFiltro === 'rangeNumeros' && (this.inputRangeDeValue || this.inputRangeAteValue)) {
      this.filtros[this.coluna.chave] = [this.inputRangeDeValue, this.inputRangeAteValue]
      await this.popoverCtrl.dismiss({ filtrosChange: this.filtros })
    }

    if (this.coluna.tipoFiltro === 'preenchidosMultiplos') {
      const opcoesSelecionadas = this.opcoesCheckBox.filter((item) => item.isChecked).map((item) => item.valor)

      this.filtros[this.coluna.chave] = opcoesSelecionadas
      await this.popoverCtrl.dismiss({ filtrosChange: this.filtros })
    }
  }

  async limparCampos(): Promise<void> {
    if (this.coluna.tipoFiltro === 'texto') {
      this.inputTextValue = null
    }

    if (this.coluna.tipoFiltro === 'rangeNumeros') {
      this.inputRangeDeValue = null
      this.inputRangeAteValue = null
    }

    if (this.coluna.tipoFiltro === 'preenchidosMultiplos') {
      this.opcoesCheckBox.forEach((item) => (item.isChecked = false))
    }

    delete this.filtros[this.coluna.chave]
    await this.popoverCtrl.dismiss({ filtrosChange: this.filtros })
  }

  focusCampos(): void {
    setTimeout(() => {
      this.focus = true
    }, 500)
    setTimeout(() => {
      this.focus = false
    }, 1000)
  }

  formatarLabelOpcoesExtrasFiltros(valor: string): string {
    return this.opcoesExtrasFiltros[this.coluna.chave][valor]
  }

  isCamposRangeNumerosPreenchidos(): boolean {
    const campoDeValido =
      this.inputRangeDeValue !== null && this.inputRangeDeValue !== undefined && this.inputRangeDeValue !== ''
    const campoAteValido =
      this.inputRangeAteValue !== null && this.inputRangeAteValue !== undefined && this.inputRangeAteValue !== ''
    return campoDeValido && campoAteValido
  }
}
