import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { PopoverController, ViewDidEnter } from '@ionic/angular'

@Component({
  selector: 'app-popover-detalhamento-manejo',
  templateUrl: './popover-detalhamento-manejo.component.html',
  styleUrls: ['./popover-detalhamento-manejo.component.scss']
})
export class PopoverDetalhamentoManejoComponent implements OnInit, ViewDidEnter {
  @ViewChild('descricaoRef') descricaoInputRef: ElementRef<HTMLInputElement>
  public descricao: string
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  ionViewDidEnter(): void {
    setTimeout(() => {
      this.descricaoInputRef.nativeElement.focus()
    }, 100)
  }

  async closePopover(): Promise<void> {
    await this.popoverCtrl.dismiss()
  }

  async adicionarDescricao(): Promise<void> {
    await this.popoverCtrl.dismiss(this.descricao)
  }
}
