import { Injectable } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import * as moment from 'moment'
import { ModalContaBloqueadaPage } from '../pages/geral/modal-conta-bloqueada/modal-conta-bloqueada.page'
import { ModalPlanoExpiradoPage } from '../pages/geral/modal-plano-expirado/modal-plano-expirado.page'
import { ModalPropriedadePage } from '../pages/geral/modal-propriedade/modal-propriedade.page'
import { ModalLimiteAnimaisAlcancado } from '../pages/geral/modal-sem-permissao-cadastro-animais/modal-limite-animais-alcancado.page'
import { ModalListaPropriedadesPage } from '../pages/menu-lateral/modal-lista-propriedades/modal-lista-propriedades.page'
import { PermissaoCadastrarNovosAnimais } from '../utils/interfaces/permissoes.interface'
import { IPropriedade, PaginasComRestricoes } from '../utils/interfaces/propriedades.interface'
import {
  InfoLimitePlano,
  IUsuario,
  LimiteDePlanoPorPropriedade,
  PermissoesDoUsuarioNaPropriedade,
  PermissoesPorPropriedade,
  StatusPermissaoUsuario,
  TopBarNomeAcoes
} from '../utils/interfaces/Usuarios.interface'
import { NavegacaoService } from './navegacao.service'
import { PropriedadesService } from './propriedades.service'
import { UsuariosService } from './usuarios.service'

type UsuarioPermissoes = {
  isPossuiPropriedadesTerceiro: boolean
  isPropriedadeTerceiroAtiva: boolean
  isUsuarioPossuiAssinatura: boolean
  isUsuarioPossuiAssinaturaAtiva: boolean
  isUsuarioTrial: boolean
  isUsuarioPossuiPropriedadesCadastradas: boolean
  isUsuarioSecundario: boolean
}

@Injectable({
  providedIn: 'root'
})
export class PermissoesUsuarioService {
  public exibeTopBar = false
  public topBarMensagem = ''
  public topBarBotao = ''
  public topBarBotaoAcao: TopBarNomeAcoes = null

  public statusPermissaoFluxoInicialVisaoGeral: StatusPermissaoUsuario
  private permissoesPorPropriedade: PermissoesPorPropriedade = {}
  public limiteDePlanoPorPropriedade: LimiteDePlanoPorPropriedade = {}
  public isAcessoDoUsuarioLiberado: boolean
  public isUsuarioSemAssinaturaTestandoPropriedadeExemplo = false

  //
  private mensagemLabelTopBarPropriedadeExemplo = 'Não é possível alterar dados no modo Demonstração'
  private mensagemBotaoTopBarPropriedadeExemplo = 'Teste Grátis'

  private mensagemLabelTopBarPropriedadeExemploComAssinatura =
    'Não é possível alterar dados no modo Demonstração'
  private mensagemBotaoTopBarPropriedadeExemploComAssinatura = 'Voltar'

  private mensagemLabelTopBarUsuarioTrial = 'Você está usando uma versão de Teste Grátis do Fertili 360.'
  private mensagemBotaoTopBarUsuarioTrial = 'Assine Agora'

  public isAcaoTopBarAtiva: boolean

  public isModalDeFluxoInicialVisaoGeralAtivo: boolean

  public jaAbriuListaPropriedades: boolean

  constructor(
    private propriedadeCtrl: PropriedadesService,
    private usuarioCtrl: UsuariosService,
    private modalCtrl: ModalController,
    private navegacaoCtrl: NavegacaoService
  ) {}

  async verificarPermissoes(): Promise<void> {
    const usuarioPermissoes = await this.getValidacoesDoUsuario()

    this.verificaTopBar()

    this.atualizarAcessoDoUsuario(usuarioPermissoes)

    this.inicializarObjetoDePermissoes()

    this.adicionarClassesSemPermissaoLeituraEscritaEmPropriedadeDeTerceiros()

    this.statusPermissaoFluxoInicialVisaoGeral = this.getStatusDaPermissaoDoUsuario(usuarioPermissoes)

    const statusPermissaoUsuario = this.statusPermissaoFluxoInicialVisaoGeral
    const isPropriedadeExemploSelecionada = !!this.propriedadeCtrl?.propriedadeSelecionada?.propriedade_exemplo

    VastaRX.setState('statusPermissao', statusPermissaoUsuario)

    this.verificarFluxoInicialDoUsuario(statusPermissaoUsuario, isPropriedadeExemploSelecionada)

    const isUsuarioPossuiApenasAcessoSecundario = statusPermissaoUsuario === 'acesso-secundario'
    const isPropriedadeDoPrioritarioSelecionada =
      this.propriedadeCtrl?.propriedadeSelecionada?.meu_role === 'proprietario'

    if (isUsuarioPossuiApenasAcessoSecundario && isPropriedadeDoPrioritarioSelecionada) {
      this.selecionarPropriedadeSecundaria()
    }
  }

  verificarFluxoInicialDoUsuario(
    statusPermissaoUsuario: StatusPermissaoUsuario,
    isPropriedadeExemploSelecionada: boolean
  ): void {
    if (statusPermissaoUsuario === 'boas-vindas') {
      this.navegacaoCtrl.set(['/bem-vindo'])
    }
    if (statusPermissaoUsuario === 'cadastrar-propriedade') {
      this.navegacaoCtrl.set(['/bem-vindo'])
    }
    if (statusPermissaoUsuario === 'listar-propriedade') {
      if (!this.jaAbriuListaPropriedades) {
        this.jaAbriuListaPropriedades = true

        if (!this.propriedadeCtrl.propriedadeSelecionada && !this.isModalDeFluxoInicialVisaoGeralAtivo) {
          this.isModalDeFluxoInicialVisaoGeralAtivo = true
          this.openModalListaPropriedades()
        }
      }
    }
    if (statusPermissaoUsuario === 'trial-expirado' && !this.isModalDeFluxoInicialVisaoGeralAtivo) {
      this.isModalDeFluxoInicialVisaoGeralAtivo = true
      this.openModalPlanoAssinatura({ isTrial: true })
    }
    if (statusPermissaoUsuario === 'plano-expirado' && !this.isModalDeFluxoInicialVisaoGeralAtivo) {
      this.isModalDeFluxoInicialVisaoGeralAtivo = true
      this.openModalPlanoAssinatura({ isTrial: false })
    }
    if (statusPermissaoUsuario === 'bloqueado' && !this.isModalDeFluxoInicialVisaoGeralAtivo) {
      this.isModalDeFluxoInicialVisaoGeralAtivo = true
      this.openModalContaBloqueada()
    }
    if (
      statusPermissaoUsuario === 'liberado' &&
      !this.propriedadeCtrl?.todasPropriedades?.length &&
      !isPropriedadeExemploSelecionada
    ) {
      this.navegacaoCtrl.set(['/bem-vindo'])
    }
  }

  selecionarPropriedadeSecundaria(): void {
    const propriedadeSecundaria = this.propriedadeCtrl.todasPropriedades.find((prop) => prop.meu_role === 'secundario')
    VastaRX.setState('propriedade_selecionada', propriedadeSecundaria)
  }

  monitorarPermissaoUsuarioFecharModalIncompativel(statusValido: StatusPermissaoUsuario, modalCtrl: ModalController): void {
    if (this.isModalDeFluxoInicialVisaoGeralAtivo) {
      VastaRX.getState('statusPermissao', (statusPermissao: StatusPermissaoUsuario) => {
        if (statusPermissao && !statusPermissao.includes(statusValido)) {
          this.isModalDeFluxoInicialVisaoGeralAtivo = false
          modalCtrl.dismiss()
        }
      })
    }
  }

  async openModalPlanoAssinatura(params?: { isTrial?: boolean; isBotaoClose?: boolean }): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalPlanoExpiradoPage,
      componentProps: {
        tipoVencimento: params?.isTrial ? 'trial-expirado' : 'plano-expirado',
        isBotaoClose: params?.isBotaoClose
      },
      cssClass: 'custom-modal',
      backdropDismiss: false
    })

    await modal.present()
  }

  private async openModalLimiteDeAnimaisAlcancado(qtdAnimaisTentandoCadastrar?: number): Promise<PermissaoCadastrarNovosAnimais> {
    return new Promise(async (resolve) => {
      const modal = await this.modalCtrl.create({
        component: ModalLimiteAnimaisAlcancado,
        componentProps: {
          qtdAnimaisTentandoCadastrar
        },
        cssClass: 'custom-modal',
        backdropDismiss: false
      })
  
      await modal.present()
  
      const { data } = await modal.onDidDismiss<PermissaoCadastrarNovosAnimais>()
      resolve(data)
    })
  }

  /**
   * Se não houver permissões de cadastro de novos animais, abrir modal avisando sobre não ter mais vagas disponíveis
   * @param qtdAnimais Quantidade de animais para serem cadastrados
  */
  public async checkPermissoesCadastroNovosAnimais(qtdAnimais?: number): Promise<{ isPossuiPermissaoCadastro: boolean }> {
    return new Promise(async (resolve) => {
      const isPermitidoCadastrarAnimais = this.informacoesDoPlanoDoUsuarioNaPropriedadeAtual.permitirCadastroAnimais
      const isQtdAnimaisParaCadastrarSuperiorAoLimite = this.vagasDeAnimaisDisponiveis - qtdAnimais < 0

      if (!isPermitidoCadastrarAnimais || isQtdAnimaisParaCadastrarSuperiorAoLimite) {
        const { isPossuiPermissaoCadastro } = await this.openModalLimiteDeAnimaisAlcancado(qtdAnimais)
        resolve({ isPossuiPermissaoCadastro })
      } else {
        resolve({ isPossuiPermissaoCadastro: true })
      }
    })
  }

  async openModalContaBloqueada(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalContaBloqueadaPage,
      cssClass: 'custom-modal',
      backdropDismiss: false
    })

    await modal.present()
  }

  async openModalPropriedade(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalPropriedadePage,
      cssClass: 'custom-modal'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()

    if (data?.success) {
      // this.propriedadeCtrl.propriedadeSelecionada =
      this.liberarAcessoAoUsuario()
    }
  }

  async openModalListaPropriedades(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaPropriedadesPage,
      cssClass: 'custom-modal-seletor'
    })

    await modal.present()
  }

  async atualizarInformacoesDePermissaoDoUsuario(): Promise<void> {
    await this.propriedadeCtrl.getPropriedades()
    this.inicializarObjetoDePermissoes()
  }

  inicializarObjetoDePermissoes(): void {
    this.montarObjetoDePermissoesDoUsuarioPorPropriedade()
    this.montarObjetoDeLimiteDePlanoPorPropriedade()
    VastaRX.setState('permissoes-usuario-atualizado', true)
  }

  atualizarAcessoDoUsuario(usuarioPermissoes: UsuarioPermissoes): void {
    const { isPossuiPropriedadesTerceiro, isUsuarioPossuiAssinaturaAtiva } = usuarioPermissoes

    const isPropriedadeExemploSelecionada = !!this.propriedadeCtrl?.propriedadeSelecionada?.propriedade_exemplo
    const isPossuiAlgumaPropriedadeCadastrada = !!this.propriedadeCtrl?.todasPropriedades?.length

    if (
      isPossuiPropriedadesTerceiro ||
      isPropriedadeExemploSelecionada ||
      (isUsuarioPossuiAssinaturaAtiva && isPossuiAlgumaPropriedadeCadastrada)
    ) {
      this.liberarAcessoAoUsuario()
    } else {
      this.desabilitarAcessoDoUsuario()
    }

    this.propriedadeCtrl.verificarSeJaExistePropriedadeDisponivelParaUso()

    if (!isUsuarioPossuiAssinaturaAtiva && isPropriedadeExemploSelecionada) {
      this.isUsuarioSemAssinaturaTestandoPropriedadeExemplo = true
    }
  }

  getStatusDaPermissaoDoUsuario(usuarioPermissoes: UsuarioPermissoes): StatusPermissaoUsuario {
    const isPropriedadeExemploSelecionada = this.propriedadeCtrl?.propriedadeSelecionada?.propriedade_exemplo

    let statusPermissao: StatusPermissaoUsuario

    if (usuarioPermissoes.isUsuarioPossuiAssinatura) {
      if (usuarioPermissoes.isPropriedadeTerceiroAtiva) {
        statusPermissao = 'listar-propriedade'
      } else if (usuarioPermissoes.isUsuarioPossuiAssinaturaAtiva) {
        if (usuarioPermissoes.isUsuarioPossuiPropriedadesCadastradas) {
          // liberar acesso / fluxo normal
          statusPermissao = 'liberado'
        } else {
          // ir para cadastrar props
          statusPermissao = 'cadastrar-propriedade'
        }
      } else {
        if (usuarioPermissoes.isUsuarioTrial) {
          // teste encerrado/ assine agora
          statusPermissao = 'trial-expirado'
        } else {
          // renovar assinatura
          statusPermissao = 'plano-expirado'
        }
      }
    } else {
      if (usuarioPermissoes.isPossuiPropriedadesTerceiro) {
        if (usuarioPermissoes.isPropriedadeTerceiroAtiva) {
          // listar props terceiros
          statusPermissao = 'listar-propriedade'
        } else {
          // block
          statusPermissao = 'bloqueado'
        }
      } else {
        // boas vindas
        statusPermissao = 'boas-vindas'
      }
    }

    if (isPropriedadeExemploSelecionada || usuarioPermissoes.isUsuarioSecundario) {
      statusPermissao = 'liberado'
    }

    if (!usuarioPermissoes.isUsuarioPossuiAssinaturaAtiva && usuarioPermissoes.isPropriedadeTerceiroAtiva) {
      statusPermissao = 'acesso-secundario'
    }

    return statusPermissao
  }

  liberarAcessoAoUsuario(): void {
    this.isAcessoDoUsuarioLiberado = true
    VastaRX.setState('permissoes_acesso', true)
  }

  desabilitarAcessoDoUsuario(): void {
    this.isAcessoDoUsuarioLiberado = false
    VastaRX.setState('permissoes_acesso', false)
  }

  resetarValoresParaRetornarABoasVindas(): void {
    this.esconderTopBar()
    this.desabilitarAcessoDoUsuario()
    this.propriedadeCtrl.removerPropriedadeSelecionada()
    this.propriedadeCtrl.isJaExistePropriedadeDisponivelParaUso = null
  }

  async verificaTopBar(): Promise<void> {
    const usuario = await this.usuarioCtrl.getUsuarioStorage()
    const propriedadeSelecionada = this.propriedadeCtrl.propriedadeSelecionada

    if (!usuario?.plano_ativo && propriedadeSelecionada?.propriedade_exemplo) {
      return this.topBarSeUsuarioSemAssinaturaEstaTestandoPropriedadeExemplo()
    }

    if (usuario?.plano_ativo && propriedadeSelecionada?.propriedade_exemplo) {
      return this.topBarSeUsuarioComAssinaturaEstaTestandoPropriedadeExemplo()
    }

    if (usuario?.plano_ativo == 'trial') {
      return this.topBarSeUsuarioForTrial(usuario)
    }

    this.esconderTopBar()
  }

  topBarSeUsuarioSemAssinaturaEstaTestandoPropriedadeExemplo(): void {
    this.exibirTopBar(
      this.mensagemLabelTopBarPropriedadeExemplo,
      this.mensagemBotaoTopBarPropriedadeExemplo,
      'assinatura-invalida-propriedade-exemplo'
    )
    document.body.classList.add('propriedade-exemplo')
  }

  topBarSeUsuarioComAssinaturaEstaTestandoPropriedadeExemplo(): void {
    this.exibirTopBar(
      this.mensagemLabelTopBarPropriedadeExemploComAssinatura,
      this.mensagemBotaoTopBarPropriedadeExemploComAssinatura,
      'selecionar-propriedade'
    )
    document.body.classList.add('propriedade-exemplo')
  }

  topBarSeUsuarioForTrial(usuario: IUsuario): void {
    const dias = moment(usuario.plano_validade).diff(moment(), 'days') + 1

    let mensagem = `${this.mensagemLabelTopBarUsuarioTrial}`
    if (dias) {
      if (dias > 1) {
        mensagem = `Este período de Teste Grátis termina em ${dias} dias`
      } else if (dias === 1) {
        mensagem = `Este período de Teste Grátis termina amanhã`
      } else {
        mensagem = 'O seu período de Teste Grátis terminou'
      }
    }
    this.exibirTopBar(mensagem, this.mensagemBotaoTopBarUsuarioTrial, 'plano-trial')
  }

  esconderTopBar(): void {
    this.exibeTopBar = false
    document.querySelector('html').classList.remove('top-bar-shown')
  }

  exibirTopBar(mensageLabel: string, mensageBotao: string, nomeBotao: TopBarNomeAcoes): void {
    this.exibeTopBar = true
    this.topBarMensagem = mensageLabel
    this.topBarBotao = mensageBotao
    this.topBarBotaoAcao = nomeBotao

    document.querySelector('html').classList.add('top-bar-shown')
  }

  get permissoesDoUsuarioNaPropriedade(): PermissoesDoUsuarioNaPropriedade {
    const idPropriedade = this.propriedadeCtrl?.propriedadeSelecionada?.id
    return this.permissoesPorPropriedade[idPropriedade]
  }

  get informacoesDoPlanoDoUsuarioNaPropriedadeAtual(): InfoLimitePlano {
    const idPropriedade = this.propriedadeCtrl?.propriedadeSelecionada?.id
    return this.limiteDePlanoPorPropriedade[idPropriedade]
  }

  get vagasDeAnimaisDisponiveis(): number {
    return this.informacoesDoPlanoDoUsuarioNaPropriedadeAtual.vagasDisponiveis
  }

  private async getValidacoesDoUsuario(): Promise<UsuarioPermissoes> {
    if (!this.propriedadeCtrl?.todasPropriedades?.length) {
      await this.propriedadeCtrl.getPropriedades()
    }

    const propriedades = this.propriedadeCtrl?.todasPropriedades
    const responseUsuario = await this.usuarioCtrl.getUsuarioStorage()

    const usuarioPermissoes: UsuarioPermissoes = {
      isPossuiPropriedadesTerceiro: this.isPossuiPropriedadesTerceiro(propriedades),
      isPropriedadeTerceiroAtiva: this.verificarSePossuiPropriedadeDeTerceirosAtiva(propriedades),
      isUsuarioPossuiAssinatura: responseUsuario ? this.verificarSeUsuarioPossuiAssinatura(responseUsuario) : null,
      isUsuarioPossuiAssinaturaAtiva: responseUsuario
        ? this.VerificarSeAssinaturaDoUsuarioEstaAtiva(responseUsuario)
        : null,
      isUsuarioTrial: responseUsuario ? this.verificarSeAssinaturaDoUsuarioEstaComoTrial(responseUsuario) : null,
      isUsuarioPossuiPropriedadesCadastradas: this.verificarSeUsuarioPossuiAlgumaPropriedadeCadastrada(responseUsuario),
      isUsuarioSecundario: responseUsuario?.plano_ativo === 'secundario'
    }

    return usuarioPermissoes
  }

  private montarObjetoDePermissoesDoUsuarioPorPropriedade(): void {
    const propriedades = this.propriedadeCtrl?.todasPropriedades

    if (propriedades?.length) {
      propriedades.map((prop) => {
        this.permissoesPorPropriedade[prop.id] = {}

        prop?.permissoes?.map((permissao) => {
          const [chave, valor] = permissao.split('-')
          this.permissoesPorPropriedade[prop.id][chave] = valor
        })
      })
    }
  }

  private montarObjetoDeLimiteDePlanoPorPropriedade(): void {
    const propriedades = this.propriedadeCtrl?.todasPropriedades
    if (propriedades?.length) {
      propriedades.forEach((prop) => {
        const isPlanoDeValidadeValido = moment(prop?.plano_validade).isAfter(moment())
        const percentualAnimaisCadastrados = (100 * Number(prop.proprietario_animais)) / prop.plano_limite
        const vagasDisponiveis = prop.plano_vagas ? prop.plano_vagas : prop.plano_limite - Number(prop.proprietario_animais)
        
        this.limiteDePlanoPorPropriedade[prop.id] = {}
        this.limiteDePlanoPorPropriedade[prop.id] = {
          proprietario: prop.proprietario,
          limiteAnimais: prop.plano_limite,
          animaisCadastrados: prop.proprietario_animais,
          cadastrosDisponiveis: prop.plano_vagas,
          meuRole: prop.meu_role,
          validadePlano: prop.plano_validade,
          situacaoPlano: !isPlanoDeValidadeValido ? 'expirado' : prop.plano_status === 'active' ? 'ativo' : 'bloqueado',
          vagasDisponiveis: vagasDisponiveis < 0 ? 0 : vagasDisponiveis,
          permitirCadastroAnimais: vagasDisponiveis > 0,
          percentualAnimaisCadastrados: Number(percentualAnimaisCadastrados.toFixed(2))
        }
      })
    }
  }

  private isPossuiPropriedadesTerceiro(propriedades: IPropriedade[]): boolean {
    return propriedades?.length && propriedades.some((prop) => prop.meu_role === 'secundario') ? true : false
  }

  private verificarSePossuiPropriedadeDeTerceirosAtiva(propriedades: IPropriedade[]): boolean {
    const propriedadeTerceiro = propriedades?.find(
      (prop) => prop.meu_role === 'secundario' && prop.plano_status === 'active'
    )

    if (propriedadeTerceiro?.plano_validade) {
      const isPlanoDeValidadeValido = moment(propriedadeTerceiro?.plano_validade).isAfter(moment().subtract({ day: 1 }))
      return isPlanoDeValidadeValido
    } else {
      return false
    }
  }

  private verificarSeUsuarioPossuiAssinatura(usuario: IUsuario): boolean {
    if(!usuario?.plano_ativo) {
      return false
    } else {
      return true
    }
  }

  private VerificarSeAssinaturaDoUsuarioEstaAtiva(usuario: IUsuario): boolean {
    const isPlanoDeValidadeValido = moment(usuario?.plano_validade).isAfter(moment().subtract({ day: 1 }))
    return isPlanoDeValidadeValido
  }

  private verificarSeAssinaturaDoUsuarioEstaComoTrial(usuario: IUsuario): boolean {
    return usuario?.plano_ativo === 'trial' ? true : false
  }

  private verificarSeUsuarioPossuiAlgumaPropriedadeCadastrada(usuario: IUsuario): boolean {
    return this.propriedadeCtrl.todasPropriedades.some((prop) => prop.id_usuario === usuario.id)
  }

  adicionarClassesSemPermissaoLeituraEscritaEmPropriedadeDeTerceiros(): void {
    const idPropriedade = this.propriedadeCtrl?.propriedadeSelecionada?.id
    const listaPaginas = PaginasComRestricoes

    const body = document.querySelector('body')
    const classes = Array.from(body.classList)

    classes.forEach((classe) => {
      if (classe.includes('sem-permissao')) {
        body.classList.remove(classe)
      }
    })

    const isPropriedadeTerceiros = this.propriedadeCtrl?.propriedadeSelecionada?.meu_role === 'secundario'
    const isPropriedadeExemplo = !!this.propriedadeCtrl?.propriedadeSelecionada?.propriedade_exemplo

    if (isPropriedadeTerceiros && !isPropriedadeExemplo) {
      listaPaginas?.forEach((pagina: string) => {
        if (!this.permissoesPorPropriedade[idPropriedade]?.[pagina]) {
          body.classList.add(`sem-permissao-leitura-${pagina}`)
        } else if (this.permissoesPorPropriedade[idPropriedade][pagina] === 'leitura') {
          body.classList.add(`sem-permissao-escrita-${pagina}`)
        }
      })
    }
  }

  verificaPermissaoDeRota(rota: string, tipo: 'leitura' | 'escrita'): void {
    return this.propriedadeCtrl.verificaPermissaoDeRota(rota, tipo)
  }
}
