<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados?.edited">
    <div class="manejo-nome">Registro de Observação</div>

    <vasta-input type="manejo-input" label="Observação" [value]="dados?.observacao" (valueChange)="dados.edited = true; emit()" (input)="onObservacaoChange($event.target.value)"></vasta-input>

    <div class="container-acoes">
      <!-- <button (click)="adicionarObservacao()" [disabled]="!dados.observacao">
        <ion-icon name="chatbox"></ion-icon>
      </button> -->
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
