<main class="container-genealogia" >
  <div class="genealogia" *ngIf="genealogia">
    <div class="container-filhos">
      <ng-template ngFor let-filho [ngForOf]="genealogia?.crias">
        <div class="card filho" (click)="navegarPorBovinos(filho)">
          <span>{{ filho?.nome || filho?.numeracao || 'Não Informado' }}</span>
        </div>
      </ng-template>
    </div>
    <div class="card principal">
      <span>{{ nomeAnimal || 'Não Informado' }}</span>
    </div>
    <div class="container-pais">
      <div class="container-pai">
        <div class="card m" [ngClass]="{'animal-nao-informado': !genealogia?.pai, 'animal-externo': genealogia?.pai?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.pai)">
          <span>{{ genealogia?.pai?.nome || genealogia?.pai?.numeracao || 'Não Informado' }}</span>
        </div>
        <div class="container-avos">
          <div class="container-avo-p">
            <div class="card m" [ngClass]="{'animal-nao-informado': !genealogia?.pai_pai, 'animal-externo': genealogia?.pai_pai?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.pai_pai)">
              <span>{{ genealogia?.pai_pai?.nome || genealogia?.pai_pai?.numeracao || 'Não Informado' }}</span>
            </div>
            <div class="container-bisavos-p">
              <div class="card m" [ngClass]="{'animal-nao-informado': !genealogia?.pai_pai_pai, 'animal-externo': genealogia?.pai_pai_pai?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.pai_pai_pai)">
                <span>{{ genealogia?.pai_pai_pai?.nome || genealogia?.pai_pai_pai?.numeracao || 'Não Informado' }}</span>
              </div>
              <div class="card f" [ngClass]="{'animal-nao-informado': !genealogia?.pai_pai_mae, 'animal-externo': genealogia?.pai_pai_mae?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.pai_pai_mae)">
                <span>{{ genealogia?.pai_pai_mae?.nome || genealogia?.pai_pai_mae?.numeracao || 'Não Informado' }}</span>
              </div>
            </div>
          </div>
          <div class="container-avo-m">
            <div class="card f" [ngClass]="{'animal-nao-informado': !genealogia?.pai_mae, 'animal-externo': genealogia?.pai_mae?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.pai_mae)">
              <span>{{ genealogia?.pai_mae?.nome || genealogia?.pai_mae?.numeracao || 'Não Informado' }}</span>
            </div>
            <div class="container-bisavos-m">
              <div class="card m" [ngClass]="{'animal-nao-informado': !genealogia?.pai_mae_pai, 'animal-externo': genealogia?.pai_mae_pai?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.pai_mae_pai)">
                <span>{{ genealogia?.pai_mae_pai?.nome || genealogia?.pai_mae_pai?.numeracao || 'Não Informado' }}</span>
              </div>
              <div class="card f" [ngClass]="{'animal-nao-informado': !genealogia?.pai_mae_mae, 'animal-externo': genealogia?.pai_mae_mae?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.pai_mae_mae)">
                <span>{{ genealogia?.pai_mae_mae?.nome || genealogia?.pai_mae_mae?.numeracao || 'Não Informado' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-mae">
        <div class="card f" [ngClass]="{'animal-nao-informado': !genealogia?.mae, 'animal-externo': genealogia?.mae?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.mae)">
          <span>{{ genealogia?.mae?.nome}} <b *ngIf="genealogia?.mae?.numeracao">#{{genealogia?.mae?.numeracao}}</b> {{ genealogia?.mae?.nome || genealogia?.mae?.numeracao ?  '' : 'Não Informado' }}</span>
        </div>
        <div class="container-avos">
          <div class="container-avo-p">
            <div class="card m" [ngClass]="{'animal-nao-informado': !genealogia?.mae_pai, 'animal-externo': genealogia?.mae_pai?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.mae_pai)">
              <span>{{ genealogia?.mae_pai?.nome}} <b *ngIf="genealogia?.mae_pai?.numeracao">#{{genealogia?.mae_pai?.numeracao}}</b> {{ genealogia?.mae_pai?.nome || genealogia?.mae_pai?.numeracao ?  '' : 'Não Informado' }}</span>
            </div>
            <div class="container-bisavos-p">
              <div class="card m" [ngClass]="{'animal-nao-informado': !genealogia?.mae_pai_pai, 'animal-externo': genealogia?.mae_pai_pai?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.mae_pai_pai)">
                <span>{{ genealogia?.mae_pai_pai?.nome}} <b *ngIf="genealogia?.mae_pai_pai?.numeracao">#{{genealogia?.mae_pai_pai?.numeracao}}</b> {{ genealogia?.mae_pai_pai?.nome || genealogia?.mae_pai_pai?.numeracao ?  '' : 'Não Informado' }}</span>
              </div>
              <div class="card f" [ngClass]="{'animal-nao-informado': !genealogia?.mae_pai_mae, 'animal-externo': genealogia?.mae_pai_mae?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.mae_pai_mae)">
                <span>{{ genealogia?.mae_pai_mae?.nome}} <b *ngIf="genealogia?.mae_pai_mae?.numeracao">#{{genealogia?.mae_pai_mae?.numeracao}}</b> {{ genealogia?.mae_pai_mae?.nome || genealogia?.mae_pai_mae?.numeracao ?  '' : 'Não Informado' }}</span>
              </div>
            </div>
          </div>
          <div class="container-avo-m">
            <div class="card f" [ngClass]="{'animal-nao-informado': !genealogia?.mae_mae, 'animal-externo': genealogia?.mae_mae?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.mae_mae)">
              <span>{{ genealogia?.mae_mae?.nome}} <b *ngIf="genealogia?.mae_mae?.numeracao">#{{genealogia?.mae_mae?.numeracao}}</b> {{ genealogia?.mae_mae?.nome || genealogia?.mae_mae?.numeracao ?  '' : 'Não Informado' }}</span>
            </div>
            <div class="container-bisavos-m">
              <div class="card m" [ngClass]="{'animal-nao-informado': !genealogia?.mae_mae_pai, 'animal-externo': genealogia?.mae_mae_pai?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.mae_mae_pai)">
                <span>{{ genealogia?.mae_mae_pai?.nome}} <b *ngIf="genealogia?.mae_mae_pai?.numeracao">#{{genealogia?.mae_mae_pai?.numeracao}}</b> {{ genealogia?.mae_mae_pai?.nome || genealogia?.mae_mae_pai?.numeracao ?  '' : 'Não Informado' }}</span>
              </div>
              <div class="card f" [ngClass]="{'animal-nao-informado': !genealogia?.mae_mae_mae, 'animal-externo': genealogia?.mae_mae_mae?.externo}" [class.animal-nao-informado]="" (click)="navegarPorBovinos(genealogia?.mae_mae_mae)">
                <span>{{ genealogia?.mae_mae_mae?.nome}} <b *ngIf="genealogia?.mae_mae_mae?.numeracao">#{{genealogia?.mae_mae_mae?.numeracao}}</b> {{ genealogia?.mae_mae_mae?.nome || genealogia?.mae_mae_mae?.numeracao ?  '' : 'Não Informado' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="carregamento" *ngIf="!genealogia">
    <ion-spinner></ion-spinner>
  </div>
</main>