import { Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { VastaInputOpcoes } from 'src/app/components/vasta-input/vasta-input.component'
import { FinancasService } from 'src/app/services/financas.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { IConta, IFinancasConta, StatusFinanceiroConta } from 'src/app/utils/interfaces/financas.interface'

@Component({
  selector: 'app-financeiro-cadastrar-nova-conta',
  templateUrl: './financeiro-cadastrar-nova-conta.page.html',
  styleUrls: ['./financeiro-cadastrar-nova-conta.page.scss']
})
export class FinanceiroCadastrarNovaContaPage implements OnInit {
  public tiposDeConta: VastaInputOpcoes = [
    {
      label: 'Corrente',
      value: 'corrente'
    },
    {
      label: 'Poupança',
      value: 'poupanca'
    },
    {
      label: 'Carteira',
      value: 'carteira'
    },
    {
      label: 'Cofre',
      value: 'cofre'
    }
  ]
  public contaPricipalProp = false
  public isSalvadoConta = false
  public isApagandoConta = false
  public financasConta: IFinancasConta = {
    conta: {
      hash: '',
      tipo: '',
      titulo: '',
      agencia: '',
      conta: '',
      descricao: ''
    },
    saldo_inicial: '0',
    conta_principal: false
  }
  public isContaPrincipal = false
  public contaSelecionada: IConta
  public contas: IConta[]

  constructor(
    private modalCtrl: ModalController,
    private financasCtrl: FinancasService,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController
  ) {}

  ngOnInit(): void {
    if (this.contaSelecionada) {
      this.getPropsContaSelecionada()
    } else {
      this.financasConta.conta.tipo = this.tiposDeConta[0].value.toString()
      this.financasConta.conta_principal = !!this.contaPricipalProp
    }
  }

  getPropsContaSelecionada(): void {
    const { hash, titulo, tipo, agencia, conta, descricao, saldo_total, is_principal } = this.contaSelecionada
    this.financasConta.conta.hash = hash
    this.financasConta.conta.titulo = titulo
    this.financasConta.conta.tipo = tipo
    this.financasConta.conta.agencia = agencia
    this.financasConta.conta.conta = conta
    this.financasConta.conta.descricao = descricao
    this.financasConta.saldo_inicial = saldo_total.toString()
    this.financasConta.conta_principal = Number(is_principal) ? true : false
    this.isContaPrincipal = this.financasConta.conta_principal
  }

  async cadastrarOuEditar(): Promise<void> {
    if (this.contaSelecionada) {
      await this.atualizarConta()
    } else {
      await this.cadastrarNovaConta()
    }
  }

  async cadastrarNovaConta(): Promise<void> {
    this.isSalvadoConta = true
    const [response, erro] = await this.financasCtrl.criarNovaConta(this.financasConta)
    if (response) {
      this.utilsCtrl.showToast('Conta Cadastrada com Sucesso', 'bottom')
      this.closeModal({ status: response })
    }

    if (erro) {
      console.error(erro)
    }

    this.isSalvadoConta = false
  }

  async atualizarConta(): Promise<void> {
    this.isSalvadoConta = true
    const [response, erro] = await this.financasCtrl.editarConta(this.financasConta)
    if (response) {
      this.utilsCtrl.showToast('Conta Editada com Sucesso', 'bottom')
      this.closeModal({ status: response })
    }

    if (erro) {
      console.error(erro)
    }

    this.isSalvadoConta = false
  }

  get isFormularioValido(): boolean {
    if (!this.financasConta?.conta?.tipo.length || !this.financasConta?.conta?.titulo.length) {
      return false
    }
    return true
  }

  getEventoContaPadrao(isContaPadrao: boolean): void {
    this.financasConta.conta_principal = isContaPadrao
  }

  async excluirConta(): Promise<void> {
    this.isApagandoConta = true
    const [response, erro] = await this.financasCtrl.apagarConta(this.financasConta.conta.hash)
    if (response) {
      this.utilsCtrl.showToast('Conta Apagada com Sucesso', 'bottom')
      this.closeModal({ isContaApagado: true })
    }

    if (erro) {
      console.error(erro)
    }

    this.isApagandoConta = false
  }

  async alertarSobreExcluirConta(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Excluir Conta',
      message: 'Tem certeza que deseja excluir essa conta?',
      buttons: [
        {
          text: 'Não'
        },
        {
          text: 'Sim',
          handler: async (): Promise<void> => {
            await this.excluirConta()
          }
        }
      ]
    })
    await alert.present()
  }

  closeModal(valor?: StatusFinanceiroConta): void {
    this.modalCtrl.dismiss(valor)
  }
}
