<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Registro de Morte</div>

    <vasta-input
      label="Resultado"
      type="manejo-segment"
      [(value)]="dados.morte_observacoes"
      [opcoes]="[
        { label: 'Morte Confirmada', value: 'morto' },
        { label: 'Não Confirmada', value: 'vivo' }
      ]"
      (valueChange)="segmentChange()"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.morte_observacoes == 'morto'"
      label="Causa"
      type="manejo-select"
      [(value)]="dados.morte_causa"
      [opcoes]="[
        { label: 'Acidente', value: 'acidente' },
        { label: 'Ataque de Onça', value: 'ataque-de-onca' },
        { label: 'Complicações no parto', value: 'complicacoes-no-parto' },
        { label: 'Eletrocussão', value: 'eletrocussao' },
        { label: 'Enroscado na cerca', value: 'enroscado-na-cerca' },
        { label: 'Indefinida', value: 'indefinida' },
        { label: 'Infecção', value: 'infeccao' },
        { label: 'Intoxicação', value: 'intoxicacao' },
        { label: 'Natimorto', value: 'natimorto' },
        { label: 'Picada de Cobra', value: 'picada-de-cobra' },
        { label: 'Tristeza Parasitária Bovina', value: 'tristeza' },
        { label: 'Verminose', value: 'verminose' },
        { label: 'Abatido', value: 'abatido' },
        { label: 'Outro', value: 'outros' }
      ]"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <div class="images-container" *ngIf="dados.morte_observacoes == 'morto'">
      <div class="morte-img" *ngIf="dados.morte_imagem_1">
        <img [src]="dados.morte_imagem_1" />
        <ion-icon name="close" (click)="removeImagem(1)"></ion-icon>
      </div>
      <div class="morte-img" *ngIf="dados.morte_imagem_2">
        <img [src]="dados.morte_imagem_2" />
        <ion-icon name="close" (click)="removeImagem(2)"></ion-icon>
      </div>
      <div class="morte-img" *ngIf="dados.morte_imagem_3">
        <img [src]="dados.morte_imagem_3" />
        <ion-icon name="close" (click)="removeImagem(3)"></ion-icon>
      </div>
      <div class="morte-img" *ngIf="dados.morte_imagem_4">
        <img [src]="dados.morte_imagem_4" />
        <ion-icon name="close" (click)="removeImagem(4)"></ion-icon>
      </div>
      <div class="morte-img" *ngIf="dados.morte_imagem_5">
        <img [src]="dados.morte_imagem_5" />
        <ion-icon name="close" (click)="removeImagem(5)"></ion-icon>
      </div>

      <button class="add-photo" *ngIf="!dados.morte_imagem_5 && !enviandoImagem">
        <enviar-imagem (imagemSrc)="enviarImagens($event)"></enviar-imagem>
        <ion-icon name="add"></ion-icon>
      </button>

      <button class="add-photo" *ngIf="!dados.morte_imagem_5 && enviandoImagem">
        <ion-spinner></ion-spinner>
      </button>
    </div>

    <!-- <vasta-input
      *ngIf="dados.morte_observacoes == 'morto'"
      label="Imagem 1"
      type="manejo-input"
      [(value)]="dados.morte_imagem_1"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.morte_observacoes == 'morto' && dados.morte_imagem_1"
      label="Imagem 2"
      type="manejo-input"
      [(value)]="dados.morte_imagem_2"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.morte_observacoes == 'morto' && dados.morte_imagem_2"
      label="Imagem 3"
      type="manejo-input"
      [(value)]="dados.morte_imagem_3"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.morte_observacoes == 'morto' && dados.morte_imagem_3"
      label="Imagem 4"
      type="manejo-input"
      [(value)]="dados.morte_imagem_4"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.morte_observacoes == 'morto' && dados.morte_imagem_4"
      label="Imagem 5"
      type="manejo-input"
      [(value)]="dados.morte_imagem_5"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input> -->

    <!-- <vasta-input
      *ngIf="dados.morte_observacoes == 'morto'"
      label="Latitude"
      type="manejo-input"
      [(value)]="dados.morte_latitude"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.morte_observacoes == 'morto'"
      label="Longitude"
      type="manejo-input"
      [(value)]="dados.morte_longitude"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input> -->

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="dados.morte_observacoes !== 'morto'">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
