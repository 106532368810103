<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title> Conta Principal Expirada </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <img src="assets/SVG/plano-expirado.svg" alt="imagem plano-expirado fertili" />

  <p>
    A assinatura do usuário principal expirou. Se você acha que isso é um erro, entre em contato com nosso time pelo
    WhatsApp abaixo e informe o ocorrido.
  </p>

  <cta-buttons>
    <button (click)="entrarEmContato()">
      <ion-icon name="logo-whatsapp"></ion-icon>
      77 99839-1515
    </button>
  </cta-buttons>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <cta-buttons>
      <button (click)="sairDaConta()">
        Sair da Conta
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
