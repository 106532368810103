<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/areas" slot="icon-only"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Área <b>{{ area?.nome }}</b>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="maximize = !maximize">
        <ion-icon *ngIf="!maximize" name="expand" color="primary"></ion-icon>
        <ion-icon *ngIf="maximize" name="contract" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <header-tabs
    [tabs]="['Lotes', 'Histórico', 'Piquetes', 'Pastejo Rotacionado']"
    [(tabActive)]="abaAtiva"
    (tabActiveChange)="tabChange()"
  ></header-tabs>
</ion-header>
<ion-content>
  <vasta-mapa
    [hidden]="abaAtiva == 'Histórico'"
    [class.maximize]="maximize"
    [areas]="[area]"
    [piquetes]="piquetes"
    [mostrarQtdAnimais]="true"
  ></vasta-mapa>

  <div [hidden]="abaAtiva !== 'Pastejo Rotacionado'">
    <vasta-tabela
      [config]="manejosPastejosRotacionadosConfig"
      [labels]="manejosPastejoRotacionadoLabels"
      [campos]="manejosPastejoRotacionado"
      [status]="manejosPastejoRotacionadoCarregamento"
      [(paginate)]="manejosPastejoRotacionadoPaginate"
      (paginateChange)="getManejosPastejoRotacionado()"
      [(novoFiltro)]="manejosPastejoRotacionadoFiltro"
      [(novaOrdenacao)]="manejosPastejoRotacionadoNovaOrdenacao"
      (novoFiltroChange)="getManejosPastejoRotacionado()"
      (novaOrdenacaoChange)="getManejosPastejoRotacionado()"
      (selecionado)="abrirManejosDoDia($event)"
      (retentar)="getManejosPastejoRotacionado()"
    >
    </vasta-tabela>
  </div>

  <div [hidden]="abaAtiva !== 'Lotes'">
    <cta-buttons>
      <button class="primary permissao-escrita-areas acao-cadastro" (click)="openModalCadastrarArea()">
        <ion-icon name="pencil-outline"></ion-icon>
        <span>Editar área</span>
      </button>
      <button class="permissao-escrita-areas" (click)="associarLoteAArea(area)">
        <ion-icon name="mais"></ion-icon>
        <span>Associar Lote</span>
      </button>
    </cta-buttons>

    <kpis-cards [cardsInfo]="optionsKpisCard" [valores]="kpisValores" [wrap]="true"></kpis-cards>

    <vasta-tabela
      [config]="config"
      [labels]="labels"
      [campos]="lotes"
      [status]="carregamentoLotes"
      [optionsButtons]="optionsButtonsTabela"
      (selecionado)="irParaPaginaLoteSingle($event.original)"
      [(novoFiltro)]="novoFiltroLotes"
      (novoFiltroChange)="getLotes()"
      [(novaOrdenacao)]="novaOrdenacaoLotes"
      (novaOrdenacaoChange)="getLotes()"
      (retentar)="getLotes()"
    ></vasta-tabela>

    <div class="cadastrado-em" *ngIf="area && area.created_at">
      Cadastrado em
      <b> {{ area.created_at | timestamp: 'horaminutos' }} de {{ area.created_at | timestamp: 'dataSimples' }} </b>,
      atualizado <b> {{ area.created_at | timestamp: 'tempoatras' }}</b>.
    </div>
  </div>
  <div [hidden]="abaAtiva !== 'Histórico'">
    <vasta-tabela
      [config]="configTabelaHistorico"
      [labels]="labelsHistorico"
      [campos]="historicoAreas"
      [status]="carregamentoHistoricoAreas"
      (retentar)="getHistoricoAreas()"
      (selecionado)="abrirManejosDoDia($event)"
      [(paginate)]="paginateHistorico"
      (paginateChange)="getHistoricoAreas()"
    ></vasta-tabela>
  </div>

  <div [hidden]="abaAtiva !== 'Piquetes'">
    <cta-buttons>
      <button class="primary permissao-escrita-areas acao-cadastro" (click)="openModalCadastrarPiquete()">
        <ion-icon name="mais"></ion-icon>
        <span>Novo Piquete</span>
      </button>
      <button (click)="abrirImportacaoKML()">
        <ion-icon name="cloud-upload"></ion-icon>
        <span>Importar KML/KMZ</span>
      </button>
    </cta-buttons>

    <vasta-tabela
      [config]="piquetesConfig"
      [labels]="piquetesLabels"
      [campos]="piquetes"
      [status]="carregamentoPiquetes"
      (selecionado)="openModalCadastrarPiquete($event.original)"
      [(paginate)]="piquetesPaginate"
      [(novoFiltro)]="piquetesNovoFiltro"
      [(novaOrdenacao)]="piquetesNovaOrdenacao"
      [optionsButtons]="piquetesOptionsButtonsTabela"
      (retentar)="getPiquetes()"
    ></vasta-tabela>
  </div>
</ion-content>
