import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Preferences } from '@capacitor/preferences'
import { Subscription } from 'rxjs'
import { IPropriedade } from 'src/app/utils/interfaces/propriedades.interface'
import {
  NovoQueryParamsModel,
  ObjetoGenerico,
  QueryParamsmModel
} from 'src/app/utils/interfaces/query-params.interface'
import { environment } from 'src/environments/environment'

type HttpParams = {
  url: string
  body?: unknown
  headers?: HttpHeaders
  queryParams?: QueryParamsmModel
  filtros?: NovoQueryParamsModel
  dataConsulta?: string
}

type PassosFunil =
  | 'basico'
  | 'trial'
  | '360_cadastrou_propriedade'
  | '360_tutorial_area'
  | '360_tutorial_lote'
  | '360_tutorial_animal'
  | '360_tutorial_pesagem'
  | '360_cadastrou_area'
  | '360_cadastrou_lote'
  | '360_cadastrou_animal'
  | '360_cadastrou_pesagem'
  | '360_ativou_360debolso'
  | 'funil_relacaoPecuaria'
  | 'funil_focoPrincipalFazenda'
  | 'funil_identificacaoAnimais'
  | 'funil_acompanhaGanhoPesoRebanho'
  | 'funil_topicosRepresentamRealidadeNegocio'
  | 'funil_coletaArmazenaInformacoesAnimaisFazenda'
  | 'funil_principaisNecessidadesDesafiosGestaoFazenda'
  | 'funil_quantosAnimaisPlanejaCadastrarFertili'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public idPropriedade: number = null

  private requestSubscriptions = new Map<string, Subscription>()

  constructor(private http: HttpClient) {
    this.inicializaIdPropriedade()
  }

  async inicializaIdPropriedade(): Promise<void> {
    if (!this.idPropriedade) {
      const selecionadaStorage = await Preferences.get({
        key: 'propriedade_selecionada'
      })

      if (selecionadaStorage.value && selecionadaStorage.value !== 'undefined') {
        const propriedadeSelecionada = JSON.parse(selecionadaStorage.value) as IPropriedade
        this.idPropriedade = propriedadeSelecionada?.id
      }
    }
  }

  async post(params: HttpParams, retentativa = false, prefixoPropriedade = ''): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      const headers = await this.montarHeader('post')
      this.http.post(`${environment.url + prefixoPropriedade}/${params.url}`, params.body, { headers }).subscribe(
        (data) => {
          resolve(data)
        },
        async (err) => {
          if (err?.error?.code === environment.jwtCode && !retentativa) {
            await Preferences.set({
              key: 'fertili-token',
              value: err.error.token
            })
            const response = await this.get({ url: params.url }, true, prefixoPropriedade)
            resolve(response)
          } else reject(err.error)
        }
      )
    })
  }

  async get(params: HttpParams, retentativa = false, prefixoPropriedade = ''): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      const headers = await this.montarHeader('get', params.filtros)

      const urlSemQueryParams = params.url
      let paramsURL = `${params.url}`

      if (params.dataConsulta) {
        paramsURL += `?dataAnimais=${params.dataConsulta}`
      }

      if (params.queryParams) {
        paramsURL += this.formatarQueryParams(params.queryParams)
      }

      if (this.requestSubscriptions.has(urlSemQueryParams) && !urlSemQueryParams.includes('propriedades')) {
        this.requestSubscriptions.get(urlSemQueryParams).unsubscribe()
      }

      this.requestSubscriptions.set(urlSemQueryParams, this.http.get(`${environment.url + prefixoPropriedade}/${paramsURL}`, { headers }).subscribe(
        (data) => {
          this.requestSubscriptions.delete(urlSemQueryParams)
          resolve(data)
        },
        async (err) => {
          this.requestSubscriptions.delete(urlSemQueryParams)
          if (err.error?.name === environment.jwtCode && !retentativa) {
            await Preferences.set({
              key: 'fertili-token',
              value: err.error.token
            })
            const response = await this.get(params, true, prefixoPropriedade)
            resolve(response)
          } else reject(err.error)
        }
      ))
    })
  }

  async put(params: HttpParams, retentativa = false, prefixoPropriedade = ''): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      const headers = await this.montarHeader('put')
      this.http.put(`${environment.url + prefixoPropriedade}/${params.url}`, params.body, { headers }).subscribe(
        (data) => {
          resolve(data)
        },
        async (err) => {
          if (err.error.code === environment.jwtCode && !retentativa) {
            await Preferences.set({
              key: 'fertili-token',
              value: err.error.token
            })
            const response = await this.put(params, true, prefixoPropriedade)
            resolve(response)
          } else reject(err.error)
        }
      )
    })
  }

  async delete(params: HttpParams, retentativa = false, prefixoPropriedade = ''): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      const headers = await this.montarHeader('delete')

      if (params.queryParams) {
        const queryParamsFormatado = this.formatarQueryParams(params.queryParams)
        params.url += queryParamsFormatado
      }

      this.http.delete(`${environment.url + prefixoPropriedade}/${params.url}`, { headers }).subscribe(
        (data) => {
          resolve(data)
        },
        async (err) => {
          if (err.error.code === environment.jwtCode && !retentativa) {
            await Preferences.set({
              key: 'fertili-token',
              value: err.error.token
            })
            const response = await this.get({ url: params.url }, true, prefixoPropriedade)
            resolve(response)
          } else reject(err.error)
        }
      )
    })
  }

  async getComPrefixoPropriedade(params: HttpParams): Promise<unknown> {
    const prefixoPropriedade = `/propriedade/${this.idPropriedade}`
    return await this.get(params, false, prefixoPropriedade)
  }

  async postComPrefixoPropriedade(params: HttpParams): Promise<unknown> {
    const prefixoPropriedade = `/propriedade/${this.idPropriedade}`
    return await this.post(params, false, prefixoPropriedade)
  }

  async putComPrefixoPropriedade(params: HttpParams): Promise<unknown> {
    const prefixoPropriedade = `/propriedade/${this.idPropriedade}`
    return await this.put(params, false, prefixoPropriedade)
  }

  async deleteComPrefixoPropriedade(params: HttpParams): Promise<unknown> {
    const prefixoPropriedade = `/propriedade/${this.idPropriedade}`
    return await this.delete(params, false, prefixoPropriedade)
  }

  formatarFiltros(data?: NovoQueryParamsModel): Record<string, string> {
    const filtros = Object.keys(data?.filtros || {}).reduce((acc, key) => {
      if (typeof data.filtros[key] === 'object') {
        acc[`filtro_${key}`] = JSON.stringify(data.filtros[key])
      } else {
        acc[`filtro_${key}`] = data.filtros[key]
      }

      return acc
    }, {})

    return {
      ...filtros,
      order_by: data?.ordenacao?.coluna || '',
      order_direction: data?.ordenacao?.direcao || '',
      query_limit: String(data?.paginate?.limit || '') || '',
      query_offset: String(data?.paginate?.offset || '0') || '0'
    }
  }

  private async montarHeader(tipoRequest: string, data?: NovoQueryParamsModel): Promise<HttpHeaders> {
    const filtros = this.formatarFiltros(data)

    const { value: token } = await Preferences.get({ key: 'fertili-token' })
    let tokenFormatado = `Bearer ${token}`
    let headersOptions = {
      'Content-Type': 'application/json',
      authorization: tokenFormatado,
      app_version: environment.appVersion,
      ...filtros
    }

    if (!token) delete headersOptions['Authorization']
    if (tipoRequest === 'get' || tipoRequest === 'delete') {
      delete headersOptions['Content-Type']
    }
    return new HttpHeaders(headersOptions)
  }

  getParamsFormatado(params: QueryParamsmModel): string {
    const queryParams = this.preencherQueryParams(params)
    const queryParamsFormatado = this.formatarQueryParams(queryParams)

    if (queryParamsFormatado) {
      return queryParamsFormatado
    } else {
      return ''
    }
  }

  preencherQueryParams(params: QueryParamsmModel): ObjetoGenerico {
    const queryParams: ObjetoGenerico = {}
    const novosParams = JSON.parse(JSON.stringify(params))

    if (novosParams?.filtros?.length) {
      novosParams.filtros.forEach(({ chave, valor }) => (queryParams[chave] = valor))
      delete novosParams.filtros
    } else if (novosParams?.filtros) {
      delete novosParams?.filtros
    }

    if (novosParams?.ordenacao) {
      queryParams['order_by'] = `${novosParams.ordenacao?.chave},${novosParams.ordenacao?.order}`
      delete novosParams.ordenacao
    }

    if (novosParams?.hash) {
      queryParams['hash'] = encodeURIComponent(novosParams?.hash)
      delete novosParams.hash
    }

    if (novosParams?.paginate) {
      const listQuery = Object.entries(novosParams.paginate)
      listQuery.forEach(([chave, valor]) => (queryParams[chave] = valor))
      delete novosParams.paginate
    } else {
      queryParams['limit'] = '50'
    }

    const chaveQueries = Object.keys(novosParams)

    if (chaveQueries.length) {
      chaveQueries.forEach((chave) => (queryParams[chave] = novosParams[chave]))
    }

    return queryParams
  }

  formatarQueryParams(objetoQueryParams: ObjetoGenerico): string {
    let urlFormatada = ''
    const listaQuery = Object.entries(objetoQueryParams)
    if (listaQuery) {
      listaQuery.forEach(([chave, valor]) => (urlFormatada += `&${chave}=${encodeURIComponent(valor as string)}`))
    }
    return `?${urlFormatada}`
  }

  setPassoFunil(action: PassosFunil | string, value?: string[]): Promise<void> {
    return new Promise(async () => {
      try {
        await this.post({
          url: 'usuario/funil/set-passo',
          body: {
            action,
            value
          }
        })
      } catch (_) { }
    })
  }
}
