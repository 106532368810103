<div class="ano-mes-wrapper-geral">
  <div class="ano-mes-container">
    <div class="ano-mes-group">
      <div class="ano-mes-container">
        <div class="titulos" *ngIf="titulo">
          <h3 [innerHTML]="titulo"></h3>
          <h4 *ngIf="subtitulo">{{ subtitulo }}</h4>
        </div>

        <div class="seletores-container">
          <button *ngIf="range" (click)="exibeOpcoesRapidas()" class="range-button">
            <ion-icon name="calendar"></ion-icon>
            <div
              class="custom-popover-overlay"
              [class.show]="opcoesRapidasOpen"
              (click)="fechaOpcoesRapidas($event)"
            ></div>
            <div class="custom-popover" [class.show]="opcoesRapidasOpen">
              <button (click)="selecaoRapida($event, 'este-mes');">Este Mês</button>
              <button (click)="selecaoRapida($event, 'mes-passado');">Mês Passado</button>
              <button (click)="selecaoRapida($event, 'ultimos-3-meses');">Últimos 3 Meses</button>
              <button (click)="selecaoRapida($event, 'ultimos-6-meses');">Últimos 6 Meses</button>
              <button (click)="showSubPopover('ano')">
                Ano
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
              <button (click)="showSubPopover('safra')">
                Safra
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
            <div class="custom-sub-popover" [class.show]="isSubPopoverVisible" [class.lista-anos]="!!listaAnosPopover.length" [class.lista-safra]="!!listaSafrasPopover.length">
              <ng-template [ngIf]="listaAnosPopover.length > 0">
                <button *ngFor="let ano of listaAnosPopover" (click)="selecaoRapida($event, 'ano', ano); isSubPopoverVisible = false">{{ano}}</button>
              </ng-template>
              <ng-template [ngIf]="listaSafrasPopover.length > 0">
                <button *ngFor="let safra of listaSafrasPopover" (click)="selecaoRapida($event, 'safra', safra); isSubPopoverVisible = false">{{safra}}</button>
              </ng-template>
            </div>
          </button>
  
          <div class="botoes-ano-mes-container-ano">
            <button (click)="anoMenos()" [attr.disabled]="anoExibido < anoMin + 1 ? '' : null">
              <ion-icon name="arrow-back"></ion-icon>
            </button>
            <select [(ngModel)]="anoExibido">
              <option *ngFor="let ano of this.anosDisponiveis" [value]="ano">{{ ano }}</option>
            </select>
            <button (click)="anoMais()" [attr.disabled]="anoExibido >= anoMax ? '' : null">
              <ion-icon name="arrow-forward"></ion-icon>
            </button>
          </div>
        </div>

        <div class="botoes-ano-mes-container">
          <button
            [attr.disabled]="
              (ano >= anoMax && 1 > mesMax) || 
              (ano <= anoMin && 1 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 1 < mes) || (anoAte == anoExibido && mesAte && 1 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 1 && mesAte > 1) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 1) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 1) )
            "
            [class.de]="
              (ano == anoExibido && mes == 1)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 1)
            "
            [class.atual]="mesAtual == 1 && anoAtual == anoExibido"
            (click)="selecionaMes(1)"
          >
            Janeiro
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 2 > mesMax) || 
              (ano <= anoMin && 2 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 2 < mes) || (anoAte == anoExibido && mesAte && 2 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 2 && mesAte > 2) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 2) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 2) )
            "
            [class.de]="
              (ano == anoExibido && mes == 2)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 2)
            "
            [class.atual]="mesAtual == 2 && anoAtual == anoExibido"
            (click)="selecionaMes(2)"
          >
            Fevereiro
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 3 > mesMax) || 
              (ano <= anoMin && 3 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 3 < mes) || (anoAte == anoExibido && mesAte && 3 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 3 && mesAte > 3) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 3) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 3) )
            "
            [class.de]="
              (ano == anoExibido && mes == 3)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 3)
            "
            [class.atual]="mesAtual == 3 && anoAtual == anoExibido"
            (click)="selecionaMes(3)"
          >
            Março
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 4 > mesMax) || 
              (ano <= anoMin && 4 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 4 < mes) || (anoAte == anoExibido && mesAte && 4 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 4 && mesAte > 4) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 4) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 4) )
            "
            [class.de]="
              (ano == anoExibido && mes == 4)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 4)
            "
            [class.atual]="mesAtual == 4 && anoAtual == anoExibido"
            (click)="selecionaMes(4)"
          >
            Abril
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 5 > mesMax) || 
              (ano <= anoMin && 5 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 5 < mes) || (anoAte == anoExibido && mesAte && 5 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 5 && mesAte > 5) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 5) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 5) )
            "
            [class.de]="
              (ano == anoExibido && mes == 5)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 5)
            "
            [class.atual]="mesAtual == 5 && anoAtual == anoExibido"
            (click)="selecionaMes(5)"
          >
            Maio
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 6 > mesMax) || 
              (ano <= anoMin && 6 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 6 < mes) || (anoAte == anoExibido && mesAte && 6 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 6 && mesAte > 6) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 6) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 6) )
            "
            [class.de]="
              (ano == anoExibido && mes == 6)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 6)
            "
            [class.atual]="mesAtual == 6 && anoAtual == anoExibido"
            (click)="selecionaMes(6)"
          >
            Junho
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 7 > mesMax) || 
              (ano <= anoMin && 7 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 7 < mes) || (anoAte == anoExibido && mesAte && 7 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 7 && mesAte > 7) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 7) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 7) )
            "
            [class.de]="
              (ano == anoExibido && mes == 7)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 7)
            "
            [class.atual]="mesAtual == 7 && anoAtual == anoExibido"
            (click)="selecionaMes(7)"
          >
            Julho
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 8 > mesMax) || 
              (ano <= anoMin && 8 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 8 < mes) || (anoAte == anoExibido && mesAte && 8 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 8 && mesAte > 8) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 8) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 8) )
            "
            [class.de]="
              (ano == anoExibido && mes == 8)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 8)
            "
            [class.atual]="mesAtual == 8 && anoAtual == anoExibido"
            (click)="selecionaMes(8)"
          >
            Agosto
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 9 > mesMax) || 
              (ano <= anoMin && 9 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 9 < mes) || (anoAte == anoExibido && mesAte && 9 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 9 && mesAte > 9) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 9) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 9) )
            "
            [class.de]="
              (ano == anoExibido && mes == 9)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 9)
            "
            [class.atual]="mesAtual == 9 && anoAtual == anoExibido"
            (click)="selecionaMes(9)"
          >
            Setembro
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 10 > mesMax) || 
              (ano <= anoMin && 10 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 10 < mes) || (anoAte == anoExibido && mesAte && 10 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 10 && mesAte > 10) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 10) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 10) )
            "
            [class.de]="
              (ano == anoExibido && mes == 10)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 10)
            "
            [class.atual]="mesAtual == 10 && anoAtual == anoExibido"
            (click)="selecionaMes(10)"
          >
            Outubro
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 11 > mesMax) || 
              (ano <= anoMin && 11 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 11 < mes) || (anoAte == anoExibido && mesAte && 11 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 11 && mesAte > 11) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 11) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 11) )
            "
            [class.de]="
              (ano == anoExibido && mes == 11)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 11)
            "
            [class.atual]="mesAtual == 11 && anoAtual == anoExibido"
            (click)="selecionaMes(11)"
          >
            Novembro
          </button>
          <button
            [attr.disabled]="
              (ano >= anoMax && 12 > mesMax) || 
              (ano <= anoMin && 12 < mesMin) || 
              (range && !(mes && mesAte) && ((ano == anoExibido && mes && 12 < mes) || (anoAte == anoExibido && mesAte && 12 > mesAte))) 
              ? '' : null"
            [class.meio]="
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte == anoExibido && mes < 12 && mesAte > 12) ) || 
              (ano && anoAte && mes && mesAte && (ano == anoExibido && anoAte > anoExibido && mes < 12) ) || 
              (ano && anoAte && mes && mesAte && (ano < anoExibido && anoAte == anoExibido && mesAte > 12) )
            "
            [class.de]="
              (ano == anoExibido && mes == 12)
            "
            [class.ate]="
              (anoAte == anoExibido && mesAte == 12)
            "
            [class.atual]="mesAtual == 12 && anoAtual == anoExibido"
            (click)="selecionaMes(12)"
          >
            Dezembro
          </button>
        </div>

        <div class="ano-mes-botoes-de-acao" *ngIf="range">
          <button class="cancelar" (click)="dismiss()">
            <ion-icon name="close"></ion-icon>
            <span>Cancelar</span>
          </button>
          <button class="confirmar" (click)="confirmar()">
            <ion-icon name="checkmark"></ion-icon>
            <span>Confirmar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
