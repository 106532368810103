import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewEncapsulation } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import * as moment from 'moment'
import { ManejoPesagem } from 'src/app/services/manejos.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-pesagem',
  templateUrl: './manejo-pesagem.component.html',
  styleUrls: ['./manejo-pesagem.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManejoPesagemComponent implements OnInit {
  @Input('dados') dados: ManejoPesagem
  @Input('pesagemAnterior') pesagemAnterior: { peso: number; data: string }
  @Input('data') data: string
  @Input('ultimoPeso') ultimoPeso: number
  @Input('ultimoPesoData') ultimoPesoData: string
  @Output() dadosChange = new EventEmitter<ManejoPesagem>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  gmd = 0

  constructor(private popoverCtrl: PopoverController, public planoBasicoCtrl: PlanoBasicoService) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChange): void {
    if (changes['ultimoPeso']) {
      this.calculaGMD()
    }
  }

  emit(): void {
    this.dadosChange.emit(this.dados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  calculaGMD(): void {
    if (!this.dados.peso || !this.ultimoPesoData) return
    const days = moment(this.data).diff(this.ultimoPesoData, 'days')
    this.gmd = (Number(this.dados.peso) - Number(this.ultimoPeso)) / days
    this.dados.gmd = this.gmd
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.peso = null
      this.calculaGMD()
      this.emit()
    }
  }
}
