/* eslint-disable indent */
import { Component, OnInit, ViewChild } from '@angular/core'
import { Pelagens, Racas } from 'src/app/utils/racas'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { BovinoSituacoes, IAnimal, IAnimalPayload } from 'src/app/utils/interfaces/animais.interface'
import { AnimaisService } from 'src/app/services/animais.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { IonPopover, ModalController } from '@ionic/angular'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import * as moment from 'moment'
import { ModalListaLotesPage } from '../modal-lista-lotes/modal-lista-lotes.page'
import { ModalListaRacasPage } from '../modal-lista-racas/modal-lista-racas.page'
import { ModalListaAnimaisPage } from '../modal-lista-animais/modal-lista-animais.page'
import { IArvoreGenealogica } from 'src/app/utils/interfaces/genealogia.interface'
import { IGenealogiaPaisAgrupado } from 'src/app/utils/interfaces/manejos.interface'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { ImportacaoService } from 'src/app/services/importacao.service'
import { PermissoesUsuarioService } from 'src/app/services/permissoes-usuario.service'
import { VastaRX } from '@vasta/rx'

@Component({
  selector: 'app-modal-bovino',
  templateUrl: './modal-bovino.component.html',
  styleUrls: ['./modal-bovino.component.scss']
})
export class ModalBovinoComponent implements OnInit {
  public maeProp: IAnimal
  public numerosDeControleJaUtilizados: string[]
  public isNumeroJaCadastrado = false
  public isNumeroIgualAoDaMae = false
  public animal: IAnimalPayload = {
    id_propriedade: this.propriedadesCtrl.propriedadeSelecionada?.id,
    sexo: 'macho',
    situacao: 'vivo',
    aptidao: 'dupla',
    tipo_entrada: '',
    data_nascimento: moment().format('YYYY-MM-DD'),
    pesagens: {
      peso_nascimento: 0,
    },
    tipo: 'bovino',
    meta_raca_composicao: '1/1',
    meta_pelagem: ''
  }
  public dataNascimentoProp: string
  public animalCopy: IAnimalPayload
  public hashAnimalProp: string
  public racas = JSON.parse(JSON.stringify(Racas))
  public lotes: ILote[]
  public abaAtiva = 'Geral'
  public animalProp: IAnimalPayload
  public dadosSituacaoAnimal = {
    vivo: 'Vivo',
    perdido: 'Perdido',
    morto: 'Morto',
    vendido: 'Vendido',
    abatido: 'Abatido',
    movido: 'Movido',
    natimorto: 'Natimorto'
  }
  public dadosOrigemAnimal = {
    nascimento: 'Nascimento',
    '': 'Não Informado',
    novo: 'Aquisição'
  }
  public dadosAptidaoAnimal = {
    corte: 'Corte',
    dupla: 'Dupla Aptidão',
    leite: 'Leite'
  }
  public loteProp: { hash: string; nome: string } = null

  public isLoading = false

  public genealogiaParto: IGenealogiaPaisAgrupado

  public isReprodutorPreenchido = false
  public isMatrizPreenchida = false
  public novaOrdenacao: Ordenacao = { coluna: 'numeracao', direcao: 'desc' }
  
  public pelagensDisponiveis: { value: string; label: string }[] = []

  @ViewChild('situacao') situacao: IonPopover
  @ViewChild('origem') origem: IonPopover
  @ViewChild('aptidao') aptidao: IonPopover
  constructor(
    public propriedadesCtrl: PropriedadesService,
    private animaisCtrl: AnimaisService,
    private utilsCtrl: IonicUtilsService,
    private importacaoCtrl: ImportacaoService,
    private modalCtrl: ModalController,
    private permissoesUsuarioCtrl: PermissoesUsuarioService
  ) {
    this.pelagensDisponiveis = Object.keys(Pelagens).map(pelagem => {
      return {
        value: pelagem,
        label: pelagem
      }
    })
  }

  async ngOnInit(): Promise<void> {
    await this.fecharModalSeLimiteDeAnimaisAlcancado()

    const pesoPadraoAoNascer = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.peso_padrao_ao_nascer || 30
    this.animal.pesagens.peso_nascimento = Number(pesoPadraoAoNascer)

    if (this.animalProp) {
      this.animal = { ...this.animal, ...this.animalProp }
      this.animal.situacao = this.animalProp.situacao || null
      this.animal.situacao_produtiva = this.animalProp.situacao_produtiva || ''
      this.animal.situacao_reprodutiva = this.animalProp.situacao_reprodutiva || ''
      this.animal.situacao_sanitaria = this.animalProp.situacao_sanitaria || ''
      this.animalCopy = JSON.parse(JSON.stringify(this.animal))
    } else {
      this.animal.raca = this.propriedadesCtrl.propriedadeSelecionada.metadados.raca_principal
      this.animal.aptidao = this.propriedadesCtrl.propriedadeSelecionada.metadados.tipo_producao
    }
    this.animal.meta_raca_composicao = this.animal.meta_raca_composicao || '1/1'

    if (this.dataNascimentoProp) {
      this.animal.data_nascimento = this.dataNascimentoProp
    }

    if (this.loteProp) {
      this.animal.extra_lote = this.loteProp.nome
      this.animal.hash_lote = this.loteProp.hash
    }

    await this.getNumerosDeControleJaEmUso()

    if(this.maeProp) {
      this.inicializarCriaComDadosDaMae()
    }

    if (this.genealogiaParto) {
      this.preencherGenealogiaDeAnimal(this.genealogiaParto)
    }

    if(!this.animalProp) {
      this.verificarSeNumeroJaEstaCadastrado()
    }
  }

  async fecharModalSeLimiteDeAnimaisAlcancado(): Promise<void> {
    const { isPossuiPermissaoCadastro } = await this.permissoesUsuarioCtrl.checkPermissoesCadastroNovosAnimais()

    if(!isPossuiPermissaoCadastro) {
      await this.modalCtrl.dismiss()
    }
  }

  inicializarCriaComDadosDaMae(): void {
    this.animal.tipo_entrada = 'nascimento'
    this.animal.aptidao = this.maeProp?.aptidao
    this.animal.extra_lote = this.maeProp?.extra_lote
    this.animal.hash_lote = this.maeProp?.hash_lote
    this.animal.numeracao = this.maeProp?.numeracao
    this.animal.raca = this.maeProp?.raca
  }

  preencherGenealogiaDeAnimal(genealogiaParto: IGenealogiaPaisAgrupado): void {
    this.animal.genealogia_matriz_interna = genealogiaParto.maeInterna?.hash ?? null
    this.animal.genealogia_reprodutor_interno = genealogiaParto.paiInterno?.hash ?? null
    this.animal.genealogia_matriz_externa = genealogiaParto?.maeExterna?.hash ?? null
    this.animal.genealogia_reprodutor_externo = genealogiaParto?.paiExterno?.hash ?? null
  }

  verificarSeNumeroJaEstaCadastrado(): void {
    this.isNumeroJaCadastrado = false
    this.isNumeroIgualAoDaMae = false

    const numeroMae = this.animal?.genealogia_numero_mae || this.maeProp?.numeracao

    if(this.animal?.numeracao?.length && this.animal.numeracao === numeroMae) {
      this.isNumeroIgualAoDaMae = true
    } else if (this.numerosDeControleJaUtilizados.includes(this.animal.numeracao)) {
      this.isNumeroJaCadastrado = true
    } 
  }

  async getNumerosDeControleJaEmUso(): Promise<void> {
    this.numerosDeControleJaUtilizados = await this.getNumeroExistentes()
  }

  async getNumeroExistentes(): Promise<string[]> {
    const [response, erro] = await this.importacaoCtrl.getNumeracoesExistentes()
    
    if(response) {
      return response
    }

    if(erro) {
      console.error(erro)
    }
  }

  async openModalListaLotes(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaLotesPage,
      cssClass: 'custom-modal-lotes',
      componentProps: { lotes: this.lotes }
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()
    if (data?.lotes) {
      const [lote] = data?.lotes
      this.animal.extra_lote = lote.nome
      this.animal.hash_lote = lote.hash
    }
  }

  get isMetadadosControleAnimaisIgualANome(): boolean {
    return (
      !this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais ||
      this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais === 'numero-e-nome' ||
      this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais === 'nome'
    )
  }

  async openModalListaRacas(qual?: 'primaria' | 'secundaria' | 'terciaria'): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaRacasPage,
      cssClass: 'custom-modal'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()

    if (data) {
      this.animal.meta_puro_origem = ''

      if (qual == 'primaria') {
        this.animal.raca = data.raca
      }
      if (qual == 'secundaria') {
        this.animal.meta_raca_secundaria = data.raca
      }
      if (qual == 'terciaria') {
        this.animal.meta_raca_terciaria = data.raca
      }

      this.racas = JSON.parse(JSON.stringify(Racas))
    }
  }
  
  async limpaRacas(qual?: 'primaria' | 'secundaria' | 'terciaria'): Promise<void> {
    if (qual == 'primaria') {
      this.animal.raca = undefined
      this.animal.meta_raca_composicao = '1/1'
      this.animal.meta_raca_secundaria = undefined
      this.animal.meta_raca_secundaria_composicao = undefined
      this.animal.meta_raca_terciaria = undefined
      this.animal.meta_raca_terciaria_composicao = undefined
    }
    if (qual == 'secundaria') {
      this.animal.meta_raca_secundaria = undefined
      this.animal.meta_raca_secundaria_composicao = undefined
      this.animal.meta_raca_terciaria = undefined
      this.animal.meta_raca_terciaria_composicao = undefined
    }
    if (qual == 'terciaria') {
      this.animal.meta_raca_terciaria = undefined
      this.animal.meta_raca_terciaria_composicao = undefined
    }
  }

  async adicionarAnimal(): Promise<void> {
    console.log(this.animal)

    this.isLoading = true
    const [response, error] = await this.animaisCtrl.novoAnimal(this.animal)
    if (response) {
      this.isLoading = false
      this.modalCtrl.dismiss({ success: true, response })
      this.utilsCtrl.showToast('Animal cadastrado com sucesso', 'bottom')
      VastaRX.setState('atualizar-cartao-usuario', true)
    }

    if (error) {
      const mensagemErro = error?.message || 'Erro ao cadastrar animal. Tente Novamente!'
      this.isLoading = false
      this.utilsCtrl.showToast(mensagemErro, 'bottom')
      console.log(error)
    }
  }

  toggleMetaPuroOrigem(isMarcado: Boolean): void {
    this.animal.meta_puro_origem = isMarcado ? '1' : ''
  }

  async editarAnimal(): Promise<void> {
    this.isLoading = true

    const payload = this.formatarPayloadEditarAnimal()
    const [response, error] = await this.animaisCtrl.atualizarAnimal(payload)

    if (response) {
      this.isLoading = false
      this.modalCtrl.dismiss({ success: true })
      this.utilsCtrl.showToast('Animal atualizado com sucesso', 'bottom')
    }

    if (error) {
      this.isLoading = false
      this.utilsCtrl.showToast('Erro ao atualizar animal. Tente Novamente!', 'bottom')
      console.log(error)
    }
  }

  formatarPayloadEditarAnimal(): IAnimalPayload {
    let payload = {}

    Object.entries(this.animal).forEach(([key, value]) => {
      if (value !== this.animalCopy[key]) {
        payload[key] = value
      }
    })

    const pesagens = {}
    Object.entries(this.animal.pesagens).forEach(([key, value]) => {
      if (value !== this.animalCopy.pesagens[key] && value !== undefined && value !== null) {
        pesagens[key] = value
      }
    })

    payload['genealogia_matriz_interna'] = this.animal.genealogia_matriz_interna
    payload['genealogia_matriz_externa'] = this.animal.genealogia_matriz_externa
    payload['genealogia_reprodutor_externo'] = this.animal.genealogia_reprodutor_externo
    payload['genealogia_reprodutor_interno'] = this.animal.genealogia_reprodutor_interno

    const sizePesagens = Object.keys(pesagens).length

    if (payload['lote']) {
      delete payload['lote']
    }

    if (sizePesagens > 0) {
      payload = { ...payload, pesagens }
    }

    payload = { ...payload, hash: this.animal.hash }

    return payload
  }

  async submit(): Promise<void> {
    if (this.animalProp) {
      delete this.animalProp.tipo
      console.log(this.animal)
      await this.editarAnimal()
    } else {
      console.log(this.animal)
      await this.adicionarAnimal()
    }
  }

  trocarAba(aba: string): void {
    this.abaAtiva = aba
  }

  closeModal(): void {
    if(!this.isLoading) {
      this.modalCtrl.dismiss()
    }
  }

  async openModalReprodutores(filtro: { chave: string; valor: string }): Promise<void> {
    const filtroSexo: NovoFiltro = {
      sexo: filtro.valor
    }
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: {
        selecionarMultiplos: false,
        filtrosFixos: ['sexo'],
        modalFiltro: filtroSexo,
        modalFiltroReprodutorExterno: filtroSexo
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      if (filtro.valor === 'macho') {
        this.animal.genealogia_nome_pai = data.nome
        this.animal.genealogia_numero_pai = data.numeracao

        if (data.externo) {
          this.isReprodutorPreenchido = true

          this.animal.genealogia_reprodutor_externo = data.hash
          this.animal.genealogia_reprodutor_interno = null
        } else {
          this.animal.genealogia_reprodutor_interno = data.hash
          this.animal.genealogia_reprodutor_externo = null
        }
      } else {
        this.isMatrizPreenchida = true

        this.animal.genealogia_nome_mae = data.nome
        this.animal.genealogia_numero_mae = data.numeracao

        this.verificarSeNumeroJaEstaCadastrado()

        if (data.externo) {
          this.animal.genealogia_matriz_externa = data.hash
          this.animal.genealogia_matriz_interna = null
          return
        } else {
          this.animal.genealogia_matriz_interna = data.hash
          this.animal.genealogia_matriz_externa = null
        }
      }
    }
  }

  get situacaoAnimal(): string[] {
    return Object.keys(this.dadosSituacaoAnimal)
  }

  get origemAnimal(): string[] {
    return Object.keys(this.dadosOrigemAnimal)
  }

  get aptidaoAnimal(): string[] {
    return Object.keys(this.dadosAptidaoAnimal)
  }

  limparCampo(genealogia: 'mae' | 'pai'): void {
    if (genealogia === 'mae') {
      this.animal.genealogia_nome_mae = ''
      this.animal.genealogia_numero_mae = ''
      this.animal.genealogia_matriz_externa = '-'
      this.animal.genealogia_matriz_interna = '-'
    } else {
      this.animal.genealogia_nome_pai = ''
      this.animal.genealogia_numero_pai = ''
      this.animal.genealogia_reprodutor_externo = '-'
      this.animal.genealogia_reprodutor_interno = '-'
    }
  }

  selecionarItemPopover(item: BovinoSituacoes, nomePopover: string): void {
    if (nomePopover === 'situacao') {
      this.animal.situacao = item
      this.situacao.dismiss()
    }
    if (nomePopover === 'origem') {
      this.animal.tipo_entrada = item
      this.origem.dismiss()
    }
    if (nomePopover === 'aptidao') {
      this.animal.aptidao = item
      this.aptidao.dismiss()
    }
  }

  async getArvoreGenealogica(hash: string): Promise<IArvoreGenealogica> {
    const [response, error] = await this.animaisCtrl.getArvoreGenealogica(hash)
    if (response) {
      return response as IArvoreGenealogica
    }
    if (error) {
      console.log(error)
    }
  }
}
