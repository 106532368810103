import { Component } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import * as moment from 'moment'

let Meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]
let Dias = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado']

@Component({
  selector: 'app-calendario-mes-ano',
  templateUrl: './calendario-mes-ano.component.html',
  styleUrls: ['./calendario-mes-ano.component.scss']
})
export class CalendarioMesAnoComponent {
  titulo: string = ''
  subtitulo: string = ''
  anoExibido: number = 0
  ano: number = 0
  mes: number = 0
  mesAte: number = 0
  anoAte: number = 0
  anoAtual: number = 0
  mesAtual: number = 0
  mesMax: number = 0
  anoMax: number = 0
  mesMin: number = 0
  anoMin: number = 1980
  anosDisponiveis = []
  range?: boolean

  opcoesRapidasOpen = false

  mesesNomes = Meses
  diasNomes = Dias

  calendario = {
    dataAtual: '',
    mesAnoAtual: '',
    ano: 0,
    diaDaSemana: 0,
    dia: 0,
    mes: 0,
    mesQtd: 0,
    mesArray: [],
    selecao: {
      data: '',
      iso: '',
      formatado: '',
      dia: 0,
      mes: 0,
      ano: 0,
      diaDaSemana: 0,
      nomeMes: '',
      nomeDia: ''
    },
    selecaoRange: {
      de: {
        data: '',
        iso: '',
        formatado: '',
        diasEpoch: null,
        dia: 0,
        mes: 0,
        ano: 0,
        diaDaSemana: 0,
        nomeMes: '',
        nomeDia: ''
      },
      ate: {
        data: '',
        iso: '',
        formatado: '',
        diasEpoch: null,
        dia: 0,
        mes: 0,
        ano: 0,
        diaDaSemana: 0,
        nomeMes: '',
        nomeDia: ''
      }
    }
  }

  listaAnosPopover: number[] = []
  listaSafrasPopover: string[] = []
  isSubPopoverVisible = false
  constructor(private popoverCtrl: PopoverController) { }

  dismiss(): void {
    this.popoverCtrl.dismiss()
  }

  ngOnInit(): void {
    this.anoExibido = this.ano ? Number(this.ano) : Number(moment().format('YYYY'))

    this.mes = this.mes ? Number(this.mes) : null
    this.mesAte = this.mesAte ? Number(this.mesAte) : null
    this.ano = this.ano ? Number(this.ano) : null
    this.anoAte = this.anoAte ? Number(this.anoAte) : null
    this.mesAtual = Number(moment().format('MM'))
    this.anoAtual = Number(moment().format('YYYY'))
    this.mesMax = Number(this.mesMax || moment().format('MM'))
    this.anoMax = Number(this.anoMax || moment().format('YYYY'))
    // this.mesMin = Number(this.mesMax || moment().format('MM'))
    this.anoMin = Number(this.anoMin || 1980)

    console.log('this.mes', this.mes)
    console.log('this.ano', this.ano)
    console.log('this.mesMax', this.mesMax)
    console.log('this.anoMax', this.anoMax)

    let a = this.anoMin
    while (a <= this.anoMax) {
      this.anosDisponiveis.push(a)
      a++
      if (this.anosDisponiveis.length > 200) break
    }
    this.anosDisponiveis.reverse()
  }

  ngOnDestroy(): void {}

  selecionaMes(numero: number): void {
    if (this.range) { 
      if (this.mes && this.mesAte) {
        this.mes = null
        this.mesAte = null
        this.ano = null
        this.anoAte = null
      }

      if (this.mes) {
        this.anoAte = this.anoExibido
        this.mesAte = numero
      } else {
        this.ano = this.anoExibido
        this.mes = numero
      }
      // this.confirmar()
    } else {
      this.ano = this.anoExibido
      this.mes = numero
      this.anoAte = this.anoExibido
      this.mesAte = numero
      this.confirmar()
    }
  }

  async confirmar(): Promise<void> {
    if (this.range) {
      await this.popoverCtrl.dismiss({
        anoMesDe: `${this.ano}-${('0' + this.mes).slice(-2)}`,
        anoMesAte: `${this.anoAte}-${('0' + this.mesAte).slice(-2)}`
      })
    } else {
      await this.popoverCtrl.dismiss({
        mes: Number(this.mes),
        ano: Number(this.ano),
        anoMes: `${this.ano}-${('0' + this.mes).slice(-2)}`
      })
    }
  }

  anoMenos(): void {
    this.anoExibido = Number(this.anoExibido)
    if (this.anoExibido > this.anoMin) {
      this.anoExibido -= 1
    }
  }

  anoMais(): void {
    this.anoExibido = Number(this.anoExibido)
    this.anoExibido += 1
    if (this.anoExibido > this.anoMax) {
      this.anoExibido = this.anoMax
    }

    if (this.mes > this.mesMax) {
      this.mes = Number(this.mesMax)
    }
  }

  exibeOpcoesRapidas(): void {
    this.opcoesRapidasOpen = true
  }

  fechaOpcoesRapidas(event: Event): void {
    event.stopPropagation()
    this.opcoesRapidasOpen = false
    this.isSubPopoverVisible = false
  }

  selecaoRapida(event: Event, valor: string, valor1?: string | number): void {
    event.stopPropagation()

    if (valor == 'este-mes') {
      this.calendario.mes = Number(moment().format('MM'))
      this.calendario.ano = Number(moment().format('YYYY'))

      this.calendario.selecaoRange.de = this.processar(1, this.calendario.mes, this.calendario.ano)
      this.calendario.selecaoRange.ate = this.processar(Number(moment().format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'mes-passado') {
      this.calendario.mes = Number(moment().subtract(1, 'month').format('MM'))
      this.calendario.ano = Number(moment().subtract(1, 'month').format('YYYY'))

      this.calendario.selecaoRange.de = this.processar(1, this.calendario.mes, this.calendario.ano)
      this.calendario.selecaoRange.ate = this.processar(Number(moment().subtract(1, 'month').endOf('month').format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'ultimos-3-meses') {
      this.calendario.mes = Number(moment().format('MM'))
      this.calendario.ano = Number(moment().format('YYYY'))
      const tresMeses = moment().subtract(2, 'months')

      this.calendario.selecaoRange.de = this.processar(Number(tresMeses.format('DD')), Number(tresMeses.format('MM')), Number(tresMeses.format('YYYY')))
      this.calendario.selecaoRange.ate = this.processar(Number(moment().format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'ultimos-6-meses') {
      this.calendario.mes = Number(moment().format('MM'))
      this.calendario.ano = Number(moment().format('YYYY'))
      const seisMeses = moment().subtract(5, 'months')

      this.calendario.selecaoRange.de = this.processar(Number(seisMeses.format('DD')), Number(seisMeses.format('MM')), Number(seisMeses.format('YYYY')))
      this.calendario.selecaoRange.ate = this.processar(Number(moment().format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'ano') {
      this.calendario.mes = 12
      this.calendario.ano = Number(valor1)

      this.calendario.selecaoRange.de = this.processar(1, 1, this.calendario.ano)
      this.calendario.selecaoRange.ate = this.processar(31, this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'safra') {
      const [anoDe, anoAte] = String(valor1).split('/')
      this.calendario.mes = 5
      this.calendario.ano = Number(anoAte)

      this.calendario.selecaoRange.de = this.processar(1, 7, Number(anoDe))
      this.calendario.selecaoRange.ate = this.processar(30, 6, Number(anoAte))
    }

    this.opcoesRapidasOpen = false

    this.anoExibido = this.calendario.selecaoRange.ate.ano

    this.ano = this.calendario.selecaoRange.de.ano
    this.mes = this.calendario.selecaoRange.de.mes
    this.anoAte = this.calendario.selecaoRange.ate.ano
    this.mesAte = this.calendario.selecaoRange.ate.mes

    this.mesMax = 12
  }

  geraDataISO(dia: number, mes: number, ano: number): string {
    return ano + '-' + ('0' + (mes + 1)).slice(-2) + '-' + ('0' + dia).slice(-2)
  }

  geraDataHifenizada(dia = 0, mes = 0, ano = 0): string {
    if (dia && (mes || Number(mes) == 0) && ano)
      return ('0' + dia).slice(-2) + '-' + ('0' + (mes + 1)).slice(-2) + '-' + ano
    else
      return (
        ('0' + new Date().getDate()).slice(-2) +
        '-' +
        ('0' + (new Date().getMonth() + 1)).slice(-2) +
        '-' +
        new Date().getFullYear()
      )
  }

  geraDataFormatada(dia: number, mes: number, ano: number): string {
    return ('0' + dia).slice(-2) + '/' + ('0' + (mes + 1)).slice(-2) + '/' + ano
  }

  processar(
    dia: number,
    mes: number,
    ano: number,
    diaDaSemana = null
  ): {
    data: string
    iso: string
    formatado: string
    diasEpoch: number
    dia: number
    mes: number
    ano: number
    diaDaSemana: number
    nomeDia: string
    nomeMes: string
  } {
    if (diaDaSemana === null) {
      diaDaSemana = new Date(ano, mes - 1, dia).getDay()
    }

    let dataISO = this.geraDataISO(dia, mes, ano)

    return {
      data: this.geraDataHifenizada(dia, mes, ano),
      iso: this.geraDataISO(dia, mes, ano),
      formatado: this.geraDataFormatada(dia, mes, ano),
      diasEpoch: moment(dataISO).diff(moment('1970-01-01'), 'days'),
      dia: dia,
      mes: mes,
      ano: ano,
      diaDaSemana: diaDaSemana,
      nomeDia: this.diasNomes[diaDaSemana],
      nomeMes: this.mesesNomes[mes]
    }
  }

  showSubPopover(tipoLista: 'ano' | 'safra'): void {
    this.listaAnosPopover = []
    this.listaSafrasPopover = []

    if (tipoLista == 'ano') {
      this.gerarListaDeAnosPopover()
    }

    if (tipoLista == 'safra') {
      this.gerarListaDeSafrasPopover()
    }

    this.isSubPopoverVisible = true
  }

  gerarListaDeAnosPopover(): void {
    const anoAtual = moment().year()

    for (let i = anoAtual; i > anoAtual - 10; i--) {
      this.listaAnosPopover.push(i)
    }
  }

  gerarListaDeSafrasPopover(): void {
    const anoAtual = moment().year()

    for (let i = 0; i < 11; i++) {
      const inicio = moment(`${anoAtual - i}-07-01`)

      if (!moment().isBefore(inicio)) {
        this.listaSafrasPopover.push(`${anoAtual - i}/${anoAtual - i + 1}`)
      }
    }
  }
}
