<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{item.hash ? 'Editar Item' : 'Novo Item'}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-input
    label="Tipo"
    type="select"
    [(value)]="item.metadados.tipo"
    [obrigatorio]="true"
    [opcoes]="[
      { label: 'Sêmen', value: 'semen' },
      { label: 'Embrião FIV', value: 'embriao-fiv' },
      { label: 'Embrião TE', value: 'embriao-te' }
    ]"
  ></vasta-input>

  <vasta-input
    label="Touro"
    type="button"
    [obrigatorio]="true"
    [(value)]="touro"
    [icon]="touro ? 'male' : ''"
    placeholder="Selecionar"
    (buttonEvent)="openModalReprodutores({ sexo: 'macho' })"
  ></vasta-input>

  <vasta-input
    *ngIf="item.metadados.tipo == 'embriao-fiv' || item.metadados.tipo == 'embriao-te'"
    label="Doadora"
    type="button"
    [obrigatorio]="true"
    [(value)]="doadora"
    [icon]="doadora ? 'female' : ''"
    placeholder="Selecionar"
    (buttonEvent)="openModalReprodutores({ sexo: 'femea' })"
  ></vasta-input>

  <ion-row>
    <ion-col
      style="padding-left: 0"
      [attr.size]="(item.metadados.fornecedor_predefinido == 'Outro' || item.metadados.fornecedor) ? '6' : '12'"
    >
      <vasta-input
        label="Fornecedor"
        type="select"
        [obrigatorio]="true"
        placeholder="Selecionar"
        [(value)]="item.metadados.fornecedor_predefinido"
        [opcoes]="[
      { label: 'ABS Brasil', value: 'ABS Brasil' },
      { label: 'Alta Genetics', value: 'Alta Genetics' },
      { label: 'Central Bela Vista', value: 'Central Bela Vista' },
      { label: 'CPT Agropecuária', value: 'CPT Agropecuária' },
      { label: 'CRV Lagoa', value: 'CRV Lagoa' },
      { label: 'Genex', value: 'Genex' },
      { label: 'Mapra', value: 'Mapra' },
      { label: 'Reserva Genética', value: 'Reserva Genética' },
      { label: 'Seleon', value: 'Seleon' },
      { label: 'Select Sires', value: 'Select Sires' },
      { label: 'Semex', value: 'Semex' },
      { label: 'Produção Própria', value: 'Produção Própria' },
      { label: 'Outro', value: 'Outro' },
    ]"
      ></vasta-input>
    </ion-col>

    <ion-col
      size="6"
      style="padding-right: 0"
      *ngIf="item.metadados.fornecedor_predefinido == 'Outro' || item.metadados.fornecedor"
    >
      <vasta-input
        label="Outro Fornecedor"
        [obrigatorio]="true"
        type="text"
        [(value)]="item.metadados.fornecedor_customizado"
      ></vasta-input>
    </ion-col>
  </ion-row>
  <vasta-input
    label="Sexado"
    type="select"
    placeholder="Selecionar"
    [(value)]="item.metadados.sexado"
    [opcoes]="[
  { label: 'Não', value: 'nao' },
  { label: 'Macho', value: 'macho' },
  { label: 'Fêmea', value: 'femea' }
]"
  ></vasta-input>
  <ion-row>
    <ion-col size="6" style="padding-left: 0">
      <vasta-input
        label="Tipo de Palheta"
        type="select"
        placeholder="Selecionar"
        [(value)]="item.metadados.tipo_de_palheta"
        [opcoes]="[
      { label: 'Fina', value: 'fina' },
      { label: 'Média', value: 'media' },
      { label: 'Grossa', value: 'grossa' }
    ]"
      ></vasta-input>
    </ion-col>

    <ion-col size="6" style="padding-right: 0">
      <vasta-input label="Partida" type="text" [(value)]="item.metadados.codigo_no_rack"></vasta-input>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="6" style="padding-left: 0">
      <vasta-input label="Botijão" type="text" [(value)]="item.metadados.botijao"></vasta-input>
    </ion-col>

    <ion-col size="6" style="padding-right: 0">
      <vasta-input label="Caneca" type="text" [(value)]="item.metadados.caneca"></vasta-input>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
  <cta-buttons>
    <button
      class="primary permissao-escrita-estoque"
      (click)="salvar()"
      [disabled]="salvando || !isCampoObrigatoriosValidos"
    >
      <ion-icon slot="start" name="checkmark" *ngIf="!salvando"></ion-icon>
      <ion-spinner name="dots" *ngIf="salvando"></ion-spinner>
      <span>Salvar</span>
    </button>
  </cta-buttons>
</ion-footer>
