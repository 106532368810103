<ion-content>
  <div class="container-opcoes">
    <button
      class="item {{item.classe}}"
      [class.item-bloqueado-plano-basico]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('acoes', item.chave)"
      *ngFor="let item of options"
      (click)="acao(item, $event)"
      [disabled]="item?.disabled"
    >
      <ion-icon *ngIf="item.icone" [name]="item.icone" class="{{item.cor}}"></ion-icon>
      <p>{{ item.titulo }}</p>
      <span class="qtd" *ngIf="item.qtd">{{ item.qtd }}</span>
      <recurso-pro
        *ngIf="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('acoes', item.chave)"
        categoria="acoes"
        [item]="item.chave"
        [exibirDescricao]="false"
      ></recurso-pro>
    </button>
  </div>
</ion-content>
