<div class="container-lista">
  <button (click)="selecionarAcao('cadastrar')">
    <ion-icon name="add"></ion-icon>
    <div class="conteudo">
      <span>Cadastrar Cria</span>
    </div>
  </button>
  <button (click)="selecionarAcao('selecionar')">
    <ion-icon name="checkmark"></ion-icon>
    <div class="conteudo">
      <span>Selecionar Cria Existente</span>
    </div>
  </button>
  <button (click)="selecionarAcao('natimorto')">
    <ion-icon name="close"></ion-icon>
    <div class="conteudo">
      <span>Natimorto</span>
    </div>
  </button>
</div>
