<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{confinamento ? 'Selecionar Confinamento' : 'Selecionar Lote'}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <vasta-tabela
    *ngIf="!(!lotes?.length && statusCarregamentoLotes == 'sucesso')"
    [labels]="labelsLotes"
    [campos]="lotes"
    [config]="config"
    [(novoFiltro)]="novoFiltro"
    [(novaOrdenacao)]="novaOrdenacao"
    [status]="statusCarregamentoLotes"
    (selecionado)="selecionarLote($event)"
    [(hashsSelecionados)]="hashsSelecionados"
    (hashsSelecionadosChange)="getHashsSelecionados($event)"
    (novoFiltroChange)="getLotes()"
    (retentar)="getLotes()"
  ></vasta-tabela>

  <div class="placeholder-vazio" *ngIf="!lotes?.length && statusCarregamentoLotes == 'sucesso'">
    Nenhum lote encontrado
  </div>
</ion-content>

<ion-footer>
  <cta-buttons>
    <button class="primary" (click)="confirmarLotes()" [disabled]="!hashsSelecionados.length" *ngIf="multiplos">
      <ion-icon name="checkmark-outline"></ion-icon>
      <span>Confirmar</span>
    </button>
  </cta-buttons>
  <cta-buttons>
    <button (click)="cadastrarLote()" [class.primary]="!multiplos && !lotes?.length && statusCarregamentoLotes == 'sucesso'">
      <ion-icon name="add-circle"></ion-icon>
      <span>Cadastrar Lote</span>
    </button>
  </cta-buttons>
</ion-footer>
