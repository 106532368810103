import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { AreasService } from 'src/app/services/areas.service'
import { ColunasAreas } from 'src/app/utils/configuracoes-padrao'
import { IArea } from 'src/app/utils/interfaces/areas.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalCadastrarAreaComponent } from '../modal-cadastrar-area/modal-cadastrar-area.component'

@Component({
  selector: 'app-modal-lista-areas',
  templateUrl: './modal-lista-areas.page.html',
  styleUrls: ['./modal-lista-areas.page.scss']
})
export class ModalListaAreasPage implements OnInit {
  public areas: IArea[]
  public areasCopy: IArea[]
  public loadingAreas = false
  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }
  public labels = ColunasAreas
  public carregamentoAreas: StatusTabela = 'carregando'
  public config: ConfigTabela = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: true,
    orderby: 'nome',
    orderbyDirection: 'desc',
    impressaoTitulo: 'Áreas',
    exibeTotais: false,
    excluirColunas: ['mais'],
    selecao: {
      ativar: true
    }
  }
  public multiplos = false
  public hashNotIn: string[] = []
  public hashsSelecionados: string[] = []

  public novoFiltro: NovoFiltro = {}
  public novaOrdenacao: Ordenacao = { coluna: 'nome', direcao: 'desc' }

  constructor(private areasService: AreasService, private modalCtrl: ModalController) {}

  ngOnInit(): void {
    if (this.hashNotIn && this.hashNotIn.length) {
      this.novoFiltro.hash_not_in = this.hashNotIn
    }

    if (this.multiplos) {
      this.labels = [
        ...[
          {
            tipo: 'checkbox',
            fixa: true
          }
        ],
        ...ColunasAreas
      ]
    }
    this.getAreas()
  }

  async getAreas(): Promise<void> {
    this.loadingAreas = true
    this.carregamentoAreas = 'carregando'
    const [response, error] = await this.areasService.getAreas({
      filtros: this.novoFiltro,
      ordenacao: this.novaOrdenacao,
      paginate: this.paginate
    })

    if (response) {
      this.areas = response.dados
      this.areasCopy = response.dados
      this.loadingAreas = false
      this.carregamentoAreas = 'sucesso'
    }

    if (error) {
      console.error(error)
      this.carregamentoAreas = 'erro'
    }
  }

  selecionarArea(event: DadosLinhaTabela): void {
    if(this.hashsSelecionados.length) return
    const area: IArea = event.original
    this.modalCtrl.dismiss({ areas: [area] })
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  async modalCadastrarArea(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCadastrarAreaComponent,
      cssClass: 'custom-modal'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()
    if (data?.success) {
      this.getAreas()
    }
  }

  filtrarListaDeAreas(event: CustomEvent): void {
    this.areas = this.areasCopy
    const termo = event.detail.value
    if (termo) {
      const listaDeAreas = this.areas.map((area) => String(area.nome).toLowerCase())
      const listaFiltrada = listaDeAreas.filter((nome) =>
        String(nome).toLowerCase().includes(String(termo).toLowerCase())
      )
      this.areas = this.areas.filter((area) => listaFiltrada.includes(String(area.nome).toLowerCase()))
    }
  }

  confirmarAreas(): void {
    const areasFormatadas = this.areas.filter((item) => this.hashsSelecionados.includes(item.hash))

    this.modalCtrl.dismiss({ areas: areasFormatadas })
  }
}
