import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import moment from 'moment'
import { PlanosAssinaturaService } from 'src/app/services/plano-assinatura.service'
import { UsuariosService } from 'src/app/services/usuarios.service'
import { IUsuario } from 'src/app/utils/interfaces/Usuarios.interface'
import { Cobrancas } from 'src/app/utils/interfaces/plano-assinatura.interface'
import { ModalAssinaturaCobrancaPage } from '../modal-assinatura-cobranca/modal-assinatura-cobranca.page'

@Component({
  selector: 'app-modal-assinatura',
  templateUrl: './modal-assinatura.page.html',
  styleUrls: ['./modal-assinatura.page.scss'],
})
export class ModalAssinaturaPage implements OnInit {

  public dadosUsuario: IUsuario 
  public cobrancas: Cobrancas[]
  public percentualUtilizado = 0
  public tempoProximaCobranca = ''
  public planoFormatado = ''
  public planoQtdAnimais = ''
  public validatePlano = ''
  public segmento = 'Gerenciar'
  public carregando = true

  constructor(
    private usuariosCtrl: UsuariosService,
    private planoCrtl: PlanosAssinaturaService,
    private modalCtrl: ModalController
  ) { }

  async ngOnInit(): Promise<void> {
    this.carregando = true
    await this.getUsuario()
    this.carregando = false
    this.calcularPercentualUtilizado()
    this.calcularTempoProximaCobranca()
    this.formataPlano()
    this.calcularQtdAnimais()
    this.formatarDataValidade()
    this.carregando = false
    if (this.planoFormatado == 'Premium'){
      await this.getCobrancas()
    }
  }

  modalClose(): void {
    this.modalCtrl.dismiss()
  }

  async getCobrancas(): Promise<void> {
    const [response, erro] = await this.planoCrtl.getCobrancasUsuario()
    if (response) {
      const { cobrancas } = response
      this.cobrancas = cobrancas
    }
  }

  async getUsuario(): Promise<void> {
    const [response, erro] = await this.usuariosCtrl.getUsuarioSingleEAtualizaUsuarioCache()
    if (response) {
      this.dadosUsuario = response
    }
  }

  calcularPercentualUtilizado(): void {
    const porcentagem = Math.round((Number(this.dadosUsuario.quantidade_animais) / Number(this.dadosUsuario.plano_quantidade)) * 100)
    this.percentualUtilizado = porcentagem > 100 ? 100 : porcentagem
  }

  calcularTempoProximaCobranca(): void {
    const diferenca = moment(this.dadosUsuario.plano_validade).diff(moment(), 'days')
  
    if (diferenca < 365) {
      this.tempoProximaCobranca = `em ${diferenca} dia${diferenca === 1 ? '' : 's'}`
    } else if (diferenca === 365) {
      this.tempoProximaCobranca = 'em 1 ano'
    } else {
      const duracao = moment.duration(moment(this.dadosUsuario.plano_validade).diff(moment()))
      const anos = duracao.years()
      const dias = duracao.days()
  
      let mensagem = 'em '
      if (anos === 1) {
        mensagem += '1 ano'
      } else if (anos > 1) {
        mensagem += `${anos} anos`
      }
  
      if (dias === 1) {
        if (anos === 0) {
          mensagem += '1 dia'
        } else {
          mensagem += ' e 1 dia'
        }
      } else if (dias > 1) {
        if (anos !== 0) {
          mensagem += ' e '
        }
        mensagem += `${dias} dias`
      }
      
      this.tempoProximaCobranca = mensagem
    }
  }

  formataPlano(): void {
    if(this.dadosUsuario.plano_ativo === 'basico') {
      this.planoFormatado = 'Controle'
    }else if(this.dadosUsuario.plano_ativo === 'trial') {
      this.planoFormatado = 'Teste Grátis'
    } else {
      this.planoFormatado = 'Premium'
    }
  }

  calcularQtdAnimais(): void {
    this.planoQtdAnimais = `Até ${this.dadosUsuario.plano_quantidade}`
  }

  formatarDataValidade(): void {
    this.validatePlano = moment(this.dadosUsuario.plano_validade).format('DD/MM/YYYY')
  }

  async openModalCobranca(cobranca: Cobrancas): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAssinaturaCobrancaPage,
      cssClass: 'custom-modal',
      componentProps: {
        cobranca
      }
    })

    await modal.present()
  }

}
