import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { FinancasService } from 'src/app/services/financas.service'
import { ColunasFinancasCompras, ColunasFinancasVendas } from 'src/app/utils/configuracoes-padrao'
import { IAnimal } from 'src/app/utils/interfaces/animais.interface'
import { LancamentoCompraEVenda } from 'src/app/utils/interfaces/financas.interface'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalCompraVendaComponent } from '../modal-compra-venda/modal-compra-venda.component'

@Component({
  selector: 'app-modal-selecionar-venda',
  templateUrl: './modal-selecionar-venda.page.html',
  styleUrls: ['./modal-selecionar-venda.page.scss']
})
export class ModalSelecionarVendaPage implements OnInit {
  lancamentos: LancamentoCompraEVenda[] = []
  public animaisProp: IAnimal[] = []
  public tipo = 'venda'
  public multiplos = false
  public confinamento = false
  public lotesCopy: ILote[]
  public isLoading = false

  public labelsVendas = ColunasFinancasVendas
  public labelsCompras = ColunasFinancasCompras
  public config: ConfigTabela = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: true,
    selecao: {
      ativar: false
    },
    excluirColunas: ['mais']
  }
  public statusCarregamentoLancamentos: StatusTabela = 'carregando'

  constructor(private modalCtrl: ModalController, private lotesService: FinancasService) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true
    await this.getLancamentos()
    this.isLoading = false
  }

  async getLancamentos(): Promise<void> {
    this.statusCarregamentoLancamentos = 'carregando'
    const [response, error] = await this.lotesService.getVendas()

    if (response) {
      this.lancamentos = response.lancamentos
      this.statusCarregamentoLancamentos = 'sucesso'
    }

    if (error) {
      this.statusCarregamentoLancamentos = 'erro'
      console.log(error)
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  selecionarLancamento(linha: DadosLinhaTabela): void {
    this.modalCtrl.dismiss({ lancamento: linha.original })
  }

  async cadastrar(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCompraVendaComponent,
      componentProps: {
        animaisProp: this.animaisProp,
        tipoTransacao: 'venda'
      },
      cssClass: 'modal-compra-venda'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.success) {
    }
  }
}
