<div class="container-numpad">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" expand="block" (click)="modalClose()">
        <ion-label>Cancelar</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="primary" expand="block" (click)="confirmarPeso()">
        <ion-label>Confirmar</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div class="container-peso">
    <p class="nome">{{ nome }}</p>
    <div class="input">
      <input type="text" [(ngModel)]="num" />
      <span>kg</span>
    </div>
  </div>
</div>
