<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ tipoTransacao === 'compra' ? 'Compra de Animais' : 'Venda de Animais' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <header-tabs [tabs]="tipoTransacao === 'compra' ? ['Informações', 'Animais'] : ['Informações', 'Animais', 'Custos e Lucratividade']" [(tabActive)]="abaAtiva" (tabActiveChange)="changeTab()"></header-tabs>
</ion-header>

<ion-content>

  <div [ngClass]="{ hidden: abaAtiva !== 'Custos e Lucratividade' }">
    <kpis-cards [cardsInfo]="optionsKpisCardCustoELucratividade" [valores]="totaisCusto" [wrap]="true"></kpis-cards>
    <vasta-tabela
    [labels]="labels"
    [config]="config"
    [campos]="listaFiltrada"
    [status]="carregamentoDadosDesmames"
    [novoFiltro]="novoFiltro"
    (novoFiltroChange)="processaFiltroLocal()"
    [novaOrdenacao]="novaOrdenacao"
    (novaOrdenacaoChange)="processaFiltroLocal()"
    (retentar)="getDadosCustosELucratividade()"
  ></vasta-tabela>

  </div>
  <div [ngClass]="{ hidden: abaAtiva !== 'Animais' }" style="padding-top: 10px">
    <div class="explicacao" *ngIf="dadosTransacaoProp?.id_propriedade && propriedadesCtrl?.propriedadeSelecionada?.id !== dadosTransacaoProp?.id_propriedade">
      <ion-icon name="warning"></ion-icon>
      <p>Para modificar animais da transação, mude para a propriedade <b>{{dadosTransacaoProp?.extra_propriedade}}</b></p>
    </div>

    <cta-buttons>
      <button class="primary" (click)="showModalListaAnimais()" [disabled]="dadosTransacaoProp?.id_propriedade && propriedadesCtrl?.propriedadeSelecionada?.id !== dadosTransacaoProp?.id_propriedade">
        <ion-icon name="rebanho"></ion-icon>
        <span>Selecionar animais</span>
      </button>
    </cta-buttons>

    <div class="container-animais">
      <vasta-tabela
        *ngIf="dadosTabelaAnimais?.length"
        labelsId="colunas_animais"
        [campos]="dadosTabelaAnimais"
        [config]="configTabela"
        [status]="statusTabela"
        [optionsButtons]="optionsButtonsAnimais"
      ></vasta-tabela>

      <div class="container-loading" *ngIf="!dadosTabelaAnimais?.length && dadosTransacaoProp">
        <ion-spinner name="dots"></ion-spinner>
      </div>
    </div>
  </div>

  <div [ngClass]="{ hidden: abaAtiva !== 'Informações' }">
    <div class="nenhum-animal" *ngIf="carregando">
      <ion-spinner style="width: 16px; height: 16px"></ion-spinner> Buscando informações
    </div>

    <div class="nenhum-animal" *ngIf="!carregando && !dadosTabelaAnimais?.length">
      Nenhum animal selecionado
      <cta-buttons>
        <button class="primary" (click)="(abaAtiva !== 'Animais'); showModalListaAnimais()">
          <ion-icon name="rebanho"></ion-icon>
          <span>Selecionar animais</span>
        </button>
      </cta-buttons>
    </div>

    <kpis-cards [cardsInfo]="optionsKpisCard" [valores]="totais" [wrap]="true">
    </kpis-cards>

    <vasta-input type="text" label="Título do lançamento financeiro" [(value)]="dadosTransacao.titulo"></vasta-input>

    <ion-row>
      <ion-col style="max-width: 140px; padding-left: 0" *ngIf="tipoTransacao === 'venda'">
        <vasta-input
          type="select"
          placeholder="Selecionar"
          label="Tipo de Saída"
          [obrigatorio]="true"
          [(value)]="dadosTransacao.meta_tipoSaida"
          [opcoes]="[
            { label: 'Boi Vivo', value: 'boi-vivo' },
            { label: 'Abate', value: 'abate' }
          ]"
          (valueChange)="calcularValoresDaTransacao()"
        ></vasta-input>
      </ion-col>
      <ion-col style="padding-left: 0; padding-right: 0">
        <vasta-input
          type="button"
          label="Conta"
          placeholder="Selecionar"
          [obrigatorio]="true"
          [value]="contasPorHash[dadosTransacao?.hash_conta]?.titulo || ''"
          (clear)="limparCampoConta()"
          (buttonEvent)="abrirListaDeContas()"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col style="max-width: 160px; padding-left: 0">
        <vasta-input type="date" label="Data" [obrigatorio]="true" [(value)]="dadosTransacao.data"></vasta-input>
      </ion-col>
      <ion-col style="padding-right: 0">
        <vasta-input
          type="button"
          [label]="tipoTransacao == 'compra' ? 'Produtor de origem' : 'Produtor de Destino'"
          placeholder="Selecionar"
          [value]="pessoaSelecionada?.nome"
          (buttonEvent)="abrirModalListaPessoas()"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="tipoTransacao === 'venda'">
      <ion-col>
        <vasta-input
          type="checkbox"
          label="Arquivar animais"
          [valueCheckbox]="dadosTransacao.meta_arquivar_animais == 1"
          (valueCheckboxChange)="retirarAnimaisDoRebanho($event)"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <div class="explicacao" *ngIf="tipoTransacao === 'venda'">
      <ion-icon name="help-circle"></ion-icon>
      <p>Animais arquivados não são considerados no rebanho da fazenda e ficam indisponíveis em novos manejos.</p>
      <p>Para acessar os animais arquivados, na página <b>Rebanho</b> modifique o filtro de <i>situação</i> para <b>vendido</b>.</p>
    </div>

    <h2>Valores da {{ tipoTransacao === 'compra' ? 'Compra' : 'Venda' }}</h2>

    <ion-row class="ion-align-items-end">
      <ion-col size="2" style="padding-left: 0">
        <vasta-input
          type="select"
          [label]="tipoTransacao == 'compra' ? 'Compra por' : 'Venda por'"
          placeholder="Selecionar"
          [(value)]="dadosTransacao.meta_unidadeValorVenda"
          [opcoes]="[
            { label: '@', value: 'arroba' },
            { label: 'KG', value: 'kg' },
            { label: 'Unidade', value: 'unidade' }
          ]"
          (valueChange)="calcularValoresDaTransacao()"
        ></vasta-input>
      </ion-col>
      <ion-col size="10" style="padding-right: 0">
        <vasta-input
          type="number"
          [label]="
            dadosTransacao.meta_unidadeValorVenda === 'arroba'
              ? 'Valor da @'
              : dadosTransacao.meta_unidadeValorVenda === 'kg'
              ? 'Valor do Kg'
              : 'Valor da Unidade'
          "
          [(value)]="valorTipoUnidade"
          (valueChange)="calcularValoresDaTransacao()"
          mask="separator.2"
          maskPrefix="R$ "
          [obrigatorio]="true"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="dadosTransacao.meta_unidadeValorVenda !== 'unidade'">
      <ion-col style="padding-left: 0">
        <vasta-input
          type="number"
          label="Peso vivo em kg"
          [obrigatorio]="true"
          [(value)]="dadosTransacao.meta_pesoVivo"
          (valueChange)="calcularValoresDaTransacao()"
          mask="separator.2"
          maskSuffix=" kg"
        >
        </vasta-input>
      </ion-col>
      <ion-col style="max-width: 120px; padding-right: 0">
        <vasta-input type="number" label="@" [(value)]="arrobaPorPesoVivo" [disabled]="true"></vasta-input>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="dadosTransacao.meta_unidadeValorVenda !== 'unidade' && dadosTransacao.meta_tipoSaida === 'abate'">
      <ion-col style="padding-left: 0">
        <vasta-input
          type="number"
          label="Peso morto em kg"
          [obrigatorio]="true"
          [(value)]="dadosTransacao.meta_pesoMorto"
          (valueChange)="calcularValoresDaTransacao()"
          mask="separator.2"
          maskSuffix=" kg"
        >
        </vasta-input>
      </ion-col>
      <ion-col style="max-width: 120px; padding-right: 0">
        <vasta-input type="number" label="@" [(value)]="arrobaPorPesoMorto" [disabled]="true"></vasta-input>
      </ion-col>
    </ion-row>

    <h2 *ngIf="dadosTransacao.meta_unidadeValorVenda !== 'unidade' && dadosTransacao.meta_tipoSaida === 'abate'">
      Rendimento da carcaça: <strong>{{ dadosTransacao.meta_rendimentoCarcaca }} %</strong>
    </h2>

    <ion-row class="ion-align-items-center">
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          type="text"
          [label]="tipoTransacao == 'compra' ? 'Valor da Compra' : 'Valor da Venda'"
          [(value)]="dadosTransacao.meta_valorTransacao"
          mask="separator.2"
          maskPrefix="R$ "
          (valueChange)="calcularValor()"
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-right: 0">
        <vasta-input
          type="text"
          label="Frete"
          [(value)]="dadosTransacao.meta_frete"
          (valueChange)="calcularValoresDaTransacao()"
          mask="separator.2"
          maskPrefix="R$ "
        ></vasta-input>
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-center" *ngIf="tipoTransacao === 'venda'">
      <ion-col size="6" style="padding-left: 0"> </ion-col>
      <ion-col size="6" style="padding-right: 0">
        <vasta-input
          type="checkbox"
          label="Pago pelo comprador"
          [(valueCheckbox)]="dadosTransacao.meta_pagoPeloComprador"
          (valueCheckboxChange)="calcularValoresDaTransacao()"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-center">
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          *ngIf="tipoTransacao == 'compra'"
          type="text"
          label="Custos Extras"
          [(value)]="dadosTransacao.meta_acrescimos"
          (valueChange)="calcularValoresDaTransacao()"
          mask="separator.2"
          maskPrefix="R$ "
        ></vasta-input>
        <vasta-input
          *ngIf="tipoTransacao == 'venda'"
          type="text"
          label="Abatimentos"
          [(value)]="dadosTransacao.meta_abatimentos"
          (valueChange)="calcularValoresDaTransacao()"
          mask="separator.2"
          maskPrefix="R$ "
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-right: 0">
        <vasta-input
          type="text"
          label="Comissão"
          [(value)]="dadosTransacao.meta_comissoes"
          (valueChange)="calcularValoresDaTransacao()"
          mask="separator.2"
          maskPrefix="R$ "
        ></vasta-input>
      </ion-col>
    </ion-row>

    <vasta-input
      type="text"
      label="Valor total"
      [(value)]="dadosTransacao.valor"
      mask="separator.2"
      maskPrefix="R$ "
    ></vasta-input>

    <ion-col size="6" style="padding-left: 0;">
      <vasta-input
        [label]="tipoTransacao == 'compra' ? 'Marcar transação como pago' : 'Marcar transação como recebida'"
        type="checkbox"
        [valueCheckbox]="dadosTransacao.realizado == 1"
        (valueCheckboxChange)="setRealizado($event)"
      ></vasta-input>
    </ion-col>

    <vasta-input type="textarea" label="Observações" [(value)]="dadosTransacao.descricao"></vasta-input>

    <vasta-input type="text" label="Referência ou NF" [(value)]="dadosTransacao.meta_nf"></vasta-input>
    <vasta-input type="checkbox" label="Possui GTA?" [(valueCheckbox)]="dadosTransacao.meta_possuiGTA"></vasta-input>

    <ng-container *ngIf="dadosTransacao.meta_possuiGTA">
      <ion-row>
        <ion-col size="6" style="padding-left: 0">
          <vasta-input type="text" label="Número" [(value)]="dadosTransacao.meta_GTAnumero"> </vasta-input>
        </ion-col>
        <ion-col size="6" style="padding-right: 0">
          <vasta-input type="text" label="Série" [(value)]="dadosTransacao.meta_GTAserie"></vasta-input>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6" style="padding-left: 0">
          <vasta-input type="text" label="UF" [(value)]="dadosTransacao.meta_GTAuf"> </vasta-input>
        </ion-col>
        <ion-col size="6" style="padding-right: 0">
          <vasta-input
            type="date"
            label="Data de Emissão"
            [(value)]="dadosTransacao.meta_GTAdataDeEmissao"
          ></vasta-input>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6" style="padding-left: 0">
          <vasta-input type="date" label="Data de Validade" [(value)]="dadosTransacao.meta_GTAdataDeValidade">
          </vasta-input>
        </ion-col>
        <ion-col size="6" style="padding-right: 0">
          <vasta-input type="date" label="Data de Saída" [(value)]="dadosTransacao.meta_GTAdataDeSaida"></vasta-input>
        </ion-col>
      </ion-row>
    </ng-container>

    <p>&nbsp;</p>

    <button class="add-nf" *ngIf="listaPdf.length < 10">
      <enviar-pdf (pdfSrc)="getPdf($event)"></enviar-pdf>
      <span>Anexar PDF</span>
      <ion-icon name="cloud-upload"></ion-icon>
    </button>

    <h2 *ngIf="listaPdf.length > 2">PDFs Anexados</h2>

    <ion-list  *ngIf="listaPdf.length">
      <ion-item *ngFor="let pdf of listaPdf" style="--border-color: transparent;">
        <ion-label style="font-size: 14px;">{{pdf.name}}</ion-label>
        <ion-button fill="clear" (click)="viewPdf(pdf.url)">
          <ion-icon name="eye"></ion-icon>
        </ion-button>
        <ion-button fill="clear" (click)="removerPdf(pdf.chaveFinanceiro)">
          <ion-icon name="trash" color="danger"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>

    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <cta-buttons class="ion-float-right">
      <span class="alerta-animais-sem-peso" *ngIf="isAlgumAnimalSelecionadoSemPeso"
        >Animais sem pesagem interferem <br />
        no cálculo das @ Produzidas</span
      >

      <button
        class="primary permissao-escrita-financeiro"
        (click)="confirmar()"
        [disabled]="verificarCamposObrigatorios() || enviando"
      >
        <ion-icon name="checkmark" *ngIf="!enviando"></ion-icon>
        <ion-spinner name="dots" color="light" *ngIf="enviando"></ion-spinner>
        <span>Confirmar</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
