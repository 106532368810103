import { Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { FinancasService } from 'src/app/services/financas.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { Subcategoria } from 'src/app/utils/interfaces/financas.interface'

@Component({
  selector: 'app-modal-subcategoria',
  templateUrl: './modal-subcategoria.component.html',
  styleUrls: ['./modal-subcategoria.component.scss']
})
export class ModalSubcategoriaComponent implements OnInit {
  public subcategoria: Partial<Subcategoria> = {
    nome: '',
    descricao: ''
  }
  public subcategoriaProp: Subcategoria
  public loading = false
  constructor(
    private modalCtrl: ModalController,
    private financasCtrl: FinancasService,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController
  ) {}

  ngOnInit(): void {
    if (this.subcategoriaProp) {
      this.subcategoria = { ...this.subcategoriaProp }
    }
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  async criarNovaSubcategoria(): Promise<void> {
    this.loading = true
    const payload = {
      subcategoria: { ...this.subcategoria }
    }
    const [response, error] = await this.financasCtrl.criarSubcategoria(payload)
    this.loading = false

    if (response) {
      await this.modalCtrl.dismiss({ success: true })
      this.utilsCtrl.showToast('Subcategoria adicionada', 'bottom')
    }

    if (error) {
      this.utilsCtrl.showToast('Erro ao adicionar subcategoria', 'bottom')
    }
  }

  async alertExcluirSubcategoria(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Excluir Subcategoria',
      message: `Deseja realmente excluir a subcategoria ${this.subcategoria.nome}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Excluir',
          handler: async (): Promise<void> => {
            await this.excluirSubcategoria()
          }
        }
      ]
    })

    await alert.present()
  }

  async excluirSubcategoria(): Promise<void> {
    const [response, error] = await this.financasCtrl.excluirSubcategoria(this.subcategoria.hash)

    if (response) {
      await this.modalCtrl.dismiss({ success: true })
      this.utilsCtrl.showToast('Subcategoria excluída', 'bottom')
    }

    if (error) {
      this.utilsCtrl.showToast('Erro ao excluir subcategoria', 'bottom')
    }
  }
}