/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { Injectable } from '@angular/core'
import { tabelaFormat } from '../components/vasta-tabela/format'
import { ColunasTabela } from '../utils/interfaces/tabela-colunas'
import * as XLSX from 'xlsx'

@Injectable({
  providedIn: 'root'
})
export class ExportacaoService {
  private format = tabelaFormat
  constructor() {}

  downloadFile(
    title: string,
    rowValues: any[],
    headerValues: ColunasTabela[],
    hashsSelecionados: string[],
    extensaoArquivo: 'csv' | 'xlsx' = 'xlsx'
  ): void {
    const excludeColumns = ['checkbox', 'mais']

    const header = headerValues.filter((item) => !excludeColumns.includes(item.chave)).map((item) => item.titulo)

    let valuesKeys = headerValues.filter((item) => !excludeColumns.includes(item.chave)).map((item) => item.chave)

    let defaultValues = {}
    valuesKeys.forEach((keys) => {
      defaultValues[keys] = ''
    })

    const filteredRowValues = rowValues.filter((item) => hashsSelecionados.includes(item.hash))

    let values = []
    if (filteredRowValues.length) {
      values = this.formatarColunasTabela(filteredRowValues, valuesKeys, headerValues, excludeColumns, extensaoArquivo)
    } else {
      values = this.formatarColunasTabela(rowValues, valuesKeys, headerValues, excludeColumns, extensaoArquivo)
    }

    let csv = header.join(';') + '\n'

    if (extensaoArquivo === 'csv') {
      values.forEach((row) => {
        const formatedRow = row.map((item) => {
          if (String(item).includes(',') && !String(item).includes('"')) {
            return '"' + item + '"'
          } else {
            return item
          }
        })
        csv += formatedRow.join(';')
        csv += '\n'
      })

      const hiddenElement = document.createElement('a')

      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      hiddenElement.target = '_blank'

      hiddenElement.download = title + '.csv'
      hiddenElement.click()
    }

    if (extensaoArquivo === 'xlsx') {
      const array = [header, ...values]
      this.arrayToXlsx(array, title)
    }
  }

  arrayToXlsx(array: any[][], title: string): void {
    const arrayFormated = array.map((row) => {
      return row.map((item) => {
        if (isNaN(item) || item === '') {
          return item
        } else {
          return Number(item)
        }
      })
    })

    const wb = XLSX.utils.book_new()

    if (title.length > 31) {
      title = title.slice(0, 31)
    }

    const ws = XLSX.utils.aoa_to_sheet(arrayFormated)

    XLSX.utils.book_append_sheet(wb, ws, title)

    XLSX.writeFile(wb, title + '.xlsx')

    const rawXlsx = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' })

    return rawXlsx
  }

  formatarColunasTabela(
    rows: any[],
    columnsKeys: string[],
    headerValues: ColunasTabela[],
    excludeColumns: string[],
    extensaoArquivo: 'csv' | 'xlsx'
  ): unknown[] {
    return rows.map((obj) => {
      return columnsKeys.map((key) => {
        const coluna = headerValues
          .filter((item) => !excludeColumns.includes(item.chave))
          .find((item) => item.chave === key)
        const colunaFormatada = coluna.labelFormat && this.format[coluna.labelFormat] ? this.format[coluna.labelFormat](obj[key]) : obj[key] || ''
        return isNaN(colunaFormatada)
          ? colunaFormatada
          : Number(colunaFormatada) !== 0 && extensaoArquivo === 'csv'
          ? `"${String(Number(colunaFormatada)).replace('.', ',')}"`
          : colunaFormatada || ''
      })
    })
  }
}
