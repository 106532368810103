import { Component, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-popover-selecionar-venda',
  templateUrl: './popover-selecionar-venda.component.html',
  styleUrls: ['./popover-selecionar-venda.component.scss']
})
export class PopoverSelecionarVendaComponent implements OnInit {
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  async selecionarAcao(acao: string): Promise<void> {
    await this.popoverCtrl.dismiss(acao)
  }
}
