<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Selecionar Estoque Nutricional
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-tabela
    [labels]="labelsEstoqueNutricional"
    [campos]="estoqueNutricional"
    [config]="configEstoqueNutricional"
    [status]="carregamentoEstoqueNutricional"
    [(hashsSelecionados)]="hashsSelecionados"
    (selecionado)="selecionarItemEstoqueNutricional($event)"
  ></vasta-tabela>
</ion-content>
<ion-footer>
  <cta-buttons>
    <button class="primary" (click)="confirmarItens()" [disabled]="!hashsSelecionados.length" *ngIf="multiplos">
      <ion-icon name="checkmark-outline"></ion-icon>
      <span>Confirmar</span>
    </button>
  </cta-buttons>
  <cta-buttons>
    <button
      (click)="cadastrarItemNutricional()"
      [class.primary]="!multiplos && !estoqueNutricional?.length && carregamentoEstoqueNutricional == 'sucesso'"
    >
      <ion-icon name="add-circle"></ion-icon>
      <span>Cadastrar Item</span>
    </button>
  </cta-buttons>
</ion-footer>
