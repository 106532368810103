import { Component, OnInit, Input } from '@angular/core'
import { PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-popover-opcoes-financeiro-pendente-pesagem',
  templateUrl: './popover-opcoes-financeiro-pendente-pesagem.component.html',
  styleUrls: ['./popover-opcoes-financeiro-pendente-pesagem.component.scss']
})
export class PopoverOpcoesFinanceiroPendentePesagemComponent implements OnInit {
  @Input('tipoFinanceiro') tipoFinanceiro: string = ''

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  async cancelarTransacao(): Promise<void> {
    this.popoverCtrl.dismiss({ cancelar: true })
  }

  async prosseguirTransacao(): Promise<void> {
    this.popoverCtrl.dismiss({ prosseguir: this.tipoFinanceiro })
  }
}
