<div class="checklist-card" *ngIf="mostrar">
  <div>
    <h3>Metas Cumpridas ({{ ((100 / lista.length) * qtd_concluidos).toFixed(0) }}%)</h3>
    <p class="subtitle">Acompanhe o seu desempenho na Fertili! Todas as metas alcançadas aparecerão aqui.</p>

    <div class="item-wrapper">
      <ng-template ngFor let-item [ngForOf]="lista">
        <div class="item-single" [ngClass]="{ concluido: item.concluido }">
          <div class="custom-checkbox" [ngClass]="{ done: item.concluido }">
            <ion-icon name="checkmark" *ngIf="item.concluido"></ion-icon>
          </div>
          <div class="item-label">
            <p>
              {{ item.titulo }}
            </p>
          </div>
        </div>
      </ng-template>
    </div>

    <ion-button size="small" *ngIf="lista.length === qtd_concluidos" (click)="esconderCard()" class="botao-concluir">
      <ion-icon name="checkmark"></ion-icon> Concluir
    </ion-button>
    <ion-button *ngIf="lista.length !== qtd_concluidos" size="small" (click)="esconderCard()" class="botao-cancelar">
      <ion-icon name="close"></ion-icon> Esconder
    </ion-button>
  </div>
</div>
