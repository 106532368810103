<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Selecionar Raça
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModalListaRacas()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar mode="ios" placeholder="Buscar" [(ngModel)]="termo" (ionInput)="filtrarListaDeRacas($event)"> </ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="atalhos" *ngIf="!termo">
    <ng-template ngFor let-raca [ngForOf]="listaDeRacasAtalho">
      <ion-item button (click)="selecionarRaca(raca)" *ngIf="raca">
        <span>{{ racas[raca] }}</span>
      </ion-item>
    </ng-template>
  </div>

  <ng-template ngFor let-raca [ngForOf]="listaDeRacas">
    <ion-item button (click)="selecionarRaca(raca)" *ngIf="raca">
      <span>{{ racas[raca] }}</span>
    </ion-item>
  </ng-template>
</ion-content>
