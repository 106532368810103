<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Inseminação Artificial</div>

    <vasta-input
      [label]="dados.inseminacao_embriao ? 'Embrião' : 'Sêmen'"
      class="inseminacao"
      type="manejo-button"
      [value]="
        estoquesAssociados[dados.inseminacao_semen || dados.inseminacao_embriao]?.hash
          ? ((estoquesAssociados[dados.inseminacao_semen || dados.inseminacao_embriao]?.touro_nome || 'Não identificado') + (estoquesAssociados[dados.inseminacao_semen || dados.inseminacao_embriao]?.doadora_nome ? ' + ' + estoquesAssociados[dados.inseminacao_semen || dados.inseminacao_embriao]?.doadora_nome : ''))
          : ''
      "
      (buttonEvent)="showModalEstoqueBiologico()"
    ></vasta-input>

    <div class="manejo-opcoes" *ngIf="opcoesRapidasEstoqueBiologico.length">
      <span class="label">Opções <br />Rápidas</span>
      <div class="container-opcoes">
        <ng-template ngFor let-hash [ngForOf]="opcoesRapidasEstoqueBiologico">
          <button
            *ngIf="estoquesAssociados[hash]"
            (click)="selecionarOpcoesRapidas(hash, estoquesAssociados[hash]?.tipoDoEstoque)"
            [class.active]="hash == dados.inseminacao_semen || hash == dados.inseminacao_embriao"
          >
            {{ estoquesAssociados[hash]?.touro_nome || 'Sem identificação' }}
            <br>{{estoquesAssociados[hash]?.doadora_nome}}
          </button>
        </ng-template>
      </div>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.inseminacao_semen && !dados.inseminacao_embriao">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>

    <div class="manejo-avancados">
      <vasta-input
        *ngIf="manejosReprodutivosAvancados"
        label="Bastão"
        type="manejo-button"
        [value]="opcoesBastao[dados.inseminacao_bastao]?.texto"
        (buttonEvent)="showPopoverOpcoesBastao($event)"
        (valueChange)="dados.edited = true; emit()"
      ></vasta-input>

      <vasta-input
        *ngIf="manejosReprodutivosAvancados"
        label="Muco"
        type="manejo-button"
        [value]="opcoesMuco[dados.inseminacao_muco]?.texto"
        (buttonEvent)="showPopoverOpcoesMuco($event)"
        (valueChange)="dados.edited = true; emit()"
      ></vasta-input>

      <vasta-input
      [label]="'Inseminador'"
      type="manejo-button"
      [value]="pessoasAssociadas[dados.inseminacao_inseminador]?.nome"
      (buttonEvent)="abrirModalListaPessoas()"
    ></vasta-input>

    <div class="manejo-opcoes" *ngIf="opcoesRapidasPessoas.length">
      <span class="label">Opções <br />Rápidas</span>
      <div class="container-opcoes">
        <ng-template ngFor let-hashPessoa [ngForOf]="opcoesRapidasPessoas">
          <button
            *ngIf="pessoasAssociadas[hashPessoa]"
            (click)="selecionarOpcoesRapidasPessoas(hashPessoa)"
            [class.active]="hashPessoa == dados.inseminacao_inseminador"
          >
            {{ pessoasAssociadas[hashPessoa]?.nome || 'Sem identificação' }}
          </button>
        </ng-template>
      </div>
    </div>
    </div>
  </section>
</div>
