<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Itens da <br />Farmácia</div>

    <div class="items-sanitarios-container">
      <ng-template ngFor let-estoque [ngForOf]="estoqueSanitario">
        <button class="item-sanitario" [class.edited]="dados[estoque.hash].edited">
          <div class="labels">
            <div class="icon">
              <ion-icon name="seringa"></ion-icon>
            </div>
            <div class="info">
              <small>{{ labelsTipo[estoque.meta_tipoDoEstoque] || '' }}</small>
              <span [title]="estoque.titulo">{{ estoque.titulo }}</span>
            </div>
          </div>
          <div class="inputs">
            <vasta-input
              type="manejo-number"
              label="Quantidade"
              [placeholder]="
                estoque.meta_unidade
                  ? '0 ' + (estoque.meta_unidade === 'dose' ? estoque.meta_unidade : estoque.meta_unidade.slice(0, 2))
                  : ''
              "
              mask="separator.2"
              [maskSuffix]="
                estoque.meta_unidade
                  ? ' ' + (estoque.meta_unidade === 'dose' ? estoque.meta_unidade : estoque.meta_unidade.slice(0, 2))
                  : ''
              "
              [(value)]="dados[estoque.hash].quantidade"
              (valueChange)="emitChange(dados[estoque.hash], estoque)"
            >
            </vasta-input>
          </div>
          <div class="infos">
            <div class="manejo-info" *ngIf="dados[estoque.hash]?.quantidade && estoque?.extra_custo_na_data">
              <span class="label">Custo</span>
              <p class="content">
                R$ {{ dados[estoque.hash].quantidade * estoque.extra_custo_na_data | number : '1.2-2' }}
              </p>
            </div>
          </div>

          <div class="manejo-opcoes" *ngIf="opcoesRapidasQuantidade[abaAtiva + estoque.hash]?.length">
            <span class="label">Opções <br />Rápidas</span>
            <div class="container-opcoes">
              <ng-template ngFor let-opcao [ngForOf]="opcoesRapidasQuantidade[abaAtiva + estoque.hash]">
                <button
                  (click)="selecionarOpcaoRapidaQuantidade(estoque, opcao)"
                  [class.active]="opcao == dados[estoque.hash].quantidade"
                >
                  {{ opcao }}{{ estoque.meta_unidade ? ' ' + estoque.meta_unidade.slice(0, 2) : '' }}
                </button>
              </ng-template>
            </div>
          </div>

          <div class="manejo-info" *ngIf="dados[estoque.hash]?.observacao">
            <span class="label">Observação</span>
            <p class="content">{{ dados[estoque.hash].observacao }}</p>
          </div>

          <button
            class="item-observacoes"
            (click)="showAlertAdicionarObservacao(estoque.hash)"
            [disabled]="!dados[estoque.hash].quantidade"
          >
            <ion-icon name="chatbox"></ion-icon>
          </button>
          <button class="item-opcoes">
            <ion-icon
              name="ellipsis-vertical"
              (click)="showPopoverOpcaoItemManejoSanitario($event, estoque.hash)"
            ></ion-icon>
          </button>
        </button>
      </ng-template>

      <button class="manejo-btn add sanitario" (click)="selecionarNovoItemDoEstoque()">
        <div class="icon">
          <ion-icon name="mais"></ion-icon>
        </div>
        <span>Adicionar</span>
      </button>
    </div>

    <div class="container-acoes">
      <button (click)="showPopoverOpcaoItemManejoSanitario($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
