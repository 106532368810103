import { Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import { AreasService } from 'src/app/services/areas.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ICadastroArea } from 'src/app/utils/interfaces/areas.interface'
import { DesenharAreaComponent } from '../desenhar-area/desenhar-area.component'
import { TiposCapimLista } from 'src/app/utils/tipos-capim'
import { TiposAreaLista } from 'src/app/utils/tipos-area'

@Component({
  selector: 'app-modal-cadastrar-area',
  templateUrl: './modal-cadastrar-area.component.html',
  styleUrls: ['./modal-cadastrar-area.component.scss']
})
export class ModalCadastrarAreaComponent implements OnInit {
  public area: ICadastroArea = {
    hash: '',
    id_propriedade: null,
    nome: null
  }
  public propArea: ICadastroArea
  public loadingExcluirArea = false
  public loadingSalvarArea = false

  public opcoesTipoCapim = [
    {
      value: '',
      label: ''
    },
    ...TiposCapimLista.map(capim => {
      return {
        label: capim,
        value: capim
      }
    })
  ]

  public opcoesTipoArea = [
    {
      value: '',
      label: ''
    },
    ...TiposAreaLista.map((area) => {
      return {
        label: area,
        value: area
      }
    })
  ]

  constructor(
    private utilsCtrl: IonicUtilsService,
    private areasService: AreasService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {}

  ngOnInit(): void {
    if (this.propArea) {
      this.area = this.propArea
    }
  }

  salvarArea(): void {
    if (this.propArea) {
      this.editarArea()
    } else {
      this.cadastrarArea()
    }
  }

  async cadastrarArea(): Promise<void> {
    this.loadingSalvarArea = true
    const [response, error] = await this.areasService.cadastrarArea(this.area)
    this.loadingSalvarArea = false

    if (response) {
      VastaRX.setState('updated', true)
      this.modalCtrl.dismiss({
        success: true
      })
      this.utilsCtrl.showToast('Área Cadastrada com sucesso', 'bottom')
    }

    if (error) {
      console.error(error)
      const message = error.message ? error.message : 'Não foi possível cadastrar a área'
      this.utilsCtrl.showToast(message, 'bottom')
    }
  }

  async editarArea(): Promise<void> {
    delete this.area.lotes
    this.loadingSalvarArea = true
    const [response, error] = await this.areasService.editarArea(this.area.hash, this.area)
    this.loadingSalvarArea = false

    if (response) {
      this.modalCtrl.dismiss({
        success: true,
        area: this.area
      })
      this.utilsCtrl.showToast('Área atualizada com sucesso', 'bottom')
    }

    if (error) {
      console.error(error)
      const message = error.message ? error.message : 'Não foi possível atualizar a área'
      this.utilsCtrl.showToast(message, 'bottom')
    }
  }

  async deletarArea(): Promise<void> {
    this.loadingExcluirArea = true

    const [response, error] = await this.areasService.deletarArea(this.area.hash)
    this.loadingExcluirArea = false

    if (error) {
      console.error(error)
    }

    if (response) {
      this.modalCtrl.dismiss({
        areaDeletada: true
      })
      this.utilsCtrl.showToast('Área deletada', 'bottom')
    }
  }

  async alertConfirmDeleteArea(lotes: string): Promise<void> {
    const qtdLotes = (lotes || '').split(',').length
    const s = qtdLotes == 1 ? '' : 's'
    const alert = await this.alertCtrl.create({
      header: 'Deseja realmente remover esta área?',
      message: qtdLotes ? `A área tem ${qtdLotes} lote${s} associado${s}` : '',
      buttons: [
        'Cancelar',
        {
          text: 'Remover',
          handler: (): void => {
            this.deletarArea()
          }
        }
      ]
    })

    await alert.present()
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  async openModalDesenharArea(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DesenharAreaComponent,
      cssClass: 'custom-modal-desenhar-area',
      componentProps: {
        hash_not_in: this.area?.hash ? [this.area?.hash] : undefined,
        area: this.area.area,
        coordenadas_centrais: this.area.coordenadas_centrais
      }
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (data) {
      const { area, coordenadas_centrais, tamanho } = data
      this.area.area = area
      this.area.coordenadas_centrais = coordenadas_centrais
      this.area.tamanho = tamanho
      console.log('Area com dados Mapa: ', this.area)
    }
  }
}
