<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Diagnóstico de Gestação</div>

    <vasta-input
      label="Resultado"
      type="manejo-segment"
      [(value)]="dados.dg_resultado"
      [opcoes]="[
        { label: 'Positivo', value: 'positivo' },
        { label: 'Negativo', value: 'negativo' }
      ]"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.dg_resultado === 'positivo' && exposicoes[dados.hash_animal]?.length"
      label="Man. de Exposição"
      type="manejo-button"
      [value]="exposicoesAssociadas[dados.dg_hash_exposicao]?.data | timestamp: 'data'"
      (buttonEvent)="showPopoverListaExposicoes($event)"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.dg_resultado === 'positivo'"
      label="Período Gestacional"
      type="manejo-number"
      [maskSuffix]="dados.dg_periodo_gestacional == '1' ? ' dia' : ' dias'"
      mask="separator.0"
      [(value)]="dados.dg_periodo_gestacional"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <div class="manejo-info" *ngIf="dados.dg_resultado === 'positivo' && dados.dg_periodo_gestacional">
      <span class="label">Data de Concepção</span>
      <p class="content">{{ dataConcepcao || autoDataConcepcao() }}</p>
    </div>

    <div class="manejo-info" *ngIf="dados.dg_resultado === 'positivo' && dados.dg_periodo_gestacional && dataConcepcao">
      <span class="label">Previsão de Parto</span>
      <p class="content">{{ dataPrevisaoParto || autoDataPrevisaoParto() }}</p>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.dg_resultado">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
    <div *ngIf="manejosReprodutivosAvancados" class="manejo-avancados">
      <vasta-input
      [label]="'Ovario Esquerdo'"
      type="manejo-button"
      [value]="opcoesOvario[dados.dg_ovario_esquerdo]?.texto"
      (buttonEvent)="showPopoverOpcoesOvario($event, 'esquerdo')"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>
    <vasta-input
      [label]="'Ovario Direito'"
      type="manejo-button"
      [value]="opcoesOvario[dados.dg_ovario_direito]?.texto"
      (buttonEvent)="showPopoverOpcoesOvario($event, 'direito')"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>
    <vasta-input
      label="Tônus Uterino"
      type="manejo-number"
      mask="separator.1"
      [(value)]="dados.dg_tonus_uterino"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>
    </div>
  </section>
</div>
