import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { PlanosAssinaturaService } from 'src/app/services/plano-assinatura.service'
import { ModalAssineAgoraPage } from '../modal-assine-agora/modal-assine-agora.page'
import { UsuariosService } from 'src/app/services/usuarios.service'
import { StatusPermissaoUsuario } from 'src/app/utils/interfaces/Usuarios.interface'
import { PermissoesUsuarioService } from 'src/app/services/permissoes-usuario.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { ModalComparativoPlanosPage } from '../modal-comparativo-planos/modal-comparativo-planos.page'

@Component({
  selector: 'app-modal-plano-expirado',
  templateUrl: './modal-plano-expirado.page.html',
  styleUrls: ['./modal-plano-expirado.page.scss']
})
export class ModalPlanoExpiradoPage implements OnInit {
  public tipoVencimento: 'trial-expirado' | 'plano-expirado' = 'trial-expirado'
  public isBotaoClose: boolean

  constructor(
    private modalCtrl: ModalController,
    private planosAssinaturaCtrl: PlanosAssinaturaService,
    private usuarioCtrl: UsuariosService,
    private permissaoCtrl: PermissoesUsuarioService,
    private utilsCtrl: IonicUtilsService,
    private planoBasicoCtrl: PlanoBasicoService
  ) {}

  ngOnInit(): void {
    const statusValidoEmFluxoInicialVisaoGeral: StatusPermissaoUsuario = this.tipoVencimento as StatusPermissaoUsuario
    this.permissaoCtrl.monitorarPermissaoUsuarioFecharModalIncompativel(statusValidoEmFluxoInicialVisaoGeral, this.modalCtrl)
  }

  entrarEmContato(): void {
    this.planosAssinaturaCtrl.entrarEmContatoPorWhatsapp()
  }

  async abrirModalComparativoFuncionalidadesProEBasico(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalComparativoPlanosPage,
      cssClass: 'custom-modal'
    })

    await modal.present()

    modal.onDidDismiss().then(() => {
      this.modalCtrl.dismiss()
      
    })
  }

  async IniciarPlanoBasicoEExibirDetalhes(): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circles', 'Ativando plano Fertili Controle...')

    const [response, error] = await this.usuarioCtrl.iniciarPlanoBasico()
    
    if(response) {
      await this.planoBasicoCtrl.atualizarPlanoAtivoDoUsuario()
      this.permissaoCtrl.liberarAcessoAoUsuario()
      this.utilsCtrl.showToast('Plano Controle iniciado', 'top')
      this.utilsCtrl.dismissLoading(loading)

      // await this.abrirModalComparativoFuncionalidadesProEBasico()
      this.modalCtrl.dismiss()
      window.dispatchEvent(new Event('resize'))
    }

    if(error) {
      console.error(error)
      loading?.dismiss()
      this.utilsCtrl.showToast('Erro ao iniciar plano controle, tente novamente', 'top')
    }
  } 

  async abrirAssinatura(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAssineAgoraPage,
      cssClass: 'custom-modal',
      backdropDismiss: false
    })

    await modal.present()
  }

  async sairDaConta(): Promise<void> {
    await this.usuarioCtrl.sairDaConta()
  }

  dismiss(): void {
    this.modalCtrl.dismiss()
  }
}
