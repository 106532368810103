import { Component, Input } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'

interface opcoesLabels {
  chave: string
  labelFiltro?: string
  filtroAtivo?: boolean
  tipoFiltro: string
  opcoesFiltro: Record<string, string | number | boolean>
  width?: number
  selectionMode?: string
  view?: string
  placeholder: string
  unidade?: string
  titulo?: string
}

interface Filtros {
  limit: number
  offset: number
  situacao?: string
  chave?: string
  situacao_produtiva?: string
  situacao_reprodutiva?: string
  situacao_sanitaria?: string
  sexo?: 'macho' | 'femea'
  raca?: string
  peso_data?: string
  peso_arroba?: string
  peso?: string
  partos?: string
  observacao?: string
  nome?: string
  lote?: string
  gmd?: string
  data_manejo_lote?: string
  data_nascimento?: string
  categoria?: string
}

@Component({
  selector: 'app-modal-filtros-tabela',
  templateUrl: './modal-filtros-tabela.component.html',
  styleUrls: ['./modal-filtros-tabela.component.scss']
})
export class ModalFiltrosTabelaComponent {
  @Input() labels: opcoesLabels[]
  @Input() filtros: Filtros
  public inputTime: NodeJS.Timeout
  constructor(private alertCtrl: AlertController, private modalCtrl: ModalController) {}

  async openAlertOptions(label: opcoesLabels): Promise<void> {
    const inputs = []

    Object.keys(label.opcoesFiltro).forEach((key) => {
      const input = {
        type: 'radio',
        label: label.opcoesFiltro[key],
        value: key,
        checked: false
      }
      inputs.push(input)
    })

    const alert = await this.alertCtrl.create({
      message: `Filtrar ${label.titulo || label.chave}`,
      inputs,
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'ok',
          handler: (value): void => {
            this.filtros[label.chave] = value
            label.labelFiltro = label.opcoesFiltro[value] as string
          }
        }
      ]
    })

    await alert.present()
  }

  async openModalDate(label: opcoesLabels): Promise<void> {
  }

  getValueInput(label: opcoesLabels, value: string | number): void {
    clearTimeout(this.inputTime)

    this.inputTime = setTimeout(() => {
      this.filtros[label.chave] = value
    }, 500)
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  removeFilter(label: opcoesLabels, inputValue?: string | number): void {
    if (label.labelFiltro !== 'Filtrar' || inputValue !== '') {
      if (label.tipoFiltro.includes('btn') || label.tipoFiltro === 'date') {
        label.labelFiltro = 'Filtrar'
        delete this.filtros[label.chave]
        console.log(this.filtros)
      } else {
        delete label.filtroAtivo
        delete this.filtros[label.chave]
        console.log(this.filtros)
      }
    }
  }

  get totalFilters(): number {
    return Object.keys(this.filtros).length
  }

  submit(): void {
    Object.keys(this.filtros).forEach((key) => {
      if (this.filtros[key] === '') {
        delete this.filtros[key]
      } else {
        this.labels.forEach((item) => {
          if (item.chave === key) {
            item['filtroAtivo'] = this.filtros[key]
          }
        })
      }
    })

    this.modalCtrl.dismiss(this.filtros)
  }

  clearFilters(): void {
    this.labels.forEach((item) => {
      delete item.filtroAtivo
      if (item.labelFiltro) {
        item.labelFiltro = 'Filtrar'
      }
    })

    this.modalCtrl.dismiss({})
  }
}
