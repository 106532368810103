import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { EstoqueService } from 'src/app/services/estoque.service'
import { ColunasEstoqueBiologicoLista } from 'src/app/utils/configuracoes-padrao'
import { EstoqueBiologico } from 'src/app/utils/interfaces/estoque.interface'

@Component({
  selector: 'app-modal-estoque-biologico',
  templateUrl: './modal-estoque-biologico.component.html',
  styleUrls: ['./modal-estoque-biologico.component.scss']
})
export class ModalEstoqueBiologicoComponent implements OnInit {
  public labelsEstoqueBiologico = ColunasEstoqueBiologicoLista
  public estoqueBiologico: EstoqueBiologico[] = []
  public carregamentoEstoqueBiologico: StatusTabela = 'carregando'
  public configEstoqueBiologico = {
    toolbar: false,
    filtrosEspecificos: true,
    selecao: {
      ativar: true
    },
    exibeTotais: false
  }
  public novoFiltro: NovoFiltro = {}
  public novaOrdenacao: Ordenacao = { coluna: 'quantidade', direcao: 'desc' }

  constructor(private estoqueCtrl: EstoqueService, private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.carregarDadosEstoqueBiologico()
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }
  async carregarDadosEstoqueBiologico(): Promise<void> {
    this.carregamentoEstoqueBiologico = 'carregando'
    const [response, error] = await this.estoqueCtrl.getEstoqueBiologico({
      filtros: this.novoFiltro,
      ordenacao: this.novaOrdenacao
    })

    if (response) {
      this.estoqueBiologico = response.estoques
      this.carregamentoEstoqueBiologico = 'sucesso'
      console.log(response)
    }

    if (error) {
      console.log(error)
      this.carregamentoEstoqueBiologico = 'erro'
    }
  }

  async selecionarSemenOuEmbriao({ original }: DadosLinhaTabela): Promise<void> {
    await this.modalCtrl.dismiss(original)
  }
}
