import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent  implements OnInit {
  @Input() type: 'help' | 'alert' | 'error' = 'help'
  @Input() label: string = ''
  @Input() position: 'top' | 'bottom' | 'right' | 'left'  = 'top'

  public icone = {
    help: 'help-circle',
    alert: 'alert-circle',
    error: 'close-circle',
  }

  constructor() { }

  ngOnInit(): void {}

  get classes(): string {
    return `tooltip-text ${this.type} ${this.position}`
  }
}
