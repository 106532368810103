<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
     <propriedade-nome></propriedade-nome>
     Manejos de <span>{{dataManejo | date: 'dd/MM/YYYY'}}</span>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-template ngFor let-manejo [ngForOf]="manejosDisponiveis">
    <div class="container-manejo" *ngIf="filtroSelecionado.procedimento === '' || filtroSelecionado.procedimento === manejo.procedimento">
      <h2 (click)="popoverSelecionarProcedimentoParaFiltrar($event)" class="dropdown">
        {{manejo.nome}} 
        <small *ngIf="manejo.tipo === 'sanitario'">{{manejo.descricao}}</small>
      </h2> 
  
      <kpis-cards [cardsInfo]="optionsKpisCardPorTipoManejo[manejo.procedimento]" [wrap]="true"></kpis-cards>
    </div>
  </ng-template>
  

  <vasta-tabela [labels]="colunas" [campos]="listaAtivaExibicao" [config]="config" [status]="'sucesso'"></vasta-tabela>
</ion-content>