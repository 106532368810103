import { Component, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-popover-opcoes-manejo',
  templateUrl: './popover-opcoes-manejo.component.html',
  styleUrls: ['./popover-opcoes-manejo.component.scss']
})
export class PopoverOpcoesManejoComponent implements OnInit {
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  async limpar(): Promise<void> {
    await this.popoverCtrl.dismiss({ limpar: true })
  }
}
