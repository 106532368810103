<div class="calendario-wrapper-geral">
  <div class="calendario-container">
    <div class="calendario-group">
      <div class="calendario-container">
        <div class="titulos" *ngIf="titulo || subtitulo">
          <h3 *ngIf="titulo">{{ titulo }}</h3>
          <h4 *ngIf="subtitulo">{{ subtitulo }}</h4>
        </div>

        <div class="calendario-custom">
          <div class="seletor-periodo top">
            <button *ngIf="range" (click)="exibeOpcoesRapidas()" class="range-button">
              <ion-icon name="calendar"></ion-icon>
              <div
                class="custom-popover-overlay"
                [class.show]="opcoesRapidasOpen"
                (click)="fechaOpcoesRapidas($event)"
              ></div>
              <div class="custom-popover" [class.show]="opcoesRapidasOpen">
                <button (click)="selecaoRapida($event, 'esta-semana'); isSubPopoverVisible = false">Esta Semana</button>
                <button (click)="selecaoRapida($event, 'semana-passada'); isSubPopoverVisible = false">Semana Passada</button>
                <button (click)="selecaoRapida($event, 'este-mes'); isSubPopoverVisible = false">Este Mês</button>
                <button (click)="selecaoRapida($event, 'mes-passado'); isSubPopoverVisible = false">Mês Passado</button>
                <button (click)="selecaoRapida($event, 'ultimos-3-meses'); isSubPopoverVisible = false">Últimos 3 Meses</button>
                <button (click)="selecaoRapida($event, 'ultimos-6-meses'); isSubPopoverVisible = false">Últimos 6 Meses</button>
                <button (click)="showSubPopover('ano')">
                  Ano
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </button>
                <button (click)="showSubPopover('safra')">
                  Safra
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </button>
              </div>
              <div class="custom-sub-popover" [class.show]="isSubPopoverVisible" [class.lista-anos]="!!listaAnosPopover.length" [class.lista-safra]="!!listaSafrasPopover.length">
                <ng-template [ngIf]="listaAnosPopover.length > 0">
                  <button *ngFor="let ano of listaAnosPopover" (click)="selecaoRapida($event, 'ano', ano); isSubPopoverVisible = false">{{ano}}</button>
                </ng-template>
                <ng-template [ngIf]="listaSafrasPopover.length > 0">
                  <button *ngFor="let safra of listaSafrasPopover" (click)="selecaoRapida($event, 'safra', safra); isSubPopoverVisible = false">{{safra}}</button>
                </ng-template>
              </div>
            </button>
            <button
              (click)="resetaParaEsteMes()"
              [disabled]="!(calendario && calendario.mesAnoAtual != calendario.mes + 1 + '-' + calendario.ano)"
            >
              <ion-icon name="refresh"></ion-icon>
            </button>
            <div class="spacer"></div>

            <div class="range" *ngIf="!range">
              {{ calendario.selecao.iso | timestamp : 'data' }}
            </div>
            <div class="range" *ngIf="range">
              <span
                class="selecione"
                *ngIf="!calendario.selecaoRange.de.formatado && !calendario.selecaoRange.ate.formatado"
                >Selecione:</span
              >
              <ng-template [ngIf]="!semAno">
                <span *ngIf="calendario.selecaoRange.de.formatado">{{ calendario.selecaoRange.de.formatado }}</span
                ><span
                  *ngIf="
                    calendario.selecaoRange.de.formatado &&
                    calendario.selecaoRange.ate.formatado &&
                    calendario.selecaoRange.de.formatado != calendario.selecaoRange.ate.formatado
                  "
                  >&nbsp;a&nbsp;</span
                ><span
                  *ngIf="
                    calendario.selecaoRange.ate.formatado &&
                    calendario.selecaoRange.de.formatado != calendario.selecaoRange.ate.formatado
                  "
                  >{{ calendario.selecaoRange.ate.formatado }}</span
                >
              </ng-template>
              <ng-template [ngIf]="semAno">
                <span *ngIf="calendario.selecaoRange.de.formatado">{{
                  calendario.selecaoRange.de.iso | timestamp : 'data'
                }}</span
                ><span
                  *ngIf="
                    calendario.selecaoRange.de.formatado &&
                    calendario.selecaoRange.ate.formatado &&
                    calendario.selecaoRange.de.formatado != calendario.selecaoRange.ate.formatado
                  "
                  >&nbsp;a&nbsp;</span
                ><span
                  *ngIf="
                    calendario.selecaoRange.ate.formatado &&
                    calendario.selecaoRange.de.formatado != calendario.selecaoRange.ate.formatado
                  "
                  >{{ calendario.selecaoRange.ate.iso | timestamp : 'data' }}</span
                >
              </ng-template>
            </div>
          </div>
          <div class="seletor-periodo">
            <div class="periodo-meses">
              <button class="modifica-mes" (click)="modificaMes(-1)" [disabled]="bloqueiaMesAnterior">
                <ion-icon name="chevron-back"></ion-icon>
              </button>
              <button class="modifica-mes" (click)="modificaMes(1)" [disabled]="bloqueiaProximoMes">
                <ion-icon name="chevron-forward"></ion-icon>
              </button>
              <div class="label-container">
                <div class="select-container">
                  <select [(ngModel)]="calendario.mes" (change)="renderizaCalendario()" class="clickable-element">
                    <option [value]="0">Janeiro</option>
                    <option [value]="1">Fevereiro</option>
                    <option [value]="2">Março</option>
                    <option [value]="3">Abril</option>
                    <option [value]="4">Maio</option>
                    <option [value]="5">Junho</option>
                    <option [value]="6">Julho</option>
                    <option [value]="7">Agosto</option>
                    <option [value]="8">Setembro</option>
                    <option [value]="9">Outubro</option>
                    <option [value]="10">Novembro</option>
                    <option [value]="11">Dezembro</option>
                  </select>
                  <select [ngModel]="calendario.mes" class="width-element">
                    <option *ngIf="calendario.mes == 0" [value]="0">Janeiro</option>
                    <option *ngIf="calendario.mes == 1" [value]="1">Fevereiro</option>
                    <option *ngIf="calendario.mes == 2" [value]="2">Março</option>
                    <option *ngIf="calendario.mes == 3" [value]="3">Abril</option>
                    <option *ngIf="calendario.mes == 4" [value]="4">Maio</option>
                    <option *ngIf="calendario.mes == 5" [value]="5">Junho</option>
                    <option *ngIf="calendario.mes == 6" [value]="6">Julho</option>
                    <option *ngIf="calendario.mes == 7" [value]="7">Agosto</option>
                    <option *ngIf="calendario.mes == 8" [value]="8">Setembro</option>
                    <option *ngIf="calendario.mes == 9" [value]="9">Outubro</option>
                    <option *ngIf="calendario.mes == 10" [value]="10">Novembro</option>
                    <option *ngIf="calendario.mes == 11" [value]="11">Dezembro</option>
                  </select>
                </div>
                <!-- {{ mesesNomes[calendario.mes] }} -->
              </div>
            </div>
            <div class="periodo-anos" *ngIf="!semAno">
              <div class="label-container">
                <select [(ngModel)]="calendario.ano" (change)="renderizaCalendario()">
                  <option *ngFor="let ano of this.anosDisponiveis" [value]="ano">{{ ano }}</option>
                </select>
              </div>
              <button class="modifica-mes" (click)="modificaAno(-1)" [disabled]="bloqueiaAnoAnterior">
                <ion-icon name="chevron-back"></ion-icon>
              </button>
              <button class="modifica-mes" (click)="modificaAno(1)" [disabled]="bloqueiaProximoAno">
                <ion-icon name="chevron-forward"></ion-icon>
              </button>
            </div>
          </div>
          <div class="dias-container">
            <div class="dia h fim-de-semana">DOM</div>
            <div class="dia h">SEG</div>
            <div class="dia h">TER</div>
            <div class="dia h">QUA</div>
            <div class="dia h">QUI</div>
            <div class="dia h">SEX</div>
            <div class="dia h fim-de-semana">SAB</div>
            <div
              class="dia {{ dia.diasEpoch }}"
              *ngFor="let dia of calendario.mesArray"
              [class.outroMes]="dia.outroMes"
              [class.hoje]="dia.hoje"
              [class.selecionado]="calendario.selecao.data == dia.data"
              [class.desabilitado]="dia.desabilitado"
              [class.dentroRange]="
                dia.diasEpoch >= calendario.selecaoRange.de.diasEpoch &&
                dia.diasEpoch <= calendario.selecaoRange.ate.diasEpoch
              "
              [class.primeiroRange]="dia.diasEpoch == calendario.selecaoRange.de.diasEpoch"
              [class.ultimoRange]="dia.diasEpoch == calendario.selecaoRange.ate.diasEpoch"
              [class.antesInicialRange]="
                dia.diasEpoch < calendario.selecaoRange.de.diasEpoch &&
                calendario.selecaoRange.de.diasEpoch &&
                !calendario.selecaoRange.ate.diasEpoch
              "
              (click)="selecionaDia(dia)"
            >
              <span>{{ dia.dia }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="calendario-botoes-de-acao">
  <button class="cancelar" (click)="dismiss()">
    <ion-icon name="close"></ion-icon>
    <span>Cancelar</span>
  </button>
  <button
    class="confirmar"
    (click)="confirmar()"
    [disabled]="
      (!range && !calendario.selecao.data) ||
      (range && (!calendario.selecaoRange.de.diasEpoch || !calendario.selecaoRange.ate.diasEpoch))
    "
  >
    <ion-icon name="checkmark"></ion-icon>
    <span>Confirmar</span>
  </button>
</div>
