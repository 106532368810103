<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Parto</div>

    <div class="chip-natimorto" *ngIf="dados?.parto_hash_cria1 == 'natimorto'">
      Natimorto
      <button (click)="desmarcaNatimorto(1)"><ion-icon name="close"></ion-icon></button>
    </div>

    <div class="container-manejo" *ngIf="dados?.parto_hash_cria1 !== 'natimorto'">
      <vasta-input
        label="Cria"
        type="manejo-button"
        [disabled]="!!errosManejoCria?.length && errosManejoCria[0]?.numeroCria === 2"
        [icon]="
          animaisAssociados[dados?.parto_hash_cria1]
            ? animaisAssociados[dados?.parto_hash_cria1].sexo == 'femea'
              ? 'female'
              : 'male'
            : ''
        "
        [value]="
          animaisAssociados[dados?.parto_hash_cria1]?.numeracao || animaisAssociados[dados?.parto_hash_cria1]?.nome
        "
        (buttonEvent)="showPopoverSelecionarAdicionarCria($event, 1)"
      ></vasta-input>

      <div class="manejo-alerta" *ngIf="errosManejoCria[0]?.isAberto && errosManejoCria[0]?.numeroCria === 1">
        <span class="titulo">{{ errosManejoCria[0]?.titulo }}</span>
        <span class="mensagem">{{ errosManejoCria[0]?.mensagem }}</span>
        <cta-buttons>
          <button (click)="corrigirErroDaCria(1)" class="primary">
            <ion-icon *ngIf="!isAtualizandoDadosDaCria" name="pencil"></ion-icon>
            <ion-spinner color="light" style="width: 12px; height: 12px" *ngIf="isAtualizandoDadosDaCria"></ion-spinner>
            <span>Corrigir</span>
          </button>
          <button (click)="ignorarErroDoManejo()">
            <ion-icon name="close"></ion-icon>
            Ignorar
          </button>
        </cta-buttons>
      </div>
    </div>

    <div class="chip-natimorto" *ngIf="dados?.parto_hash_cria2 == 'natimorto'">
      Natimorto
      <button (click)="desmarcaNatimorto(2)"><ion-icon name="close"></ion-icon></button>
    </div>

    <div class="container-manejo" *ngIf="dados?.parto_hash_cria2 !== 'natimorto'">
      <vasta-input
        *ngIf="dados.parto_hash_cria1 || dados.parto_hash_cria2"
        label="Cria 2"
        [disabled]="!!errosManejoCria?.length && errosManejoCria[0]?.numeroCria === 1"
        [icon]="
          animaisAssociados[dados.parto_hash_cria2]
            ? animaisAssociados[dados.parto_hash_cria2].sexo == 'femea'
              ? 'female'
              : 'male'
            : ''
        "
        type="manejo-button"
        class="cria2"
        [value]="
          animaisAssociados[dados.parto_hash_cria2]?.numeracao || animaisAssociados[dados.parto_hash_cria2]?.nome
        "
        (buttonEvent)="showPopoverSelecionarAdicionarCria($event, 2)"
      ></vasta-input>

      <div class="manejo-alerta" *ngIf="errosManejoCria[0]?.isAberto && errosManejoCria[0]?.numeroCria === 2">
        <span class="titulo">{{ errosManejoCria[0]?.titulo }}</span>
        <span class="mensagem">{{ errosManejoCria[0]?.mensagem }}</span>
        <cta-buttons>
          <button (click)="corrigirErroDaCria(2)" class="primary">
            <ion-icon *ngIf="!isAtualizandoDadosDaCria" name="pencil"></ion-icon>
            <ion-spinner color="light" style="width: 12px; height: 12px" *ngIf="isAtualizandoDadosDaCria"></ion-spinner>
            <span>Corrigir</span>
          </button>
          <button (click)="ignorarErroDoManejo()">
            <ion-icon name="close"></ion-icon>
            Ignorar
          </button>
        </cta-buttons>
      </div>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.parto_hash_cria1">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
