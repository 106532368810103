import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { ModalCompraVendaComponent } from 'src/app/pages/financas/modal-compra-venda/modal-compra-venda.component'
import { ModalSelecionarVendaPage } from 'src/app/pages/financas/modal-selecionar-venda/modal-selecionar-venda.page'
import { IManejoGeralUnitario } from 'src/app/services/manejos.service'
import { IAnimal } from 'src/app/utils/interfaces/animais.interface'
import { IFinancasCompraEVenda } from 'src/app/utils/interfaces/financas.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'
import { PopoverSelecionarVendaComponent } from '../popover-selecionar-venda/popover-selecionar-venda.component'

@Component({
  selector: 'app-manejo-geral-venda',
  templateUrl: './manejo-geral-venda.component.html',
  styleUrls: ['./manejo-geral-venda.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManejoGeralVendaComponent implements OnInit {
  @Input('dados') dados: IManejoGeralUnitario = {}
  @Input('animal') animal: IAnimal

  @Input('vendas') vendas: { [key: number]: IFinancasCompraEVenda } = {}
  @Output() vendasChange = new EventEmitter<{ [key: number]: IFinancasCompraEVenda }>()

  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  constructor(private popoverCtrl: PopoverController, private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  async selecionarVenda(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverSelecionarVendaComponent,
      cssClass: 'popover-selecionar-adicionar-cria',
      event,
      reference: 'trigger'
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()

    if (data) {
      if (data === 'cadastrar') {
        this.cadastrarVenda()
      }

      if (data === 'selecionar') {
        this.selecionarVendaExistente()
      }
    }
  }

  async cadastrarVenda(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCompraVendaComponent,
      componentProps: {
        animaisProp: [this.animal],
        tipoTransacao: 'venda'
      },
      cssClass: 'modal-venda'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.success) {
    }
  }

  async selecionarVendaExistente(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalSelecionarVendaPage,
      componentProps: { tipo: 'venda', animaisProp: [this.animal] },
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    console.log('data', data)
    if (data?.lancamento) {
      const { lancamento } = data as { lancamento: IFinancasCompraEVenda }
      this.dados.venda_hash_transacao = lancamento.hash
      this.vendas[lancamento.hash] = lancamento
      this.emit()
    }
  }

  emit(): void {
    this.dadosChange.emit(this.dados)
    this.vendasChange.emit(this.vendas)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.transf_propriedade_id = null
      this.emit()
    }
  }

  async verTransacao(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCompraVendaComponent,
      componentProps: {
        dadosTransacaoProp: {
          hash: this.dados.venda_hash_transacao
        },
        tipoTransacao: 'venda'
      },
      cssClass: 'modal-venda'
    })

    await modal.present()
    // const { data } = await modal.onWillDismiss()
  }
}
