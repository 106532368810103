import { Component, Input, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-popover-seletor',
  templateUrl: './popover-seletor.component.html',
  styleUrls: ['./popover-seletor.component.scss'],
})

export class PopoverSeletorComponent  implements OnInit {
  
  @Input() opcoes: { [key: string]: { icone: string, texto: string } }
  @Input() opcoesKeys: string[]

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  async selecionarAcao(acao: string): Promise<void> {
    await this.popoverCtrl.dismiss(acao)
  }
}

