import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Conteudo } from 'src/app/services/educacao.service'

@Component({
  selector: 'conteudos-lista',
  templateUrl: './conteudos-lista.component.html',
  styleUrls: ['./conteudos-lista.component.scss']
})
export class ConteudosListaComponent implements OnInit {
  @Input() lista: Conteudo[] = []
  @Input() tipo?: 'vertical' | 'horizontal' = 'horizontal'
  @Input() activeId?: string
  @Output() clickedItem: EventEmitter<Conteudo> = new EventEmitter<Conteudo>()

  constructor() {}

  ngOnInit(): void { }
  
  clicked(item: Conteudo): void {
    this.clickedItem.next(item)
  }
}
