<main>
  <div class="container-lista">
    <button (click)="limpar()">
      <ion-icon name="trash"></ion-icon>
      <div class="conteudo">
        <span>Limpar</span>
      </div>
    </button>
  </div>
</main>
