<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="defaultHref"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{item?.tipoDoEstoque == 'semen' ? 'Estoque de Sêmen' : 'Estoque de Embriões'}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button disabled>
        <ion-icon name="help" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <cta-buttons style="margin-top: 0">
    <button class="primary" (click)="cadastroMovimentacao()" [disabled]="!item?.hash">
      <ion-icon name="add-circle"></ion-icon>
      <span>Nova movimentação</span>
    </button>
    <button (click)="editarItem()" [disabled]="!item?.hash">
      <ion-icon name="pencil-outline"></ion-icon>
      <span>Editar item</span>
    </button>
  </cta-buttons>
  <kpis-cards [cardsInfo]="optionsKpisCard" [valores]="item || {}"></kpis-cards>

  <vasta-tabela
    [labels]="estoqueBiologicoColunas"
    [campos]="estoquesBiologicos"
    [config]="estoqueBiologicoConfig"
    [status]="carregamentoEstoque"
    [optionsButtons]="optionsButtonsItens"
    (retentar)="buscarLancamentos()"
    (selecionado)="cadastroMovimentacao($event)"
    [(paginate)]="paginate"
    (paginateChange)="buscarLancamentos()"
  ></vasta-tabela>
</ion-content>
