import { Component, OnInit } from '@angular/core'
import { ModalController, Platform, PopoverController } from '@ionic/angular'
import { PopoverDetalhamentoManejoComponent } from 'src/app/components/popover-detalhamento-manejo/popover-detalhamento-manejo.component'
import { IManejoAreaTipos, IManejoGeneticaTipos, IManejoGeralTipos, IManejoLoteTipos, IManejoReprodutivoTipos, IManejoSanitarioTipos } from 'src/app/services/manejos.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { listaManejosDisponiveis } from './lista-manejos-disponiveis'
import { PropriedadesService } from 'src/app/services/propriedades.service'

export type ListaManejosProp = {
  hash?: string
  icon?: string
  nome: string
  procedimento:
  | string
  | 'pesagem'
  | 'ordenha'
  | 'outros'
  | IManejoGeralTipos
  | IManejoReprodutivoTipos
  | IManejoSanitarioTipos
  | IManejoAreaTipos
  | IManejoLoteTipos
  | IManejoGeneticaTipos
  tipo?: 'sanitario' | 'reprodutivo' | 'pesagem' | 'ordenha' | 'geral' | 'areas' | 'lote' | 'nutricao' | 'sanitarioEstoque' | 'manejosLotes' | 'genetica'
  sexo?: 'femea' | 'macho'
  cor?: string
  identificador?: string
  descricao?: string
  classActive?: boolean
  qtd?: number
  importavel?: boolean
  
  fertiliCampo?: boolean
  fertiliCampoIdentificador?: string
}

@Component({
  selector: 'app-manejos-disponiveis',
  templateUrl: './manejos-disponiveis.component.html',
  styleUrls: ['./manejos-disponiveis.component.scss']
})
export class ManejosDisponiveisComponent implements OnInit {
  public filtrosAtivosAnimais: ListaManejosProp[] = []
  public filtrosAtivosLotes: ListaManejosProp[] = []
  public filtrosAtivosAreas: ListaManejosProp[] = []

  public listaManejos: ListaManejosProp[] = JSON.parse(JSON.stringify(listaManejosDisponiveis.manejos))
  public listaManejosReprodutivos: ListaManejosProp[] = JSON.parse(
    JSON.stringify(listaManejosDisponiveis.manejosReprodutivos)
  )
  public listaManejosSanitarios: ListaManejosProp[] = JSON.parse(
    JSON.stringify(listaManejosDisponiveis.manejosSanitarios)
  )
  public listaManejosGenetica: ListaManejosProp[] = JSON.parse(
    JSON.stringify(listaManejosDisponiveis.manejosGenetica)
  )
  public listaManejosLotes: ListaManejosProp[] = JSON.parse(JSON.stringify(listaManejosDisponiveis.manejosLotes))
  public listaManejosAreas: ListaManejosProp[] = JSON.parse(JSON.stringify(listaManejosDisponiveis.manejosAreas))
  public tipoManejoSelecionado: 'outros' | 'sanitario' | 'reprodutivo' | 'vazio' = 'vazio'
  public manejosSelecionadosAnimais: ListaManejosProp[] = []
  public manejosSelecionadosLotes: ListaManejosProp[] = []
  public manejosSelecionadosAreas: ListaManejosProp[] = []

  public isAlgumManejoGeralBloqueadoEmPlanoBasico: boolean
  public isAlgumManejoReprodutivoBloqueadoEmPlanoBasico: boolean
  public isAlgumManejoSanitarioBloqueadoEmPlanoBasico: boolean
  public isAlgumManejoLoteBloqueadoEmPlanoBasico: boolean
  public isAlgumManejoAreaBloqueadoEmPlanoBasico: boolean

  public exibicaoAtiva: string | 'Animais' | 'Lotes' | 'Áreas' = 'Animais'
  manejosHabilitadosPeao: string[] = []
  public isManejosCampo: boolean = false

  public habilitaManejosGenetica: boolean

  constructor(
    private modalCtrl: ModalController, 
    private popoverCtrl: PopoverController,
    private plt: Platform,
    public planoBasicoCtrl: PlanoBasicoService,
    public propriedadesCtrl: PropriedadesService
  ) {}

  ngOnInit(): void {

    this.habilitaManejosGenetica = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.habilita_manejos_genetica

    if(this.isManejosCampo){
      this.processaManejosFertiliCampo()
    }

    this.filtrosAtivosAnimais.forEach((item) => {
      if (item.tipo === 'sanitario') {
        this.listaManejosSanitarios.forEach((m) => {
          if (m.identificador === item.identificador) {
            m.qtd++
          }
        })
      }
    })

    this.verificarManejosBloqueadoSeForPlanoBasico()
  }
  
  public verificarManejosBloqueadoSeForPlanoBasico(): void {
    if(this.planoBasicoCtrl.isPlanoBasico) {
      this.isAlgumManejoGeralBloqueadoEmPlanoBasico = this.listaManejos.some(manejo => this.planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', manejo.identificador))
      this.isAlgumManejoReprodutivoBloqueadoEmPlanoBasico = this.listaManejosReprodutivos.some(manejo => this.planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', manejo.identificador))
      this.isAlgumManejoSanitarioBloqueadoEmPlanoBasico = this.listaManejosSanitarios.some(manejo => this.planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', manejo.identificador))
      this.isAlgumManejoLoteBloqueadoEmPlanoBasico = this.listaManejosLotes.some(manejo => this.planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', manejo.identificador))
      this.isAlgumManejoAreaBloqueadoEmPlanoBasico = this.listaManejosAreas.some(manejo => this.planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('manejos', manejo.identificador))
    }
  }

  async closeModalManejosDisponiveis(): Promise<void> {
    this.modalCtrl.dismiss()
    this.tipoManejoSelecionado = 'vazio'
  }

  async selecionarTipoManejo(manejoProp: ListaManejosProp, event?: Event): Promise<void> {
    if (!this.isManejosCampo){
      const { acessoAoItemBloqueado } = await this.planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('manejos', manejoProp.identificador, event)
      if(acessoAoItemBloqueado) return
    }

    if(this.exibicaoAtiva == 'Animais') {
      let manejo = { ...manejoProp }
      this.tipoManejoSelecionado = 'vazio'
      if (manejo.tipo === 'sanitario' && !this.isManejosCampo) {
        const descricao = await this.showPopoverDetalhamentoManejo(event)
        if (!descricao) return
        manejo.descricao = descricao
        manejo.procedimento = new Date().getTime().toFixed(0)
        manejoProp.qtd++
      }
      if (this.isManejosCampo) {
        manejo.procedimento = manejo.identificador
      }
      if (
        !this.manejosSelecionadosAnimais.map((m) => m.procedimento || m.identificador).includes(manejo.procedimento || manejo.identificador) ||
        (!this.isManejosCampo && manejo.tipo === 'sanitario')
      ) {
        this.manejosSelecionadosAnimais.push(manejo)
      } else {
        this.manejosSelecionadosAnimais = this.manejosSelecionadosAnimais.filter((m) => m.procedimento !== manejo.procedimento)
      }
      manejoProp.classActive = !manejoProp.classActive
    }
    if(this.exibicaoAtiva == 'Lotes') {
      let manejo = { ...manejoProp }
      this.tipoManejoSelecionado = 'vazio'
      if ( !this.manejosSelecionadosLotes.map((m) => m.procedimento).includes(manejo.procedimento) ) {
        this.manejosSelecionadosLotes.push(manejo)
      } else {
        this.manejosSelecionadosLotes = this.manejosSelecionadosLotes.filter((m) => m.procedimento !== manejo.procedimento)
      }
      manejoProp.classActive = !manejoProp.classActive
    }
    if(this.exibicaoAtiva == 'Áreas') {
      let manejo = { ...manejoProp }
      this.tipoManejoSelecionado = 'vazio'
      if ( !this.manejosSelecionadosAreas.map((m) => m.procedimento).includes(manejo.procedimento) ) {
        this.manejosSelecionadosAreas.push(manejo)
      } else {
        this.manejosSelecionadosAreas = this.manejosSelecionadosAreas.filter((m) => m.procedimento !== manejo.procedimento)
      }
      manejoProp.classActive = !manejoProp.classActive
    }
  }

  verificarFiltrosAtivos(filtro: ListaManejosProp): boolean {
    const arrayFiltros = {
      'Animais': this.filtrosAtivosAnimais,
      'Lotes': this.filtrosAtivosLotes,
      'Áreas': this.filtrosAtivosAreas,
    }[this.exibicaoAtiva] || []

    let filtroAtivo = false
    arrayFiltros.forEach((f) => {
      if (f.tipo === 'sanitario') {
        return
      }
      if (f.procedimento === filtro.procedimento) {
        filtroAtivo = true
        return
      }
    })

    return filtroAtivo
  }

  confirmarSelecao(): void {
    this.modalCtrl.dismiss({
      manejosSelecionadosAnimais: this.manejosSelecionadosAnimais,
      manejosSelecionadosLotes: this.manejosSelecionadosLotes,
      manejosSelecionadosAreas: this.manejosSelecionadosAreas
    })
  }

  async showPopoverDetalhamentoManejo(event: Event): Promise<string> {
    const popover = await this.popoverCtrl.create({
      component: PopoverDetalhamentoManejoComponent,
      event: this.plt.is('capacitor') ? undefined : event,
      reference: this.plt.is('capacitor') ? 'trigger' : undefined,
    })
    await popover.present()
    const { data } = await popover.onWillDismiss()
    return data
  }

  processaManejosFertiliCampo(): void {
    this.listaManejos = this.listaManejos.filter(manejo => manejo.fertiliCampo)
    this.listaManejosReprodutivos = this.listaManejosReprodutivos.filter(manejo => manejo.fertiliCampo)
    this.listaManejosSanitarios = this.listaManejosSanitarios.filter(manejo => manejo.fertiliCampo)
    this.listaManejosGenetica = this.listaManejosGenetica.filter(manejo => manejo.fertiliCampo)
    this.listaManejosLotes = this.listaManejosLotes.filter(manejo => manejo.fertiliCampo)
    this.listaManejosAreas = this.listaManejosAreas.filter(manejo => manejo.fertiliCampo)

    this.manejosSelecionadosAnimais = [
      ...this.listaManejos,
      ...this.listaManejosReprodutivos,
      ...this.listaManejosSanitarios,
      ...this.listaManejosGenetica
    ].filter(a => this.manejosHabilitadosPeao.includes(a.fertiliCampoIdentificador))
    this.manejosSelecionadosLotes = this.listaManejosLotes.filter(a => this.manejosHabilitadosPeao.includes(a.fertiliCampoIdentificador))
    this.manejosSelecionadosAreas = this.listaManejosAreas.filter(a => this.manejosHabilitadosPeao.includes(a.fertiliCampoIdentificador))

    const todosManejos = [...this.listaManejos, ...this.listaManejosReprodutivos, ...this.listaManejosSanitarios, ...this.listaManejosGenetica]
    todosManejos.filter(manejo => this.manejosSelecionadosAnimais.map(m => m.fertiliCampoIdentificador).includes(manejo.fertiliCampoIdentificador)).forEach(manejo => manejo.classActive = true)
    this.listaManejosLotes.filter(manejo => this.manejosSelecionadosLotes.map(m => m.fertiliCampoIdentificador).includes(manejo.fertiliCampoIdentificador)).forEach(manejo => manejo.classActive = true)
    this.manejosSelecionadosLotes.map(manejoLote => {
      this.listaManejosLotes.filter(manejo => manejo.fertiliCampoIdentificador == manejoLote.fertiliCampoIdentificador).forEach(manejo => manejo.classActive = true)
    })
    this.listaManejosAreas.filter(manejo => this.manejosSelecionadosAreas.map(m => m.fertiliCampoIdentificador).includes(manejo.fertiliCampoIdentificador)).forEach(manejo => manejo.classActive = true)
    this.manejosSelecionadosAreas.map(manejoLote => {
      this.listaManejosAreas.filter(manejo => manejo.fertiliCampoIdentificador == manejoLote.fertiliCampoIdentificador).forEach(manejo => manejo.classActive = true)
    })
  }

  async selecionarTodos(ev: boolean): Promise<void> {
    if (this.exibicaoAtiva === 'Animais') {
      const todosManejos = [...this.listaManejos, ...this.listaManejosReprodutivos, ...this.listaManejosSanitarios, ...this.listaManejosGenetica]
      if(ev){
        todosManejos.map((manejo) => { manejo.classActive = true; return manejo })
        this.manejosSelecionadosAnimais = [...this.listaManejos, ...this.listaManejosReprodutivos, ...this.listaManejosSanitarios, ...this.listaManejosGenetica]
      } else {
        todosManejos.map((manejo) => { manejo.classActive = false; return manejo })
        this.manejosSelecionadosAnimais = []
      }
    }

    if (this.exibicaoAtiva === 'Lotes') {
      if(ev){
        this.listaManejosLotes.map((manejo) => { manejo.classActive = true; return manejo })  
        this.manejosSelecionadosLotes = [...this.listaManejosLotes]
      } else {
        this.listaManejosLotes.map((manejo) => { manejo.classActive = false; return manejo })
        this.manejosSelecionadosLotes = []
      }
    }

    if (this.exibicaoAtiva === 'Áreas') {
      if(ev){
        this.listaManejosAreas.map((manejo) => { manejo.classActive = true; return manejo })  
        this.manejosSelecionadosAreas = [...this.listaManejosAreas]
      } else {
        this.listaManejosAreas.map((manejo) => { manejo.classActive = false; return manejo })
        this.manejosSelecionadosAreas = []
      }
    }
  }
}
