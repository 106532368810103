import { Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import * as moment from 'moment'
import { IOptionButtons } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { IAnimal } from 'src/app/utils/interfaces/animais.interface'
import {
  IConta,
  IFinancasCompraEVenda,
  IDismissPropsPessoaSelecionada,
  Pessoa
} from 'src/app/utils/interfaces/financas.interface'
import { ColunasTabela, ConfigTabela, IKpisCard } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalListaAnimaisPage } from '../../bovinos/modal-lista-animais/modal-lista-animais.page'
import { ModalListaLotesPage } from '../../bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { FinancasService } from 'src/app/services/financas.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { AnimaisService } from 'src/app/services/animais.service'
import { ModalListaPessoasPage } from '../modal-lista-pessoas/modal-lista-pessoas.page'
import { Browser } from '@capacitor/browser'
import { BovinoSingleComponent } from '../../bovinos/bovino-single/bovino-single.page'
import { RelatoriosService } from 'src/app/services/relatorios.service'
import { TabelaService } from 'src/app/services/tabela.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { ColunasRelatorioCustosELucratividade } from 'src/app/utils/configuracoes-padrao'
import { IRelatorioCustoAnimais } from 'src/app/utils/interfaces/relatorios.interface'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { ModalFinanceiroContasPage } from '../modal-financeiro-contas/modal-financeiro-contas.page'

@Component({
  selector: 'app-modal-compra-venda',
  templateUrl: './modal-compra-venda.component.html',
  styleUrls: ['./modal-compra-venda.component.scss']
})
export class ModalCompraVendaComponent implements OnInit {
  public tipoTransacao: 'compra' | 'venda'
  public abaAtiva = 'Informações'
  public carregando = true

  private animaisProp: IAnimal[] = []
  public dadosTabelaAnimais: IAnimal[] = []
  public isAlgumAnimalSelecionadoSemPeso = false
  public configTabela: ConfigTabela = {
    exibeTotais: false,
    excluirColunas: [
      'checkbox',
      'meta_nbrinco',
      'nome',
      'meta_partos',
      'data_manejo_lote',
      'situacao',
      'situacao_produtiva',
      'situacao_reprodutiva',
      'situacao_sanitaria',
      'meta_cria_ao_pe',
      'observacao'
    ]
  }
  public statusTabela: StatusTabela = 'sucesso'

  public optionsButtonsAnimais: IOptionButtons[] = [
    {
      chave: 'excluirAnimal',
      icone: 'trash',
      titulo: 'Remover Animal da Transação',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        if (this.animaisDeOutraFazenda) {
          this.utilsCtrl.showToast('Não é possível remover animais de outra fazenda da transação', 'bottom')
          return
        }
        this.excluirAnimal(linha)
      }
    },
    {
      chave: 'verAnimal',
      icone: 'eye',
      titulo: 'Ver ficha do Animal',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        if (this.animaisDeOutraFazenda) {
          this.utilsCtrl.showToast('Não é possível ver a ficha de animais de outra fazenda', 'bottom')
          return
        }
        this.irParaFichaDoAnimal(linha)
      }
    }
  ]

  public optionsKpisCard: IKpisCard[] = [
    { key: 'animais', titulo: 'Animais', formatacao: 'number', escondeSeVazio: false },
    { key: 'peso_animais', titulo: 'Peso Vivo Total em Kg', unidade: 'kg', formatacao: 'number' },
    { key: 'peso_animais_arroba', titulo: 'Peso Vivo Total em @', formatacao: 'number', unidade: '@' },
    { key: 'peso_animal', titulo: 'Peso em Kg/Animal', unidade: 'kg', formatacao: 'number' },
    { key: 'arroba_animal', titulo: '@/Animal', formatacao: 'number', unidade: '@' },
    { key: 'peso_morto', titulo: 'Peso Morto Total em Kg', unidade: 'kg', escondeSeVazio: true },
    { key: 'arroba_morto', titulo: 'Peso Morto Total em @', formatacao: 'number', unidade: '@', escondeSeVazio: true },
    { key: 'peso_morto_animal', titulo: 'Peso Morto em Kg/Animal', unidade: 'kg', formatacao: 'number', escondeSeVazio: true },
    { key: 'peso_morto_animal_arroba', titulo: 'Peso Morto em @/Animal', formatacao: 'number', unidade: '@', escondeSeVazio: true },
    { key: 'rendimento_carcaca', titulo: 'Rendimento de Carcaça', unidade: '%', formatacao: 'number', escondeSeVazio: true },
    { key: 'gmd_lote', titulo: 'GMD do Lote', formatacao: 'gmd', unidade: 'kg', escondeSeVazio: true }
  ]

  public optionsKpisCardCustoELucratividade: IKpisCard[] = [
    { key: 'custo_total', titulo: 'Custo Total', formatacao: 'currency', escondeSeVazio: false },
    { key: 'custo_compra', titulo: 'Custo de Compra Total', formatacao: 'currency', escondeSeVazio: true },
    { key: 'custo_nutricional', titulo: 'Custo Nutricional Total', formatacao: 'currency', escondeSeVazio: true },
    { key: 'custo_sanitario', titulo: 'Custo Sanitário Total', formatacao: 'currency', escondeSeVazio: true },
    { key: 'custo_atribuido', titulo: 'Custo Atribuído Total', formatacao: 'currency', escondeSeVazio: false },
    { key: 'machos', titulo: 'Machos', formatacao: 'number', escondeSeVazio: true },
    { key: 'femeas', titulo: 'Fêmeas', formatacao: 'number', escondeSeVazio: true }
  ]

  public totais = {
    animais: 0,
    peso_animal: 0,
    arroba_animal: 0,
    peso_morto: 0,
    arroba_morto: 0,
    rendimento_carcaca: 0,
    peso_morto_animal: 0,
    peso_morto_animal_arroba: 0,
    peso_animais: 0,
    peso_animais_arroba: 0,
    gmd_lote: 0
  }

  public totaisCusto = {
    custo_total: 0,
    custo_nutricional: 0,
    custo_sanitario: 0,
    custo_atribuido: 0,
    custo_compra: 0,
    machos: 0,
    femeas: 0
  }

  public contas: IConta[] = []
  public contasPorHash: { [key: string]: IConta } = {}

  public dadosTransacao: IFinancasCompraEVenda = {
    hash_conta: '',
    tipo_repeticao: '',
    tipo: 'entrada',
    data: moment().format('YYYY-MM-DD'),
    valor: '',
    descricao: '',
    titulo: '',
    meta_pessoas: '',
    meta_nf: '',
    meta_possuiGTA: false,
    meta_pesoVivo: null,
    meta_valorArroba: null,
    meta_unidadeValorVenda: 'kg',
    meta_frete: '',
    meta_valorTransacao: null,
    meta_GTAnumero: '',
    meta_GTAserie: '',
    meta_GTAuf: '',
    meta_GTAdataDeEmissao: '',
    meta_GTAdataDeValidade: '',
    meta_GTAdataDeSaida: '',
    meta_categorias: '',
    meta_pesoMorto: null,
    meta_rendimentoCarcaca: null,
    meta_tipoSaida: '',
    meta_hashLote: '',
    meta_pagoPeloComprador: true,
    meta_abatimentos: '',
    meta_acrescimos: '',
    meta_comissoes: '',
    meta_valorKg: '',
    meta_valorUnidade: '',
    meta_arquivar_animais: 1,
    extra_pessoa: '',
    extra_hash_lote_label: '',
    extra_qtd_animais: '',
    extra_conta: '',
    extra_valorUnitario: null,
    realizado: 0,
  }
  public valorTipoUnidade = null
  public copyDadosTransacao = null

  public dadosTransacaoProp: IFinancasCompraEVenda = null

  public arrobaPorPesoVivo = ''
  public arrobaPorPesoMorto = ''

  public enviando = false

  public pessoas: Pessoa[]
  public pessoaSelecionada: Partial<Pessoa>

  public listaPdf: { name: string, url: string, chaveFinanceiro: string }[] = []

  public labels = ColunasRelatorioCustosELucratividade
  public config: ConfigTabela = {
    toolbar: true,
    filtrosEspecificos: true,
    impressaoTitulo: 'Relatório de Custo e Lucratividade',
    buscaDados: async () => {
      const [response, error] = await this.relatoriosCtrl.custosELucratividade()
      return {
        dados: response as unknown as unknown[]
      }
    },
    ativarDownload: true,
    ativarImpressao: true,
    selecionavel: true,
    excluirColunas: ['hash_lote'],
    exibeTotais: false
  }
  public valorArroba: number = 0
  public listaFiltrada: Record<string, string | number | boolean>[] = []
  public dadosCustos: IRelatorioCustoAnimais[] = []
  public carregamentoDadosDesmames: StatusTabela = 'carregando'

  public novoFiltro: NovoFiltro = {}
  public novaOrdenacao: Ordenacao = { coluna: 'custo_total', direcao: 'desc' }

  animaisDeOutraFazenda = false

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private financasCtrl: FinancasService,
    private utilsCtrl: IonicUtilsService,
    private animaisCtrl: AnimaisService,
    private relatoriosCtrl: RelatoriosService,
    private tabelaCtrl: TabelaService,
    public propriedadesCtrl: PropriedadesService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.animaisProp && this.animaisProp.length) {
      this.dadosTabelaAnimais = JSON.parse(JSON.stringify(this.animaisProp))
      this.calcularKpiCardsAnimais()
      this.carregando = false
    }

    this.copyDadosTransacao = JSON.parse(JSON.stringify(this.dadosTransacao))
    if (this.dadosTransacaoProp) {
      if (this.dadosTransacaoProp?.data) this.preencherDadosTransacao(this.dadosTransacaoProp)

      if (this.tipoTransacao === 'compra') {
        await this.getDetalhesCompra(this.dadosTransacaoProp.hash)
      } else {
        await this.getDetalhesVendas(this.dadosTransacaoProp.hash)
      }
    } else {
      this.carregando = false
      if (this.tipoTransacao === 'venda') {
        this.dadosTransacao.meta_tipoSaida = 'abate'
        this.dadosTransacao.titulo = 'Venda de Animais'
      } else {
        this.dadosTransacao.titulo = 'Compra de Animais'
      }
    }

    this.animaisDeOutraFazenda = this.dadosTransacaoProp?.id_propriedade && this.propriedadesCtrl?.propriedadeSelecionada?.id !== this.dadosTransacaoProp?.id_propriedade

    this.preencherListaPdf()

    if (!this.contas || !this.contas.length) {
      this.getContas()
    } else {
      this.contas.map((c) => (this.contasPorHash[c.hash] = c))
    }
    if (!this.pessoas || !this.pessoas.length) {
      this.getPessoas()
    }

    this.verificarSeHaAlgumAnimalSelecionadoSemPeso()

    const controleNomeNumero = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais
    const exibirNBrinco = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.mostra_brinco

    if (!exibirNBrinco) {
      this.config.excluirColunas.push('meta_nbrinco')
    }

    if (controleNomeNumero == 'numero') {
      this.config.excluirColunas.push('nome')
    }

    if (controleNomeNumero == 'nome') {
      this.config.excluirColunas.push('numeracao')
    }
  }

  verificarSeHaAlgumAnimalSelecionadoSemPeso(): void {
    this.isAlgumAnimalSelecionadoSemPeso = this.dadosTabelaAnimais.some((animal) => !animal.pesagem_peso)
  }

  async preencherDadosTransacao(dados: IFinancasCompraEVenda): Promise<void> {
    this.dadosTransacao = { ...dados }
    this.pessoaSelecionada = {
      nome: dados?.extra_pessoa,
      hash: dados?.meta_pessoas
    }
    this.dadosTransacao.hash_conta = dados?.hash_conta

    this.valorTipoUnidade = {
      'arroba': this.dadosTransacao.meta_valorArroba,
      'kg': this.dadosTransacao.meta_valorKg,
      'unidade': this.dadosTransacao.meta_valorUnidade,
    }[this.dadosTransacao.meta_unidadeValorVenda || 'arroba']

    this.calcularKpiCardsAnimais()
    // this.calcularValoresDaTransacao()
  }

  async getPessoas(): Promise<void> {
    const [sucesso, erro] = await this.financasCtrl.getPessoas()
    if (sucesso) {
      this.pessoas = sucesso.centros
    }

    if (erro) {
      console.error(erro)
    }
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss({ pessoas: this.pessoas })
  }

  async showModalSelecionarLote(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaLotesPage,
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.lotes) {
      const [lote] = data.lotes
    }
  }

  async showModalListaAnimais(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: {
        somenteInternos: true,
        selecionarMultiplos: true,
        hashNotIn: this.dadosTabelaAnimais.map((a) => a.hash),
        permitirCadastro: true
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      if (data.hashs?.length) {
        const [response, error] = await this.animaisCtrl.getAnimais({
          filtros: { hash_in: data.hashs },
          paginate: { limit: 5000, offset: 0 }
        })

        if (response) {
          this.dadosTabelaAnimais = [...this.dadosTabelaAnimais, ...response.dados]
        }

        if (error) {
          console.log(error)
        }
      } else {
        this.dadosTabelaAnimais = [...this.dadosTabelaAnimais, ...(data.animais || [data])]
      }

      this.verificarSeHaAlgumAnimalSelecionadoSemPeso()
      this.calcularKpiCardsAnimais()
    }
  }

  excluirAnimal({ original }: DadosLinhaTabela): void {
    this.dadosTabelaAnimais = this.dadosTabelaAnimais.filter((animal) => animal.hash !== original.hash)

    this.calcularKpiCardsAnimais()
  }

  calcularKpiCardsAnimais(): void {
    const pesoTotalAnimaisSelecionados = this.dadosTabelaAnimais.reduce((acc, item) => {
      return (acc += parseFloat(item.pesagem_peso as string) || 0)
    }, 0)

    const pesoTotalAnimais = Number(this.dadosTransacao.meta_pesoVivo || pesoTotalAnimaisSelecionados || 0)

    const gmdLote = this.dadosTabelaAnimais.reduce((acc, item) => {
      return (acc += parseFloat(item.pesagem_gmd as string) / this.dadosTabelaAnimais.length || 0)
    }, 0)

    this.totais.animais = this.dadosTabelaAnimais.length
    this.totais.arroba_animal = pesoTotalAnimais / (this.dadosTabelaAnimais.length || 1) / 30
    this.totais.peso_animal = pesoTotalAnimais / (this.dadosTabelaAnimais.length || 1)

    this.totais.peso_animais = pesoTotalAnimais
    this.totais.peso_animais_arroba = pesoTotalAnimais / 30
    this.totais.gmd_lote = gmdLote

    this.calcularArrobaPorPeso()

    if (pesoTotalAnimais && !this.dadosTransacao.meta_pesoVivo) {
      this.alertPreencherPesoTotal(pesoTotalAnimais)
    }
  }

  calcularArrobaPorPeso(): void {
    const arroba = (Number(this.dadosTransacao.meta_pesoVivo || 0) / 30).toFixed(2)
    this.arrobaPorPesoVivo = String(arroba)

    if (this.dadosTransacao.meta_tipoSaida === 'abate') {
      const arroba = (Number(this.dadosTransacao.meta_pesoMorto || 0) / 15).toFixed(2)
      this.arrobaPorPesoMorto = String(arroba)
    } else {
      this.arrobaPorPesoMorto = '0'
    }

    const rendimentoCarcaca =
      (Number(this.dadosTransacao.meta_pesoMorto) / Number(this.dadosTransacao.meta_pesoVivo)) * 100

    this.dadosTransacao.meta_rendimentoCarcaca = parseFloat(rendimentoCarcaca.toFixed(2))

    this.totais.rendimento_carcaca = this.dadosTransacao.meta_rendimentoCarcaca
    this.totais.arroba_morto = Number(this.arrobaPorPesoMorto)
    this.totais.peso_morto = Number(this.dadosTransacao.meta_pesoMorto)
    this.totais.peso_morto_animal_arroba = Number(this.arrobaPorPesoMorto) / (this.dadosTabelaAnimais.length || 1)
    this.totais.peso_morto_animal = Number(this.dadosTransacao.meta_pesoMorto) / (this.dadosTabelaAnimais.length || 1)
  }

  calcularValoresDaTransacao(): void {
    let valor = 0

    if (this.dadosTransacao.meta_tipoSaida === 'boi-vivo') {
      this.dadosTransacao.meta_pesoMorto = null
    }

    if (this.dadosTransacao.meta_unidadeValorVenda === 'arroba') {
      this.dadosTransacao.meta_valorArroba = this.valorTipoUnidade
      if (this.tipoTransacao === 'venda' && this.dadosTransacao.meta_tipoSaida === 'abate') {
        valor = Number(this.arrobaPorPesoMorto) * Number(this.dadosTransacao.meta_valorArroba)
      } else {
        valor = Number(this.arrobaPorPesoVivo) * Number(this.dadosTransacao.meta_valorArroba)
      }
    } else if (this.dadosTransacao.meta_unidadeValorVenda === 'kg') {
      this.dadosTransacao.meta_valorKg = this.valorTipoUnidade
      if (this.tipoTransacao === 'venda' && this.dadosTransacao.meta_tipoSaida === 'abate') {
        valor = Number(this.dadosTransacao.meta_pesoMorto) * Number(this.dadosTransacao.meta_valorKg)
      } else {
        valor = Number(this.dadosTransacao.meta_pesoVivo) * Number(this.dadosTransacao.meta_valorKg)
      }
    } else if (this.dadosTransacao.meta_unidadeValorVenda === 'unidade' && this.dadosTabelaAnimais.length) {
      this.dadosTransacao.meta_valorUnidade = this.valorTipoUnidade
      valor = Number(this.dadosTabelaAnimais.length) * Number(this.dadosTransacao.meta_valorUnidade)
    }
    if (valor && !isNaN(valor)) {
      this.dadosTransacao.meta_valorTransacao = Number(valor.toFixed(2))
    }

    if (this.dadosTransacao.meta_frete) {
      if (this.tipoTransacao === 'compra') {
        const valorTransacao = Number(this.dadosTransacao.meta_frete) + valor
        this.dadosTransacao.valor = parseFloat(valorTransacao.toFixed(2))
      } else {
        const valorTransacao = this.dadosTransacao.meta_pagoPeloComprador
          ? valor
          : valor - Number(this.dadosTransacao.meta_frete)
        this.dadosTransacao.valor = parseFloat(valorTransacao.toFixed(2))
      }
    } else {
      this.dadosTransacao.valor = parseFloat(String(this.dadosTransacao.meta_valorTransacao))
    }

    if (this.dadosTransacao.meta_abatimentos) {
      const valorTransacao =
        Number(this.dadosTransacao.valor) - Number(this.dadosTransacao.meta_abatimentos)
      this.dadosTransacao.valor = parseFloat(valorTransacao.toFixed(2))
    }

    if (this.dadosTransacao.meta_acrescimos) {
      const valorTransacao =
        Number(this.dadosTransacao.valor) + Number(this.dadosTransacao.meta_acrescimos)
      this.dadosTransacao.valor = parseFloat(valorTransacao.toFixed(2))
    }

    if (this.dadosTransacao.meta_comissoes) {
      if (this.tipoTransacao === 'compra') {
        const valorTransacao =
          Number(this.dadosTransacao.valor) + Number(this.dadosTransacao.meta_comissoes)
        this.dadosTransacao.valor = parseFloat(valorTransacao.toFixed(2))
      }
      if (this.tipoTransacao === 'venda') {
        const valorTransacao =
          Number(this.dadosTransacao.valor) - Number(this.dadosTransacao.meta_comissoes)
        this.dadosTransacao.valor = parseFloat(valorTransacao.toFixed(2))
      }
    }

    this.calcularKpiCardsAnimais()
    this.calcularValor()
  }

  async confirmar(): Promise<void> {
    this.enviando = true

    const hashsAnimais = this.dadosTabelaAnimais?.map((item) => item.hash)

    const payload = {
      transacao: {
        ...this.dadosTransacao,
        valor: parseFloat(this.dadosTransacao.valor.toString()),
        tipo: this.tipoTransacao === 'compra' ? 'saida' : 'entrada',
        meta_categorias:
          this.tipoTransacao === 'compra'
            ? 'despesas_investimentos_bovino_bovinos'
            : 'receitas_pecuarias_venda_de_animal',
        realizado: this.dadosTransacao.realizado
      } as IFinancasCompraEVenda,
      hashsAnimais
    }

    const [response, error] = await this.financasCtrl.setCompraEVenda(payload)

    this.enviando = false

    if (response) {
      this.utilsCtrl.showToast(
        `${this.tipoTransacao === 'compra' ? 'Compra' : 'Venda'} cadastrada com sucesso!`,
        'bottom'
      )
      this.modalCtrl.dismiss({ success: true, pessoas: this.pessoas })
      console.log(response)
    }

    if (error) {
      this.utilsCtrl.showToast(`Erro ao cadastrar ${this.tipoTransacao === 'compra' ? 'Compra' : 'Venda'}`, 'bottom')
      console.log(error)
    }
  }

  async alertPreencherPesoTotal(peso: number): Promise<void> {
    if (peso === 0 || this.dadosTransacaoProp) return
    const alert = await this.alertCtrl.create({
      header: `Prencher o peso total ${peso}kg?`,
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Preencher',
          handler: (): void => {
            this.dadosTransacao.meta_pesoVivo = parseFloat(peso.toFixed(2))
            this.calcularKpiCardsAnimais()
          }
        }
      ]
    })

    await alert.present()
  }

  async abrirListaDeContas(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalFinanceiroContasPage,
      cssClass: 'modal-large'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()

    if (data) {
      const { conta } = data as { conta?: IConta }

      if (conta) {
        this.contas[conta.hash] = conta
        this.contasPorHash[conta.hash] = conta
        this.dadosTransacao.hash_conta = conta.hash
      }
    }
  }

  async getContas(): Promise<void> {
    const [response, erro] = await this.financasCtrl.getContas()

    if (response) {
      this.contas = response.contas
      this.contas.map((c) => (this.contasPorHash[c.hash] = c))
    }

    if (erro) {
      console.error(erro)
    }
  }

  async showAlertSair(): Promise<void> {
    let formEdited = false
    for (let [key, value] of Object.entries(this.copyDadosTransacao)) {
      if (value !== this.dadosTransacao[key]) {
        formEdited = true
        break
      }
    }

    const alert = await this.alertCtrl.create({
      header: 'Deseja cancelar a edição?',
      message: 'Informações alteradas e não salvas serão perdidas.',
      buttons: [
        {
          text: 'Não'
        },
        {
          text: 'Sim',
          handler: (): void => {
            this.closeModal()
          }
        }
      ]
    })

    if (formEdited) {
      await alert.present()
    } else {
      this.closeModal()
    }
  }

  async abrirModalListaPessoas(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaPessoasPage,
      componentProps: {
        listaPessoas: this.pessoas
      },
      backdropDismiss: false,
      cssClass: 'custom-modal-seletor'
    })

    modal.present()

    const { data } = await modal.onDidDismiss()

    if (data) {
      const propsPessoa = data as IDismissPropsPessoaSelecionada

      if (propsPessoa?.pessoaSelecionada) {
        this.pessoaSelecionada = propsPessoa.pessoaSelecionada
        this.dadosTransacao.meta_pessoas = propsPessoa.pessoaSelecionada.hash
      }

      if (propsPessoa?.isPessoaNovaCadastrada) {
        this.pessoas = propsPessoa.listaPessoasAtualizada
      }
    }
  }

  async getDetalhesCompra(hash: string): Promise<void> {
    const [response, error] = await this.financasCtrl.getDetalhesCompras(encodeURIComponent(hash))

    if (response) {
      this.carregando = false
      this.preencherDadosTransacao(response.lancamento)
      if (response.animais.length) {
        this.dadosTabelaAnimais = response.animais
        this.calcularKpiCardsAnimais()
      }
    }

    if (error) {
      console.log(error)
    }
  }

  async getDetalhesVendas(hash: string): Promise<void> {
    const [response, error] = await this.financasCtrl.getDetalhesVendas(hash)

    if (response) {
      this.carregando = false
      this.preencherDadosTransacao(response.lancamento)
      if (response.animais.length) {
        this.dadosTabelaAnimais = response.animais
        this.calcularKpiCardsAnimais()
        this.changeTab()
      }
    }

    if (error) {
      console.log(error)
    }
  }

  limparCampoConta(): void {
    this.dadosTransacao.hash_conta = ''
  }

  verificarCamposObrigatorios(): boolean {
    if (this.tipoTransacao === 'compra') {
      return (
        !this.dadosTransacao.hash_conta ||
        !this.dadosTransacao.data ||
        (this.dadosTransacao.meta_unidadeValorVenda == 'unidade' && !this.dadosTransacao.meta_valorUnidade) ||
        (this.dadosTransacao.meta_unidadeValorVenda == 'arroba' && !this.dadosTransacao.meta_valorArroba) ||
        (this.dadosTransacao.meta_unidadeValorVenda == 'kg' && !this.dadosTransacao.meta_valorKg) ||
        !this.dadosTabelaAnimais?.length
      )
    } else {
      return (
        !this.dadosTransacao.hash_conta ||
        !this.dadosTransacao.data ||
        (this.dadosTransacao.meta_unidadeValorVenda !== 'unidade' && !this.dadosTransacao.meta_pesoVivo) ||
        !this.dadosTransacao.meta_tipoSaida ||
        !this.dadosTabelaAnimais?.length ||
        (this.dadosTransacao.meta_unidadeValorVenda == 'unidade' && !this.dadosTransacao.meta_valorUnidade) ||
        (this.dadosTransacao.meta_unidadeValorVenda == 'arroba' && !this.dadosTransacao.meta_valorArroba) ||
        (this.dadosTransacao.meta_unidadeValorVenda == 'kg' && !this.dadosTransacao.meta_valorKg) ||
        (this.dadosTransacao.meta_tipoSaida == 'abate' &&
          this.dadosTransacao.meta_unidadeValorVenda !== 'unidade' &&
          !this.dadosTransacao.meta_pesoMorto)
      )
    }
  }

  async getPdf(pdfSrc: { base64: string, name: string }): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circles', 'Carregando PDF')
    const tituloComTimestamp = `${pdfSrc.name.replace('.pdf', '')}_${new Date().getTime()}.pdf`
    const [response, error] = await this.financasCtrl.enviarPdf({ tipo: 'financeiro', pdf: pdfSrc.base64, titulo: tituloComTimestamp })

    if (error) {
      this.utilsCtrl.showToast('Erro ao carregar PDF', 'bottom')
      return
    }

    this.listaPdf = [...this.listaPdf]
    this.listaPdf.push({
      chaveFinanceiro: `meta_pdf_${this.listaPdf.length + 1}`,
      name: pdfSrc.name,
      url: response.url
    })

    this.listaPdf.forEach((pdf) => {
      this.dadosTransacao[pdf.chaveFinanceiro] = pdf.url
    })

    this.utilsCtrl.dismissLoading(loading)
  }

  viewPdf(url: string): void {
    Browser.open({ url, presentationStyle: 'popover' })
  }

  removerPdf(chave: string): void {
    this.listaPdf = this.listaPdf.filter((pdf) => pdf.chaveFinanceiro !== chave)
    this.dadosTransacao[chave] = ''
  }

  preencherListaPdf(): void { 
    const metasPdf = ['meta_pdf_1', 'meta_pdf_2', 'meta_pdf_3', 'meta_pdf_4', 'meta_pdf_5', 'meta_pdf_6', 'meta_pdf_7', 'meta_pdf_8', 'meta_pdf_9', 'meta_pdf_10']

    metasPdf.forEach((meta) => {
      if (this.dadosTransacao[meta]) {
        let pdfName = this.dadosTransacao[meta].split('/').pop().replace('.pdf.pdf', '.pdf')
        pdfName = (pdfName.split('.pdf__?')[0]) + '.pdf'
        const regexPattern = /^\d+_\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}_\d+__/
        pdfName = pdfName.replace(regexPattern, '')
        pdfName = decodeURIComponent(pdfName)
        this.listaPdf.push({ name: pdfName, url: this.dadosTransacao[meta], chaveFinanceiro: meta })
      }
    })
  }

  async irParaFichaDoAnimal({ original }: DadosLinhaTabela): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: BovinoSingleComponent,
      componentProps: { isModal: true, animalProp: original },
      cssClass: 'modal-fullScreen'
    })

    await modal.present()
  }

  async getDadosCustosELucratividade(): Promise<void> {
    const hashsAnimais = this.dadosTabelaAnimais?.map((item) => item.hash)
    if (!hashsAnimais?.length) {
      this.carregamentoDadosDesmames = 'sucesso'
      return
    }
        
    this.carregamentoDadosDesmames = 'carregando'
    const [response, error] = await this.relatoriosCtrl.custosELucratividade({hashs: hashsAnimais})

    if (response) {
      this.dadosCustos = response.dados
      this.processaFiltroLocal()

      let custo_total = 0
      let custo_nutricional = 0
      let custo_sanitario = 0
      let custo_atribuido = 0
      let custo_compra = 0
  
      this.listaFiltrada.map(a => {
        custo_total += Number(a.custo_total)
        custo_compra += Number(a.custo_compra)
        custo_nutricional += Number(a.custo_nutricional)
        custo_sanitario += Number(a.custo_sanitario)
        custo_atribuido += Number(a.custo_atribuido)
      })

      this.totaisCusto = {
        custo_total,
        custo_nutricional,
        custo_atribuido,
        custo_sanitario,
        custo_compra,
        machos: this.listaFiltrada.filter(a => a.sexo == 'macho').length,
        femeas: this.listaFiltrada.filter(a => a.sexo == 'femea').length,
      }
      this.config.impressaoKpiCardConfig = this.optionsKpisCard
      this.config.impressaoKpiValores = this.totais
      this.carregamentoDadosDesmames = 'sucesso'
    }

    this.calcularValor()

    if (error) {
      this.carregamentoDadosDesmames = 'erro'
      console.log(error)
    }
  }

  processaFiltroLocal(): void {
    // if (this.tabActive === 'Exposições') {
    this.listaFiltrada = this.filtroLocal(
      this.labels,
      this.dadosCustos as unknown as Record<string, string | number | boolean>[],
      this.novoFiltro,
      this.novaOrdenacao
    )

    const totaisFiltrados = {
      custo_total: 0,
      custo_nutricional: 0,
      custo_sanitario: 0,
      custo_atribuido: 0,
      custo_compra: 0,
    }

    this.listaFiltrada.forEach(animal => {
      totaisFiltrados['custo_total'] += Number(animal.custo_total)
      totaisFiltrados['custo_nutricional'] += Number(animal.custo_nutricional)
      totaisFiltrados['custo_sanitario'] += Number(animal.custo_sanitario)
      totaisFiltrados['custo_atribuido'] += Number(animal.custo_atribuido)
      totaisFiltrados['custo_compra'] += Number(animal.custo_compra)
    })

    this.totaisCusto = {
      ...totaisFiltrados,
      machos: this.listaFiltrada.filter(a => a.sexo == 'macho').length,
      femeas: this.listaFiltrada.filter(a => a.sexo == 'femea').length,
    }

  }

  filtroLocal(
    colunas: ColunasTabela[],
    dados: Record<string, string | number | boolean>[],
    filtros: NovoFiltro,
    ordenacao: Ordenacao
  ): Record<string, string | number | boolean>[] {
    const filtrosAplicar = JSON.parse(JSON.stringify(filtros))
    if (filtrosAplicar.extra_lote) {
      filtrosAplicar.hash_lote = filtrosAplicar.extra_lote
      delete filtrosAplicar.extra_lote
    }
    const dadosFiltrados = this.tabelaCtrl.filtrarDados(colunas, dados, filtrosAplicar)
    const dadosOrdenados = this.tabelaCtrl.ordenarDados(dadosFiltrados, ordenacao)
    return dadosOrdenados
  }

  calcularValor(): void {
    this.dadosCustos.map(a => {
      a.valor_venda = Number(this.dadosTransacao.meta_valorTransacao)/ this.dadosTabelaAnimais.length
      a.lucro_ou_prejuizo = a.valor_venda - a.custo_total
      a.icone_lucro_prejuizo = a.lucro_ou_prejuizo > 0 ? 'entrada' : 'saida'
    })

    this.processaFiltroLocal()
  }

  changeTab(): void {
    if (this.abaAtiva == 'Custos e Lucratividade') {
      this.getDadosCustosELucratividade()
    }
    if (this.abaAtiva == 'Informações') {
      document.querySelectorAll('ion-modal.modal-compra-venda').forEach(a => {
        a.classList.remove('modal-venda')
      })
    } else {
      document.querySelectorAll('ion-modal.modal-compra-venda').forEach(a => {
        a.classList.add('modal-venda')
      })
    }
  }

  retirarAnimaisDoRebanho(isMacarcadoParaRetirar: boolean): void {
    this.dadosTransacao.meta_arquivar_animais = isMacarcadoParaRetirar ? 1 : 0
  }

  setRealizado(isMacarcadoComoRecebido: boolean): void {
    this.dadosTransacao.realizado = isMacarcadoComoRecebido ? 1 : 0
  }
}
