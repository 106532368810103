import { Component, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-modal-selecionar-adicionar-cria',
  templateUrl: './modal-selecionar-adicionar-cria.component.html',
  styleUrls: ['./modal-selecionar-adicionar-cria.component.scss']
})
export class ModalSelecionarAdicionarCriaComponent implements OnInit {
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  async selecionarAcao(acao: string): Promise<void> {
    await this.popoverCtrl.dismiss(acao)
  }
}
