import { Component, OnDestroy, OnInit } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import { Product } from 'capacitor-subscriptions'
import { OpcoesBotoesComponent } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { InAppPurchasesService } from 'src/app/services/in-app-purchases.service'
import { PermissoesUsuarioService } from 'src/app/services/permissoes-usuario.service'
import { PlanosAssinaturaService } from 'src/app/services/plano-assinatura.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { UsuariosService } from 'src/app/services/usuarios.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { PlanoAssinatura } from 'src/app/utils/interfaces/plano-assinatura.interface'
import { InfoLimitePlano } from 'src/app/utils/interfaces/Usuarios.interface'

const VANTAGENS_PLANO = [
  'Custo da Arroba Produzida',
  'Conexão com Balanças e Bastões',
  'Controle Financeiro',
  'Manejos Reprodutivos Completos',
  'Manejos Sanitários Completos',
  'Manejo Nutricional',
  'Estoque Nutricional',
  'Relatórios',
  'Usuários Ilimitados',
  'Aplicativo Fertili Campo',
  'Exportação e Impressão',
  'Pluviometria',
  'Calendário de Tarefas',
  'Coleta de Melhoramento Genético',
]

@Component({
  selector: 'app-modal-assine-agora',
  templateUrl: './modal-assine-agora.page.html',
  styleUrls: ['./modal-assine-agora.page.scss']
})
export class ModalAssineAgoraPage implements OnInit, OnDestroy {
  public isCarregandoPlanos = true

  public titulosDosPlanosDisponiveis: { chave: string, titulo: string }[]
  public tituloDoPlanoSelecionado: string

  public planosAssinatura: PlanoAssinatura[]
  public planoSelecionado: PlanoAssinatura

  public tituloMaisDeDezMil: string
  public mensagemDesconto: string
  public mensagemAjuda: string
  public mensagemPlanoCustom: string

  private rxAssinatura = null

  public vantagensPlano = VANTAGENS_PLANO

  public planoPeriodo: 'mensal' | 'anual' = 'anual'

  public informacoesDoPlanoUsuario: InfoLimitePlano

  isIOS = Capacitor.getPlatform() === 'ios'
  productIOS: Product = {
    productIdentifier: '',
    price: '',
    displayName: '',
    description: ''
  }

  constructor(
    private modalCtrl: ModalController,
    private planosAssinaturaCtrl: PlanosAssinaturaService,
    private utilsCtrl: IonicUtilsService,
    private planoBasicoCtrl: PlanoBasicoService,
    private popoverCtrl: PopoverController,
    private propriedadesCtrl: PropriedadesService,
    private permissoesUsuarioCtrl: PermissoesUsuarioService,
    private usuarioCtrl: UsuariosService,
    private inAppPurchasesCtrl: InAppPurchasesService
  ) { }

  async ngOnInit(): Promise<void> {
    if(this.isIOS) {
      // get preço ios
      this.productIOS = await this.inAppPurchasesCtrl.getPreco()
    }

    this.getInformacoesQuantidadeDeAnimaisPropriedade()
    await this.getPlanosAssinatura()

    if (this.planosAssinatura) {
      this.getTitulosDosPlanosDisponiveis()
    }

    // this.rxAssinatura = VastaRX.getState('firestore_assinatura_update', async (value) => {
    //   if (value?.length && this.updateNotificationService.dadosUsuarioNotification.assinatura !== value) {
    //     this.fecharModal()
    //     await this.usuarioCtrl.getUsuarioSingleEAtualizaUsuarioCache()
    //     this.menuCtrl.enable(true)
    //     this.router.navigate(['/'])
    //   }
    // })
  }

  ngOnDestroy(): void {
    VastaRX.unListen(this.rxAssinatura)
  }

  async getPlanosAssinatura(): Promise<void> {
    this.isCarregandoPlanos = true

    const [response, erro] = await this.planosAssinaturaCtrl.getPlanosDeAssinatura()

    if (response) {
      const { planos, mensagemAjuda, mensagemDesconto, mensagemPlanoCustom, tituloMaisDe } = response
      this.planosAssinatura = planos
      this.mensagemAjuda = mensagemAjuda
      this.mensagemDesconto = mensagemDesconto
      this.mensagemPlanoCustom = mensagemPlanoCustom
      this.tituloMaisDeDezMil = tituloMaisDe

      if (this.informacoesDoPlanoUsuario) {
        this.planoSelecionado = planos.find((plano) => Number(this.informacoesDoPlanoUsuario.animaisCadastrados) <= plano.qtd_animais && plano.periodo === 'anual')
      } else {
        this.planoSelecionado = planos.find((plano) => plano.periodo === 'anual')
      }
    }

    if (erro) {
      console.error(erro)
    }

    this.isCarregandoPlanos = false
  }

  getTitulosDosPlanosDisponiveis(): void {
    this.titulosDosPlanosDisponiveis = this.planosAssinatura.map((plano) => {
      return {
        chave: plano.slug,
        titulo: plano.nome
      }
    }).filter((plano, index, self) => index === self.findIndex((p) => p.titulo === plano.titulo))
  }

  selecionarTituloDoPlano(titulo: string): void {
    this.tituloDoPlanoSelecionado = titulo
  }

  selecionarPlano(plano: PlanoAssinatura): void {
    this.planoSelecionado = plano
  }

  entrarEmContato(): void {
    this.planosAssinaturaCtrl.entrarEmContatoPorWhatsapp()
  }

  isProntoParaAssinar(): boolean {
    if (this.tituloMaisDeDezMil === this.tituloDoPlanoSelecionado) {
      return false
    }

    if (this.planoSelecionado?.periodo === 'anual') {
      return true
    } else if (this.planoSelecionado?.periodo === 'mensal') {
      return true
    } else {
      return false
    }
  }

  async assinarIOS(): Promise<void> {
    const success = await this.inAppPurchasesCtrl.assinar()
    if(success) {
      await this.usuarioCtrl.getUsuarioSingleEAtualizaUsuarioCache()
      await this.permissoesUsuarioCtrl.verificarPermissoes()
      this.fecharModal()
    }
  }

  async assinarPlano(): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circles')

    const [linkPagamento, erro] = await this.planosAssinaturaCtrl.criarAssinatura(this.planoSelecionado.id)

    if (linkPagamento) {
      console.log('linkPagamento: ', linkPagamento)
      if (location.href.includes('360.fertili.com.br')) {
        location.href = linkPagamento.link
      } else {
        this.planosAssinaturaCtrl.abrirLink(linkPagamento.link)
      }
    }

    if (erro) {
      console.error(erro)
    }

    await this.utilsCtrl.dismissLoading(loading)
  }

  fecharModal(): void {
    const isPlanoBasico = true

    if (!isPlanoBasico) {
      this.planoBasicoCtrl.atualizarClasseCssDePlanoBasicoDoUsuario()
    }

    this.modalCtrl.dismiss({ isPlanoBasico })
  }

  async openPopoverPlanosDisponiveis(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: { options: this.titulosDosPlanosDisponiveis },
      event,
      reference: 'trigger',
      size: 'cover',
      showBackdrop: false,
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data) {
      this.planoSelecionado = this.planosAssinatura.find((plano) => plano.slug === data.chave && plano.periodo === this.planoPeriodo)
    }
  }

  onChangeTogglePeriodo(event: CustomEvent): void {
    this.planoPeriodo = event.detail.checked ? 'anual' : 'mensal'
    if (this.planoSelecionado) {
      this.planoSelecionado = this.planosAssinatura.find((plano) => plano.slug === this.planoSelecionado.slug && plano.periodo === this.planoPeriodo)
    }
  }

  getInformacoesQuantidadeDeAnimaisPropriedade(): void {
    const limiteDePlanoPorPropriedade = this.permissoesUsuarioCtrl.limiteDePlanoPorPropriedade

    this.informacoesDoPlanoUsuario = limiteDePlanoPorPropriedade[this.propriedadesCtrl.propriedadeSelecionada.id]
  }
}
