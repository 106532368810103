<ion-header class="ion-no-border">
  <div class="modal-header">
    <div class="modal-header-title">
      <span>Filtrar</span>
      <div (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </div>
    </div>
  </div>
</ion-header>
<ion-content>
  <ion-list *ngFor="let label of labels">
    <ion-row>
      <ion-col>
        <ion-label>{{ label.titulo || label.chave | titlecase }}</ion-label>
      </ion-col>
      <ion-col>
        <ng-container *ngIf="label.tipoFiltro?.includes('btn') || label.tipoFiltro === 'date'">
          <button class="filter-btn">
            <div
              class="icon"
              [ngClass]="{ filterActive: label.labelFiltro !== 'Filtrar' }"
              (click)="removeFilter(label)"
            >
              <ion-icon [name]="label.labelFiltro === 'Filtrar' ? 'filter' : 'close'"></ion-icon>
            </div>
            <span (click)="label.tipoFiltro === 'date' ? openModalDate(label) : openAlertOptions(label)">
              {{ label.labelFiltro }}
            </span>
          </button>
        </ng-container>
        <ng-container *ngIf="label.tipoFiltro === 'input'">
          <div class="container-input">
            <div
              class="icon"
              [ngClass]="{ filterActive: input.value !== '' || label.filtroAtivo }"
              (click)="removeFilter(label, input.value); input.value = ''"
            >
              <ion-icon [name]="input.value !== '' || label.filtroAtivo ? 'close' : 'filter'"></ion-icon>
            </div>
            <input type="text" placeholder="Filtrar" (input)="getValueInput(label, input.value)" #input />
          </div>
        </ng-container>
        <ng-container *ngIf="label.tipoFiltro === 'input-range'">
          <div class="container-input-range">
            <div
              class="icon"
              [ngClass]="{ filterActive: inputFrom.value !== '' || inputTo.value !== '' }"
              (click)="removeFilter(label, inputFrom + '' + inputTo); inputFrom.value = ''; inputTo.value = ''"
            >
              <ion-icon [name]="inputFrom.value !== '' || inputTo.value !== '' ? 'close' : 'filter'"></ion-icon>
            </div>
            <div>
              <input
                type="text"
                placeholder="De"
                (input)="getValueInput(label, inputFrom.value + ',' + inputTo.value || '')"
                #inputFrom
              />
              <input
                type="text"
                placeholder="Até"
                (input)="getValueInput(label, inputFrom.value + ',' + inputTo.value || '')"
                #inputTo
              />
            </div>
          </div>
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-button size="small" color="danger" (click)="clearFilters()">
    <ion-icon name="trash-outline"></ion-icon>
    <ion-label>Limpar</ion-label>
  </ion-button>
  <ion-button size="small" (click)="submit()" [disabled]="totalFilters <= 2">
    <ion-icon name="checkmark-outline"></ion-icon>
    <ion-label>Aplicar</ion-label>
  </ion-button>
</ion-footer>
