import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { IManejoGeralUnitario } from 'src/app/services/manejos.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IPropriedade } from 'src/app/utils/interfaces/propriedades.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-geral-transferencia-propriedade',
  templateUrl: './manejo-geral-transferencia-propriedade.component.html',
  styleUrls: ['./manejo-geral-transferencia-propriedade.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManejoGeralTransferenciaPropriedadeComponent implements OnInit {
  @Input('dados') dados: IManejoGeralUnitario = {}
  @Input('data') data: string

  @Input('propriedades') propriedades: IPropriedade[] = []
  propriedadesPorId: { [key: number]: IPropriedade } = {}
  propriedadesOpcoes: { value: number; label: string }[] = []

  @Output() propriedadeSelecionadaChange = new EventEmitter<number>()

  @Input('lotesOpcoes') lotesOpcoes: { [key: number]: { value: string; label: string }[] } = {}

  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  constructor(private popoverCtrl: PopoverController, private propriedadesCtrl: PropriedadesService) {}

  ngOnInit(): void {
    this.propriedadesOpcoes = []
    this.propriedades.map((p) => {
      if (p.id !== this.propriedadesCtrl.propriedadeSelecionada.id) {
        this.propriedadesOpcoes.push({
          value: p.id,
          label: p.nome
        })
        this.propriedadesPorId[p.id] = p
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.propriedades?.currentValue) {
      this.propriedadesOpcoes = []

      changes?.propriedades.currentValue.map((p) => {
        if (p.id !== this.propriedadesCtrl.propriedadeSelecionada.id) {
          this.propriedadesOpcoes.push({
            value: p.id,
            label: p.nome
          })
          this.propriedadesPorId[p.id] = p
        }
      })
    }
  }

  emit(): void {
    this.dadosChange.emit(this.dados)
  }

  emitPropriedadeSelecionadaChange(): void {
    this.propriedadeSelecionadaChange.emit(this.dados.transf_propriedade_id)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.transf_propriedade_id = null
      this.dados.novo_status = ''
      this.emit()
    }
  }

  selecionarListaLotes(): void {
    if (!this.lotesOpcoes[this.dados.transf_propriedade_id]?.length) {
      this.emitPropriedadeSelecionadaChange()
    }
  }
}
