import moment from 'moment'
import { Component, OnInit } from '@angular/core'
import { Preferences } from '@capacitor/preferences'
// import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { environment } from 'src/environments/environment'
import { ModalController } from '@ionic/angular'
import { ModalAssineAgoraPage } from './pages/geral/modal-assine-agora/modal-assine-agora.page'
import { ModalListaPropriedadesPage } from './pages/menu-lateral/modal-lista-propriedades/modal-lista-propriedades.page'
import { NavegacaoService } from './services/navegacao.service'
import { PermissoesUsuarioService } from './services/permissoes-usuario.service'
import { PropriedadesService } from './services/propriedades.service'
import { UsuariosService } from './services/usuarios.service'
import { TopBarNomeAcoes } from './utils/interfaces/Usuarios.interface'
import { PlanoBasicoService } from './services/plano-basico.service'
import { LogService } from './services/log.service'
import { PushNotificationsService } from './services/push-notifications.service'
import { Capacitor } from '@capacitor/core'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private usuarioCtrl: UsuariosService,
    private logCtrl: LogService,
    private propriedadesCtrl: PropriedadesService,
    private navegacaoCtrl: NavegacaoService,
    public permissoesUsuarioCtrl: PermissoesUsuarioService,
    private planoBasicoCtrl: PlanoBasicoService,
    private pushNotificationsService: PushNotificationsService,
    private modalCtrl: ModalController
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      // await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig)
    } catch (e) {
      console.log('e', e)
    }
      
    const user = await this.usuarioCtrl.getUsuarioStorage()
    if (user) {
      await this.propriedadesCtrl.iniciaPropriedadeMemoria()
      this.permissoesUsuarioCtrl.inicializarObjetoDePermissoes()
      await this.permissoesUsuarioCtrl.verificarPermissoes()
      this.atualizaDados()
      
      // FirebaseAnalytics.setUserId({
      //   userId: `${user.id}`
      // })

      this.pushNotificationsService.initialize()
    }

    Notification?.requestPermission()

    // FirebaseAnalytics.logEvent({
    //   name: 'fertili360web',
    //   params: {}
    // })

    if (Capacitor.getPlatform() !== 'web') {
      document.body.classList.add('hide-web-options')
    }
  }

  async atualizaDados(): Promise<void> {
    this.propriedadesCtrl.getPropriedades()
    await this.usuarioCtrl.getUsuarioSingleEAtualizaUsuarioCache()
    await this.permissoesUsuarioCtrl.verificarPermissoes()
    this.planoBasicoCtrl.atualizarClasseCssDePlanoBasicoDoUsuario()

    const { value: dataUltimoLog } = await Preferences.get({ key: 'ultimo-log' })
    const dataHoje = moment().format('YYYY/MM/DD')

    if(!dataUltimoLog || dataUltimoLog !== dataHoje ) {
      await Preferences.set({
        key: 'ultimo-log',
        value: dataHoje
      })
      this.logCtrl.enviarLogUsuario('sessao')
    }
  }

  topBarAcaoBotao(topBarAcao: TopBarNomeAcoes): void {
    if (!this.permissoesUsuarioCtrl?.isAcaoTopBarAtiva) {
      this.permissoesUsuarioCtrl.isAcaoTopBarAtiva = true

      if (topBarAcao === 'plano-trial') {
        this.abrirModalDeAssinaturaDePlano()
      }

      if (topBarAcao === 'assinatura-invalida-propriedade-exemplo') {
        this.voltarParaOnboardingBoasVindas()
      }

      if (topBarAcao === 'selecionar-propriedade') {
        this.openModalListaPropriedades()
      }
    }
  }

  async abrirModalDeAssinaturaDePlano(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAssineAgoraPage,
      cssClass: 'custom-modal',
      backdropDismiss: false
    })

    await modal.present()
    await modal.onDidDismiss()
    this.permissoesUsuarioCtrl.isAcaoTopBarAtiva = false
  }

  async voltarParaOnboardingBoasVindas(): Promise<void> {
    this.navegacaoCtrl.set(['/bem-vindo'])
    this.permissoesUsuarioCtrl.resetarValoresParaRetornarABoasVindas()
    document.body.classList.remove('propriedade-exemplo')
    await this.abrirModalDeBoasVindas()
  }

  async abrirModalDeBoasVindas(): Promise<void> {
    console.log('abrirModalDeBoasVindas(): Promise')
    this.navegacaoCtrl.set(['/bem-vindo'])
    this.permissoesUsuarioCtrl.isAcaoTopBarAtiva = false
  }

  async openModalListaPropriedades(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaPropriedadesPage,
      cssClass: 'custom-modal-seletor'
    })

    await modal.present()
    await modal.onDidDismiss()
    this.permissoesUsuarioCtrl.isAcaoTopBarAtiva = false
  }
}
