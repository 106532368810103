export type IGetPropriedades = IPropriedade[]

export interface IPropriedade {
  id?: number
  id_usuario?: number
  nome?: string
  localizacao?: Localizacao
  status?: number
  telefone?: number
  endereco?: string
  bairro?: string
  cep?: string
  cidade?: string
  estado?: string
  pais?: string
  cpf?: string
  cnpj?: string
  ie?: string
  numero_sisbov?: string
  nrf?: string
  incra?: string
  created_at?: string
  updated_at?: string
  logo?: string
  proprietario_animais?: string
  metadados?: Metadados
  meu_role?: 'proprietario' | 'secundario'
  plano_ativo?: string
  proprietario?: string
  plano_status?: string
  plano_vagas?: number
  plano_validade?: string
  plano_limite?: number
  permissoes?: string[]
  propriedade_exemplo?: boolean
}

export interface Localizacao {
  latitude?: string
  longitude?: string
}

export interface Metadados {
  last_sync?: string
  linha_genealogia?: boolean
  conta_principal?: string
  tipo_producao?: string
  controle_animais?: string
  sistema_criacao?: string
  raca_principal?: string
  quantidade?: string
  hora_alertas_calendario?: string
  mostra_brinco?: boolean
  logo_thumb?: string
  logo_url?: string
  tempo_gestacao?: string
  tipo_criacao?: string
  colunas_animais?: string
  tempo_desmame?: string
  participa_qualitas?: boolean
  habilita_manejos_genetica?: boolean
  qualitas_nome_fornecedor?: string
  qualitas_codigo_criador?: string
  qualitas_nome_proprietario?: string
  tempo_ate_vaca_ficar_apta?: string
  periodo_minimo_diagnostico_gestacao?: string
  peso_padrao_ao_nascer?: string
  qualitas_codigo_proprietario?: string
  manejos_reprodutivos_avancados?: boolean
  isolar_financeiro?: boolean
  isolar_estoque?: boolean
  unidade_peso?: string
}

export interface IParamsAdicionarPropriedade {
  nome: string
  endereco: string
  cidade: string
  estado: string
  pais: string
  localizacao: Localizacao
  metadados: Metadados
}

// export interface IParamsAtualizarPropriedade {
//   id: number
//   id_usuario: number
//   nome: string
//   localizacao: Localizacao
//   status: number
//   telefone: number
//   endereco: string
//   bairro?: string
//   cep: number
//   cidade: string
//   estado: string
//   pais: string
//   cpf: string | number
//   cnpj: string | number
//   ie: string | number
//   numero_sisbov: number
//   nrf: string | number
//   incra: string | number
//   created_at: string
//   updated_at: string
//   logo: string | number
//   proprietario_animais: string
//   metadados: Metadados
//   meu_role: string
//   proprietario: string
//   plano_status: string
//   plano_validade: string
//   plano_limite: number
// }

export interface IMetas {
  criar_area: boolean
  criar_lote: boolean
  criar_animal: boolean
  criar_evento: boolean
  criar_propriedade: boolean
}

export const ListaPaginas = [
  'propriedade',
  'areas',
  'pluviometria',
  'financeiro',
  'calendario',
  'relatorios',
  'animais',
  'lotes',
  'manejos',
  'estacoes-reprodutivas',
  'estoque',
  'usuarios',
  'aprovacoes',
  'tutoriais',
  'usuarios_360debolso'
] as const

export type PaginasDisponiveis = typeof ListaPaginas[number]

export const PaginasComRestricoes: PaginasDisponiveis[] = [
  'propriedade',
  'areas',
  'animais',
  'lotes',
  'manejos',
  'financeiro',
  'calendario',
  'estoque',
  'aprovacoes',
  'relatorios',
  'usuarios_360debolso'
]
