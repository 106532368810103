import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ManejosRoutingModule } from './manejos-routing.module'
import { IonicModule } from '@ionic/angular'
import { ComponentsModule } from 'src/app/components/components.module'
import { ImportacaoManejosComponent } from './importacao-manejos/importacao-manejos.component'
import { FormsModule } from '@angular/forms'
import { CadastrarManejos } from './cadastrar-manejos/cadastrar-manejos.component'
import { ManejosDisponiveisComponent } from './manejos-disponiveis/manejos-disponiveis.component'
import { SeletorManejosComponent } from 'src/app/components/manejo-seletor/manejo-seletor.component'
import { ImportacaoPesagemComponent } from './importacao-pesagem/importacao-pesagem.component'
import { PipesModule } from 'src/app/pipes/pipes.module'
import { RelatorioManejosComponent } from './relatorio-manejos/relatorio-manejos.component'

@NgModule({
  declarations: [
    ImportacaoManejosComponent,
    CadastrarManejos,
    ManejosDisponiveisComponent,
    SeletorManejosComponent,
    ImportacaoPesagemComponent,
    RelatorioManejosComponent
  ],
  imports: [CommonModule, FormsModule, ManejosRoutingModule, IonicModule, ComponentsModule, PipesModule]
})
export class ManejosModule { }
