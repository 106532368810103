import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-numpad-pesos',
  templateUrl: './numpad-pesos.component.html',
  styleUrls: ['./numpad-pesos.component.scss']
})
export class NumpadPesosComponent {
  public num: number
  public nome: string
  constructor(private modalCtrl: ModalController) {}

  confirmarPeso(): void {
    this.modalCtrl.dismiss({ peso: this.num })
  }

  modalClose(): void {
    this.modalCtrl.dismiss()
  }
}
