import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { IManejoGeralUnitario } from 'src/app/services/manejos.service'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-geral-numero-controle',
  templateUrl: './manejo-geral-numero-controle.component.html',
  styleUrls: ['./manejo-geral-numero-controle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManejoGeralNumeroControleComponent implements OnInit, OnChanges {
  @Input('dados') dados: IManejoGeralUnitario
  @Input() numerosDeControleJaUtilizados: string[] = []
  @Input() numeroAtual: string

  @Output() dadosChange = new EventEmitter<IManejoGeralUnitario>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  public isNumeroJaCadastrado = false

  constructor(
    private popoverCtrl: PopoverController,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dados) {
      this.verificarSeNumeroJaEstaCadastrado()
    }
  }

  emit(): void {
    this.verificarSeNumeroJaEstaCadastrado()
    this.dadosChange.emit(this.dados)
  }

  verificarSeNumeroJaEstaCadastrado(): void {
    if (
      this.numerosDeControleJaUtilizados?.includes(this.dados.numero_novo) &&
      this.dados.numero_novo !== this.numeroAtual
    ) {
      this.isNumeroJaCadastrado = true
    } else {
      this.isNumeroJaCadastrado = false
    }
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.numero_novo = ''
      this.emit()
    }
  }
}
