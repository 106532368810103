import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MenuController, ModalController } from '@ionic/angular'
import * as moment from 'moment'
import { NovoFiltro } from 'src/app/components/vasta-tabela/vasta-tabela'
import { AnimaisService } from 'src/app/services/animais.service'
import { ImportacaoService } from 'src/app/services/importacao.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { AnimaisPorNumeracao, AnimalPorNumeracao, IAnimal } from 'src/app/utils/interfaces/animais.interface'
import { ModalListaAnimaisPage } from '../../bovinos/modal-lista-animais/modal-lista-animais.page'

import { ModalEstoqueBiologicoComponent } from '../../estoque/modal-estoque-biologico/modal-estoque-biologico.component'

declare const jexcel

@Component({
  selector: 'app-importacao-manejos',
  templateUrl: './importacao-manejos.component.html',
  styleUrls: ['./importacao-manejos.component.scss']
})
export class ImportacaoManejosComponent implements OnInit, AfterViewInit {
  @ViewChild('spreadsheetManejos') spreadsheet: ElementRef

  private botaoSelecionarAnimais = '<button class="btn-importacao-selecionar-animal">Selecionar Animal Correto</button>'
  public tipoManejo = null
  public colunas = {
    status: { title: 'Status', type: 'hidden', width: '170px' },
    busca_numero: { title: 'Buscar por Número', type: 'text', width: '250px' },
    animal: { title: 'Animal', type: 'html', width: '250px', height: '50px' }
  }

  public colunaFixaManejosReprodutivos = {
    data_manejo: { title: 'Data do Manejo', type: 'calendar', width: '160px' },
    observacao: { title: 'Observação', type: 'text', width: '200px' }
  }

  public colunasFixasPesagem = {
    data_pesagem: { title: 'Data da Pesagem', type: 'calendar', width: '160px' },
    peso: { title: 'Peso (Kg)', type: 'numeric', width: '180px', mask: '#.##,00 kg', decimal: ',' },
    observacao: { title: 'Observação', type: 'text', width: '200px' }
  }

  public colunasFixasOrdenha = {
    data_ordenha: { title: 'Data', type: 'calendar', width: '160px' },
    peso_primeira_ordenha: { title: 'Ordenha 1 (Kg)', type: 'numeric', width: '180px', mask: '#.##,00 kg', decimal: ',' },
    peso_segunda_ordenha: { title: 'Ordenha 2 (Kg)', type: 'numeric', width: '180px', mask: '#.##,00 kg', decimal: ',' },
    peso_terceira_ordenha: { title: 'Ordenha 3 (Kg)', type: 'numeric', width: '180px', mask: '#.##,00 kg', decimal: ',' }
  }

  public colunasExtrasManejoReprodutivo = {
    'monta-natural': {
      cobertura_hash_reprodutor: { title: 'Reprodutor da Fazenda', type: 'html', width: '200px' },
      cobertura_hash_reprodutor_externo: { title: 'Reprodutor Externo', type: 'html', width: '200px' }
    },
    inseminacao: {
      inseminacao_semen: { title: 'Sêmen/Embrião', type: 'html', width: '240px' }
    },
    dg: {
      dg_resultado: { title: 'Resultado', type: 'dropdown', source: ['Positivo', 'Negativo'], width: '200px' },
      dg_periodo_gestacional: { title: 'Período Gestacional', type: 'numeric', mask: '#.## dias', width: '200px' }
    },
    parto: {
      busca_numero_cria1: { title: 'Buscar por Número', type: 'text', width: '200px' },
      parto_hash_cria1: { title: 'Cria 1', type: 'html', width: '200px' },
      busca_numero_cria2: { title: 'Buscar por Número', type: 'text', width: '200px' },
      parto_hash_cria2: { title: 'Cria 2', type: 'html', width: '200px' }
    }
  }

  public nesteColumnsFixasManejosReprodutivos = [
    {
      title: 'Dados do animal',
      colspan: '2'
    },
    {
      title: 'Dados do manejo',
      colspan: '2'
    }
  ]

  public nestedColumnsExtrasManejoReprodutivo = {
    cio: {
      title: 'Cio',
      colspan: '1'
    },
    'monta-natural': {
      title: 'Monta Natural',
      colspan: '2'
    },
    inseminacao: {
      title: 'Inseminação',
      colspan: '1'
    },
    dg: {
      title: 'Diagnóstico de Gestação',
      colspan: '2'
    },
    parto: {
      title: 'Parto',
      colspan: '4'
    },
    implante: {
      title: 'Aplicação de Implante',
      colspan: '1'
    },
    'implante-remocao': {
      title: 'Remoção de Implante',
      colspan: '1'
    },
    desmame: {
      title: 'Desmame',
      colspan: '2'
    },
    aborto: {
      title: 'Aborto',
      colspan: '1'
    },
    castracao: {
      title: 'Castração',
      colspan: '1'
    },
    pesagem: {
      title: 'Pesagem',
      colspan: '1'
    },
    ordenha: {
      title: 'Ordenha',
      colspan: '1'
    }
  }

  public preventDetectChanges = {}
  public tabela
  public dadosASalvar: unknown
  public colunasArray = []
  public timeoutValidacao = null
  public adicionarMais = 10
  public numeracaoDeAnimaisExistentes: string[]

  public numeracoes = new Set()
  public todosAnimaisPorNumeracao: AnimaisPorNumeracao = {}
  public todasCriasPorNumeracao: AnimaisPorNumeracao = {}

  public loadingNumeracao = false

  constructor(
    private modalCtrl: ModalController,
    private utilsCtrl: IonicUtilsService,
    private route: ActivatedRoute,
    private importacaoCtrl: ImportacaoService,
    private menuCtrl: MenuController,
    private animaisCtrl: AnimaisService
  ) {
    this.menuCtrl.enable(false)
  }

  async ngOnInit(): Promise<void> {
    this.tipoManejo = this.route.snapshot.queryParamMap.get('tipoManejo')

    if (this.tipoManejo === 'pesagem') {
      this.colunas = { ...this.colunas, ...this.colunasFixasPesagem }
    } else if (this.tipoManejo === 'ordenha') {
      this.colunas = { ...this.colunas, ...this.colunasFixasOrdenha }
    } else {
      this.colunas = {
        ...this.colunas,
        ...this.colunaFixaManejosReprodutivos,
        ...(this.colunasExtrasManejoReprodutivo[this.tipoManejo] || {})
      }
    }
    this.colunasArray = Object.keys(this.colunas)

    const [numeracaoDeAnimaisExistentes] = await this.importacaoCtrl.getNumeracoesExistentes()
    this.numeracaoDeAnimaisExistentes = numeracaoDeAnimaisExistentes
  }

  async ngOnDestroy(): Promise<void> {
    this.menuCtrl.enable(true)
  }

  ngAfterViewInit(): void {
    let nestedHeaders = []
    if (this.tipoManejo !== 'pesagem' && this.tipoManejo !== 'ordenha') {
      nestedHeaders = [
        [...this.nesteColumnsFixasManejosReprodutivos, this.nestedColumnsExtrasManejoReprodutivo[this.tipoManejo] || '']
      ]
    }

    this.tabela = jexcel(this.spreadsheet.nativeElement, {
      data: [[]],
      includeHeadersOnDownload: true,
      tableOverflow: true,
      lazyLoading: true,
      lazyColumns: true,
      loadingSpin: true,
      tableHeight: '100%',
      tableWidth: '100%',
      columns: Object.values(this.colunas),
      minDimensions: [Object.values(this.colunas).length, 300],
      autoIncrement: false,
      contextMenu: false,
      allowRenameColumn: false,
      allowDeleteColumn: false,
      allowDeleteRow: false,
      nestedHeaders,

      text: {
        noRecordsFound: 'Nenhum registro encontrado',
        showingPage: 'Mostrando página {0} de {1} entradas',
        show: 'Show',
        entries: 'entradas',
        insertANewColumnBefore: 'Inserir uma nova coluna antes de',
        insertANewColumnAfter: 'Inserir uma nova coluna depois de',
        deleteSelectedColumns: 'Excluir colunas selecionadas',
        renameThisColumn: 'Renomear esta coluna',
        orderAscending: 'ordem ascendente',
        orderDescending: 'Order decrescente',
        insertANewRowBefore: 'Inserir uma nova linha antes de',
        insertANewRowAfter: 'Inserir uma nova linha depois de',
        deleteSelectedRows: 'Excluir linhas selecionadas',
        editComments: 'Editar comentários',
        addComments: 'Adicionar comentários',
        comments: 'Comentários',
        clearComments: 'Limpar comentários',
        copy: 'Copiar ...',
        paste: 'Colar ...',
        saveAs: 'Salvar como ...',
        about: 'About',
        areYouSureToDeleteTheSelectedRows: 'Tem certeza de excluir as linhas selecionadas?',
        areYouSureToDeleteTheSelectedColumns: 'Tem certeza de excluir as colunas selecionadas?',
        thisActionWillDestroyAnyExistingMergedCellsAreYouSure:
          'Esta ação irá destruir todas as células mescladas existentes. Você tem certeza?',
        thisActionWillClearYourSearchResultsAreYouSure:
          'Esta ação limpará seus resultados de pesquisa. Você tem certeza?',
        thereIsAConflictWithAnotherMergedCell: 'Há um conflito com outra célula mesclada',
        invalidMergeProperties: 'Propriedades mescladas inválidas',
        cellAlreadyMerged: 'Célula já mesclada',
        noCellsSelected: 'Nenhuma célula selecionada'
      },

      onchange: async (params, a1, coluna, linha, novoValor) => {
        if ((coluna == 1 || (this.tipoManejo === 'parto' && (coluna == 5 || coluna == 7))) && novoValor) {
          const numeroDigitado = novoValor
          const numeroProcessado = numeroDigitado.replace(/^0+/, '')

          if (!this.numeracoes.has(numeroProcessado)) {
            this.numeracoes.add(numeroProcessado)
          }

          await this.buscarAnimaisPorNumeracao()

          return
        }

        if (coluna == 2 && novoValor && novoValor !== this.botaoSelecionarAnimais && !novoValor.includes('manejado"')) {
          this.tabela.setValueFromCoords(coluna, linha, '')
          return
        }

        if (coluna == 3 && novoValor) {
          if (
            String(novoValor).length > 19 ||
            novoValor.includes('>') ||
            novoValor.includes('<') ||
            novoValor.includes('a')
          ) {
            this.tabela.setValueFromCoords(coluna, linha, '')
            return
          }
          if (novoValor[2] == '/' && novoValor[5] == '/') {
            this.tabela.setValueFromCoords(coluna, linha, novoValor.split('/').reverse().join('-'))
            return
          }
        }

        if (coluna == 4 && this.tipoManejo !== 'pesagem') {
          if (novoValor.includes('</')) {
            this.tabela.setValueFromCoords(coluna, linha, '')
            return
          }
        }

        if (this.tipoManejo == 'pesagem' && coluna == 4) {
          try {
            if (this.preventDetectChanges[`${coluna}-${linha}`]) {
              delete this.preventDetectChanges[`${coluna}-${linha}`]
              return
            }

            novoValor = String(novoValor)
            if (novoValor.includes('.') && !novoValor.includes(',')) {
              novoValor = novoValor.replace('.', ',')
            }

            if (isNaN(novoValor) && (novoValor.includes('.') || novoValor.includes(',') || novoValor.includes('kg'))) {
              if (novoValor.includes('.') && novoValor.includes(',')) {
                const indexPonto = novoValor.indexOf('.')
                const indexComma = novoValor.indexOf(',')
                if (indexPonto > indexComma) {
                  novoValor = novoValor.replace(/[,]/g, '').replace('.', ',')
                } else {
                  novoValor = novoValor.replace(/[.]/g, '')
                }
              }

              const valorFinal = Number(novoValor.replace(/[^0-9,.]/g, '').replace(',', '.'))
              setTimeout(() => {
                this.preventDetectChanges[`${coluna}-${linha}`] = true
                this.tabela.setValueFromCoords(coluna, linha, valorFinal)
                this.validarLinha()
              }, 1000)
            }
          } catch (e) {
            console.error('🚀 ~ onchange: ~ e:', e)
          }
          return
        }

        if (this.tipoManejo == 'parto') {
          if ((coluna == 5 || coluna == 7) && novoValor) {
            if (!novoValor.includes('cria"')) {
              this.tabela.setValueFromCoords(coluna, linha, '')
              return
            }
            const value = this.tabela.getValueFromCoords(6, linha)
            if (novoValor == value) {
              this.tabela.setValueFromCoords(6, linha, '')
              return
            }
          }
        }

        if (this.tipoManejo == 'monta-natural') {
          if (coluna == 5 && novoValor) {
            if (!novoValor.includes('interno"')) {
              this.tabela.setValueFromCoords(coluna, linha, '')
            }
            this.tabela.setValueFromCoords(6, linha, '')
            return
          }
          if (coluna == 6 && novoValor) {
            if (!novoValor.includes('externo"')) {
              this.tabela.setValueFromCoords(coluna, linha, '')
            }
            this.tabela.setValueFromCoords(5, linha, '')
            return
          }
        }

        if (this.tipoManejo == 'inseminacao') {
          if (coluna == 5 && novoValor) {
            if (!novoValor.includes('semen-embriao"')) {
              this.tabela.setValueFromCoords(coluna, linha, '')
              return
            }
          }
        }

        if (this.tipoManejo == 'cio' && coluna == 5) {
          if (novoValor.length && novoValor !== 'Positivo' && ['P', 'p'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Positivo')
            return
          }
          if (novoValor.length && novoValor !== 'Negativo' && ['N', 'n', 'V', 'v'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Negativo')
            return
          }
        }

        if (this.tipoManejo == 'dg' && coluna == 5) {
          if (novoValor.length && novoValor !== 'Positivo' && ['P', 'p'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Positivo')
            return
          }
          if (novoValor.length && novoValor !== 'Negativo' && ['N', 'n', 'V', 'v'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Negativo')
            return
          }
        }

        if (this.tipoManejo == 'implante' && coluna == 5) {
          if (novoValor.length && novoValor !== 'Sim' && ['S', 's', 'I', 'i'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Sim')
            return
          }
          if (novoValor.length && novoValor !== 'Não' && ['N', 'n'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Não')
            return
          }
        }

        if (this.tipoManejo == 'implante-remocao' && coluna == 5) {
          if (novoValor.length && novoValor !== 'Sim' && ['S', 's', 'I', 'i'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Sim')
            return
          }
          if (novoValor.length && novoValor !== 'Não' && ['N', 'n'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Não')
            return
          }
        }

        this.dadosASalvar = this.tabela.getData()
        if (coluna !== 0) {
          clearTimeout(this.timeoutValidacao)
          this.timeoutValidacao = setTimeout(() => {
            this.validarLinha()
          }, 1000)
        }
      },

      oneditionstart: (params, param1, coluna, linha, param4) => {
        const colunaNome = this.tabela.getHeader(this.tabela.getSelectedColumns())
        const value = this.tabela.getValueFromCoords(coluna, linha)

        if (colunaNome === 'Status') {
          this.utilsCtrl.showToast('Coluna somente para visualização do status da linha', 'bottom')
        }

        if (colunaNome === 'Animal') {
          const brincoDigitado = this.tabela.getValueFromCoords(1, linha)
          if (brincoDigitado) {
            const animaisPorNumeracao = this.todosAnimaisPorNumeracao[brincoDigitado]
            const numerosDiferentes = Object.keys(animaisPorNumeracao)

            if (animaisPorNumeracao && numerosDiferentes.length) {
              let hashs = animaisPorNumeracao.map((item) => item.hash)

              this.abrirSelecaoAnimalManejado({ coluna, linha, hashs })
            } else {
              if (value) {
                this.abrirSelecaoAnimalManejado({ coluna, linha })
              }
            }
          } else {
            if (value) {
              this.abrirSelecaoAnimalManejado({ coluna, linha })
            }
          }
        }

        if (colunaNome === 'Sêmen/Embrião') {
          this.selecionarSemenOuEmbriao(coluna, linha)
        }

        if (colunaNome === 'Cria Desmamada' || colunaNome === 'Cria 1' || colunaNome === 'Cria 2') {
          this.selecionarAnimal({ coluna, linha, somenteInternos: true, tipoAnimal: 'cria' })
        }

        if (colunaNome === 'Reprodutor da Fazenda' || colunaNome === 'Reprodutor Externo') {
          this.selecionarAnimal({
            coluna,
            linha,
            reprodutor: true,
            somenteInternos: colunaNome === 'Reprodutor da Fazenda',
            somenteExternos: colunaNome === 'Reprodutor Externo',
            tipoAnimal: colunaNome === 'Reprodutor da Fazenda' ? 'interno' : 'externo'
          })
        }
      },

      onselection: (params, param1, linha, coluna, param4) => {
        if (this.tabela.getSelectedColumns().length > 1) {
          return
        }

        const colunaNome = this.tabela.getHeader(this.tabela.getSelectedColumns())
        const value = this.tabela.getValueFromCoords(coluna, linha)

        if (colunaNome === 'Animal' && (!value || value == this.botaoSelecionarAnimais)) {
          const brincoDigitado = this.tabela.getValueFromCoords(1, linha)
          if (brincoDigitado) {
            const animaisPorNumeracao = this.todosAnimaisPorNumeracao[brincoDigitado]
            const numerosDiferentes = Object.keys(animaisPorNumeracao || {})

            if (animaisPorNumeracao && numerosDiferentes.length) {
              let hashs = animaisPorNumeracao.map((item) => item.hash)
              this.abrirSelecaoAnimalManejado({ coluna, linha, hashs })
            } else {
              this.abrirSelecaoAnimalManejado({ coluna, linha })
            }
          } else {
            this.abrirSelecaoAnimalManejado({ coluna, linha })
          }
        }

        if (colunaNome === 'Sêmen/Embrião' && value == this.botaoSelecionarAnimais) {
          this.selecionarSemenOuEmbriao(coluna, linha)
        }
      }
    })
  }

  insereAnimal(data: IAnimal | AnimalPorNumeracao, col: number, row: number, tipoAnimal: string): string {
    const idadeAnimal = this.calcularIdadeAnimal(data.data_nascimento)
    const stringIdade = data.data_nascimento ? `<small>${idadeAnimal}</small>` : ''
    const { sexo, nome, numeracao } = data
    const icone = sexo === 'macho' ? 'male' : 'female'
    const html = `<div class="pesagens-col-animal ${tipoAnimal}"><ion-icon name="${icone}" color="${icone}"></ion-icon> <span>${
      numeracao || ''
    } <b>${nome || ''}</b></span>${stringIdade}<p style="display: none">hash:${data.hash}</p></div>`
    this.tabela.setValueFromCoords(col, row, html)
    this.utilsCtrl.tick()
    return html
  }

  async validarLinha(): Promise<void> {
    document.querySelectorAll('td.pendente').forEach((td) => td.classList.remove('pendente'))
    document.querySelectorAll('tr.valido').forEach((tr) => tr.classList.remove('valido'))

    this.dadosASalvar = this.tabela.getData()
    const todosOsDados = JSON.parse(JSON.stringify(this.dadosASalvar))

    let Nlinha = -1
    for (const dadosAtuais of todosOsDados) {
      Nlinha++

      const colunaStatus = dadosAtuais[0]
      dadosAtuais[0] = ''
      const valorPreenchido = dadosAtuais.join('')
      if (!valorPreenchido) {
        if (colunaStatus) {
          this.tabela.setValueFromCoords(0, Nlinha, '')
        }
        continue
      }

      const busca_numero = dadosAtuais[this.colunasArray.indexOf('busca_numero')]
      const data_manejo = dadosAtuais[this.colunasArray.indexOf('data_manejo')]
      const data_ordenha = dadosAtuais[this.colunasArray.indexOf('data_ordenha')]
      const data_pesagem = dadosAtuais[this.colunasArray.indexOf('data_pesagem')]
      const animal = dadosAtuais[this.colunasArray.indexOf('animal')]
      const peso = dadosAtuais[this.colunasArray.indexOf('peso')]
      const peso_primeira_ordenha = dadosAtuais[this.colunasArray.indexOf('peso_primeira_ordenha')]
      const peso_segunda_ordenha = dadosAtuais[this.colunasArray.indexOf('peso_segunda_ordenha')]
      const peso_terceira_ordenha = dadosAtuais[this.colunasArray.indexOf('peso_terceira_ordenha')]
      let novoValor = ''

      if (this.tipoManejo === 'monta-natural') {
        const colunaCobertura =
          dadosAtuais[this.colunasArray.indexOf('cobertura_hash_reprodutor')] ||
          dadosAtuais[this.colunasArray.indexOf('cobertura_hash_reprodutor_externo')]

        if (!colunaCobertura) {
          this.tabela.getCellFromCoords(5, Nlinha).classList.add('pendente')
          this.tabela.getCellFromCoords(6, Nlinha).classList.add('pendente')
          novoValor = '<span style="color: #d32f2f">Adicionar reprodutor</span>'
        }

        if (!colunaCobertura.includes('hash:')) {
          this.tabela.getCellFromCoords(5, Nlinha).classList.add('pendente')
          this.tabela.getCellFromCoords(6, Nlinha).classList.add('pendente')
          novoValor = '<span style="color: #d32f2f">Selecione o reprodutor</span>'
        }
      }
      if (this.tipoManejo === 'parto') {
        const colunasCria =
          dadosAtuais[this.colunasArray.indexOf('parto_hash_cria1')] ||
          dadosAtuais[this.colunasArray.indexOf('parto_hash_cria2')]

        const colunaNumeroAnimal = dadosAtuais[1]
        const colunaNumeroCria1 = dadosAtuais[5]
        const colunaNumeroCria2 = dadosAtuais[7]

        const colunaHashAnimal = dadosAtuais[2]
        const colunaHashCria1 = dadosAtuais[6]
        const colunaHashCria2 = dadosAtuais[8]

        const regexParaPegarHash = /hash:(\S+)<\/p>/

        const hashAnimal = colunaHashAnimal.match(regexParaPegarHash)
        const hashCria1 = colunaHashCria1.match(regexParaPegarHash)
        const hashCria2 = colunaHashCria2.match(regexParaPegarHash)

        if (
          ( (colunaNumeroAnimal && colunaNumeroAnimal === colunaNumeroCria1) || (colunaNumeroAnimal && colunaNumeroAnimal === colunaNumeroCria2)) ||
          (hashAnimal === hashCria1 || hashAnimal === hashCria2)
        ) {
          this.tabela.getCellFromCoords(2, Nlinha).classList.add('pendente')
          this.tabela.getCellFromCoords(6, Nlinha).classList.add('pendente')
          novoValor = '<span style="color: #d32f2f">Animal igual a cria</span>'

        }

        if (!colunasCria || colunasCria == this.botaoSelecionarAnimais) {
          this.tabela.getCellFromCoords(6, Nlinha).classList.add('pendente')
          novoValor = '<span style="color: #d32f2f">Adicionar cria</span>'
        }
      }
      if (this.tipoManejo === 'inseminacao') {
        const inseminacao = dadosAtuais[this.colunasArray.indexOf('inseminacao_semen')]

        if (!inseminacao) {
          this.tabela.getCellFromCoords(5, Nlinha).classList.add('pendente')
          novoValor = '<span style="color: #d32f2f">Adicionar Sêmen/Embrião</span>'
        }

        if (!inseminacao.includes('hash:')) {
          this.tabela.getCellFromCoords(5, Nlinha).classList.add('pendente')
          novoValor = '<span style="color: #d32f2f">Selecione Sêmen/Embrião</span>'
        }
      }

      if (!animal || animal == this.botaoSelecionarAnimais || !animal.includes('<ion-icon')) {
        this.tabela.getCellFromCoords(2, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Selecione o animal</span>'
      }

      if (!animal.includes('hash:')) {
        this.tabela.getCellFromCoords(2, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Selecione o animal</span>'
      }

      if (!data_manejo && this.tipoManejo !== 'pesagem' && this.tipoManejo !== 'ordenha' && data_manejo[2] !== '/') {
        this.tabela.getCellFromCoords(3, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Sem data do manejo</span>'
      }

      if (!data_ordenha && this.tipoManejo === 'ordenha') {
        this.tabela.getCellFromCoords(3, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Sem data da ordenha</span>'
      }
      if (
        this.tipoManejo === 'ordenha' &&
        peso_primeira_ordenha == 0 &&
        peso_segunda_ordenha == 0 &&
        peso_terceira_ordenha == 0
      ) {
        this.tabela.getCellFromCoords(4, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Sem peso</span>'
      }

      if (this.tipoManejo === 'pesagem' && (!data_pesagem || data_pesagem[4] !== '-' || data_pesagem[7] !== '-')) {
        this.tabela.getCellFromCoords(3, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Sem data da pesagem</span>'
      }

      if (this.tipoManejo === 'pesagem' && (!peso || isNaN(Number(String(peso || '').split(' ')[0])))) {
        this.tabela.getCellFromCoords(4, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Sem peso</span>'
      }

      if (!novoValor) {
        this.tabela.getCellFromCoords(3, Nlinha)?.parentNode?.classList.add('valido')
        novoValor = '<span style="color: #006158">Ok</span>'
      }

      this.tabela.setValueFromCoords(0, Nlinha, novoValor)
    }
  }

  abrirSelecaoAnimalManejado(opcoes: { coluna: number; linha: number; hashs?: string[] }): void {
    const { coluna, linha, hashs } = opcoes
    this.selecionarAnimal({
      coluna,
      linha,
      matriz: !['pesagem', 'desmame'].includes(this.tipoManejo),
      hashs,
      somenteInternos: true,
      tipoAnimal: 'manejado'
    })
  }

  selecaoAberta = false
  async selecionarAnimal(opcoes: {
    coluna: number
    linha: number
    matriz?: boolean
    reprodutor?: boolean
    hashs?: string[]
    somenteInternos?: boolean
    somenteExternos?: boolean
    tipoAnimal: string
  }): Promise<void> {
    if (this.selecaoAberta) return
    this.selecaoAberta = true
    const { coluna, linha, matriz, reprodutor, hashs, somenteInternos, somenteExternos, tipoAnimal } = opcoes
    let componentProps = {}
    if (matriz || reprodutor) {
      const filtroSexo: NovoFiltro = {
        sexo: matriz ? 'femea' : 'macho'
      }

      componentProps = {
        filtrosFixos: ['sexo'],
        modalFiltro: filtroSexo,
        modalFiltroReprodutorExterno: filtroSexo
      }
    }

    componentProps = { ...componentProps, hashIn: hashs, somenteInternos, somenteExternos }

    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps,
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()
    this.selecaoAberta = false
    if (data) {
      console.log({ data })

      this.insereAnimal(data, coluna, linha, tipoAnimal)
    }
  }

  calcularIdadeAnimal(dataNascimento: string): string {
    const dataHoje = moment().format('YYYY-MM-DD')
    const dataNascimentoMoment = moment(dataNascimento, 'YYYY-MM-DD')
    const idade = moment(dataHoje).diff(dataNascimentoMoment, 'years')
    if (idade < 1) {
      const idadeMeses = moment(dataHoje).diff(dataNascimentoMoment, 'months')

      if (idadeMeses < 1) {
        const idadeDias = `${moment(dataHoje).diff(dataNascimentoMoment, 'days')} dias`
        return idadeDias
      } else {
        return `${idadeMeses} meses`
      }
    } else {
      return `${idade} anos`
    }
  }

  async salvarManejos(): Promise<void> {
    const dados = this.tabela.getData()
    const dadosPreenchidos = []
    for (const linha of dados) {
      const linhaValida = JSON.parse(JSON.stringify(linha))

      if (linhaValida.join('') !== '') {
        dadosPreenchidos.push(linhaValida)
      }
    }

    const manejos = []
    let keysColunasTipoManejo = []
    if (this.colunasExtrasManejoReprodutivo[this.tipoManejo]) {
      keysColunasTipoManejo = Object.keys(this.colunasExtrasManejoReprodutivo[this.tipoManejo]).map((key) => key)
    }

    if (dadosPreenchidos.length === 1) {
      if (!dadosPreenchidos[0][0].includes('Ok')) {
        this.utilsCtrl.showToast('Preencha todos os campos', 'bottom')
      }
    }

    for (let linhaValida of dadosPreenchidos) {
      if (linhaValida[0].includes('Ok')) {
        let manejo: Record<string, unknown>

        if (this.tipoManejo === 'pesagem') {
          manejo = {
            hash_animal: linhaValida[this.colunasArray.indexOf('animal')]?.split('hash:')[1]?.split('</p>')[0] || '',
            data_pesagem: linhaValida[this.colunasArray.indexOf('data_pesagem')].split(' ')[0] || '',
            peso: Number(String(linhaValida[this.colunasArray.indexOf('peso') || '']).split(' ')[0]) || 0,
            observacao: linhaValida[this.colunasArray.indexOf('observacao')] || '',
            identificador: 'importacao'
          }
        } else if (this.tipoManejo === 'ordenha') {
          manejo = {
            hash_animal: linhaValida[this.colunasArray.indexOf('animal')]?.split('hash:')[1]?.split('</p>')[0] || '',
            data: linhaValida[this.colunasArray.indexOf('data_ordenha')].split(' ')[0] || '',
            primeira_ordenha: this.formatarPesoParaEnviar(
              linhaValida[this.colunasArray.indexOf('peso_primeira_ordenha')]
            ),
            segunda_ordenha: this.formatarPesoParaEnviar(
              linhaValida[this.colunasArray.indexOf('peso_segunda_ordenha')]
            ),
            terceira_ordenha: this.formatarPesoParaEnviar(
              linhaValida[this.colunasArray.indexOf('peso_terceira_ordenha')]
            )
          }
        } else {
          manejo = {
            hash_animal: linhaValida[this.colunasArray.indexOf('animal')]?.split('hash:')[1]?.split('</p>')[0] || '',
            data: linhaValida[this.colunasArray.indexOf('data_manejo')].split(' ')[0] || '',
            observacao: linhaValida[this.colunasArray.indexOf('observacao')] || '',
            identificador: 'importacao',
            procedimento: this.tipoManejo
          }
        }

        const manejoExtras = {}

        keysColunasTipoManejo.map((key) => {
          if (key.includes('hash_reprodutor') || key.includes('hash_cria')) {
            manejoExtras[key] = linhaValida[this.colunasArray.indexOf(key)].split('hash:')[1]?.split('</p>')[0] || ''
          } else if (key.includes('inseminacao')) {
            manejoExtras[
              linhaValida[this.colunasArray.indexOf(key)].includes('female')
                ? 'inseminacao_embriao'
                : 'inseminacao_semen'
            ] = linhaValida[this.colunasArray.indexOf(key)].split('hash:')[1]?.split('</p>')[0] || ''
          } else if (key.includes('dg_periodo_gestacional')) {
            manejoExtras['dg_periodo_gestacional'] = (linhaValida[this.colunasArray.indexOf(key)] || '').split(' dias')[0]
            if (isNaN(manejoExtras['dg_periodo_gestacional'])) {
              manejoExtras['dg_periodo_gestacional'] = ''
            }
          } else if (key.includes('dg_resultado')) {
            manejoExtras['dg_resultado'] = String(
              linhaValida[this.colunasArray.indexOf(key) || 'positivo']
            ).toLowerCase()
          } else {
            manejoExtras[key] = linhaValida[this.colunasArray.indexOf(key)]
          }
        })

        manejo = { ...manejo, ...manejoExtras }

        if (this.tipoManejo === 'cio') {
          manejo = { ...manejo, cio_resultado: 'positivo' }
        }

        if (this.tipoManejo === 'dg') {
          manejo = { ...manejo }
        }

        if (this.tipoManejo === 'implante') {
          manejo = { ...manejo, implante_aplica: 'realizado' }
        }

        if (this.tipoManejo === 'desmame') {
          manejo = { ...manejo, desmame_detalhes: 'realizado' }
        }

        if (this.tipoManejo === 'implante-remocao') {
          manejo = { ...manejo, implante_remove: 'realizado' }
        }

        if (this.tipoManejo === 'aborto') {
          manejo = { ...manejo, aborto_detalhes: 'realizado' }
        }

        if (this.tipoManejo === 'castracao') {
          manejo = { ...manejo, castracao_detalhes: 'realizado' }
        }

        manejos.push(manejo)
      }
    }

    const payload = {
      manejos
    }

    if (!manejos.length) {
      this.utilsCtrl.showToast('Nenhum manejo válido preenchido', 'bottom')
      return
    }

    if (this.tipoManejo === 'pesagem') {
      const loading = await this.utilsCtrl.showLoading('circular', 'Salvando pesagens...')
      const [response, error] = await this.importacaoCtrl.salvarImportacaoPesagens(manejos)
      this.utilsCtrl.dismissLoading(loading)

      if (response) {
        this.excluirLinhasAposSalvarManejos()
        this.utilsCtrl.showToast('Pesagens cadastradas com sucesso', 'bottom')
      }

      if (error) {
        this.utilsCtrl.showToast('Erro ao salvar pesagens', 'bottom')
      }
    } else if (this.tipoManejo === 'ordenha') {
      const loading = await this.utilsCtrl.showLoading('circular', 'Salvando manejos...')
      const [response, error] = await this.importacaoCtrl.salvarImportacaoLeite(manejos)
      this.utilsCtrl.dismissLoading(loading)
      console.log('response', response)

      if (response) {
        this.excluirLinhasAposSalvarManejos()
        this.utilsCtrl.showToast(
          manejos.length == 1 ? '1 manejo importado com sucesso' : `${manejos.length} Manejos importados com sucesso`,
          'bottom'
        )
      }

      if (error) {
        this.utilsCtrl.showToast('Erro ao salvar manejos', 'bottom')
      }
    } else {
      const loading = await this.utilsCtrl.showLoading('circular', 'Salvando manejos...')
      const [response, error] = await this.importacaoCtrl.salvarImportacaoManejosReprodutivos(payload)
      this.utilsCtrl.dismissLoading(loading)

      if (response) {
        this.excluirLinhasAposSalvarManejos()
        this.utilsCtrl.showToast(
          manejos.length == 1 ? '1 manejo importado com sucesso' : `${manejos.length} Manejos importados com sucesso`,
          'bottom'
        )
      }

      if (error) {
        this.utilsCtrl.showToast('Erro ao salvar manejos', 'bottom')
      }
    }
  }

  excluirLinhasAposSalvarManejos(): void {
    const dados = this.tabela.getData()
    let Nlinha = dados.length

    const reverseDados = dados.reverse()

    this.tabela.el.jexcel.options = { ...this.tabela.el.jexcel.options, allowDeleteRow: true }
    for (let linha of reverseDados) {
      Nlinha--
      if (linha[0].includes('Ok')) {
        this.tabela.deleteRow(Nlinha, 1)
      }
    }
    this.tabela.el.jexcel.options = { ...this.tabela.el.jexcel.options, allowDeleteRow: false }
  }

  buscaNumeracaoTimeout

  async buscarAnimaisPorNumeracao(): Promise<void> {
    clearTimeout(this.buscaNumeracaoTimeout)
    this.buscaNumeracaoTimeout = setTimeout(() => {
      this.buscarAnimaisPorNumeracaoHelper()
    }, 300)
  }

  async buscarAnimaisPorNumeracaoHelper(): Promise<void> {
    const todosOsNumeros = []
    if (this.tipoManejo === 'parto') {
      this.buscarCriasPorNumeracao()
    }
    this.tabela.getData().map((linha) => {
      const numeroProcessado = (linha[1] || '').replace(/^0+/, '')
      if (numeroProcessado && !todosOsNumeros.includes(numeroProcessado)) {
        todosOsNumeros.push(numeroProcessado)
      }
    })

    const numerosABuscar = todosOsNumeros.filter(
      (numero) => !this.todosAnimaisPorNumeracao[numero] && !this.todosAnimaisPorNumeracao[Number(numero)]
    )

    if (numerosABuscar.length) {
      this.loadingNumeracao = true

      const sexo = !['pesagem', 'desmame'].includes(this.tipoManejo) ? 'femea' : ''

      const [response, error] = await this.animaisCtrl.getAnimaisPorNumeracao(numerosABuscar, sexo)
      this.loadingNumeracao = false

      if (response) {
        this.todosAnimaisPorNumeracao = { ...this.todosAnimaisPorNumeracao, ...response }
      }

      if (error) {
        console.error(error)
      }
    }
    this.preencheAnimaisBusca()
  }

  preencheAnimaisBusca(): void {
    const todosOsDados = JSON.parse(JSON.stringify(this.tabela.getData()))

    for (const linha in todosOsDados) {
      const dadosLinha = todosOsDados[linha]

      const [_, numero_busca, animal] = dadosLinha

      const numeroProcessado = numero_busca.replace(/^0+/, '')

      if (numeroProcessado && (!animal || animal == this.botaoSelecionarAnimais)) {
        const animaisComONumero = this.todosAnimaisPorNumeracao[numeroProcessado]
        if (!animaisComONumero || !animaisComONumero.length) {
          this.tabela.setValueFromCoords(2, linha, this.botaoSelecionarAnimais)
        } else if (animaisComONumero.length === 1) {
          this.insereAnimal(animaisComONumero[0], 2, Number(linha), 'manejado')
        } else {
          this.tabela.setValueFromCoords(2, linha, this.botaoSelecionarAnimais)
        }
      }
    }

    this.validarLinha()
  }

  async buscarCriasPorNumeracao(): Promise<void> {
    const todosOsNumeros = []
    this.tabela.getData().map((linha) => {
      const numeroProcessadoCria1 = (linha[5] || '').replace(/^0+/, '')
      const numeroProcessadoCria2 = (linha[7] || '').replace(/^0+/, '')

      if (numeroProcessadoCria1 && !todosOsNumeros.includes(numeroProcessadoCria1)) {
        todosOsNumeros.push(numeroProcessadoCria1)
      }

      if (numeroProcessadoCria2 && !todosOsNumeros.includes(numeroProcessadoCria2)) {
        todosOsNumeros.push(numeroProcessadoCria2)
      }
    })

    const numerosABuscar = todosOsNumeros.filter(
      (numero) => !this.todasCriasPorNumeracao[numero] && !this.todasCriasPorNumeracao[Number(numero)]
    )

    if (numerosABuscar.length) {
      this.loadingNumeracao = true

      const [response, error] = await this.animaisCtrl.getAnimaisPorNumeracao(numerosABuscar)

      this.loadingNumeracao = false

      if (response) {
        this.todasCriasPorNumeracao = { ...this.todasCriasPorNumeracao, ...response }
      }

      if (error) {
        console.error(error)
      }
    }
    this.preencheCriaBusca()
  }

  preencheCriaBusca(): void {
    const todosOsDados = JSON.parse(JSON.stringify(this.tabela.getData()))

    for (const linha in todosOsDados) {
      const dadosLinha = todosOsDados[linha]

      const [col1, col2, col3, col4, col5, numero_cria_1, cria_1, numero_cria_2, cria_2] = dadosLinha

      const numeroCria1Processado = numero_cria_1.replace(/^0+/, '')
      const numeroCria2Processado = numero_cria_2.replace(/^0+/, '')

      const colunaCria1 = this.colunasArray.indexOf('parto_hash_cria1')
      const colunaCria2 = this.colunasArray.indexOf('parto_hash_cria2')

      if (numeroCria1Processado && (!cria_1 || cria_1 == this.botaoSelecionarAnimais)) {
        const cria1ComONumero = this.todasCriasPorNumeracao[numeroCria1Processado]

        if (!cria1ComONumero || !cria1ComONumero.length) {
          this.tabela.setValueFromCoords(colunaCria1, linha, this.botaoSelecionarAnimais)
        } else if (cria1ComONumero.length === 1) {
          this.insereAnimal(cria1ComONumero[0], colunaCria1, Number(linha), 'cria')
        } else {
          this.tabela.setValueFromCoords(colunaCria1, linha, this.botaoSelecionarAnimais)
        }
      }

      if (numeroCria2Processado && (!cria_2 || cria_2 == this.botaoSelecionarAnimais)) {
        const cria2ComONumero = this.todasCriasPorNumeracao[numeroCria2Processado]
        if (!cria2ComONumero || !cria2ComONumero.length) {
          this.tabela.setValueFromCoords(colunaCria2, linha, this.botaoSelecionarAnimais)
        } else if (cria2ComONumero.length === 1) {
          this.insereAnimal(cria2ComONumero[0], colunaCria2, Number(linha), 'cria')
        } else {
          this.tabela.setValueFromCoords(colunaCria2, linha, this.botaoSelecionarAnimais)
        }
      }
    }
    this.validarLinha()
  }

  async selecionarSemenOuEmbriao(col: number, row: number): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEstoqueBiologicoComponent,
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      // prettier-ignore
      const html = `<div class="pesagens-col-animal semen-embriao">${data.touro_nome ? `<ion-icon name="male" color="male"></ion-icon><span>${data.touro_nome}</span>` : ''}${data.doadora_nome ? `<ion-icon name="female" color="female"></ion-icon><span>${data.doadora_nome}</span>` : ''}${!data.doadora && !data.touro_nome ? 'Não identificado' : ''}<p style="display: none">hash:${data.hash}</p></div>`
      this.tabela.setValueFromCoords(Number(col), row, html)
    }
  }

  formatarPesoParaEnviar(peso: string): number {
    return parseFloat(peso.replace(/kg|\s/g, '').replace(',', '.'))
  }
}
