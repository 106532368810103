import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { VastaRX } from '@vasta/rx'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IPropriedade } from 'src/app/utils/interfaces/propriedades.interface'

@Component({
  selector: 'propriedade-nome',
  templateUrl: './propriedade-nome.component.html',
  styleUrls: ['./propriedade-nome.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PropriedadeNomeComponent implements OnInit {
  @Input('tipo') tipo: '' | 'h1'
  public propriedadeNome: string
  constructor(private propriedadeCtrl: PropriedadesService) {}

  ngOnInit(): void {
    VastaRX.getState('propriedade_selecionada', (propriedade: IPropriedade) => {
      this.propriedadeNome = propriedade?.nome
    })

    this.propriedadeNome = this.propriedadeCtrl.propriedadeSelecionada?.nome
  }
}
