import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-reprodutivo-aborto',
  templateUrl: './manejo-reprodutivo-aborto.component.html',
  styleUrls: ['./manejo-reprodutivo-aborto.component.scss']
})
export class ManejoReprodutivoAbortoComponent implements OnInit {
  @Input('dados') dados = { aborto_detalhes: '', observacao: '', edited: true }
  @Input('data') data: string
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  emit(): void {
    this.dadosChange.emit(this.dados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.aborto_detalhes = null
      this.emit()
    }
  }
}
