<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Envio para Confinamento</div>
    <vasta-input
      label="Confinamento"
      type="manejo-button"
      [value]="lotesAssociados[dados.hash_confinamento]?.nome"
      (buttonEvent)="selecionarConfinamento()"
    ></vasta-input>

    <div class="manejo-opcoes" *ngIf="opcoesRapidasConfinamento.length">
      <span class="label">Opções <br />Rápidas</span>
      <div class="container-opcoes">
        <ng-template ngFor let-hash [ngForOf]="opcoesRapidasConfinamento">
          <button
            *ngIf="lotesAssociados[hash]"
            (click)="selecionarConfinamentoRapido(hash)"
            [class.active]="hash == dados.hash_confinamento"
          >
            {{ lotesAssociados[hash]?.nome || 'Sem nome' }}
          </button>
        </ng-template>
      </div>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.hash_confinamento">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
