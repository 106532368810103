import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { AlertController, ModalController } from '@ionic/angular'
import { AtualizacoesPage } from 'src/app/atualizacoes/atualizacoes.page'
import { ApiService } from 'src/app/services/api/api.service'
import { PlanosAssinaturaService } from 'src/app/services/plano-assinatura.service'
import { UsuariosService } from 'src/app/services/usuarios.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { environment } from 'src/environments/environment'
import { ModalAssineAgoraPage } from '../../geral/modal-assine-agora/modal-assine-agora.page'
import { ModalContaBloqueadaPage } from '../../geral/modal-conta-bloqueada/modal-conta-bloqueada.page'
import { ModalPlanoExpiradoPage } from '../../geral/modal-plano-expirado/modal-plano-expirado.page'
import { Preferences } from '@capacitor/preferences'
import { ModalAssinaturaPage } from '../modal-assinatura/modal-assinatura.page'
import { ModalNotificacoesPage } from '../modal-notificacoes/modal-notificacoes.page'
import { Browser } from '@capacitor/browser'

@Component({
  selector: 'app-modal-configuracoes',
  templateUrl: './modal-configuracoes.page.html',
  styleUrls: ['./modal-configuracoes.page.scss']
})
export class ModalConfiguracoesPage {
  configCounter = 0

  constructor(
    private apiCtrl: ApiService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public usuarioCtrl: UsuariosService,
    public utilsCtrl: IonicUtilsService,
    public planoCtrl: PlanosAssinaturaService,
    private router: Router
  ) {
    if (['dev', 'staging'].includes(environment.name)) this.configCounter = 10
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  async alertConfirmLogout(): Promise<void> {
    this.closeModal()
    const alert = await this.alertCtrl.create({
      header: 'Sair da Conta?',
      message: 'Deseja realmente sair da sua conta?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Sair',
          handler: (): void => {
            this.usuarioCtrl.logout()
          }
        }
      ]
    })

    await alert.present()
  }

  plusConfig(): void {
    this.configCounter++
  }

  async devModalPlanoExpirado(trial?: boolean): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalPlanoExpiradoPage,
      componentProps: {
        tipoVencimento: trial ? 'trial-expirado' : 'plano-expirado'
      },
      cssClass: 'custom-modal'
    })

    await modal.present()
  }

  async devModalContaBloqueada(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalContaBloqueadaPage,
      cssClass: 'custom-modal'
    })

    await modal.present()
  }

  async devModalDeAssinaturaDePlano(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAssineAgoraPage,
      cssClass: 'custom-modal'
    })

    await modal.present()
  }

  async devBemVindo(comPlano?: boolean): Promise<void> {
    this.modalCtrl.dismiss()

    this.router.navigateByUrl('/bem-vindo', {
      state: {
        testes: true,
        comPlano
      }
    })
  }

  async abrirManutencaoENascimento(): Promise<void> {
    this.modalCtrl.dismiss()

    this.router.navigateByUrl('/relatorios/manutencao-genealogia')
  }

  async abrirImportacaoGenealogia(): Promise<void> {
    this.modalCtrl.dismiss()

    this.router.navigateByUrl('/animais/importacao/genealogia')
  }

  async devMigrarManejosReprodutivos(): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circular', 'Migrando Manejos Reprodutivos')
    const response = await this.apiCtrl.getComPrefixoPropriedade({
      url: 'manejos/reprodutivos/migrar'
    })
    this.utilsCtrl.dismissLoading(loading)
  }

  async devMigrarManejosSanitarios(): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circular', 'Migrando Manejos Sanitários')
    const response = await this.apiCtrl.getComPrefixoPropriedade({
      url: 'manejos/sanitarios/migrar'
    })
    this.utilsCtrl.dismissLoading(loading)
  }

  async devMigrarManejosGerais(): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circular', 'Migrando Manejos Gerais')
    const response = await this.apiCtrl.getComPrefixoPropriedade({
      url: 'manejos/gerais/migrar'
    })
    this.utilsCtrl.dismissLoading(loading)
  }

  async limparStorageLog(): Promise<void> {
    await Preferences.remove({
      key: 'ultimo-log'
    })
  }

  async sincronizarCusto(): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circular', 'Sincronizando Custos')
    try {
      await this.apiCtrl.getComPrefixoPropriedade({
        url: 'sincronizar-custos'
      })
    } catch (error) {
      console.error(error)
    }
    this.utilsCtrl.dismissLoading(loading)
  }

  async abrirAtualizacoes(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: AtualizacoesPage,
      cssClass: 'custom-modal',
      componentProps: {}
    })

    await modal.present()
  }

  async abrirModalAssinatura(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAssinaturaPage,
      cssClass: 'custom-modal',
      componentProps: {}
    })

    await modal.present()
  }

  async openLinkWhatsapp(): Promise<void> {
    this.planoCtrl.entrarEmContatoPorWhatsapp()
  }

  async openModalNotificacoes(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalNotificacoesPage,
      cssClass: 'custom-modal'
    })

    await modal.present()
  }

  abrirTermosDeUso() {
    Browser.open({ url: 'https://fertili.com.br/termos-de-uso' })
  }

  abrirPoliticaDePrivacidade() {
    Browser.open({ url: 'https://fertili.com.br/politica-de-privacidade' })
  }
}
