import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { ListaManejosProp } from 'src/app/pages/manejos/manejos-disponiveis/manejos-disponiveis.component'
import { PopoverCampos } from 'src/app/services/manejos.service'
import { IOptionButtons, OpcoesBotoesComponent } from '../opcoes-botoes/opcoes-botoes.component'
import { StatusTabela } from '../vasta-tabela/tabela-types'

@Component({
  selector: 'app-manejo-seletor',
  templateUrl: './manejo-seletor.component.html',
  styleUrls: ['./manejo-seletor.component.scss']
})
export class SeletorManejosComponent implements OnInit {
  @Input() status: StatusTabela = 'carregando'
  @Input() manejosDisponiveis: ListaManejosProp[] = []
  @Output() manejosDisponiveisChange: EventEmitter<ListaManejosProp[]> = new EventEmitter()

  @Input() manejosSelecionados: ListaManejosProp[] = []
  @Output() manejosSelecionadosChange: EventEmitter<ListaManejosProp[]> = new EventEmitter()

  @Input() manejosSelecionadosStrings: string[] = []
  @Input() resumos: {} = {}
  @Input() salvando: '' | 'pesagem' | 'ordenha' | 'reprodutivo' | 'sanitario' | 'geral' | 'nutricao' | 'lotes' | 'areas' | 'genetica' = ''

  @Input('animaisAssociados') animaisAssociados: {} = {}
  @Output() animaisAssociadosChange = new EventEmitter<unknown>()

  @Input('animaisExternosAssociados') animaisExternosAssociados: {} = {}
  @Output() animaisExternosAssociadosChange = new EventEmitter<unknown>()

  @Input('estoquesAssociados') estoquesAssociados: {} = {}
  @Output() estoquesAssociadosChange = new EventEmitter<unknown>()

  @Output('acaoManejo') acaoManejo = new EventEmitter<{
    ev: Event
    identificador: string
    opcoesParaTodosManejos?: boolean
  }>()

  @Input('tipo') tipo: 'Animais' | 'Lotes' | 'Áreas'
  @Output() manejoDeOutraAbaSelecionado = new EventEmitter<{ aba: string; manejosSelecionados: ListaManejosProp[] }>()

  @Output() adicionarManejo = new EventEmitter<unknown>()

  @Input() filtrosManejos: { [key: string]: { label: string, valor: string } } = {}
  @Output() filtrosManejosChange = new EventEmitter<{
    [key: string]: {
      [key: string]: 'preenchidos' | 'nao-preenchidos'
    }
  }>()
  public opcoesFiltrosManejos: { [key: string]: { label: string, valor: string } } = {}
  public popoverCampos = PopoverCampos

  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit(): void { }

  selecionarFiltro(event: Event, filtro?: ListaManejosProp): void {
    event.stopPropagation()
    if (!filtro) {
      this.manejosSelecionadosStrings = []
      this.opcoesFiltrosManejos = {}
      return this.emit()
    }
    if (this.manejosSelecionadosStrings.includes(filtro.hash || filtro.procedimento)) {
      this.manejosSelecionadosStrings = this.manejosSelecionadosStrings.filter(
        (f) => f !== (filtro.hash || filtro.procedimento)
      )
      delete this.opcoesFiltrosManejos[filtro.hash || filtro.procedimento]
    } else {
      this.manejosSelecionadosStrings.push(filtro.hash || filtro.procedimento)
    }
    this.emit()
  }

  async popoverOpcoes(event: Event, manejo?: ListaManejosProp): Promise<void> {
    event.stopPropagation()

    if (manejo) {
      this.acaoManejo.emit({
        ev: event,
        identificador: manejo.tipo === 'sanitario' ? manejo.procedimento : manejo.identificador
      })
    } else {
      this.acaoManejo.emit({
        ev: event,
        identificador: null,
        opcoesParaTodosManejos: true
      })
    }
  }

  emit(): void {
    this.manejosSelecionados = this.manejosDisponiveis.filter((f) =>
      this.manejosSelecionadosStrings.includes(f.hash || f.procedimento)
    )
    this.manejosDisponiveisChange.emit(this.manejosDisponiveis)
    this.manejosSelecionadosChange.emit(this.manejosSelecionados)
  }

  adicionarManejoTrigger(): void {
    this.adicionarManejo.emit()
  }

  emitFiltroManejos(): void {
    const filtrosFormatados = {}

    Object.keys(this.opcoesFiltrosManejos).map((identificador) => {
      const tipoManejo = this.manejosDisponiveis.find((m) => m.hash === identificador || m.procedimento === identificador)?.tipo

      filtrosFormatados[tipoManejo] = filtrosFormatados[tipoManejo] || {}
      filtrosFormatados[tipoManejo][identificador] = this.opcoesFiltrosManejos[identificador].valor

      return filtrosFormatados
    })

    this.filtrosManejosChange.emit(filtrosFormatados)
  }

  async selecionarFiltroManejo(event: Event, manejoSelecionado: ListaManejosProp): Promise<void> {
    event.stopPropagation()
    const options = [
      {
        icone: 'rebanho',
        titulo: 'Todos os Animais',
        chave: 'todos',
        acao: ({ chave }: IOptionButtons, { procedimento }: ListaManejosProp): void => {
          this.opcoesFiltrosManejos[procedimento] = {
            label: 'Todos os Animais',
            valor: chave
          }
          this.emitFiltroManejos()
        }
      },
      {
        icone: 'document-text-outline',
        titulo: 'Preenchidos',
        chave: 'preenchidos',
        acao: ({ chave }: IOptionButtons, { procedimento }: ListaManejosProp): void => {
          this.opcoesFiltrosManejos[procedimento] = {
            label: 'Preenchidos',
            valor: chave
          }
          this.emitFiltroManejos()
        }
      },
      {
        icone: 'remove-outline',
        titulo: 'Não Preenchidos',
        chave: 'nao-preenchidos',
        acao: ({ chave }: IOptionButtons, { procedimento }: ListaManejosProp): void => {
          this.opcoesFiltrosManejos[procedimento] = {
            label: 'Não Preenchidos',
            valor: chave
          }
          this.emitFiltroManejos()
        }
      },
    ]

    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: { options },
      event: event,
      reference: 'trigger',
      showBackdrop: false,
      size: 'cover',
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()
    if (data) {
      data.acao(data, manejoSelecionado)
    }
  }

  removerFiltroManejo(event: Event, manejoSelecionado: ListaManejosProp): void {
    event.stopPropagation()
    delete this.opcoesFiltrosManejos[manejoSelecionado.hash || manejoSelecionado.procedimento]
    this.emitFiltroManejos()
  }

  verificarSeFiltroAtivo(manejo: ListaManejosProp): boolean {
    const filtro = this.opcoesFiltrosManejos[manejo.hash || manejo.procedimento]
    if (filtro) {
      return filtro.valor !== 'default'
    }
  }
}
