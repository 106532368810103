import { Component, OnInit, Input } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Cobrancas } from 'src/app/utils/interfaces/plano-assinatura.interface'

@Component({
  selector: 'app-modal-assinatura-cobranca',
  templateUrl: './modal-assinatura-cobranca.page.html',
  styleUrls: ['./modal-assinatura-cobranca.page.scss'],
})
export class ModalAssinaturaCobrancaPage implements OnInit {

  @Input() cobranca: Cobrancas

  public assinaturaStatus = {
    active: 'Ativa',
    canceled: 'Cancelada',
	  cancelled: 'Cancelada',
    future: 'Futura'
  }

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit(): void {
  }

  modalClose(): void {
    this.modalCtrl.dismiss()
  }

}
