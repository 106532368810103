import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-paginate',
  templateUrl: './paginate.component.html',
  styleUrls: ['./paginate.component.scss']
})
export class PaginateComponent implements OnInit {
  @Input('totalItens') totalItens: number | string
  @Input('mode') mode: 'slim' | string = ''
  @Output() paginateChange = new EventEmitter()

  @Input('limit') limit: number = 50
  @Output() limitChange = new EventEmitter()
  @Input('offset') offset: number = 0
  @Output() offsetChange = new EventEmitter()
  @Input('currentPage') currentPage: number = 1
  @Output() currentPageChange = new EventEmitter()

  totalPages: number[]
  pagesDisplayed: number[]
  endPage: number
  ultimaPagina: number = null
  mostraPrimeira: boolean = false
  mostraPrimeiraReticencia: boolean = false
  mostraUltima: boolean = false
  mostraUltimaReticencia: boolean = false

  constructor() {}

  ngOnInit(): void {
    this.processaPaginas()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processaPaginas()
  }

  processaPaginas(): void {
    if (this.totalItens && this.limit) {
      const pages = this.totalItens
      this.totalPages = Array.from(Array(Number(pages)).keys()).map((i) => i + 1)
    } else {
      this.totalPages = [1]
    }
    this.calcularMaximoDeItensVisiveisDaPaginacao()
  }

  emitTimeout

  emit(offset = 0): void {
    clearTimeout(this.emitTimeout)
    this.emitTimeout = setTimeout(() => {
      this.paginateChange.emit({
        currentPage: this.currentPage,
        limit: this.limit || 50,
        total: this.totalItens,
        offset
      })
      this.limitChange.emit(this.limit)
      this.offsetChange.emit(this.offset)
      this.currentPageChange.emit(this.currentPage)
    }, 500)
  }

  nextPage(): void {
    this.currentPage++
    if (this.currentPage > this.totalPages.length) this.currentPage = this.totalPages.length
    const offset = (this.currentPage - 1) * (this.limit || 50)
    this.emit(offset)
    this.calcularMaximoDeItensVisiveisDaPaginacao()
  }

  prevPage(): void {
    this.currentPage--
    if (this.currentPage < 1) this.currentPage = 1
    const offset = (this.currentPage - 1) * (this.limit || 50)
    this.emit(offset)
    this.calcularMaximoDeItensVisiveisDaPaginacao()
  }

  goToPage(page: number): void {
    this.currentPage = page
    const offset = (this.currentPage - 1) * (this.limit || 50)
    this.emit(offset)
    this.calcularMaximoDeItensVisiveisDaPaginacao()
  }

  calcularMaximoDeItensVisiveisDaPaginacao(): void {
    const { pages, endPage, totalPages } = this.paginate({
      totalItems: this.totalPages.length,
      currentPage: this.currentPage,
      pageSize: this.limit || 50,
      maxPages: this.currentPage < 3 ? 4 : 3
    })
    this.pagesDisplayed = pages
    this.endPage = endPage
    this.ultimaPagina = totalPages
    this.mostraPrimeira = !this.pagesDisplayed.includes(1)
    this.mostraPrimeiraReticencia = !this.pagesDisplayed.includes(2)
    this.mostraUltima = totalPages > 3 && !this.pagesDisplayed.includes(totalPages)
    this.mostraUltimaReticencia = totalPages > 3 && !this.pagesDisplayed.includes(totalPages - 1)
  }

  paginate({
    totalItems = 0,
    currentPage = 1,
    pageSize = 10,
    maxPages = 10
  }: {
    totalItems: number
    currentPage: number
    pageSize: number
    maxPages: number
  }): {
    totalItems: number
    currentPage: number
    pageSize: number
    totalPages: number
    startPage: number
    endPage: number
    startIndex: number
    endIndex: number
    pages: number[]
  } {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize)

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1
    } else if (currentPage > totalPages) {
      currentPage = totalPages
    }

    let startPage: number, endPage: number
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1
      endPage = totalPages
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1
        endPage = maxPages
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1
        endPage = totalPages
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage
        endPage = currentPage + maxPagesAfterCurrentPage
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i)

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    }
  }
}
