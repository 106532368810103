import { Component, Input, ViewChild, ElementRef, ApplicationRef } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'

@Component({
  selector: 'chat-audio-player',
  templateUrl: './chat-audio-player.component.html',
  styleUrls: ['./chat-audio-player.component.scss']
})
export class ChatAudioPlayerComponent {
  @Input() fileUrl!: string | SafeUrl
  @Input() duration!: number

  @ViewChild('audioRef') audioRef!: ElementRef<HTMLAudioElement>

  isPlaying = false
  currentTime = 0
  playbackSpeed = 1

  constructor(private ref: ApplicationRef) {

  }

  ngAfterViewInit(): void {
    const audio = this.audioRef.nativeElement
    audio.addEventListener('ended', () => {
      this.handleAudioEnd()
    })
  }

  play(): void {
    const audio = this.audioRef.nativeElement
    audio.play()
    this.isPlaying = true
    try {
      this.ref.tick()
    } catch (e) {
      console.error(e)
    }
  }

  pause(): void {
    const audio = this.audioRef.nativeElement
    audio.pause()
    this.isPlaying = false
    try {
      this.ref.tick()
    } catch (e) {
      console.error(e)
    }
  }

  updateCurrentTime(): void {
    if (!this.isPlaying) return
    const audio = this.audioRef.nativeElement
    this.currentTime = Math.floor(audio.currentTime * 1000)
    
    if (audio.currentTime === audio.duration) {
      this.handleAudioEnd()
    }
    
    try {
      this.ref.tick()
    } catch (e) {
      console.error(e)
    }
  }

  handleAudioEnd(): void {
    const audio = this.audioRef.nativeElement
    this.pause()
    this.currentTime = 0
    audio.currentTime = 0
  }

  seekTo(event: Event): void {
    const audio = this.audioRef.nativeElement
    const target = event.target as HTMLInputElement
    const time = Number(target.value) / 1000
    audio.currentTime = time
  }

  togglePlaybackSpeed(): void {
    console.log('togglePlaybackSpeed')
    const audio = this.audioRef.nativeElement
    if (this.playbackSpeed === 1) {
      this.playbackSpeed = 1.5
    } else if (this.playbackSpeed === 1.5) {
      this.playbackSpeed = 2
    } else {
      this.playbackSpeed = 1
    }
    audio.playbackRate = this.playbackSpeed
    this.ref.tick()
  }

  get displayTime(): string {
    const minutes = Math.floor(this.currentTime / 60000)
    const seconds = Math.floor((this.currentTime % 60000) / 1000)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  get displayDuration(): string {
    const minutes = Math.floor(this.duration / 60000)
    const seconds = Math.floor((this.duration % 60000) / 1000)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }
}
