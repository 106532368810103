/* eslint-disable indent */
import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertController, IonContent, ModalController, NavController, PopoverController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import * as moment from 'moment'
import { IOptionButtons, OpcoesBotoesComponent } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao, TabelaFiltroExtra } from 'src/app/components/vasta-tabela/vasta-tabela'
import { AnimaisService } from 'src/app/services/animais.service'
import { AreasService } from 'src/app/services/areas.service'
import { ImportacaoService } from 'src/app/services/importacao.service'
import { LotesService } from 'src/app/services/lotes.service'
import {
  IManejoGeralUnitario,
  manejoGeralTipos,
  manejoReprodutivoTipos,
  manejoSanitarioTipos,
  ManejosService
} from 'src/app/services/manejos.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ColunasManejosTodos, ColunasMovimentacoes } from 'src/app/utils/configuracoes-padrao'
import { IAnimal, IGetAnimais } from 'src/app/utils/interfaces/animais.interface'
import { IHistoricoLote, ILote } from 'src/app/utils/interfaces/lotes.interface'
import { ResumoManejos } from 'src/app/utils/interfaces/manejos.interface'
import { ConfigTabela, IKpisCard } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalListaAreasPage } from '../../areas/modal-lista-areas/modal-lista-areas.page'
import { ModalAlterarSituacaoComponent } from '../../bovinos/modal-alterar-situacao/modal-alterar-situacao.component'
import { ModalBovinoComponent } from '../../bovinos/modal-bovino/modal-bovino.component'
import { ModalListaAnimaisPage } from '../../bovinos/modal-lista-animais/modal-lista-animais.page'
import { CalendarioEventoCadastrarPage } from '../../calendario/calendario-evento-cadastrar/calendario-evento-cadastrar.page'
import { ModalCompraVendaComponent } from '../../financas/modal-compra-venda/modal-compra-venda.component'
import { listaManejosDisponiveis } from '../../manejos/manejos-disponiveis/lista-manejos-disponiveis'
import { ModalEdicaoLoteComponent } from '../modal-edicao-lote/modal-edicao-lote.component'

@Component({
  selector: 'app-lote-single',
  templateUrl: './lote-single.component.html',
  styleUrls: ['./lote-single.component.scss']
})
export class LoteSingleComponent implements OnInit {
  @ViewChild(IonContent) content: IonContent

  public abaAtiva = 'Animais'
  public carregamentoAnimais: StatusTabela = 'carregando'
  public config: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    selecao: {
      ativar: true
    },
    buscaDados: async () => {
      const [response, error] = await this.animaisCtrl.getAnimais({
        filtros: this.novoFiltroAnimais,
        ordenacao: this.novaOrdenacao,
        paginate: { offset: 0, limit: 5000 }
      })

      const currentHashs = this.animaisSelecionados.map(a => a.hash)
      this.animaisSelecionados = [...this.animaisSelecionados, ...response.dados.filter(animal => !currentHashs.includes(animal.hash))]

      return {
        dados: response.dados
      }
    },
    impressaoTitulo: 'Lote',
    ativarDownload: true,
    ativarImpressao: true,
    orderby: 'numeracao',
    orderbyDirection: 'asc',
    excluirColunas: ['extra_lote']
  }
  public animais: IAnimal[]

  public todosManejosLabels = ColunasManejosTodos
  public todosManejosConfig: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    selecao: {
      ativar: false
    },
    exibeTotais: false,
    excluirColunas: ['qtdAnimais']
  }
  public carregamentoTodosManejos: StatusTabela = 'carregando'
  public todosManejos: ResumoManejos[] = []
  public paginateTodosManejos: { limit: number; offset: number; total: number } = { limit: 50, offset: 0, total: 0 }
  public totaisManejos: Record<string, string | number>

  public listaManejosGerais: string[] = manejoGeralTipos
  public listaManejosReprodutivos: string[] = manejoReprodutivoTipos
  public listaManejosSanitarios: string[] = manejoSanitarioTipos

  public manejosDisponiveis = listaManejosDisponiveis.manejos
    .concat(
      listaManejosDisponiveis.manejosReprodutivos,
      listaManejosDisponiveis.manejosSanitarios,
      listaManejosDisponiveis.manejosLotes,
      listaManejosDisponiveis.manejosAreas
    )
    .reduce((acc, item) => {
      acc[item.identificador] = { icon: item.icon, nome: item.nome }
      return acc
    }, {})

  public novoFiltroManejos: NovoFiltro = { hash_lote: '' }
  public filtrosExtras: TabelaFiltroExtra[] = [
    {
      chave: 'procedimento',
      titulo: 'Manejos',
      tipo: 'lista',
      icone: 'pesagem-controle',
      acao: async (event: Event, filtro: TabelaFiltroExtra): Promise<void> => {
        console.log(event)
        const selecionado = await this.selecionarTipoDeManejo(event, false)
        if (selecionado) {
          this.novoFiltroManejos['procedimento'] = selecionado.chave

          filtro.valor = selecionado.titulo
          this.getTodosManejos()
        }
      }
    }
  ]

  public novoFiltroAnimais: NovoFiltro = { situacao: 'vivo' }
  public novaOrdenacao: Ordenacao = { coluna: 'numeracao', direcao: 'desc' }
  public filtrosFixos: string[] = ['extra_lote']
  public paginate: { limit: number; offset: number; total: number } = { limit: 50, offset: 0, total: 0 }
  public lote: ILote

  public optionsKpisCard: IKpisCard[] = [
    {
      key: 'nome_area',
      titulo: 'Área',
      valor: '',
      escondeSeVazio: true,
      acao: (): void => {
        this.abrirArea()
      },
      classe: 'permissao-leitura-areas'
    },
    {
      key: 'data_entrada_area',
      titulo: 'Entrada na Área',
      valor: '',
      formatacao: 'date',
      escondeSeVazio: true,
      classe: 'permissao-leitura-areas'
    },
    { key: 'sem_area_desde', titulo: 'Sem Área Desde', valor: '', formatacao: 'date', escondeSeVazio: true },
    { key: 'aptidao', titulo: 'Aptidão', valor: '', capitalize: true, escondeSeVazio: true },
    { key: 'finalidade', titulo: 'Finalidade', valor: '', capitalize: true, escondeSeVazio: true },
    {
      key: 'total_peso',
      titulo: 'Total',
      valor: 0,
      capitalize: true,
      escondeSeVazio: true,
      unidade: 'Kg',
      formatacao: 'number'
    },
    {
      key: 'peso_medio',
      titulo: 'Peso Médio',
      valor: 0,
      capitalize: true,
      escondeSeVazio: true,
      unidade: 'Kg',
      formatacao: 'number'
    },
    {
      key: 'media_gmd',
      titulo: 'Média dos GMDS',
      valor: 0,
      capitalize: true,
      escondeSeVazio: true,
      unidade: 'Kg',
      formatacao: 'gmd'
    },
    {
      key: 'tipo_lote',
      titulo: 'Tipo de Lote',
      escondeSeVazio: true
    },
    {
      key: 'total_resultados',
      titulo: 'Total de Animais',
      valor: 0,
      formatacao: 'number',
    }
  ]

  kpisTotais: { [key: string]: string | unknown } = {
    nome_area: null,
    data_entrada_area: null,
    sem_area_desde: null,
    aptidao: null,
    finalidade: null,
    total_peso: null,
    media_gmd: null,
    tipo_lote: null,
    total_resultados: null
  }

  public optionsButtonsSelecionadosTabela: IOptionButtons[] = [
    {
      icone: 'add-circle-outline',
      chave: 'novo-manejo',
      titulo: 'Novo Manejo',
      exibirCalendario: true,
      acao: ({ hashsSelecionados, date }: DadosLinhaTabela): void => {
        this.novoManejo(hashsSelecionados as string[], date as string)
      }
    },
    {
      icone: 'cash',
      titulo: 'Registrar Venda',
      chave: 'registrar-venda',
      classe: 'permissao-escrita-financeiro',
      acao: ({ hashsSelecionados }: DadosLinhaTabela): void => {
        this.registrarVenda(hashsSelecionados as string[])
      }
    },
    {
      chave: 'excluirAnimais',
      classe: 'permissao-escrita-animais',
      icone: 'trash-outline',
      titulo: 'Excluir animais',
      disabled: false,
      acao: ({ hashsSelecionados }: DadosLinhaTabela): void => {
        this.alertRemoverAnimal(null, hashsSelecionados as string[])
      }
    },
    {
      chave: 'exportarAnimais',
      classe: 'permissao-escrita-animais',
      icone: 'cloud-download-outline',
      titulo: 'Exportar animais',
      disabled: false
    },
    {
      chave: 'agendarEvento',
      icone: 'calendar-outline',
      titulo: 'Agendar Evento',
      classe: 'permissao-escrita-animais',
      disabled: false,
      acao: async ({ dadosSelecionados }: DadosLinhaTabela): Promise<void> => {
        const modal = await this.modalCtrl.create({
          component: CalendarioEventoCadastrarPage,
          componentProps: { animaisSelecionados: dadosSelecionados },
          cssClass: 'custom-modal'
        })

        await modal.present()
        const { data } = await modal.onWillDismiss()
        if (data?.sucesso) {
          this.hashsSelecionados = []
          this.content.scrollToTop()
        }
      }
    }
  ]
  public optionsButtonsAnimais: IOptionButtons[] = [
    {
      icone: 'open-outline',
      titulo: 'Abrir em Nova Aba',
      classe: 'option-web-only',
      acao: ({ original }: DadosLinhaTabela): void => {
        window.open(`/animais/${encodeURIComponent(original.hash)}`, '_blank')
      }
    },
    {
      icone: 'add-circle-outline',
      titulo: 'Novo Manejo',
      exibirCalendario: true,
      acao: ({ original }: DadosLinhaTabela, date: string): void => {
        this.novoManejo([original.hash] as string[], date as string)
      }
    },
    {
      icone: 'cash',
      classe: 'permissao-escrita-financeiro',
      chave: 'registrar-venda',
      titulo: 'Registrar Venda',
      acao: ({ original }: DadosLinhaTabela): void => {
        if (!this.animais.find((a) => a.hash == original.hash)) {
          this.animais.push(original as unknown as IAnimal)
        }
        this.registrarVenda([original.hash] as string[])
      }
    },
    {
      chave: 'editarAnimal',
      icone: 'pencil-outline',
      titulo: 'Editar Animal',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        this.openModalBovino(linha.original as unknown as IAnimal)
      }
    },
    {
      chave: 'alterarObservacao',
      classe: 'permissao-escrita-animais',
      icone: 'chatbox-outline',
      titulo: 'Alterar Observação',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        this.alertAlterarObservacao(linha.original as unknown as IAnimal)
      }
    },
    {
      chave: 'duplicarAnimal',
      icone: 'documents-outline',
      classe: 'permissao-escrita-animais',
      titulo: 'Duplicar Animal',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        this.alertDuplicarAnimal(linha.original as unknown as IAnimal)
      }
    },
    {
      chave: 'apagarAnimal',
      icone: 'trash-outline',
      classe: 'permissao-escrita-animais',
      titulo: 'Apagar Animal',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        this.alertRemoverAnimal(linha.original as unknown as IAnimal)
      }
    }
  ]

  public hashsSelecionados: string[] = []
  public isCarregandoLote = false
  public animaisSelecionados: IAnimal[] = []

  public optionsButtonAdicionarAnimal: IOptionButtons[] = [
    {
      chave: 'cadastrarAnimaisExistentes',
      icone: 'bovino',
      classe: 'permissao-escrita-animais',
      titulo: 'Selecionar Animais Existentes'
    },
    {
      chave: 'cadastrarAnimal',
      icone: 'mais',
      classe: 'permissao-escrita-animais',
      titulo: 'Cadastrar Animal'
    },
    {
      chave: 'cadastrarMultiplos',
      icone: 'rebanho',
      classe: 'permissao-escrita-animais',
      titulo: 'Cadastrar Múltiplos Animais'
    }
  ]

  // tabela historico
  public configTabelaHistorico = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: false,
    impressaoTitulo: 'Histórico',
    ocultarFiltros: true
  }
  public labelsHistorico = ColunasMovimentacoes
  public historicoLotes: IHistoricoLote[]
  public carregamentoHistoricoLotes: StatusTabela = 'carregando'

  public hash: string

  constructor(
    private route: ActivatedRoute,
    private animaisCtrl: AnimaisService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private areasCtrl: AreasService,
    private lotesCtrl: LotesService,
    private router: Router,
    private popoverCtrl: PopoverController,
    private manejosCtrl: ManejosService,
    private utilsCtrl: IonicUtilsService,
    private importacaoCtrl: ImportacaoService,
    private navCtrl: NavController,
    private lotesService: LotesService
  ) { }

  async ngOnInit(): Promise<void> {
    this.route.url.subscribe(async () => {
      if (this.abaAtiva === 'Manejos') {
        this.getTodosManejos()
      }
      if (!this.novoFiltroManejos.hash_lote) {
        await this.buscarDados()
      }
      await this.getAnimais()

      this.utilsCtrl.tick()
    })
  }

  async buscarDados(): Promise<void> {
    this.isCarregandoLote = true

    this.hash = decodeURIComponent(this.route.snapshot.paramMap.get('hash'))

    this.novoFiltroManejos.hash_lote = this.hash

    const state = this.router.getCurrentNavigation().extras.state
    if (state) {
      this.lote = state?.dadosLote
      this.setTituloImpressao()

      this.isCarregandoLote = false

      if (this.hash === 'sem_lote') {
        this.lote = {}
        this.config.impressaoTitulo = 'Animais sem lote'
      }
      this.novoFiltroAnimais['extra_lote'] = this.hash
    } else {
      this.isCarregandoLote = true
      if (this.hash === 'sem_lote') {
        this.lote = {}
        this.config.impressaoTitulo = 'Animais sem lote'
      } else {
        this.lote = await this.lotesCtrl.getLoteByHash(this.hash)
        this.setTituloImpressao()
        this.isCarregandoLote = false
      }
      this.novoFiltroAnimais['extra_lote'] = this.hash

      this.isCarregandoLote = false
    }

    // if (this.lote) {
    //   this.inserirDadosKpisCard(this.lote)
    // }
  }

  async getAnimais(): Promise<IGetAnimais> {
    this.content?.scrollToTop(500)

    this.carregamentoAnimais = 'carregando'

    const [response, error] = await this.animaisCtrl.getAnimais({
      filtros: this.novoFiltroAnimais,
      ordenacao: this.novaOrdenacao,
      paginate: this.paginate
    })

    if (response) {
      this.carregamentoAnimais = 'sucesso'
      this.animais = response.dados
      this.paginate.total = Number(response.totais.total_resultados || '0')

      this.config.impressaoKpiCardConfig = this.optionsKpisCard
      const loteSingleKpiValores = {
        ...this.lote,
        ...response.totais
      }

      this.kpisTotais = loteSingleKpiValores
      this.config.impressaoKpiValores = loteSingleKpiValores

      return response
    }

    if (error) {
      this.carregamentoAnimais = 'erro'
      console.log(error)
    }
  }

  async abrirArea(): Promise<void> {
    const [response, error] = await this.areasCtrl.getAreas({
      filtros: {
        hash: this.lote.hash_area
      }
    })

    console.log(response)

    if (response && response.dados.length) {
      this.router.navigateByUrl(`areas/${encodeURIComponent(this.lote.hash_area)}`, {
        state: { ...response.dados[0] }
      })
    }

    if (error) {
      console.error(error)
    }
  }

  selecionouAnimal(dado: DadosLinhaTabela): void {
    const animal = dado.original as unknown as IAnimal
    this.router.navigateByUrl(`/animais/${encodeURIComponent(animal.hash)}`, { state: { animal } })
  }

  getHashsSelecionados(selecionados: string[]): void {
    this.hashsSelecionados = selecionados

    if (!this.hashsSelecionados.length) {
      this.animaisSelecionados = []
    } else {
      const novosAnimais = this.animais.filter(
        (a) => this.hashsSelecionados.includes(a.hash) && !this.animaisSelecionados.map((a) => a.hash).includes(a.hash)
      )
      this.animaisSelecionados = [...this.animaisSelecionados, ...novosAnimais]

      this.animaisSelecionados = this.animaisSelecionados.filter((a) => this.hashsSelecionados.includes(a.hash))
    }
  }

  async openModalLote(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEdicaoLoteComponent,
      componentProps: { loteParams: this.lote },
      cssClass: 'custom-modal',
      backdropDismiss: false
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (!data) return

    if (data.success) {
      this.atualizaInfoLote()
    }

    if (data.loteDeletado) {
      this.navCtrl.pop()
    }
  }

  async atualizaInfoLote(): Promise<void> {
    const [response, error] = await this.lotesCtrl.getLotes({
      filtros: { hash: this.lote.hash }
    })

    if (response) {
      this.lote = response.dados[0]
      this.setTituloImpressao()
      this.config.impressaoKpiValores = response.dados[0]
      VastaRX.setState('atualizar-lote', true)
      this.kpisTotais.nome_area = response.dados[0].nome_area
    }

    if (error) {
      console.error(error)
    }
  }

  setTituloImpressao(): void {
    try {
      this.config.impressaoTitulo = `Lote ${this.lote?.nome}`
    } catch (_) {
      this.config.impressaoTitulo = 'Lote'
    }
  }

  tabChange(): void {
    if (this.abaAtiva == 'Histórico') this.getHistorico()
    if (this.abaAtiva == 'Manejos') this.getTodosManejos()
  }

  async getHistorico(): Promise<void> {
    this.carregamentoHistoricoLotes = 'carregando'
    const [response, error] = await this.lotesCtrl.getHistorico({
      hashLote: this.lote.hash
    })

    if (response) {
      this.historicoLotes = response.dados
      this.carregamentoHistoricoLotes = 'sucesso'
      console.log({ response })
    }

    if (error) {
      this.carregamentoHistoricoLotes = 'erro'
      console.error(error)
    }
  }

  irParaPesagemAnimal(): void {
    const dadosPesagem = {
      data_pesagem: moment(new Date()).format('YYYY-MM-DD')
    }

    // this.ionNav.push(PesagemAnimalComponent, { dadosPesagem, lote: this.lote })
  }

  async showPopoverButtonAdicionarAnimal(ev: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: { options: this.optionsButtonAdicionarAnimal },
      event: ev,
      reference: 'trigger'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data) {
      if (data.chave === 'cadastrarAnimaisExistentes') {
        this.showModalListaAnimais()
      }

      if (data.chave === 'cadastrarAnimal') {
        this.showModalCadastrarAnimal()
      }

      if (data.chave === 'cadastrarMultiplos') {
        this.router.navigateByUrl('animais/importacao', {
          state: {
            loteProp: { hash: this.lote.hash, nome: this.lote.nome }
          }
        })
      }
    }
  }

  async showModalListaAnimais(): Promise<void> {
    const hashNotIn = this.animais.map((animal) => animal.hash)
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      cssClass: 'custom-modal-animais',
      componentProps: { selecionarMultiplos: true, hashNotIn, somenteInternos: true }
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()

    if (data) {
      this.manejoMovimentacaoEntreLotesOuConfinamento(data.hashs || [data.hash])
    }
  }

  async showModalCadastrarAnimal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalBovinoComponent,
      cssClass: 'custom-modal',
      backdropDismiss: false,
      componentProps: { loteProp: { hash: this.lote.hash, nome: this.lote.nome } }
    })

    await modal.present()
    await modal.onWillDismiss()
    this.getAnimais()
  }

  async moverAnimaisParaOLote(hashLote: string, hashsAnimais: string[]): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('dots', 'Adicionando animais ao lote...')
    const [response, error] = await this.animaisCtrl.atualizarAnimal({ hash_lote: hashLote }, { hashs: hashsAnimais })
    if (response) {
      this.utilsCtrl.showToast('Animais adicionados ao lote com sucesso!', 'bottom')
      this.getAnimais()
    }

    if (error) {
      this.utilsCtrl.showToast('Erro ao adicionar animais ao lote!', 'bottom')
    }

    this.utilsCtrl.dismissLoading(loading)
  }

  // Remover animal/animais
  async alertRemoverAnimal(animal: IAnimal, hashs?: string[]): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Remover Animal',
      message: hashs?.length
        ? `Confirmar remoção de ${hashs.length} animais?`
        : `Confirmar remoção do animal? ${animal.nome || animal.numeracao}`,
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Remover',
          handler: async (): Promise<void> => {
            const loading = await this.utilsCtrl.showLoading('circular', 'Removendo animal...')

            const [response, error] = await this.animaisCtrl.deletarAnimal(hashs || [animal.hash])

            if (response) {
              this.hashsSelecionados = []
              await this.getAnimais()
              this.utilsCtrl.showToast(
                hashs?.length > 1 ? 'Animais removidos com sucesso!' : 'Animal removido com sucesso!',
                'bottom'
              )
              this.utilsCtrl.tick()
            }

            if (error) {
              this.utilsCtrl.showToast(
                hashs?.length > 1 ? 'Erro ao remover animais!' : 'Erro ao remover animal!',
                'bottom'
              )
              console.error(error)
            }

            this.utilsCtrl.dismissLoading(loading)
          }
        }
      ]
    })

    await alert.present()
  }

  // Múltiplos animais selecionados
  async novoManejo(hashsAnimaisSelecionados: string[], data: string): Promise<void> {
    this.router.navigateByUrl(`/manejos/${data}`, {
      state: {
        data,
        hashsAnimais: hashsAnimaisSelecionados,
        animais: this.animaisSelecionados.filter((a) => hashsAnimaisSelecionados.includes(a.hash))
      }
    })
  }

  async registrarVenda(hashsAnimaisSelecionados: string[]): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalCompraVendaComponent,
      componentProps: {
        animaisProp: this.animaisSelecionados.filter((a) => hashsAnimaisSelecionados.includes(a.hash)),
        tipoTransacao: 'venda'
      },
      cssClass: 'modal-compra-venda'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.success) {
      this.hashsSelecionados = []
      await this.getAnimais()
    }
  }

  async openModalAlterarSituacao(hashs: string[]): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalAlterarSituacaoComponent,
      cssClass: 'custom-modal'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()
    if (data) {
      this.hashsSelecionados = []
      console.log(data)
      console.log(hashs)
    }
  }

  async openModalBovino(animal: IAnimal): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalBovinoComponent,
      backdropDismiss: false,
      componentProps: { animalProp: animal, hashAnimalProp: animal.hash },
      cssClass: 'custom-modal'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data?.success) {
      await this.getAnimais()
    }
  }

  // Alterar observação
  async alertAlterarObservacao(animal: IAnimal): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: `Alterar Observação de ${animal.nome || animal.numeracao}`,
      inputs: [
        {
          name: 'observacao',
          type: 'text',
          value: animal.observacao,
          placeholder: 'Observação'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Confirmar',
          handler: async (data: { observacao: string }): Promise<void> => {
            const loading = await this.utilsCtrl.showLoading('circular', 'Atualizando animal...')
            animal.observacao = data.observacao

            const [response, error] = await this.animaisCtrl.atualizarAnimal(animal)

            if (response) {
              await this.getAnimais()
              this.utilsCtrl.showToast('Observação alterada com sucesso!', 'bottom')
              this.utilsCtrl.tick()
            }

            if (error) {
              this.utilsCtrl.showToast('Erro ao alterar observação!', 'bottom')
              console.error(error)
            }

            this.utilsCtrl.dismissLoading(loading)
          }
        }
      ]
    })
    await alert.present()
  }

  // Duplicar animal
  async alertDuplicarAnimal(animal: IAnimal): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Insira a numeração do novo animal',
      inputs: [
        {
          type: 'number',
          name: 'numeracao',
          placeholder: 'VF-4243'
        },
        {
          type: 'text',
          name: 'nome',
          placeholder: 'Mimosa II'
        }
      ],
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Duplicar',
          handler: async (data: { numeracao: number; nome: string }): Promise<void> => {
            const loading = await this.utilsCtrl.showLoading('circular', 'Duplicando animal...')
            const [response, error] = await this.animaisCtrl.duplicarAnimal({
              hashAnimal: animal.hash,
              novaNumeracao: data.numeracao,
              novoNome: data.nome
            })

            if (response) {
              await this.getAnimais()
              this.utilsCtrl.tick()
              this.utilsCtrl.showToast('Animal duplicado com sucesso!', 'bottom')
            }

            if (error) {
              this.utilsCtrl.showToast('Erro ao duplicar animal!', 'bottom')
              console.error(error)
            }

            this.utilsCtrl.dismissLoading(loading)
          }
        }
      ]
    })

    await alert.present()
  }

  abrirManejosDoDia(dados: DadosLinhaTabela): void {
    const data = dados.original.data
    const { procedimento } = dados?.original || {}

    this.router.navigateByUrl(`/manejos/${data}`, {
      state: {
        data,
        abaAtivaParams: procedimento === 'movimentacao-lote' ? 'Animais' : 'Lotes'
      }
    })
  }

  async getTodosManejos(): Promise<void> {
    this.carregamentoTodosManejos = 'carregando'
    const [response, error] = await this.manejosCtrl.getTodosManejos({
      paginate: this.paginateTodosManejos,
      filtros: this.novoFiltroManejos
    })

    if (response) {
      this.totaisManejos = { total_resultados: response.datasDisponiveis }
      this.paginateTodosManejos.total = response.datasDisponiveis
      this.todosManejos = response.manejos
      this.carregamentoTodosManejos = 'sucesso'
    }

    if (error) {
      console.log(error)
      this.carregamentoTodosManejos = 'erro'
    }
  }

  async selecionarTipoDeManejo(ev: Event, importacao = true): Promise<{ chave: string; titulo: string }> {
    let options = []

    listaManejosDisponiveis.manejos
      .concat(listaManejosDisponiveis.manejosReprodutivos, listaManejosDisponiveis.manejosSanitarios)
      .map((manejo) => {
        options.push({ chave: manejo.identificador, titulo: manejo.nome, importavel: manejo.importavel })
      }, {})

    if (importacao) {
      options = options.filter((o) => o.importavel)
    }

    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: { options },
      event: ev,
      reference: 'trigger'
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()
    if (data) {
      return data
    }
  }

  async abrirManejo(dataParam: string | DadosLinhaTabela, editar = false): Promise<void> {
    const data = typeof dataParam === 'string' ? dataParam : dataParam.original.data

    let animais = this.animais

    if (this.paginate.total > this.animais.length) {
      const loading = await this.utilsCtrl.showLoading('circular', 'Buscando todos os animais...')
      const [response, error] = await this.animaisCtrl.getAnimais({
        filtros: this.novoFiltroAnimais,
        ordenacao: this.novaOrdenacao,
        paginate: { offset: 0, limit: 5000 }
      })

      this.utilsCtrl.dismissLoading(loading)
      animais = response.dados
    }


    this.router.navigateByUrl(`/manejos/${data}`, {
      state: { data, editar, hashsAnimais: animais.map((a) => a.hash), animais }
    })
  }

  async manejoMovimentacaoEntreLotesOuConfinamento(animaisHashs: string[]): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circular', 'Salvando Manejos...')
    const payloadManejos = animaisHashs.map((hash_animal) => {
      return {
        data: moment().format('YYYY-MM-DD'),
        hash_animal,
        [`${this.lote.confinamento ? 'hash_confinamento' : 'hash_lote'}`]: this.lote.hash,
        origem: 'cadastro',
        procedimento: this.lote.confinamento ? 'movimentacao-confinamento' : 'movimentacao-lote'
      }
    }) as IManejoGeralUnitario[]

    const [response, error] = await this.importacaoCtrl.salvarImportacaoManejosGerais({ manejos: payloadManejos })

    if (response) {
      this.getAnimais()
      this.utilsCtrl.showToast('Manejos salvos com sucesso', 'bottom')
    }

    if (error) {
      alert(error.message)
    }

    this.utilsCtrl.dismissLoading(loading)
  }

  async associarLoteAArea(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAreasPage,
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (!data?.areas) return

    const [area] = data?.areas

    if (this.lote.hash_area == area.hash) {
      return this.utilsCtrl.showToast('O lote já está na área selecionada', 'bottom')
    }


    const [sucesso, error] = await this.importacaoCtrl.salvarImportacaoManejosLotes({ manejos: [{
      data: moment().format('YYYY-MM-DD'),
      hash_area_destino: area.hash,
      hash_lote: this.lote.hash,
      procedimento: 'movimentacao-entre-areas'
    }] })

    if (sucesso) {
      this.utilsCtrl.showToast('Lote adicionado à área com sucesso', 'bottom')
      this.atualizaInfoLote()
    }

    if (error) {
      this.utilsCtrl.showToast('Erro ao adicionar lote à área', 'bottom')
    }
  }
}
