import { DEFAULT_CURRENCY_CODE, Injector, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { ComponentsModule } from './components/components.module'
import { HttpClientModule } from '@angular/common/http'
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common'
import { AuthGuard } from './guards/auth.guard'

import { ReactiveFormsModule } from '@angular/forms'

import localePt from '@angular/common/locales/pt'
import { BovinoSingleComponent } from './pages/bovinos/bovino-single/bovino-single.page'

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { MenuLateralPageModule } from './pages/menu-lateral/menu-lateral.module'
import { AreasPageModule } from './pages/areas/areas.module'
import { setAppInjector } from './utils/app-injector'
import { GenealogiaComponent } from './components/bovinos/genealogia/genealogia.component'
import { ManejosModule } from './pages/manejos/manejos.module'
import { EstoqueSinglePage } from './pages/estoque/estoque-single/estoque-single.page'
import { ExitCadastroManejoGuard } from './guards/exit-cadastro-manejo.guard'
import { ModalSelecionarVendaPage } from './pages/financas/modal-selecionar-venda/modal-selecionar-venda.page'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { provideMessaging, getMessaging } from '@angular/fire/messaging'
import { environment } from 'src/environments/environment'
import { PipesModule } from './pipes/pipes.module'
import { AtualizacoesPageModule } from './atualizacoes/atualizacoes.module'
import { LotesPage } from './pages/lotes/lotes.page'

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null

registerLocaleData(localePt, 'pt-br')

@NgModule({
  declarations: [AppComponent, BovinoSingleComponent, GenealogiaComponent, EstoqueSinglePage, ModalSelecionarVendaPage, LotesPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    ComponentsModule,
    PipesModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options),
    MenuLateralPageModule,
    AreasPageModule,
    ManejosModule,
    AtualizacoesPageModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideMessaging(() => getMessaging())
  ],
  providers: [
    AuthGuard,
    ExitCadastroManejoGuard,
    DecimalPipe,
    DatePipe,
    CurrencyPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pt-br' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector)
  }
}
