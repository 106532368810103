<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ propArea ? 'Editar Área' : 'Cadastrar Nova Área' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <cta-buttons>
    <button (click)="openModalDesenharArea()">
      <ion-icon name="pencil"></ion-icon>
      <span>{{ area.area ? 'Editar Demarcação da Área' : 'Demarcar Área' }}</span>
    </button>
  </cta-buttons>
</ion-header>
<ion-content>
  <vasta-input label="Nome" type="text" [obrigatorio]="true" [(value)]="area.nome"></vasta-input>

  <vasta-input
    label="Tamanho em HA"
    type="number"
    maskSuffix=" HA"
    mask="separator.2"
    [(value)]="area.tamanho"
  ></vasta-input>

  <vasta-input
    label="Tipo de Area"
    type="select"
    [(value)]="area.meta_tipo_area"
    [opcoes]="opcoesTipoArea"
  ></vasta-input>

  <ng-template [ngIf]="area.meta_tipo_area !== 'Reserva Legal' && area.meta_tipo_area !== 'Área de Preservação Permanente'">
    <vasta-input
      label="Lotação Máxima em UA"
      type="int"
      [(value)]="area.max_lotacao"
      maskSuffix=" UA"
      mask="separator.0"
    ></vasta-input>

    <vasta-input
      label="Tipo de Capim"
      type="select"
      [(value)]="area.meta_tipo_capim"
      [opcoes]="opcoesTipoCapim"
    ></vasta-input>
  </ng-template>

  <vasta-input label="Observação" type="textarea" [(value)]="area.observacao"></vasta-input>

  <ng-template [ngIf]="area.meta_tipo_area !== 'Reserva Legal' && area.meta_tipo_area !== 'Área de Preservação Permanente'">
    <h2>Pastejo Rotacionado</h2>

    <vasta-input
      label="Altura de Entrada do Capim em cm"
      type="number"
      maskSuffix=" cm"
      mask="separator.2"
      [(value)]="area.meta_altura_entrada_capim"
    ></vasta-input>

    <vasta-input
      label="Altura de Saída do Capim em cm"
      type="number"
      maskSuffix=" cm"
      mask="separator.2"
      [(value)]="area.meta_altura_saida_capim"
    ></vasta-input>
  </ng-template>
</ion-content>
<ion-footer>
  <cta-buttons>
    <button class="primary permissao-escrita-areas" (click)="salvarArea()" [disabled]="!area.nome || loadingSalvarArea">
      <ion-icon *ngIf="!loadingSalvarArea" name="checkmark"></ion-icon>
      <ion-spinner *ngIf="loadingSalvarArea" name="dots"></ion-spinner>
      <span>{{ loadingSalvarArea ? 'Salvando' : 'Salvar' }}</span>
    </button>
  </cta-buttons>
  <cta-buttons *ngIf="propArea">
    <button
      class="danger-clear permissao-escrita-areas"
      (click)="alertConfirmDeleteArea(propArea.lotes)"
      [disabled]="loadingExcluirArea"
    >
      <ion-icon *ngIf="!loadingExcluirArea" name="trash"></ion-icon>
      <ion-spinner *ngIf="loadingExcluirArea" name="dots"></ion-spinner>
      <span>Remover Área</span>
    </button>
  </cta-buttons>
</ion-footer>
