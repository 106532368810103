<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Chuva</div>

    <vasta-input
      label="Volume"
      type="manejo-number"
      maskSuffix=" mm"
      mask="separator.1"
      [(value)]="dados.chuva_quantidade"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>
    <vasta-input
      label="Hora da Coleta"
      type="manejo-number"
      mask="00:00"
      [(value)]="dados.chuva_hora"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.chuva_quantidade && !dados.chuva_hora">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
