<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{item.hash ? 'Editar Item Nutricional' : 'Novo Item Nutricional'}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-input
    label="Tipo"
    type="select"
    [(value)]="item.metadados.tipo"
    [obrigatorio]="true"
    [opcoes]="[
      { label: 'Aditivos', value: 'aditivos' },
      { label: 'Concentrado', value: 'concentrado' },
      { label: 'Minerais', value: 'minerais' },
      { label: 'Núcleo', value: 'nucleo' },
      { label: 'Proteinado', value: 'proteinado' },
      { label: 'Proteico Energético', value: 'proteicos-energetico' },
      { label: 'Ração', value: 'racao' },
      { label: 'Sal Mineral', value: 'sal-mineral' },
      { label: 'Vitaminas', value: 'vitaminas' },
      { label: 'Volumosos', value: 'volumosos' },
      ]"
    (valueChange)="limparFormulacao()"
  ></vasta-input>

  <vasta-input label="Título" type="text" [(value)]="item.titulo" [obrigatorio]="true"></vasta-input>

  <!-- <vasta-input
    label="Quantidade Mínima"
    type="text"
    [(value)]="item.metadados.qtd_min"
    [obrigatorio]="true"
    tooltipLabel="Quantidade mínima para manter em estoque"
    tooltipPosition="left"
    tooltipType="help"
    ></vasta-input> -->

  <ng-template [ngIf]="item.metadados.tipo == 'racao'">
    <h2 [class.no-margin-bottom]="!insumos.length">Fórmula <span style="font-weight: 300">(opcional)</span></h2>

    <div class="insumo-single" *ngFor="let insumo of insumos">
      <div class="inputs-wrapper">
        <button class="remove" (click)="removeInsumo($event, insumo.hash)">
          <ion-icon name="close-circle"></ion-icon>
        </button>

        <div class="title">{{insumo.titulo}}</div>

        <vasta-input
          label="Percentual"
          type="number"
          placeholder="0,00 %"
          mask="separator.2"
          maskSuffix=" %"
          [obrigatorio]="true"
          [(value)]="insumosTotais[insumo.hash]"
          (valueChange)="calcularTotais()"
        ></vasta-input>
      </div>
    </div>

    <div class="percentual-total" *ngIf="insumos.length">
      <span> Total: </span>
      <div class="percent" [class.error]="percentualTotal !== 100">
        <ion-icon [name]="percentualTotal !== 100 ? 'close-circle' : 'checkmark-circle'"></ion-icon>
        {{percentualTotal}} %
      </div>
    </div>

    <cta-buttons>
      <button class="permissao-escrita-estoque" (click)="adicionarItemNutricional()">
        <ion-icon slot="start" name="add-circle"></ion-icon>
        <span>Adicionar Insumo</span>
      </button>
    </cta-buttons>
  </ng-template>
</ion-content>

<ion-footer class="ion-no-border">
  <cta-buttons>
    <button
      class="primary permissao-escrita-estoque"
      (click)="salvar()"
      [disabled]="salvando || !isCampoObrigatoriosValidos || (insumos.length && percentualTotal !== 100)"
    >
      <ion-icon slot="start" name="checkmark" *ngIf="!salvando"></ion-icon>
      <ion-spinner name="dots" *ngIf="salvando"></ion-spinner>
      <span>Salvar</span>
    </button>
  </cta-buttons>
</ion-footer>
