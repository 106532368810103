<div class="top-bar" [class.show]="permissoesUsuarioCtrl.exibeTopBar" (click)="topBarAcaoBotao(permissoesUsuarioCtrl.topBarBotaoAcao)">
  <div class="top-bar-wrapper">
    <span class="top-bar-mensagem">{{ permissoesUsuarioCtrl.topBarMensagem }}</span>
    <button>
      {{ permissoesUsuarioCtrl.topBarBotao }}
    </button>
  </div>
</div>

<ion-app>
  <ion-router-outlet></ion-router-outlet>
</ion-app>
