import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import * as moment from 'moment'
import { IOptionButtons } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { VastaInputOpcoes } from 'src/app/components/vasta-input/vasta-input.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { CalendarioService } from 'src/app/services/calendario.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { IAnimal } from 'src/app/utils/interfaces/animais.interface'
import { AcontecimentosDoDia, ICalendario } from 'src/app/utils/interfaces/calendario.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalListaAnimaisPage } from '../../bovinos/modal-lista-animais/modal-lista-animais.page'

@Component({
  selector: 'app-calendario-evento-cadastrar',
  templateUrl: './calendario-evento-cadastrar.page.html',
  styleUrls: ['./calendario-evento-cadastrar.page.scss']
})
export class CalendarioEventoCadastrarPage implements OnInit {
  public eventoProp: AcontecimentosDoDia = null
  public carregamentoTabelaAnimais: StatusTabela = 'carregando'
  public qtdAnimaisSelecionados = 0
  public dataProp: string
  public isCadastrandoEvento = false
  public novoEventoCalendario: ICalendario = {
    hash: '',
    titulo: '',
    data: '',
    hora: '',
    observacao: '',
    situacao: '',
    tipo: ''
  }
  public animaisSelecionados: IAnimal[] = []

  public listaCategorias: VastaInputOpcoes = [
    {
      label: 'Evento Padrão',
      value: 'outros'
    },
    {
      label: 'Manejo Sanitário',
      value: 'manejo-sanitario'
    },
    {
      label: 'Manejo Reprodutivo',
      value: 'manejo-reprodutivo'
    },
    {
      label: 'Manejo Geral',
      value: 'manejo-geral'
    },
    {
      label: 'Pesagem',
      value: 'pesagem'
    },
    {
      label: 'Ordenha',
      value: 'ordenha'
    },
  ]
  
  public optionsButtonsItens: IOptionButtons[] = [
    {
      chave: 'apagarItem',
      icone: 'trash',
      titulo: 'Remover animal',
      disabled: false,
      acao: (linha: DadosLinhaTabela): void => {
        const {
          original: { hash }
        } = linha
        this.animaisSelecionados = this.animaisSelecionados.filter((a) => a.hash !== hash)
      }
    }
  ]

  public configTabelaAnimais: ConfigTabela = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: false,
    impressaoTitulo: 'Animais',
    orderby: 'numeracao',
    orderbyDirection: 'asc',
    exibeTotais: false,
    excluirColunas: ['checkbox']
  }

  constructor(
    private modalCtrl: ModalController,
    private calendarioCtrl: CalendarioService,
    private router: Router,
    private utilsCtrl: IonicUtilsService
  ) {}

  ngOnInit(): void {
    if (this.eventoProp) {
      this.carregarDadosApi(this.eventoProp.hash)
      console.log('eventoProp', this.eventoProp)
      this.novoEventoCalendario.hash = this.eventoProp.hash
      this.novoEventoCalendario.titulo = this.eventoProp.titulo
      this.novoEventoCalendario.hora = this.eventoProp.hora
      this.novoEventoCalendario.data = this.eventoProp.data
      this.novoEventoCalendario.observacao = this.eventoProp.observacao
    } else {
      if(this.dataProp) {
        this.novoEventoCalendario.data = this.dataProp
      } else {
        this.novoEventoCalendario.data = moment().format('YYYY-MM-DD')
      }
    }
    this.novoEventoCalendario.tipo = this.listaCategorias[0].value.toString()

    if (this.animaisSelecionados.length > 0) {
      this.carregamentoTabelaAnimais = 'sucesso'
    }
  }

  async carregarDadosApi(hash: string): Promise<void> {
    const [sucesso, erro] = await this.calendarioCtrl.detalhesDoEvento(hash)

    if (sucesso) {
      const { evento, animais } = sucesso
      this.novoEventoCalendario = evento
      console.log('this.novoEventoCalendario ',this.novoEventoCalendario)

      this.animaisSelecionados = animais
      this.carregamentoTabelaAnimais = 'sucesso'
    }

    if (erro) {
      this.utilsCtrl.showToast('Erro ao buscar dados do evento', 'bottom')
      this.modalCtrl.dismiss()
    }
  }

  async cadastrarEvento(): Promise<void> {
    this.isCadastrandoEvento = true

    const [sucesso, erro] = await this.calendarioCtrl.salvarEvento({
      evento: this.novoEventoCalendario,
      hashsAnimais: this.animaisSelecionados.map((a) => a.hash)
    })

    if (sucesso) {
      this.modalCtrl.dismiss({ sucesso: true })
    }

    if (erro) {
      this.utilsCtrl.showToast('Erro ao salvar evento', 'bottom')
    }

    this.isCadastrandoEvento = false
  }

  get isFormularioValido(): boolean {
    let isTodosCamposPreenchidos = true

    if (!this.novoEventoCalendario.data.length) {
      isTodosCamposPreenchidos = false
    }

    if (!this.novoEventoCalendario.titulo.length) {
      isTodosCamposPreenchidos = false
    }

    return isTodosCamposPreenchidos
  }

  async selecionarAnimal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: {
        selecionarMultiplos: true,
        modalFiltroReprodutorExterno: { chave: 'sexo', valor: 'macho' }
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      this.carregamentoTabelaAnimais = 'sucesso'
      if (data.animais?.length) {
        this.animaisSelecionados = data.animais
      } else {
        this.animaisSelecionados = [data]
      }
    }
    console.log('data: ', data)
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  novoManejo(): void {
    this.closeModal()
    const hashsAnimaisSelecionados = this.animaisSelecionados.map((a) => a.hash)

    this.router.navigateByUrl(`/manejos/${this.novoEventoCalendario.data}`, {
      state: {
        data: this.novoEventoCalendario.data,
        hashsAnimais: hashsAnimaisSelecionados,
        animais: this.animaisSelecionados
      }
    })
  }
}
