import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ModalController } from '@ionic/angular'
import * as moment from 'moment'
import { AnimaisService } from 'src/app/services/animais.service'
import { ImportacaoService } from 'src/app/services/importacao.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { AnimalPorNumeracao, IAnimal } from 'src/app/utils/interfaces/animais.interface'
import { ModalListaAnimaisPage } from '../../bovinos/modal-lista-animais/modal-lista-animais.page'

declare const jexcel
@Component({
  selector: 'app-importacao-pesagem',
  templateUrl: './importacao-pesagem.component.html',
  styleUrls: ['./importacao-pesagem.component.scss']
})
export class ImportacaoPesagemComponent implements OnInit, AfterViewInit {
  @ViewChild('spreadsheetPesagens') spreadsheet: ElementRef

  public colunas = {
    status: { title: 'Status', type: 'hidden', width: '170px' },
    busca_numero: { title: 'Buscar por número', type: 'text', width: '160px' },
    animal: { title: 'Animal', type: 'html', width: '220px', height: '50px' },
    data_pesagem: { title: 'Data da Pesagem', type: 'calendar', width: '160px' },
    peso: { title: 'Peso (Kg)', type: 'numeric', width: '180px', mask: '#.##,00 kg', decimal: ',' },
    observacao: { title: 'Observação', type: 'text', width: '200px' }
  }

  public tabela
  public dadosASalvar: unknown
  public colunasArray = []
  public timeoutValidacao = null
  public adicionarMais = 10
  public numeracaoDeAnimaisExistentes: string[]

  public animaisPorNumeracao = null
  public numeracoes = new Set()
  public todosAnimaisPorNumeracao = {}

  public loadingNumeracao = false

  constructor(
    private utilsCtrl: IonicUtilsService,
    private importacaoCtrl: ImportacaoService,
    private modalCtrl: ModalController,
    private animaisCtrl: AnimaisService
  ) {}

  async ngOnInit(): Promise<void> {
    this.colunasArray = Object.keys(this.colunas)
    const [numeracaoDeAnimaisExistentes] = await this.importacaoCtrl.getNumeracoesExistentes()
    this.numeracaoDeAnimaisExistentes = numeracaoDeAnimaisExistentes
  }

  ngAfterViewInit(): void {
    this.tabela = jexcel(this.spreadsheet.nativeElement, {
      data: [[]],
      includeHeadersOnDownload: true,
      tableOverflow: true,
      lazyLoading: true,
      lazyColumns: true,
      loadingSpin: true,
      tableHeight: '100%',
      tableWidth: '100%',
      columns: Object.values(this.colunas),
      minDimensions: [6, 300],
      autoIncrement: false,

      text: {
        noRecordsFound: 'Nenhum registro encontrado',
        showingPage: 'Mostrando página {0} de {1} entradas',
        show: 'Show',
        entries: 'entradas',
        insertANewColumnBefore: 'Inserir uma nova coluna antes de',
        insertANewColumnAfter: 'Inserir uma nova coluna depois de',
        deleteSelectedColumns: 'Excluir colunas selecionadas',
        renameThisColumn: 'Renomear esta coluna',
        orderAscending: 'ordem ascendente',
        orderDescending: 'Order decrescente',
        insertANewRowBefore: 'Inserir uma nova linha antes de',
        insertANewRowAfter: 'Inserir uma nova linha depois de',
        deleteSelectedRows: 'Excluir linhas selecionadas',
        editComments: 'Editar comentários',
        addComments: 'Adicionar comentários',
        comments: 'Comentários',
        clearComments: 'Limpar comentários',
        copy: 'Copiar ...',
        paste: 'Colar ...',
        saveAs: 'Salvar como ...',
        about: 'About',
        areYouSureToDeleteTheSelectedRows: 'Tem certeza de excluir as linhas selecionadas?',
        areYouSureToDeleteTheSelectedColumns: 'Tem certeza de excluir as colunas selecionadas?',
        thisActionWillDestroyAnyExistingMergedCellsAreYouSure:
          'Esta ação irá destruir todas as células mescladas existentes. Você tem certeza?',
        thisActionWillClearYourSearchResultsAreYouSure:
          'Esta ação limpará seus resultados de pesquisa. Você tem certeza?',
        thereIsAConflictWithAnotherMergedCell: 'Há um conflito com outra célula mesclada',
        invalidMergeProperties: 'Propriedades mescladas inválidas',
        cellAlreadyMerged: 'Célula já mesclada',
        noCellsSelected: 'Nenhuma célula selecionada'
      },

      onchange: (params, a1, coluna, linha, novoValor) => {
        if (coluna !== 0) {
          clearTimeout(this.timeoutValidacao)
          this.timeoutValidacao = setTimeout(() => {
            this.dadosASalvar = this.tabela.getData()
            this.validarLinha()
          }, 1000)
        }
      },

      // oneditionstart: async (params, param1, col, linha, param4) => {
      //   const coluna = this.tabela.getHeader(this.tabela.getSelectedColumns())

      //   if (coluna === 'Buscar por número') {
      //     await this.buscarAnimaisPorNumeracao()
      //   }
      // },

      oneditionstart: async (params, param1, col, linha, param4) => {
        const coluna = this.tabela?.getHeader(this.tabela.getSelectedColumns())

        if (coluna === 'Status') {
          this.utilsCtrl.showToast('Coluna somente para visualização do status da linha', 'bottom')
        }

        if (coluna === 'Animal') {
          const value = this.tabela.getValueFromCoords(1, linha)
          this.animaisPorNumeracao = this.todosAnimaisPorNumeracao[value]

          if (this.animaisPorNumeracao && Object.keys(this.animaisPorNumeracao)?.length) {
            console.log('animaisPorNumeracao: ', this.animaisPorNumeracao)
            let hashs = []
            Object.values(this.animaisPorNumeracao)
              .map((item: []) => item.map((inner: AnimalPorNumeracao) => inner.hash))
              .forEach((array) => {
                hashs = [...hashs, ...array]
              })

            this.selecionarAnimal(linha, col, hashs)
          } else {
            this.selecionarAnimal(linha, col)
          }
        }
      }
    })
  }

  async validarLinha(): Promise<void> {
    document.querySelectorAll('td.pendente').forEach((td) => td.classList.remove('pendente'))
    document.querySelectorAll('tr.valido').forEach((tr) => tr.classList.remove('valido'))

    this.dadosASalvar = this.tabela.getData()
    const todosOsDados = JSON.parse(JSON.stringify(this.dadosASalvar))

    let Nlinha = -1
    for (const dadosAtuais of todosOsDados) {
      Nlinha++

      dadosAtuais[0] = ''
      const valorPreenchido = dadosAtuais.join('')
      if (!valorPreenchido) {
        this.tabela.setValueFromCoords(0, Nlinha, '')
        continue
      }

      const busca_numero = dadosAtuais[this.colunasArray.indexOf('busca_numero')]
      let animal = dadosAtuais[this.colunasArray.indexOf('animal')]
      const data_pesagem = dadosAtuais[this.colunasArray.indexOf('data_pesagem')]
      const peso = dadosAtuais[this.colunasArray.indexOf('peso')]

      let novoValor = ''

      if (busca_numero && !animal) {
        if (this.numeracoes.has(busca_numero)) {
          this.animaisPorNumeracao = this.todosAnimaisPorNumeracao[busca_numero]
        } else {
          this.numeracoes.add(busca_numero)
          await this.buscarAnimaisPorNumeracao(String(busca_numero))
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const [values] = Object.values(this.animaisPorNumeracao) as Array<any>

        if (Object.keys(this.animaisPorNumeracao)?.length === 1 && values.length === 1 && !animal) {
          const dadosAnimal = Object.values(this.animaisPorNumeracao)[0][0]
          animal = this.insereAnimal(dadosAnimal, 2, Nlinha)
        } else if (Object.keys(this.animaisPorNumeracao)?.length > 1 && !animal) {
          const html = '<button class="btn-importacao-selecionar-animal">Selecionar Animal Correto</button>'
          this.tabela.setValueFromCoords(2, Nlinha, html)
        } else {
          const html = '<button class="btn-importacao-selecionar-animal">Selecionar Animal Correto</button>'
          this.tabela.setValueFromCoords(2, Nlinha, html)
        }
      }

      if (!peso) {
        this.tabela.getCellFromCoords(4, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Sem peso</span>'
      }

      if (!data_pesagem) {
        this.tabela.getCellFromCoords(3, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Sem data de pesagem</span>'
      }

      if (!animal || animal?.includes('Selecionar Animal')) {
        this.tabela.getCellFromCoords(2, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #c38a06">Adicione um animal</span>'
      }

      if (!novoValor) {
        this.tabela.getCellFromCoords(3, Nlinha)?.parentNode?.classList.add('valido')
        novoValor = '<span style="color: #006158">Ok</span>'
      }

      this.tabela.setValueFromCoords(0, Nlinha, novoValor)
    }
  }

  async selecionarAnimal(row: number, col: number, hashs?: string[]): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: { hashIn: hashs },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()
    if (data) {
      this.insereAnimal(data, col, row)
    }
  }

  insereAnimal(data: IAnimal, col: number, row: number): string {
    const idadeAnimal = this.calcularIdadeAnimal(data.data_nascimento)
    const { sexo, nome, numeracao } = data
    const icone = sexo === 'macho' ? 'male' : 'female'
    const html = `<div class="pesagens-col-animal"><ion-icon name="${icone}" color="${icone}"></ion-icon> <span>${
      numeracao || ''
    } <b>${nome || ''}</b></span><small>${idadeAnimal}</small><p style="display: none">${data.hash}</p></div>`
    this.tabela.setValueFromCoords(col, row, html)
    this.utilsCtrl.tick()
    return html
  }

  calcularIdadeAnimal(dataNascimento: string): string {
    const dataHoje = moment().format('YYYY-MM-DD')
    const dataNascimentoMoment = moment(dataNascimento, 'YYYY-MM-DD')
    const idade = moment(dataHoje).diff(dataNascimentoMoment, 'years')
    if (idade < 1) {
      const idadeMeses = moment(dataHoje).diff(dataNascimentoMoment, 'months')

      if (idadeMeses < 1) {
        const idadeDias = `${moment(dataHoje).diff(dataNascimentoMoment, 'days')} dias`
        return idadeDias
      } else {
        return `${idadeMeses} ${idadeMeses === 1 ? 'mês' : 'meses'}`
      }
    } else {
      return `${idade} ${idade === 1 ? 'ano' : 'anos'}`
    }
  }

  adicionarMaisItens(): void {
    for (let a = 0; a < this.adicionarMais; a++) {
      this.tabela.insertRow(1)
    }
  }

  async salvarPesagens(): Promise<void> {
    const dados = this.tabela.getData()
    const dadosPreenchidos = []
    for (const linha of dados) {
      const linhaValida = JSON.parse(JSON.stringify(linha))

      if (linhaValida.join('') !== '') {
        dadosPreenchidos.push(linhaValida)
      }
    }

    const pesagens = []

    for (let linhaValida of dadosPreenchidos) {
      if (!linhaValida[0].includes('Ok')) {
        this.utilsCtrl.showToast('Preencha todos os campos', 'bottom')
        return
      }
      const pesagem = {
        hash_animal:
          linhaValida[this.colunasArray.indexOf('animal')]?.split('<p style="display: none">')[1]?.split('</p>')[0] ||
          '',
        data_pesagem: linhaValida[this.colunasArray.indexOf('data_pesagem')].split(' ')[0] || '',
        peso: Number(linhaValida[this.colunasArray.indexOf('peso')].split(' ')[0]) || 0,
        observacao: linhaValida[this.colunasArray.indexOf('observacao')] || '',
        identificador: 'importacao'
      }

      pesagens.push(pesagem)
    }

    if (pesagens.length) {
      const loading = await this.utilsCtrl.showLoading('circular', 'Salvando pesagens...')
      const [response, error] = await this.importacaoCtrl.salvarImportacaoPesagens(pesagens)
      this.utilsCtrl.dismissLoading(loading)

      if (response) {
        this.excluirLinhasAposSalvarPesagens()
        this.utilsCtrl.showToast('Pesagens cadastradas com sucesso', 'bottom')
      }

      if (error) {
        console.log(error)
        this.utilsCtrl.showToast('Erro ao salvar pesagens', 'bottom')
      }
    } else {
      this.utilsCtrl.showToast('Preencha os campos!', 'bottom')
    }
  }

  excluirLinhasAposSalvarPesagens(): void {
    const dados = this.tabela.getData()
    let Nlinha = dados.length

    const reverseDados = dados.reverse()

    for (let linha of reverseDados) {
      Nlinha--
      if (linha[0].includes('Ok')) {
        this.tabela.deleteRow(Nlinha, 1)
      }
    }
  }

  async buscarAnimaisPorNumeracao(numeracao: string): Promise<void> {
    this.loadingNumeracao = true
    const [response, error] = await this.animaisCtrl.getAnimaisPorNumeracao([numeracao])
    this.loadingNumeracao = false

    if (response) {
      this.animaisPorNumeracao = response
      this.todosAnimaisPorNumeracao[numeracao] = response
    }

    if (error) {
      console.error(error)
    }
  }
}
