<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ loteParams ? 'Editar' : 'Cadastrar' }} Lote
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-input [label]="'Nome do lote'" [type]="'text'" [(value)]="formLote.nome" [obrigatorio]="true"></vasta-input>
  <vasta-input [label]="'Confinamento'" [type]="'checkbox'" [(valueCheckbox)]="formLote.confinamento"></vasta-input>
  <ng-container *ngIf="!formLote.confinamento">
    <vasta-input
      *ngIf="loteParams"
      [label]="'Situação do lote'"
      [type]="'select'"
      [placeholder]="'Selecionar'"
      [opcoes]="[
        { label: 'Ativo', value: 'ativo' },
        { label: 'Arquivado', value: 'inativo' }
      ]"
      [(value)]="formLote.meta_situacao"
      tooltipLabel="Lotes arquivados não são exibidos em novos manejos, mas as informações relacionadas a manejos e custos anteriores permanecem preservadas."
      tooltipPosition="left"
      tooltipType="help"
    ></vasta-input>
    <vasta-input
      [label]="'Área'"
      [type]="'button'"
      [placeholder]="'Selecionar'"
      [(value)]="formLote.nome_area"
      (buttonEvent)="openModalListaAreas()"
    ></vasta-input>
    <vasta-input
      [label]="'Aptidão'"
      [type]="'select'"
      [placeholder]="'Selecionar'"
      [opcoes]="[
        { label: 'Corte', value: 'corte' },
        { label: 'Leite', value: 'leite' },
        { label: 'Dupla Aptidão', value: 'dupla' }
      ]"
      [(value)]="formLote.aptidao"
    ></vasta-input>
    <vasta-input
      [label]="'Finalidade'"
      [type]="'select'"
      [placeholder]="'Selecionar'"
      [opcoes]="[
        { label: 'Cria', value: 'cria' },
        { label: 'Recria', value: 'recria' },
        { label: 'Engorda', value: 'engorda' }
      ]"
      [(value)]="formLote.finalidade"
    ></vasta-input>
    <vasta-input
      [label]="'Sistema de criação'"
      [type]="'select'"
      [placeholder]="'Selecionar'"
      [opcoes]="[
        { label: 'Intensivo', value: 'intensivo' },
        { label: 'Semi Intensivo', value: 'semi-intensivo' },
        { label: 'Extensivo', value: 'extensivo' }
      ]"
      [(value)]="formLote.sistema_criacao"
    ></vasta-input>
    <vasta-input label="Observação" type="textarea" [(value)]="formLote.observacao"></vasta-input>
  </ng-container>
</ion-content>
<ion-footer>
  <cta-buttons class="ion-float-right">
    <button
      class="primary permissao-escrita-lotes"
      (click)="cadastrarLote()"
      [disabled]="!formLote.nome"
      *ngIf="!salvando"
    >
      <ion-icon name="checkmark"></ion-icon>
      <span *ngIf="formLote.hash">Salvar Lote</span>
      <span *ngIf="!formLote.hash">Cadastrar Lote</span>
    </button>
    <button class="primary" disabled *ngIf="salvando">
      <ion-spinner name="dots"></ion-spinner>
      <span>Salvando</span>
    </button>
  </cta-buttons>
  <cta-buttons *ngIf="loteParams?.id_propriedade && formLote?.nome">
    <button class="danger-clear permissao-escrita-lotes" (click)="alertConfirmDeleteLote()" [disabled]="salvando">
      <ion-icon name="trash"></ion-icon>
      <span>Remover Lote</span>
    </button>
  </cta-buttons>
</ion-footer>
