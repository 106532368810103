<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      Detalhes da cobrança
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="modalClose()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  
  <ng-template [ngIf]="cobranca.status">
    <div class="info-cobrancas">
		  <ng-template [ngIf]="cobranca.cycle">
        <div class="item-cobranca">
		  	  <h2>Ciclo:</h2>
		  	  <p>{{cobranca.cycle.cycle}}</p>
        </div>
        <div class="item-cobranca">
		  	  <h2>Início do ciclo</h2>
		  	  <p>{{cobranca.cycle.start_at | date: 'dd/MM/yyyy HH:mm' }}</p>
        </div>
        <div class="item-cobranca">
		  	  <h2>Fim do ciclo</h2>
		  	  <p>{{cobranca.cycle.end_at | date: 'dd/MM/yyyy HH:mm' }}</p>
        </div>
		  </ng-template>

      <div class="item-cobranca">
		    <h2>Status</h2>
		    <p>{{cobranca.status}}</p>
      </div>

      <div class="item-cobranca" *ngIf="cobranca.total_discount">
		    <h2>Desconto</h2>
		    <p>R$ {{((cobranca.total_discount || 0) / 100).toFixed(2).replace('.',',')}}</p>
      </div>

      <div class="item-cobranca" *ngIf="cobranca.total_increment">
		    <h2>Incremento</h2>
		    <p>R$ {{((cobranca.total_increment || 0) / 100).toFixed(2).replace('.',',')}}</p>
      </div>


		  <ng-template [ngIf]="cobranca.charge">
        <div class="item-cobranca">
		  	  <h2>Valor</h2>
		  	  <p>R$ {{((cobranca.charge.amount || 0) / 100).toFixed(2).replace('.',',')}}</p>
        </div>
      
        <div class="item-cobranca" *ngIf="cobranca.charge.paid_at">
		  	  <h2>Pago em:</h2>
		  	  <p>{{cobranca.charge.paid_at | date: 'dd/MM/yyyy HH:mm' }}</p>
        </div>
		  </ng-template>
    
		  <ng-template [ngIf]="cobranca.subscription">
        <div class="item-cobranca">
		  	  <h2>Valor</h2>
		  	  <p>R$ {{((cobranca.charge.amount || 0) / 100).toFixed(2).replace('.',',')}}</p>
        </div>

        <div class="item-cobranca" *ngIf="cobranca.subscription.next_billing_at">
		  	  <h2>Próxima cobrança em:</h2>
		  	  <p>{{cobranca.subscription.next_billing_at  | date: 'dd/MM/yyyy HH:mm' }}</p>
        </div>
      
        <div class="item-cobranca" *ngIf="cobranca.subscription.status">
		  	  <h2>Status da assinatura:</h2>
		  	  <p>{{assinaturaStatus[cobranca.subscription.status] || cobranca.subscription.status}}</p>
        </div>
		  </ng-template>
    </div>
	</ng-template>
</ion-content>
