<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Editar Situação {{ dadosSituacao.tipoSituacaoSelecionada === 'sanitaria' ? 'Sanitária' : '' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-input
    [label]="'Tipo'"
    [type]="'select'"
    [(value)]="dadosSituacao.tipoSituacaoSelecionada"
    [opcoes]="[
      { label: 'Situacao', value: 'situacao' },
      { label: 'Situação Sanitária', value: 'sanitaria' }
    ]"
    placeholder="Selecionar"
  ></vasta-input>
  <vasta-input [label]="'Data da Alteração'" [type]="'date'" [(value)]="dadosSituacao.data_alteracao"></vasta-input>

  <vasta-input
    *ngIf="dadosSituacao.tipoSituacaoSelecionada === 'sanitaria'"
    [label]="'Nova Situação'"
    [type]="'select'"
    [(value)]="dadosSituacao.situacao_sanitaria"
    [opcoes]="[
      { label: 'Doente', value: 'doente' },
      { label: 'Tratamento', value: 'tratamento' },
      { label: 'Saúdavel', value: 'saudavel' }
    ]"
  ></vasta-input>

  <vasta-input
    *ngIf="dadosSituacao.tipoSituacaoSelecionada === 'situacao'"
    [label]="'Nova Situação'"
    [type]="'select'"
    [(value)]="dadosSituacao.situacao"
    [opcoes]="[
      { label: 'Vivo', value: 'vivo' },
      { label: 'Morto', value: 'morto' },
      { label: 'Vendido', value: 'vendido' },
      { label: 'Perdido', value: 'perdido' }
    ]"
  ></vasta-input>

  <vasta-input
    [label]="'Não alterar valor atual'"
    [type]="'checkbox'"
    [(valueCheckbox)]="dadosSituacao.alterarValorAtual"
  ></vasta-input>

  <vasta-input [label]="'Observações'" [type]="'textarea'" [(value)]="dadosSituacao.observacao"></vasta-input>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <cta-buttons class="ion-float-right">
      <button class="primary permissao-escrita-animais" (click)="salvarSituacao()">
        <ion-icon name="checkmark-outline"></ion-icon>
        <span>Salvar</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
