import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { IAreaPiquete } from 'src/app/utils/interfaces/areas.interface'
import { ManejoArea } from 'src/app/utils/interfaces/manejos.interface'
import { PopoverListaPiquetesComponent } from '../popover-lista-piquetes/popover-lista-piquetes.component'
import { PopoverController } from '@ionic/angular'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-area-pastejo-rotacionado',
  templateUrl: './manejo-area-pastejo-rotacionado.component.html',
  styleUrls: ['./manejo-area-pastejo-rotacionado.component.scss'],
})
export class ManejoAreaPastejoRotacionadoComponent  implements OnInit {

  @Input() dados: Partial<ManejoArea> = {}
  @Input() piquetes: string[] = []
  @Input() hash_area: string

  @Input() piquetesAssociados: {[key: string]: IAreaPiquete} = {}
  @Output() piquetesAssociadosChange = new EventEmitter<{ [key: string]: IAreaPiquete }>()

  @Output() piquetesChange = new EventEmitter<unknown>()
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  public piqueteSelecionado: string
  
  constructor(
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit(): void {}

  emit(): void {
    this.piquetesAssociadosChange.emit(this.piquetesAssociados)
    this.dadosChange.emit(this.dados)
  }

  async showPopoverPiquetes(event: Event): Promise<void> {
    const todosPiquetesArea = Object.keys(this.piquetesAssociados).filter((key) => this.piquetesAssociados[key].hash_area === this.hash_area)

    for (const piqueteHash of todosPiquetesArea) {
      this.piquetesAssociados[piqueteHash].piquete_selecionado_pastejo_rotacionado = piqueteHash === this.dados.hash_piquete
    }

    const popover = await this.popoverCtrl.create({
      component: PopoverListaPiquetesComponent,
      componentProps: {
        piquetes:  todosPiquetesArea,
        piquetesAssociados: this.piquetesAssociados,
        mensagem: 'Nenhum piquete registrado nessa área',
        popoverCheck: true,
        showNenhum: true
      },
      event
    })
    await popover.present()

    const { data } = await popover.onDidDismiss()

    if (data) {
      this.mudarPiquete(data)
    }
    
  }

  mudarPiquete(hash: string): void {
    this.dados.edited = true
    this.dados.hash_piquete = hash
    this.emit()
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.hash_piquete = ''
      this.dados.edited = true
      this.emit()
    }
  }

}
