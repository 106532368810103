<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Selecionar Animal
    </ion-title>
    <ion-buttons slot="end">
      <div class="search-wrapper desktop" *ngIf="abaAtiva == 'Animais da Fazenda'">
        <div class="search-animais">
          <ion-icon name="search"></ion-icon>
          <input
            class="busca-animais"
            placeholder="Buscar por número"
            [(ngModel)]="novoFiltro.numeracao"
            (keyup.esc)="limparBusca($event)"
            (ngModelChange)="getAnimaisTimeout()"
          />
        </div>
      </div>
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <header-tabs
    *ngIf="!somenteInternos && !somenteExternos"
    [tabs]="['Animais da Fazenda', 'Animais Externos']"
    [(tabActive)]="abaAtiva"
  ></header-tabs>
  <div class="search-wrapper mobile" *ngIf="abaAtiva == 'Animais da Fazenda'">
    <div class="search-animais">
      <ion-icon name="search"></ion-icon>
      <input
        class="busca-animais"
        placeholder="Buscar por número"
        [(ngModel)]="novoFiltro.numeracao"
        (keyup.esc)="limparBusca($event)"
        (ngModelChange)="getAnimaisTimeout()"
      />
    </div>
  </div>
</ion-header>
<ion-content>
  <vasta-tabela
    [ngClass]="{ 'ion-hide': abaAtiva !== 'Animais da Fazenda' }"
    labelsId="colunas_animais"
    [campos]="animais"
    [config]="config"
    [status]="carregamentoAnimais"
    [(hashsSelecionados)]="hashsSelecionados"
    (hashsSelecionadosChange)="getHashsSelecionados($event)"
    [filtrosFixos]="filtrosFixos"
    [(paginate)]="paginate"
    (paginateChange)="getAnimais()"
    [(novaOrdenacao)]="novaOrdenacao"
    [(novoFiltro)]="novoFiltro"
    (novoFiltroChange)="limparPaginate(); getAnimais()"
    (retentar)="getAnimais()"
    (selecionado)="enviarDadosAnimal($event)"
  ></vasta-tabela>
  <div [ngClass]="{ 'ion-hide': abaAtiva !== 'Animais Externos' }" class="container-externos">
    <vasta-tabela
      [labels]="labelsReprodutoresExternos"
      [config]="configReprodutoresExternos"
      [campos]="reprodutoresExternos"
      [status]="carregamentoReprodutoresExternos"
      [filtrosFixos]="filtrosFixos"
      [(novoFiltro)]="novoFiltroReprodutores"
      [(novaOrdenacao)]="novaOrdenacaoReprodutores"
      (novoFiltroChange)="getReprodutoresExternos()"
      (selecionado)="enviarDadosAnimalExterno($event)"
      (retentar)="getReprodutoresExternos()"
    ></vasta-tabela>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <cta-buttons class="ion-float-left permissao-escrita-animais" *ngIf="abaAtiva === 'Animais da Fazenda'">
      <button 
        [class.item-bloqueado-plano-basico]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('acoes', 'cadastro-animais-atalho')" 
        (click)="seletorOpcaoCadastro($event)" 
        *ngIf="permitirCadastro"
      >
        <ion-icon name="add-circle"></ion-icon>
        <span>Cadastrar Animal</span>
      </button>
    </cta-buttons>
    <cta-buttons class="ion-float-left permissao-escrita-animais" *ngIf="abaAtiva === 'Animais Externos'">
      <button (click)="openModalNovoReprodutorExterno()">
        <ion-icon name="add-circle"></ion-icon>
        <span>Cadastrar Reprodutor</span>
      </button>
    </cta-buttons>
    <cta-buttons class="ion-float-right" *ngIf="selecionarMultiplos">
      <button class="primary" (click)="enviarHashsAnimaisSelecionados()">
        <ion-icon name="checkmark"></ion-icon>
        <span>Confirmar</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
