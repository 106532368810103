import { Injectable } from '@angular/core'
import { Preferences } from '@capacitor/preferences'
import { VastaRX } from '@vasta/rx'
import {
  IGetPropriedades,
  IMetas,
  IParamsAdicionarPropriedade,
  IPropriedade,
  Metadados
} from '../utils/interfaces/propriedades.interface'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'
import { ApiService } from './api/api.service'

@Injectable({
  providedIn: 'root'
})
export class PropriedadesService {
  private _propriedadeSelecionada: IPropriedade
  private _todasPropriedades: IGetPropriedades
  public isPropriedadeExemploAtivada = true
  public isJaExistePropriedadeDisponivelParaUso = false

  constructor(private apiCtrl: ApiService) {}

  async iniciaPropriedadeMemoria(): Promise<void> {
    try {
      const propriedadeMemoria = this._propriedadeSelecionada
      const propriedadesMemoria = this._todasPropriedades

      if (!propriedadeMemoria) {
        const selecionadaStorage = await Preferences.get({
          key: 'propriedade_selecionada'
        })

        if (selecionadaStorage.value && selecionadaStorage.value !== 'undefined') {
          this._propriedadeSelecionada = JSON.parse(selecionadaStorage.value) as IPropriedade
          this.apiCtrl.idPropriedade = this._propriedadeSelecionada?.id || this.apiCtrl.idPropriedade
        }
      }

      if (!propriedadesMemoria) {
        const propriedadesStorage = await Preferences.get({
          key: 'fertili-usuario-propriedades'
        })

        if (propriedadesStorage.value && propriedadesStorage.value !== 'undefined') {
          this.todasPropriedades = JSON.parse(propriedadesStorage.value)
        }
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  async getPropriedades(): Promise<[IGetPropriedades, Error?]> {
    try {
      const response = (await this.apiCtrl.get({ url: 'usuario/propriedades' })) as IGetPropriedades
      if (response) {
        this.todasPropriedades = response

        await Preferences.set({
          key: 'fertili-usuario-propriedades',
          value: JSON.stringify(response)
        })

        if (this.todasPropriedades?.length) {
          this.isJaExistePropriedadeDisponivelParaUso = true
        }

        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async adicionarPropriedade(params: IParamsAdicionarPropriedade): Promise<[StatusRequest, Error?]> {
    try {
      const response = (await this.apiCtrl.post({ url: 'propriedade', body: params })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async atualizarPropriedade(id: number, params: IPropriedade): Promise<[StatusRequest, Error?]> {
    try {
      this.verificaPermissaoDeRota('propriedade', 'escrita')
      const response = (await this.apiCtrl.put({ url: `propriedade/${id}`, body: params })) as StatusRequest
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async deletarPropriedade(id: number): Promise<[StatusRequest, Error?]> {
    try {
      this.verificaPermissaoDeRota('propriedade', 'escrita')
      const response = (await this.apiCtrl.delete({ url: `propriedade/${id}` })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getMetas(): Promise<[IMetas, Error?]> {
    try {
      const response = (await this.apiCtrl.get({
        url: `propriedade/${this.propriedadeSelecionada.id}/metas`
      })) as IMetas
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async adicionarMetaPropriedade(params: Metadados): Promise<[StatusRequest, Error?]> {
    try {
      const response = (await this.apiCtrl.post({
        url: `propriedade/${this.propriedadeSelecionada.id}/metas-propriedade`,
        body: params
      })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async removerMetaPropriedade(chave: string): Promise<[StatusRequest, Error?]> {
    try {
      const response = (await this.apiCtrl.delete({
        url: `propriedade/${this.propriedadeSelecionada.id}/metas-propriedade/remover?chave=${chave}`
      })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async gerarHashs(qtd: number): Promise<[string[], Error?]> {
    try {
      const response = (await this.apiCtrl.get({
        url: `propriedade/${this.propriedadeSelecionada.id}/gerar-hash?qtdHashes=${qtd}`
      })) as string[]

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async selecionarPropriedadeExemplo(): Promise<void> {
    try {
      const [propriedadeExemplo] = (await this.apiCtrl.get({ url: 'usuario/propriedades-exemplo' })) as IPropriedade[]
      this.propriedadeSelecionada = propriedadeExemplo

      this.isJaExistePropriedadeDisponivelParaUso = true
      VastaRX.setState('propriedade_selecionada', propriedadeExemplo)

      document.body.classList.add('propriedade-exemplo')
    } catch (error) {
      throw error
    }
  }

  verificarSeJaExistePropriedadeDisponivelParaUso(): void {
    const isPropriedadeExemploSelecionada = !!this.propriedadeSelecionada?.propriedade_exemplo
    const isAlgumaPropriedadeCadastrada = !!this.todasPropriedades?.length
    this.isJaExistePropriedadeDisponivelParaUso =
      isPropriedadeExemploSelecionada || isAlgumaPropriedadeCadastrada ? true : false
  }

  removerPropriedadeSelecionada(): void {
    this.propriedadeSelecionada = null
    document.body.classList.remove('propriedade-exemplo')
    VastaRX.setState('propriedade_selecionada', null)

    if (this.todasPropriedades?.length) {
      this.isJaExistePropriedadeDisponivelParaUso = false
    }
  }

  get propriedadeSelecionada(): IPropriedade {
    this.apiCtrl.idPropriedade = this._propriedadeSelecionada?.id || this.apiCtrl.idPropriedade
    return this._propriedadeSelecionada
  }

  set propriedadeSelecionada(propriedade: IPropriedade) {
    this._propriedadeSelecionada = propriedade

    Preferences.set({
      key: 'propriedade_selecionada',
      value: JSON.stringify(propriedade)
    })

    this.apiCtrl.idPropriedade = this._propriedadeSelecionada?.id || this.apiCtrl.idPropriedade
    this.verificarPropriedadeExemplo()
  }

  get todasPropriedades(): IGetPropriedades {
    return this._todasPropriedades
  }

  set todasPropriedades(todasPropriedades: IGetPropriedades) {
    if (!this.propriedadeSelecionada?.propriedade_exemplo) {
      this.propriedadeSelecionada =
        todasPropriedades.find((p) => p.id == this.propriedadeSelecionada?.id) || todasPropriedades[0]

      VastaRX.setState('propriedade_selecionada', this.propriedadeSelecionada)
    }
    this._todasPropriedades = todasPropriedades
    this.verificarPropriedadeExemplo()
  }

  verificarPropriedadeExemplo(): void {
    if (this.propriedadeSelecionada?.propriedade_exemplo) {
      this.isPropriedadeExemploAtivada = true
      document.body.classList.add('propriedade-exemplo')
    } else {
      this.isPropriedadeExemploAtivada = false
      document.body.classList.remove('propriedade-exemplo')
    }
  }

  temPermissao(rota: string, tipo: 'leitura' | 'escrita'): boolean {
    if (!this.isPropriedadeExemploAtivada) {
      if (this.propriedadeSelecionada.meu_role !== 'proprietario') {
        const hasPermissao = this.propriedadeSelecionada.permissoes.some(
          (permissao) =>
            permissao.includes(rota) &&
            (permissao.includes(tipo) || (permissao.includes('escrita') && tipo === 'leitura'))
        )

        if (!hasPermissao) {
          return false
        }
      }
    } else {
      if (tipo === 'escrita') {
        return false
      }
    }

    return true
  }

  verificaPermissaoDeRota(rota: string, tipo: 'leitura' | 'escrita'): void {
    if (!this.temPermissao(rota, tipo)) {
      throw new Error(`usuário não tem permissão, rota: ${rota}, tipo: ${tipo}`)
    }
  }
}
