<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ isPiquete ? 'Demarcar Piquete' : 'Demarcar Área' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      placeholder="Digite a cidade a buscar..."
      id="map-input"
      [(ngModel)]="mapa_search"
      (keyup.enter)="campoStop($event)"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="map-wrapper">
    <div class="editores-mapa">
      <button class="localizar" (click)="localizar()" *ngIf="!localizando">
        <ion-icon name="locate"></ion-icon> Localizar
      </button>
      <button class="localizar" disabled *ngIf="localizando"><ion-icon name="locate"></ion-icon> Localizando</button>
    </div>
    <div class="descricao-mapa">Clique uma vez para começar a contornar {{ isPiquete ? 'o piquete' : 'a área desejada' }}.</div>
  </div>
  <div #map id="map"></div>
</ion-content>
<ion-footer>
  <cta-buttons>
    <button class="primary" (click)="dismiss(true)" [disabled]="polygons.length === 0">
      <ion-icon name="checkmark"></ion-icon>
      <span>{{ isPiquete ? 'Salvar Piquete' : 'Salvar Área' }} {{ tamanho ? ' de ' + tamanho + 'ha' : '' }}</span>
    </button>
  </cta-buttons>
  <cta-buttons>
    <button class="secondary" *ngIf="!selecionaArea" (click)="desenharArea()">
      <ion-icon name="location"></ion-icon> <span>{{ isPiquete ? 'Demarcar Piquete' : 'Demarcar Área' }}</span>
    </button>
    <button class="danger-clear" (click)="removerArea()" *ngIf="selecionaArea && polygons.length == 0">
      <ion-icon name="close"></ion-icon> <span>Cancelar</span>
    </button>
    <button class="danger" (click)="removerArea()" *ngIf="selecionaArea && polygons.length">
      <ion-icon name="trash"></ion-icon> <span>{{ isPiquete ? 'Apagar Piquete' : 'Apagar Área' }}</span>
    </button>
  </cta-buttons>
</ion-footer>
