<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Monta Natural</div>

    <vasta-input
      label="Touro"
      type="manejo-button"
      [value]="
        animaisExternosAssociados[dados.cobertura_hash_reprodutor_externo]?.nome ||
        animaisAssociados[dados.cobertura_hash_reprodutor]?.numeracao ||
        animaisAssociados[dados.cobertura_hash_reprodutor]?.nome
      "
      (buttonEvent)="showModalReprodutores()"
    ></vasta-input>

    <div class="manejo-opcoes" *ngIf="opcoesRapidasReprodutores.length">
      <span class="label">Opções <br />Rápidas</span>
      <div class="container-opcoes">
        <ng-template ngFor let-hash [ngForOf]="opcoesRapidasReprodutores">
          <button
            *ngIf="animaisAssociados[hash]"
            (click)="selecionarOpcoesRapidas(hash, false)"
            [class.active]="hash == dados.cobertura_hash_reprodutor || hash == dados.cobertura_hash_reprodutor_externo"
          >
            <b>{{ animaisAssociados[hash].numeracao }}</b> {{ animaisAssociados[hash].nome }}
          </button>
          <button
            *ngIf="animaisExternosAssociados[hash]"
            (click)="selecionarOpcoesRapidas(hash, true)"
            [class.active]="hash == dados.cobertura_hash_reprodutor || hash == dados.cobertura_hash_reprodutor_externo"
          >
            <b>{{ animaisExternosAssociados[hash].nome }}</b>
          </button>
        </ng-template>
      </div>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button
        (click)="adicionarObservacao()"
        [disabled]="!dados.cobertura_hash_reprodutor || dados.cobertura_hash_reprodutor_externo"
      >
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
