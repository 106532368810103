import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-modal-comparativo-planos',
  templateUrl: './modal-comparativo-planos.page.html',
  styleUrls: ['./modal-comparativo-planos.page.scss']
})
export class ModalComparativoPlanosPage {
  constructor(private modalCtrl: ModalController) {}

  fecharModal(): void {
    this.modalCtrl.dismiss()
  }
}
