/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { Chart, ChartData, ChartDataset, ChartOptions, registerables } from 'chart.js'
Chart.register(...registerables)

@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.scss']
})
export class GraficoComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('grafico') grafico: ElementRef
  @Input('height') height: number
  @Input('labels') labels: string[] = []
  @Input('dataset') dataset: ChartDataset[]
  @Input('options') options: ChartOptions = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    // indexAxis: 'y',
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: {
            family: 'Oxygen Mono',
            size: 11
          },
        }
      },
      y: {
        grid: { display: false },
        ticks: {
          font: {
            family: 'Oxygen Mono'
          },
          callback: (value: string): string => {
            if (Number(value) > 1000000 || Number(value) < -1000000) {
              return `${this.unidade && this.unidade === 'R$' ? ` ${this.unidade}` : ''} ${(Number(value) / 1000000).toFixed(0)} mi${this.unidade && this.unidade !== 'R$' ? ` ${this.unidade}` : ''}`
            } else if (Number(value) > 1000 || Number(value) < -1000) {
              return `${this.unidade && this.unidade === 'R$' ? ` ${this.unidade}` : ''} ${(Number(value) / 1000).toFixed(0)} mil${this.unidade && this.unidade !== 'R$' ? ` ${this.unidade}` : ''}`
            } else {
              return `${this.unidade && this.unidade === 'R$' ? ` ${this.unidade}` : ''} ${Number(value).toFixed(0)}${this.unidade && this.unidade !== 'R$' ? ` ${this.unidade}` : ''}`
            }
          }
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context): string => {
            if (this.unidade) {
              return context.raw + ' ' + this.unidade
            } else {
              return String(context.raw)
            }
          }
        }
      },
      legend: {
        display: false
      }
    },

    onClick: (e): void => {
      // const canvasPosition = getRelativePosition(e, chart)
      // Substitute the appropriate scale IDs
      // const dataX = chart.scales.x.getValueForPixel(canvasPosition.x)
      // const dataY = chart.scales.y.getValueForPixel(canvasPosition.y)
    }
  }
  @Input('unidade') unidade: string

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.grafico.nativeElement['style'].height = this.height ? this.height + 'px' : '128px'
    this.chart.resize()
  }

  public chart: Chart

  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initGrafico()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dataset && this.chart) {
      const data: ChartData = {
        labels: this.labels,
        datasets: [...this.dataset]
      }
      this.chart.data = data
    }
    this.chart?.update()
  }

  initGrafico(): void {
    const data: ChartData = {
      labels: this.labels,
      datasets: [...this.dataset]
    }

    const ctx = this.grafico.nativeElement

    this.chart = new Chart(ctx, {
      type: data.datasets[0].type,
      data,
      options: this.options
    })

    this.grafico.nativeElement['style'].height = this.height ? this.height + 'px' : '128px'
    this.chart?.resize()
  }
}
