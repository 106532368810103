import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { AlertController } from '@ionic/angular'
import { ManejosService } from '../services/manejos.service'

@Injectable({
  providedIn: 'root'
})
export class ExitCadastroManejoGuard implements CanDeactivate<unknown> {
  private canDeactivateValue = false
  constructor(private alertCtrl: AlertController, private manejosCtrl: ManejosService) {}
  async canDeactivate(): Promise<boolean> {
    if (this.manejosCtrl.campoManejoAlterado) {
      const alert = await this.alertCtrl.create({
        header: 'Deseja sair sem salvar os dados?',
        buttons: [
          {
            text: 'Cancelar',
            handler: (): void => {
              this.canDeactivateValue = false
            }
          },
          {
            text: 'Confirmar',
            handler: (): void => {
              this.canDeactivateValue = true
            }
          }
        ]
      })
      await alert.present()
      await alert.onWillDismiss()

      return this.canDeactivateValue
    } else {
      return true
    }
  }
}
