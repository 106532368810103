import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoteSingleComponent } from '../lotes/lote-single/lote-single.component'
import { AreaSinglePage } from './area-single/area-single.page'
import { AreasPage } from './areas.page'

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AreasPage
      },
      {
        path: 'lotes/:hash',
        component: LoteSingleComponent
      },
      {
        path: ':hash',
        component: AreaSinglePage
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AreasPageRoutingModule {}
