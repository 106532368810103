<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Importar KML</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon color="primary" name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="page-container">
    <vasta-mapa [areas]="areasMapa"></vasta-mapa>

    <div class="tabela-container">
      <cta-buttons>
        <button (click)="importarKml()" [class.primary]="!areasCtrl.importacaoKMLDados.length">
          <ion-icon name="folder"></ion-icon>
          <span> Selecionar Arquivo </span>
        </button>
      </cta-buttons>
      <vasta-tabela
        [config]="config"
        [labels]="labels"
        [campos]="areasCtrl.importacaoKMLDados"
        status="sucesso"
        [(hashsSelecionados)]="hashsSelecionados"
        (hashsSelecionadosChange)="getHashsSelecionados($event)"
        (selecionado)="selecionarHashArea($event)"
      >
      </vasta-tabela>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <cta-buttons>
    <button (click)="importarConfirmar()" class="primary" [disabled]="!hashsSelecionados.length">
      <ion-icon name="cloud-upload"></ion-icon>
      <span *ngIf="!hashArea"> Importar Áreas </span>
      <span *ngIf="hashArea"> Importar Piquetes </span>
    </button>
  </cta-buttons>
</ion-footer>
