<header>
  <ion-searchbar
    mode="ios"
    placeholder="Pesquisar"
    (ionInput)="filtrarCategorias($event)"
    debounce="500"
  ></ion-searchbar>
</header>
<div class="container-categorias">
  <div class="container-lista" *ngIf="listaCategorias.length">
    <ng-template ngFor let-categoria [ngForOf]="listaCategorias">
      <span *ngIf="categoria?.type === 'separator'" [class.match]="categoria?.match">{{ categoria.nome }}</span>
      <button *ngIf="categoria?.type !== 'separator' && !selecaoMultipla" (click)="selecionarCategoria(categoria)" [class.match]="categoria?.match">
        {{ categoria?.nome }}
        <ion-icon name="checkmark" color="primary" *ngIf="isSelecionada(categoria)"></ion-icon>
      </button>
      <vasta-input
        *ngIf="categoria?.type !== 'separator' && selecaoMultipla"
        type="checkbox"
        [label]="categoria?.nome"
        [valueCheckbox]="isSelecionada(categoria)"
        (valueCheckboxChange)="selecionarCategoria(categoria)"
      ></vasta-input>
    </ng-template>
  </div>
</div>
<ion-footer *ngIf="selecaoMultipla">
  <ion-toolbar>
    <cta-buttons class="ion-float-right">
      <button class="primary" (click)="confirmarSelecao()">
        <ion-icon name="checkmark"></ion-icon>
        <span>Confirmar</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
