import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { ModalListaAnimaisPage } from 'src/app/pages/bovinos/modal-lista-animais/modal-lista-animais.page'
import { IAnimal, IReprodutorExterno } from 'src/app/utils/interfaces/animais.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'
import { NovoFiltro } from '../vasta-tabela/vasta-tabela'

@Component({
  selector: 'app-manejo-reprodutivo-monta-natural',
  templateUrl: './manejo-reprodutivo-monta-natural.component.html',
  styleUrls: ['./manejo-reprodutivo-monta-natural.component.scss']
})
export class ManejoReprodutivoMontaNaturalComponent implements OnInit {
  @Input('dados') dados = {
    cobertura_hash_reprodutor: '',
    cobertura_hash_reprodutor_externo: '',
    observacao: '',
    edited: true
  }
  @Input('data') data: string
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  @Input('opcoesRapidasReprodutores') opcoesRapidasReprodutores: string[] = []
  @Output() opcoesRapidasReprodutoresChange = new EventEmitter<string[]>()
  @Input('animaisAssociados') animaisAssociados: { [key: string]: IAnimal } = {}
  @Output() animaisAssociadosChange = new EventEmitter<{ [key: string]: IAnimal }>()
  @Input('animaisExternosAssociados') animaisExternosAssociados: { [key: string]: IReprodutorExterno } = {}
  @Output() animaisExternosAssociadosChange = new EventEmitter<{ [key: string]: IReprodutorExterno }>()

  constructor(private modalCtrl: ModalController, private popoverCtrl: PopoverController) {}

  async ngOnInit(): Promise<void> {}

  emit(): void {
    if (this.opcoesRapidasReprodutores.length >= 3) {
      this.opcoesRapidasReprodutores = this.opcoesRapidasReprodutores.slice(0, 3)
    }
    this.dadosChange.emit(this.dados)
    this.animaisAssociadosChange.emit(this.animaisAssociados)
    this.animaisExternosAssociadosChange.emit(this.animaisExternosAssociados)
    this.opcoesRapidasReprodutoresChange.emit(this.opcoesRapidasReprodutores)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showModalReprodutores(): Promise<void> {
    const filtroSexo: NovoFiltro = {
      sexo: 'macho'
    }
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: {
        filtrosFixos: ['sexo'],
        modalFiltro: filtroSexo,
        selecionarMultiplos: false,
        modalFiltroReprodutorExterno: filtroSexo
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()
    if (data) {
      this.dados.edited = true
      if (
        data.hash !== this.dados.cobertura_hash_reprodutor ||
        data.hash !== this.dados.cobertura_hash_reprodutor_externo
      ) {
        if (data.externo) {
          this.animaisExternosAssociados[data.hash] = data
          this.dados.cobertura_hash_reprodutor_externo = data.hash
          this.dados.cobertura_hash_reprodutor = ''
        } else {
          this.animaisAssociados[data.hash] = data
          this.dados.cobertura_hash_reprodutor = data.hash
          this.dados.cobertura_hash_reprodutor_externo = ''
        }

        if (!this.opcoesRapidasReprodutores.includes(data.hash)) this.opcoesRapidasReprodutores.unshift(data.hash)
      }
    }
    this.emit()
  }

  selecionarOpcoesRapidas(hash: string, externo: boolean): void {
    this.dados.edited = true
    if (externo) {
      this.dados.cobertura_hash_reprodutor_externo = hash
      this.dados.cobertura_hash_reprodutor = ''
    } else {
      this.dados.cobertura_hash_reprodutor = hash
      this.dados.cobertura_hash_reprodutor_externo = ''
    }
    this.emit()
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()

    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.cobertura_hash_reprodutor = null
      this.dados.cobertura_hash_reprodutor_externo = null
      this.emit()
    }
  }
}
