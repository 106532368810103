import { Injectable } from '@angular/core'
import { NavController } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class NavegacaoService {
  public baseAtual: string = '/'
  constructor(private navCtrl: NavController) {
    this.processaBaseUrl()
  }

  async set(rota = ['/']): Promise<void> {
    await this.navCtrl.navigateRoot(rota)
    this.processaBaseUrl()
  }

  processaBaseUrl(): void {
    if (location.pathname.length < 3) {
      this.baseAtual = ''
    } else {
      this.baseAtual = '/' + (location.pathname || '').replace('/', '').split('/')[0]
    }
  }
}
