const TiposCapimLista = [
  'Alfafa',
  'Aveia',
  'Azevém',
  'Brachiarão',
  'Brachiaria',
  'Brachiaria MG-4',
  'Brachiaria Brizantha',
  'Brachiaria Brizantha - BRS Paiaguás',
  'Brachiaria - BRS Ipyporã',
  'Brachiaria Decumbens',
  'Brachiaria Dictyoneura - cv. llanero',
  'Brachiaria Humidícola',
  'Brachiaria Híbrida - cv. Mavuno',
  'Brachiaria Híbrida - cv. Mulato II',
  'Brachiaria Híbrida - cv. Sabiá',
  'Brachiaria Ruziziensis',
  'BRS Braúna MG-13',
  'BRS Capiaçu',
  'Capim Andropogon',
  'Capim Angola',
  'Capim Anoni',
  'Capim Aruanã',
  'Capim Buffel Aridus',
  'Capim BRS Kurumi',
  'Capim Coast Cross',
  'Capim Colonião',
  'Capim Elefante',
  'Capim Jaraguá',
  'Capim Marandu',
  'Capim Massai',
  'Capim Mombaça',
  'Capim Napier',
  'Campo Nativo',
  'Capim Piatã',
  'Capim Pangola',
  'Capim Urocloa',
  'Capim Tanzânia',
  'Capim Xaraés',
  'Cynodon nlemfuensis cv. Africana - Grama Estrela',
  'Estilosante',
  'Grama Bermuda',
  'Jiggs',
  'Lianeiro',
  'MG 12 Paredão',
  'Milheto',
  'Pastagem Nativa',
  'Panicum Maximum',
  'Panicum Maximum - BRS Zuri',
  'Panicum Maximum Jacq. Var. gatton - Gatton Panic',
  'Panicum Maximum cv. Mg12 Paredão',
  'Panicum Maximum cv. Miyagi',
  'Palma Miúda (Nopalea cochenillifera Salm Dick)',
  'Panicum Maximum - BRS Quênia',
  'Sorgo',
  'Trevo',
  'Tifton 85',
  'Tifton 68',
  'Tifton 78',
  'Trevo Branco',
  'Tamani',
  'Vaquero'
]

const TiposCapimObject = {}

TiposCapimLista.map(capim => {
  TiposCapimObject[capim] = capim
})

export {
  TiposCapimObject,
  TiposCapimLista
}
