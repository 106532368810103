import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

const configMoment = moment
configMoment.suppressDeprecationWarnings = true
configMoment.locale('pt-br')

@Pipe({
  name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {
  transform(value: string, args: string): string {

    if (!value) return ''

    if (args == 'data') {
      return moment(value).format('DD/MM/YYYY')
    }

    if (args == 'mes-ano') {
      return moment(value).format('MM/YYYY')
    }

    if (args == 'dataAbreviada') {
      return moment(value).format('DD/MM/YY')
    }

    if (args == 'dataSimples') {
      return moment(value).format('DD/MM')
    }

    if (args == 'dataSimplesExtenso') {
      return moment(value).format('DD/MMM')
    }

    if (args == 'horadata') {
      return moment.utc(value).local().format('HH:mm:ss DD/MM/YYYY')
    }

    if (args == 'horadatasemsegundos') {
      return moment.utc(value).local().format('HH:mm DD/MM/YYYY')
    }

    if (args == 'horaminutos') {
      return moment.utc(value).local().format('HH:mm')
    }

    if (args == 'ano') {
      return moment.utc(value).local().format('YYYY')
    }

    if (args == 'tempoatras') {
      return moment.utc(value).fromNow()
    }
  }
}
