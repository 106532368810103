import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { LotesService } from 'src/app/services/lotes.service'
import { ColunasLotes } from 'src/app/utils/configuracoes-padrao'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { NovoQueryParamsModel } from 'src/app/utils/interfaces/query-params.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalEdicaoLoteComponent } from '../../lotes/modal-edicao-lote/modal-edicao-lote.component'

@Component({
  selector: 'app-modal-lista-lotes',
  templateUrl: './modal-lista-lotes.page.html',
  styleUrls: ['./modal-lista-lotes.page.scss']
})
export class ModalListaLotesPage implements OnInit {
  @Input('lotes') lotes: ILote[]
  public multiplos = false
  public confinamento = false
  public lotes_cadastrados = false
  public data_selecao = ''
  public lotesCopy: ILote[]
  public isLoading = false

  public novoFiltro: NovoFiltro = { meta_situacao: 'ativo' }
  public novaOrdenacao: Ordenacao = { coluna: 'qtd_animais', direcao: 'desc' }
  public labelsLotes = [...ColunasLotes]
  public config: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    selecao: {
      ativar: false
    }
  }
  public statusCarregamentoLotes: StatusTabela = 'carregando'

  public hash_not_in: string[] = []
  public hashsSelecionados: string[] = []
  constructor(private modalCtrl: ModalController, private lotesService: LotesService) {}

  async ngOnInit(): Promise<void> {
    if (!this.data_selecao) {
      this.config.excluirColunas = ['qtd_animais_na_data']
    }
    if (this.multiplos) {
      this.config.selecao.ativar = true
      this.labelsLotes = [
        ...[
          {
            tipo: 'checkbox',
            fixa: true
          }
        ],
        ...ColunasLotes
      ]
    }
    this.labelsLotes.pop()
    this.isLoading = true
    if (this.lotes) {
      this.lotesCopy = this.lotes
    } else {
      const params = {}
      if (this.confinamento) {
        params['confinamento'] = 1
      }

      await this.getLotes(params)
    }
    this.isLoading = false
  }

  async getLotes(params?: NovoQueryParamsModel): Promise<void> {
    if (!params) {
      params = {}
    }

    this.statusCarregamentoLotes = 'carregando'
    if (this.hash_not_in && this.hash_not_in.length) {
      params.filtros = this.novoFiltro
      params.filtros.hash_not_in = this.hash_not_in
    }
    if (this.lotes_cadastrados) {
      params.filtros = params.filtros || {}
      params.filtros.lotes_cadastrados = '1'
    }

    params.filtros = params.filtros ? { ...params.filtros, ...this.novoFiltro } : this.novoFiltro
    params.ordenacao = this.novaOrdenacao
    
    const [response, error] = await this.lotesService.getLotes(params, null, this.data_selecao)

    if (response) {
      this.lotes = response.dados
      this.lotesCopy = response.dados
      this.statusCarregamentoLotes = 'sucesso'
    }

    if (error) {
      this.statusCarregamentoLotes = 'erro'
      console.log(error)
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  filtrarListaDeLotes(event: CustomEvent): void {
    this.lotes = this.lotesCopy
    const termo = event.detail.value
    if (termo) {
      const listaDeLotes = this.lotes.map((lote) => String(lote.nome).toLowerCase())
      const listaFiltrada = listaDeLotes.filter((nome) =>
        String(nome).toLowerCase().includes(String(termo).toLowerCase())
      )
      this.lotes = this.lotes.filter((lote) => listaFiltrada.includes(String(lote.nome).toLowerCase()))
    }
  }

  getHashsSelecionados(selecionados: string[]): void {
    this.hashsSelecionados = selecionados
  }

  confirmarLotes(): void {
    const lotesFormatados = this.lotes.filter((item) => this.hashsSelecionados.includes(item.hash))

    this.modalCtrl.dismiss({ lotes: lotesFormatados })
  }

  selecionarLote(linha: DadosLinhaTabela): void {
    if (!this.hashsSelecionados.length) {
      this.modalCtrl.dismiss({ lotes: [linha.original] })
    }
  }

  async cadastrarLote(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEdicaoLoteComponent,
      cssClass: 'custom-modal',
      backdropDismiss: false
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (data?.success) {
      this.getLotes()
    }
  }
}
