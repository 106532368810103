<ng-template [ngIf]="!mode">
  <div class="container-paginate" *ngIf="totalItens">
    <div class="pages">
      <button class="page chevron" (click)="prevPage()" [disabled]="currentPage === 1 || pagesDisplayed.length == 1">
        <ion-icon name="chevron-back"></ion-icon>
      </button>
      <ng-template [ngIf]="mostraPrimeira">
        <button class="page" (click)="goToPage(1)">1</button>
        <button disabled class="page ellipsis" *ngIf="mostraPrimeiraReticencia">...</button>
      </ng-template>
      <button
        *ngFor="let item of pagesDisplayed"
        class="page"
        [ngClass]="{ active: item === currentPage }"
        (click)="goToPage(item)"
      >
        {{ item }}
      </button>
      <ng-template [ngIf]="mostraUltima">
        <button disabled class="page ellipsis" *ngIf="mostraUltimaReticencia">...</button>
        <button class="page" (click)="goToPage(ultimaPagina)">
          {{ ultimaPagina }}
        </button>
      </ng-template>
      <button class="page chevron" (click)="nextPage()" [disabled]="currentPage === ultimaPagina || pagesDisplayed.length == 1">
        <ion-icon name="chevron-forward"></ion-icon>
      </button>
    </div>
    <!-- <div class="actions">
      <button (click)="prevPage()" [disabled]="currentPage === 1 || pagesDisplayed.length == 1">
        <ion-icon name="arrow-back"></ion-icon>
        <span>Página anterior</span>
      </button>
      <button (click)="nextPage()" [disabled]="currentPage === ultimaPagina + 1 || pagesDisplayed.length == 1">
        <span>Próxima página</span>
        <ion-icon name="arrow-forward"></ion-icon>
      </button>
    </div> -->
  </div>
</ng-template>

<ng-template [ngIf]="mode == 'slim'">
  <div class="paginate-slim">
    <!-- <button (click)="prevPage()" *ngIf="currentPage !== 1" title="Página anterior">
      <ion-icon name="arrow-back"></ion-icon>
    </button> -->
    <ng-template [ngIf]="mostraPrimeira">
      <button class="page" (click)="goToPage(1)">1</button>
      <button disabled class="page ellipsis" *ngIf="mostraPrimeiraReticencia">...</button>
    </ng-template>
    <button
      *ngFor="let item of pagesDisplayed"
      class="page"
      [ngClass]="{ active: item === currentPage }"
      (click)="goToPage(item)"
    >
      {{ item }}
    </button>
    <ng-template [ngIf]="mostraUltima">
      <button disabled class="page ellipsis" *ngIf="mostraUltimaReticencia">...</button>
      <button class="page" (click)="goToPage(ultimaPagina)">
        {{ ultimaPagina }}
      </button>
    </ng-template>
    <!-- <button (click)="nextPage()" *ngIf="currentPage !== endPage" title="Próxima página">
      <ion-icon name="arrow-forward"></ion-icon>
    </button> -->
  </div>
</ng-template>
