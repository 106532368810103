<div class="container-cards" [class.wrap]="wrap" *ngIf="tipo === 'cards'">
  <ng-template ngFor let-card [ngForOf]="cards">
    <div
      *ngIf="!card.ocultar"
      [class.hidden]="card.escondeSeVazio && !(card.valor || valores[card?.key])"
      [class.clickable]="!!card.acao"
      (click)="clickCard(card)"
      class="card {{ card.classe }} {{card?.formatacao}}"
      [class.item-bloqueado-plano-basico]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('indicadores', card?.key)"
    >
      <div class="info" *ngIf="card.info">
        <ion-icon name="help-circle-sharp"></ion-icon>
      </div>
      <div class="info-content">
        {{ card.info }}
      </div>
      <span>{{ card.titulo }}</span>
      <p class="recurso-pro" 
        *ngIf="card?.key && planoBasicoCtrl.isPlanoBasico && planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('indicadores', card.key)  && !propriedadesCtrl.isPropriedadeExemploAtivada"
        (click)="planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('indicadores', card.key, $event)"
      >
        Premium
        <ion-icon name="diamond" color="primary"></ion-icon>
      </p>
      <p class="titlecase" [class.capitalize]="card.capitalize" *ngIf="!planoBasicoCtrl.isPlanoBasico || propriedadesCtrl.isPropriedadeExemploAtivada  || 
      (planoBasicoCtrl.isPlanoBasico &&
      !planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('indicadores', card.key))">
        {{formatarValorCard(card)}}
        <small class="unidade">{{ card.unidade ? card.unidade : '' }}</small>
        <span class="indicador" *ngIf="card.indicador" [class]="card.indicador"></span>
      </p>
    </div>
  </ng-template>
</div>

<ul class="container-comprovante" *ngIf="tipo === 'comprovante'">
  <li *ngFor="let card of cards">
    <strong class="item-titulo">{{card.titulo}}</strong>
    <p class="item-valor">
      {{formatarValorCard(card)}}
      <small class="unidade">{{ card.unidade ? card.unidade : '' }}</small>
    </p>
  </li>
</ul>
