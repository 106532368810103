<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Selecionar Propriedade
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="modalClose(); this.menuClose()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-tabela
    [labels]="labels"
    [campos]="propriedades"
    [config]="config"
    [status]="carregamentoPropriedades"
    [optionsButtons]="optionsButtonsPropriedades"
    (retentar)="atualizarPropriedades()"
    (selecionado)="selecionarPropriedade(null, $event)"
  ></vasta-tabela>
</ion-content>
<ion-footer>
  <cta-buttons>
    <button (click)="selecionarPropriedadeExemplo()">
      <ion-icon name="home"></ion-icon>
      <span>Fazenda Demonstração</span>
    </button>
  </cta-buttons>
  <cta-buttons>
    <button
      [class.item-bloqueado-plano-basico]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('acoes', 'cadastro-propriedades')"
      (click)="openModalPropriedade($event)"
      *ngIf="carregamentoPropriedades == 'sucesso' && permiteCadastrarPropriedade"
      [disabled]="propriedades?.length > 9"
    >
      <ion-icon name="add-circle"></ion-icon>
      <span>Nova Propriedade</span>
    </button>
  </cta-buttons>
</ion-footer>
