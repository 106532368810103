
<div class="piquetes-container">
  <span
    class="piquetes-item"
    *ngIf="showNenhum"
    (click)="selecionarPiquete('nenhum')"
  >
    <div class="icon">
      <ion-icon
        name="checkmark-sharp"
        *ngIf="popoverCheck && 'nenhum' === piqueteSelecionado"
      ></ion-icon>
    </div>
    Nenhum
  </span>

  <ng-template ngFor let-piqueteHash [ngForOf]="piquetes">
    <span
      class="piquetes-item"
      (click)="selecionarPiquete(piqueteHash)"
    >
    <div class="icon">
      <ion-icon
        name="checkmark-sharp"
        *ngIf="popoverCheck && piqueteHash === piqueteSelecionado"
      ></ion-icon>
    </div>
      {{ piquetesAssociados[piqueteHash]?.nome }}
    </span>
  </ng-template>
  <span class="piquetes-info" *ngIf="piquetes.length == 0">
    {{ mensagem }}
  </span>
</div>