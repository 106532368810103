import { Injectable } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import { PopoverRecursoPremiumComponent } from '../components/popover-recurso-premium/popover-recurso-premium.component'
import { ModalAssineAgoraPage } from '../pages/geral/modal-assine-agora/modal-assine-agora.page'
import { IKpisCard } from '../utils/interfaces/tabela-colunas'
import { PermissoesUsuarioService } from './permissoes-usuario.service'
import { UsuariosService } from './usuarios.service'
import { IonicUtilsService } from './utils/ionic-utils.service'
import { ApiService } from './api/api.service'

import PONTOS_CONVERSAO from '../utils/pontos-conversao.json'
import { PropriedadesService } from './propriedades.service'

type CheckPlanoBasico = { isPlanoBasico: boolean }
type PermissaoDeAcessoAoItem = { acessoAoItemBloqueado: boolean }

export type CategoriaDeItensBloqueados =
  | 'manejos'
  | 'abasMenuLateral'
  | 'visaoGeral'
  | 'relatorios'
  | 'tabs'
  | 'acoes'
  | 'indicadores'
  | 'cta'
  | 'colunasTabela'

type ItensBloqueadosPlanoBasico = {
  [key in CategoriaDeItensBloqueados]: string[]
}

type InfoRecursoPremium = {
  titulo: string
  descricao: string,
  isTrialIniciado: boolean,
  ev: Event
  slug: string
}

@Injectable({
  providedIn: 'root'
})
export class PlanoBasicoService {
  private readonly _listaDeItensBloqueadosEmPlanoBasico: ItensBloqueadosPlanoBasico = {
    manejos: [
      'movimentacao-confinamento',
      'escore',
      'transferencia-propriedade',
      'implante',
      'implante-remocao',
      'cio',
      'castracao',
      'nutricao',
      'numero-brinco-eletronico',
      'vermifugacao',
      'procedimentos-veterinarios',
      'exames',
      'banho',
      'complexo-vitaminico',
      'tratamento-clinico',
      'antiparasitarios',
      'diagnostico',
      'outros'
    ],
    visaoGeral: [
      'saibaMais',
      'financeiro',
      'usuarios',
      'calendario',
      'pluviometria',
      'arrobas-produzidas',
      'estacoes-reprodutivas',
      'desembolso-cabeca-mes'
    ],
    abasMenuLateral: [
      'financeiro',
      'calendario',
      'usuarios',
      'aprovacoes',
      'pluviometria'
    ],
    relatorios: [
      'pesagem',
      'arrobas-produzidas',
      'custo-arroba-produzida',
      'ganho-de-peso',
      'mapa-sanitario',
      'mortalidade',
      'chuvas',
      'estacoes-reprodutivas',
      'previsao-partos',
      'partos',
      'previsao-de-desmames',
      'fluxo-de-caixa',
      'analise-de-recebimentos',
      'analise-de-pagamentos',
      'movimentacoes',
      'custo-e-lucratividade',
      'abortos',
      'producao-de-leite',
      'producao-de-leite-por-vaca',
      'desempenho-nutricional',
      'vacas-aptas-pos-parto',
      'vacas-expostas-sem-dg',
      'intervalo-entre-partos',
      'desembolso-cabeca-mes',
      'indice-perda',
    ],
    tabs: [
      'Nutricional',
      'Histórico',
      'Piquetes',
      'Custos',
      'Pastejo Rotacionado',
      'Fertili 360'
    ],
    acoes: [
      'exportacao',
      'impressao',
      'manejos-importar',
      'exportarAnimais',
      'importar-manejo-cio',
      'importar-manejo-implante',
      'importar-manejo-implante-remocao',
      'importar-manejo-cio',
      'importar-manejo-castracao',
      'cadastro-propriedades',
      'cadastro-animais-atalho',
      'registrar-venda',
      'agendarEvento'
    ],
    indicadores: [
      'lotacao',
      'total_peso',
      'total_ua',
      'ocupacao',
      'cabecaHectare',
      'total_lotacao',
      'media_gmd',
      'gmd',
      'max_lotacao',
      'tx_ocupacao',
      'tx_lotacao',
      'GMD Médio'
    ],
    colunasTabela: [
      'pesagem_gmd',
      'gmd',
      'tx_ocupacao',
      'tx_lotacao'
    ],
    cta: [
      'manejos',
      'menu_lateral',
      'visao_geral'
    ]
  }
  classBodyAtiva = false
  constructor(
    private usuarioCtrl: UsuariosService,
    private modalCtrl: ModalController,
    private permissoesUsuarioCtrl: PermissoesUsuarioService,
    private popoverCtrl: PopoverController,
    private utilsCtrl: IonicUtilsService,
    private api: ApiService,
    private propriedadesCtrl: PropriedadesService
  ) {
    this.usuarioCtrl.getUsuarioStorage()
    this.atualizarClasseCssDePlanoBasicoDoUsuario()
  }

  get isPlanoBasico(): boolean {
    const isPlanoDoUsuarioBasico = this.propriedadesCtrl.propriedadeSelecionada?.plano_ativo === 'basico'

    if (this.classBodyAtiva !== isPlanoDoUsuarioBasico) {
      this.classBodyAtiva = isPlanoDoUsuarioBasico
      this.atualizarClasseCssDePlanoBasicoDoUsuario()
    }
    return isPlanoDoUsuarioBasico
  }

  get isMinhaPropriedade(): boolean {
    return this.propriedadesCtrl.propriedadeSelecionada?.meu_role === 'proprietario'
  }

  get textoAtivarPlanoPremium(): string {
    return this.usuarioCtrl?.usuarioCache?.trial_iniciado === 1 ? 'Conheça o Plano Premium' : 'Experimente o Plano Premium'
  }

  async atualizarPlanoAtivoDoUsuario(): Promise<void> {
    await this.usuarioCtrl.getUsuarioSingleEAtualizaUsuarioCache()
    await this.permissoesUsuarioCtrl.verificaTopBar()
    this.atualizarClasseCssDePlanoBasicoDoUsuario()
  }

  public atualizarClasseCssDePlanoBasicoDoUsuario(): void {
    if (this.isPlanoBasico && !this.propriedadesCtrl.isPropriedadeExemploAtivada) {
      document.body.classList.add('plano-basico')
    } else {
      document.body.classList.remove('plano-basico')
    }

    if (this.isPlanoBasico && !this.isMinhaPropriedade && !this.propriedadesCtrl.isPropriedadeExemploAtivada) {
      document.body.classList.add('hidden-plano-basico')
    } else {
      document.body.classList.remove('hidden-plano-basico')
    }

    VastaRX.setState('atualizar-plano-basico', true)
  }

  public async verificarSeAssinaturaPermiteAcessoAoItem(categoriaItem: CategoriaDeItensBloqueados, itemSlug: string, ev: Event): Promise<PermissaoDeAcessoAoItem> {
    console.log('itemSlug', itemSlug)
    return new Promise(async (resolve) => {
      const itemFormatado = itemSlug?.replace('/', '')

      if (!this._listaDeItensBloqueadosEmPlanoBasico[categoriaItem].includes(itemFormatado)) {
        return resolve({ acessoAoItemBloqueado: false })
      }
      const infoRecursoPremium: InfoRecursoPremium = {
        ...PONTOS_CONVERSAO[categoriaItem][itemFormatado],
        isTrialIniciado: this.usuarioCtrl.usuarioCache?.trial_iniciado === 1 ? true : false,
        slug: `${categoriaItem}_${itemFormatado}`,
        ev
      }

      const { isPlanoBasico } = await this.abrirPopoverRecursoPremium(infoRecursoPremium)
      return resolve({ acessoAoItemBloqueado: isPlanoBasico })
    })
  }

  async sePlanoAtivoForBasicoAbrirModalDeAssinatura(): Promise<CheckPlanoBasico> {
    return new Promise(async (resolve) => {
      if (!this.isPlanoBasico) return resolve({ isPlanoBasico: false })
      if (await this.popoverCtrl.getTop()) {
        this.popoverCtrl.dismiss()
      }
      const { isPlanoBasico: isContinuaComoPlanoBasico } = await this.abrirModalDeAssinatura()
      return resolve({ isPlanoBasico: isContinuaComoPlanoBasico })
    })
  }

  private async abrirModalDeAssinatura(): Promise<CheckPlanoBasico> {
    return new Promise(async (resolve) => {
      const modal = await this.modalCtrl.create({
        component: ModalAssineAgoraPage,
        cssClass: 'tall-modal',
        backdropDismiss: true,
      })

      await modal.present()

      const { data } = await modal.onDidDismiss<CheckPlanoBasico>()
      if (!data) return resolve({ isPlanoBasico: true })
      return resolve(data)
    })
  }

  private async abrirPopoverRecursoPremium({ titulo, descricao, isTrialIniciado, ev, slug }: InfoRecursoPremium): Promise<CheckPlanoBasico> {
    return new Promise(async (resolve) => {
      if (!this.isPlanoBasico) return resolve({ isPlanoBasico: false })

      const popover = await this.popoverCtrl.create({
        component: PopoverRecursoPremiumComponent,
        componentProps: { titulo, descricao, isTrialIniciado, slug },
        cssClass: 'popover-recurso-premium',
        backdropDismiss: true,
        event: ev
      })

      await popover.present()
    })
  }

  async iniciarFluxoPlanoTrial(): Promise<void> {
    await this.iniciarPlanoTrial()

    this.permissoesUsuarioCtrl.desabilitarAcessoDoUsuario()
    this.propriedadesCtrl.removerPropriedadeSelecionada()
    this.usuarioCtrl.getUsuarioSingleEAtualizaUsuarioCache()

    VastaRX.setState('iniciando-plano-trial', true)

    this.permissoesUsuarioCtrl.atualizarInformacoesDePermissaoDoUsuario()

    this.permissoesUsuarioCtrl.verificaTopBar()
  }

  public async iniciarPlanoTrial(): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circles', 'Iniciando Teste Grátis')
    const [, error] = await this.usuarioCtrl.iniciarPlanoTrial()

    if (error) {
      console.error(error)
      this.utilsCtrl.showToast('Erro ao iniciar teste grátis, tente novamente', 'top')
    } else {
      this.permissoesUsuarioCtrl.liberarAcessoAoUsuario()
      this.api.setPassoFunil('trial')
    }

    this.utilsCtrl.dismissLoading(loading)
  }

  public checkSeItemEstaBloqueadoEmPlanoBasico(chaveCategoria: CategoriaDeItensBloqueados, itemSlug: string): boolean {
    if (!itemSlug || !this.isPlanoBasico) return false

    const itemFormatado = itemSlug.replace('/', '')
    return this._listaDeItensBloqueadosEmPlanoBasico[chaveCategoria].includes(itemFormatado)
  }

  public removerChavesEValoresDosIndicadoresSePlanoForBasico(optionsKpisCard: IKpisCard[], kpisValores?: { [key: string]: number }): void {
    if (this.isPlanoBasico) {
      optionsKpisCard.forEach(option => {
        const isItemBloqueadoEmPlanoBasico = this.checkSeItemEstaBloqueadoEmPlanoBasico('indicadores', option.key)

        if (isItemBloqueadoEmPlanoBasico) {
          if (kpisValores?.[option.key]) {
            delete kpisValores[option.key]
          }
          option.valor = null
        }
      })
    }
  }
}
