import { Component, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { Categorias } from 'src/app/pages/financas/financas-categorias'
import { ICategoria } from 'src/app/utils/interfaces/financas.interface'

@Component({
  selector: 'app-popover-financas-categorias',
  templateUrl: './popover-financas-categorias.component.html',
  styleUrls: ['./popover-financas-categorias.component.scss']
})
export class PopoverFinancasCategoriasComponent implements OnInit {
  public tipo: 'entrada' | 'saida' | 'todos' = 'entrada'
  public selecaoMultipla = false
  public idsCategoriasSelecionadas: string[] = []
  public categoriasSelecionadas: ICategoria[] = []
  public listaDeCategoriasPadrao: ICategoria[] = Categorias.financeiro_entrada
  public listaCategorias = []

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {
    if (this.tipo == 'todos') {
      this.listaDeCategoriasPadrao = [...Categorias.financeiro_entrada, ...Categorias.financeiro_saida]
    }
    if (this.tipo == 'saida') {
      this.listaDeCategoriasPadrao = Categorias.financeiro_saida
    }

    this.listaCategorias = [...this.listaDeCategoriasPadrao]

    if (this.idsCategoriasSelecionadas?.length > 0) {
      this.idsCategoriasSelecionadas.forEach((id) => {
        this.categoriasSelecionadas.push(this.listaDeCategoriasPadrao.find((cat) => cat.id === id))
      })
    }
  }

  selecionarCategoria(categoria: ICategoria): void {
    if (this.selecaoMultipla) {
      if (this.categoriasSelecionadas.find((cat) => cat.id === categoria.id)) {
        this.categoriasSelecionadas = this.categoriasSelecionadas.filter((cat) => cat.id !== categoria.id)
      } else {
        this.categoriasSelecionadas.push(categoria)
      }
    } else {
      this.popoverCtrl.dismiss({ listaCategorias: this.listaDeCategoriasPadrao, categoria })
    }
  }

  filtrarCategorias(event: CustomEvent): void {
    this.listaCategorias = [...this.listaDeCategoriasPadrao]
    const termo = event.detail.value
    const listaFiltrada: ICategoria[] = []
    const listaFiltradaComSeparador: ICategoria[] = []

    if (termo && termo.trim() != '') {
      this.listaDeCategoriasPadrao.forEach((categoria) => {
        categoria = {...categoria}
        const matchID = categoria.id
          .replace('receitas_', '')
          .replace('despesas_', '')
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            termo
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
        const matchNome = categoria.nome
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            termo
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
        if (
          matchID ||
          matchNome ||
          categoria.type === 'separator'
        ) {
          categoria.match = !!matchNome
          listaFiltrada.push(categoria)
        }
      })

      let prevItem = null

      listaFiltrada.reverse().forEach((item) => {
        if (item?.type !== 'separator') {
          prevItem = item
          listaFiltradaComSeparador.push(item)
        } else if (prevItem) {
          prevItem = null
          listaFiltradaComSeparador.push(item)
        }
      })
    }

    if (listaFiltrada.length > 0) this.listaCategorias = listaFiltradaComSeparador.reverse()
  }

  isSelecionada(categoria: ICategoria): boolean {
    return this.categoriasSelecionadas.find((cat) => cat.id === categoria.id) ? true : false
  }

  confirmarSelecao(): void {
    this.popoverCtrl.dismiss({ listaCategorias: this.listaDeCategoriasPadrao, categorias: this.categoriasSelecionadas })
  }
}
