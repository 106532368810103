import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { PermissoesUsuarioService } from 'src/app/services/permissoes-usuario.service'
import { PlanosAssinaturaService } from 'src/app/services/plano-assinatura.service'
import { UsuariosService } from 'src/app/services/usuarios.service'
import { StatusPermissaoUsuario } from 'src/app/utils/interfaces/Usuarios.interface'

@Component({
  selector: 'app-modal-conta-bloqueada',
  templateUrl: './modal-conta-bloqueada.page.html',
  styleUrls: ['./modal-conta-bloqueada.page.scss']
})
export class ModalContaBloqueadaPage implements OnInit {
  constructor(
    private planosAssinaturaCtrl: PlanosAssinaturaService,
    private usuarioCtrl: UsuariosService,
    private modalCtrl: ModalController,
    private permimissaoCtrl: PermissoesUsuarioService,
  ) {}

  ngOnInit(): void {
    const statusValidoEmFluxoInicialVisaoGeral: StatusPermissaoUsuario = 'bloqueado'
    this.permimissaoCtrl.monitorarPermissaoUsuarioFecharModalIncompativel(statusValidoEmFluxoInicialVisaoGeral, this.modalCtrl)
  }

  entrarEmContato(): void {
    this.planosAssinaturaCtrl.entrarEmContatoPorWhatsapp()
  }

  async sairDaConta(): Promise<void> {
    await this.usuarioCtrl.sairDaConta()
  }
}
