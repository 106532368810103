<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Movimentação Entre Fazendas</div>

    <vasta-input
      type="manejo-segment"
      [(value)]="dados.novo_status"
      [opcoes]="[
        { label: 'Movido', value: 'movido' },
        { label: 'Não Movido', value: 'nao-movido' }
      ]"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.novo_status == 'movido'"
      label="Propriedade"
      type="manejo-select"
      [(value)]="dados.transf_propriedade_id"
      [opcoes]="propriedadesOpcoes"
      (valueChange)="dados.edited = true; emit(); selecionarListaLotes()"
      [disabled]="!!dados?.transf_propriedade_novo_hash"
    ></vasta-input>

    <vasta-input
      *ngIf="dados.novo_status == 'movido' && dados.transf_propriedade_id"
      label="Lote"
      type="manejo-select"
      [(value)]="dados.transf_propriedade_hash_lote"
      [opcoes]="lotesOpcoes[dados.transf_propriedade_id] || []"
      (valueChange)="dados.edited = true; emit()"
      [disabled]="!!dados?.transf_propriedade_novo_hash || !lotesOpcoes[dados.transf_propriedade_id]?.length"
    ></vasta-input>

    <div class="manejo-info" *ngIf="dados?.transf_propriedade_novo_hash && dados.novo_status !== 'movido'">
      <span class="label">Alerta</span>
      <p class="content">O animal criado na propriedade destino continuará disponível</p>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.transf_propriedade_id">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
