import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { ModalEstoqueBiologicoComponent } from 'src/app/pages/estoque/modal-estoque-biologico/modal-estoque-biologico.component'
import { EstoqueBiologico } from 'src/app/utils/interfaces/estoque.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { PopoverSeletorComponent } from '../popover-seletor/popover-seletor.component'
import { ModalListaPessoasPage } from 'src/app/pages/financas/modal-lista-pessoas/modal-lista-pessoas.page'
import { Pessoa } from 'src/app/utils/interfaces/financas.interface'

@Component({
  selector: 'app-manejo-reprodutivo-inseminacao',
  templateUrl: './manejo-reprodutivo-inseminacao.component.html',
  styleUrls: ['./manejo-reprodutivo-inseminacao.component.scss']
})
export class ManejoReprodutivoInseminacaoComponent implements OnInit {
  @Input('dados') dados = { 
    inseminacao_semen: '', 
    inseminacao_embriao: '',
    inseminacao_bastao: '',
    inseminacao_muco: '',
    inseminacao_inseminador: '',
    observacao: '', 
    edited: true 
  }
  @Input('data') data: string
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  @Input('estoquesAssociados') estoquesAssociados: { [key: string]: EstoqueBiologico } = {}
  @Output() estoquesAssociadosChange = new EventEmitter<{ [key: string]: EstoqueBiologico }>()

  @Input('opcoesRapidasEstoqueBiologico') opcoesRapidasEstoqueBiologico: string[] = []
  @Output() opcoesRapidasEstoqueBiologicoChange = new EventEmitter<string[]>()

  public manejosReprodutivosAvancados = false

  public opcoesBastao = {
    presente: { texto: 'Presente'},
    ausente: { texto: 'Ausente'},
  }

  public opcoesMuco = {
    presente: { texto: 'Presente'},
    ausente: { texto: 'Ausente'},
  }

  @Input() pessoasAssociadas: { [key: string]: Pessoa } = {}
  @Output() pessoasAssociadasChange = new EventEmitter<{ [key: string]: Pessoa }>()

  @Input() opcoesRapidasPessoas: string[] = []
  @Output() opcoesRapidasPessoasChange = new EventEmitter<string[]>()

  constructor(
    private modalCtrl: ModalController, 
    private popoverCtrl: PopoverController, 
    public propriedadesCtrl: PropriedadesService
  ) { }

  ngOnInit(): void {
    console.log('estoquesAssociados', this.estoquesAssociados)
    this.verificarManejosReprodutivosAvancados()
  }

  emit(): void {
    this.opcoesRapidasEstoqueBiologico = this.opcoesRapidasEstoqueBiologico.slice(0, 3)
    this.opcoesRapidasPessoas = this.opcoesRapidasPessoas.slice(0, 3)
    this.opcoesRapidasEstoqueBiologicoChange.emit(this.opcoesRapidasEstoqueBiologico)
    this.estoquesAssociadosChange.emit(this.estoquesAssociados)
    this.opcoesRapidasPessoasChange.emit(this.opcoesRapidasPessoas)
    this.pessoasAssociadasChange.emit(this.pessoasAssociadas)
    this.dadosChange.emit(this.dados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  verificarManejosReprodutivosAvancados(): void {
    if(Number(this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.manejos_reprodutivos_avancados)){
      this.manejosReprodutivosAvancados = true
    } else {
      this.manejosReprodutivosAvancados = false
    }
  }

  selecionarOpcoesRapidas(hash: string, tipo: string): void {
    this.dados.edited = true
    if (tipo == 'semen') {
      this.dados.inseminacao_semen = hash
      this.dados.inseminacao_embriao = ''
    } else {
      this.dados.inseminacao_semen = ''
      this.dados.inseminacao_embriao = hash
    }
    this.emit()
  }

  selecionarOpcoesRapidasPessoas(hash: string): void {
    this.dados.edited = true
    this.dados.inseminacao_inseminador = hash
    this.emit()    
  }

  async showModalEstoqueBiologico(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEstoqueBiologicoComponent,
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      this.opcoesRapidasEstoqueBiologico.push(data.hash)
      if (data.tipoDoEstoque === 'semen') {
        this.dados.inseminacao_semen = data.hash
        this.dados.inseminacao_embriao = ''
        this.estoquesAssociados[data.hash] = data
      } else {
        this.dados.inseminacao_embriao = data.hash
        this.dados.inseminacao_semen = ''
        this.estoquesAssociados[data.hash] = data
      }
      this.dados.edited = true
      this.emit()
    }
  }

  async abrirModalListaPessoas(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaPessoasPage,
      componentProps: {
        filtroPessoas: {
          categoria_pai: 'inseminador'
        }
      },
      backdropDismiss: false,
      cssClass: 'custom-modal-seletor'
    })

    modal.present()

    const { data } = await modal.onDidDismiss()

    if (data.pessoaSelecionada) {
      this.opcoesRapidasPessoas.push(data.pessoaSelecionada.hash)
      this.dados.inseminacao_inseminador = data.pessoaSelecionada.hash
      this.pessoasAssociadas[data.pessoaSelecionada.hash] = data.pessoaSelecionada
    }

    this.dados.edited = true
    this.emit()

  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()

    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.inseminacao_semen = null
      this.dados.inseminacao_embriao = null
      this.dados.inseminacao_inseminador = null
      this.dados.inseminacao_bastao = null
      this.dados.inseminacao_muco = null
      this.emit()
    }
  }

  async showPopoverOpcoesBastao(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverSeletorComponent,
      event,
      reference: 'trigger',
      componentProps: {
        opcoesKeys: Object.keys(this.opcoesBastao),
        opcoes: this.opcoesBastao,
      },
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()

    if(data){
      this.dados.inseminacao_bastao = data
      this.dados.edited = true
      this.emit()
    }

  }

  async showPopoverOpcoesMuco(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverSeletorComponent,
      event,
      reference: 'trigger',
      componentProps: {
        opcoesKeys: Object.keys(this.opcoesMuco),
        opcoes: this.opcoesMuco,
      },
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()

    if(data){
      this.dados.inseminacao_muco = data
      this.dados.edited = true
      this.emit()
    }
  }
}
