<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-title>{{ propriedadeProp ? 'Editar Propriedade' : 'Cadastrar Propriedade' }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" color="primary" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="modal-form">
    <p>Todos os campos com * são obrigatórios.</p>

    <vasta-input [label]="'Nome da propriedade'" [obrigatorio]="true" [type]="'text'" [(value)]="propriedade.nome" (valueChange)="tick()"></vasta-input>

    <ion-row class="ion-justify-content-between">
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          [placeholder]="'Selecionar'"
          [obrigatorio]="true"
          [label]="'Tipo de produção'"
          [type]="'select'"
          [(value)]="propriedade.metadados.tipo_producao"
          [opcoes]="[
              { label: 'Corte', value: 'corte' },
              { label: 'Leite', value: 'leite' },
              { label: 'Corte e Leite', value: 'dupla' }
            ]"
          (valueChange)="tick()"
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-right: 0">
        <vasta-input
          placeholder="Selecionar"
          [obrigatorio]="true"
          label="Tipo de Criação"
          [type]="'select'"
          [(value)]="propriedade.metadados.tipo_criacao"
          [opcoes]="[
              { label: 'Cria',value: 'cria' },
              { label: 'Recria', value: 'recria' },
              { label: 'Engorda', value: 'engorda' },
              { label: 'Ciclo Completo', value: 'ciclo-completo' }
            ]"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <vasta-input [label]="'Endereço'" [type]="'textarea'" [obrigatorio]="true" [(value)]="propriedade.endereco" (valueChange)="tick()"></vasta-input>

    <vasta-input
      [label]="'Cidade'"
      [type]="'text'"
      [obrigatorio]="true"
      [(value)]="propriedade.cidade"
      [placeholder]="'Cidade'"
      (valueChange)="tick()"
    ></vasta-input>

    <ion-row class="ion-justify-content-between">
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          [label]="'Estado'"
          [type]="'text'"
          [obrigatorio]="true"
          [(value)]="propriedade.estado"
          [placeholder]="'Estado'"
          (valueChange)="tick()"
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-right: 0">
        <vasta-input
          [label]="'País'"
          [type]="'text'"
          [obrigatorio]="true"
          [(value)]="propriedade.pais"
          [placeholder]="'País'"
          (valueChange)="tick()"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <vasta-input
      [placeholder]="'Selecionar'"
      [obrigatorio]="true"
      [label]="'Raça Principal'"
      [type]="'button'"
      [value]="racas[propriedade.metadados?.raca_principal]"
      (valueChange)="propriedade.metadados.raca_principal = $event"
      (buttonEvent)="openModalListaRacas()"
    ></vasta-input>

    <ion-row class="ion-justify-content-between">
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          [placeholder]="'Selecionar'"
          [label]="'Controlar Animais por'"
          [type]="'select'"
          [(value)]="propriedade.metadados.controle_animais"
          [opcoes]="[
              { value: 'numero-e-nome', label: 'Nome e Número' },
              { value: 'numero', label: 'Somente Número' },
              { value: 'nome', label: 'Somente Nome' }
            ]"
          (valueChange)="tick()"
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-right: 0">
        <vasta-input
          [placeholder]="'Selecionar'"
          [label]="'Sistema de Criação'"
          [type]="'select'"
          [(value)]="propriedade.metadados.sistema_criacao"
          [opcoes]="[
              { value: 'intensivo', label: 'Intensivo' },
              { value: 'extensivo', label: 'Extensivo' },
              { value: 'semi-intensivo', label: 'Semi-Intensivo' }
            ]"
          (valueChange)="tick()"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <vasta-input
      *ngIf="propriedadeProp"
      label="Relatório de Pesagens por"
      type="select"
      [(value)]="propriedade.metadados.unidade_peso"
      [opcoes]="[
        { value: '@', label: '@' },
        { value: 'kg', label: 'Kg' },
      ]"
      tooltipLabel="Unidade de medida padrão para relatórios de pesagens"
      tooltipPosition="left"
      tooltipType="help"
    ></vasta-input>

    <vasta-input
      *ngIf="propriedadeProp"
      label="Tempo de Gestação das Matrizes"
      [type]="'text'"
      [obrigatorio]="false"
      [(value)]="propriedade.metadados.tempo_gestacao"
      mask="separator.0"
      maskSuffix=" dias"
      placeholder="283 dias"
      tooltipLabel="Dias de gestação das matrizes, utilizado para cálculo da previsão de parto"
      tooltipPosition="left"
      tooltipType="help"
    ></vasta-input>

    <vasta-input
      *ngIf="propriedadeProp"
      [placeholder]="'Padrão - (05:00)'"
      [label]="'Horário de Notificação do Calendário'"
      [type]="'select'"
      [(value)]="propriedade.metadados.hora_alertas_calendario"
      [opcoes]="[
          { value: '0', label: '00:00' },
          { value: '1', label: '01:00' },
          { value: '2', label: '02:00' },
          { value: '3', label: '03:00' },
          { value: '4', label: '04:00' },
          { value: '5', label: '05:00' },
          { value: '6', label: '06:00' },
          { value: '7', label: '07:00' },
          { value: '8', label: '08:00' },
          { value: '9', label: '09:00' },
          { value: '10', label: '10:00' },
          { value: '11', label: '11:00' },
          { value: '12', label: '12:00' },
          { value: '13', label: '13:00' },
          { value: '14', label: '14:00' },
          { value: '15', label: '15:00' },
          { value: '16', label: '16:00' },
          { value: '17', label: '17:00' },
          { value: '18', label: '18:00' },
          { value: '19', label: '19:00' },
          { value: '20', label: '20:00' },
          { value: '21', label: '21:00' },
          { value: '22', label: '22:00' },
          { value: '23', label: '23:00' }
        ]"
        tooltipLabel="Hora do dia em que o Fertili 360 irá notificar as tarefas do dia"
        tooltipPosition="left"
        tooltipType="help"
    ></vasta-input>

    <vasta-input
      *ngIf="propriedadeProp"
      placeholder="Selecionar"
      label="Idade para Desmame / Apartação"
      type="select"
      [(value)]="propriedade.metadados.tempo_desmame"
      [opcoes]="[
        { value: '0', label: 'Imediato' },
        { value: '30', label: '1 mês' },
        { value: '60', label: '2 meses' },
        { value: '90', label: '3 meses' },
        { value: '120', label: '4 meses' },
        { value: '150', label: '5 meses' },
        { value: '180', label: '6 meses' },
        { value: '210', label: '7 meses' },
        { value: '240', label: '8 meses' },
        { value: '270', label: '9 meses' },
        { value: '300', label: '10 meses' },
        { value: '330', label: '11 meses' },
        { value: '365', label: '12 meses' },
      ]"
      tooltipLabel="Meses de espera para desmamar um animal, usado na previsão de desmame"
      tooltipPosition="left"
      tooltipType="help"
    ></vasta-input>

    <vasta-input
      label="Período Minimo para Diagnóstico de Gestação"
      type="text"
      [obrigatorio]="false"
      [(value)]="propriedade.metadados.periodo_minimo_diagnostico_gestacao"
      mask="separator.0"
      maskSuffix=" dias"
      placeholder="30 dias"
      tooltipLabel="Intervalo em dias necessário para realizar um novo diagnóstico de gestação"
      tooltipPosition="left"
      tooltipType="help"
    ></vasta-input>

    <vasta-input
      label="Tempo Voluntário de Espera Mínimo"
      type="text"
      [obrigatorio]="false"
      [(value)]="propriedade.metadados.tempo_ate_vaca_ficar_apta"
      mask="separator.0"
      maskSuffix=" dias"
      placeholder="30 dias"
      tooltipLabel="Dias após o parto em que a vaca não deve ser considerada apta"
      tooltipPosition="left"
      tooltipType="help"
    ></vasta-input>

    <vasta-input
      label="Peso Padrão Da Cria ao Nascer"
      type="text"
      [obrigatorio]="false"
      [(value)]="propriedade.metadados.peso_padrao_ao_nascer"
      mask="separator.0"
      maskSuffix=" kg"
      placeholder="30 kg"
      tooltipLabel="Ao cadastrar um animal, o peso padrão será preenchido automaticamente"
      tooltipPosition="left"
      tooltipType="help"
    ></vasta-input>

    <vasta-input
      [label]="'Ativar uso de Brincos Eletrônicos'"
      [type]="'checkbox'"
      [(valueCheckbox)]="propriedade.metadados.mostra_brinco"
    ></vasta-input>


    <ng-template [ngIf]="propriedade.metadados.tipo_producao === 'leite' || propriedade.metadados.tipo_producao === 'dupla'">
      <h2>Controle Leiteiro</h2>

      <vasta-input
        [placeholder]="'Selecionar'"
        [label]="'Ordenhas por Dia'"
        [type]="'select'"
        [(value)]="propriedade.metadados.quantidade"
        [opcoes]="[
            { value: '1', label: '1 ordenha' },
            { value: '2', label: '2 ordenhas' },
            { value: '3', label: '3 ordenhas' }
          ]"
      ></vasta-input>
    </ng-template>

    <div class="campos-grupo" *ngIf="propriedadeProp">
      <h2>Extra</h2>

      <vasta-input
        label="Isolar financeiro da propriedade"
        type="checkbox"
        [(valueCheckbox)]="propriedade.metadados.isolar_financeiro"
        *ngIf="propriedadesCtrl?.propriedadeSelecionada?.meu_role === 'proprietario'"
      ></vasta-input>

      <vasta-input
        label="Isolar estoque da propriedade"
        type="checkbox"
        [(valueCheckbox)]="propriedade.metadados.isolar_estoque"
        *ngIf="propriedadesCtrl?.propriedadeSelecionada?.meu_role === 'proprietario'"
      ></vasta-input>

      <vasta-input
        label="Campos avançados de manejos reprodutivos"
        type="checkbox"
        [(valueCheckbox)]="propriedade.metadados.manejos_reprodutivos_avancados"
      ></vasta-input>

      <vasta-input
        label="Habilitar campos de melhoramento genético"
        type="checkbox"
        [(valueCheckbox)]="propriedade.metadados.habilita_manejos_genetica"
      ></vasta-input>

      <vasta-input
        label="Participante do programa Qualitas"
        type="checkbox"
        [(valueCheckbox)]="propriedade.metadados.participa_qualitas"
      ></vasta-input>

      <ng-template [ngIf]="propriedade.metadados.participa_qualitas">
        <p>&nbsp;</p>
        <vasta-input
          label="Qualitas - Campo Nome Origem/Fornecedor"
          type="text"
          [(value)]="propriedade.metadados.qualitas_nome_fornecedor"
        ></vasta-input>
        <vasta-input
          label="Qualitas - Campo Código do Criador"
          type="text"
          [(value)]="propriedade.metadados.qualitas_codigo_criador"
        ></vasta-input>
        <vasta-input
          label="Qualitas - Campo Nome do Proprietário"
          type="text"
          [(value)]="propriedade.metadados.qualitas_nome_proprietario"
        ></vasta-input>
        <vasta-input
          label="Qualitas - Campo Código do Proprietário"
          type="text"
          [(value)]="propriedade.metadados.qualitas_codigo_proprietario"
        ></vasta-input>
      </ng-template>

      <p>&nbsp;</p>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <cta-buttons>
    <button
      *ngIf="propriedadeProp"
      class="danger permissao-escrita-propriedade"
      (click)="alertConfirmDeletePropriedade()"
      [disabled]="loadingExcluir || loadingAdicionarOuAtualizar"
    >
      <ion-icon *ngIf="!loadingExcluir" slot="start" name="trash"></ion-icon>
      <ion-spinner *ngIf="loadingExcluir" name="dots"></ion-spinner>
      <span>Excluir propriedade</span>
    </button>
    <button
      class="primary permissao-escrita-propriedade"
      [disabled]="!verificarCamposObrigatorios() || loadingAdicionarOuAtualizar"
      (click)="submit()"
    >
      <ion-icon *ngIf="!loadingAdicionarOuAtualizar" slot="start" name="checkmark"></ion-icon>
      <ion-spinner *ngIf="loadingAdicionarOuAtualizar" name="dots"></ion-spinner>
      <span>{{ propriedadeProp ? 'Atualizar' : 'Cadastrar' }}</span>
    </button>
  </cta-buttons>
</ion-footer>
