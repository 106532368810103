import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { IonicModule } from '@ionic/angular'

import { MenuLateralPageRoutingModule } from './menu-lateral-routing.module'

import { MenuLateralPage } from './menu-lateral.page'
import { ComponentsModule } from 'src/app/components/components.module'
import { ModalConfiguracoesPage } from './modal-configuracoes/modal-configuracoes.page'
import { ModalListaPropriedadesPage } from './modal-lista-propriedades/modal-lista-propriedades.page'
import { ModalMenuPesquisaPage } from './modal-menu-pesquisa/modal-menu-pesquisa.page'
import { ModalNotificacoesPage } from './modal-notificacoes/modal-notificacoes.page'
import { ModalAssinaturaPage } from './modal-assinatura/modal-assinatura.page'
import { ModalAssinaturaCobrancaPage } from './modal-assinatura-cobranca/modal-assinatura-cobranca.page'

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, MenuLateralPageRoutingModule, ComponentsModule],
  declarations: [MenuLateralPage, ModalConfiguracoesPage, ModalListaPropriedadesPage, ModalMenuPesquisaPage, ModalNotificacoesPage, ModalAssinaturaPage, ModalAssinaturaCobrancaPage]
})
export class MenuLateralPageModule {}
