import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AlertController, ModalController, PopoverController } from '@ionic/angular'
import { ModalEstoqueSanitarioComponent } from 'src/app/pages/estoque/modal-estoque-sanitario/modal-estoque-sanitario.component'
import { EstoqueFarmacia } from 'src/app/utils/interfaces/estoque.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'
import { opcoesFiltros } from '../vasta-tabela/opcoes-filtros'

@Component({
  selector: 'app-manejo-sanitario-novo',
  templateUrl: './manejo-sanitario-novo.component.html',
  styleUrls: ['./manejo-sanitario-novo.component.scss'],
})
export class ManejoSanitarioNovoComponent implements OnInit {
  @Input('dados') dados: {
    [key: string]: { quantidade: number; edited: boolean; observacao?: string }
  } = {}
  @Output() dadosChange = new EventEmitter<unknown>()

  @Input('estoqueSanitario') estoqueSanitario: EstoqueFarmacia[] = []
  @Output() estoqueSanitarioChange = new EventEmitter<EstoqueFarmacia[]>()

  @Input('opcoesRapidasQuantidade') opcoesRapidasQuantidade = {}
  @Output() opcoesRapidasQuantidadeChange = new EventEmitter<unknown>()

  @Input('dataManejo') dataManejo: string = ''

  @Input('abaAtiva') abaAtiva: string = null

  public labelsTipo = opcoesFiltros.labelsEstoqueFarmaciaTipo

  constructor(private modalCtrl: ModalController, private alertCtrl: AlertController, private popoverCtrl: PopoverController) { }

  ngOnInit(): void {
  }

  async selecionarNovoItemDoEstoque (): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEstoqueSanitarioComponent,
      componentProps: {
        data_custo: this.dataManejo,
        hash_not_in: this.estoqueSanitario.map((a) => a.hash)
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {

      if (data.itensSelecionados?.length) {
        this.estoqueSanitario = [...this.estoqueSanitario, ...data.itensSelecionados]
      } else {
        this.estoqueSanitario.push(data)
      }
      this.estoqueSanitarioChange.emit(this.estoqueSanitario)
    }
  }

  async showPopoverOpcaoItemManejoSanitario(
    event: Event,
    hashEstoque?: string
  ): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event',
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      if (hashEstoque) {
        this.dados[hashEstoque].quantidade = null
        this.dados[hashEstoque].observacao = null
        this.dados[hashEstoque].edited = true
      } else {
        Object.keys(this.dados).forEach((key) => {
          this.dados[key].quantidade = null
          this.dados[key].observacao = null
          this.dados[key].edited = true
        })
      }

      this.emit()
    }
  }

  opcoesRapidasTime = null
  emitChange(dado: { quantidade: number; edited: boolean }, estoque: EstoqueFarmacia): void {
    dado.edited = true
    clearTimeout(this.opcoesRapidasTime)
    this.opcoesRapidasTime = setTimeout(() => {
      if (dado.quantidade && !this.opcoesRapidasQuantidade[this.abaAtiva + estoque.hash]?.includes(dado.quantidade)) {
        if (this.opcoesRapidasQuantidade[this.abaAtiva + estoque.hash]) {
          this.opcoesRapidasQuantidade[this.abaAtiva + estoque.hash].unshift(dado.quantidade)
        } else {
          this.opcoesRapidasQuantidade[this.abaAtiva + estoque.hash] = [dado.quantidade]
        }
        this.opcoesRapidasQuantidade[this.abaAtiva + estoque.hash] = [...new Set(this.opcoesRapidasQuantidade[this.abaAtiva + estoque.hash])]
        this.opcoesRapidasQuantidade[this.abaAtiva + estoque.hash] = this.opcoesRapidasQuantidade[this.abaAtiva + estoque.hash].slice(0, 3)
        console.log('this.opcoesRapidasQuantidade: ', this.opcoesRapidasQuantidade)
      }
    }, 500)

    this.emit()
  }

  emit(): void {
    this.dadosChange.emit(this.dados)
  }

  async showAlertAdicionarObservacao(hashEstoque: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Adicionar observação',
      inputs: [
        {
          name: 'observacao',
          type: 'text',
          placeholder: 'Observação',
          value: this.dados[hashEstoque].observacao || ''
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Adicionar',
          handler: (data): void => {
            if (data.observacao) {
              this.dados[hashEstoque].edited = true
              this.dados[hashEstoque]['observacao'] = data.observacao
              this.emit()
            }
          }
        }
      ]
    })

    await alert.present()
  }

  selecionarOpcaoRapidaQuantidade(estoque: EstoqueFarmacia, opcao: number): void {
    this.dados[estoque.hash].quantidade = opcao
    this.dados[estoque.hash].edited = true
    this.emit()
  }
}
