<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{novoEventoCalendario.hash ? 'Atualizar Evento' : 'Cadastrar Evento'}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col size="6" style="padding-left: 0">
      <vasta-input label="Data do Evento" [obrigatorio]="true" type="date" [(value)]="novoEventoCalendario.data"></vasta-input>
    </ion-col>

    <ion-col size="6" style="padding-right: 0">
      <vasta-input label="Horário" type="time" [(value)]="novoEventoCalendario.hora"></vasta-input>
    </ion-col>
  </ion-row>

  <vasta-input
    label="Categoria"
    type="select"
    [opcoes]="listaCategorias"
    [(value)]="novoEventoCalendario.tipo"
  ></vasta-input>
  <vasta-input label="Título" [obrigatorio]="true" type="text" [(value)]="novoEventoCalendario.titulo"></vasta-input>
  <vasta-input label="Descrição" type="text" [(value)]="novoEventoCalendario.observacao"></vasta-input>

  <cta-buttons>
    <button (click)="selecionarAnimal()">
      <ion-icon slot="start" name="add-outline"></ion-icon>
      <span>Selecionar Animais</span>
    </button>
  </cta-buttons>

  <ng-container>
    <vasta-tabela
      *ngIf="animaisSelecionados?.length"
      [status]="carregamentoTabelaAnimais"
      labelsId="colunas_animais"
      [campos]="animaisSelecionados"
      [config]="configTabelaAnimais"
      [optionsButtons]="optionsButtonsItens"
    ></vasta-tabela>
  </ng-container>
</ion-content>

<ion-footer>
  <cta-buttons>
    <button (click)="novoManejo()" *ngIf="animaisSelecionados.length && novoEventoCalendario.hash">
      <ion-icon name="add-circle"></ion-icon>
      <span>Novo Manejo</span>
    </button>
    <button
      class="primary permissao-escrita-calendario"
      (click)="cadastrarEvento()"
      [disabled]="isCadastrandoEvento || !isFormularioValido"
    >
      <ion-icon slot="start" name="checkmark" *ngIf="!isCadastrandoEvento"></ion-icon>
      <ion-spinner name="dots" *ngIf="isCadastrandoEvento"></ion-spinner>
      <span>Salvar</span>
    </button>
  </cta-buttons>
</ion-footer>
