import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { ModalListaAreasPage } from 'src/app/pages/areas/modal-lista-areas/modal-lista-areas.page'
import { ModalListaAnimaisPage } from 'src/app/pages/bovinos/modal-lista-animais/modal-lista-animais.page'
import { ModalListaLotesPage } from 'src/app/pages/bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { ModalEstoqueBiologicoComponent } from 'src/app/pages/estoque/modal-estoque-biologico/modal-estoque-biologico.component'
import { ListaManejosProp } from 'src/app/pages/manejos/manejos-disponiveis/manejos-disponiveis.component'
import { LotesService } from 'src/app/services/lotes.service'
import {
  IPopoverCampos,
  IPopoverCamposCampos,
  IPopoverCamposGroup,
  PopoverCampoManejoSanitario,
  PopoverCampos
} from 'src/app/services/manejos.service'
import { EstoqueFarmacia, EstoqueNutricional } from 'src/app/utils/interfaces/estoque.interface'
import { IPropriedade } from 'src/app/utils/interfaces/propriedades.interface'
import { CalendarioComponent } from '../calendario/calendario.component'
import { NovoFiltro } from '../vasta-tabela/vasta-tabela'
import { ModalListaPessoasPage } from 'src/app/pages/financas/modal-lista-pessoas/modal-lista-pessoas.page'

@Component({
  selector: 'app-popover-opcoes-manejos-selecionados',
  templateUrl: './popover-opcoes-manejos-selecionados.component.html',
  styleUrls: ['./popover-opcoes-manejos-selecionados.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopoverOpcoesManejosSelecionadosComponent implements OnInit {
  public popoverCampos: IPopoverCamposGroup = JSON.parse(JSON.stringify(PopoverCampos))

  public manejosDisponiveis: ListaManejosProp[] = []

  public animaisAssociados: {} = {}
  public animaisExternosAssociados: {} = {}
  public estoquesAssociados: {} = {}
  public pessoasAssociadas: {} = {}
  public lotesAssociados: {} = {}
  public areasAssociadas: {} = {}
  public propriedadesProp: IPropriedade[]
  public propriedadesOpcoes: { value: number; label: string }[]
  public lotesOpcoes: { value: string; label: string }[] = []
  public procedimentoSelecionadoProp: string = null
  public estoqueNutricional: EstoqueNutricional[] = []
  public estoqueNutricionalHashs: string[] = []
  public estoqueSanitario: EstoqueFarmacia[] = []
  public estoqueSanitarioHashs: string[] = []
  public hasSelecionados: boolean = false

  public campoManejoSanitario: {
    campos?: IPopoverCamposCampos[]
    opcoes?: { label: string; value: string }[]
    valorChave?: string
  } = JSON.parse(JSON.stringify(PopoverCampoManejoSanitario))

  public manejoSelecionado: ListaManejosProp

  public camposDoManejoSelecionado: IPopoverCampos = null

  public acaoSelecionada: 'preencherTodos' | 'preencherDescricao'

  public opcoesParaTodosManejos = false

  public abaAtiva: 'Animais' | 'Lotes' | 'Áreas' = null

  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private lotesCtrl: LotesService
  ) { }

  ngOnInit(): void {
    const hasManejoNutricional = this.manejosDisponiveis.some((item) => item.identificador === 'nutricao')
    const hasManejoSanitarioNovo = this.manejosDisponiveis.some((item) => item.identificador === 'sanitarioEstoque')

    if (hasManejoNutricional) {
      this.inserirCamposManejoNutricional()
    }

    if (hasManejoSanitarioNovo) {
      this.inserirCamposManejoSanitarioEstoque()
    }

    this.estoqueNutricionalHashs = this.estoqueNutricional.map((item) => item.hash)
    this.estoqueSanitarioHashs = this.estoqueSanitario.map((item) => item.hash)

    if (this.procedimentoSelecionadoProp) {
      const manejo = this.manejosDisponiveis.find(
        (m) =>
          m.identificador === this.procedimentoSelecionadoProp || m.procedimento === this.procedimentoSelecionadoProp
      )
      this.manejoSelecionado = manejo
    }

    this.propriedadesOpcoes = this.propriedadesProp.map((p) => ({
      value: p.id,
      label: p.nome
    }))
  }

  inserirCamposManejoNutricional(): void {
    const itemCampos = {}
    this.estoqueNutricional.forEach((manejo) => {
      itemCampos[manejo.hash] = {
        titulo: manejo.titulo,
        campos: [
          {
            label: 'Quantidade',
            value: '',
            chave: 'quantidade',
            tipoCampo: 'manejo-input'
          },
          {
            label: 'Duração',
            value: '',
            chave: 'duracao',
            tipoCampo: 'manejo-input'
          }
        ] as IPopoverCamposCampos[]
      }
    })
    this.popoverCampos['nutricao'].camposManejoNutricional = itemCampos
  }
  inserirCamposManejoSanitarioEstoque(): void {
    const itemCampos = {}
    this.estoqueSanitario.forEach((manejo) => {
      itemCampos[manejo.hash] = {
        titulo: manejo.titulo,
        campos: [
          {
            label: 'Quantidade',
            value: '',
            chave: 'quantidade',
            tipoCampo: 'manejo-input',
            unidade: manejo.meta_unidade
          },
        ] as IPopoverCamposCampos[]
      }
    })
    this.popoverCampos['sanitarioEstoque'].camposManejoSanitarioNovo = itemCampos
  }

  limparTodos(manejo: ListaManejosProp = null): void {
    if (!manejo) {
      this.popoverCtrl.dismiss({
        acao: 'limparTodos',
        selecionados: this.hasSelecionados,
      })
      return
    }

    const { procedimento, tipo, identificador } = manejo

    if (tipo === 'sanitario') {
      this.popoverCtrl.dismiss({
        acao: 'sanitario',
        selecionados: this.hasSelecionados,
        identificador,
        procedimento,
        tipo,
        campos: [
          {
            chave: 'aplicado',
            value: this.campoManejoSanitario.campos[0].value === 'aplicado' ? true : false
          }
        ]
      })
    } else if (tipo === 'nutricao') {
      this.popoverCtrl.dismiss({
        acao: 'limparTodos',
        selecionados: this.hasSelecionados,
        tipo,
        procedimento,
        camposManejoNutricao: this.popoverCampos['nutricao'].camposManejoNutricional
      })
    } else if (tipo === 'sanitarioEstoque') {
      this.popoverCtrl.dismiss({
        acao: 'limparTodos',
        selecionados: this.hasSelecionados,
        tipo,
        procedimento,
        camposManejoSanitarioNovo: this.popoverCampos['sanitarioEstoque'].camposManejoSanitarioNovo
      })
    }
    else {
      this.popoverCtrl.dismiss({
        acao: 'limparTodos',
        selecionados: this.hasSelecionados,
        tipo,
        procedimento,
        campos: this.popoverCampos[procedimento].campos
      })
    }
  }

  ativaPreencherTodos(manejo: ListaManejosProp): void {
    const { procedimento, tipo } = manejo
    this.acaoSelecionada = 'preencherTodos'
    this.camposDoManejoSelecionado = tipo == 'sanitario' ? null : this.popoverCampos[procedimento]
  }

  async alterarData(event: Event, manejo: ListaManejosProp = null): Promise<void> {

    const popoverOverlay1 = await this.popoverCtrl.getTop()
    const popover = await this.popoverCtrl.create({
      component: CalendarioComponent,
      event,
      reference: 'trigger',
      cssClass: 'popover-calendario'
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()
    if (data) {
      const { iso } = data

      if (manejo) {
        const { procedimento, tipo } = manejo

        await popoverOverlay1.dismiss({
          acao: 'alterarData',
          tipo,
          procedimento,
          date: iso
        })
      } else {
        await popoverOverlay1.dismiss({
          acao: 'alterarData',
          date: iso,
        })
      }
    }
    // this.camposDoManejoSelecionado = tipo == 'sanitario' ? null : this.popoverCampos[procedimento]
  }

  confirmaAlteracaoDescricao(tipo: string, procedimento: string, identificador?: string): void {
    this.manejosDisponiveis.map((manejo) => {
      if (manejo.procedimento === procedimento) {
        manejo.descricao = this.campoManejoSanitario.campos[1].value
      }
    })

    this.popoverCtrl.dismiss({
      acao: 'sanitario',
      identificador,
      procedimento,
      tipo,
      campos: [this.campoManejoSanitario.campos[1]],
      manejosDisponiveisChange: this.manejosDisponiveis
    })
  }

  confirmar(acao: string, tipo: string, procedimento: string, identificador?: string): void {
    if (tipo === 'sanitario') {
      this.popoverCtrl.dismiss({
        acao: 'sanitario',
        selecionados: this.hasSelecionados,
        identificador,
        procedimento,
        tipo,
        campos: [
          {
            chave: 'aplicado',
            value: this.campoManejoSanitario.campos[0].value === 'aplicado' ? true : false
          }
        ],
        manejosDisponiveisChange: this.manejosDisponiveis
      })
    } else if (tipo === 'nutricao') {
      this.popoverCtrl.dismiss({
        acao,
        selecionados: this.hasSelecionados,
        tipo,
        procedimento,
        camposManejoNutricao: this.popoverCampos['nutricao'].camposManejoNutricional
      })
    } else if (tipo === 'sanitarioEstoque') {
      this.popoverCtrl.dismiss({
        acao,
        selecionados: this.hasSelecionados,
        tipo,
        procedimento,
        camposManejoSanitarioNovo: this.popoverCampos['sanitarioEstoque'].camposManejoSanitarioNovo
      })
    }
    else {
      this.popoverCtrl.dismiss({
        acao,
        selecionados: this.hasSelecionados,
        tipo,
        procedimento,
        campos: this.popoverCampos[procedimento].campos,
        estoquesAssociadosChange: this.estoquesAssociados,
        pessoasAssociadasChange: this.pessoasAssociadas,
        animaisAssociadosChange: this.animaisAssociados,
        animaisExternosAssociadosChange: this.animaisExternosAssociados,
        areasAssociadasChange: this.areasAssociadas,
        lotesAssociadosChange: this.lotesAssociados,
        lotesOpcoesChange: this.lotesOpcoes
      })
    }
  }

  async acaoClick(
    manejoSelecionado: ListaManejosProp,
    campo: { label: string; value: string; chave: string; chaveExtra?: string; tipoCampo: string }
  ): Promise<void> {
    if (manejoSelecionado.procedimento === 'inseminacao') {
      if( campo.chave === 'inseminacao_inseminador'){
        const modal = await this.modalCtrl.create({
          component: ModalListaPessoasPage,
          componentProps: {
            filtroPessoas: {
              categoria_pai: 'inseminador'
            }
          },
          backdropDismiss: false,
          cssClass: 'custom-modal-seletor'
        })
    
        modal.present()
    
        const { data } = await modal.onDidDismiss()
    
        if (data.pessoaSelecionada) {
          this.pessoasAssociadas[data.pessoaSelecionada.hash] = data.pessoaSelecionada
          campo.value = data.pessoaSelecionada.hash
        }

      } else {
        const modal = await this.modalCtrl.create({
          component: ModalEstoqueBiologicoComponent,
          cssClass: 'custom-modal-animais'
        })
  
        await modal.present()
        const { data } = await modal.onWillDismiss()
        if (data) {
          if (data.tipoDoEstoque.includes('embriao')) {
            campo['extra'] = true
          } else {
            campo['extra'] = false
          }
          this.estoquesAssociados[data.hash] = data
          campo.value = data.hash
        }
      }
    } else {
      if (['movimentacao-lote', 'movimentacao-confinamento'].includes(manejoSelecionado.identificador)) {
        const modal = await this.modalCtrl.create({
          component: ModalListaLotesPage,
          componentProps: {
            tabela: true,
            confinamento: manejoSelecionado.identificador === 'movimentacao-confinamento' ? true : false
          },
          cssClass: 'custom-modal-lotes'
        })

        await modal.present()
        const { data } = await modal.onWillDismiss()
        if (data?.lotes) {
          const [lote] = data.lotes

          this.lotesAssociados[lote.hash] = lote
          campo.value = lote.hash
        }
      } else if (manejoSelecionado.identificador === 'movimentacao-entre-areas') {
        const modal = await this.modalCtrl.create({
          component: ModalListaAreasPage,
          cssClass: 'custom-modal-animais'
        })

        await modal.present()
        const { data } = await modal.onDidDismiss()
        if (data) {
          const [area] = data.areas
          this.areasAssociadas[area.hash] = area
          campo.value = area.hash
        }
      } else {
        const filtroSexo: NovoFiltro = {
          sexo: 'macho'
        }

        const modal = await this.modalCtrl.create({
          component: ModalListaAnimaisPage,
          componentProps: {
            filtrosFixos: manejoSelecionado.procedimento === 'monta-natural' ? ['sexo'] : [],
            modalFiltro: manejoSelecionado.procedimento === 'monta-natural' ? filtroSexo : null,
            modalFiltroReprodutorExterno: manejoSelecionado.procedimento === 'monta-natural' ? filtroSexo : null
          },
          cssClass: 'custom-modal-animais'
        })

        await modal.present()
        const { data } = await modal.onDidDismiss()
        if (data) {
          if (data?.externo && manejoSelecionado.procedimento === 'monta-natural') {
            campo['extra'] = true
            this.animaisExternosAssociados[data.hash] = data
          } else {
            this.animaisAssociados[data.hash] = data
          }
          campo.value = data.hash
        }
      }
    }
  }

  voltar(): void {
    if (this.acaoSelecionada) {
      this.acaoSelecionada = null
      return
    }

    if (this.manejoSelecionado) {
      this.manejoSelecionado = null
      return
    }
  }

  async carregarLotesPropriedade(idPropriedade: string): Promise<void> {
    if (!this.lotesOpcoes[idPropriedade]) {
      const [response, error] = await this.lotesCtrl.getLotes(null, Number(idPropriedade))
      if (response) {
        this.lotesOpcoes[idPropriedade] = response.dados.map((l) => ({
          value: l.hash,
          label: l.nome
        }))
      }

      if (error) {
        console.error(error)
      }
    }
  }

  async acaoAgendarEvento(): Promise<void> {
    await this.popoverCtrl.dismiss({
      acao: 'agendar-evento',
    })
  }
}
