import { IConta, IFinancasTransacao } from './interfaces/financas.interface'
import { ColunasTabela } from './interfaces/tabela-colunas'

export const ColunasAnimais: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    fixa: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Lote',
    chave: 'extra_lote',
    placeholder: 'Sem lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8,
    classesExtra: 'mobile-width-auto mobile-width-max-half mobile-line-break'
  },
  {
    titulo: 'Peso',
    chave: 'pesagem_peso',
    unidade: 'Kg',
    format: 'peso',
    placeholder: 'Não pesado',
    tipoFiltro: 'rangeNumeros',
    tamanho: 7,
    classesExtra: 'mobile-line-break mobile-width-auto'
  },
  {
    titulo: 'Peso @',
    chave: 'pesagem_arroba',
    unidade: '@',
    placeholder: '',
    tipoFiltro: 'rangeNumeros',
    tamanho: 6,
    format: 'peso',
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Pesagem',
    format: 'data',
    chave: 'pesagem_data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7,
    classesExtra: 'mobile-font-smaller mobile-width-auto'
  },
  {
    titulo: 'GMD',
    chave: 'pesagem_gmd',
    unidade: 'kg',
    placeholder: '0',
    assinaturaPremium: true,
    tipoFiltro: 'rangeNumeros',
    tamanho: 5,
    format: 'gmd',
    classesExtra: 'mobile-hide-empty mobile-before-gmd-texto mobile-width-auto'
  },
  {
    titulo: 'Partos',
    chave: 'extra_partos_total',
    // format: "categoria",
    tipoFiltro: 'preenchidosMultiplos',
    labelFormat: 'labelsParto',
    tamanho: 5,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'No lote desde',
    format: 'data',
    chave: 'data_manejo_lote',
    small: 'data_manejo_lote_dias_atras',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    classesExtra: 'mobile-before-lote-desde mobile-font-smaller'
  },
  {
    titulo: 'Categoria',
    chave: 'extra_categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 7,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Raça',
    chave: 'extra_composicao_racial',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 12
  },
  {
    titulo: 'Nascimento',
    format: 'data',
    chave: 'data_nascimento',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Situação',
    labelFormat: 'labelsSituacao',
    chave: 'situacao',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 6
  },
  {
    titulo: 'S. Reprodutiva',
    labelFormat: 'labelsSituacaoReprodutiva',
    chave: 'situacao_reprodutiva',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: '',
    tamanho: 8
  },
  {
    titulo: 'Data S. Reprodutiva',
    format: 'data',
    chave: 'meta_ultimo_manejo_reprodutivo',
    tipoFiltro: 'rangeDatas',
    tamanho: 11
  },
  {
    titulo: 'S. Sanitária',
    labelFormat: 'labelsSituacaoSanitaria',
    chave: 'situacao_sanitaria',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: '',
    tamanho: 8
  },
  {
    titulo: 'Aptidão',
    labelFormat: 'labelsAptidao',
    chave: 'aptidao',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: '',
    tamanho: 8
  },
  {
    titulo: 'Cria ao pé',
    chave: 'meta_cria_ao_pe',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: 'Não',
    tamanho: 9,
    labelFormat: 'labelsCriaAoPe'
  },
  {
    chave: 'meta_cria_ao_pe_sexo',
    titulo: 'Sexo da cria ao pé',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only',
    exibida: false
  },
  {
    titulo: 'Nº Cria ao pé',
    chave: 'meta_cria_ao_pe_numero',
    tipoFiltro: 'texto',
    placeholder: 'Não',
    tamanho: 7,
    labelFormat: 'labelsCriaAoPe',
    exibida: false
  },
  {
    titulo: 'Observações',
    chave: 'observacao',
    tipoFiltro: 'texto',
    tamanho: 8
  },
  {
    titulo: 'SISBOV',
    chave: 'meta_n_sisbov',
    tipoFiltro: 'texto',
    tamanho: 8
  },
  {
    titulo: 'Produtor de Origem',
    chave: 'extra_produtor_de_origem',
    tipoFiltro: 'texto',
    tamanho: 11,
    bloquearOrdenacao: true
  },
  {
    titulo: 'Área',
    chave: 'extra_area',
    tipoFiltro: 'preenchidos',
    tamanho: 10
  },
  {
    titulo: 'RGN',
    chave: 'meta_registroDeNascimento',
    placeholder: 'Sem RGN',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto',
    exibida: false
  },
  {
    titulo: 'Data RGN',
    format: 'data',
    chave: 'meta_dataRegistroDeNascimento',
    tipoFiltro: 'rangeDatas',
    tamanho: 10,
    exibida: false
  },
  {
    titulo: 'RGD',
    chave: 'meta_registroDefinitivo',
    placeholder: 'Sem RGD',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto',
    exibida: false
  },
  {
    titulo: 'Data RGD',
    format: 'data',
    chave: 'meta_dataRegistroDefinitivo',
    tipoFiltro: 'rangeDatas',
    tamanho: 10,
    exibida: false
  },
  {
    titulo: 'Pelagem',
    chave: 'meta_pelagem',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsPelagem',
    tamanho: 10
  },
  {
    titulo: 'Número Pai',
    chave: 'genealogia_numero_pai',
    tipoFiltro: 'texto',
    tamanho: 10,
    exibida: false
  },
  {
    titulo: 'Nome Pai',
    chave: 'genealogia_nome_pai',
    tipoFiltro: 'texto',
    tamanho: 10,
    exibida: false
  },
  {
    titulo: 'Número Mãe',
    chave: 'genealogia_numero_mae',
    tipoFiltro: 'texto',
    tamanho: 10,
    exibida: false
  },
  {
    titulo: 'Nome Mãe',
    chave: 'genealogia_nome_mae',
    tipoFiltro: 'texto',
    tamanho: 10,
    exibida: false
  },
  {
    titulo: 'Previsão de Parto',
    chave: 'meta_previsao_de_parto',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 10,
    exibida: false
  },
  {
    titulo: 'Dias em Lactação',
    chave: 'extra_dias_em_lactacao',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10,
    unidade: 'dias'
  },
  {
    titulo: 'Data de Cadastro',
    format: 'data',
    chave: 'created_at',
    tipoFiltro: 'rangeDatas',
    tamanho: 10
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasCustoDoAnimal: ColunasTabela[] = [
  {
    titulo: 'Data',
    format: 'data',
    chave: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7,
  },
  {
    titulo: 'Manejo',
    chave: 'manejo',
    labelFormat: 'tipoDeManejoCustoAnimal',
    tamanho: 10,
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    labelFormat: 'tipoDeManejoNutricional',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: '',
  },
  {
    titulo: 'Qtd',
    chave: 'qtd_consumido',
    // unidade: 'Kg',
    small: 'unidade',
    format: 'peso',
    placeholder: '',
    tipoFiltro: 'rangeNumeros',
    tamanho: 7,
  },
  // {
  //   titulo: 'Unidade',
  //   chave: 'unidade',
  //   // format: 'peso',
  //   // placeholder: 'Não pesado',
  //   // tipoFiltro: 'rangeNumeros',
  //   tamanho: 7,
  // },
  {
    titulo: 'Item',
    chave: 'item',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: '',
  },
  // {
  //   titulo: 'Duração',
  //   chave: 'duracao',
  //   tamanho: 7,
  //   unidade: 'dias',
  //   tipoFiltro: 'texto',
  //   placeholder: '',
  // },
  {
    titulo: 'Valor Estoque',
    chave: 'valor_kg',
    format: 'dinheiro',
    placeholder: '-',
    tamanho: 8,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Custo Total',
    chave: 'custo_total',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tamanho: 10,
    tipoFiltro: 'rangeNumeros'
  },
]

export const ColunasAnimalSingleHistorico: ColunasTabela[] = [
  {
    titulo: 'Data',
    chave: 'data',
    placeholder: '-',
    tipoFiltro: 'texto',
    format: 'data',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Ação',
    chave: 'acao',
    tamanho: 12,
    tipoFiltro: 'texto',
    placeholder: '-',
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Dados',
    chave: 'dados',
    placeholder: '-',
    tipoFiltro: 'texto',
    tamanho: 18,
    classesExtra: 'mobile-width-auto'
  }
]
export const ColunasAnimaisExternos: ColunasTabela[] = [
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    fixa: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 10,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Número',
    chave: 'meta_numero',
    placeholder: 'Sem número',
    tipoFiltro: 'texto',
    tamanho: 10,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Raça',
    chave: 'extra_raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    bloquearOrdenacao: true,
    tamanho: 8
  },
  {
    titulo: 'Matriz (Mãe)',
    chave: 'matriz',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Reprodutor (Pai)',
    chave: 'reprodutor',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Pelagem',
    chave: 'meta_pelagem',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 10,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Registro Definitivo',
    chave: 'meta_registroDefinitivo',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 9,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Registro de Nascimento',
    chave: 'meta_registroDeNascimento',
    placeholder: 'Não informado',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Descrição',
    chave: 'descricao',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

interface IColunasContas extends ColunasTabela {
  chave?: keyof IConta | 'checkbox' | 'mais'
  small?: keyof IConta
  chaveFallback?: keyof IFinancasTransacao
}

export const ColunasContas: IColunasContas[] = [
  {
    titulo: 'Principal',
    chave: 'is_principal',
    tituloHidden: true,
    placeholder: '',
    tipo: 'icon-only',
    fixa: true,
    tamanho: 1,
    bloquearOrdenacao: true,
    iconeCase: {
      1: {
        class: 'star',
        color: '#f1c40f'
      },
    }
  },
  {
    titulo: 'Título',
    chave: 'titulo',
    tipoFiltro: 'texto',
    fixa: true,
    tamanho: 12
  },
  {
    titulo: 'Saldo',
    chave: 'saldo_total',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10
  },
  {
    titulo: 'Descrição',
    chave: 'descricao',
    placeholder: '',
    tipoFiltro: 'texto',
    tamanho: 10
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    placeholder: '',
    labelFormat: 'labelsTiposContas',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8
  },
  {
    titulo: 'Agência',
    chave: 'agencia',
    placeholder: '',
    tipoFiltro: 'texto',
    tamanho: 5
  },
  {
    titulo: 'Conta',
    chave: 'conta',
    placeholder: '',
    tipoFiltro: 'texto',
    tamanho: 7
  },
  {
    titulo: 'Propriedade',
    chave: 'extra_propriedade_nome',
    placeholder: '',
    tipoFiltro: 'preenchidosMultiplos',
    tamanhoMinimo: 8
  },
  // entradas_mes?: number
  // saidas_mes?: number
  // saldo_mes?: number
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

interface IColunasFinancas extends ColunasTabela {
  chave?: keyof IFinancasTransacao | 'checkbox' | 'mais'
  small?: keyof IFinancasTransacao
  chaveFallback?: keyof IFinancasTransacao
}

export const ColunasFinancas: IColunasFinancas[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Tipo',
    tituloHidden: true,
    chave: 'tipo',
    tipo: 'icon-only',
    labelFormat: 'labelsTiposFinancas',
    fixa: true,
    tipoFiltro: 'preenchidosMultiplos',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      },
      transferencia: {
        class: 'arrow-redo',
        color: '#8e44ad'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Pago',
    chave: 'realizado',
    tamanho: 4,
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsRealizado',
    tipo: 'toggle',
    evento: 'realizar',
    pointer: true
  },
  {
    titulo: 'Valor',
    chave: 'valor',
    format: 'dinheiro',
    tamanho: 10,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Título',
    chave: 'titulo',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Categoria',
    chave: 'extra_categorias_chave',
    tipoFiltro: 'preenchidos',
    placeholder: 'Sem categoria',
    labelFormat: 'labelsCategoriasFinancas',
    tamanho: 15,
    bloquearOrdenacao: true
  },
  {
    titulo: 'Subcategoria',
    chave: 'extra_subcategoria',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Data de vencimento',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 11
  },
  {
    titulo: 'Data de pagamento',
    chave: 'meta_data_pagamento',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    placeholder: 'Não selecionado',
    tamanho: 10
  },
  {
    titulo: 'Conta',
    chave: 'extra_conta',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 10
  },
  {
    titulo: 'Propriedade',
    chave: 'extra_propriedade_nome',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 10
  },
  {
    titulo: 'Pessoa/Empresa',
    chave: 'extra_pessoas_label',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: 'Não selecionado',
    tamanho: 10
  },
  {
    titulo: 'Conta Origem',
    chave: 'extra_conta_origem',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: 'Não é transferência',
    tamanho: 12
  },
  {
    chave: 'extra_centro_de_custo_labels',
    titulo: 'Centro de Custo',
    tamanho: 12,
    bloquearOrdenacao: true,
    tipoFiltro: 'preenchidosMultiplos'
  },
  {
    titulo: 'Forma de Pag.',
    chave: 'meta_forma_pagamento',
    labelFormat: 'labelsFormasDePagamentoFinancas',
    placeholder: 'Não informada',
    tamanho: 10,
    tipoFiltro: 'preenchidosMultiplos'
  },
  {
    titulo: 'Nota Fiscal',
    chave: 'meta_nf',
    tipoFiltro: 'texto',
    placeholder: 'NF não informada',
    tamanho: 10,
  },
  {
    titulo: 'Descrição',
    chave: 'descricao',
    tipoFiltro: 'texto',
    placeholder: 'Sem descrição',
    bloquearOrdenacao: true,
    tamanho: 15,
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasFinancasVendaLeite: IColunasFinancas[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Tipo',
    tituloHidden: true,
    chave: 'tipo',
    tipo: 'icon-only',
    labelFormat: 'labelsTiposFinancas',
    fixa: true,
    tipoFiltro: 'preenchidosMultiplos',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      },
      transferencia: {
        class: 'arrow-redo',
        color: '#8e44ad'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Recebido',
    chave: 'realizado',
    tamanho: 6,
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsRealizado',
    tipo: 'toggle',
    evento: 'realizar',
    pointer: true
  },
  {
    titulo: 'Valor',
    chave: 'valor',
    format: 'dinheiro',
    tamanho: 10,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Quantidade',
    chave: 'meta_litros_de_leite',
    format: 'peso',
    placeholder: '0 Kg',
    unidade: 'Kg',
    tamanho: 10,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Título',
    chave: 'titulo',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Data de vencimento',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 11
  },
  {
    titulo: 'Data de recebimento',
    chave: 'meta_data_pagamento',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    placeholder: 'Não selecionado',
    tamanho: 12
  },
  {
    titulo: 'Conta',
    chave: 'extra_conta',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 10
  },
  {
    titulo: 'Propriedade',
    chave: 'extra_propriedade_nome',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 10
  },
  {
    titulo: 'Pessoa/Empresa',
    chave: 'extra_pessoas_label',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: 'Não selecionado',
    tamanho: 10
  },
  {
    titulo: 'Conta Origem',
    chave: 'extra_conta_origem',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: 'Não é transferência',
    tamanho: 12
  },
  {
    chave: 'extra_centro_de_custo_labels',
    titulo: 'Centro de Custo',
    tamanho: 12,
    tipoFiltro: 'preenchidosMultiplos'
  },
  {
    titulo: 'Forma de Pag.',
    chave: 'meta_forma_pagamento',
    labelFormat: 'labelsFormasDePagamentoFinancas',
    placeholder: 'Não informada',
    tamanho: 10,
    tipoFiltro: 'preenchidosMultiplos'
  },
  {
    titulo: 'Nota Fiscal',
    chave: 'meta_nf',
    tipoFiltro: 'texto',
    placeholder: 'NF não informada',
    tamanho: 10,
  },
  {
    titulo: 'Descrição',
    chave: 'descricao',
    tipoFiltro: 'texto',
    placeholder: 'Sem descrição',
    tamanho: 15,
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasRelatorioFinanceiroAnalise: ColunasTabela[] = [
  {
    titulo: 'Valor',
    chave: 'quantidade',
    format: 'dinheiro',
    tamanho: 10,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Percentual',
    chave: 'percentualNumerico',
    format: 'gmd',
    unidade: '%',
    tamanho: 7,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Título',
    chave: 'label',
    tipoFiltro: 'texto',
    tamanho: 15
  }
]

export const ColunasFinancasCentrosDeCusto: ColunasTabela[] = [
  {
    chave: 'nome',
    titulo: 'Nome',
    tamanho: 10,
    tipoFiltro: 'texto'
  },
  {
    chave: 'descricao',
    titulo: 'Descrição',
    tamanho: 15,
    tipoFiltro: 'texto'
  },
  {
    chave: 'extra_propriedade_nome',
    titulo: 'Propriedade Origem',
    tamanho: 15,
    tipoFiltro: 'preenchidosMultiplos'
  },
  {
    chave: 'created_at',
    titulo: 'Data de Cadastro',
    tamanhoMinimo: 15,
    format: 'data'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasFinancasPessoas: ColunasTabela[] = [
  {
    chave: 'nome',
    titulo: 'Nome',
    tamanho: 12,
    tipoFiltro: 'texto'
  },
  {
    chave: 'categoria_pai',
    titulo: 'Categoria',
    placeholder: 'Não selecionada',
    labelFormat: 'labelsPessoa',
    tipoFiltro: 'texto',
    tamanho: 15,
    bloquearOrdenacao: true
  },
  {
    chave: 'extra_propriedade_nome',
    titulo: 'Propriedade Origem',
    tamanho: 15,
    tipoFiltro: 'preenchidosMultiplos'
  },
  {
    chave: 'descricao',
    titulo: 'Descrição',
    placeholder: 'Não Preenchido',
    tamanhoMinimo: 15,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasFinancasSubcategorias: ColunasTabela[] = [
  {
    chave: 'nome',
    titulo: 'Nome',
    tamanho: 12,
    tipoFiltro: 'texto'
  },
  {
    chave: 'descricao',
    titulo: 'Descrição',
    placeholder: 'Não Preenchido',
    tamanhoMinimo: 15,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasFinancasVendas: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Pago',
    chave: 'realizado',
    tamanho: 4,
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsRealizado',
    tipo: 'toggle',
    evento: 'realizar',
    pointer: true
  },
  {
    chave: 'data',
    titulo: 'Data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 8
  },
  {
    chave: 'extra_propriedade_nome',
    titulo: 'Propriedade',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8
  },
  {
    chave: 'extra_qtd_animais',
    titulo: 'Animais',
    tipoFiltro: 'rangeNumeros',
    tamanho: 6
  },
  {
    titulo: 'Arquivar Animais',
    chave: 'meta_arquivar_animais',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 10,
    labelFormat: 'labelsRemoverAnimaisDoRebanho',
    placeholder: 'Se pago',
    bloquearOrdenacao: true
  },
  {
    chave: 'meta_tipoSaida',
    titulo: 'Tipo',
    placeholder: 'Não Informado',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsTipoSaida',
    tamanho: 8
  },
  {
    chave: 'meta_valorArroba',
    titulo: 'Preço @',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'valor',
    titulo: 'Valor Total',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_valorKg',
    titulo: 'Preço Kg',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_pesoVivo',
    titulo: 'Peso Total',
    format: 'peso',
    unidade: 'Kg',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_valorUnidade',
    titulo: 'Preço Unidade',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_pesoMorto',
    titulo: 'Peso Morto',
    format: 'peso',
    unidade: 'kg',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_rendimentoCarcaca',
    titulo: 'Rend. Carcaça',
    placeholder: 'N/A',
    unidade: '%',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_nf',
    titulo: 'Referência ou NF',
    placeholder: 'Não informada',
    tipoFiltro: 'texto',
    tamanho: 10
  },
  {
    chave: 'extra_conta',
    titulo: 'Conta',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 6
  },
  {
    chave: 'extra_pessoa',
    titulo: 'Destino',
    placeholder: 'Não selecionado',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8
  },
  {
    chave: 'meta_abatimentos',
    titulo: 'Abatimentos',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_comissoes',
    titulo: 'Comissões',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_frete',
    titulo: 'Frete',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'extra_hash_lote_label',
    titulo: 'Lote',
    placeholder: 'Não selecionado',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasFinancasCompras: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Pago',
    chave: 'realizado',
    tamanho: 4,
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsRealizado',
    tipo: 'toggle',
    evento: 'realizar',
    pointer: true
  },
  {
    chave: 'data',
    titulo: 'Data',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 8
  },
  {
    chave: 'extra_propriedade_nome',
    titulo: 'Propriedade',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8
  },
  {
    chave: 'extra_qtd_animais',
    titulo: 'Animais',
    tipoFiltro: 'rangeNumeros',
    tamanho: 6
  },
  {
    chave: 'meta_valorArroba',
    titulo: 'Preço @',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'valor',
    titulo: 'Valor Total',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10
  },
  {
    chave: 'meta_valorKg',
    titulo: 'Preço Kg',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10
  },
  {
    chave: 'meta_pesoVivo',
    titulo: 'Peso Total',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    tamanho: 8
  },
  {
    chave: 'meta_valorUnidade',
    titulo: 'Preço Unidade',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_nf',
    titulo: 'Referência ou NF',
    placeholder: 'Não informada',
    tipoFiltro: 'texto',
    tamanho: 10
  },
  {
    chave: 'extra_conta',
    titulo: 'Conta',
    tamanho: 6,
    tipoFiltro: 'preenchidosMultiplos'
  },
  {
    chave: 'extra_pessoa',
    titulo: 'Origem',
    placeholder: 'Não selecionado',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8
  },
  {
    chave: 'extra_valorUnitario',
    titulo: 'Valor unitário',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_acrescimos',
    titulo: 'Acréscimos',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_comissoes',
    titulo: 'Comissões',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'meta_frete',
    titulo: 'Frete',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    tamanho: 8
  },
  {
    chave: 'extra_hash_lote_label',
    titulo: 'Lote',
    placeholder: 'Não selecionado',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 12
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasAnimaisManejo: ColunasTabela[] = [
  {
    tipo: 'checkbox'
  },
  {
    titulo: 'Ordem',
    chave: 'ordem',
    tamanho: 4,
    classesExtra: 'gray',
    tipoFiltro: 'texto'
  },
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    // fixa: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    // fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto bold'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Lote',
    chave: 'extra_lote',
    placeholder: 'Sem lote',
    tipoFiltro: 'preenchidos',
    tamanho: 8,
    classesExtra: 'mobile-width-auto mobile-width-max-half mobile-line-break'
  },
  {
    titulo: 'Raça',
    chave: 'extra_composicao_racial',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 10
  },
  {
    titulo: 'Nascimento',
    format: 'data',
    chave: 'data_nascimento',
    tipoFiltro: 'rangeDatas',
    tamanho: 9
  },
  {
    titulo: 'Situação',
    labelFormat: 'labelsSituacao',
    chave: 'situacao',
    tipoFiltro: 'preenchidos',
    tamanho: 5
  },
  {
    titulo: 'Observações',
    chave: 'observacao',
    tipoFiltro: 'texto',
    tamanhoMinimo: 7
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasLotesManejo: ColunasTabela[] = [
  {
    tipo: 'checkbox'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    small: 'label_confinamento',
    tamanho: 12,
    classesExtra: 'mobile-bold mobile-width-two-thirds'
  },
  {
    titulo: 'Animais Hoje',
    chave: 'qtd_animais',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 8
  },
  {
    titulo: 'Animais na Data',
    chave: 'qtd_animais_na_data',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 10
  },
  {
    titulo: 'Entrada na área',
    chave: 'data_entrada_area',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    placeholder: 'N/A',
    tamanho: 9,
    classesExtra: 'mobile-hide'
  },
  {
    titulo: 'Peso total',
    chave: 'total_peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 7,
    classesExtra: 'mobile-width-auto mobile-line-break'
  },
  {
    titulo: 'Peso médio',
    chave: 'peso_medio',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 7,
    classesExtra: 'mobile-width-auto mobile-line-break'
  },
  {
    titulo: 'Peso @',
    chave: 'peso_arroba',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    placeholder: '0',
    unidade: '@',
    tamanho: 6,
    classesExtra: 'mobile-width-auto mobile-hide-empty'
  },
  {
    titulo: 'GMD Médio',
    chave: 'gmd',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'gmd',
    unidade: 'kg',
    tamanho: 7,
    classesExtras: 'mobile-width-auto mobile-hide-empty'
  },
  {
    titulo: 'Área',
    chave: 'nome_area',
    tipoFiltro: 'preenchidos',
    placeholder: 'Sem área',
    tamanho: 10,
    classesExtra: 'mobile-before-pin mobile-width-auto'
  },
  {
    titulo: 'Finalidade',
    chave: 'finalidade',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsFinalidade',
    tamanho: 7,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Sistema de Criação',
    chave: 'sistema_criacao',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsSistemaCriacao',
    tamanho: 10,
    classesExtra: 'mobile-hide-empty'
  },
  {
    titulo: 'Aptidão',
    chave: 'aptidao',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsAptidao',
    tamanhoMinimo: 7,
    classesExtra: 'mobile-hide-empty'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasAreasManejo: ColunasTabela[] = [
  {
    tipo: 'checkbox'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Lotes incluídos',
    chave: 'lotes_nomes',
    placeholder: 'Nenhum lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8
  },
  {
    titulo: 'Tamanho',
    chave: 'tamanho',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Não preenchido',
    unidade: 'ha',
    tamanho: 7
  },
  {
    titulo: 'Animais',
    chave: 'qtd_animais',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 6
  },
  {
    titulo: 'Peso total',
    chave: 'total_peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 6
  },
  {
    titulo: 'Data de Cadastro',
    chave: 'created_at',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 10
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    tipoFiltro: 'texto',
    placeholder: 'N/A',
    tamanho: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasPesagens: ColunasTabela[] = [
  {
    titulo: 'Data',
    chave: 'data_pesagem',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    classesExtra: 'mobile-bold mobile-width-half'
  },
  {
    titulo: 'Peso total',
    chave: 'peso_total',
    placeholder: '0',
    unidade: 'Kg',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    small: 'peso_arroba',
    tamanho: 8,
    classesExtra: 'mobile-line-break mobile-width-half mobile-before-peso-texto'
  },
  {
    titulo: 'GMD',
    chave: 'gmd_medio',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    format: 'gmd',
    tamanho: 8,
    classesExtra: 'mobile-width-half mobile-before-gmd-texto mobile-hide-empty'
  },
  {
    titulo: 'Peso médio',
    chave: 'peso_medio',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'N/A',
    unidade: 'kg',
    format: 'peso',
    small: 'peso_medio_arroba',
    tamanho: 8,
    classesExtra: 'mobile-hide'
  },
  {
    titulo: 'Animais Pesados',
    chave: 'qtd_animais',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 8,
    classesExtra: 'mobile-hide'
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    tipoFiltro: 'texto',
    placeholder: '',
    tamanho: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasMovimentacoes: ColunasTabela[] = [
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9
  },
  {
    chave: 'icone',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      Animal: {
        class: 'bovino'
      },
      Área: {
        class: 'location',
        color: '#ca8d56'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Ação',
    chave: 'mensagem',
    tipoFiltro: 'preenchidos',
    tamanho: 15
  },
  {
    titulo: 'Item',
    chave: 'item',
    tipoFiltro: 'texto',
    tamanho: 18
  }
]
export const ColunasAreas: ColunasTabela[] = [
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    fixa: true,
    tamanho: 12,
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Lotes incluídos',
    chave: 'lotes_nomes',
    placeholder: 'Nenhum lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 15
  },
  {
    titulo: 'Tamanho',
    chave: 'tamanho',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Não preenchido',
    unidade: 'ha',
    tamanho: 7
  },

  {
    titulo: 'Animais',
    chave: 'qtd_animais',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 6
  },
  {
    titulo: 'UA',
    chave: 'ua',
    placeholder: '0',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    tamanho: 4
  },
  {
    titulo: 'Piquete Ativo',
    chave: 'extra_piquete_ativo',
    placeholder: '',
    tipoFiltro: 'texto',
    tamanho: 10
  },
  {
    titulo: 'Piquetes',
    chave: 'extra_piquetes_qtd',
    placeholder: '0',
    format: 'int',
    tipoFiltro: 'rangeNumeros',
    tamanho: 7
  },
  {
    titulo: '% de ocupação',
    chave: 'tx_ocupacao',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    placeholder: '0',
    assinaturaPremium: true,
    unidade: '%',
    tamanho: 8
  },
  {
    titulo: '% de lotação',
    chave: 'tx_lotacao',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    placeholder: '0',
    assinaturaPremium: true,
    unidade: 'UA/ha',
    tamanho: 7
  },
  {
    titulo: 'Peso total',
    chave: 'total_peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 6
  },
  {
    titulo: 'Lot. Máxima',
    chave: 'max_lotacao',
    tipoFiltro: 'rangeNumeros',
    unidade: 'ua',
    placeholder: '0',
    tamanho: 7
  },
  {
    titulo: 'Tipo de Área',
    tipoFiltro: 'preenchidosMultiplos',
    chave: 'meta_tipo_area',
    labelFormat: 'labelsTipoDeArea',
    placeholder: 'Não preenchido',
    tamanho: 15
  },
  {
    titulo: 'Tipo de Capim',
    tipoFiltro: 'texto',
    chave: 'meta_tipo_capim',
    placeholder: 'Não preenchido',
    tamanho: 15
  },
  {
    titulo: 'Altura de Entrada do Capim',
    chave: 'meta_altura_entrada_capim',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    unidade: 'cm',
    placeholder: 'Não preenchido',
    tamanho: 15
  },
  {
    titulo: 'Altura de Saída do Capim',
    chave: 'meta_altura_saida_capim',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    unidade: 'cm',
    placeholder: 'N/A',
    tamanho: 15
  },
  {
    titulo: 'Data de Cadastro',
    chave: 'created_at',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 10
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    tipoFiltro: 'texto',
    placeholder: 'Não preenchido',
    tamanho: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasImportacaoKML: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    fixa: true,
    tamanho: 12,
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Tamanho',
    chave: 'tamanho',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Não preenchido',
    unidade: 'ha',
    tamanho: 12
  }
]

export const ColunasAreasPiquetes: ColunasTabela[] = [
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    fixa: true,
    tamanho: 12,
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Tamanho',
    chave: 'tamanho',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Não preenchido',
    unidade: 'ha',
    tamanho: 7
  },
  {
    titulo: 'Lot. Máxima',
    chave: 'max_lotacao',
    tipoFiltro: 'rangeNumeros',
    unidade: 'ua',
    placeholder: '0',
    tamanho: 7
  },
  {
    titulo: 'Altura do Capim',
    chave: 'ultima_altura_pasto',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    unidade: 'cm',
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Data da Medida',
    chave: 'ultima_altura_pasto_data',
    tipoFiltro: 'rangeDatas',
    tamanho: 10,
    format: 'data'
  },
  {
    titulo: 'Dias de Pastejo',
    chave: 'dias_pastejo',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10,
    unidade: 'dias',
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Dias de Descanso',
    chave: 'dias_descanso',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10,
    unidade: 'dias',
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    tipoFiltro: 'texto',
    placeholder: 'N/A',
    tamanho: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasPastejosRotacionados: ColunasTabela[] = [
  {
    titulo: 'Área',
    chave: 'nome_area',
    tipoFiltro: 'preenchidos',
    placeholder: 'Sem área',
    tamanho: 10,
    classesExtra: 'mobile-before-pin mobile-width-auto'
  },
  {
    titulo: 'Data da Rotação',
    chave: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 10,
    format: 'data'
  },
  {
    titulo: 'Piquete Selecionado',
    chave: 'nome_piquete',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Dias de Pastejo',
    chave: 'dias_pastejo',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10,
    unidade: 'dias',
    classesExtra: 'mobile-bold'
  }
]

export const ColunasPropriedades: ColunasTabela[] = [
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    fixa: true,
    tamanho: 12,
    classesExtra: 'mobile-bold'
  },
  {
    titulo: 'Animais',
    chave: 'propriedade_animais',
    placeholder: 'Nenhum animal',
    tipoFiltro: 'texto',
    tamanho: 8
  },
  {
    titulo: 'Tipo de Criação',
    chave: 'criacao',
    tipoFiltro: 'texto',
    labelFormat: 'labelsFinalidade',
    placeholder: 'Sem criação',
    tamanho: 7
  },
  {
    titulo: 'Produção',
    chave: 'producao',
    tipoFiltro: 'texto',
    labelFormat: 'labelsAptidao',
    placeholder: 'Sem produção',
    tamanho: 7
  },
  {
    titulo: 'Proprietário',
    chave: 'proprietario',
    tipoFiltro: 'texto',
    placeholder: 'Não preenchido',
    tamanho: 7
  },
  {
    titulo: 'Cidade',
    chave: 'cidade',
    tipoFiltro: 'texto',
    placeholder: 'Sem ciadade',
    tamanho: 7
  },
  {
    titulo: 'Estado',
    chave: 'estado',
    tipoFiltro: 'texto',
    tamanhoMinimo: 7,
    placeholder: 'Sem estado'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasLotes: ColunasTabela[] = [
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    fixa: true,
    small: 'label_confinamento',
    tamanho: 12,
    classesExtra: 'mobile-bold mobile-width-two-thirds'
  },
  {
    titulo: 'Animais Hoje',
    chave: 'qtd_animais',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 8
  },
  {
    titulo: 'Animais na Data',
    chave: 'qtd_animais_na_data',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 10
  },
  {
    titulo: 'Entrada na área',
    chave: 'data_entrada_area',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    placeholder: 'N/A',
    tamanho: 9,
    classesExtra: 'mobile-hide'
  },
  {
    titulo: 'Peso total',
    chave: 'total_peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 7,
    classesExtra: 'mobile-width-auto mobile-line-break'
  },
  {
    titulo: 'Peso médio',
    chave: 'peso_medio',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 7,
    classesExtra: 'mobile-width-auto mobile-line-break'
  },
  {
    titulo: 'Peso @',
    chave: 'peso_arroba',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    placeholder: '0',
    unidade: '@',
    tamanho: 6,
    classesExtra: 'mobile-width-auto mobile-hide-empty'
  },
  {
    titulo: 'GMD Médio',
    chave: 'gmd',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    assinaturaPremium: true,
    format: 'gmd',
    unidade: 'kg',
    tamanho: 7,
    classesExtras: 'mobile-width-auto mobile-hide-empty'
  },
  {
    titulo: 'Área',
    chave: 'nome_area',
    tipoFiltro: 'preenchidos',
    placeholder: 'Sem área',
    tamanho: 10,
    classesExtra: 'mobile-before-pin mobile-width-auto'
  },
  {
    titulo: 'Finalidade',
    chave: 'finalidade',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsFinalidade',
    tamanho: 7,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Sistema de Criação',
    chave: 'sistema_criacao',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsSistemaCriacao',
    tamanho: 10,
    classesExtra: 'mobile-hide-empty'
  },
  {
    titulo: 'Aptidão',
    chave: 'aptidao',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsAptidao',
    tamanhoMinimo: 7,
    classesExtra: 'mobile-hide-empty'
  },
  {
    titulo: 'Situação',
    chave: 'meta_situacao',
    tipoFiltro: 'preenchidos',
    placeholder: 'Ativo',
    labelFormat: 'labelsSituacaoLote',
    tamanhoMinimo: 7,
    classesExtra: 'mobile-hide-empty'
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    placeholder: '',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const OrdenhasLista: ColunasTabela[] = [
  {
    titulo: 'Dia',
    chave: 'dia',
    tipoFiltro: 'rangeNumeros',
    tamanho: 7,
    classesExtra: 'mobile-bold mobile-width-fourth'
  },
  {
    titulo: 'Vacas',
    chave: 'ordenhas',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    tamanho: 7,
    classesExtra: 'mobile-before-animais mobile-width-fourth'
  },
  {
    titulo: 'Total',
    chave: 'total',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 9,
    classesExtra: 'mobile-before-leite mobile-width-fourth'
  },
  {
    // titulo: "Diferença",
    chave: 'diffDirecao',
    tipo: 'icon-only',
    centralizar: true,
    tipoFiltro: 'preenchidos',
    tamanho: 1,
    iconeCase: {
      positivo: {
        class: 'arrow-up'
      },
      negativo: {
        class: 'arrow-down',
        color: '#d32f2f'
      }
    }
  },
  {
    titulo: 'Diferença',
    chave: 'diff',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    placeholder: '-',
    unidade: 'kg',
    tamanho: 9,
    classesExtra: 'mobile-width-auto'
  }
]
export const ColunasManejoReprodutivo: ColunasTabela[] = [
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    classesExtra: 'mobile-bold mobile-width-one-third'
  },
  {
    titulo: 'Manejo',
    chave: 'id_manejos',
    labelFormat: 'labelsManejosReprodutivos',
    tipoFiltro: 'preenchidos',
    placeholder: '',
    tamanho: 9,
    classesExtra: 'mobile-width-auto mobile-width-two-thirds mobile-manejos-titulos'
  },
  {
    chave: 'sexo_animal',
    tipo: 'icon-only',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Animal ou lote',
    chave: 'nome_lote',
    chaveFallback: 'nome_animal',
    placeholder: 'Não selecionado',
    tipoFiltro: 'texto',
    tamanho: 13
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    placeholder: '',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Quantidade de animais',
    chave: 'qtd_animais',
    placeholder: '0',
    tipoFiltro: 'texto',
    tamanhoMinimo: 10,
    classesExtra: 'mobile-hide'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasManejoSanitario: ColunasTabela[] = [
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    classesExtra: 'mobile-bold mobile-width-one-third'
  },
  {
    titulo: 'Manejo',
    chave: 'id_manejos',
    labelFormat: 'labelsManejosSanitarios',
    tipoFiltro: 'preenchidosSplit',
    placeholder: '',
    tamanho: 12,
    classesExtra: 'mobile-width-auto mobile-width-two-thirds mobile-manejos-titulos'
  },
  {
    // titulo: 'Sexo',
    chave: 'sexo_animal',
    tipo: 'icon-only',
    // fixa: true,
    // tipoFiltro: "sexo",
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Animal ou lote',
    chave: 'nome_lote',
    chaveFallback: 'nome_animal',
    placeholder: 'Não selecionado',
    tipoFiltro: 'texto',
    tamanho: 13
  },
  {
    titulo: 'Quantidade de animais',
    chave: 'qtd_animais',
    placeholder: '0',
    tipoFiltro: 'texto',
    tamanhoMinimo: 12,
    classesExtra: 'mobile-hide'
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    tipoFiltro: 'texto',
    placeholder: '',
    tamanho: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasEstoqueBiologicoLista: ColunasTabela[] = [
  {
    titulo: 'Tipo',
    chave: 'tipoDoEstoque',
    labelFormat: 'labelsEstoqueBiologicoTipo',
    placeholder: 'Sem tipo',
    tamanho: 6,
    tipoFiltro: 'preenchidos'
  },
  {
    titulo: 'Quantidade',
    chave: 'quantidade',
    placeholder: '0',
    tamanho: 7,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Touro',
    chave: 'touro_nome',
    placeholder: 'Sem touro',
    tamanho: 9,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Doadora',
    chave: 'doadora_nome',
    placeholder: 'Sem doadora',
    tamanho: 9,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Fornecedor',
    chave: 'fornecedor_nome',
    placeholder: '-',
    tamanho: 9,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Raça',
    chave: 'raca',
    placeholder: '-',
    labelFormat: 'labelsRaca',
    bloquearOrdenacao: true,
    tamanho: 9,
    tipoFiltro: 'preenchidos'
  },
  {
    titulo: 'Partida',
    chave: 'codigo_no_rack',
    placeholder: 'Sem partida',
    tamanho: 7,
    tipoFiltro: 'texto'
  },

  {
    titulo: 'Caneca',
    chave: 'caneca',
    placeholder: '-',
    tamanho: 7,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Botijão',
    chave: 'botijao',
    placeholder: '-',
    tamanho: 7,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Propriedade',
    chave: 'extra_propriedade_nome',
    placeholder: '',
    tipoFiltro: 'preenchidosMultiplos',
    tamanhoMinimo: 8
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasEstoqueNutricionalLista: ColunasTabela[] = [
  {
    titulo: 'Título',
    chave: 'titulo',
    placeholder: 'Sem título',
    tamanho: 10,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Tipo',
    chave: 'meta_tipoDoEstoque',
    labelFormat: 'labelsEstoqueNutricionalTipo',
    placeholder: 'Sem tipo',
    tamanho: 10,
    tipoFiltro: 'preenchidos'
  },
  {
    titulo: 'Valor',
    format: 'dinheiro',
    chave: 'extra_valor_total',
    placeholder: 'R$ 0,00',
    tamanho: 15,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Quantidade',
    chave: 'extra_quantidade',
    unidade: 'kg',
    placeholder: '0 kg',
    format: 'gmd',
    tamanho: 8,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Última Movimentação',
    chave: 'extra_ultima_movimentacao',
    placeholder: 'Sem movimentação',
    format: 'data',
    tamanho: 12,
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Propriedade',
    chave: 'extra_propriedade_nome',
    placeholder: '',
    tipoFiltro: 'preenchidosMultiplos',
    tamanhoMinimo: 8
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasEstoqueFarmaciaLista: ColunasTabela[] = [
  {
    titulo: 'Título',
    chave: 'titulo',
    placeholder: 'Sem título',
    tamanho: 10,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Marca',
    chave: 'meta_marca',
    placeholder: '-',
    tamanho: 6,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Tipo',
    chave: 'meta_tipoDoEstoque',
    labelFormat: 'labelsEstoqueFarmaciaTipo',
    placeholder: 'Sem tipo',
    tamanho: 10,
    tipoFiltro: 'preenchidos'
  },
  {
    titulo: 'Valor',
    format: 'dinheiro',
    chave: 'extra_valor_total',
    placeholder: 'R$ 0,00',
    tamanho: 15,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Quantidade',
    chave: 'extra_quantidade',
    small: 'meta_unidade',
    // unidade: 'kg',
    placeholder: '0',
    format: 'gmd',
    tamanho: 8,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Última Movimentação',
    chave: 'extra_ultima_movimentacao',
    placeholder: 'Sem movimentação',
    format: 'data',
    tamanho: 12,
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Propriedade',
    chave: 'extra_propriedade_nome',
    placeholder: '',
    tipoFiltro: 'preenchidosMultiplos',
    tamanhoMinimo: 8
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasEstoqueNutricionalLancamentos: ColunasTabela[] = [
  {
    titulo: 'Movimentação',
    tituloHidden: true,
    chave: 'movimentacao',
    tipo: 'icon-only',
    labelFormat: 'labelsEstoqueLancamentoMovimentacao',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Data',
    chave: 'data_lancamento',
    placeholder: 'Sem data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Quantidade',
    chave: 'qtd',
    unidade: 'kg',
    format: 'gmd',
    placeholder: 'Sem qtd',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    labelFormat: 'labelsEstoqueNutricionalLancamentoTipo',
    placeholder: 'Sem tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Valor',
    chave: 'valor_total',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Valor / Kg',
    chave: 'valor_medio_momentaneo',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Descrição',
    chave: 'obs',
    placeholder: '-',
    tamanhoMinimo: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]
export const ColunasEstoqueFarmaciaLancamentos: ColunasTabela[] = [
  {
    titulo: 'Movimentação',
    tituloHidden: true,
    chave: 'movimentacao',
    tipo: 'icon-only',
    labelFormat: 'labelsEstoqueLancamentoMovimentacao',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Data',
    chave: 'data_lancamento',
    placeholder: 'Sem data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Quantidade',
    chave: 'qtd',
    small: 'meta_unidade',
    format: 'gmd',
    placeholder: 'Sem qtd',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    labelFormat: 'labelsEstoqueNutricionalLancamentoTipo',
    placeholder: 'Sem tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Valor',
    chave: 'valor_total',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Valor Unitário',
    chave: 'valor_medio_momentaneo',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Descrição',
    chave: 'obs',
    placeholder: '-',
    tamanhoMinimo: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]

export const ColunasEstoqueFerramentasLista: ColunasTabela[] = [
  {
    titulo: 'Título',
    chave: 'titulo',
    placeholder: 'Sem título',
    tamanho: 10,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Tipo',
    chave: 'meta_tipoDoEstoque',
    labelFormat: 'labelsEstoqueFerramentasTipo',
    placeholder: 'Sem tipo',
    tamanho: 10,
    tipoFiltro: 'preenchidos'
  },
  {
    titulo: 'Valor',
    format: 'dinheiro',
    chave: 'extra_valor_total',
    placeholder: 'R$ 0,00',
    tamanho: 15,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Quantidade',
    chave: 'extra_quantidade',
    small: 'meta_unidade',
    // unidade: 'kg',
    placeholder: '0',
    format: 'gmd',
    tamanho: 8,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Código',
    chave: 'meta_codigo',
    placeholder: 'Sem código',
    tamanho: 10,
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Última Movimentação',
    chave: 'extra_ultima_movimentacao',
    placeholder: 'Sem movimentação',
    format: 'data',
    tamanho: 12,
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Propriedade',
    chave: 'extra_propriedade_nome',
    placeholder: '',
    tipoFiltro: 'preenchidosMultiplos',
    tamanhoMinimo: 8
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]

export const ColunasEstoqueFerramentasLancamentos: ColunasTabela[] = [
  {
    titulo: 'Movimentação',
    tituloHidden: true,
    chave: 'movimentacao',
    tipo: 'icon-only',
    labelFormat: 'labelsEstoqueLancamentoMovimentacao',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Data',
    chave: 'data_lancamento',
    placeholder: 'Sem data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Quantidade',
    chave: 'qtd',
    small: 'meta_unidade',
    format: 'gmd',
    placeholder: 'Sem qtd',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    labelFormat: 'labelsEstoqueNutricionalLancamentoTipo',
    placeholder: 'Sem tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Valor',
    chave: 'valor_total',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Valor Unitário',
    chave: 'valor_medio_momentaneo',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanho: 7
  },
  {
    titulo: 'Descrição',
    chave: 'obs',
    placeholder: '-',
    tamanhoMinimo: 15
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]

export const ColunasCalendario: ColunasTabela[] = [
  {
    titulo: '',
    chave: 'icon',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      pesagem: {
        class: 'pesagem-controle',
        color: 'manejo-pesagem'
      },
      ordenha: {
        class: 'leiteiro',
        color: 'manejo-ordenha'
      },
      reprodutivo: {
        class: 'inseminacao',
        color: 'manejo-reprodutivo'
      },
      parto: {
        class: 'parto',
        color: 'manejo-reprodutivo'
      },
      desmame: {
        class: 'desmame',
        color: 'manejo-reprodutivo'
      },
      chuva: {
        class: 'rainy-sharp',
        color: 'manejo-chuva'
      },
      sanitario: {
        class: 'seringa',
        color: 'manejo-sanitario'
      },
      geral: {
        class: 'calendar',
        color: 'manejo-evento'
      },
      outros: {
        class: 'calendar',
        color: 'manejo-evento'
      },
      evento: {
        class: 'calendar',
        color: 'manejo-evento'
      },
      observacao_animal: {
        class: 'warning',
        color: 'manejo-evento'
      },
      observacao_area: {
        class: 'warning',
        color: 'manejo-evento'
      },
      observacao_lote: {
        class: 'warning',
        color: 'manejo-evento'
      }
    },
    tamanho: 1
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    tipoFiltro: 'texto',
    labelFormat: 'labelsCalendarioEvento',
    tamanho: 8
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    tipoFiltro: 'texto',
    labelFormat: 'labelsCalendarioEvento',
    tamanho: 10
  },
  {
    titulo: 'Hora',
    placeholder: '--:--',
    chave: 'hora',
    tipoFiltro: 'texto',
    tamanho: 4
  },
  {
    titulo: 'Título',
    chave: 'titulo',
    tipoFiltro: 'texto',
    tamanho: 15
  },
  {
    titulo: 'Descrição',
    chave: 'observacao',
    tipoFiltro: 'texto',
    tamanhoMinimo: 14
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasEstoqueBiologicoLancamento: ColunasTabela[] = [
  {
    titulo: 'Movimentação',
    tituloHidden: true,
    chave: 'movimentacao',
    tipo: 'icon-only',
    labelFormat: 'labelsEstoqueLancamentoMovimentacao',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Data',
    chave: 'data_lancamento',
    placeholder: 'Sem data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Quantidade',
    chave: 'qtd',
    format: 'gmd',
    placeholder: 'Sem qtd',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Tipo',
    chave: 'tipo',
    labelFormat: 'labelsEstoqueBiologicoLancamentoTipo',
    placeholder: 'Sem tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 7
  },
  {
    titulo: 'Valor',
    chave: 'valor_total',
    placeholder: '-',
    tipoFiltro: 'preenchidos',
    format: 'dinheiro',
    tamanhoMinimo: 7
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]

export const ColunasCustoDaArroba: ColunasTabela[] = [
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    fixa: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Nascimento',
    format: 'data',
    chave: 'data_nascimento',
    tipoFiltro: 'rangeDatas',
    tamanho: 8
  },
  {
    titulo: 'Peso',
    chave: 'peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 6,
  },
  {
    titulo: 'Data',
    chave: 'data_pesagem',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'GMD',
    chave: 'gmd',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'gmd',
    unidade: 'kg',
    tamanho: 7,
  },
  {
    titulo: 'Peso Anterior',
    chave: 'peso_anterior',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 7,
  },
  {
    titulo: 'Ganho',
    chave: 'ganho',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'peso',
    unidade: 'kg',
    tamanho: 7,
  },
  {
    titulo: 'Custo Atribuído',
    chave: 'custo_atribuido',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'dinheiro',
    tamanho: 9
  },
  {
    titulo: 'Custo Sanitário',
    chave: 'custo_sanitario',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'dinheiro',
    tamanho: 9
  },
  {
    titulo: 'Custo Nutrição',
    chave: 'custo_nutricional',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'dinheiro',
    tamanho: 8,
  },
  {
    titulo: 'Custo Total',
    chave: 'custo_total',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'dinheiro',
    tamanho: 8,
  },
  {
    titulo: 'Custo por Kg',
    chave: 'custo_por_kg',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'dinheiro',
    tamanho: 8,
  },
  {
    titulo: 'Custo por @',
    chave: 'custo_por_arroba',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0',
    format: 'dinheiro',
    tamanho: 8,
  }
]

export const ColunasProduzidas: ColunasTabela[] = [
  {
    titulo: 'Mês',
    chave: 'mes',
    format: 'mes',
    tamanho: 5,
    tipoFiltro: 'rangeDatas'
  },
  {
    chave: 'nascimentos_peso',
    titulo: 'Nascimentos',
    unidade: 'kg',
    tamanho: 8,
    format: 'peso',
    placeholder: '-',
    small: 'nascimentos_qtd',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Transf. Entradas',
    chave: 'transferencias_entrada_peso',
    tamanho: 8,
    unidade: 'kg',
    format: 'peso',
    placeholder: '-',
    small: 'transferencias_entrada_qtd',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Mortes',
    chave: 'mortes_peso',
    tamanho: 8,
    unidade: 'kg',
    format: 'peso',
    placeholder: '-',
    small: 'mortes_qtd',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Perdas',
    chave: 'perdas_peso',
    tamanho: 8,
    unidade: 'kg',
    format: 'peso',
    placeholder: '-',
    small: 'perdas_qtd',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Transferências',
    chave: 'transferencias_peso',
    tamanho: 8,
    unidade: 'kg',
    format: 'peso',
    placeholder: '-',
    small: 'transferencias_qtd',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Compras',
    chave: 'compras_peso',
    unidade: 'kg',
    format: 'peso',
    placeholder: '-',
    tamanho: 8,
    small: 'compras_qtd',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Compras (sem registro)',
    chave: 'compras_manuais_peso',
    unidade: 'kg',
    format: 'peso',
    placeholder: '-',
    tamanho: 12,
    small: 'compras_manuais_qtd',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Vendas',
    chave: 'vendas_peso',
    unidade: 'kg',
    format: 'peso',
    placeholder: '-',
    tamanho: 8,
    small: 'vendas_qtd',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Animais',
    chave: 'animais',
    format: 'int',
    placeholder: '-',
    tamanho: 8,
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Peso total',
    chave: 'total_peso_arroba',
    unidade: '@',
    format: 'peso',
    placeholder: '-',
    tamanho: 8,
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Peso total (kg)',
    chave: 'total_peso',
    unidade: 'kg',
    format: 'peso',
    placeholder: '-',
    tamanho: 8,
    small: 'animais',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  }
]

export const ColunasRelatorioMovimentacoes: ColunasTabela[] = [
  {
    titulo: 'Mês',
    chave: 'mes',
    format: 'mes',
    tamanho: 5,
    tipoFiltro: 'rangeDatas',
  },
  {
    chave: 'nascimentos_qtd',
    titulo: 'Partos',
    tamanho: 4,
    placeholder: '0',
    classesExtra: 'titulo-verde'
  },
  {
    titulo: 'Comprados',
    chave: 'compras_qtd',
    placeholder: '0',
    tamanho: 7,
    classesExtra: 'titulo-verde'
  },
  {
    titulo: 'Comprados (sem registro)',
    chave: 'compras_manuais_qtd',
    placeholder: '0',
    tamanho: 12,
    classesExtra: 'titulo-verde'
  },
  {
    titulo: 'Transf. Entrada',
    chave: 'transferencias_entrada_qtd',
    placeholder: '0',
    tamanho: 10,
    classesExtra: 'titulo-verde'
  },
  {
    titulo: 'Mortes',
    chave: 'mortes_qtd',
    tamanho: 5,
    placeholder: '0',
    classesExtra: 'titulo-vermelho'
  },
  {
    titulo: 'Perdidos',
    chave: 'perdas_qtd',
    tamanho: 7,
    placeholder: '0',
    classesExtra: 'titulo-vermelho'
  },
  {
    titulo: 'Vendidos',
    chave: 'vendas_qtd',
    placeholder: '0',
    tamanho: 8,
    classesExtra: 'titulo-vermelho'
  },
  {
    titulo: 'Transferidos',
    chave: 'transferencias_qtd',
    placeholder: '0',
    tamanho: 10,
    classesExtra: 'titulo-vermelho'
  },
  {
    titulo: 'Total de Animais',
    chave: 'animais',
    format: 'int',
    placeholder: '-',
    tamanho: 8,
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Peso total',
    chave: 'total_peso_arroba',
    unidade: '@',
    format: 'peso',
    placeholder: '-',
    tamanho: 10,
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Peso total (kg)',
    chave: 'total_peso',
    unidade: 'kg',
    format: 'peso',
    placeholder: '-',
    tamanho: 10,
    // small: 'animais',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  }
]

export const ColunasUsuarios: ColunasTabela[] = [
  {
    chave: 'nome',
    titulo: 'Nome',
    tamanho: 12
  },
  {
    chave: 'email',
    titulo: 'Email',
    tamanho: 15
  },
  {
    chave: 'qtdPermissoes',
    titulo: 'Permissões'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasUsuariosPeoes: ColunasTabela[] = [
  {
    chave: 'nome',
    titulo: 'Nome',
    tamanho: 12
  },
  {
    chave: 'qtdPropriedades',
    titulo: 'Propriedades'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasEstacoesExposicoes: ColunasTabela[] = [
  {
    chave: 'matriz_sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Vaca',
    chave: 'matriz_numeracao',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Lote Atual',
    placeholder: 'Sem lote',
    chave: 'matriz_lote',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Raça',
    chave: 'matriz_raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Inseminador',
    chave: 'inseminador',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Partida',
    chave: 'semen_partida',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Escore',
    chave: 'ultimo_escore',
    tipoFiltro: 'rangeNumeros',
    tamanho: 5
  },
  {
    titulo: 'Touro',
    chave: 'exposicao_touro',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Exposição',
    labelFormat: 'labelsExposicao',
    chave: 'exposicao_tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Tipo',
    labelFormat: 'labelsExposicaoTipo',
    chave: 'exposicao_tipo_usado',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Doadora',
    chave: 'exposicao_doadora',
    tipoFiltro: 'texto',
    tamanho: 9
  }
]
export const ColunasEstacoesPrenhez: ColunasTabela[] = [
  {
    chave: 'matriz_sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Vaca',
    chave: 'matriz_numeracao',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Lote Atual',
    placeholder: 'Sem lote',
    chave: 'matriz_lote',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Raça',
    chave: 'matriz_raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Resultado',
    chave: 'dg_resultado',
    labelFormat: 'labelsExposicaoDG',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Inseminador',
    chave: 'inseminador',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Partida',
    chave: 'semen_partida',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Escore',
    chave: 'ultimo_escore',
    tipoFiltro: 'rangeNumeros',
    tamanho: 6
  },
  {
    titulo: 'Data Exposição',
    chave: 'exposicao_data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 8
  },
  {
    titulo: 'Resultado Final',
    chave: 'is_resultado_final',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 10,
    labelFormat: 'labelsCriaAoPe'
  },
  {
    titulo: 'Touro',
    chave: 'exposicao_touro',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Exposição',
    labelFormat: 'labelsExposicao',
    chave: 'exposicao_tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Tipo',
    labelFormat: 'labelsExposicaoTipo',
    chave: 'exposicao_tipo_usado',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Doadora',
    chave: 'exposicao_doadora',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Doses até Concepção',
    chave: 'exposicao_doses_gastas',
    tipoFiltro: 'texto',
    tamanho: 12
  },
  {
    titulo: 'Previsão de Parto',
    chave: 'dg_previsao_de_parto',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 10
  },
  {
    titulo: 'Dias até o Parto',
    chave: 'dias_ate_o_parto',
    format: 'int',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Ovário Direito',
    chave: 'dg_ovario_direito',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSituacaoOvarios',
    tamanho: 10
  },
  {
    titulo: 'Ovário Esquerdo',
    chave: 'dg_ovario_esquerdo',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSituacaoOvarios',
    tamanho: 10
  },
  {
    titulo: 'Tônus Uterino',
    chave: 'dg_tonus_uterino',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10
  },
  {
    titulo: 'Bastão',
    chave: 'inseminacao_bastao',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelBastaoMuco',
    tamanho: 10
  },
  {
    titulo: 'Muco',
    chave: 'inseminacao_muco',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelBastaoMuco',
    tamanho: 10
  }
]
export const ColunasEstacoesPartos: ColunasTabela[] = [
  {
    chave: 'matriz_sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Vaca',
    chave: 'matriz_numeracao',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Lote Atual',
    placeholder: 'Sem lote',
    chave: 'matriz_lote',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Raça',
    chave: 'matriz_raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    chave: 'parto_hash_cria1_sexo',
    titulo: 'Sexo Cria 1',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Cria',
    chave: 'extra_cria1',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    chave: 'parto_hash_cria2_sexo',
    titulo: 'Sexo Cria 2',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Cria 2',
    chave: 'extra_cria2',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Data Exposição',
    chave: 'exposicao_data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9
  },
  {
    titulo: 'Touro',
    chave: 'exposicao_touro',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Exposição',
    labelFormat: 'labelsExposicao',
    chave: 'exposicao_tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Tipo',
    labelFormat: 'labelsExposicaoTipo',
    chave: 'exposicao_tipo_usado',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Doadora',
    chave: 'exposicao_doadora',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Dias de Gestação',
    chave: 'dias_ate_o_parto',
    tipoFiltro: 'texto',
    tamanho: 14
  }
]
export const ColunasEstacoesDesmames: ColunasTabela[] = [
  {
    chave: 'matriz_sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Cria',
    chave: 'matriz_numeracao',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Nascimento',
    chave: 'matriz_nascimento',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  // {
  //   titulo: 'Categoria',
  //   chave: 'categoria',
  //   labelFormat: 'labelsCategoriaAnimal',
  //   tamanho: 6
  // },
  {
    titulo: 'Lote Atual',
    placeholder: 'Sem lote',
    chave: 'matriz_lote',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Desmame',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Raça',
    chave: 'matriz_raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Peso',
    format: 'peso',
    chave: 'desmame_peso',
    tamanho: 6,
    placeholder: '0 kg',
    tipoFiltro: 'texto',
    unidade: 'kg'
  },
  {
    titulo: 'Peso Ajustado 120 Dias',
    format: 'peso',
    chave: 'desmame_peso_ajustado_120',
    tamanho: 13,
    placeholder: '0 kg',
    tipoFiltro: 'texto',
    unidade: 'kg'
  },
  {
    titulo: 'Peso Ajustado 205 Dias',
    format: 'peso',
    chave: 'desmame_peso_ajustado',
    tamanho: 13,
    placeholder: '0 kg',
    tipoFiltro: 'texto',
    unidade: 'kg'
  },
  {
    titulo: 'Data Exposição',
    chave: 'exposicao_data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 8
  },
  {
    titulo: 'Touro',
    chave: 'exposicao_touro',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Exposição',
    labelFormat: 'labelsExposicao',
    chave: 'exposicao_tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Tipo',
    labelFormat: 'labelsExposicaoTipo',
    chave: 'exposicao_tipo_usado',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Doadora',
    chave: 'exposicao_doadora',
    tipoFiltro: 'texto',
    tamanho: 9
  }
]
export const ColunasEstacoesAbortos: ColunasTabela[] = [
  {
    chave: 'matriz_sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Vaca',
    chave: 'matriz_numeracao',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Lote Atual',
    placeholder: 'Sem lote',
    chave: 'matriz_lote',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Raça',
    chave: 'matriz_raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  // {
  //   chave: 'desmame_cria_sexo',
  //   titulo: 'Sexo',
  //   tipo: 'icon-only',
  //   labelFormat: 'labelsSexo',
  //   tipoFiltro: 'preenchidos',
  //   iconeCase: {
  //     macho: {
  //       class: 'male'
  //     },
  //     femea: {
  //       class: 'female'
  //     },
  //     natimorto: {
  //       class: 'close-circle',
  //       color: 'danger'
  //     }
  //   },
  //   centralizar: true,
  //   classesExtra: 'mobile-icon-only'
  // },
  // {
  //   titulo: 'Cria',
  //   chave: 'desmame_cria_numero',
  //   tamanho: 9
  // },
  // {
  //   titulo: 'Peso',
  //   format: 'peso',
  //   chave: 'desmame_peso',
  //   tamanho: 6,
  //   placeholder: '0 kg',
  //   unidade: 'kg'
  // },
  {
    titulo: 'Data Exposição',
    chave: 'exposicao_data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 8
  },
  {
    titulo: 'Touro',
    chave: 'exposicao_touro',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Exposição',
    labelFormat: 'labelsExposicao',
    chave: 'exposicao_tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Tipo',
    labelFormat: 'labelsExposicaoTipo',
    chave: 'exposicao_tipo_usado',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Doadora',
    chave: 'exposicao_doadora',
    tipoFiltro: 'texto',
    tamanho: 9
  }
]
export const ColunasEstacoesMortes: ColunasTabela[] = [
  {
    chave: 'matriz_sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Vaca',
    chave: 'matriz_numeracao',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Lote Atual',
    placeholder: 'Sem lote',
    chave: 'matriz_lote',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Data Parto',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Data Morte',
    chave: 'cria1_morte_data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Raça',
    chave: 'matriz_raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    chave: 'parto_hash_cria1_sexo',
    titulo: 'Sexo Cria 1',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Cria',
    chave: 'extra_cria1',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Data Exposição',
    chave: 'exposicao_data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 8
  },
  {
    titulo: 'Touro',
    chave: 'exposicao_touro',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Exposição',
    labelFormat: 'labelsExposicao',
    chave: 'exposicao_tipo',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Tipo',
    labelFormat: 'labelsExposicaoTipo',
    chave: 'exposicao_tipo_usado',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Doadora',
    chave: 'exposicao_doadora',
    tipoFiltro: 'texto',
    tamanho: 9
  },
  {
    titulo: 'Dias de Gestação',
    chave: 'dias_ate_o_parto',
    tipoFiltro: 'texto',
    tamanho: 14
  }
]
export const ColunasEstacoes: ColunasTabela[] = [
  {
    titulo: 'Estação',
    chave: 'estacao',
    tamanho: 6
  },
  {
    chave: 'inicio',
    titulo: 'Início',
    tamanho: 7,
    format: 'data',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    chave: 'expostas',
    titulo: 'Vacas Expostas',
    tamanho: 8,
    placeholder: '0',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  // {
  //   chave: 'prenhes',
  //   titulo: 'Prenhes',
  //   tamanho: 4,
  //   placeholder: '0',
  //   classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  // },
  {
    chave: 'taxa_de_prenhez',
    small: 'prenhes',
    titulo: '% Prenhez',
    format: 'peso',
    tamanho: 6,
    placeholder: '0%',
    unidade: '%',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    chave: 'taxa_perda_pre_parto',
    small: 'abortos',
    titulo: '% Perda Pré Parto',
    format: 'peso',
    tamanho: 9,
    placeholder: '0%',
    unidade: '%',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    chave: 'taxa_de_nascimento',
    small: 'partos',
    titulo: '% Nascimento',
    format: 'peso',
    tamanho: 8,
    placeholder: '0%',
    unidade: '%',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    chave: 'taxa_morte_bezerro',
    small: 'mortes_bezerro',
    titulo: '% Morte de Bezerro',
    format: 'peso',
    tamanho: 10,
    unidade: '%',
    placeholder: '0%',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    chave: 'taxa_desmame',
    small: 'desmames',
    titulo: '% Desmame',
    format: 'peso',
    tamanho: 7,
    placeholder: '0%',
    unidade: '%',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    chave: 'kg_bezerro_desmamado',
    titulo: 'Kg Bezerro Desmamado',
    format: 'peso',
    unidade: 'kg',
    tamanho: 11,
    placeholder: '0 kg',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    chave: 'kg_bezerro_desmamado_vaca_exposta',
    titulo: 'Kg Desmamado / Exposta',
    format: 'peso',
    unidade: 'kg',
    tamanhoMinimo: 15,
    placeholder: '0 kg',
    classesExtra: 'mobile-line-break mobile-width-auto bold mobile-bold'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes'
  }
]

export const ColunasEstacoesInseminacoes: ColunasTabela[] = [
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tamanho: 7
  },
  {
    chave: 'data',
    titulo: 'Data',
    tamanho: 7,
    format: 'data'
  },
  {
    chave: 'extra_lote',
    titulo: 'Lote',
    tamanho: 7
  },
  {
    chave: 'touro_nome',
    titulo: 'Touro'
  }
]
export const ColunasAprovacoesAreas: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    small: 'label_confinamento',
    tamanho: 15
  },
  {
    titulo: 'Tipo de Área',
    chave: 'meta_tipo_area',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    tamanho: 14
  },
  {
    titulo: 'Tipo de Capim',
    chave: 'meta_tipo_capim',
    tipoFiltro: 'texto',
    placeholder: '-',
    tamanho: 7
  },
  {
    titulo: 'Data de Cadastro',
    format: 'data',
    chave: 'aprovacao_data',
    tipoFiltro: 'rangeDatas',
    tamanho: 8
  },
  {
    titulo: 'Enviado por',
    chave: 'aprovacao_usuario_nome',
    tamanho: 8
  }
]
export const ColunasAprovacoesLotes: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    small: 'label_confinamento',
    tamanho: 15,
    classesExtra: 'mobile-bold mobile-width-two-thirds'
  },
  {
    titulo: 'Área',
    chave: 'area_nome',
    tipoFiltro: 'preenchidos',
    placeholder: 'Sem área',
    tamanho: 14,
    classesExtra: 'mobile-before-pin mobile-width-auto'
  },
  {
    titulo: 'Finalidade',
    chave: 'finalidade',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsFinalidade',
    tamanho: 7,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Sistema de Criação',
    chave: 'sistema_criacao',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsSistemaCriacao',
    tamanho: 9,
    classesExtra: 'mobile-hide-empty'
  },
  {
    titulo: 'Aptidão',
    chave: 'aptidao',
    tipoFiltro: 'preenchidos',
    placeholder: '-',
    labelFormat: 'labelsAptidao',
    tamanhoMinimo: 7,
    classesExtra: 'mobile-hide-empty'
  },
  {
    titulo: 'Data de Cadastro',
    format: 'data',
    chave: 'aprovacao_data',
    tipoFiltro: 'rangeDatas',
    tamanho: 8
  },
  {
    titulo: 'Enviado por',
    chave: 'aprovacao_usuario_nome',
    tamanho: 8
  }
]
export const ColunasAprovacoesAnimais: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Lote',
    chave: 'nome_lote',
    placeholder: 'Sem lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8,
    classesExtra: 'mobile-width-auto mobile-width-max-half mobile-line-break'
  },
  {
    titulo: 'Raça',
    chave: 'raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Nascimento',
    format: 'data',
    chave: 'data_nascimento',
    tipoFiltro: 'rangeDatas',
    tamanhoMinimo: 10
  },
  {
    titulo: 'Data de Cadastro',
    format: 'data',
    chave: 'aprovacao_data',
    tipoFiltro: 'rangeDatas',
    tamanho: 8
  },
  {
    titulo: 'Enviado por',
    chave: 'aprovacao_usuario_nome',
    tamanho: 8
  }
]
export const ColunasAprovacoesManejos: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Manejo',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'preenchidos',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    chave: 'animal_sexo',
    labelFormat: 'labelsSexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Animal/Lote/Área',
    chave: 'animal_numero',
    tamanho: 9,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  // {
  //   titulo: 'Nome',
  //   chave: 'animal_nome',
  //   placeholder: 'Sem nome',
  //   tipoFiltro: 'texto',
  //   tamanho: 7,
  //   classesExtra: 'mobile-width-auto'
  // },
  {
    chave: 'icone_manejo_resultado',
    labelFormat: 'labelsSexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      lote: {
        class: 'rebanho'
      },
      area: {
        class: 'location-sharp'
      },
      confinamento: {
        class: 'boi-cerca'
      },
      positivo: {
        class: 'checkmark-circle',
        color: '#479288'
      },
      negativo: {
        class: 'remove-circle',
        color: '#d32f2f'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Valor',
    chave: 'valor',
    placeholder: 'Sem valor',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Observação',
    chave: 'observacao',
    placeholder: '-',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Data de Cadastro',
    format: 'data',
    chave: 'aprovacao_data',
    tipoFiltro: 'rangeDatas',
    tamanho: 10
  },
  {
    titulo: 'Enviado por',
    chave: 'aprovacao_usuario_nome',
    tamanhoMinimo: 10,
    tipoFiltro: 'preenchidos'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]
export const ColunasManejosTodos: ColunasTabela[] = [
  {
    chave: 'data',
    tipoFiltro: 'rangeDatas',
    titulo: 'Data',
    format: 'data',
    tamanho: 7
  },
  {
    chave: 'qtdAnimais',
    titulo: 'Animais Manejados',
    placeholder: '0',
    tamanho: 10
  },
  {
    chave: 'qtdLotes',
    titulo: 'Lotes Manejados',
    placeholder: '0',
    tamanho: 9
  },
  {
    chave: 'qtdAreas',
    titulo: 'Áreas Manejadas',
    placeholder: '0',
    tamanho: 9
  },
  {
    chave: 'totalPeso',
    titulo: 'Peso Total',
    placeholder: '0 kg',
    format: 'peso',
    unidade: 'kg',
    tamanho: 8
  },
  {
    chave: 'gmd',
    titulo: 'GMD',
    placeholder: '0 kg',
    format: 'gmd',
    assinaturaPremium: true,
    unidade: 'kg',
    tamanho: 6
  },
  {
    chave: 'totalOrdenha',
    titulo: 'Total de Ordenhas',
    placeholder: '0 kg',
    format: 'gmd',
    unidade: 'kg',
    // tamanho: 8
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasControlePluviometrico: ColunasTabela[] = [
  {
    titulo: 'Data',
    format: 'data',
    chave: 'data',
    // tipoFiltro: 'rangeDatas',
    tamanho: 9
  },
  {
    chave: 'indice',
    titulo: 'Índice',
    unidade: 'mm',
    placeholder: 'Insira aqui',
    tamanho: 9
  },
  {
    chave: 'hora',
    titulo: 'Hora'
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasRelatorioPrevisaoPartos: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    chave: 'sexo',
    tipo: 'icon-only',
    // tipoFiltro: 'sexo',
    fixa: true,
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Previsão',
    chave: 'previsao',
    format: 'data',
    tamanho: 7,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Dias até o Parto',
    chave: 'dias_ate_o_parto',
    tamanho: 9,
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Sem nome'
  },
  {
    titulo: 'Lote',
    chave: 'lote',
    placeholder: 'Sem lote',
    tamanho: 9
  },
  {
    titulo: 'Qtd Partos',
    chave: 'qtd_partos',
    tipoFiltro: 'rangeNumeros',
    tamanho: 7
  },
  {
    titulo: 'Data DG',
    chave: 'data_dg',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Tipo Exposição',
    chave: 'tipo_exposicao',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsExposicoes',
    tamanho: 9
  },
  {
    titulo: 'Data Exposição',
    chave: 'data_exposicao',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 9
  },
  {
    chave: 'macho',
    tipo: 'icon-only',
    iconeCase: {
      macho: {
        class: 'male'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Touro',
    chave: 'touro',
    tipoFiltro: 'texto',
    tamanho: 10
  }
]

export const ColunasRelatorioManutencaoNascimento: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    chave: 'cria_sexo',
    tipo: 'icon-only',
    fixa: true,
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'cria_numeracao',
    tamanho: 9,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Data Nascimento',
    chave: 'cria_data_nascimento',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 9,
  },
  {
    titulo: 'Data Parto',
    chave: 'data_parto',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    // tamanho: 9,
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasRelatorioManutencaoGenealogia: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    chave: 'cria_sexo',
    tipo: 'icon-only',
    fixa: true,
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'cria_numeracao',
    tamanho: 9,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Data Nascimento',
    chave: 'cria_data_nascimento',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 9,
  },
  {
    titulo: 'Mãe (Correta)',
    chave: 'maeCorreta',
    tipoFiltro: 'texto',
    placeholder: 'Não Informada',
    tamanho: 9,
  },
  {
    titulo: 'Mãe (Atual)',
    chave: 'maeAtual',
    tipoFiltro: 'texto',
    placeholder: 'Não Informada',
    tamanho: 9,
  },
  {
    titulo: 'Pai (Correto)',
    chave: 'paiCorreto',
    tipoFiltro: 'texto',
    placeholder: 'Não Informado',
    tamanho: 9,
  },
  {
    titulo: 'Pai (Atual)',
    chave: 'paiAtual',
    tipoFiltro: 'texto',
    placeholder: 'Não Informado',
    // tamanho: 9,
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasRelatorioPrevisaoDesmames: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Sexo',
    chave: 'sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    fixa: true,
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Previsão',
    chave: 'previsao',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 7,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Dias até o Desmame',
    chave: 'dias_ate_o_desmame',
    tamanho: 11,
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Sem nome'
  },
  {
    titulo: 'Lote',
    chave: 'lote',
    placeholder: 'Sem lote',
    tamanho: 9
  },
  {
    titulo: 'Ícone Fêmea Matriz',
    chave: 'sexo_mae',
    tipo: 'icon-only',
    iconeCase: {
      femea: {
        class: 'female'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Matriz',
    chave: 'genealogia_numero_mae',
    tipoFiltro: 'texto',
    tamanho: 10
  },
  {
    titulo: 'Ícone Macho Pai',
    chave: 'sexo_pai',
    tipo: 'icon-only',
    iconeCase: {
      macho: {
        class: 'male'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Pai',
    chave: 'genealogia_numero_pai',
    tipoFiltro: 'texto',
    tamanho: 10
  }
]

export const ColunasRelatorioFluxoDeCaixa: ColunasTabela[] = [
  {
    titulo: '',
    chave: 'dado',
    placeholder: 'Sem dado',
    tipoFiltro: 'texto',
    class: 'bold',
    tamanho: 10,
    fixa: true,
    bold: true
  },
  {
    titulo: 'Janeiro',
    chave: 'janeiro',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Fevereiro',
    chave: 'fevereiro',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Março',
    chave: 'marco',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Abril',
    chave: 'abril',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Maio',
    chave: 'maio',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Junho',
    chave: 'junho',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Julho',
    chave: 'julho',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Agosto',
    chave: 'agosto',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Setembro',
    chave: 'setembro',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Outubro',
    chave: 'outubro',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Novembro',
    chave: 'novembro',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  },
  {
    titulo: 'Dezembro',
    chave: 'dezembro',
    tamanho: 9,
    format: 'dinheiro',
    tipoFiltro: 'texto',
    placeholder: 'R$ 0,00'
  }
  // {
  //   titulo: 'Total',
  //   chave: 'total',
  //   tamanho: 7,
  //   format: 'dinheiro',
  //   tipoFiltro: 'texto',
  //   placeholder: 'R$ 0,00',
  // },
]

export const ColunasRelatorioChuvas: ColunasTabela[] = [
  {
    titulo: 'Mês',
    chave: 'mesAno',
    format: 'mes',
    tamanho: 5
  },
  {
    titulo: 'Total',
    chave: 'total',
    format: 'gmd',
    unidade: 'mm',
    tamanho: 10,
    placeholder: '0'
  },
  {
    titulo: 'Dias de chuva',
    chave: 'dias',
    tamanho: 10,
    placeholder: '0'
  },
  {
    titulo: 'Média por dia do mês',
    chave: 'media',
    format: 'gmd',
    unidade: 'mm',
    tamanho: 10,
    placeholder: '0'
  },
  {
    titulo: 'Média por dia que choveu',
    chave: 'mediaDiaChuvoso',
    format: 'gmd',
    unidade: 'mm',
    tamanho: 15,
    placeholder: '0'
  }
]

export const ColunasTodasChuvas: ColunasTabela[] = [
  {
    titulo: 'Área',
    chave: 'extra_area',
    tipoFiltro: 'preenchidos',
    tamanho: 10
  },
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Hora do Registro',
    chave: 'chuva_hora',
    tamanho: 9
  },
  {
    titulo: 'Quantidade',
    chave: 'chuva_quantidade',
    unidade: 'mm',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10,
    placeholder: '0'
  }
]

export const ColunasRelatorioDesempenhoMatrizes: ColunasTabela[] = [
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    placeholder: 'Sem número',
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Brinco',
    chave: 'nbrinco',
    placeholder: 'Sem brinco',
    tamanho: 6,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tamanho: 7,
    placeholder: 'Sem nome',
    tipoFiltro: 'texto'
  },
  {
    titulo: 'Cio',
    chave: 'cio',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Implante',
    chave: 'implante',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Remoção Implante',
    chave: 'remocao',
    tamanho: 8,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Cobertura',
    chave: 'cobertura',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Inseminação',
    chave: 'inseminacao',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'DG +',
    chave: 'toque',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'DG -',
    chave: 'toque_negativo',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Previsão de parto',
    chave: 'previsao_parto',
    tamanho: 8,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Parto',
    chave: 'parto',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Intervalo entre partos',
    chave: 'intervalo_partos',
    small: 'intervalo_partos_texto',
    tamanho: 10,
    placeholder: '--'
    // tipoFiltro: "rangeDatas",
  },
  {
    titulo: 'Parto anterior',
    chave: 'parto_anterior',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Aborto',
    chave: 'aborto',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Natimorto',
    chave: 'natimorto',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Desmame',
    chave: 'desmame',
    tamanho: 7,
    format: 'data',
    placeholder: '--',
    tipoFiltro: 'rangeDatas'
  },
  {
    titulo: 'Peso do bezerro desmamado',
    chave: 'peso_filhote_desmame',
    tamanho: 11,
    format: 'peso',
    unidade: 'kg',
    placeholder: '--',
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Raça',
    chave: 'raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos'
  }
]

export const ColunasRelatorioEstoqueMensal: ColunasTabela[] = [
  {
    titulo: 'Mês',
    chave: 'mesAno',
    format: 'mes',
    tamanho: 5
  },
  {
    titulo: 'Cabeças',
    chave: 'animais',
    tamanho: 5,
    placeholder: '0'
  },
  {
    titulo: 'Bezerras',
    chave: 'bezerras',
    tamanho: 5,
    placeholder: '0'
  },
  {
    titulo: 'Novilhas 8-12',
    chave: 'novilhas_8_12',
    tamanho: 6,
    placeholder: '0'
  },
  {
    titulo: 'Novilhas 13-24',
    chave: 'novilhas_13_24',
    tamanho: 7,
    placeholder: '0'
  },
  {
    titulo: 'Nulíparas',
    chave: 'nuliparas',
    tamanho: 5,
    placeholder: '0'
  },
  {
    titulo: 'Primíparas',
    chave: 'primiparas',
    tamanho: 5,
    placeholder: '0'
  },
  {
    titulo: 'Secundíparas',
    chave: 'secundiparas',
    tamanho: 7,
    placeholder: '0'
  },
  {
    titulo: 'Multíparas',
    chave: 'multiparas',
    tamanho: 6,
    placeholder: '0'
  },
  {
    titulo: 'Bezerros',
    chave: 'bezerros',
    tamanho: 5,
    placeholder: '0'
  },
  {
    titulo: 'Garrotes 8-12',
    chave: 'garrotes_8_12',
    tamanho: 7,
    placeholder: '0'
  },
  {
    titulo: 'Garrotes 13-24',
    chave: 'garrotes_13_24',
    tamanho: 7,
    placeholder: '0'
  },
  {
    titulo: 'Bois 25-36',
    chave: 'bois_25_36',
    tamanho: 5,
    placeholder: '0'
  },
  {
    titulo: 'Bois 36+',
    chave: 'bois_36_mais',
    tamanho: 5,
    placeholder: '0'
  },
  {
    titulo: 'Reprodutores',
    chave: 'reprodutores',
    tamanho: 8,
    placeholder: '0'
  }
]

export const ColunasRelatorioProducaoDeLeite: ColunasTabela[] = [
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Mês',
    chave: 'mesAno',
    format: 'mes',
    tamanho: 5
  },
  {
    titulo: 'Total',
    chave: 'total',
    tamanho: 5,
    placeholder: '0',
    format: 'peso',
    unidade: 'kg'
  },
  {
    titulo: 'Ordenhas',
    chave: 'qtd_ordenhas',
    tamanho: 5,
    placeholder: '0',
  },
  {
    titulo: 'Qtd Vacas',
    chave: 'qtd_vacas',
    tamanho: 5,
    placeholder: '0',
  },
  {
    titulo: 'Média por Ordenha',
    chave: 'media_por_ordenha',
    tamanho: 9,
    placeholder: '0',
    format: 'peso',
    unidade: 'kg'
  },
  {
    titulo: 'Média por Vaca',
    chave: 'media_por_vaca',
    tamanho: 8,
    placeholder: '0',
    format: 'peso',
    unidade: 'kg'
  }
]
export const ColunasRelatorioProducaoDeLeitePorVaca: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Brinco Elet',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Lote',
    chave: 'extra_lote',
    placeholder: 'Sem lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8,
    classesExtra: 'mobile-width-auto mobile-width-max-half mobile-line-break'
  },
  {
    titulo: 'Hash Lote',
    tipoFiltro: 'preenchidosMultiplos',
    chave: 'hash_lote',
    tamanho: 6
  },
  {
    titulo: 'Ordenhas Diárias',
    chave: 'qtd_ordenhas_diarias',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    placeholder: '0'
  },
  {
    titulo: 'Ordenhas Totais',
    chave: 'total_ordenhas',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    placeholder: '0'
  },
  {
    titulo: 'Total',
    chave: 'total_litros',
    tamanho: 5,
    placeholder: '0',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    unidade: 'kg'
  },
  {
    titulo: 'Média Diária',
    chave: 'media_diaria',
    tamanho: 7,
    placeholder: '0',
    tipoFiltro: 'rangeNumeros',
    format: 'gmd',
    unidade: 'kg'
  },
  {
    titulo: 'Melhor Dia',
    chave: 'qtd_melhor_dia',
    tamanho: 7,
    placeholder: '0',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    unidade: 'kg'
  },
  {
    titulo: 'Data Melhor Dia',
    chave: 'data_melhor_dia',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    classesExtra: 'mobile-before-lote-desde mobile-font-smaller'
  },
  {
    titulo: 'Pior Dia',
    chave: 'qtd_pior_dia',
    tamanho: 7,
    placeholder: '0',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    unidade: 'kg'
  },
  {
    titulo: 'Data Pior Dia',
    chave: 'data_pior_dia',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    classesExtra: 'mobile-before-lote-desde mobile-font-smaller'
  },
  {
    titulo: 'Raça',
    chave: 'raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 12
  }
]

export const ColunasRelatorioGMD: ColunasTabela[] = [
  {
    titulo: 'Mês',
    chave: 'mes',
    format: 'mes',
    tamanho: 5
  },
  {
    titulo: 'GMD Médio',
    chave: 'gmd_medio',
    format: 'gmd',
    tamanho: 6,
    unidade: 'kg'
  },
  {
    titulo: 'Peso Total',
    chave: 'total_peso',
    format: 'peso',
    tamanho: 6,
    unidade: 'kg'
  },
  {
    titulo: 'Peso Médio',
    chave: 'total_peso_medio',
    format: 'peso',
    tamanho: 6,
    unidade: 'kg'
  },
  {
    titulo: 'Peso Total @',
    chave: 'total_peso_arroba',
    format: 'peso',
    tamanho: 6,
    unidade: '@'
  },
  {
    titulo: 'Peso Médio @',
    chave: 'total_peso_arroba_medio',
    format: 'peso',
    tamanho: 7,
    unidade: '@'
  },
  {
    titulo: 'Animais',
    chave: 'animais',
    tamanho: 8,
    format: 'int'
  },
  {
    titulo: 'UAs',
    chave: 'unidades_animais',
    tamanho: 8,
    format: 'int',
    unidade: 'UA'
  }
]

export const ColunasRelatorioMapaSanitario: ColunasTabela[] = [
  {
    titulo: 'Mês',
    chave: 'mesAno',
    format: 'mes',
    tamanho: 6
  },
  {
    titulo: 'Total',
    chave: 'animais',
    tamanho: 4,
    placeholder: '0'
  },
  {
    tituloSmall: 'Machos',
    titulo: '0 a 12 Meses',
    chave: 'machos_0_12',
    tamanho: 7,
    placeholder: '0'
  },
  {
    tituloSmall: 'Fêmeas',
    titulo: '0 a 12 Meses',
    chave: 'femeas_0_12',
    tamanho: 7,
    placeholder: '0'
  },
  {
    tituloSmall: 'Machos',
    titulo: '13 a 24 Meses',
    chave: 'machos_13_24',
    tamanho: 7,
    placeholder: '0'
  },
  {
    tituloSmall: 'Fêmeas',
    titulo: '13 a 24 Meses',
    chave: 'femeas_13_24',
    tamanho: 7,
    placeholder: '0'
  },
  {
    tituloSmall: 'Machos',
    titulo: '25 a 36 Meses',
    chave: 'machos_25_36',
    tamanho: 7,
    placeholder: '0'
  },
  {
    tituloSmall: 'Fêmeas',
    titulo: '25 a 36 Meses',
    chave: 'femeas_25_36',
    tamanho: 7,
    placeholder: '0'
  },
  {
    tituloSmall: 'Machos',
    titulo: '> 36 Meses',
    chave: 'machos_36_mais',
    tamanho: 6,
    placeholder: '0'
  },
  {
    tituloSmall: 'Fêmeas',
    titulo: '> 36 Meses',
    chave: 'femeas_36_mais',
    tamanho: 6,
    placeholder: '0'
  }
]
export const ColunasRelatorioDesembolsoCabecaMes: ColunasTabela[] = [
  {
    titulo: 'Mês',
    chave: 'mesAno',
    format: 'mes',
    tamanho: 6
  },
  // {
  //   titulo: 'Animais na Conta',
  //   chave: 'animais',
  //   tamanho: 10,
  //   placeholder: '0'
  // },
  {
    titulo: 'Animais',
    chave: 'animais_atual',
    tamanho: 8,
    placeholder: '0'
  },
  // {
  //   titulo: 'Despesas',
  //   chave: 'despesas',
  //   format: 'dinheiro',
  //   placeholder: 'R$ 0,00',
  //   tipoFiltro: 'rangeNumeros',
  //   tamanho: 9
  // },
  {
    titulo: 'Desembolso',
    chave: 'despesas_atual',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9
  },
  // {
  //   titulo: 'Desembolso / Cabeça',
  //   chave: 'desembolso_cabeca_mes',
  //   format: 'dinheiro',
  //   placeholder: 'R$ 0,00',
  //   tipoFiltro: 'rangeNumeros',
  //   tamanho: 10
  // },
  {
    titulo: 'Desembolso / Cabeça',
    chave: 'desembolso_cabeca_mes_atual',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10
  },
  {
    titulo: 'Desembolso / Cabeça / Dia',
    chave: 'desembolso_diario_atual',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tipoFiltro: 'rangeNumeros',
    tamanho: 12
  }
]
export const ColunasRelatorioMortalidade: ColunasTabela[] = [
  {
    titulo: 'Data da Morte',
    chave: 'data',
    format: 'data',
    tamanho: 7
  },
  {
    titulo: 'Causa',
    chave: 'causa',
    labelFormat: 'labelsCausaMorte',
    tamanho: 8,
    placeholder: 'N/A'
  },
  {
    chave: 'sexo',
    tipo: 'icon-only',
    tipoFiltro: 'sexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 6,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tamanho: 6,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tamanho: 8,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Nascimento',
    chave: 'data_nascimento',
    format: 'data',
    tamanho: 7
  },
  {
    titulo: 'Idade',
    chave: 'idade',
    unidade: 'meses',
    tamanho: 5
  },
  {
    titulo: 'Lote',
    chave: 'lote',
    tamanho: 8,
    placeholder: 'Sem lote'
  },
  {
    titulo: 'Peso',
    chave: 'peso',
    unidade: 'Kg',
    format: 'peso',
    placeholder: 'Não pesado',
    tamanho: 9
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tamanho: 8,
    placeholder: 'Não informado'
  },
  {
    titulo: 'Raça',
    chave: 'raca',
    labelFormat: 'labelsRaca',
    tamanho: 6
  },
  {
    titulo: 'Custo',
    chave: 'custo_animal',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tamanho: 8
  },
  {
    titulo: 'Observações',
    chave: 'observacao',
    tamanho: 15
  }
]

export const ColunasRelatorioPerda: ColunasTabela[] = [
  {
    titulo: 'Data da Perda',
    chave: 'data',
    format: 'data',
    tamanho: 7
  },
  {
    chave: 'sexo',
    tipo: 'icon-only',
    tipoFiltro: 'sexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 6,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tamanho: 6,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tamanho: 8,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Nascimento',
    chave: 'data_nascimento',
    format: 'data',
    tamanho: 7
  },
  {
    titulo: 'Idade',
    chave: 'idade',
    unidade: 'meses',
    tamanho: 5
  },
  {
    titulo: 'Lote',
    chave: 'lote',
    tamanho: 8,
    placeholder: 'Sem lote'
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tamanho: 8,
    placeholder: 'Não informado'
  },
  {
    titulo: 'Raça',
    chave: 'raca',
    labelFormat: 'labelsRaca',
    tamanho: 6
  },
  {
    titulo: 'Observações',
    chave: 'observacao',
    tamanho: 15
  }
]

export const ColunasRelatorioPartos: ColunasTabela[] = [
  {
    titulo: 'Sexo',
    chave: 'sexo',
    tipo: 'icon-only',
    fixa: true,
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tipoFiltro: 'texto',
    tamanho: 6,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    tipoFiltro: 'texto',
    placeholder: 'Sem brinco',
    tamanho: 6,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 8,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Data',
    chave: 'data_nascimento',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Raça',
    chave: 'extra_composicao_racial',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsRaca',
    tamanho: 12
  },
  {
    titulo: 'Lote',
    tipoFiltro: 'preenchidos',
    chave: 'extra_lote',
    tamanho: 8
  },
  {
    titulo: 'Data do DG',
    tipoFiltro: 'rangeDatas',
    chave: 'data_dg',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Peso ao Nascer',
    chave: 'peso_nascimento',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    tamanho: 9
  },
  {
    titulo: 'Último Peso',
    chave: 'ultimo_peso',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    unidade: 'kg',
    tamanho: 8
  },
  {
    titulo: 'Data Último Peso',
    chave: 'ultimo_peso_data',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 9
  },
  {
    titulo: 'Sexo',
    chave: 'sexo_matriz',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Matriz',
    chave: 'matriz_numero',
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    tamanho: 9
  },
  {
    titulo: 'Intervalo Entre Partos',
    chave: 'intervalo_entre_partos',
    tipoFiltro: 'rangeNumeros',
    format: 'gmd',
    unidade: 'dias',
    tamanho: 12
  },
  {
    titulo: 'Lote da Matriz',
    chave: 'extra_lote_matriz',
    tipoFiltro: 'preenchidos',
    tamanho: 9
  },
  {
    titulo: 'Sexo',
    chave: 'reprodutor_sexo',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Reprodutor',
    chave: 'reprodutor',
    tipoFiltro: 'texto',
    tamanho: 9
  }
]

export const ColunasRelatorioIntervaloEntrePartos: ColunasTabela[] = [
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    fixa: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tipoFiltro: 'texto',
    tamanho: 6,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    tipoFiltro: 'texto',
    placeholder: 'Sem brinco',
    tamanho: 6,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 8,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'S. Reprodutiva',
    labelFormat: 'labelsSituacaoReprodutiva',
    chave: 'situacao_reprodutiva',
    tipoFiltro: 'preenchidosMultiplos',
    placeholder: '',
    tamanho: 8
  },
  {
    titulo: 'Categoria',
    chave: 'extra_categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 7,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Raça',
    chave: 'raca',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsRaca',
    tamanho: 12
  },
  {
    titulo: 'Lote',
    tipoFiltro: 'preenchidos',
    chave: 'extra_lote_matriz',
    tamanho: 8
  },
  {
    titulo: 'Intervalo Entre Partos',
    chave: 'intervalo_entre_partos',
    tipoFiltro: 'rangeNumeros',
    format: 'gmd',
    unidade: 'dias',
    tamanho: 12
  },
  {
    titulo: 'Data Último Parto',
    chave: 'data_ultimo_parto',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 10
  },
  {
    titulo: 'Data Penúltimo Parto',
    chave: 'data_penultimo_parto',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 12
  },
  {
    titulo: 'Dias Desde Último Parto',
    chave: 'dias_desde_ultimo_parto',
    tipoFiltro: 'rangeNumeros',
    format: 'gmd',
    unidade: 'dias',
    tamanho: 12
  }
]

export const ColunasRelatorioCustosELucratividade: ColunasTabela[] = [
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    fixa: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tipoFiltro: 'texto',
    tamanho: 6,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 8,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Nascimento',
    chave: 'data_nascimento',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Situação',
    labelFormat: 'labelsSituacao',
    chave: 'situacao',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 6
  },
  {
    titulo: 'Lote',
    tipoFiltro: 'preenchidosMultiplos',
    chave: 'extra_lote',
    tamanho: 6
  },
  {
    titulo: 'Hash Lote',
    tipoFiltro: 'preenchidosMultiplos',
    chave: 'hash_lote',
    tamanho: 6
  },
  {
    titulo: 'Custo Compra',
    chave: 'custo_compra',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tamanho: 9,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Custo Nutricional',
    chave: 'custo_nutricional',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tamanho: 10,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Custo Sanitário',
    chave: 'custo_sanitario',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tamanho: 10,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Custo Atribuído',
    chave: 'custo_atribuido',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tamanho: 10,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Custo Total',
    chave: 'custo_total',
    format: 'dinheiro',
    placeholder: 'R$ 0,00',
    tamanho: 8,
    tipoFiltro: 'rangeNumeros'
  },
  {
    titulo: 'Peso',
    chave: 'peso',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0 kg',
    unidade: 'kg',
    tamanho: 6
  },
  {
    titulo: 'Valor Venda',
    chave: 'valor_venda',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'R$ 0,00',
    tamanho: 8
  },
  {
    titulo: 'Tipo',
    tituloHidden: true,
    chave: 'icone_lucro_prejuizo',
    tipo: 'icon-only',
    labelFormat: 'labelsTiposFinancas',
    iconeCase: {
      undefined: {},
      entrada: {
        class: 'add-circle',
        color: '#479288'
      },
      saida: {
        class: 'remove-circle',
        color: '#d32f2f'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Lucro/Prejuízo',
    chave: 'lucro_ou_prejuizo',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'R$ 0,00',
    tamanho: 9
  },
  {
    titulo: 'Peso de Compra',
    chave: 'peso_na_compra',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    format: 'peso',
    placeholder: 'Não pesado',
    tamanho: 10
  },
  {
    titulo: 'Ganho',
    chave: 'ganho',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    placeholder: '0 kg',
    unidade: 'kg',
    tamanho: 7
  },
]
export const ColunasRelatorioDesmames: ColunasTabela[] = [
  {
    titulo: 'Sexo',
    chave: 'sexo',
    tipo: 'icon-only',
    fixa: true,
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tipoFiltro: 'texto',
    tamanho: 6,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    tipoFiltro: 'texto',
    placeholder: 'Sem brinco',
    tamanho: 6,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 8,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Nascimento',
    chave: 'data_nascimento',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Raça',
    chave: 'extra_composicao_racial',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsRaca',
    tamanho: 12,
    bloquearOrdenacao: true
  },
  {
    titulo: 'Lote',
    tipoFiltro: 'preenchidos',
    chave: 'extra_lote',
    tamanho: 8
  },
  {
    titulo: 'Peso ao Nascer',
    chave: 'peso_nascimento',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    tamanho: 9
  },
  {
    titulo: 'Desmame',
    chave: 'data_desmame',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Última Pesagem',
    chave: 'pesagem_data',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Último Peso',
    chave: 'pesagem_peso',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    tamanho: 9
  },
  {
    titulo: 'GMD Último Peso',
    chave: 'pesagem_gmd',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    tamanho: 10,
    format: 'gmd',
    placeholder: 'Sem GMD'
  },
  {
    titulo: 'Peso no Desmame',
    chave: 'peso_desmame',
    format: 'peso',
    placeholder: '0 kg',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    tamanho: 10
  },
  {
    titulo: 'Peso Ajustado 120 Dias',
    format: 'peso',
    chave: 'desmame_peso_ajustado_120',
    tamanho: 13,
    placeholder: '0 kg',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg'
  },
  {
    titulo: 'Peso Ajustado 205 Dias',
    format: 'peso',
    chave: 'desmame_peso_ajustado',
    tamanho: 13,
    placeholder: '0 kg',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg'
  },
  {
    titulo: 'Ícone Macho Pai',
    chave: 'sexo_pai',
    tipo: 'icon-only',
    iconeCase: {
      macho: {
        class: 'male'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Pai',
    chave: 'genealogia_nome_pai',
    tipoFiltro: 'texto',
    tamanho: 8,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Ícone Fêmea Matriz',
    chave: 'sexo_mae',
    tipo: 'icon-only',
    iconeCase: {
      femea: {
        class: 'female'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Mãe',
    chave: 'genealogia_nome_mae',
    tipoFiltro: 'texto',
    tamanho: 8,
    placeholder: 'Não identificado'
  }
]
export const ColunasRelatorioPesagem: ColunasTabela[] = [
  {
    titulo: 'Sexo',
    chave: 'sexo',
    tipo: 'icon-only',
    fixa: true,
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    fixa: true,
    tipoFiltro: 'texto',
    tamanho: 7,
    placeholder: 'Sem número'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    tipoFiltro: 'texto',
    placeholder: 'Sem brinco',
    tamanho: 6,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    placeholder: 'Sem nome'
  },
  {
    titulo: 'Idade na pesagem',
    chave: 'idade_na_pesagem',
    unidade: 'meses',
    tipoFiltro: 'rangeNumeros',
    tamanho: 10
  },
  {
    titulo: 'Lote Atual',
    chave: 'extra_lote',
    placeholder: 'Sem lote',
    // tipoFiltro: 'preenchidosMultiplos',
    tamanho: 11,
    classesExtra: 'mobile-width-auto mobile-width-max-half mobile-line-break'
  },
  {
    titulo: 'Data',
    chave: 'extra_data_pesagem',
    format: 'data',
    tamanho: 7
  },
  {
    titulo: 'Peso',
    chave: 'extra_peso',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    format: 'peso',
    placeholder: 'Não pesado',
    tamanho: 5
  },
  {
    titulo: 'Peso @',
    chave: 'peso_arroba',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    unidade: '@',
    tamanho: 5
  },
  {
    titulo: 'GMD',
    chave: 'extra_gmd',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    tamanho: 6,
    format: 'gmd',
    placeholder: 'Sem GMD'
  },
  {
    titulo: 'Ganho',
    chave: 'ganho',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    small: 'peso_data',
    format: 'peso',
    placeholder: '0 Kg',
    tamanho: 12
  },
  {
    titulo: 'Data 2',
    chave: 'peso_anterior_data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7,
    placeholder: 'Não pesado',
  },
  {
    titulo: 'Peso',
    chave: 'peso_anterior',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    format: 'peso',
    placeholder: '0 Kg',
    tamanho: 5
  },
  {
    titulo: 'GMD',
    chave: 'penultimo_gmd',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    tamanho: 14,
    format: 'gmd',
    placeholder: '0 kg',
  },
  {
    titulo: 'Data 3',
    chave: 'antepenultimo_data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7,
    placeholder: 'Não pesado',
  },
  {
    titulo: 'Peso',
    chave: 'antepenultimo_peso',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    format: 'peso',
    placeholder: '0 Kg',
    tamanho: 5
  },
  {
    titulo: 'GMD',
    chave: 'antepenultimo_gmd',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    tamanho: 14,
    format: 'gmd',
    placeholder: '0 kg',
  },
  {
    titulo: 'Primeira Pesagem',
    chave: 'primeiro_peso_data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    placeholder: 'Não pesado',
    tamanho: 10
  },
  {
    titulo: 'Primeiro peso',
    chave: 'primeiro_peso',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    format: 'peso',
    placeholder: '0 Kg',
    tamanho: 8
  },
  {
    titulo: 'GMD desde o Nascimento',
    chave: 'gmd_global',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    format: 'gmd',
    placeholder: 'Sem GMD',
    tamanho: 14
  },
  // {
  //   titulo: 'Entrada na fazenda',
  //   chave: 'meta_data_entrada',
  //   format: 'data',
  //   tamanho: 10,
  //   placeholder: 'Não informado'
  // },
  {
    titulo: 'Produtor de Origem',
    tipoFiltro: 'texto',
    chave: 'extra_produtor_de_origem',
    placeholder: 'Nascido na propriedade',
    // bloquearOrdenacao: true,
    tamanho: 12
  }
]
export const ColunasRelatorioDesempenhoNutricional: ColunasTabela[] = [
  {
    titulo: 'Sexo',
    chave: 'sexo',
    tipo: 'icon-only',
    fixa: true,
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    fixa: true,
    tipoFiltro: 'texto',
    tamanho: 7,
    placeholder: 'Sem número'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    tipoFiltro: 'texto',
    placeholder: 'Sem brinco',
    tamanho: 6,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    placeholder: 'Sem nome'
  },
  {
    titulo: 'Lote Atual',
    chave: 'extra_lote',
    placeholder: 'Sem lote',
    // tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8,
    classesExtra: 'mobile-width-auto mobile-width-max-half mobile-line-break'
  },
  {
    titulo: 'Primeiro Peso',
    chave: 'inicio_peso',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    format: 'peso',
    placeholder: 'Não pesado',
    tamanho: 8
  },
  {
    titulo: 'Data',
    chave: 'inicio_data',
    format: 'data',
    tamanho: 7
  },
  {
    titulo: 'GMD',
    chave: 'inicio_gmd',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    format: 'gmd',
    placeholder: 'Sem GMD',
    tamanho: 7
  },
  {
    titulo: 'Último Peso',
    chave: 'final_peso',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    format: 'peso',
    placeholder: 'Não pesado',
    tamanho: 8
  },
  {
    titulo: 'Data',
    chave: 'final_data',
    format: 'data',
    tamanho: 7
  },
  {
    titulo: 'GMD',
    chave: 'final_gmd',
    tipoFiltro: 'rangeNumeros',
    unidade: 'kg',
    format: 'gmd',
    placeholder: 'Sem GMD',
    tamanho: 7
  },
  {
    titulo: 'Ganho',
    chave: 'peso_ganho',
    tipoFiltro: 'rangeNumeros',
    unidade: 'Kg',
    small: 'peso_data',
    format: 'peso',
    placeholder: 'Não pesado',
    tamanho: 6
  },
  {
    titulo: 'Dieta',
    tipoFiltro: 'preenchidosMultiplos',
    chave: 'estoque_item_nome',
    placeholder: '-',
    tamanho: 12
  }
]
export const ColunasRelatorioConsumoNutricional: ColunasTabela[] = [
  {
    titulo: 'Item',
    chave: 'item',
    tipoFiltro: 'texto',
    placeholder: 'Sem item',
    tamanho: 8
  },
  {
    titulo: 'Lote',
    chave: 'lote',
    placeholder: 'Sem lote',
    tamanho: 8
  },
  {
    titulo: 'Animais',
    chave: 'animais',
    format: 'int',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Sem animais',
    tamanho: 6
  },
  {
    titulo: 'Consumo',
    chave: 'consumo',
    format: 'gmd',
    unidade: 'kg',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Sem consumo',
    tamanho: 7
  },
  {
    titulo: 'Custo',
    chave: 'custo',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Não informado',
    tamanho: 9
  },
  {
    titulo: 'Dias',
    chave: 'dias',
    format: 'int',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Sem dias',
    tamanho: 6
  },
  {
    titulo: 'Diárias',
    chave: 'diarias',
    format: 'int',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Sem diárias',
    tamanho: 6
  },
  {
    titulo: 'Consumo Diário',
    chave: 'consumo_diario',
    format: 'gmd',
    unidade: 'Kg',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Sem Consumo Diário',
    tamanho: 9
  },
  {
    titulo: 'Cons. Diário / Animal',
    chave: 'consumo_diario_por_animal',
    format: 'gmd',
    unidade: 'Kg',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Sem Consumo Diário',
    tamanho: 12
  },
  {
    titulo: 'Custo Diário',
    chave: 'custo_diario',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Não informado',
    tamanho: 10
  },
  {
    titulo: 'Custo Diario por Animal',
    chave: 'custo_diario_por_animal',
    format: 'dinheiro',
    tipoFiltro: 'rangeNumeros',
    placeholder: 'Não informado',
    tamanho: 14
  }
]

export const ColunasRelatorioAbortos: ColunasTabela[] = [
  {
    chave: 'matriz_sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    labelFormat: 'labelsSexo',
    tipoFiltro: 'preenchidos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Vaca',
    chave: 'matriz_numeracao',
    placeholder: 'Sem número',
    tipoFiltro: 'texto',
    tamanho: 6
  },
  {
    titulo: 'Categoria',
    chave: 'categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Lote',
    placeholder: 'Sem lote',
    chave: 'extra_lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 6
  },
  {
    titulo: 'Data',
    chave: 'data',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 7
  },
  {
    titulo: 'Raça',
    chave: 'matriz_raca',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidos',
    tamanho: 6
  },
  {
    titulo: 'Data da Exposição',
    chave: 'data_exposicao',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 10
  },
  {
    titulo: 'Tipo Exposição',
    chave: 'tipo_exposicao',
    placeholder: '',
    labelFormat: 'labelsTiposExposicao',
    tipoFiltro: 'preenchidos',
    tamanho: 8
  },
  {
    titulo: 'Touro',
    chave: 'touro',
    placeholder: '-',
    tipoFiltro: 'texto',
    tamanho: 8
  },
  {
    titulo: 'Doadora',
    chave: 'doadora',
    placeholder: '-',
    tipoFiltro: 'texto',
    tamanho: 8
  }
]

export const ColunasRelatorioVacasAptasPosParto: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    fixa: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Data do Parto',
    format: 'data',
    chave: 'data_ultimo_parto',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    classesExtra: 'mobile-before-lote-desde mobile-font-smaller'
  },
  {
    titulo: 'Dias desde o Parto',
    chave: 'dias_desde_ultimo_parto',
    placeholder: 'Sem informação',
    tipoFiltro: 'texto',
    tamanho: 11,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Lote',
    chave: 'extra_lote',
    placeholder: 'Sem lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8,
    classesExtra: 'mobile-width-auto mobile-width-max-half mobile-line-break'
  },
  {
    titulo: 'Hash Lote',
    tipoFiltro: 'preenchidosMultiplos',
    chave: 'hash_lote',
    tamanho: 6
  },
  {
    titulo: 'Partos',
    chave: 'extra_partos_total',
    tipoFiltro: 'preenchidosMultiplos',
    labelFormat: 'labelsParto',
    tamanho: 5,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Categoria',
    chave: 'extra_categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 7,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Raça',
    chave: 'extra_composicao_racial',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 12
  },
  {
    titulo: 'Observações',
    chave: 'observacao',
    tipoFiltro: 'texto',
    tamanho: 8
  }
]

export const ColunasRelatorioVacasExpostasSemDG: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    fixa: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Lote',
    chave: 'extra_lote',
    placeholder: 'Sem lote',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8,
    classesExtra: 'mobile-width-auto mobile-width-max-half mobile-line-break'
  },
  {
    titulo: 'Hash Lote',
    tipoFiltro: 'preenchidosMultiplos',
    chave: 'hash_lote',
    tamanho: 6
  },
  {
    titulo: 'Data Exposição',
    chave: 'data_exposicao',
    placeholder: '',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 10,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Intervalo Exposição',
    chave: 'intervalo_exposicao',
    placeholder: '',
    tipoFiltro: 'rangeNumeros',
    tamanho: 12,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Tipo Exposição',
    chave: 'tipo_exposicao_inseminacao',
    labelFormat: 'labelsExposicaoInseminacaoTipo',
    placeholder: '',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 8,
    classesExtra: 'mobile-width-auto'
  },
  {
    titulo: 'Partos',
    chave: 'extra_partos_total',
    tipoFiltro: 'rangeNumeros',
    labelFormat: 'labelsParto',
    tamanho: 5,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Categoria',
    chave: 'extra_categoria',
    labelFormat: 'labelsCategoriaAnimal',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 7,
    classesExtra: 'mobile-line-break'
  },
  {
    titulo: 'Raça',
    chave: 'extra_composicao_racial',
    labelFormat: 'labelsRaca',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 12
  },
  {
    titulo: 'Observações',
    chave: 'observacao',
    tipoFiltro: 'texto',
    tamanhoMinimo: 14
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasRelatorioTodosReprodutivos: ColunasTabela[] = [
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      natimorto: {
        class: 'close-circle',
        color: 'danger'
      }
    },
    centralizar: true
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tipoFiltro: 'texto',
    tamanho: 6,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    tipoFiltro: 'texto',
    placeholder: 'Sem brinco',
    tamanho: 6,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 8,
    placeholder: 'Não identificado'
  },
  {
    titulo: 'Data',
    chave: 'data_nascimento',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Raça',
    chave: 'raca',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsRaca',
    tamanho: 8
  },
  {
    titulo: 'Lote',
    chave: 'lote',
    tamanho: 9
  },
  {
    titulo: 'Data do Manejo',
    tipoFiltro: 'rangeDatas',
    chave: 'data_manejo',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Tipo Manejo',
    chave: 'tipo_manejo',
    labelFormat: 'labelsManejosReprodutivos',
    tipoFiltro: 'preenchidos',
    tamanho: 12
  },
  {
    titulo: 'Resultado',
    chave: 'resultado',
    labelFormat: 'labelsRelatorioTodosReprodutivos',
    tamanho: 20
  },
  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true
  }
]

export const ColunasListaTodosManejosAnimais: ColunasTabela[] = [
  {
    tipo: 'checkbox',
    chave: 'checkbox',
    fixa: true
  },
  {
    chave: 'icone',
    titulo: 'Tipo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsTipoManejos',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      },
      lotes: {
        class: 'rebanho'
      },
      areas: {
        class: 'location-sharp'
      },
    },
    centralizar: true,
    fixa: true
  },
  // {
  //   titulo: 'Tipo',
  //   chave: 'categoria',
  //   tipoFiltro: 'texto',
  //   tamanho: 8,
  //   placeholder: 'Não identificado',
  //   fixa: true
  // },
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 8,
    placeholder: 'Não identificado',
    fixa: true
  },
  // {
  //   titulo: 'Animal',
  //   chave: 'animal',
  //   tipoFiltro: 'texto',
  //   tamanho: 8,
  //   placeholder: 'Não identificado',
  //   fixa: true
  // },
  {
    titulo: 'Data',
    chave: 'data',
    tipoFiltro: 'rangeDatas',
    format: 'data',
    tamanho: 8
  },
  {
    titulo: 'Manejo',
    chave: 'procedimento',
    labelFormat: 'labelsListaTodosManejos',
    // tipoFiltro: 'preenchidos',
    tamanho: 12
  },

  {
    titulo: 'Número',
    chave: 'geral_numero_controle',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'EID',
    chave: 'geral_brinco_eletronico',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Propriedade',
    chave: 'geral_transferencia_propriedade',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Causa',
    chave: 'geral_morte_causa',
    tipoFiltro: 'texto',
    tamanho: 9,
    labelFormat: 'labelsCausaMorte',
    chip: true
  },
  {
    titulo: 'Lote',
    chave: 'geral_movimentacao_lote',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Confinamento',
    chave: 'geral_envio_confinamento',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },


  {
    titulo: 'Total',
    chave: 'ordenha_total',
    format: 'peso',
    unidade: 'Kg',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Ordenha 1',
    chave: 'ordenha_primeira',
    format: 'peso',
    unidade: 'Kg',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Ordenha 2',
    chave: 'ordenha_segunda',
    format: 'peso',
    unidade: 'Kg',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Ordenha 3',
    chave: 'ordenha_terceira',
    format: 'peso',
    unidade: 'Kg',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },


  {
    titulo: 'Escore Corporal',
    chave: 'reprodutivo_escore',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Resultado Cio',
    chave: 'reprodutivo_cio_resultado',
    labelFormat: 'labelsExposicaoCio',
    tipoFiltro: 'preenchidos',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Resultado DG',
    chave: 'reprodutivo_dg_resultado',
    labelFormat: 'labelsExposicaoDG',
    tipoFiltro: 'preenchidos',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Período Gestacional',
    chave: 'reprodutivo_dg_periodo_gestacional',
    format: 'int',
    unidade: 'dias',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Previsão de Parto',
    chave: 'reprodutivo_dg_previsao_de_parto',
    format: 'data',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Ovário Direito',
    chave: 'reprodutivo_dg_ovario_direito',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Ovário Esquerdo',
    chave: 'reprodutivo_dg_ovario_esquerdo',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Tônus Uterino',
    chave: 'reprodutivo_dg_tonus_uterino',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },

  {
    titulo: 'Monta Touro',
    chave: 'reprodutivo_monta_touro',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Inseminação Touro',
    chave: 'reprodutivo_inseminacao_touro',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Doadora',
    chave: 'reprodutivo_inseminacao_doadora',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Inseminador',
    chave: 'reprodutivo_inseminacao_inseminador',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Cria 1',
    chave: 'reprodutivo_parto_cria1',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Cria 2',
    chave: 'reprodutivo_parto_cria2',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },


  {
    titulo: 'Item',
    chave: 'sanitario_estoque_titulo',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Quantidade',
    chave: 'sanitario_estoque_qtd',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    format: 'int',
    chip: true
  },
  {
    titulo: 'Sanitario - Tipo',
    chave: 'sanitario_sem_estoque_tipo',
    tipoFiltro: 'preenchidosMultiplos',
    tamanho: 9,
    labelFormat: 'labelsManejosSanitarios',
    chip: true
  },
  {
    titulo: 'Sanitário - Procedimento',
    chave: 'sanitario_sem_estoque_titulo',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },


  {
    titulo: 'Nutricional',
    chave: 'nutricional_estoque_titulo',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Nutricional Quantidade',
    chave: 'nutricional_estoque_qtd',
    tipoFiltro: 'rangeNumeros',
    format: 'peso',
    unidade: 'Kg',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Nutricional Duração',
    chave: 'nutricional_estoque_duracao',
    format: 'int',
    unidade: 'dias',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },

  {
    titulo: 'Circunferência',
    chave: 'genetica_ce',
    unidade: 'cm',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Aprumos',
    chave: 'genetica_aprumos',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Boca',
    chave: 'genetica_boca',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Casco',
    chave: 'genetica_casco',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Chanfro',
    chave: 'genetica_chanfro',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Deca',
    chave: 'genetica_deca',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Frame',
    chave: 'genetica_frame',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Garupa',
    chave: 'genetica_garupa',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'IABCZ',
    chave: 'genetica_iabcz',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'IC',
    chave: 'genetica_ic',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'LD',
    chave: 'genetica_ld',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Musculosidade',
    chave: 'genetica_musculosidade',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Ossatura',
    chave: 'genetica_ossatura',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Pelagem',
    chave: 'genetica_pelagem',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Profundidade',
    chave: 'genetica_profundidade',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Racial',
    chave: 'genetica_racial',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Reprodução',
    chave: 'genetica_reproducao',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Temperamento',
    chave: 'genetica_temperamento',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'TT',
    chave: 'genetica_tt',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Úbere',
    chave: 'genetica_ubere',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Umbigo',
    chave: 'genetica_umbigo',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },

  {
    titulo: 'Peso',
    chave: 'pesagem_peso',
    unidade: 'Kg',
    format: 'peso',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'GMD',
    chave: 'pesagem_gmd',
    unidade: 'kg',
    assinaturaPremium: true,
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    format: 'gmd',
    chip: true
  },


  {
    titulo: 'Área',
    chave: 'lotes_movimentacao_area',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Escore Fezes',
    chave: 'lotes_escore_fezes',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },



  {
    titulo: 'Altura Capim',
    chave: 'areas_altura_capim',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Piquete',
    chave: 'areas_pastejo_rotacionado',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Índice',
    chave: 'areas_chuva_qtd',
    tipoFiltro: 'rangeNumeros',
    tamanho: 9,
    unidade: 'mm',
    chip: true
  },
  {
    titulo: 'Hora',
    chave: 'areas_chuva_hora',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },


  {
    titulo: 'Observação',
    chave: 'geral_observacao',
    tipoFiltro: 'texto',
    tamanho: 9,
    chip: true
  },
  {
    titulo: 'Cadastro',
    chave: 'created_at',
    format: 'dataHora',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    chip: true,
    classesExtras: 'chip-extra'
  },
  {
    titulo: 'Atualizado em',
    chave: 'updated_at',
    format: 'dataHora',
    tipoFiltro: 'rangeDatas',
    tamanho: 9,
    chip: true,
    classesExtras: 'chip-extra'
  },


  {
    titulo: 'Mais',
    chave: 'mais',
    tamanho: 2,
    fixaFinal: true,
    tipo: 'opcoes',
    centralizar: true,
    evento: 'opcoes',
    pointer: true,
    chip: true
  }
]