import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { FinancasService } from 'src/app/services/financas.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { CentrosDeCustoEPessoa } from 'src/app/utils/interfaces/financas.interface'

@Component({
  selector: 'app-modal-centro-de-custo',
  templateUrl: './modal-centro-de-custo.component.html',
  styleUrls: ['./modal-centro-de-custo.component.scss']
})
export class ModalCentroDeCustoComponent implements OnInit {
  public centroDeCusto: Partial<CentrosDeCustoEPessoa> = {
    nome: '',
    descricao: ''
  }
  public centroDeCustoProp: CentrosDeCustoEPessoa
  public loading = false
  constructor(
    private modalCtrl: ModalController,
    private financasCtrl: FinancasService,
    private utilsCtrl: IonicUtilsService
  ) {}

  ngOnInit(): void {
    if (this.centroDeCustoProp) {
      this.centroDeCusto = { ...this.centroDeCustoProp }
    }
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  async criarNovoCentroDeCusto(): Promise<void> {
    this.loading = true
    const payload = {
      centro: { ...this.centroDeCusto }
    }
    const [response, error] = await this.financasCtrl.criarCentrosDeCusto(payload)
    this.loading = false

    if (response) {
      await this.modalCtrl.dismiss({ success: true })
      this.utilsCtrl.showToast('Novo centro de custo adicionado', 'bottom')
    }

    if (error) {
      this.utilsCtrl.showToast('Erro ao adicionar novo centro de custo', 'bottom')
    }
  }
}
