import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { versionamento } from 'src/environments/environment.common'

@Component({
  selector: 'app-atualizacoes',
  templateUrl: './atualizacoes.page.html',
  styleUrls: ['./atualizacoes.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AtualizacoesPage implements OnInit {
  versionamento = versionamento

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.modalCtrl.dismiss()
  }
}
