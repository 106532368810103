<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      Notificações
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="modalClose()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="notificacoes-container">
    <div class="carregamento" *ngIf="!notificacoes.length && !carregando">
        <ion-icon name="sad-outline"></ion-icon>
        <p>Nenhuma notificação recebida</p>
    </div>
    <ng-template [ngIf]="!notificacoes.length && carregando">
      <ng-template ngFor let-notificacao [ngForOf]="[1,2,3,4,5,6,7,8]">
        <div class="notificacao">
          <ion-skeleton-text style="width: 20px;height: 20px;margin-right: 9px;" class="icone"></ion-skeleton-text>
          <div class="notificacao-content">
            <h1><ion-skeleton-text [animated]="true" style="width: 140px;margin-bottom: 10px;"></ion-skeleton-text></h1>
            <p><ion-skeleton-text [animated]="true" style="width: 260px;margin-bottom: 10px;"></ion-skeleton-text></p>
            <span><ion-skeleton-text [animated]="true" style="width: 90px;"></ion-skeleton-text></span>
          </div>
        </div>
      </ng-template>
    </ng-template>
    <ng-template ngFor let-notificacao [ngForOf]="notificacoes">
      <div class="notificacao" (click)="abrirNotificacao(notificacao.link, notificacao.tipo)">
        <ion-icon *ngIf="notificacao.link === '/calendario'" name="calendar-clear-outline"></ion-icon>
        <ion-icon *ngIf="notificacao.link === '/aprovacoes'" name="checkmark-circle-outline"></ion-icon>
        <ion-icon *ngIf="notificacao.link === '/assinatura'" name="card-outline"></ion-icon>
        <ion-icon *ngIf="notificacao.tipo === 'link_externo'" name="open-outline"></ion-icon>
        <div class="notificacao-content">
          <h1>{{notificacao.titulo}}</h1>
          <p>{{notificacao.mensagem}}</p>
          <span>{{ notificacao.timestamp }}</span>
        </div>
      </div>
    </ng-template>
  </div>
</ion-content>
