<div class="position-wrapper">
  <ng-template [ngIf]="tooltipLabel">
    <app-tooltip
    [type]="tooltipType"
    [position]="tooltipPosition"
    [label]="tooltipLabel"
    ></app-tooltip>
  </ng-template>
</div>

<div
  *ngIf="!type.includes('manejo')"
  class="vasta-input"
  [class.clear]="mode == 'clear'"
  [ngClass]="type"
  [class.obrigatorio]="obrigatorio"
  [class.disabled]="disabled"
>
  <!-- <ion-icon *ngIf="icon" [name]="icon"></ion-icon> -->
  <ng-template [ngIf]="type == 'checkbox'">
    <div
      class="checkbox-wrapper"
      (click)="valueCheckbox = !valueCheckbox; emitCheckbox()"
      [class.checked]="valueCheckbox"
    >
      <ion-icon [name]="valueCheckbox ? 'checkbox' : 'square'"></ion-icon>
      <span>
        {{ label }}
      </span>
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'button'">
    <span class="label">
      {{ label }}
    </span>
    <div class="input-wrapper" [ngClass]="{ 'input-preenchido': value, disabled }">
      <button class="button-content" [class.filled]="value" (click)="emitButton($event)">
        <ion-icon *ngIf="icon" [name]="icon"></ion-icon> {{ value }}
        <span class="placeholder" *ngIf="!value">{{ placeholder }}</span>
      </button>
      <button class="btn-clear-input" *ngIf="value && !disabled && !hideClear" (click)="limparValorInput()">
        <ion-icon name="close"></ion-icon>
      </button>
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'text'">
    <span class="label">
      {{ label }}
    </span>
    <div class="input-wrapper">
      <input
        [class.filled]="value"
        [(ngModel)]="value"
        (keyup)="emit()"
        (keyup.enter)="emitEnter()"
        (change)="emit()"
        [placeholder]="placeholder"
        [mask]="mask"
        [prefix]="maskPrefix || ''"
        [suffix]="maskSuffix || ''"
        thousandSeparator="."
        decimalMarker=","
        [placeholder]="placeholder"
        [disabled]="disabled"
        #inputFocus
      />
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'number' || type == 'int'">
    <span class="label">
      {{ label }}
    </span>
    <div class="input-wrapper">
      <input
        class="monospace"
        type="text"
        [attr.inputmode]="type == 'number' ? 'decimal' : 'numeric'"
        [class.filled]="value"
        [ngClass]="{ 'remove-arrows-number': value }"
        [(ngModel)]="value"
        (keypress)="emitNumber($event)"
        (keyup)="emit()"
        [mask]="mask"
        [allowNegativeNumbers]="allowNegativeNumbers"
        [prefix]="maskPrefix || ''"
        [suffix]="maskSuffix || ''"
        [placeholder]="placeholder"
        thousandSeparator="."
        decimalMarker=","
        #inputFocus
      />
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'password'">
    <span class="label">
      {{ label }}
    </span>
    <div class="input-wrapper">
      <input
        type="password"
        [class.filled]="value"
        [(ngModel)]="value"
        (change)="emit()"
        (keyup)="emit()"
        [placeholder]="placeholder"
        [mask]="mask"
      />
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'email'">
    <span class="label">
      {{ label }}
    </span>
    <div class="input-wrapper">
      <input type="email" [class.filled]="value" [(ngModel)]="value" (keyup)="emit()" (change)="emit()" [placeholder]="placeholder" />
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'textarea'">
    <span class="label">
      {{ label }}
    </span>
    <div class="input-wrapper">
      <textarea [class.filled]="value" [(ngModel)]="value" (keyup)="emit()" (change)="emit()" [placeholder]="placeholder"></textarea>
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'select'">
    <span class="label">
      {{ label }}
    </span>
    <div class="input-wrapper">
      <ion-icon *ngIf="icon" [name]="icon"></ion-icon>
      <select *ngIf="!isReloadingSelects" [class.filled]="value" [(ngModel)]="value" (change)="emit()">
        <option *ngIf="placeholder" [value]="''">{{ placeholder }}</option>
        <ng-template ngFor let-opcao [ngForOf]="opcoes">
          <option [value]="opcao.value">{{ opcao.label }}</option>
        </ng-template>
      </select>
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'date'">
    <ion-icon *ngIf="icon" [name]="icon"></ion-icon>
    <span class="label">
      {{ label }}
    </span>

    <div class="input-wrapper" [ngClass]="{ 'input-preenchido': value, disabled }">
      <button class="button-content" [class.filled]="value" (click)="abrirVastaCalendar($event)">
        <ion-icon *ngIf="icon" [name]="icon"></ion-icon> {{ value ? (value | timestamp: 'data') : '' }}
        <span class="placeholder" *ngIf="!value">{{ placeholder || 'Selecionar' }}</span>
      </button>
      <button class="btn-clear-input" *ngIf="value && !disabled && !hideClear" (click)="limparValorInput()">
        <ion-icon name="close"></ion-icon>
      </button>
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'month'">
    <ion-icon *ngIf="icon" [name]="icon"></ion-icon>
    <span class="label">
      {{ label }}
    </span>
    <div class="input-wrapper">
      <input
        type="month"
        [class.filled]="value"
        [(ngModel)]="value"
        (keyup)="emit()"
        (change)="emit()"
        [placeholder]="placeholder"
        [max]="max"
      />
    </div>
  </ng-template>
  <ng-template [ngIf]="type == 'time'">
    <span class="label">
      {{ label }}
    </span>
    <div class="input-wrapper">
      <input
        type="time"
        [class.filled]="value"
        [(ngModel)]="value"
        (keyup)="emit()"
        (change)="emit()"
        [placeholder]="placeholder"
      />
    </div>
  </ng-template>
</div>

<ng-template [ngIf]="type == 'manejo-input'">
  <div
    class="manejo-input"
    [ngClass]="{ pendente: !value, disabled: disabled }"
    [ngStyle]="{ width: expand === 'full' ? '100%' : '' }"
  >
    <span class="label">{{ label }}</span>
    <input [placeholder]="placeholder" [(ngModel)]="value" (keyup)="emit()" (change)="emit()" />
  </div>
</ng-template>
<ng-template [ngIf]="type == 'manejo-number' || type == 'manejo-int'">
  <div class="manejo-input" [ngClass]="{ pendente: !value && !opcional }" [ngStyle]="{ width: expand === 'full' ? '100%' : '' }">
    <span class="label">{{ label }}</span>
    <input
      class="monospace"
      type="text"
      inputmode="numeric"
      [class.filled]="value"
      [ngClass]="{ 'remove-arrows-number': value }"
      [(ngModel)]="value"
      (keypress)="emitNumber($event)"
      (keyup)="emit()"
      (ngModelChange)="emit()"
      [mask]="mask"
      [prefix]="maskPrefix || ''"
      [suffix]="maskSuffix || ''"
      thousandSeparator="."
      decimalMarker=","
      [placeholder]="placeholder"
    />
  </div>
</ng-template>
<ng-template [ngIf]="type == 'manejo-button'">
  <div
    class="manejo-button"
    [ngClass]="{ pendente: !value, disabled: disabled }"
    [ngStyle]="{ width: expand === 'full' ? '100%' : '' }"
  >
    <span class="label">{{ label }}</span>
    <button (click)="emitButton($event)">
      <ion-icon *ngIf="icon" [name]="icon"></ion-icon>
      <span>{{ value || placeholder }}</span>
      <ion-icon name="caret-down-outline"></ion-icon>
    </button>
  </div>
</ng-template>
<ng-template [ngIf]="type == 'manejo-select'">
  <div
    class="manejo-select"
    [ngClass]="{ pendente: !value, disabled }"
    [ngStyle]="{ width: expand === 'full' ? '100%' : '' }"
  >
    <span class="label">{{ label }}</span>
    <select *ngIf="!isReloadingSelects" [class.filled]="value" [(ngModel)]="value" (change)="emit()">
      <option *ngIf="placeholder" [value]="''">{{ placeholder }}</option>
      <ng-template ngFor let-opcao [ngForOf]="opcoes">
        <option [value]="opcao.value">{{ opcao.label }}</option>
      </ng-template>
    </select>
  </div>
</ng-template>
<ng-template [ngIf]="type == 'manejo-checkbox'">
  <div
    class="manejo-checkbox"
    [ngClass]="{ checked: valueCheckbox }"
    [ngStyle]="{ width: expand === 'full' ? '100%' : '' }"
    (click)="valueCheckbox = !valueCheckbox; emitCheckbox()"
  >
    <div class="checkbox">
      <ion-icon name="checkmark-sharp"></ion-icon>
    </div>
    <span class="label-checkbox">{{ label }}</span>
  </div>
</ng-template>
<ng-template [ngIf]="type == 'manejo-segment'">
  <div class="manejo-segment" [class.pendente]="!value" [ngStyle]="{ 'min-width': expand === 'full' ? '100%' : '' }">
    <ng-template ngFor let-opcao [ngForOf]="opcoes">
      <button [class.active]="value == opcao.value" (click)="segmentSelect(opcao.value)">{{ opcao.label }}</button>
    </ng-template>
  </div>
</ng-template>
