<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="primary"></ion-menu-button>
    </ion-buttons>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Áreas
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="maximize = !maximize">
        <ion-icon *ngIf="!maximize" name="expand" color="primary"></ion-icon>
        <ion-icon *ngIf="maximize" name="contract" color="primary"></ion-icon>
      </ion-button>
      <ion-button>
        <ion-icon name="help" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <header-tabs
    [tabs]="['Geral', 'Pastejo Rotacionado']"
    [(tabActive)]="abaAtiva"
    (tabActiveChange)="tabChange()"
  ></header-tabs>
</ion-header>
<ion-content>
  <vasta-mapa [class.maximize]="maximize" [areas]="areas" [mostrarQtdAnimais]="true"></vasta-mapa>
  <div [hidden]="abaAtiva !== 'Geral'">
    <cta-buttons>
      <button class="primary permissao-escrita-areas acao-cadastro" (click)="openModalCadastrarArea()">
        <ion-icon name="mais"></ion-icon>
        <span>Nova Área</span>
      </button>
      <button (click)="abrirImportacaoKML()">
        <ion-icon name="cloud-upload"></ion-icon>
        <span>Importar KML/KMZ</span>
      </button>
    </cta-buttons>
    <kpis-cards [cardsInfo]="optionsKpisCard" [valores]="kpisValores" [wrap]="true"></kpis-cards>
    <vasta-tabela
      [config]="config"
      [labels]="labels"
      [campos]="areas"
      [status]="carregamentoAreas"
      [optionsButtons]="optionsButtonsTabela"
      [(paginate)]="paginate"
      (paginateChange)="getAreas()"
      [(novoFiltro)]="novoFiltro"
      [(novaOrdenacao)]="novaOrdenacao"
      (novoFiltroChange)="getAreas()"
      (novaOrdenacaoChange)="getAreas()"
      (selecionado)="irParaDetalhesArea($event)"
      (retentar)="getAreas()"
    >
    </vasta-tabela>
  </div>
  
  <div [hidden]="abaAtiva !== 'Pastejo Rotacionado'">
    <vasta-tabela
      [config]="manejosPastejosRotacionadosConfig"
      [labels]="manejosPastejoRotacionadoLabels"
      [campos]="manejosPastejoRotacionado"
      [status]="manejosPastejoRotacionadoCarregamento"
      [(paginate)]="manejosPastejoRotacionadoPaginate"
      (paginateChange)="getManejosPastejoRotacionado()"
      [(novoFiltro)]="manejosPastejoRotacionadoFiltro"
      [(novaOrdenacao)]="manejosPastejoRotacionadoNovaOrdenacao"
      (novoFiltroChange)="getManejosPastejoRotacionado()"
      (novaOrdenacaoChange)="getManejosPastejoRotacionado()"
      (selecionado)="abrirManejosDoDia($event)"
      (retentar)="getManejosPastejoRotacionado()"
    >
    </vasta-tabela>
  </div>

  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
</ion-content>
