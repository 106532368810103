import { Component, OnInit } from '@angular/core'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IMetas } from 'src/app/utils/interfaces/propriedades.interface'

@Component({
  selector: 'checklist-inicial',
  templateUrl: './checklist-inicial.component.html',
  styleUrls: ['./checklist-inicial.component.scss']
})
export class ChecklistInicialComponent implements OnInit {
  public qtd_concluidos = 0
  public mostrar = false
  public lista = [
    {
      identificador: 'criar_propriedade',
      titulo: 'Crie sua primeira propriedade',
      concluido: false
    },
    {
      identificador: 'criar_area',
      titulo: 'Crie sua primeira área',
      concluido: false
    },
    {
      identificador: 'criar_lote',
      titulo: 'Crie seu primeiro lote',
      concluido: false
    },
    {
      identificador: 'criar_animal',
      titulo: 'Crie seu primeiro bovino',
      concluido: false
    },
    {
      identificador: 'criar_evento',
      titulo: 'Agende seu primeiro evento de calendário',
      concluido: false
    }
  ]
  public metas: IMetas
  constructor(private propriedadesCtrl: PropriedadesService) {}

  async ngOnInit(): Promise<void> {
    this.metas = await this.buscarMetas()
    this.verificarMetas(this.metas)
  }

  esconderCard(): void {
    this.mostrar = false
  }

  async buscarMetas(): Promise<IMetas> {
    const [response, error] = await this.propriedadesCtrl.getMetas()

    if (response) return response

    if (error) console.log(error)
  }

  verificarMetas(metas: IMetas): void {
    this.lista.forEach((item) => {
      Object.keys(metas).forEach((meta) => {
        if (item.identificador === meta && metas[meta]) {
          item.concluido = true
          this.qtd_concluidos++
        }
      })
      if (!item.concluido) this.mostrar = true
      if (this.qtd_concluidos === this.lista.length) this.esconderCard()
    })
  }
}
