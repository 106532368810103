import { Component, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { IonSearchbar } from '@ionic/angular'
import { DadosLinhaTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { GeralService } from 'src/app/services/geral.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ColunasAreas, ColunasLotes } from 'src/app/utils/configuracoes-padrao'
import { IAnimal } from 'src/app/utils/interfaces/animais.interface'
import { IArea } from 'src/app/utils/interfaces/areas.interface'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { IPesquisaGeral } from 'src/app/utils/interfaces/pesquisa-geral.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'

@Component({
  selector: 'app-modal-menu-pesquisa',
  templateUrl: './modal-menu-pesquisa.page.html',
  styleUrls: ['./modal-menu-pesquisa.page.scss']
})
export class ModalMenuPesquisaPage {
  public itensPesquisa: IPesquisaGeral = {
    animais: [],
    lotes: [],
    areas: []
  }
  public loading: boolean
  public termo: string = ''

  public labelsLotes = ColunasLotes
  public labelsAreas = ColunasAreas

  public configAnimais: ConfigTabela = {
    toolbar: false,
    filtrosEspecificos: false,
    selecao: {
      ativar: false
    },
    exibeTotais: false,
    selecionavel: false,
    excluirColunas: ['checkbox', 'selecionar', 'mais']
  }

  public configLotes: ConfigTabela = {
    toolbar: false,
    filtrosEspecificos: false,
    selecao: {
      ativar: false
    },
    exibeTotais: false,
    selecionavel: false,
    excluirColunas: ['checkbox', 'selecionar', 'mais', 'qtd_animais_na_data']
  }
  
  public configAreas: ConfigTabela = {
    toolbar: false,
    filtrosEspecificos: false,
    selecao: {
      ativar: false
    },
    exibeTotais: false,
    selecionavel: false,
    excluirColunas: ['checkbox', 'selecionar', 'mais', 'qtd_animais_na_data']
  }

  @ViewChild('searchBar', { static: false }) searchBar: IonSearchbar
  constructor(
    private geralCtrl: GeralService,
    private utilsCtrl: IonicUtilsService,
    private router: Router
  ) {
  }

  ionViewDidEnter(): void {
    setTimeout(() => {
      this.searchBar.setFocus()
    }, 100)
  }

  async pesquisar(termo: string): Promise<void> {
    this.loading = true

    if (termo.trim() && termo.length >= 1) {
      const [response, error] = await this.geralCtrl.pesquisaGeral(termo)

      if (response) {
        this.itensPesquisa = response
      }

      if (error) console.error(error)
    } else {
      this.itensPesquisa = null
    }
    this.loading = false
  }

  abrirAnimal(linha: DadosLinhaTabela): void {
    const animal = linha.original as unknown as IAnimal
    this.dismiss()
    this.router.navigate(['animais', animal.hash])
  }

  abrirLote(linha: DadosLinhaTabela): void {
    const lote = linha.original as unknown as ILote
    this.dismiss()
    this.router.navigate(['lotes', lote.hash])
  }

  abrirArea(linha: DadosLinhaTabela): void {
    const area = linha.original as unknown as IArea
    this.dismiss()
    this.router.navigate(['areas', area.hash])
  }

  dismiss(): void {
    this.utilsCtrl.dismissModal()
  }
}
