<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/manejos"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      <div>
        Importar Pesagens
        <ion-spinner name="dots" *ngIf="loadingNumeracao"></ion-spinner>
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div #spreadsheetPesagens></div>
</ion-content>

<ion-footer>
  <cta-buttons>
    <button class="primary" (click)="salvarPesagens()">
      <ion-icon name="checkmark"></ion-icon>
      <span>Cadastrar</span>
    </button>
  </cta-buttons>

  <cta-buttons>
    <button (click)="adicionarMaisItens()">
      <ion-icon name="add-circle"></ion-icon>
      <span class="hideMobile">Inserir</span>
      <span class="hideDesktop">10 linhas</span>
    </button>
    <input [(ngModel)]="adicionarMais" class="linhas hideMobile" />
    <span class="label hideMobile">linhas</span>
  </cta-buttons>
</ion-footer>
