import { Component, OnInit } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { OpcoesBotoesComponent } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { IManejoGeneticaUnitario, IManejoGeralUnitario, ManejoOrdenha, ManejoPesagem, ManejoReprodutivoUnitario, ManejoSanitarioUnitario } from 'src/app/services/manejos.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IAnimal, IReprodutorExterno } from 'src/app/utils/interfaces/animais.interface'
import { IArea, IAreaPiquete } from 'src/app/utils/interfaces/areas.interface'
import { EstoqueBiologico, EstoqueFarmacia, EstoqueNutricional } from 'src/app/utils/interfaces/estoque.interface'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { IPropriedade } from 'src/app/utils/interfaces/propriedades.interface'
import { ColunasTabela, ConfigTabela, IKpisCard } from 'src/app/utils/interfaces/tabela-colunas'
import { ListaManejosProp } from '../manejos-disponiveis/manejos-disponiveis.component'
import { ManejoArea } from 'src/app/utils/interfaces/manejos.interface'

const COLUNAS_ANIMAIS: ColunasTabela[] = [
  {
    chave: 'sexo',
    titulo: 'Sexo',
    tipo: 'icon-only',
    tipoFiltro: 'preenchidos',
    labelFormat: 'labelsSexo',
    iconeCase: {
      macho: {
        class: 'male'
      },
      femea: {
        class: 'female'
      }
    },
    centralizar: true,
    fixa: true,
    classesExtra: 'mobile-icon-only'
  },
  {
    titulo: 'Número',
    chave: 'numeracao',
    tamanho: 7,
    tipoFiltro: 'texto',
    placeholder: 'Sem número',
    fixa: true,
    classesExtra: 'mobile-bold mobile-width-auto'
  },
  {
    titulo: 'Brinco Elet.',
    chave: 'meta_nbrinco',
    placeholder: 'Sem brinco',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto reverse-ellipsis'
  },
  {
    titulo: 'Nome',
    chave: 'nome',
    placeholder: 'Sem nome',
    tipoFiltro: 'texto',
    tamanho: 7,
    classesExtra: 'mobile-width-auto'
  },
]

const COLUNAS_LOTES: ColunasTabela[] = [
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    small: 'label_confinamento',
    tamanho: 12,
    classesExtra: 'mobile-bold mobile-width-two-thirds'
  },
  // {
  //   titulo: 'Animais',
  //   chave: 'qtd_animais',
  //   tipoFiltro: 'rangeNumeros',
  //   placeholder: '0',
  //   tamanho: 6,
  //   classesExtra: 'mobile-before-animais'
  // },
  // {
  //   titulo: 'Entrada na área',
  //   chave: 'data_entrada_area',
  //   format: 'data',
  //   tipoFiltro: 'rangeDatas',
  //   placeholder: 'N/A',
  //   tamanho: 9,
  //   classesExtra: 'mobile-hide'
  // },
  // {
  //   titulo: 'Área',
  //   chave: 'nome_area',
  //   tipoFiltro: 'preenchidos',
  //   placeholder: 'Sem área',
  //   tamanho: 10,
  //   classesExtra: 'mobile-before-pin mobile-width-auto'
  // },
  // {
  //   titulo: 'Finalidade',
  //   chave: 'finalidade',
  //   tipoFiltro: 'preenchidos',
  //   placeholder: '-',
  //   labelFormat: 'labelsFinalidade',
  //   tamanho: 7,
  //   classesExtra: 'mobile-line-break'
  // },
]

const COLUNAS_AREAS: ColunasTabela[] = [
  {
    titulo: 'Nome',
    chave: 'nome',
    tipoFiltro: 'texto',
    tamanho: 12,
    classesExtra: 'mobile-bold'
  },
  // {
  //   titulo: 'Lotes incluídos',
  //   chave: 'lotes_nomes',
  //   placeholder: 'Nenhum lote',
  //   tipoFiltro: 'preenchidosMultiplos',
  //   tamanho: 8
  // },
  // {
  //   titulo: 'Tamanho',
  //   chave: 'tamanho',
  //   tipoFiltro: 'rangeNumeros',
  //   placeholder: 'Não preenchido',
  //   unidade: 'ha',
  //   tamanho: 7
  // },
  // {
  //   titulo: 'Animais',
  //   chave: 'qtd_animais',
  //   tipoFiltro: 'rangeNumeros',
  //   placeholder: '0',
  //   tamanho: 6
  // },
]

const COLUNAS_PROCEDIMENTOS: { [key: string]: ColunasTabela[] } = {
  pesagem: [
    {
      titulo: 'Peso',
      chave: 'peso',
      unidade: 'Kg',
      format: 'peso',
      tamanho: 7,
    },
    {
      titulo: 'GMD',
      chave: 'gmd',
      placeholder: '0',
      assinaturaPremium: true,
      unidade: 'Kg',
      format: 'gmd',
      tamanho: 7
    }
  ],
  ordenha: [
    {
      titulo: 'Primeira Ordenha',
      chave: 'primeira_ordenha',
      tamanho: 9
    },
    {
      titulo: 'Segunda Ordenha',
      chave: 'segunda_ordenha',
      tamanho: 9
    },
    {
      titulo: 'Terceira Ordenha',
      chave: 'terceira_ordenha',
      tamanho: 9
    }
  ],
  'movimentacao-lote': [
    {
      titulo: 'Lote Destino',
      chave: 'hash_lote',
      tamanho: 9
    }
  ],
  'venda': [
    {
      titulo: 'Venda',
      chave: 'novo_status',
      tamanho: 9
    }
  ],
  'movimentacao-confinamento': [
    {
      titulo: 'Confinamento Destino',
      chave: 'hash_confinamento',
      tamanho: 10
    }
  ],
  'numero-controle': [
    {
      titulo: 'Número',
      chave: 'numero_novo',
      tamanho: 7
    }
  ],
  observacao: [
    {
      titulo: 'Observação',
      chave: 'observacao',
      tamanho: 15
    }
  ],
  'numero-brinco-eletronico': [
    {
      titulo: 'Brinco Elet.',
      chave: 'brinco_eletronico',
      tamanho: 7
    }
  ],
  'morte': [
    {
      titulo: 'Causa',
      chave: 'morte_causa',
      tamanho: 7,
      labelFormat: 'labelsCausaMorte'
    }
  ],
  'perda': [
    {
      titulo: 'Observações Perda',
      chave: 'perda_observacoes',
      tamanho: 9,
      labelFormat: 'labelsManejosGerais'
    }
  ],
  secagem: [
    {
      titulo: 'Observações Secagem',
      chave: 'secagem_observacoes',
      tamanho: 10,
      labelFormat: 'labelsManejosGerais'
    }
  ],
  'transferencia-propriedade': [
    {
      titulo: 'Propriedade Destino',
      chave: 'transf_propriedade_id',
      tamanho: 9
    },
    {
      titulo: 'Lote Destino',
      chave: 'transf_propriedade_hash_lote',
      tamanho: 9
    }
  ],
  'cio': [
    {
      titulo: 'Resultado',
      chave: 'cio_resultado',
      labelFormat: 'labelsExposicaoDG',
      tamanho: 7
    }
  ],
  'implante': [
    {
      titulo: 'Implante',
      chave: 'implante_aplica',
      labelFormat: 'labelsManejoRealizado',
      tamanho: 9
    }
  ],
  'implante-remocao': [
    {
      titulo: 'Remoção Implante',
      chave: 'implante_remove',
      labelFormat: 'labelsManejoRealizado',
      tamanho: 9
    }
  ],
  'dg': [
    {
      titulo: 'Resultado DG',
      chave: 'dg_resultado',
      labelFormat: 'labelsExposicaoDG',
      tamanho: 7
    },
    {
      titulo: 'Período Gestacional',
      chave: 'dg_periodo_gestacional',
      unidade: 'dias',
      tamanho: 10
    },
    {
      titulo: 'Previsão de Parto',
      chave: 'dg_previsao_de_parto',
      format: 'data',
      tamanho: 9
    }
  ],
  'inseminacao': [
    {
      titulo: 'Sêmen',
      chave: 'inseminacao_semen',
      tamanho: 8
    },
    {
      titulo: 'Embrião',
      chave: 'inseminacao_embriao',
      tamanho: 8
    },
  ],
  'monta-natural': [
    {
      titulo: 'Reprodutor',
      chave: 'cobertura_hash_reprodutor',
      tamanho: 9
    },
    {
      titulo: 'Reprodutor Externo',
      chave: 'cobertura_hash_reprodutor_externo',
      tamanho: 9
    }
  ],
  'parto': [
    {
      titulo: 'Cria 1',
      chave: 'parto_hash_cria1',
      tamanho: 7
    },
    {
      titulo: 'Cria 2',
      chave: 'parto_hash_cria2',
      tamanho: 7
    }
  ],
  'desmame': [
    {
      titulo: 'Detalhes Desmame',
      chave: 'desmame_detalhes',
      labelFormat: 'labelsManejoRealizado',
      tamanho: 9
    }
  ],
  'aborto': [
    {
      titulo: 'Detalhes Aborto',
      chave: 'aborto_detalhes',
      tamanho: 9,
      labelFormat: 'labelsManejoRealizado',
    }
  ],
  'castracao': [
    {
      titulo: 'Detalhes Castração',
      chave: 'castracao_detalhes',
      labelFormat: 'labelsManejoRealizado',
      tamanho: 9
    }
  ],
  'descarte': [
    {
      titulo: 'Detalhes Descarte',
      chave: 'descarte_resultado',
      labelFormat: 'labelsManejoRealizado',
      tamanho: 9
    }
  ],
  'escore': [
    {
      titulo: 'Escore Corporal',
      chave: 'escore_corporal',
      tamanho: 7
    }
  ],
  'movimentacao-entre-areas': [
    {
      titulo: 'Área Destino',
      chave: 'hash_area_destino',
      tamanho: 7
    }
  ],
  'aprumos': [
    {
      titulo: 'Escore',
      chave: 'aprumos',
      tamanho: 10
    }
  ],
  'boca': [
    {
      titulo: 'Escore',
      chave: 'boca',
      tamanho: 10
    }
  ],
  'casco': [
    {
      titulo: 'Escore',
      chave: 'casco',
      tamanho: 10
    }
  ],
  'chanfro': [
    {
      titulo: 'Escore',
      chave: 'chanfro',
      tamanho: 10
    }
  ],
  'perimetro-escrotal': [
    {
      titulo: 'Perímetro Escrotal',
      chave: 'perimetro_escrotal',
      tamanho: 10
    }
  ],
  'frame': [
    {
      titulo: 'Escore',
      chave: 'frame',
      tamanho: 10
    }
  ],
  'garupa': [
    {
      titulo: 'Escore',
      chave: 'garupa',
      tamanho: 10
    }
  ],
  'insercao-cauda': [
    {
      titulo: 'Escore',
      chave: 'insercao_cauda',
      tamanho: 10
    }
  ],
  'linha-dorso': [
    {
      titulo: 'Escore',
      chave: 'linha_dorso',
      tamanho: 10
    }
  ],
  'musculosidade': [
    {
      titulo: 'Escore',
      chave: 'musculosidade',
      tamanho: 10
    }
  ],
  'ossatura': [
    {
      titulo: 'Escore',
      chave: 'ossatura',
      tamanho: 10
    }
  ],
  'pelagem': [
    {
      titulo: 'Escore',
      chave: 'pelagem',
      tamanho: 10
    }
  ],
  'profundidade': [
    {
      titulo: 'Escore',
      chave: 'profundidade',
      tamanho: 10
    }
  ],
  'racial': [
    {
      titulo: 'Escore',
      chave: 'racial',
      tamanho: 10
    }
  ],
  'reproducao': [
    {
      titulo: 'Escore',
      chave: 'reproducao',
      tamanho: 10
    }
  ],
  'temperamento': [
    {
      titulo: 'Escore',
      chave: 'temperamento',
      tamanho: 10
    }
  ],
  'torcao-testicular': [
    {
      titulo: 'Escore',
      chave: 'torcao_testicular',
      tamanho: 10
    }
  ],
  'ubere': [
    {
      titulo: 'Escore',
      chave: 'ubere',
      tamanho: 10
    }
  ],
  'umbigo': [
    {
      titulo: 'Escore',
      chave: 'umbigo',
      tamanho: 10
    }
  ],
  'altura-capim': [
    {
      titulo: 'Piquete',
      chave: 'piquete_nome',
      tamanho: 10
    },
    {
      titulo: 'Altura Capim',
      chave: 'altura_capim',
      tamanho: 10,
      unidade: 'cm'
    }
  ],
  'pastejo-rotacionado': [
    {
      titulo: 'Piquete Ativo',
      chave: 'piquete_nome',
      tamanho: 10
    }
  ],
  'escore-fezes': [
    {
      titulo: 'Escore de Fezes',
      chave: 'escore_fezes',
      tamanho: 10
    }
  ],
  'chuva': [
    {
      titulo: 'Quantidade',
      chave: 'chuva_quantidade',
      tamanho: 10
    },
    {
      titulo: 'Hora',
      chave: 'chuva_hora',
      tamanho: 10,
      format: 'hora'
    }
  ]
}

@Component({
  selector: 'app-relatorio-manejos',
  templateUrl: './relatorio-manejos.component.html',
  styleUrls: ['./relatorio-manejos.component.scss'],
})
export class RelatorioManejosComponent implements OnInit {
  public dadosManejos: {
    [key: string]: {
      hash: string
      nutricao?: { [key: string]: { quantidade: number; duracao: number; edited: boolean; data_final: string } }
      pesagem?: {
        pesagem?: ManejoPesagem
      }
      ordenha?: {
        ordenha?: ManejoOrdenha
      }
      reprodutivo?: {
        [key: string]: ManejoReprodutivoUnitario
      }
      sanitario?: {
        [key: string]: ManejoSanitarioUnitario
      }
      geral?: {
        [key: string]: IManejoGeralUnitario
      }
      genetica?: {
        [key: string]: IManejoGeneticaUnitario
      }
      area?: {
        [key: string]: ManejoArea
      }
    }
  } = {}

  public abaAtiva: 'Animais' | 'Lotes' | 'Áreas' = 'Animais'
  public listaAtiva = null
  public listaAtivaExibicao = null

  public dadosManejosAgrupados: {}

  public resumos: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } } = {}

  public manejosDisponiveis: ListaManejosProp[] = []

  public dataManejo: string

  public colunas = null

  public optionsKpisCard: IKpisCard[] = []

  public optionsKpisCardPorTipoManejo: { [key: string]: IKpisCard[] } = {}

  public filtroSelecionado: { procedimento: string, titulo: string } = { procedimento: '', titulo: '' }

  public config: ConfigTabela = {
    toolbar: true,
    impressaoTitulo: 'Relatório de Manejos',
    ocultarFiltros: true,
    ativarImpressao: true,
    ativarDownload: true,
    excluirColunas: ['novo_status']
  }

  public lotesAssociados: { [key: string]: ILote } = {}

  public areasAssociadas: { [key: string]: IArea } = {}

  public piquetesAssociados: { [key: string]: IAreaPiquete } = {}

  public propriedadesAssociadas: IPropriedade[] = []

  public estoquesAssociados: { [key: string]: EstoqueBiologico } = {}

  public animaisAssociados: { [key: string]: IAnimal } = {}
  public animaisExternosAssociados: { [key: string]: IReprodutorExterno } = {}

  private estoqueNutricional: {
    animais: EstoqueNutricional[],
    lotes: EstoqueNutricional[],
    areas: EstoqueNutricional[],
  } = {
    animais: [],
    lotes: [],
    areas: [],
  }

  private estoqueSanitario: {
    animais: EstoqueFarmacia[],
    lotes: EstoqueFarmacia[],
    areas: EstoqueFarmacia[],
  } = {
    animais: [],
    lotes: [],
    areas: [],
  }

  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private propriedadesCtrl: PropriedadesService,
    private planoBasicoCtrl: PlanoBasicoService,
  ) { }

  ngOnInit(): void {
    const controleNomeNumero = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais
    const exibirNBrinco = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.mostra_brinco

    if (!exibirNBrinco) {
      this.config.excluirColunas.push('meta_nbrinco')
    }

    if (controleNomeNumero == 'numero') {
      this.config.excluirColunas.push('nome')
    }

    if (controleNomeNumero == 'nome') {
      this.config.excluirColunas.push('numeracao')
    }

    this.colunas = {
      'Animais': COLUNAS_ANIMAIS,
      'Lotes': COLUNAS_LOTES,
      'Áreas': COLUNAS_AREAS
    }[this.abaAtiva]

    if (this.listaAtiva) {
      this.listaAtivaExibicao = [...this.listaAtiva]
    }

    this.manejosDisponiveis.forEach(manejo => {
      this.preencherKpisCardProcedimentos(manejo.procedimento)
    })

    this.filtroSelecionado = {
      procedimento: this.manejosDisponiveis[0].procedimento,
      titulo: this.manejosDisponiveis[0].nome
    }

    this.filtrarPorProcedimento()
  }

  async popoverSelecionarProcedimentoParaFiltrar(event: Event): Promise<void> {
    const options = this.manejosDisponiveis.map(manejo => {
      return {
        chave: manejo.procedimento,
        titulo: manejo.tipo === 'sanitario' ? manejo.descricao : manejo.nome,
      }
    })

    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: {
        options
      },
      event,
      reference: 'trigger',
    })

    await popover.present()

    const { data } = await popover.onWillDismiss()
    if (data) {
      this.filtroSelecionado.procedimento = data.chave
      this.filtroSelecionado.titulo = data.titulo

      this.filtrarPorProcedimento()
    }
  }

  filtrarPorProcedimento(): void {
    this.config.impressaoTitulo = `Relatório de Manejos - ${this.filtroSelecionado.titulo}`

    const colunaDefault = {
      'Animais': COLUNAS_ANIMAIS,
      'Lotes': COLUNAS_LOTES,
      'Áreas': COLUNAS_AREAS
    }[this.abaAtiva]

    const estoqueNutricionalAtivo = {
      'Animais': this.estoqueNutricional.animais,
      'Lotes': this.estoqueNutricional.lotes,
      'Áreas': this.estoqueNutricional.areas
    }[this.abaAtiva] || []

    const estoqueSanitarioAtivo = {
      'Animais': this.estoqueSanitario.animais,
      'Lotes': this.estoqueSanitario.lotes,
      'Áreas': this.estoqueSanitario.areas
    }[this.abaAtiva] || []

    this.colunas = [...colunaDefault]

    if (COLUNAS_PROCEDIMENTOS[this.filtroSelecionado.procedimento]) {
      COLUNAS_PROCEDIMENTOS[this.filtroSelecionado.procedimento].forEach(coluna => {
        this.colunas.push(coluna)
      })
    } else if (this.filtroSelecionado.procedimento === 'nutricao') {
      this.colunas.push({
        titulo: 'Quantidade',
        chave: 'quantidade',
        unidade: 'Kg',
        format: 'peso',
        tamanho: 7
      })
      this.colunas.push({
        titulo: 'Custo',
        chave: 'custo',
        format: 'dinheiro',
        tamanho: 7
      })
    } else if (this.filtroSelecionado.procedimento === 'sanitarioEstoque') {
      this.colunas.push({
        titulo: 'Quantidade',
        chave: 'quantidade',
        // unidade: 'Kg',
        format: 'peso',
        tamanho: 7
      })
      this.colunas.push({
        titulo: 'Custo',
        chave: 'custo',
        format: 'dinheiro',
        tamanho: 7
      })
    }

    if (this.filtroSelecionado.procedimento !== 'observacao') {
      this.colunas.push({
        titulo: 'Observação',
        chave: 'observacao',
        placeholder: 'Sem observação',
        tamanho: 15
      })
    }

    this.listaAtivaExibicao = [...this.listaAtiva]

    // filtrar animais por procedimento
    // Preencher campos do procedimento
    const animaisFiltrados = []
    this.listaAtiva.map(animal => {
      const hash = animal.hash

      Object.keys(this.dadosManejos[hash]).map((key) => {
        if (key !== 'hash') {

          let nutricao = {}
          let sanitario = {}
          let sanitarioEstoque = {}
          let outros = {}

          if (COLUNAS_PROCEDIMENTOS[this.filtroSelecionado.procedimento] && this.filtroSelecionado.procedimento !== 'altura-capim' && this.filtroSelecionado.procedimento !== 'pastejo-rotacionado') {
            const campos = COLUNAS_PROCEDIMENTOS[this.filtroSelecionado.procedimento]?.map(coluna => {
              return {
                [coluna.chave]: this.formatarValorCampo(this.dadosManejos[hash][key][this.filtroSelecionado.procedimento]?.[coluna.chave], coluna.chave),
                observacao: this.dadosManejos[hash][key][this.filtroSelecionado.procedimento]?.observacao,
              }
            })
            outros = Object.assign({}, ...campos)
          } else if (this.filtroSelecionado.procedimento === 'altura-capim' && this.dadosManejos[hash][key]['altura-capim']) {
            const hashsPiquetes = Object.keys(this.dadosManejos[hash][key]['altura-capim'])
            hashsPiquetes.forEach(hashPiquete => {
              outros = {
                piquete_nome: this.piquetesAssociados[hashPiquete].nome,
                altura_capim: this.dadosManejos[hash][key]['altura-capim'][hashPiquete]?.altura_capim,
                observacao: this.dadosManejos[hash][key]['altura-capim'][hashPiquete]?.observacao,
              }
            })
          } else if (this.filtroSelecionado.procedimento === 'pastejo-rotacionado' && this.dadosManejos[hash][key]['pastejo-rotacionado']) {
            outros = {
              piquete_nome: this.piquetesAssociados?.[this.dadosManejos[hash][key]['pastejo-rotacionado']?.hash_piquete]?.nome,
              observacao: this.dadosManejos[hash][key]['pastejo-rotacionado']?.observacao,
            }
          } else if (this.filtroSelecionado.procedimento === 'nutricao') {
            const procedimentosNutricao = Object.keys(this.dadosManejos[hash][key])
            const quantidadeTotalItensNutricao = procedimentosNutricao.reduce((soma, procedimento) => {
              return soma + Number(this.dadosManejos[hash][key][procedimento]?.quantidade)
            }, 0)
            const custoTotalItensNutricao = procedimentosNutricao.reduce((soma, procedimento) => {
              const valorUnitario = estoqueNutricionalAtivo.find(estoque => estoque.hash === procedimento)?.extra_custo_na_data || 0
              return soma + (Number(valorUnitario) * Number(this.dadosManejos[hash][key][procedimento]?.quantidade))
            }, 0)

            procedimentosNutricao.forEach(() => {
              nutricao = {
                custo: custoTotalItensNutricao,
                quantidade: quantidadeTotalItensNutricao,
                observacao: this.dadosManejos[hash][key][this.filtroSelecionado.procedimento]?.observacao,
              }
            })
          } else if (this.filtroSelecionado.procedimento === 'sanitarioEstoque') {
            const procedimentosSanitarioEstoque = Object.keys(this.dadosManejos[hash][key])
            const quantidadeTotalItensSanitario = procedimentosSanitarioEstoque.reduce((soma, procedimento) => {
              return soma + Number(this.dadosManejos[hash][key][procedimento]?.quantidade)
            }, 0)
            const custoTotalItensSanitario = procedimentosSanitarioEstoque.reduce((soma, procedimento) => {
              const valorUnitario = estoqueSanitarioAtivo.find(estoque => estoque.hash === procedimento)?.extra_custo_na_data || 0
              return soma + (Number(valorUnitario) * Number(this.dadosManejos[hash][key][procedimento]?.quantidade))
            }, 0)

            procedimentosSanitarioEstoque.forEach(() => {
              sanitarioEstoque = {
                custo: custoTotalItensSanitario,
                quantidade: quantidadeTotalItensSanitario,
                observacao: this.dadosManejos[hash][key][this.filtroSelecionado.procedimento]?.observacao,
              }
            })
          }
          else {
            sanitario = {
              aplicado: this.dadosManejos[hash][key][this.filtroSelecionado.procedimento]?.aplicado,
              observacao: this.dadosManejos[hash][key][this.filtroSelecionado.procedimento]?.observacao
            }
          }

          let valoresCamposProcedimento = null
          if (key === 'sanitario') {
            valoresCamposProcedimento = sanitario
          } else if (key === 'nutricao') {
            valoresCamposProcedimento = nutricao
          } else if (key === 'sanitarioEstoque') {
            valoresCamposProcedimento = sanitarioEstoque
          } else {
            valoresCamposProcedimento = outros
          }

          const hasSomeCampoValido = Object.keys(valoresCamposProcedimento).some(key => valoresCamposProcedimento[key])

          if (hasSomeCampoValido) {
            this.listaAtivaExibicao.forEach(animalExibicao => {
              if (animalExibicao.hash === hash) {
                animalExibicao = {
                  ...animalExibicao,
                  ...valoresCamposProcedimento
                }

                animaisFiltrados.push(animalExibicao)
              }
            })
          }
        }
      })
    })

    this.listaAtivaExibicao = [...animaisFiltrados]
    this.config.impressaoKpiCardConfig = this.optionsKpisCardPorTipoManejo[this.filtroSelecionado.procedimento]
  }

  preencherKpisCardProcedimentos(procedimento: string): void {
    const resumoProcedimento = this.resumos[procedimento]
    const optionsKpisCardProcedimento: IKpisCard[] = []

    optionsKpisCardProcedimento.push({
      key: 'qtd',
      titulo: 'Quantidade',
      valor: resumoProcedimento.qtd,
      formatacao: 'number'
    })

    resumoProcedimento.totais.map(item => {
      optionsKpisCardProcedimento.push({
        key: item.chave,
        titulo: item.chave,
        valor: item.valor as string | number,
      })
    })

    this.optionsKpisCardPorTipoManejo[procedimento] = optionsKpisCardProcedimento || []

    if (this.planoBasicoCtrl.isPlanoBasico && !this.propriedadesCtrl.isPropriedadeExemploAtivada) {
      this.planoBasicoCtrl.removerChavesEValoresDosIndicadoresSePlanoForBasico(this.optionsKpisCardPorTipoManejo[procedimento])
    }
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  formatarValorCampo(valor: string | number, chaveCampo: string): string | number {
    return {
      'hash_lote': this.lotesAssociados[valor]?.nome,
      'hash_confinamento': this.lotesAssociados[valor]?.nome,
      'transf_propriedade_id': this.propriedadesAssociadas.find(prop => Number(prop.id) === Number(valor))?.nome,
      'transf_propriedade_hash_lote': this.lotesAssociados[valor]?.nome,
      'inseminacao_semen': this.estoquesAssociados[valor]?.touro_nome,
      'inseminacao_embriao': this.estoquesAssociados[valor]?.touro_nome,
      'cobertura_hash_reprodutor': this.animaisAssociados[valor]?.nome || this.animaisAssociados[valor]?.numeracao,
      'cobertura_hash_reprodutor_externo': this.animaisAssociados[valor]?.nome || this.animaisAssociados[valor]?.numeracao,
      'parto_hash_cria1': this.animaisAssociados[valor]?.nome || this.animaisAssociados[valor]?.numeracao,
      'parto_hash_cria2': this.animaisAssociados[valor]?.nome || this.animaisAssociados[valor]?.numeracao,
      'hash_area_destino': this.areasAssociadas[valor]?.nome
    }[chaveCampo] || valor
  }
}
