<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Perímetro Escrotal</div>

    <vasta-input
      label="Perímetro"
      type="manejo-number"
      maskSuffix=" cm"
      mask="separator.3"
      [(value)]="dados.perimetro_escrotal"
      (valueChange)="dados.edited = true; emit()"
    ></vasta-input>

    <div class="manejo-info" *ngIf="ultimoPerimetro">
      <span class="label">Último Perímetro</span>
      <p class="content">{{ ultimoPerimetro }}</p>
    </div>

    <div class="manejo-info" *ngIf="ultimoPerimetroData">
      <span class="label">Data Último Perímetro</span>
      <p class="content">{{ ultimoPerimetroData | timestamp: 'data' }}</p>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.perimetro_escrotal">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
