/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import { AreasService } from 'src/app/services/areas.service'

@Component({
  selector: 'app-desenhar-area',
  templateUrl: './desenhar-area.component.html',
  styleUrls: ['./desenhar-area.component.scss']
})
export class DesenharAreaComponent {
  @ViewChild('map') mapElement: ElementRef
  public map: google.maps.Map

  public isPiquete = false
  public selecionaArea = false

  public polygons = []
  public polygon

  public mapa_search: HTMLInputElement

  public area_cadastrada = false

  public localizando = false
  public outrasAreasOuPiquetes: {
    area: number[]
    nome: string
  }[] = []
  public tamanho: number
  public area: number[]
  public coordenadas_centrais: {lat?: number, lng?: number} = {}

  public markers = []

  public drawingManager

  public outrasAreas: number[][] = []
  public areaPrincipal: number[] = []
  public hashArea: string = ''
  public hash_not_in: string[] = []

  constructor(private areasCtrl: AreasService, public cd: ChangeDetectorRef, private modalCtrl: ModalController) {
    VastaRX.getState('bloquear-desenho', (a) => {
      if (!a) return
      if (this.drawingManager) this.drawingManager.setOptions({ drawingMode: null, drawingControl: false })
    })
  }

  async ionViewDidEnter(): Promise<void> {
    this.exibirMapa()

    if (this.areaPrincipal?.length) {
      this.renderizaAreaPrincipal()
    }

    if (this.isPiquete) {
      const [response, error] = await this.areasCtrl.getPiquetes({
        filtros: { hashs_areas: [this.hashArea] }
      })
      if (response) {
        response.piquetes?.filter(p => !this.hash_not_in.includes(p.hash))?.map(area => {
          this.outrasAreasOuPiquetes.push({
            nome: area.nome,
            area: area.area
          })
        })
      }
      if (error) return
    } else {
      const [response, error] = await this.areasCtrl.getAreas(this.hash_not_in ? {
        filtros: {
          hash_not_in: this.hash_not_in
        }
      } : undefined)
      if (response) {
        response.dados?.map(area => {
          this.outrasAreasOuPiquetes.push({
            nome: area.nome,
            area: area.area
          })
        })
      }
      if (error) return
    }

    let coordenadasIniciais = null

    for (let area of this.outrasAreasOuPiquetes) {
      if (area?.area?.length > 1) {
        coordenadasIniciais = { lat: Number(area?.area[0]), lng: Number(area?.area[1]) }
      }
      this.renderizaOutraAreaOuPiquete(area)
    }

    if (this.area?.length) {
      this.area_cadastrada = true
      this.renderizaAreaAtiva()
    } else {
      this.desenharArea()
      if (coordenadasIniciais) {
        this.map.setCenter(coordenadasIniciais)
        this.map.setZoom(15)
      } else {
        if (!this.hashArea) {
          this.localizar()
        }
      }
    }
  }

  exibirMapa(): void {
    const latLng = new google.maps.LatLng(-14.2375431, -60.3424685)
    const mapOptions: google.maps.MapOptions = {
      center: latLng,
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      disableDefaultUI: true,
      zoomControl: true,
      mapId: String(new Date().getTime())
    }

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions)
    const mapInput = document.querySelector('#map-input input')
    const autoComplete = new google.maps.places.Autocomplete(mapInput as HTMLInputElement)
    autoComplete.bindTo('bounds', this.map)

    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace()

      if (place.geometry && place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport)
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  limparJSON(objeto: any): any {
    let array = JSON.stringify(objeto)
    array = array.replace(/(\")|(\])|(\[)|(\\)/gm, '')
    array = array.split(',') as any
    return array as any
  }
  
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  parseCoodenadasCentrais(objeto: any): any {
    try {
      return JSON.parse(String(this.coordenadas_centrais))
    } catch (ex) {
      return {
        lat: null,
        lng: null
      }
    }
  }

  renderizaAreaAtiva(): void {
    this.area = this.limparJSON(this.area)
    this.coordenadas_centrais = this.parseCoodenadasCentrais(this.coordenadas_centrais)

    if (!this.area?.length) {
      return
    }

    this.selecionaArea = true

    const shape = []
    for (let i = 0; i < this.area.length - 1; i = i + 2) {
      shape.push({ lat: Number(this.area[i]), lng: Number(this.area[i + 1]) })
    }

    const polygon = new google.maps.Polygon({
      paths: shape,
      fillColor: '#fc6300',
      strokeColor: '#fc6300',
      fillOpacity: 0.2,
      strokeWeight: 2,
      clickable: false,
      editable: true,
      zIndex: 1
    })
    this.polygons.push(polygon)
    polygon.setMap(this.map) //Insere poligono no mapa
    this.eventListenerPolygon(polygon)

    const bounds = new google.maps.LatLngBounds()

    for (let k = 0; k < shape.length; k++) {
      bounds.extend(shape[k])
    }

    this.map.setCenter(bounds.getCenter())
    this.map.fitBounds(bounds)

    VastaRX.getState('remove-polygon', (remove) => {
      if (!remove) return

      polygon.setMap(null)
    })
  }

  renderizaOutraAreaOuPiquete(area: { nome: string, area: number[] }): void {
    if (area.area && area.area.length > 0) {
      const shape = []
      for (let i = 0; i < area.area.length - 1; i = i + 2) {
        shape.push({ lat: area.area[i], lng: area.area[i + 1] })
      }

      const polygon = new google.maps.Polygon({
        paths: shape,
        strokeColor: '#ffffff',
        strokeOpacity: 0.7,
        fillOpacity: 0,
        strokeWeight: 2,
        clickable: false,
        editable: false,
        zIndex: 4
      })
      polygon.setMap(this.map) //Insere poligono no mapa

      const bounds = new google.maps.LatLngBounds()

      for (let k = 0; k < shape.length; k++) {
        bounds.extend(shape[k])
      }

      const areaNome = document.createElement('div')
      areaNome.className = 'map-label'
      areaNome.innerHTML = `${area.nome}`
      const markerCentral = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        position: bounds.getCenter(),
        content: areaNome,
      })
      this.markers.push(markerCentral)
    }
  }

  desenharArea(): void {
    this.polygons = []
    this.polygon = null
    this.selecionaArea = false

    this.selecionaArea = true
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: {
        fillColor: '#000000',
        strokeColor: '#fc6300',
        fillOpacity: 0.2,
        strokeWeight: 2,
        clickable: false,
        editable: true,
        zIndex: 1
      }
    })
    this.drawingManager.setMap(this.map)
    
    this.polygon = google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
      this.eventListenerPolygon(polygon)
    })
  }

  eventListenerPolygon(polygon: google.maps.Polygon): void {
    console.warn('poligoncomplete')
    this.processaPoligonoDesenhado(polygon)

    polygon.getPaths().forEach((path, index) => {
      google.maps.event.addListener(path, 'set_at', (_index, _obj) => {
        console.log('a point has changed', polygon.getPath())
        this.processaPoligonoDesenhado(polygon)
      })
      google.maps.event.addListener(path, 'insert_at', (_index, _obj) => {
        console.log('a point has been added', polygon.getPath())
        this.processaPoligonoDesenhado(polygon)
      })
      google.maps.event.addListener(path, 'remove_at', (_index, _obj) => {
        console.log('remove_at', polygon.getPath())
        this.processaPoligonoDesenhado(polygon)
      })
    })
  }

  processaPoligonoDesenhado(polygon: google.maps.Polygon): void {
    this.polygons = []
    this.polygons.push(polygon)

    const centroid = this.calculaPolyCentroid(polygon)

    if (centroid[0] && centroid[1]) {
      this.coordenadas_centrais = { lat: null, lng: null }
      this.coordenadas_centrais.lat = Number(centroid[0])
      this.coordenadas_centrais.lng = Number(centroid[1])
    }

    const polygonBounds = polygon.getPath()
    const boundsArray = polygonBounds.getArray()
    const coordinates = []
    const coordinatesArea = []

    VastaRX.setState('bloquear-desenho', true)
    setTimeout(() => {
      VastaRX.setState('bloquear-desenho', false)
    }, 100)

    for (let i = 0; i < boundsArray.length; i++) {
      coordinates.push(boundsArray[i].lat(), boundsArray[i].lng())
      coordinatesArea.push(new google.maps.LatLng(boundsArray[i].lat(), boundsArray[i].lng()))
    }

    this.tamanho = Number((google.maps.geometry.spherical.computeArea(coordinatesArea) * 0.0001).toFixed(2))
    this.area = coordinates

    this.cd.detectChanges()

    VastaRX.getState('remove-polygon', (remove) => {
      if (!remove) return

      polygon.setMap(null)
    })
  }

  dismiss(salvar: boolean): void {
    if (salvar) {
      this.modalCtrl.dismiss({
        area: this.area,
        coordenadas_centrais: this.coordenadas_centrais,
        tamanho: Number(this.tamanho)
      })
    } else this.modalCtrl.dismiss()
  }

  removerArea(): void {
    this.polygons = []
    this.polygon = null
    this.selecionaArea = false
    VastaRX.setState('remove-polygon', true)
    setTimeout(() => {
      VastaRX.setState('remove-polygon', false)
    }, 100)
    this.cd.detectChanges()
  }

  calculaPolyCentroid(poly: google.maps.Polygon): string[] {
    let lowx,
      highx,
      lowy,
      highy,
      lats = [],
      lngs = [],
      center_x,
      center_y,
      vertices = poly.getPath() as any

    for (let i = 0; i < vertices.length; i++) {
      lngs.push(vertices.getAt(i).lng())
      lats.push(vertices.getAt(i).lat())
    }

    lats.sort()
    lngs.sort()
    lowx = lats[0]
    highx = lats[vertices.length - 1]
    lowy = lngs[0]
    highy = lngs[vertices.length - 1]
    center_x = lowx + (highx - lowx) / 2
    center_y = lowy + (highy - lowy) / 2
    return [center_x, center_y]
  }

  localizar(): void {
    this.localizando = true

    const localizar = (callback, erro): void => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          callback(position.coords)
        })
      } else {
        erro('Geolocation is not supported by this browser.')
      }
    }

    localizar(
      (coordenadas) => {
        this.map.setCenter({ lat: coordenadas.latitude, lng: coordenadas.longitude })
        this.map.setZoom(13)
        this.localizando = false
        this.cd.detectChanges()
      },
      (error) => {
        console.log(error)

        this.localizando = false
      }
    )
  }

  mapaSearch(): void {
    const searchBox = new google.maps.places.SearchBox(this.mapa_search)
    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds())
    })
  }

  campoStop(event: Event): void {
    console.log({ event })

    event.preventDefault()
    event.stopPropagation()
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  renderizaAreaPrincipal(): void {
    let array = JSON.stringify(this.areaPrincipal)
    array = array.replace(/(\")|(\])|(\[)|(\\)/gm, '')
    array = array.split(',') as any
    const areaPrincipal = array as any

    if (areaPrincipal.length > 0) {

      const shape = []
      for (let i = 0; i < areaPrincipal.length - 1; i = i + 2) {
        shape.push({ lat: Number(areaPrincipal[i]), lng: Number(areaPrincipal[i + 1]) })
      }

      const polygon = new google.maps.Polygon({
        paths: shape,
        fillColor: '#ffffff',
        strokeColor: '#ffffff',
        fillOpacity: 0.2,
        strokeWeight: 2,
        clickable: false,
        editable: false,
        zIndex: 1
      })

      this.polygons.push(polygon)
      polygon.setMap(this.map) //Insere poligono no mapa

      const bounds = new google.maps.LatLngBounds()

      for (let k = 0; k < shape.length; k++) {
        bounds.extend(shape[k])
      }

      this.map.setCenter(bounds.getCenter())
      this.map.fitBounds(bounds)
    }
  }
}
