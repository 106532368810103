<div class="container-lista">
  <button (click)="selecionarAcao('cadastrar')">
    <ion-icon name="add"></ion-icon>
    <div class="conteudo">
      <span>Cadastrar Venda</span>
    </div>
  </button>
  <button (click)="selecionarAcao('selecionar')">
    <ion-icon name="checkmark"></ion-icon>
    <div class="conteudo">
      <span>Selecionar Venda Cadastrada</span>
    </div>
  </button>
</div>
