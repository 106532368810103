import { ApplicationRef, Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import {
  IGetPropriedades,
  IParamsAdicionarPropriedade,
  IPropriedade
} from 'src/app/utils/interfaces/propriedades.interface'
import { Racas } from 'src/app/utils/racas'
import { ModalListaRacasPage } from '../../bovinos/modal-lista-racas/modal-lista-racas.page'

@Component({
  selector: 'app-modal-propriedade',
  templateUrl: './modal-propriedade.page.html',
  styleUrls: ['./modal-propriedade.page.scss']
})
export class ModalPropriedadePage implements OnInit {
  public abaAtiva = 'Geral'
  public racas = Racas

  public propriedadeProp: IPropriedade

  public propriedade = {
    id: null,
    nome: '',
    endereco: '',
    cidade: '',
    estado: '',
    pais: 'Brasil',
    localizacao: {
      latitude: null,
      longitude: null
    },
    metadados: {
      controle_animais: '',
      sistema_criacao: '',
      raca_principal: '',
      tipo_producao: '',
      tipo_criacao: '',
      hora_alertas_calendario: null,
      mostra_brinco: true,
      habilita_manejos_genetica: false,
      quantidade: '0',
      tempo_desmame: null,
      tempo_ate_vaca_ficar_apta: '',
      periodo_minimo_diagnostico_gestacao: '',
      manejos_reprodutivos_avancados: false
    }
  } as IPropriedade

  public loadingAdicionarOuAtualizar = false
  public loadingExcluir = false

  constructor(
    public propriedadesCtrl: PropriedadesService,
    private utilsCtrl: IonicUtilsService,
    private modalCtrl: ModalController,
    private ref: ApplicationRef,
    private alertCtrl: AlertController
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.propriedadeProp) {
      console.log('Prop: ', this.propriedadeProp)

      this.propriedade = { ...this.propriedadeProp }
      
      const brinco = Number(this.propriedade.metadados.mostra_brinco)
      const manejoGenetica = Boolean(this.propriedade.metadados.habilita_manejos_genetica)
      const manejosReprodutivosAvancados = Number(this.propriedade.metadados.manejos_reprodutivos_avancados)
      const isolarFinanceiro = Number(this.propriedade.metadados.isolar_financeiro)
      const isolarEstoque = Number(this.propriedade.metadados.isolar_estoque)

      this.propriedade.metadados.mostra_brinco = Boolean(brinco)
      this.propriedade.metadados.habilita_manejos_genetica = Boolean(manejoGenetica)
      this.propriedade.metadados.manejos_reprodutivos_avancados = Boolean(manejosReprodutivosAvancados)
      this.propriedade.metadados.isolar_financeiro = Boolean(isolarFinanceiro)
      this.propriedade.metadados.isolar_estoque = Boolean(isolarEstoque)
    }
    this.propriedadesCtrl.getPropriedades()
    console.log('Todas as Propriedades: ', this.propriedadesCtrl.todasPropriedades)
    console.log(this.propriedadesCtrl.propriedadeSelecionada)
  }

  async openModalListaRacas(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaRacasPage,
      cssClass: 'custom-modal'
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (data) {
      this.propriedade.metadados.raca_principal = data.raca
      this.ref.tick()
    }
  }

  tick(): void {
    try {
      this.ref.tick()
    } catch (e) {
      console.log('Error tick', e)
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  trocarAba(aba: string): void {
    this.abaAtiva = aba
  }

  verificarCamposObrigatorios(): boolean {
    if (
      this.propriedade.nome.length &&
      this.propriedade.endereco.length &&
      this.propriedade.cidade.length &&
      this.propriedade.estado.length &&
      this.propriedade.metadados.tipo_producao &&
      this.propriedade.metadados.tipo_criacao &&
      this.racas[this.propriedade.metadados?.raca_principal]
    ) {
      return true
    }

    return false
  }

  async getPropriedades(): Promise<IGetPropriedades> {
    const [response, error] = await this.propriedadesCtrl.getPropriedades()
    if (response) {
      return response
    }

    if (error) {
      console.error(error)
    }
  }

  async adicionarPropriedade(): Promise<void> {
    this.verificarmetadadosDados()
    this.loadingAdicionarOuAtualizar = true
    const [response, error] = await this.propriedadesCtrl.adicionarPropriedade(
      this.propriedade as IParamsAdicionarPropriedade
    )
    await this.getPropriedades()
    if (response) {

      VastaRX.setState('propriedade_cadastrada', true)

      this.modalCtrl.dismiss({
        success: true,
        idPropriedade: response.id
      })
      this.loadingAdicionarOuAtualizar = false
      this.propriedadesCtrl.getPropriedades()
    }

    if (error) {
      this.loadingAdicionarOuAtualizar = false
      console.error(error)
    }
  }

  async atualizarPropriedade(): Promise<void> {
    this.loadingAdicionarOuAtualizar = true
    const [response, error] = await this.propriedadesCtrl.atualizarPropriedade(this.propriedade.id, this.propriedade)
    if (response) {
      this.propriedadesCtrl.getPropriedades()
      this.modalCtrl.dismiss({
        success: true
      })
      this.loadingAdicionarOuAtualizar = false
    }
    if (error) {
      console.error(error)
      this.loadingAdicionarOuAtualizar = false
    }
  }

  async deletarPropriedade(): Promise<void> {
    if (this.propriedadesCtrl.todasPropriedades.length <= 1) {
      this.modalCtrl.dismiss()
      this.utilsCtrl.showToast('Não é possível remover esta propriedade', 'bottom')
    } else {
      this.loadingExcluir = true
      const [response, error] = await this.propriedadesCtrl.deletarPropriedade(this.propriedade.id)
      if (response) {
        this.propriedadesCtrl.getPropriedades()
        this.propriedadesCtrl.propriedadeSelecionada = this.propriedadesCtrl.todasPropriedades[0]
        VastaRX.setState('propriedade_selecionada', this.propriedadesCtrl.propriedadeSelecionada)
        this.modalCtrl.dismiss({ success: true })
        this.loadingExcluir = false
      }

      if (error) {
        console.error(error)
        this.loadingExcluir = false
      }
    }
  }

  async alertConfirmDeletePropriedade(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Deseja remover esta propriedade?',
      message: 'Não é possível reverter esta operação.',
      buttons: [
        {
          text: 'Não'
        },
        {
          text: 'Sim',
          handler: (): void => {
            this.deletarPropriedade()
          }
        }
      ]
    })
    await alert.present()
  }

  verificarmetadadosDados(): void {
    if (!this.propriedade.metadados.controle_animais) {
      delete this.propriedade.metadados.controle_animais
    }
    if (!this.propriedade.metadados.quantidade) {
      delete this.propriedade.metadados.quantidade
    }
    if (!this.propriedade.metadados.raca_principal) {
      delete this.propriedade.metadados.raca_principal
    }
    if (!this.propriedade.metadados.sistema_criacao) {
      delete this.propriedade.metadados.sistema_criacao
    }
    if (!this.propriedade.metadados.tipo_producao) {
      delete this.propriedade.metadados.tipo_producao
    }
    if (!this.propriedade.metadados.tipo_criacao) {
      delete this.propriedade.metadados.tipo_criacao
    }
  }



  async submit(): Promise<void> {
    if (this.propriedadeProp) {
      await this.atualizarPropriedade()
    } else {
      await this.adicionarPropriedade()
    }
  }
}
