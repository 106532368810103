import { Component, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { ApiService } from 'src/app/services/api/api.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'

@Component({
  selector: 'app-popover-recurso-premium',
  templateUrl: './popover-recurso-premium.component.html',
  styleUrls: ['./popover-recurso-premium.component.scss'],
})
export class PopoverRecursoPremiumComponent implements OnInit {
  public titulo: string
  public descricao: string
  public isTrialIniciado: boolean
  public sufixoDescricao: string
  public slug: string

  constructor(
    private planoBasicoCtrl: PlanoBasicoService,
    private api: ApiService,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit(): void {
    if (!this.titulo) this.titulo = 'Experimente o melhor da Fertili com o Plano Premium'
    if (!this.descricao) this.descricao = 'Acesse todos os recursos e tenha acesso a todas as funcionalidades do sistema.'
    this.sufixoDescricao = this.isTrialIniciado ? ', faça o upgrade para o Plano Premium.' : ', experimente o Plano Premium.'


    this.api.setPassoFunil(`click_${this.slug}`)
  }

  public acaoPlanoUsuario(): void {
    if (this.isTrialIniciado) {
      this.planoBasicoCtrl.sePlanoAtivoForBasicoAbrirModalDeAssinatura()
    } else {
      this.planoBasicoCtrl.iniciarFluxoPlanoTrial()
      this.popoverCtrl.dismiss()
    }
  }
}
