import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { FinancasService } from 'src/app/services/financas.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { CentrosDeCustoEPessoa } from 'src/app/utils/interfaces/financas.interface'

@Component({
  selector: 'app-modal-pessoas',
  templateUrl: './modal-pessoas.component.html',
  styleUrls: ['./modal-pessoas.component.scss']
})
export class ModalPessoasComponent implements OnInit {
  public pessoa: Partial<CentrosDeCustoEPessoa> = {
    nome: '',
    categoria_pai: '',
    descricao: ''
  }
  public pessoaProp: CentrosDeCustoEPessoa
  public loading = false
  constructor(
    private modalCtrl: ModalController,
    private financasCtrl: FinancasService,
    private utilsCtrl: IonicUtilsService
  ) {}

  ngOnInit(): void {
    if (this.pessoaProp) {
      this.pessoa = { ...this.pessoaProp }
    }
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  async criaNovaPessoa(): Promise<void> {
    this.loading = true
    const payload = {
      centro: { ...this.pessoa }
    }
    const [response, error] = await this.financasCtrl.criarPessoa(payload)
    this.loading = false

    if (response) {
      await this.modalCtrl.dismiss({ success: true })
      this.utilsCtrl.showToast('Pessoa cadastrada com sucesso!', 'bottom')
    }

    if (error) {
      this.utilsCtrl.showToast('Erro ao cadastrar pessoa', 'bottom')
    }
  }
}
