const TiposAreaLista = [
  'Agrícola',
  'Área de Preservação Permanente',
  'Área Total da Propriedade',
  'Confinamento',
  'Pastagem',
  'Reserva Legal',
  'Semi-Confinamento',
]

const TiposAreaObject = {}

TiposAreaLista.map((area) => {
  TiposAreaObject[area] = area
})

export { TiposAreaObject, TiposAreaLista }
