<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Pesagem</div>
    <vasta-input
      label="Peso"
      type="manejo-number"
      maskSuffix=" kg"
      mask="separator.2"
      [(value)]="dados.peso"
      (valueChange)="dados.edited = true; emit(); calculaGMD()"
    ></vasta-input>

    <div class="manejo-info" *ngIf="ultimoPeso">
      <span class="label">Último Peso</span>
      <p class="content">
        {{ ultimoPeso | number: '1.0-2' }} kg <small>({{ ultimoPesoData | timestamp: 'data' }})</small>
      </p>
    </div>

    <div class="manejo-info" *ngIf="ultimoPeso && dados.peso">
      <span class="label">Ganho</span>
      <p class="content">
        {{ dados.peso - ultimoPeso | number: '1.0-1' }} kg
      </p>
    </div>

    <div class="manejo-info" *ngIf="ultimoPeso && dados.peso">
      <span class="label">GMD</span>
      <p class="content" *ngIf="!planoBasicoCtrl.isPlanoBasico">
        {{ gmd | number: '1.0-3' }} kg
        <span
          class="status"
          [class.verde]="gmd > 0.7"
          [class.amarelo]="gmd <= 0.7 && gmd > 0.5"
          [class.vermelho]="gmd <= 0.5"
        ></span>
      </p>
      <ion-icon name="diamond" *ngIf="planoBasicoCtrl.isPlanoBasico"></ion-icon>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.peso">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
