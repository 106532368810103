import { Injectable } from '@angular/core'
import { Device } from '@capacitor/device'
import {
  ILogUsuario,
  IUsuario,
  PermissoesPorPropriedade,
  StatusPermissaoUsuario
} from '../utils/interfaces/Usuarios.interface'
import { ApiService } from './api/api.service'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'
export type HasEmail = { existe: boolean; id?: number }
export type StatusOperacaoUsuario = { success: boolean; id?: number }

@Injectable({
  providedIn: 'root'
})
export class LogService {
  public isUsuarioValido = false
  public statusPermissao: StatusPermissaoUsuario
  public permissoesPorPropriedade: PermissoesPorPropriedade = {}
  public usuarioCache: IUsuario = null

  constructor(
    private apiCtrl: ApiService
  ) {}

  async enviarLogUsuario(evento: string): Promise<[StatusRequest, Error?]> {
    const info = await Device.getInfo()

    const body: ILogUsuario = {
      evento: evento,
      modelo: info.model,
      plataforma: info.platform,
      sistema_operacional: info.operatingSystem,
      versao_so: info.osVersion,
      fabricante: info.manufacturer,
      versao_navegador: info.webViewVersion,
      is_emulador: info.isVirtual ? 1 : 0
    }

    try {
      const response = (await this.apiCtrl.post({
        url: 'usuario/log',
        body
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }
}
