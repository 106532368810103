import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { ModalBovinoComponent } from 'src/app/pages/bovinos/modal-bovino/modal-bovino.component'
import { ModalListaAnimaisPage } from 'src/app/pages/bovinos/modal-lista-animais/modal-lista-animais.page'
import { AnimaisService } from 'src/app/services/animais.service'
import { ManejoReprodutivoUnitario } from 'src/app/services/manejos.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { IAnimal, IGenealogia } from 'src/app/utils/interfaces/animais.interface'
import { IErroManejo, IGenealogiaPaisAgrupado } from 'src/app/utils/interfaces/manejos.interface'
import { ModalSelecionarAdicionarCriaComponent } from '../modal-selecionar-adicionar-cria/modal-selecionar-adicionar-cria.component'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-reprodutivo-parto',
  templateUrl: './manejo-reprodutivo-parto.component.html',
  styleUrls: ['./manejo-reprodutivo-parto.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManejoReprodutivoPartoComponent implements OnInit, OnChanges {
  @Input('dados') dados: ManejoReprodutivoUnitario = {
    parto_hash_cria1: '',
    parto_hash_cria2: '',
    observacao: '',
    edited: true
  }
  @Input('data') data: string
  @Input() genealogiaParto: IGenealogiaPaisAgrupado
  @Input() animal: IAnimal
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  @Input('animaisAssociados') animaisAssociados: { [key: string]: IAnimal } = {}
  @Output() animaisAssociadosChange = new EventEmitter<{ [key: string]: IAnimal }>()

  @Input('hashNotIn') hashNotIn = []

  public showErrorDataNascimento = false
  public errosManejoCria: IErroManejo[] = []
  public isAtualizandoDadosDaCria = false

  constructor(
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private animaisCtrl: AnimaisService,
    private utilsCtrl: IonicUtilsService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.genealogiaParto) {
      this.genealogiaParto = changes.genealogiaParto.currentValue
    }
  }

  emit(): void {
    this.dadosChange.emit(this.dados)
    this.animaisAssociadosChange.emit(this.animaisAssociados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverSelecionarAdicionarCria(event: Event, nCria: number): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: ModalSelecionarAdicionarCriaComponent,
      cssClass: 'popover-selecionar-adicionar-cria',
      event,
      reference: 'trigger'
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()

    if (data) {
      if (data === 'cadastrar') {
        this.showModalCadastrarCria(nCria, this.genealogiaParto)
      }

      if (data === 'selecionar') {
        this.showModalAnimais(nCria, this.genealogiaParto)
      }

      if (data === 'natimorto') {
        if (nCria === 1) {
          this.dados.parto_hash_cria1 = 'natimorto'
        } else {
          this.dados.parto_hash_cria2 = 'natimorto'
        }
        this.dados.edited = true
        this.limparErrosAoSelecionarOutroAnimal(nCria, null, true)
        this.emit()
      }
    }
  }

  async showModalAnimais(nCria: number, genealogiaParto?: IGenealogiaPaisAgrupado): Promise<void> {
    // Não exibir cria 1, na seleção de cria 2 ou vice versa
    this.hashNotIn = [this.dados.hash_animal]

    if (this.dados.parto_hash_cria1?.length) {
      this.hashNotIn.push(this.dados.parto_hash_cria1)
    }

    if (this.dados.parto_hash_cria2?.length) {
      this.hashNotIn.push(this.dados.parto_hash_cria2)
    }

    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: { hashNotIn: this.hashNotIn, genealogiaParto },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      const animalSelecionado: IAnimal = data
      const { isGenealogiaMaeCorreta, isGenealogiaPaiCorreta } = this.getVerificacaoGenealogia(
        animalSelecionado,
        genealogiaParto
      )

      let mensagemErroGenealogia = ''

      if (!isGenealogiaMaeCorreta && !isGenealogiaPaiCorreta) {
        mensagemErroGenealogia = 'Cria selecionada é de outra mãe e de outro pai'
      } else if (!isGenealogiaMaeCorreta) {
        mensagemErroGenealogia = 'Cria selecionada é de outra mãe'
      } else if (!isGenealogiaPaiCorreta) {
        mensagemErroGenealogia = 'Cria selecionada é de outro pai'
      }

      this.limparErrosAoSelecionarOutroAnimal(nCria, animalSelecionado)

      if (!isGenealogiaMaeCorreta || !isGenealogiaPaiCorreta) {
        this.errosManejoCria.push({
          erro: 'genealogia',
          isAberto: true,
          titulo: 'Genealogia',
          mensagem: mensagemErroGenealogia,
          numeroCria: nCria
        })
      }

      if (nCria === 1) {
        this.dados.parto_hash_cria1 = animalSelecionado.hash
      } else {
        this.dados.parto_hash_cria2 = animalSelecionado.hash
      }

      this.animaisAssociados[animalSelecionado.hash] = animalSelecionado

      if (animalSelecionado.data_nascimento !== this.data) {
        this.errosManejoCria.push({
          erro: 'data_nascimento',
          isAberto: true,
          titulo: 'Data de Nascimento',
          mensagem: 'Data de nascimento diferente da data do manejo',
          numeroCria: nCria
        })
      }

      this.dados.edited = true
      this.emit()
    }
  }

  limparErrosAoSelecionarOutroAnimal(nCria: number, animalSelecionado: IAnimal, natimorto?: boolean): void {
    if (this.errosManejoCria?.length) {
      if (nCria === 1) {
        if (this.dados.parto_hash_cria1 !== animalSelecionado?.hash) {
          this.errosManejoCria = []
        }
      } else {
        if (this.dados.parto_hash_cria2 !== animalSelecionado?.hash) {
          this.errosManejoCria = []
        }
      }
    }
  }

  getVerificacaoGenealogia(
    animalSelecionado: IAnimal,
    genealogiaParto: IGenealogiaPaisAgrupado
  ): { isGenealogiaMaeCorreta: boolean; isGenealogiaPaiCorreta: boolean } {
    const {
      genealogia_matriz_externa,
      genealogia_matriz_interna,
      genealogia_reprodutor_externo,
      genealogia_reprodutor_interno
    } = animalSelecionado

    if (genealogiaParto) {
      const { maeExterna, maeInterna, paiExterno, paiInterno } = genealogiaParto
      let isGenealogiaMaeCorreta = false
      let isGenealogiaPaiCorreta = false

      if (genealogia_matriz_externa && maeExterna?.hash && genealogia_matriz_externa === maeExterna.hash) {
        isGenealogiaMaeCorreta = true
      } else if (genealogia_matriz_interna && maeInterna?.hash && genealogia_matriz_interna === maeInterna.hash) {
        isGenealogiaMaeCorreta = true
      }

      if (genealogia_reprodutor_externo && paiExterno?.hash && genealogia_reprodutor_externo === paiExterno.hash) {
        isGenealogiaPaiCorreta = true
      } else if (
        genealogia_reprodutor_interno &&
        paiInterno?.hash &&
        genealogia_reprodutor_interno === paiInterno.hash
      ) {
        isGenealogiaPaiCorreta = true
      } else if (
        !genealogia_reprodutor_interno &&
        !paiInterno?.hash &&
        !genealogia_reprodutor_externo &&
        !paiExterno?.hash
      ) {
        isGenealogiaPaiCorreta = true
      }
      return { isGenealogiaMaeCorreta, isGenealogiaPaiCorreta }
    } else {
      return { isGenealogiaMaeCorreta: true, isGenealogiaPaiCorreta: true }
    }
  }

  async corrigirErroDaCria(numeroCria: number): Promise<void> {
    if (this.errosManejoCria[0].erro === 'genealogia') {
      await this.corrigirGenealogiaDaCria(numeroCria)
    } else if (this.errosManejoCria[0].erro === 'data_nascimento') {
      await this.corrigirDataDeNascimentoDaCria(numeroCria)
    }
  }

  async corrigirGenealogiaDaCria(numeroCria: number): Promise<void> {
    this.isAtualizandoDadosDaCria = true
    const loading = await this.utilsCtrl.showLoading('circular', 'Atualizando genealogia da cria')

    const genealogiaCriaAtualizada: IGenealogia = {
      genealogia_matriz_externa: this.genealogiaParto?.maeExterna?.hash,
      genealogia_matriz_interna: this.genealogiaParto?.maeInterna?.hash,
      genealogia_reprodutor_externo: this.genealogiaParto?.paiExterno?.hash,
      genealogia_reprodutor_interno: this.genealogiaParto?.paiInterno?.hash
    }

    let hashCria: string

    if (numeroCria === 1) {
      hashCria = this.dados.parto_hash_cria1
    } else if (numeroCria === 2) {
      hashCria = this.dados.parto_hash_cria2
    }

    const [response, error] = await this.animaisCtrl.atualizarVariosAnimais({
      hashs: [hashCria],
      ...genealogiaCriaAtualizada
    })

    if (response) {
      this.utilsCtrl.showToast('Genealogia da cria atualizada com sucesso', 'top')
    }

    if (error) {
      console.error(error)
      this.utilsCtrl.showToast('Erro ao atualizar genealogia da cria', 'top')
    }

    this.isAtualizandoDadosDaCria = false
    this.errosManejoCria.shift()
    this.utilsCtrl.dismissLoading(loading)
  }

  async corrigirDataDeNascimentoDaCria(numeroCria: number): Promise<void> {
    this.isAtualizandoDadosDaCria = true
    const loading = await this.utilsCtrl.showLoading('circular', 'Atualizando data de nascimento da cria')

    let hashCria: string

    if (numeroCria === 1) {
      hashCria = this.dados.parto_hash_cria1
    } else if (numeroCria === 2) {
      hashCria = this.dados.parto_hash_cria2
    }

    const [response, error] = await this.animaisCtrl.atualizarVariosAnimais({
      hashs: [hashCria],
      data_nascimento: this.data
    })

    if (response) {
      this.utilsCtrl.showToast('Data de nascimento da cria atualizada com sucesso', 'top')
    }

    if (error) {
      console.error(error)
      this.utilsCtrl.showToast('Erro ao atualizar data de nascimento da cria', 'top')
    }

    this.errosManejoCria.shift()
    this.isAtualizandoDadosDaCria = false
    this.utilsCtrl.dismissLoading(loading)
  }

  ignorarErroDoManejo(): void {
    this.errosManejoCria.shift()
  }

  async showModalCadastrarCria(nCria: number, genealogiaParto?: IGenealogiaPaisAgrupado): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalBovinoComponent,
      backdropDismiss: false,
      componentProps: {
        genealogiaParto,
        maeProp: this.animal,
        dataNascimentoProp: this.data
      },
      cssClass: 'custom-modal'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      this.dados.edited = true
      const [response, error] = await this.animaisCtrl.getAnimal(data.response.hashs[0])
      if (response) {
        this.showErrorDataNascimento = false
        this.animaisAssociados[response.hash] = response as IAnimal
        if (nCria === 1) {
          this.dados.parto_hash_cria1 = response.hash
        } else {
          this.dados.parto_hash_cria2 = response.hash
        }
      }

      this.emit()

      if (error) {
        console.error(error)
      }
    }
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()

    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.parto_hash_cria1 = null
      this.dados.parto_hash_cria2 = null
      this.emit()
    }
  }

  desmarcaNatimorto(nCria: number): void {
    if (nCria === 1) {
      this.dados.parto_hash_cria1 = ''
    } else {
      this.dados.parto_hash_cria2 = ''
    }
    this.dados.edited = true
    this.emit()
  }
}
