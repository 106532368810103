import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { VastaRX } from '@vasta/rx'
import { NavegacaoService } from '../services/navegacao.service'
import { PermissoesUsuarioService } from '../services/permissoes-usuario.service'
import { PlanoBasicoService } from '../services/plano-basico.service'
import { UsuariosService } from '../services/usuarios.service'
import { Permissao, StatusPermissaoUsuario } from '../utils/interfaces/Usuarios.interface'

@Injectable({
  providedIn: 'root'
})
export class PermissaoesUsuarioGuard implements CanActivate {
  public rotaAtiva: string = ''
  public subRotaAtiva: string = ''

  constructor(
    public navegacaoCtrl: NavegacaoService,
    public usuarioCtrl: UsuariosService,
    private permissoesUsuarioCtrl: PermissoesUsuarioService,
    private planoBasicoCtrl: PlanoBasicoService
  ) {
    this.rotaAtiva = location.pathname.replace('/', '').split('/')[0]

    this.subRotaAtiva = location.pathname

    VastaRX.getState('statusPermissao', (emit: StatusPermissaoUsuario) => {
      if (emit) {
        this.impedirUsuarioDeAcessarRotaQueEleNaoTenhaPermissao()
      }
    })
  }

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const rotaAnterior = `${this.rotaAtiva}`
    this.rotaAtiva = state.url.replace('/', '').split('/')[0]

    if (this.rotaAtiva && this.rotaAtiva !== rotaAnterior) {
      this.impedirUsuarioDeAcessarRotaQueEleNaoTenhaPermissao()
    }

    if (this.rotaAtiva === 'login') {
      return false
    }

    return true
  }

  private impedirUsuarioDeAcessarRotaQueEleNaoTenhaPermissao(): void {
    if (!this.usuarioCtrl.usuarioCache) {
      return
    }

    if(this.planoBasicoCtrl.isPlanoBasico) {
      this.voltarParaHomeSeRotaEstaBloqueadoEmPlanoBasico()
    }

    const { permissoesDoUsuarioNaPropriedade, isAcessoDoUsuarioLiberado } = this.permissoesUsuarioCtrl

    if (!isAcessoDoUsuarioLiberado) {
      this.navegacaoCtrl.set(['/bem-vindo'])
    }

    if (permissoesDoUsuarioNaPropriedade) {
      const isRestricoes = !!Object.keys(permissoesDoUsuarioNaPropriedade).length

      let rotaUsuarios360deBolso = null

      if(this.rotaAtiva === 'usuarios') {
        rotaUsuarios360deBolso = 'usuarios_360debolso'
      }

      if(this.rotaAtiva === 'pluviometria') {
        rotaUsuarios360deBolso = 'areas'
      }

      const permissao: Permissao = isRestricoes && (permissoesDoUsuarioNaPropriedade[this.rotaAtiva] || permissoesDoUsuarioNaPropriedade[rotaUsuarios360deBolso])

      if (isRestricoes && !permissao) {
        this.navegacaoCtrl.set(['/'])
      }
    }
  }

  private voltarParaHomeSeRotaEstaBloqueadoEmPlanoBasico(): void {
    const rotaDividida = this.subRotaAtiva.split('/')
    const rotaAtual = rotaDividida[rotaDividida.length - 1]

    const isAbaAtualBloqueada = this.planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('abasMenuLateral', rotaAtual)
    const isRelatorioAtualBloqueado = this.planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('relatorios', rotaAtual)

    if(isAbaAtualBloqueada || isRelatorioAtualBloqueado) {
      console.log('this.navegacaoCtrl.set([/]) 2')
      this.navegacaoCtrl.set(['/'])
    }
  }
}
