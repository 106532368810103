/* eslint-disable indent */
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertController, IonContent, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import * as moment from 'moment'
import { PopoverOpcoesManejosSelecionadosComponent } from 'src/app/components/popover-opcoes-manejos-selecionados/popover-opcoes-manejos-selecionados.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { AnimaisService } from 'src/app/services/animais.service'
import { ImportacaoService } from 'src/app/services/importacao.service'
import {
  IManejoGeralUnitario,
  IManejoGeralTipos,
  IManejoReprodutivoTipos,
  IManejoSanitarioTipos,
  ManejoOrdenha,
  ManejoPesagem,
  ManejoReprodutivoUnitario,
  ManejoSanitarioUnitario,
  ManejosService,
  ManejoNutricional,
  IManejoLote,
  ResponseManejosData,
  ManejoSanitarioEstoque,
  PopoverCampos,
  IManejoGeneticaUnitario,
  IManejoGeneticaTipos
} from 'src/app/services/manejos.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { ColunasAnimaisManejo, ColunasAreasManejo, ColunasLotesManejo } from 'src/app/utils/configuracoes-padrao'
import { IAnimal, IReprodutorExterno } from 'src/app/utils/interfaces/animais.interface'
import { IArea, IAreaPiquete } from 'src/app/utils/interfaces/areas.interface'
import { EstoqueBiologico, EstoqueFarmacia, EstoqueNutricional } from 'src/app/utils/interfaces/estoque.interface'
import { CustosManejo, ICategoria, IConta, IFinancasCompraEVenda, Pessoa } from 'src/app/utils/interfaces/financas.interface'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { Exposicao, IGenealogiaPartoAgrupadosPorHash } from 'src/app/utils/interfaces/manejos.interface'
import { IPropriedade } from 'src/app/utils/interfaces/propriedades.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalListaAreasPage } from '../../areas/modal-lista-areas/modal-lista-areas.page'
import { BovinoSingleComponent } from '../../bovinos/bovino-single/bovino-single.page'
import { ModalListaAnimaisPage } from '../../bovinos/modal-lista-animais/modal-lista-animais.page'
import { ModalListaLotesPage } from '../../bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { listaManejosDisponiveis } from '../manejos-disponiveis/lista-manejos-disponiveis'
import { ManejoArea } from 'src/app/utils/interfaces/manejos.interface'
import { ListaManejosProp, ManejosDisponiveisComponent } from '../manejos-disponiveis/manejos-disponiveis.component'
import { ManejosUtilsService } from 'src/app/services/manejos-utils.service'
import { LotesService } from 'src/app/services/lotes.service'
import { Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { TabelaService } from 'src/app/services/tabela.service'

import { Keyboard } from '@capacitor/keyboard'
import { RelatorioManejosComponent } from '../relatorio-manejos/relatorio-manejos.component'
import { CalendarioEventoCadastrarPage } from '../../calendario/calendario-evento-cadastrar/calendario-evento-cadastrar.page'
import { AreasService } from 'src/app/services/areas.service'
import { FinanceiroFormularioTransacaoPage } from '../../financas/financeiro-formulario-transacao/financeiro-formulario-transacao.page'
import { FinancasService } from 'src/app/services/financas.service'
import { Categorias } from '../../financas/financas-categorias'
import { PopoverSeletorComponent } from 'src/app/components/popover-seletor/popover-seletor.component'

@Component({
  selector: 'app-modal-manejos',
  templateUrl: './cadastrar-manejos.component.html',
  styleUrls: ['./cadastrar-manejos.component.scss']
})
export class CadastrarManejos implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent
  @ViewChild('scrollTopElement') scrollTopElement: ElementRef

  public data: string = ''
  public editando: boolean = false
  public listaManejos: ResponseManejosData = null

  // Animais
  public manejosDisponiveis: ListaManejosProp[] = []
  public manejosSelecionados: ListaManejosProp[] = []
  public resumos: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } } = {}
  public labels = ColunasAnimaisManejo
  public animaisPorHash = {}
  public animais: IAnimal[] = []
  public animaisExibidos = []
  public animaisFiltrados = []
  public config: ConfigTabela = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: false,
    selecao: {
      ativar: true
    },
    impressaoTitulo: 'Animais',
    orderby: 'numeracao',
    orderbyDirection: 'asc',
    exibeTotais: false,
    excluirColunas: ['mais']
  }
  public carregamentoManejos: StatusTabela = 'sucesso'

  public dadosManejosAnimais: {
    [key: string]: {
      hash: string
      nutricao?: { [key: string]: { quantidade: number; duracao: number; edited: boolean; data_final: string } }
      pesagem?: {
        pesagem?: ManejoPesagem
      }
      ordenha?: {
        ordenha?: ManejoOrdenha
      }
      reprodutivo?: {
        [key: string]: ManejoReprodutivoUnitario
      }
      sanitario?: {
        [key: string]: ManejoSanitarioUnitario
      }
      geral?: {
        [key: string]: IManejoGeralUnitario
      }
      genetica?: {
        [key: string]: IManejoGeneticaUnitario
      }
      sanitarioEstoque?: { [key: string]: { quantidade: number; edited: boolean; observacao?: string } }
    }
  } = {}
  public salvando: '' | 'pesagem' | 'ordenha' | 'reprodutivo' | 'sanitario' | 'geral' | 'nutricao' | 'lotes' | 'areas' | 'genetica' = ''
  public textosSalvando = {
    pesagem: 'pesagens',
    ordenha: 'ordenhas',
    reprodutivo: 'manejos reprodutivos',
    sanitario: 'manejos sanitários'
  }
  public state: {
    data?: string
    editar?: boolean
    manejosParam?: string
    tipoManejo?: string
    hashsAnimais?: string[]
    animais?: IAnimal[]
    abaAtivaParams?: string
    abrirRelatorio?: boolean
  } = {}

  public calcularResumosTimeout: unknown

  public hashsSemensRapidos = []
  public hashsReprodutoresRapidos = []
  public montaNaturalOpcoesRapidasReprodutores: string[] = []
  public inseminacaoOpcoesRapidasEstoqueBiologico: string[] = []
  public inseminacaoOpcoesRapidasInseminador: string[] = []
  public lotesOpcoesRapidasLotes: string[] = []
  public lotesOpcoesRapidasConfinamentos: string[] = []
  public opcoesRapidasQuantidade = {}
  public animaisAssociados: { [key: string]: IAnimal } = {}
  public animaisExternosAssociados: { [key: string]: IReprodutorExterno } = {}
  public estoquesAssociados: { [key: string]: EstoqueBiologico } = {}
  public lotesAssociados: { [key: string]: ILote } = {}
  public areasAssociadas: { [key: string]: IArea } = {}
  public areasOpcoesRapidas: string[] = []
  public exposicoes: { [key: string]: Exposicao[] } = {}
  public exposicoesAssociadas = {}

  public paginate = {
    total: 1,
    limit: 30,
    offset: 0,
    currentPage: 1
  }

  public filtro = {
    termo: '',
    termoLote: '',
    termoArea: '',
    hashsAreas: [],
    hashsLotes: [],
    hashsAnimais: []
    // hashsLotes: ['$3UEqavVzHGstl9/@292']
  }

  public lotesPreenchidos: { [key: string]: ILote } = {}
  public hashsSelecionados: string[] = []

  public genealogiaParto: IGenealogiaPartoAgrupadosPorHash = {}

  public numerosDeControleJaUtilizados: string[]

  public propriedades: IPropriedade[] = []
  public lotesPorPropriedade: { [id: string]: ILote[] } = {}
  public lotesPorPropriedadeOpcoes: { [key: number]: { value: string; label: string }[] } = {}

  public vendas: { [key: number]: IFinancasCompraEVenda } = {}

  public abaAtivaParams: string | 'Animais' | 'Lotes' | 'Áreas' | 'Manejos' = ''
  public abaAtiva: string | 'Animais' | 'Lotes' | 'Áreas' | 'Manejos' = 'Animais'
  public secaoAtiva: string | 'Lista' | 'Manejos'

  // Lotes
  public resumosLotes: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } } = {}
  public manejosDisponiveisLotes: ListaManejosProp[] = []
  public manejosSelecionadosLotes: ListaManejosProp[] = []
  public dadosManejosLotes: {
    [key: string]: {
      hash: string
      nutricao?: { [key: string]: { quantidade: number; duracao: number; edited: boolean; data_final: string } }
      sanitarioEstoque?: { [key: string]: { quantidade: number; edited: boolean; observacao?: string } }
      manejosLotes?: {
        [key: string]: IManejoLote
      }
    }
  } = {}
  public paginateLotes = {
    total: 1,
    limit: 30,
    offset: 0,
    currentPage: 1
  }
  public labelsLotes = ColunasLotesManejo
  public lotes = []
  public configLotes = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: false,
    selecao: {
      ativar: true
    },
    impressaoTitulo: 'áreas',
    orderby: 'numeracao',
    orderbyDirection: 'asc',
    exibeTotais: false,
    excluirColunas: ['mais']
  }
  public hashsSelecionadosLotes = []
  public lotesPorHash = {}
  public lotesExibidos = []
  public lotesFiltrados = []

  // Áreas
  public manejosDisponiveisAreas: ListaManejosProp[] = []
  public manejosSelecionadosAreas: ListaManejosProp[] = []
  public resumosAreas: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } } = {}
  public labelsAreas = ColunasAreasManejo
  public areasPorHash = {}
  public areas: IArea[] = []
  public areasExibidas = []
  public areasFiltradas = []
  public configAreas: ConfigTabela = {
    preload: true,
    toolbar: false,
    filtrosEspecificos: false,
    selecao: {
      ativar: true
    },
    impressaoTitulo: 'áreas',
    orderby: 'numeracao',
    orderbyDirection: 'asc',
    exibeTotais: false,
    excluirColunas: ['mais']
  }
  public paginateAreas = {
    total: 1,
    limit: 30,
    offset: 0,
    currentPage: 1
  }
  public hashsSelecionadosAreas: string[] = []
  public dadosManejosAreas: {
    [key: string]: {
      hash: string
      nutricao?: { [key: string]: { quantidade: number; duracao: number; edited: boolean; data_final: string } },
      sanitarioEstoque?: { [key: string]: { quantidade: number; edited: boolean; observacao?: string } }
      areas?: {
        [key: string]: ManejoArea
      }
    }
  } = {}

  public areasEstoqueNutricional: EstoqueNutricional[] = []
  public lotesEstoqueNutricional: EstoqueNutricional[] = []
  public animaisEstoqueNutricional: EstoqueNutricional[] = []

  public animaisEstoqueSanitario: EstoqueFarmacia[] = []
  public lotesEstoqueSanitario: EstoqueFarmacia[] = []
  public areasEstoqueSanitario: EstoqueFarmacia[] = []

  public filtrosManejosAnimais = {}
  public filtrosManejosLotes = {}
  public filtrosManejosAreas = {}

  public ordenacaoAnimais: Ordenacao = { coluna: 'ordem', direcao: 'desc' }
  public ordenacaoLotes: Ordenacao = { coluna: 'nome', direcao: 'desc' }
  public ordenacaoAreas: Ordenacao = { coluna: 'nome', direcao: 'desc' }

  public hideFooter = false;

  public piquetes: {[key: string]: string[]} = {}
  public piquetesAssociados: {[key: string]: IAreaPiquete} = {}

  public pessoasAssociadas: {[key: string]: Pessoa} = {}

  public contas: IConta[] = []
  public custosManejo
  categoriasLabels = {}
  public carregamentoLancamentoFinanceiros: StatusTabela = 'sucesso'

  animalIndex = 1

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event: Event): boolean {
    return !this.manejosCtrl.campoManejoAlterado
  }

  constructor(
    private toast: ToastController,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private ref: ChangeDetectorRef,
    private router: Router,
    public manejosCtrl: ManejosService,
    public manejosUtils: ManejosUtilsService,
    private importacaoCtrl: ImportacaoService,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private propriedadesCtrl: PropriedadesService,
    private animaisCtrl: AnimaisService,
    private lotesCtrl: LotesService,
    private plt: Platform,
    private cdr: ChangeDetectorRef,
    private areasCtrl: AreasService,
    private tabelaService: TabelaService,
    private financasCtrl: FinancasService
  ) {
    Categorias.financeiro_saida.map((item) => {
      this.categoriasLabels[item.id] = item.nome
    })
  }

  async ngOnInit(): Promise<void> {
    const controleNomeNumero = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais
    const exibirNBrinco = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.mostra_brinco

    if (!exibirNBrinco) {
      this.config.excluirColunas.push('meta_nbrinco')
    }

    if (controleNomeNumero == 'numero') {
      this.config.excluirColunas.push('nome')
    }

    if (controleNomeNumero == 'nome') {
      this.config.excluirColunas.push('numeracao')
    }

    this.data = this.route.snapshot.paramMap.get('data')

    if (this.data == moment().format('YYYY-MM-DD')) {
      this.configLotes.excluirColunas.push('qtd_animais_na_data')
    }

    this.state = this.router.getCurrentNavigation().extras.state as {
      editar: boolean
      manejosParam?: string
      tipoManejo?: string
      hashsAnimais?: string[]
      animais?: IAnimal[]
      abaAtivaParams?: string
      abrirRelatorio?: boolean
    }
    if (this.state?.editar) {
      this.editando = true
    }
    if (this.state?.abaAtivaParams) {
      this.abaAtivaParams = this.state?.abaAtivaParams
      this.abaAtiva = this.abaAtivaParams
    }
    if (this.state?.manejosParam && this.state?.tipoManejo) {
      const { manejosParam, tipoManejo } = this.state

      const seletorCorreto = {
        outros: 'manejos',
        reprodutivo: 'manejosReprodutivos',
        sanitario: 'manejosSanitarios'
      }

      this.manejosDisponiveis = listaManejosDisponiveis[seletorCorreto[tipoManejo]].filter((manejo) =>
        manejosParam.includes(manejo.identificador)
      )
    } else {
      // if (!this.editando) {
      //   console.log('this.openModalManejosDisponiveis')
      //   this.openModalManejosDisponiveis({ tipoManejoSelecionado: state?.tipoManejo })
      // }
    }
    if (this.state?.hashsAnimais?.length && this.state?.animais?.length) {
      this.filtro.hashsAnimais = this.state?.hashsAnimais
      this.animais = this.state.animais
      this.preencherOrdemAnimais()
      await this.buscarAnimaisNaoListados()
      this.formatarManejos()

      this.filtrar()
      if (!this.editando) {
        this.openModalManejosDisponiveis()
      }
    }

    this.carregarManejosSalvos().then(() => {
      if (this.state?.abrirRelatorio) {
        this.openModalRelatorioManejos()
      }
    })
    this.getPropriedades()
    this.getNumerosDeControleJaEmUso()
    this.getCustosManejo()

    if (this.plt.is('capacitor')) {
      Keyboard.addListener('keyboardDidShow', () => {
        this.hideFooter = true
        this.cdr.detectChanges()
      })
      Keyboard.addListener('keyboardDidHide', () => {
        this.hideFooter = false
        this.cdr.detectChanges()
      })
    }
  }

  async ngOnDestroy(): Promise<void> {
    if (this.plt.is('capacitor')) {
      await Keyboard.removeAllListeners()
    }
  }

  async buscarAnimaisNaoListados(): Promise<void> {
    if (this.filtro.hashsAnimais.length > this.animais.length) {
      // buscar dados dos animais faltantes
      const hashsPresentes = this.animais.map((a) => a.hash)
      const hashsQueFaltam = this.filtro.hashsAnimais.filter((hash) => !hashsPresentes.includes(hash))
      if (hashsQueFaltam.length) {
        const [response, error] = await this.animaisCtrl.getAnimais({
          filtros: {
            hash_in: hashsQueFaltam
          },
          paginate: {
            limit: 10000,
            offset: 0
          }
        })

        if (response) {
          this.animais = [...response.dados, ...this.animais]
          this.preencherOrdemAnimais()
        }

        if (error) {
          console.log(error)
        }
      }
    }
  }

  async getNumerosDeControleJaEmUso(): Promise<void> {
    const [response, erro] = await this.importacaoCtrl.getNumeracoesExistentes()

    if (response) {
      this.numerosDeControleJaUtilizados = response
    }

    if (erro) {
      console.error(erro)
    }
  }

  async selecionarAnimais(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: {
        hashNotIn: this.animais.map((a) => a.hash),
        somenteInternos: true,
        selecionarMultiplos: true,
        permitirCadastro: true
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      const hashNotIn = this.animais.map((b) => b.hash)
      if (data.hashs?.length) {
        const [response, error] = await this.animaisCtrl.getAnimais({
          filtros: { hash_in: data.hashs },
          paginate: {
            limit: 10000
          }
        })

        if (response) {
          this.animais = [...response.dados.filter((a) => !hashNotIn.includes(a.hash)), ...this.animais]
        }
        if (error) {
          console.log(error)
        }
      } else {
        if (!hashNotIn.includes(data.hash)) {
          this.animais = [data, ...this.animais]
        }
      }

      this.preencherOrdemAnimais()
      this.formatarManejos()

      if (data.lotes?.length) {
        this.filtro.hashsLotes = data.lotes.map((item) => item.value)
        data.lotes.forEach((item) => {
          this.lotesPreenchidos[item.value] = item
        })
        this.filtrar()
      }

      this.verificarGenealogiaParto()

      setTimeout(() => {
        this.scrollTop()
      }, 300)
    }
  }

  async selecionarAreas(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAreasPage,
      componentProps: {
        hashNotIn: this.areas.map((a) => a.hash),
        multiplos: true,
        permitirCadastro: true
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      const hashNotIn = this.areas.map((b) => b.hash)
      const novasAreas = data.areas.filter((a) => !hashNotIn.includes(a.hash))

      console.log({ novasAreas })

      this.areas = [...novasAreas, ...this.areas]

      console.log('this.areas', this.areas)

      this.filtrar()

      this.formatarManejosAreas()

      // if (data.lotes?.length) {
      //   this.filtro.hashsLotes = data.lotes.map((item) => item.value)
      //   data.lotes.forEach((item) => {
      //     this.lotesPreenchidos[item.value] = item
      //   })
      //   this.filtraAnimais()
      // }

      this.verificarDadosPiquetes()

      setTimeout(() => {
        this.scrollTop()
      }, 300)
    }
  }

  scrollTop(): void {
    this.scrollTopElement?.nativeElement?.scrollIntoView({ behavior: 'smooth' })
    this.content?.scrollToTop(300)
  }

  alterouItensEstoque(): void {
    setTimeout(() => {
      this.scrollTopElement?.nativeElement?.scrollIntoView({ behavior: 'instant' })
      this.content?.scrollToTop(0)
    }, 350)
  }

  async verificarGenealogiaParto(): Promise<void> {
    const animaisFemeas = this.animais.filter((animal) => animal.sexo === 'femea')
    const hashsAnimaisFemeas = animaisFemeas.map((animal) => animal.hash)
    const isManejoPartoDisponivel = this.manejosDisponiveis.some((manejo) => manejo.procedimento === 'parto')

    if (animaisFemeas?.length && isManejoPartoDisponivel) {
      const maesNaoConsultadas = hashsAnimaisFemeas.filter((hash) => !this.genealogiaParto[hash])

      if (maesNaoConsultadas.length) {
        this.genealogiaParto = await this.getGenealogiaParto(hashsAnimaisFemeas)
        this.ref.detectChanges()
      }
    }
  }

  async getGenealogiaParto(hashsAnimais: string[]): Promise<IGenealogiaPartoAgrupadosPorHash> {
    const [response, erro] = await this.manejosCtrl.getGenealogiaParto(this.data, hashsAnimais)

    if (response) {
      return response
    }

    if (erro) {
      console.error(erro)
    }
  }

  async openModalManejosDisponiveis(propsExtras?: { tipoManejoSelecionado: string }): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ManejosDisponiveisComponent,
      componentProps: {
        exibicaoAtiva: this.abaAtiva,
        filtrosAtivosAnimais: this.manejosDisponiveis,
        filtrosAtivosLotes: this.manejosDisponiveisLotes,
        filtrosAtivosAreas: this.manejosDisponiveisAreas,
        ...propsExtras
      },
      cssClass: 'modal-manejos-disponiveis'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()
    if (data) {
      console.log('data', data)
      const novosManejosAnimais = data.manejosSelecionadosAnimais.filter(
        (a) => !this.manejosDisponiveis.map((a) => a.identificador).includes(a.identificador) || a.tipo === 'sanitario'
      )
      this.manejosDisponiveis = [...novosManejosAnimais, ...this.manejosDisponiveis]
      const novosManejosLotes = data.manejosSelecionadosLotes.filter(
        (a) => !this.manejosDisponiveisLotes.map((a) => a.identificador).includes(a.identificador)
      )
      this.manejosDisponiveisLotes = [...novosManejosLotes, ...this.manejosDisponiveisLotes]
      const novosManejosAreas = data.manejosSelecionadosAreas.filter(
        (a) => !this.manejosDisponiveisAreas.map((a) => a.identificador).includes(a.identificador)
      )
      this.manejosDisponiveisAreas = [...novosManejosAreas, ...this.manejosDisponiveisAreas]

      if (novosManejosAreas.length) {
        this.abaAtiva = 'Áreas'
      }
      if (novosManejosLotes.length) {
        this.abaAtiva = 'Lotes'
      }
      if (novosManejosAnimais.length) {
        this.abaAtiva = 'Animais'
      }

      this.verificarGenealogiaParto()
      this.verificarDadosPiquetes()
      this.formatarManejos()
      this.formatarManejosLotes()
      this.formatarManejosAreas()
      this.filtrar()
    }
  }

  preencherOrdemAnimais(): void {
    let adicionou = false
    this.animais.reverse().map(a => {
      if (!a.ordem) {
        a.ordem = this.animalIndex
        this.animalIndex++
        adicionou = true
      }
    })
    this.animais.reverse()

    if (adicionou) {
      this.ordenacaoAnimais.coluna = 'ordem'
      this.ordenacaoAnimais.direcao = 'desc'
      this.filtrar()
    }
  }

  async carregarManejosSalvos(): Promise<void> {
    this.carregamentoManejos = 'carregando'
    const [response, error] = await this.manejosCtrl.getManejosData(this.data, {})

    if (response) {
      this.listaManejos = response
      const {
        animais,
        gerais,
        genetica,
        ordenhas,
        pesagens,
        reprodutivos,
        sanitarios,
        associados,
        lotes,
        areas,
        nutricional,
        sanitarioEstoque,
        manejosLotes,
        manejosAreas
      } = response
      const {
        animaisExternos,
        animaisInternos,
        estoquesBiologicos,
        estoqueNutricional,
        estoqueSanitario,
        lotes: lotesAssociados,
        areas: areasAssociadas,
        piquetes: piquetesAssociados,
        pessoas
      } = associados
      animaisExternos.map((a) => (this.animaisExternosAssociados[a.hash] = a))
      animaisInternos.map((a) => (this.animaisAssociados[a.hash] = a))
      estoquesBiologicos.map((a) => (this.estoquesAssociados[a.hash] = a))
      lotesAssociados.map((l) => (this.lotesAssociados[l.hash] = l))
      areasAssociadas.map((a) => (this.areasAssociadas[a.hash] = a))
      piquetesAssociados.map((p) => (this.piquetesAssociados[p.hash] = p))
      pessoas.map((p) => (this.pessoasAssociadas[p.hash] = p))

      if (animais && animais.length) {
        this.animais = [...this.animais, ...animais.filter((n) => !this.animais.map((a) => a.hash).includes(n.hash))]
        this.preencherOrdemAnimais()
      }
      if (lotes && lotes.length) {
        this.lotes = [...this.lotes, ...lotes.filter((n) => !this.lotes.map((a) => a.hash).includes(n.hash))]
      }
      if (areas && areas.length) {
        this.areas = [...this.areas, ...areas.filter((n) => !this.areas.map((a) => a.hash).includes(n.hash))]
      }

      if (pesagens.length) {
        const procedimentos = ['pesagem']
        this.manejosDisponiveis = [
          ...this.manejosDisponiveis,
          ...listaManejosDisponiveis.manejos.filter((manejo) => procedimentos.includes(manejo.procedimento))
        ]
      }

      if (ordenhas.length) {
        const procedimentos = ['ordenha']
        this.manejosDisponiveis = [
          ...this.manejosDisponiveis,
          ...listaManejosDisponiveis.manejos.filter((manejo) => procedimentos.includes(manejo.procedimento))
        ]
      }

      if (gerais.length) {
        const procedimentos = gerais.map((r) => r['procedimento'])
        console.warn({
          procedimentos,
          filter: listaManejosDisponiveis.manejos.filter((manejo) =>
            procedimentos.includes(manejo.procedimento as IManejoGeralTipos)
          )
        })
        this.manejosDisponiveis = [
          ...this.manejosDisponiveis,
          ...listaManejosDisponiveis.manejos.filter((manejo) =>
            procedimentos.includes(manejo.procedimento as IManejoGeralTipos)
          )
        ]

        // verificar propriedades que precisamos buscar os lotes
        const idsPropriedadesBuscarLotes = [
          ...new Set(gerais.map((manejo) => manejo.transf_propriedade_id).filter(Boolean))
        ]
        idsPropriedadesBuscarLotes.map((id) => {
          this.getLotes(id)
        })
      }
      
      if (genetica.length) {
        const procedimentos = genetica.map((r) => r['procedimento'])

        this.manejosDisponiveis = [
          ...this.manejosDisponiveis,
          ...listaManejosDisponiveis.manejosGenetica.filter((manejo) =>
            procedimentos.includes(manejo.procedimento as IManejoGeneticaTipos)
          )
        ]
      }

      if (reprodutivos.length) {
        const procedimentos = reprodutivos.map((r) => r['procedimento'])
        this.manejosDisponiveis = [
          ...this.manejosDisponiveis,
          ...listaManejosDisponiveis.manejosReprodutivos.filter((manejo) =>
            procedimentos.includes(manejo.procedimento as IManejoReprodutivoTipos)
          )
        ]
      }

      if (sanitarios.length) {
        sanitarios.map((r) => {
          const hash_procedimento = r['hash_procedimento']
          const procedimento = r['procedimento']
          const descricao = r['descricao']
          let manejoPadrao = listaManejosDisponiveis.manejosSanitarios.find(
            (manejo) => procedimento === manejo.identificador
          )

          if (manejoPadrao) {
            manejoPadrao = { ...manejoPadrao }
            manejoPadrao['descricao'] = descricao
            manejoPadrao['procedimento'] = hash_procedimento
            this.manejosDisponiveis = [...this.manejosDisponiveis, manejoPadrao]
          }
        })

        const usados = []
        this.manejosDisponiveis = this.manejosDisponiveis
          .map((item) => {
            if (!usados.includes(item?.procedimento)) {
              usados.push(item.procedimento)
              return item
            }
          })
          .filter(Boolean)
      }

      if (manejosLotes.length) {
        const procedimentos = manejosLotes.map((r) => r['procedimento'])
        this.manejosDisponiveisLotes = [
          ...this.manejosDisponiveisLotes,
          ...listaManejosDisponiveis.manejosLotes.filter((manejo) => procedimentos.includes(manejo.procedimento as 'observacao' | 'movimentacao-entre-areas'))
        ]
      }

      if (manejosAreas.length) {
        const procedimentos = manejosAreas.map((r) => r['procedimento'])
        this.manejosDisponiveisAreas = [
          ...this.manejosDisponiveisAreas,
          ...listaManejosDisponiveis.manejosAreas.filter((manejo) => procedimentos.includes(manejo.procedimento))
        ]
      }

      const nutricionalAnimais = nutricional ? nutricional.filter((a) => !!a.hash_animal) : []
      const nutricionalLotes = nutricional ? nutricional.filter((a) => !!a.hash_lote) : []
      const nutricionalAreas = nutricional ? nutricional.filter((a) => !!a.hash_area) : []

      const sanitarioEstoqueAnimais = sanitarioEstoque ? sanitarioEstoque.filter((a) => !!a.hash_animal) : []
      const sanitarioEstoqueLotes = sanitarioEstoque ? sanitarioEstoque.filter((a) => !!a.hash_lote) : []
      const sanitarioEstoqueAreas = sanitarioEstoque ? sanitarioEstoque.filter((a) => !!a.hash_area) : []

      if (nutricionalAnimais.length) {
        this.manejosDisponiveis = [
          ...this.manejosDisponiveis,
          ...listaManejosDisponiveis.manejos.filter((manejo) => manejo.procedimento == 'nutricao')
        ]
      }

      if (nutricionalLotes.length) {
        this.manejosDisponiveisLotes = [
          ...this.manejosDisponiveisLotes,
          ...listaManejosDisponiveis.manejosLotes.filter((manejo) => manejo.procedimento == 'nutricao')
        ]
      }

      if (nutricionalAreas.length) {
        this.manejosDisponiveisAreas = [
          ...this.manejosDisponiveisAreas,
          ...listaManejosDisponiveis.manejosAreas.filter((manejo) => manejo.procedimento == 'nutricao')
        ]
      }

      if (sanitarioEstoqueAnimais.length) {
        this.manejosDisponiveis = [
          ...this.manejosDisponiveis,
          ...listaManejosDisponiveis.manejosSanitarios.filter((manejo) => manejo.procedimento == 'sanitarioEstoque')
        ]
      }

      if (sanitarioEstoqueLotes.length) {
        this.manejosDisponiveisLotes = [
          ...this.manejosDisponiveisLotes,
          ...listaManejosDisponiveis.manejosSanitarios.filter((manejo) => manejo.procedimento == 'sanitarioEstoque')
        ]
      }

      if (sanitarioEstoqueAreas.length) {
        this.manejosDisponiveisAreas = [
          ...this.manejosDisponiveisAreas,
          ...listaManejosDisponiveis.manejosSanitarios.filter((manejo) => manejo.procedimento == 'sanitarioEstoque')
        ]
      }

      this.areasEstoqueNutricional = estoqueNutricional
        ? estoqueNutricional.filter((item) =>
          nutricionalAreas.map((n) => n.nutricional_hash_estoque).includes(item.hash)
        )
        : []

      this.lotesEstoqueNutricional = estoqueNutricional
        ? estoqueNutricional.filter((item) =>
          nutricionalLotes.map((n) => n.nutricional_hash_estoque).includes(item.hash)
        )
        : []

      this.animaisEstoqueNutricional = estoqueNutricional
        ? estoqueNutricional.filter((item) =>
          nutricionalAnimais.map((n) => n.nutricional_hash_estoque).includes(item.hash)
        )
        : []

      this.animaisEstoqueSanitario = estoqueSanitario ? estoqueSanitario.filter((item) => sanitarioEstoqueAnimais.map((n) => n.sanitario_hash_estoque).includes(item.hash)) : []
      this.lotesEstoqueSanitario = estoqueSanitario ? estoqueSanitario.filter((item) => sanitarioEstoqueLotes.map((n) => n.sanitario_hash_estoque).includes(item.hash)) : []
      this.areasEstoqueSanitario = estoqueSanitario ? estoqueSanitario.filter((item) => sanitarioEstoqueAreas.map((n) => n.sanitario_hash_estoque).includes(item.hash)) : []

      if (!this.abaAtivaParams) {
        if (!this.manejosDisponiveis.length && this.manejosDisponiveisLotes.length) {
          this.abaAtiva = 'Lotes'
        }
        if (
          !this.manejosDisponiveis.length &&
          !this.manejosDisponiveisLotes.length &&
          this.manejosDisponiveisAreas.length
        ) {
          this.abaAtiva = 'Áreas'
        }
      }

      this.formatarManejos({ pesagens, ordenhas, reprodutivos, sanitarios, gerais, nutricao: nutricionalAnimais, sanitarioEstoque: sanitarioEstoqueAnimais, geneticos: genetica })
      this.formatarManejosLotes({ nutricao: nutricionalLotes, manejosLotes, sanitarioEstoque: sanitarioEstoqueLotes })
      this.formatarManejosAreas({ nutricao: nutricionalAreas, manejosAreas, sanitarioEstoque: sanitarioEstoqueAreas })

      if (
        ((!this.manejosDisponiveis.length &&
          !this.manejosDisponiveisLotes.length &&
          !this.manejosDisponiveisAreas.length) ||
          this.state?.tipoManejo) &&
        !this.state?.hashsAnimais
      ) {
        this.openModalManejosDisponiveis({ tipoManejoSelecionado: this.state?.tipoManejo })
      }

      this.calcularResumosHandle()
      this.carregamentoManejos = 'sucesso'

      this.montaNaturalOpcoesRapidasReprodutores = []
      this.inseminacaoOpcoesRapidasEstoqueBiologico = []
      this.inseminacaoOpcoesRapidasInseminador = []
      this.lotesOpcoesRapidasLotes = []
      Object.values(this.dadosManejosAnimais).map((d) => {
        const hashReprodutor =
          d['reprodutivo']?.['monta-natural']?.cobertura_hash_reprodutor ||
          d['reprodutivo']?.['monta-natural']?.cobertura_hash_reprodutor_externo
        if (
          hashReprodutor &&
          this.montaNaturalOpcoesRapidasReprodutores.length < 3 &&
          !this.montaNaturalOpcoesRapidasReprodutores.includes(hashReprodutor)
        ) {
          this.montaNaturalOpcoesRapidasReprodutores.push(hashReprodutor)
        }

        const hashEstoqueBiologico =
          d['reprodutivo']?.['inseminacao']?.inseminacao_semen || d['reprodutivo']?.['inseminacao']?.inseminacao_embriao
        if (
          hashEstoqueBiologico &&
          this.inseminacaoOpcoesRapidasEstoqueBiologico.length < 3 &&
          !this.inseminacaoOpcoesRapidasEstoqueBiologico.includes(hashEstoqueBiologico)
        ) {
          this.inseminacaoOpcoesRapidasEstoqueBiologico.push(hashEstoqueBiologico)
        }

        const hashInseminador = d['reprodutivo']?.['inseminacao']?.inseminacao_inseminador

        if (
          hashInseminador &&
          this.inseminacaoOpcoesRapidasInseminador.length < 3 &&
          !this.inseminacaoOpcoesRapidasInseminador.includes(hashInseminador)
        ) {
          this.inseminacaoOpcoesRapidasInseminador.push(hashInseminador)
        }

        const hashLote = d['geral']?.['movimentacao-lote']?.hash_lote
        if (hashLote && this.lotesOpcoesRapidasLotes.length < 3 && !this.lotesOpcoesRapidasLotes.includes(hashLote)) {
          this.lotesOpcoesRapidasLotes.push(hashLote)
        }

        const hashConfinamento = d['geral']?.['movimentacao-confinamento']?.hash_confinamento
        if (
          hashConfinamento &&
          this.lotesOpcoesRapidasConfinamentos.length < 3 &&
          !this.lotesOpcoesRapidasConfinamentos.includes(hashConfinamento)
        ) {
          this.lotesOpcoesRapidasConfinamentos.push(hashConfinamento)
        }
      })

      this.verificarGenealogiaParto()
      this.ref.detectChanges()
    }

    if (error) {
      console.log(error)
      this.carregamentoManejos = 'erro'
    }
  }

  pesagemPadrao(hashAnimal: string): ManejoPesagem {
    return {
      hash_animal: hashAnimal,
      data_pesagem: this.data,
      peso: null,
      identificador: 'controle'
    }
  }

  ordenhaPadrao(hashAnimal: string): ManejoOrdenha {
    return {
      hash_animal: hashAnimal,
      data: this.data,
      quantidade: null,
      primeira_ordenha: null,
      segunda_ordenha: null,
      terceira_ordenha: null
    }
  }

  reprodutivoPadrao(opcoes: { hashAnimal: string; procedimento: IManejoReprodutivoTipos }): ManejoReprodutivoUnitario {
    const { hashAnimal, procedimento } = opcoes
    return {
      hash_animal: hashAnimal,
      data: this.data,
      procedimento
    }
  }

  geralPadrao(opcoes: { hashAnimal: string; procedimento: IManejoGeralTipos }): IManejoGeralUnitario {
    const { hashAnimal, procedimento } = opcoes
    return {
      hash_animal: hashAnimal,
      data: this.data,
      procedimento
    }
  }

  geneticaPadrao(opcoes: { hashAnimal: string; procedimento: IManejoGeneticaTipos }): IManejoGeneticaUnitario {
    const { hashAnimal, procedimento } = opcoes
    return {
      hash_animal: hashAnimal,
      data: this.data,
      procedimento
    }
  }

  sanitarioPadrao(opcoes: {
    hashAnimal: string
    descricao: string
    procedimento: IManejoSanitarioTipos
    hash_procedimento: string
  }): ManejoSanitarioUnitario {
    const { hashAnimal, descricao, procedimento, hash_procedimento } = opcoes
    return {
      hash_animal: hashAnimal,
      data: this.data,
      descricao,
      procedimento,
      hash_procedimento,
      aplicado: false
    }
  }

  chuvaPadrao(hashArea: string): ManejoArea {
    return {
      hash_area: hashArea,
      chuva_hora: null,
      chuva_quantidade: null
    }
  }

  movimentacaoEntreAreasPadrao(hashLote: string): IManejoLote {
    return {
      data: this.data,
      procedimento: 'movimentacao-entre-areas',
      hash_lote: hashLote,
      hash_area_destino: null
    }
  }

  nutricaoPadrao(hashArea: string): ManejoNutricional {
    return {
      hash_area: hashArea,
      nutricional_hash_estoque: null,
      nutricional_quantidade: null,
      nutricional_duracao: null
    }
  }

  formatarManejos(opcoes?: {
    pesagens?: ManejoPesagem[]
    ordenhas?: ManejoOrdenha[]
    gerais?: IManejoGeralUnitario[]
    geneticos?: IManejoGeneticaUnitario[]
    reprodutivos?: ManejoReprodutivoUnitario[]
    sanitarios?: ManejoSanitarioUnitario[]
    nutricao?: ManejoNutricional[]
    sanitarioEstoque?: ManejoSanitarioEstoque[]
    alterouItensEstoque?: boolean
  }): void {
    this.consultarExposicoes()
    // preencher todos os animais com manejos vazios
    this.animais.map(async (animal) => {
      this.dadosManejosAnimais[animal.hash] = this.dadosManejosAnimais[animal.hash] || {
        hash: animal.hash,
        geral: {},
        genetica: {},
        nutricao: {},
        pesagem: {},
        ordenha: {},
        reprodutivo: {},
        sanitarioEstoque: {},
        sanitario: {}
      }

      for (const manejo of this.manejosDisponiveis) {
        if (manejo.procedimento === 'nutricao' && !this.dadosManejosAnimais[animal.hash].nutricao) {
          this.dadosManejosAnimais[animal.hash].nutricao = {}
        }
        if (manejo.procedimento === 'nutricao') {
          for (const estoqueSingle of this.animaisEstoqueNutricional) {
            if (!this.dadosManejosAnimais[animal.hash].nutricao[estoqueSingle.hash]) {
              this.dadosManejosAnimais[animal.hash].nutricao[estoqueSingle.hash] = {
                quantidade: null,
                duracao: null,
                edited: false,
                data_final: ''
              }
            }
          }
        }
        if (manejo.procedimento === 'pesagem' && !this.dadosManejosAnimais[animal.hash].pesagem['pesagem']) {
          this.dadosManejosAnimais[animal.hash].pesagem['pesagem'] = this.pesagemPadrao(animal.hash)
        }
        if (manejo.procedimento === 'ordenha' && !this.dadosManejosAnimais[animal.hash].ordenha['ordenha']) {
          this.dadosManejosAnimais[animal.hash].ordenha['ordenha'] = this.ordenhaPadrao(animal.hash)
        }
        if (
          manejo.tipo == 'reprodutivo' &&
          !this.dadosManejosAnimais[animal.hash]['reprodutivo'][manejo.procedimento]
        ) {
          this.dadosManejosAnimais[animal.hash]['reprodutivo'][manejo.procedimento] = this.reprodutivoPadrao({
            hashAnimal: animal.hash,
            procedimento: manejo.procedimento as IManejoReprodutivoTipos
          })
        }
        if (manejo.tipo == 'geral' && !this.dadosManejosAnimais[animal.hash]['geral'][manejo.procedimento]) {
          this.dadosManejosAnimais[animal.hash]['geral'][manejo.procedimento] = this.geralPadrao({
            hashAnimal: animal.hash,
            procedimento: manejo.procedimento as IManejoGeralTipos
          })
        }
        if (manejo.tipo == 'genetica' && !this.dadosManejosAnimais[animal.hash]['genetica'][manejo.procedimento]) {
          this.dadosManejosAnimais[animal.hash]['genetica'][manejo.procedimento] = this.geneticaPadrao({
            hashAnimal: animal.hash,
            procedimento: manejo.procedimento as IManejoGeneticaTipos
          })
        }

        if (manejo.tipo == 'sanitario' && !this.dadosManejosAnimais[animal.hash]['sanitario'][manejo.procedimento]) {
          this.dadosManejosAnimais[animal.hash]['sanitario'][manejo.procedimento] = this.sanitarioPadrao({
            hashAnimal: animal.hash,
            descricao: manejo.descricao,
            procedimento: manejo.identificador as IManejoSanitarioTipos,
            hash_procedimento: manejo.procedimento
          })
        }

        if (manejo.tipo == 'sanitarioEstoque' && !this.dadosManejosAnimais[animal.hash]['sanitarioEstoque'][manejo.procedimento]) {
          for (const estoqueSingle of this.animaisEstoqueSanitario) {
            if (!this.dadosManejosAnimais[animal.hash]['sanitarioEstoque'][estoqueSingle.hash]) {
              this.dadosManejosAnimais[animal.hash]['sanitarioEstoque'][estoqueSingle.hash] = {
                quantidade: null,
                edited: false,
              }
            }
          }
        }
      }
    })

    // após isso, se tiver passando preenchimento, preencher
    const { pesagens, ordenhas, reprodutivos, sanitarios, gerais, nutricao, sanitarioEstoque, geneticos } = opcoes || {}

    if (pesagens?.length) {
      pesagens.map((pesagem) => {
        this.dadosManejosAnimais[pesagem.hash_animal].pesagem['pesagem'] = pesagem
      })
    }

    if (ordenhas?.length) {
      ordenhas.map((ordenha) => {
        this.dadosManejosAnimais[ordenha.hash_animal].ordenha['ordenha'] = ordenha
      })
    }

    if (reprodutivos?.length) {
      reprodutivos.map((reprodutivo) => {
        this.dadosManejosAnimais[reprodutivo.hash_animal].reprodutivo[reprodutivo.procedimento] = reprodutivo
      })
    }

    if (gerais?.length) {
      gerais.map((geral) => {
        this.dadosManejosAnimais[geral.hash_animal].geral[geral.procedimento] = geral
      })
    }

    if (geneticos?.length) {
      geneticos.map((genetica) => {
        this.dadosManejosAnimais[genetica.hash_animal].genetica[genetica.procedimento] = genetica
      })
    }

    if (sanitarios?.length) {
      sanitarios.map((sanitario) => {
        sanitario['aplicado'] = true
        this.dadosManejosAnimais[sanitario.hash_animal].sanitario[sanitario.hash_procedimento] = sanitario
      })
    }

    if (nutricao?.length) {
      this.animais.forEach((animal) => {
        const nutricaoDoAnimal = nutricao.filter((n) => n.hash_animal == animal.hash)
        nutricaoDoAnimal.map((manejo) => {
          this.dadosManejosAnimais[animal.hash].nutricao[manejo.nutricional_hash_estoque] = {
            quantidade: manejo.nutricional_quantidade,
            duracao: manejo.nutricional_duracao,
            edited: false,
            data_final: ''
          }
        })
      })
    }

    if (sanitarioEstoque?.length) {
      this.animais.forEach((animal) => {
        const sanitarioEstoqueDoAnimal = sanitarioEstoque.filter((n) => n.hash_animal == animal.hash)
        sanitarioEstoqueDoAnimal.map((manejo) => {
          if (
            (!this.opcoesRapidasQuantidade['Animais' + manejo.sanitario_hash_estoque] ||
              this.opcoesRapidasQuantidade['Animais' + manejo.sanitario_hash_estoque].length < 3)) {
            const casaDecimal = manejo.sanitario_quantidade?.toString().split('.').pop()
            const quantidadeFormatada = Number(casaDecimal === '0' ? String(manejo.sanitario_quantidade).split('.').shift() : manejo.sanitario_quantidade)
            this.opcoesRapidasQuantidade['Animais' + manejo.sanitario_hash_estoque] = [...new Set([...this.opcoesRapidasQuantidade['Animais' + manejo.sanitario_hash_estoque] || []].concat(quantidadeFormatada))]
          }
          this.dadosManejosAnimais[animal.hash].sanitarioEstoque[manejo.sanitario_hash_estoque] = {
            observacao: manejo.observacao,
            quantidade: manejo.sanitario_quantidade,
            edited: false,
          }
        })
      })
    }

    this.zeraPagination()
    this.ordenacaoLocal()
    this.ref.detectChanges()
    this.calcularResumos()

    if (opcoes?.alterouItensEstoque) {
      this.alterouItensEstoque()
    }
  }

  formatarManejosLotes(opcoes?: {
    manejosLotes?: IManejoLote[]
    nutricao?: ManejoNutricional[]
    sanitarioEstoque?: ManejoSanitarioEstoque[]
    alterouItensEstoque?: boolean
  }): void {
    // preencher todos os areas com manejos vazios
    this.lotes.map((lote) => {
      this.dadosManejosLotes[lote.hash] = this.dadosManejosLotes[lote.hash] || {
        hash: lote.hash,
        nutricao: {},
        sanitarioEstoque: {},
        manejosLotes: {}
      }

      for (const manejo of this.manejosDisponiveisLotes) {
        if (
          manejo.procedimento === 'movimentacao-entre-areas' &&
          !this.dadosManejosLotes[lote.hash].manejosLotes['movimentacao-entre-areas']
        ) {
          this.dadosManejosLotes[lote.hash].manejosLotes['movimentacao-entre-areas'] =
            this.movimentacaoEntreAreasPadrao(lote.hash)
        }
        if (manejo.procedimento === 'nutricao' && !this.dadosManejosLotes[lote.hash].nutricao) {
          this.dadosManejosLotes[lote.hash].nutricao = {}
        }
        if (manejo.procedimento === 'nutricao') {
          for (const estoqueSingle of this.lotesEstoqueNutricional) {
            if (!this.dadosManejosLotes[lote.hash].nutricao[estoqueSingle.hash]) {
              this.dadosManejosLotes[lote.hash].nutricao[estoqueSingle.hash] = {
                quantidade: null,
                duracao: null,
                edited: false,
                data_final: ''
              }
            }
          }
        }
        if (manejo.tipo == 'sanitarioEstoque' && !this.dadosManejosLotes[lote.hash]['sanitarioEstoque'][manejo.procedimento]) {
          for (const estoqueSingle of this.lotesEstoqueSanitario) {
            if (!this.dadosManejosLotes[lote.hash]['sanitarioEstoque'][estoqueSingle.hash]) {
              this.dadosManejosLotes[lote.hash]['sanitarioEstoque'][estoqueSingle.hash] = {
                quantidade: null,
                edited: false,
              }
            }
          }
        }
        if (manejo.procedimento === 'observacao' && !this.dadosManejosLotes[lote.hash].manejosLotes['observacao']) {
          this.dadosManejosLotes[lote.hash].manejosLotes['observacao'] = {
            observacao: '',
            data: this.data,
            procedimento: 'observacao',
            hash_lote: lote.hash
          }
        }
        if (manejo.procedimento === 'escore-fezes' && !this.dadosManejosLotes[lote.hash].manejosLotes['escore-fezes']) {
          this.dadosManejosLotes[lote.hash].manejosLotes['escore-fezes'] = {
            observacao: '',
            escore_fezes: '',
            data: this.data,
            procedimento: 'escore-fezes',
            hash_lote: lote.hash
          }
        }
      }
    })

    console.log('dadosManejosLotes: ', this.dadosManejosLotes)

    // após isso, se tiver passando preenchimento, preencher

    const { nutricao, manejosLotes, sanitarioEstoque } = opcoes || {}

    if (nutricao?.length) {
      this.lotes.forEach((lote) => {
        const nutricaoDoLote = nutricao.filter((n) => n.hash_lote == lote.hash)
        nutricaoDoLote.map((manejo) => {
          this.dadosManejosLotes[lote.hash].nutricao[manejo.nutricional_hash_estoque] = {
            quantidade: manejo.nutricional_quantidade,
            duracao: manejo.nutricional_duracao,
            edited: false,
            data_final: ''
          }
        })
      })
    }

    if (manejosLotes?.length) {
      this.lotes.forEach((lote) => {
        const manejoDoLote = manejosLotes.filter((n) => n.hash_lote == lote.hash)
        manejoDoLote.map((manejo) => {
          const hashArea = manejo?.hash_area_destino
          if (hashArea && this.areasOpcoesRapidas.length < 3 && !this.areasOpcoesRapidas.includes(hashArea)) {
            this.areasOpcoesRapidas.push(hashArea)
          }

          this.dadosManejosLotes[lote.hash].manejosLotes[manejo.procedimento] = manejo
        })
      })
    }

    if (sanitarioEstoque?.length) {
      this.lotes.forEach((lote) => {
        const sanitarioEstoqueDoAnimal = sanitarioEstoque.filter((n) => n.hash_lote == lote.hash)
        sanitarioEstoqueDoAnimal.map((manejo) => {
          if (
            (!this.opcoesRapidasQuantidade['Lotes' + manejo.sanitario_hash_estoque] ||
              this.opcoesRapidasQuantidade['Lotes' + manejo.sanitario_hash_estoque].length < 3)) {
            const casaDecimal = manejo.sanitario_quantidade?.toString().split('.').pop()
            const quantidadeFormatada = casaDecimal === '0' ? String(manejo.sanitario_quantidade).split('.').shift() : manejo.sanitario_quantidade
            this.opcoesRapidasQuantidade['Lotes' + manejo.sanitario_hash_estoque] = [...new Set([...this.opcoesRapidasQuantidade['Lotes' + manejo.sanitario_hash_estoque] || []].concat(quantidadeFormatada))]
          }
          this.dadosManejosLotes[lote.hash].sanitarioEstoque[manejo.sanitario_hash_estoque] = {
            observacao: manejo.observacao,
            quantidade: manejo.sanitario_quantidade,
            edited: false,
          }
        })
      })
    }

    // this.zeraPagination()
    this.ordenacaoLocal()
    this.ref.detectChanges()
    // this.calcularResumos()

    if (opcoes?.alterouItensEstoque) {
      this.alterouItensEstoque()
    }
  }

  formatarManejosAreas(opcoes?: {
    chuva?: ManejoArea[]
    nutricao?: ManejoNutricional[]
    sanitarioEstoque?: ManejoSanitarioEstoque[]
    manejosAreas?: ManejoArea[]
    alterouItensEstoque?: boolean
  }): void {
    // preencher todos os areas com manejos vazios
    this.areas.map((area) => {
      this.dadosManejosAreas[area.hash] = this.dadosManejosAreas[area.hash] || {
        hash: area.hash,
        nutricao: {},
        sanitarioEstoque: {},
        areas: {}
      }

      for (const manejo of this.manejosDisponiveisAreas) {
        if (manejo.procedimento === 'chuva' && !this.dadosManejosAreas[area.hash].areas['chuva']) {
          this.dadosManejosAreas[area.hash].areas['chuva'] = this.chuvaPadrao(area.hash)
        }

        if (manejo.procedimento === 'altura-capim') {
          
          this.dadosManejosAreas[area.hash].areas['altura-capim'] = this.dadosManejosAreas[area.hash].areas['altura-capim'] || {}

          for (const hash_area of Object.keys(this.piquetes)) {
            for (const piqueteHash of this.piquetes[hash_area]) {
              if (!this.dadosManejosAreas[hash_area]?.areas['altura-capim'][piqueteHash] && this.dadosManejosAreas[hash_area]) {
                this.dadosManejosAreas[hash_area].areas['altura-capim'][piqueteHash] = {
                  altura_capim: null,
                  data: this.data,
                  edited: false,
                }
              }
            }
          }
        }

        if (
          manejo.procedimento === 'pastejo-rotacionado' &&
          !this.dadosManejosAreas[area.hash].areas['pastejo-rotacionado']
        ) {
          this.dadosManejosAreas[area.hash].areas['pastejo-rotacionado'] =
            {
              data: this.data,
              procedimento: 'pastejo-rotacionado',
              hash_area: area.hash,
              hash_piquete: null
            }
        }

        if (manejo.procedimento === 'nutricao' && !this.dadosManejosAreas[area.hash].nutricao) {
          this.dadosManejosAreas[area.hash].nutricao = {}
        }
        if (manejo.procedimento === 'nutricao') {
          for (const estoqueSingle of this.areasEstoqueNutricional) {
            if (!this.dadosManejosAreas[area.hash].nutricao[estoqueSingle.hash]) {
              this.dadosManejosAreas[area.hash].nutricao[estoqueSingle.hash] = {
                quantidade: null,
                duracao: null,
                edited: false,
                data_final: ''
              }
            }
          }
        }
        if (manejo.tipo == 'sanitarioEstoque' && !this.dadosManejosAreas[area.hash]['sanitarioEstoque'][manejo.procedimento]) {
          for (const estoqueSingle of this.areasEstoqueSanitario) {
            if (!this.dadosManejosAreas[area.hash]['sanitarioEstoque'][estoqueSingle.hash]) {
              this.dadosManejosAreas[area.hash]['sanitarioEstoque'][estoqueSingle.hash] = {
                quantidade: null,
                edited: false,
              }
            }
          }
        }

        if (manejo.procedimento === 'observacao' && !this.dadosManejosAreas[area.hash].areas['observacao']) {
          this.dadosManejosAreas[area.hash].areas['observacao'] = {
            observacao: '',
            data: this.data,
            procedimento: 'observacao',
            hash_area: area.hash
          }
        }
      }
    })

    // após isso, se tiver passando preenchimento, preencher

    const { chuva, nutricao, sanitarioEstoque, manejosAreas } = opcoes || {}

    if (chuva?.length) {
      console.log({ chuva })

      chuva.map((manejo) => {
        this.dadosManejosAreas[manejo.hash_area].areas['chuva'] = manejo
      })
    }

    if (nutricao?.length) {
      this.areas.forEach((area) => {
        const nutricaoDaArea = nutricao.filter((n) => n.hash_area == area.hash)
        nutricaoDaArea.map((manejo) => {
          this.dadosManejosAreas[area.hash].nutricao[manejo.nutricional_hash_estoque] = {
            quantidade: manejo.nutricional_quantidade,
            duracao: manejo.nutricional_duracao,
            edited: false,
            data_final: ''
          }
        })
      })
    }

    

    if (manejosAreas?.length) {
      this.areas.forEach((area) => {
        const manejosDaArea = manejosAreas.filter((n) => n.hash_area == area.hash)
        manejosDaArea.map((manejo) => {
          this.piquetes[area.hash] = this.piquetes[area.hash] || []
          if (!this.piquetes[area.hash].includes(manejo.hash_piquete))
            this.piquetes[area.hash].push(manejo.hash_piquete)
          if(manejo.procedimento === 'altura-capim'){
            this.piquetesAssociados[manejo.hash_piquete].piquete_selecionado_altura_capim = true
            this.dadosManejosAreas[area.hash].areas[manejo.procedimento][manejo.hash_piquete] = manejo
          }
          if(manejo.procedimento === 'pastejo-rotacionado'){
            this.dadosManejosAreas[area.hash].areas[manejo.procedimento] = manejo
          }
          if(manejo.procedimento === 'observacao'){
            this.dadosManejosAreas[area.hash].areas[manejo.procedimento] = manejo
          }
          if(manejo.procedimento === 'chuva'){
            this.dadosManejosAreas[area.hash].areas[manejo.procedimento] = manejo
          }
        })
      })
    }


    if (sanitarioEstoque?.length) {
      this.areas.forEach((area) => {
        const sanitarioEstoqueDoAnimal = sanitarioEstoque.filter((n) => n.hash_area == area.hash)
        sanitarioEstoqueDoAnimal.map((manejo) => {
          if (
            (!this.opcoesRapidasQuantidade['Áreas' + manejo.sanitario_hash_estoque] ||
              this.opcoesRapidasQuantidade['Áreas' + manejo.sanitario_hash_estoque].length < 3)) {
            const casaDecimal = manejo.sanitario_quantidade?.toString().split('.').pop()
            const quantidadeFormatada = casaDecimal === '0' ? String(manejo.sanitario_quantidade).split('.').shift() : manejo.sanitario_quantidade
            this.opcoesRapidasQuantidade['Áreas' + manejo.sanitario_hash_estoque] = [...new Set([...this.opcoesRapidasQuantidade['Áreas' + manejo.sanitario_hash_estoque] || []].concat(quantidadeFormatada))]
          }
          this.dadosManejosAreas[area.hash].sanitarioEstoque[manejo.sanitario_hash_estoque] = {
            observacao: manejo.observacao,
            quantidade: manejo.sanitario_quantidade,
            edited: false,
          }
        })
      })
    }

    // this.zeraPagination()
    this.ordenacaoLocal()
    this.ref.detectChanges()
    // this.calcularResumos()

    if (opcoes?.alterouItensEstoque) {
      this.alterouItensEstoque()
    }
  }

  zeraPagination(): void {
    if (this.abaAtiva === 'Animais') {
      this.paginate.offset = 0
      this.paginate.currentPage = 1
    }

    if (this.abaAtiva === 'Lotes') {
      this.paginateLotes.offset = 0
      this.paginateLotes.currentPage = 1
    }

    if (this.abaAtiva === 'Áreas') {
      this.paginateAreas.offset = 0
      this.paginateAreas.currentPage = 1
    }

    this.selecionaPagina(1)
  }

  async eventosAoSelecionarManejos(): Promise<void> {
    if (!this.manejosSelecionados.length) {
      this.filtrosManejosAnimais = {}
    }
    if (!this.manejosSelecionadosLotes.length) {
      this.filtrosManejosLotes = {}
    }
    if (!this.manejosSelecionadosAreas.length) {
      this.filtrosManejosAreas = {}
    }
    this.formatarManejos()
    this.formatarManejosLotes()
    this.formatarManejosAreas()
    this.verificarGenealogiaParto()
  }

  selecionaPagina(pagina: number): void {
    if (this.abaAtiva === 'Animais') {
      const somenteFemeas =
        this.manejosSelecionados.length &&
        this.manejosSelecionados.filter((m) => m.sexo == 'femea').length == this.manejosSelecionados.length
      const somenteVendidos =
        this.manejosSelecionados.length &&
        this.manejosSelecionados.filter((m) => m.identificador == 'venda').length == this.manejosSelecionados.length
      this.ordenacaoLocal()
      let animaisFiltrados = this.animais.filter((a) => {
        this.animaisPorHash[a.hash] = a
        if (this.filtro.termo) {
          const termoLower = (this.filtro.termo || '').toLocaleLowerCase()
          const nomeLower = (a.nome || '').toLocaleLowerCase()
          const numeroLower = (a.numeracao || '').toLocaleLowerCase()
          if (!nomeLower.includes(termoLower) && !numeroLower.includes(termoLower)) {
            return false
          }
        }
        if (
          this.filtro.hashsLotes.length &&
          ((a.hash_lote && !this.filtro.hashsLotes.includes(a.hash_lote)) ||
            (!this.filtro.hashsLotes.includes('sem_lote') && !a.hash_lote))
        ) {
          return false
        }
        if (this.filtro.hashsAnimais.length && !this.filtro.hashsAnimais.includes(a.hash)) {
          return false
        }
        if (somenteVendidos) {
          return !!this.dadosManejosAnimais[a.hash]?.geral?.venda?.venda_hash_transacao
        }
        if (somenteFemeas) {
          return a.sexo == 'femea'
        } else return true
      })

      if (animaisFiltrados.length === 0 && this.filtro.termo) {
        // buscarAnimaisSugestao
      }

      animaisFiltrados = this.filtrosManejosChange({
        animaisFiltrados
      })

      console.log({ animaisFiltrados })

      const offset = (pagina - 1) * this.paginate.limit
      this.paginate.total = animaisFiltrados.length
      this.animaisFiltrados = animaisFiltrados
      this.animaisExibidos = animaisFiltrados.slice(offset, offset + this.paginate.limit)
      this.scrollTop()
    }

    if (this.abaAtiva === 'Lotes') {
      let lotesFiltrados = this.lotes.filter((l) => {
        this.lotesPorHash[l.hash] = l

        if (this.filtro.termoLote) {
          const termoLower = (this.filtro.termoLote || '').toLocaleLowerCase()
          const nomeLower = (l.nome || '').toLocaleLowerCase()

          if (!nomeLower.includes(termoLower)) {
            return false
          }
        }

        if (
          this.filtro.hashsLotes.length &&
          ((l.hash && !this.filtro.hashsLotes.includes(l.hash)) ||
            (!this.filtro.hashsLotes.includes('sem_lote') && !l.hash_lote))
        ) {
          return false
        }

        if (this.filtro.hashsLotes.length && !this.filtro.hashsLotes.includes(l.hash)) {
          return false
        }

        return true
      })

      lotesFiltrados = this.filtrosManejosChange({
        lotesFiltrados
      })

      const offset = (pagina - 1) * this.paginateLotes.limit
      this.paginateLotes.total = lotesFiltrados.length
      this.lotesFiltrados = lotesFiltrados
      this.lotesExibidos = lotesFiltrados.slice(offset, offset + this.paginateLotes.limit)
      this.scrollTop()
    }

    if (this.abaAtiva === 'Áreas') {
      let areasFiltradas = this.areas.filter((a) => {
        this.areasPorHash[a.hash] = a
        if (this.filtro.termoArea) {
          const termoLower = (this.filtro.termoArea || '').toLocaleLowerCase()
          const nomeLower = (a.nome || '').toLocaleLowerCase()
          console.log({ termoLower })
          console.log({ nomeLower })

          if (!nomeLower.includes(termoLower)) {
            return false
          }
        }
        if (this.filtro.hashsAreas.length && a.hash && !this.filtro.hashsAreas.includes(a.hash)) {
          return false
        }
        if (this.filtro.hashsAreas.length && !this.filtro.hashsAreas.includes(a.hash)) {
          return false
        }

        return true
      })

      areasFiltradas = this.filtrosManejosChange({
        areasFiltradas
      }) as IArea[]

      const offset = (pagina - 1) * this.paginateAreas.limit
      this.paginateAreas.total = areasFiltradas.length
      this.areasFiltradas = areasFiltradas
      this.areasExibidas = areasFiltradas.slice(offset, offset + this.paginateAreas.limit)
      this.scrollTop()
    }
  }

  async salvar(): Promise<void> {
    if (this.manejosDisponiveis.filter((f) => f.procedimento === 'pesagem').length) {
      this.salvando = 'pesagem'

      const pesagens = []

      this.animais.map((a) => {
        const pesagem = this.dadosManejosAnimais[a.hash].pesagem.pesagem
        if (pesagem.edited && (pesagem.peso || pesagem.created_at)) {
          pesagem.identificador = 'cadastro'
          pesagens.push(pesagem)
        }
      })

      console.log({ pesagens })

      if (pesagens.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoPesagens(pesagens)

        if (response) {
          this.salvouManejoPesagem()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    if (
      this.manejosDisponiveis.filter((f) => f.procedimento === 'nutricao').length ||
      this.manejosDisponiveisLotes.filter((f) => f.procedimento === 'nutricao').length ||
      this.manejosDisponiveisAreas.filter((f) => f.procedimento === 'nutricao').length
    ) {
      this.salvando = 'nutricao'

      const nutricao: ManejoNutricional[] = []

      const estoquesAnimais = this.animaisEstoqueNutricional.map((n) => n.hash)
      if (estoquesAnimais.length) {
        this.animais.map((a) => {
          const nutricaoDoAnimal = this.dadosManejosAnimais[a.hash].nutricao || {}
          for (const hash of estoquesAnimais) {
            if (nutricaoDoAnimal[hash].edited) {
              nutricao.push({
                nutricional_hash_estoque: hash,
                hash_animal: a.hash,
                nutricional_quantidade: Number(nutricaoDoAnimal[hash].quantidade),
                nutricional_duracao: nutricaoDoAnimal[hash].duracao,
                data: this.data,
                origem: 'cadastro'
              })
            }
          }
        })
      }

      const estoquesLotes = this.lotesEstoqueNutricional.map((n) => n.hash)
      if (estoquesLotes.length) {
        this.lotes.map((a) => {
          const nutricaoDoLote = this.dadosManejosLotes[a.hash].nutricao || {}
          for (const hash of estoquesLotes) {
            if (nutricaoDoLote[hash].edited) {
              nutricao.push({
                nutricional_hash_estoque: hash,
                hash_lote: a.hash,
                nutricional_quantidade: Number(nutricaoDoLote[hash].quantidade),
                nutricional_duracao: nutricaoDoLote[hash].duracao,
                data: this.data,
                origem: 'cadastro'
              })
            }
          }
        })
      }

      const estoquesAreas = this.areasEstoqueNutricional.map((n) => n.hash)
      if (estoquesAreas.length) {
        this.areas.map((a) => {
          this.filtro.hashsAreas.push(a.hash)
          const nutricaoDaArea = this.dadosManejosAreas[a.hash].nutricao || {}
          for (const hash of estoquesAreas) {
            if (nutricaoDaArea[hash].edited) {
              nutricao.push({
                nutricional_hash_estoque: hash,
                hash_area: a.hash,
                nutricional_quantidade: Number(nutricaoDaArea[hash].quantidade),
                nutricional_duracao: nutricaoDaArea[hash].duracao,
                data: this.data,
                origem: 'cadastro'
              })
            }
          }
        })
      }

      if (nutricao.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoNutricional(nutricao)

        if (response) {
          this.salvouManejoNutricional()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    if (
      this.manejosDisponiveisAreas.filter((f) => f.procedimento === 'altura-capim').length ||
      this.manejosDisponiveisAreas.filter((f) => f.procedimento === 'pastejo-rotacionado').length ||
      this.manejosDisponiveisAreas.filter((f) => f.procedimento === 'observacao').length ||
      this.manejosDisponiveisAreas.filter((f) => f.procedimento === 'chuva').length
    ) {
      this.salvando = 'areas'

      const manejosAreas: ManejoArea[] = []

      this.areas.map((a) => {
        const alturaCapimPiquete = this.dadosManejosAreas[a.hash].areas['altura-capim']

        if(alturaCapimPiquete) {
          for (const hash_piquete of Object.keys(alturaCapimPiquete)){
            if (alturaCapimPiquete[hash_piquete].edited && (alturaCapimPiquete[hash_piquete].altura_capim || alturaCapimPiquete[hash_piquete].created_at)) {
              manejosAreas.push({
                hash_area: a.hash,
                hash_piquete,
                altura_capim: alturaCapimPiquete[hash_piquete].altura_capim,
                procedimento: 'altura-capim',
                data: this.data,
                origem: 'cadastro'
              })
            }
          }
        }

        const pastejoRotacionado = this.dadosManejosAreas[a.hash].areas['pastejo-rotacionado']
       
        if (pastejoRotacionado && pastejoRotacionado.edited && (pastejoRotacionado.hash_piquete || pastejoRotacionado.created_at) ) {
          manejosAreas.push({
            hash_area: a.hash,
            hash_piquete: pastejoRotacionado.hash_piquete,
            procedimento: 'pastejo-rotacionado',
            data: this.data,
            origem: 'cadastro'
          })
        }

        const observacao = this.dadosManejosAreas[a.hash].areas['observacao']
        if (observacao && observacao.edited && (observacao.observacao || observacao.created_at)) {
          manejosAreas.push({
            hash_area: a.hash,
            observacao: observacao.observacao,
            procedimento: 'observacao',
            data: this.data,
            origem: 'cadastro'
          })
        }

        const chuva = this.dadosManejosAreas[a.hash].areas['chuva']
        if (chuva && chuva.edited && (chuva.chuva_hora || chuva.chuva_quantidade || chuva.created_at)) {
          manejosAreas.push({
            hash_area: a.hash,
            observacao: chuva.observacao,
            procedimento: 'chuva',
            chuva_hora: chuva.chuva_hora || '',
            chuva_quantidade: chuva.chuva_quantidade,
            data: this.data,
            origem: 'cadastro'
          })
        }
      })

      if (manejosAreas.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoManejosAreas({ manejos: manejosAreas })

        if (response) {
          this.salvouManejosAreas()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    if (
      this.manejosDisponiveisLotes.filter((f) => f.tipo === 'lote').length || 
      this.manejosDisponiveisLotes.filter((f) => f.procedimento === 'observacao').length ||
      this.manejosDisponiveisLotes.filter((f) => f.procedimento === 'escore-fezes').length 
      ) {
      this.salvando = 'lotes'

      const manejoLotes: IManejoLote[] = []

      this.lotes.map((a) => {
        const movimentacao = this.dadosManejosLotes[a.hash]?.manejosLotes?.['movimentacao-entre-areas']
        if (
          movimentacao?.edited &&
          (movimentacao?.hash_area_destino || movimentacao?.sem_area || movimentacao?.created_at)
        ) {
          manejoLotes.push(movimentacao)
        }

        const observacao = this.dadosManejosLotes[a.hash]?.manejosLotes?.['observacao']

         if (observacao) {
          manejoLotes.push({
            hash_lote: a.hash,
            observacao: observacao.observacao,
            procedimento: 'observacao',
            data: this.data,
            origem: 'cadastro'
          })
        }

        const escoreFezes = this.dadosManejosLotes[a.hash]?.manejosLotes?.['escore-fezes']

         if (escoreFezes) {
          manejoLotes.push({
            hash_lote: a.hash,
            escore_fezes: escoreFezes.escore_fezes,
            procedimento: 'escore-fezes',
            observacao: escoreFezes.observacao,
            data: this.data,
            origem: 'cadastro'
          })
        }
      })

      

      if (manejoLotes.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoManejosLotes({
          manejos: manejoLotes
        })

        if (response) {
          console.log(response)
          this.salvouManejoLotes()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    if (this.manejosDisponiveis.filter((f) => f.procedimento === 'ordenha').length) {
      this.salvando = 'ordenha'

      const ordenhas = []

      this.animais.map((a) => {
        if (a.sexo == 'femea') {
          const ordenha = this.dadosManejosAnimais[a.hash].ordenha.ordenha
          if (
            ordenha.edited &&
            (ordenha.primeira_ordenha || ordenha.segunda_ordenha || ordenha.terceira_ordenha || ordenha.created_at)
          ) {
            if (!ordenha.primeira_ordenha) ordenha.primeira_ordenha = 0
            if (!ordenha.segunda_ordenha) ordenha.segunda_ordenha = 0
            if (!ordenha.terceira_ordenha) ordenha.terceira_ordenha = 0
            ordenhas.push(ordenha)
          }
        }
      })

      console.log({ ordenhas })

      if (ordenhas.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoLeite(ordenhas)

        if (response) {
          this.salvouManejoOrdenhas()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    if (this.manejosDisponiveis.filter((f) => f.tipo === 'reprodutivo').length) {
      this.salvando = 'reprodutivo'

      const reprodutivos = []

      this.animais.map((a) => {
        for (const tipo in this.dadosManejosAnimais[a.hash].reprodutivo) {
          const procedimento = this.dadosManejosAnimais[a.hash].reprodutivo[tipo]
          // if (procedimento.primeira_ordenha || procedimento.segunda_ordenha || procedimento.terceira_ordenha || procedimento.created_at) {
          // validar todos os manejos reprodutivos
          if (a.sexo == 'femea' || (a.sexo == 'macho' && ['desmame', 'descarte', 'castracao'].includes(procedimento.procedimento))) {
            if (procedimento.edited) {
              procedimento.origem = 'cadastro'
              reprodutivos.push(procedimento)
            }
          }
        }
      })

      console.log({ reprodutivos })
      if (reprodutivos.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoManejosReprodutivos({
          manejos: reprodutivos
        })

        if (response) {
          this.salvouManejoReprodutivo()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    if (this.manejosDisponiveis.filter((f) => f.procedimento === 'sanitarioEstoque').length ||
      this.manejosDisponiveisLotes.filter((f) => f.procedimento === 'sanitarioEstoque').length ||
      this.manejosDisponiveisAreas.filter((f) => f.procedimento === 'sanitarioEstoque').length) {
      this.salvando = 'sanitario'

      const sanitarioEstoque = []

      const estoqueAnimais = this.animaisEstoqueSanitario.map((n) => n.hash)
      if (estoqueAnimais.length) {
        this.animais.map(a => {
          const itensSanitariosDoAnimal = this.dadosManejosAnimais[a.hash].sanitarioEstoque
          for (const hash of estoqueAnimais) {
            if (itensSanitariosDoAnimal[hash].edited) {
              sanitarioEstoque.push({
                hash_animal: a.hash,
                sanitario_quantidade: Number(itensSanitariosDoAnimal[hash].quantidade),
                sanitario_hash_estoque: hash,
                data: this.data,
                observacao: itensSanitariosDoAnimal[hash].observacao,
                origem: 'cadastro'
              })
            }
          }
        })
      }

      const estoqueLotes = this.lotesEstoqueSanitario.map((n) => n.hash)
      if (estoqueLotes.length) {
        this.lotes.map(l => {
          const itensSanitariosDoLote = this.dadosManejosLotes[l.hash].sanitarioEstoque
          for (const hash of estoqueLotes) {
            if (itensSanitariosDoLote[hash].edited) {
              sanitarioEstoque.push({
                hash_lote: l.hash,
                sanitario_quantidade: Number(itensSanitariosDoLote[hash].quantidade),
                sanitario_hash_estoque: hash,
                data: this.data,
                observacao: itensSanitariosDoLote[hash].observacao,
                origem: 'cadastro'
              })
            }
          }
        })
      }

      const estoqueAreas = this.areasEstoqueSanitario.map((n) => n.hash)
      if (estoqueAreas.length) {
        this.areas.map(a => {
          const itensSanitariosDaArea = this.dadosManejosAreas[a.hash].sanitarioEstoque
          for (const hash of estoqueAreas) {
            if (itensSanitariosDaArea[hash].edited) {
              sanitarioEstoque.push({
                hash_area: a.hash,
                sanitario_quantidade: Number(itensSanitariosDaArea[hash].quantidade),
                sanitario_hash_estoque: hash,
                data: this.data,
                observacao: itensSanitariosDaArea[hash].observacao,
                origem: 'cadastro'
              })
            }
          }
        })
      }

      if (sanitarioEstoque.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoManejosSanitariosEstoque(sanitarioEstoque)

        if (response) {
          this.salvouManejoSanitarioEstoque()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    if (this.manejosDisponiveis.filter((f) => f.tipo === 'sanitario').length) {
      this.salvando = 'sanitario'

      const sanitarios = []

      this.animais.map((a) => {
        for (const tipo in this.dadosManejosAnimais[a.hash].sanitario) {
          const procedimento = this.dadosManejosAnimais[a.hash].sanitario[tipo]
          // validar todos os manejos sanitarios
          if (procedimento.edited && (procedimento.aplicado || procedimento.created_at)) {
            procedimento.status = procedimento.aplicado ? 1 : 0
            procedimento.origem = 'cadastro'
            sanitarios.push(procedimento)
          }
        }
      })

      if (sanitarios.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoManejosSanitarios({ manejos: sanitarios })

        if (response) {
          this.salvouManejoSanitarioAntigo()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    if (this.manejosDisponiveis.filter((f) => f.tipo === 'geral').length) {
      this.salvando = 'geral'

      const gerais = []

      this.animais.map((a) => {
        for (const tipo in this.dadosManejosAnimais[a.hash].geral) {
          const procedimento = this.dadosManejosAnimais[a.hash].geral[tipo]
          if (tipo == 'secagem' && a.sexo == 'macho') {
            continue
          }
          // if (procedimento.primeira_ordenha || procedimento.segunda_ordenha || procedimento.terceira_ordenha || procedimento.created_at) {
          // validar todos os manejos gerais
          if (procedimento.edited) {
            procedimento.origem = 'cadastro'
            gerais.push(procedimento)
          }
        }
      })

      console.log({ gerais })

      if (gerais.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoManejosGerais({ manejos: gerais })

        if (response) {
          this.salvouManejoGeral()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    if (this.manejosDisponiveis.filter((f) => f.tipo === 'genetica').length) {
      this.salvando = 'genetica'

      const geneticos = []

      this.animais.map((a) => {
        for (const tipo in this.dadosManejosAnimais[a.hash].genetica) {
          const procedimento = this.dadosManejosAnimais[a.hash].genetica[tipo]

          if (procedimento.edited) {
            procedimento.origem = 'cadastro'
            geneticos.push(procedimento)
          }
        }
      })

      if (geneticos.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoManejosGeneticos({ manejos: geneticos })

        if (response) {
          this.salvouManejoGenetica()
        }

        if (error) {
          alert(error.message)
        }
      }
    }

    VastaRX.setState('atualiza-manejos', true)

    const toast = await this.toast.create({ message: 'Manejos salvos com sucesso', duration: 3000 })
    toast.present()

    this.manejosCtrl.campoManejoAlterado = false
    this.manejosCtrl.campoManejoLotesAlterado = false
    this.manejosCtrl.campoManejoAreasAlterado = false

    this.salvando = ''
  }

  salvouManejoReprodutivo(): void {
    for (const hashAnimal in this.dadosManejosAnimais) {
      const animal = this.dadosManejosAnimais[hashAnimal]
      for (const tipo in animal.reprodutivo) {
        animal.reprodutivo[tipo].edited = false
      }
    }
  }

  salvouManejoOrdenhas(): void {
    for (const hashAnimal in this.dadosManejosAnimais) {
      const animal = this.dadosManejosAnimais[hashAnimal]
      for (const tipo in animal.ordenha) {
        animal.ordenha[tipo].edited = false
      }
    }
  }

  salvouManejoGeral(): void {
    for (const hashAnimal in this.dadosManejosAnimais) {
      const animal = this.dadosManejosAnimais[hashAnimal]
      for (const tipo in animal.geral) {
        animal.geral[tipo].edited = false
      }
    }
  }
  
  salvouManejoGenetica(): void {
    for (const hashAnimal in this.dadosManejosAnimais) {
      const animal = this.dadosManejosAnimais[hashAnimal]
      for (const tipo in animal.genetica) {
        animal.genetica[tipo].edited = false
      }
    }
  }

  salvouManejoPesagem(): void {
    for (const hashAnimal in this.dadosManejosAnimais) {
      const animal = this.dadosManejosAnimais[hashAnimal]
      for (const tipo in animal.pesagem) {
        animal.pesagem[tipo].edited = false
      }
    }
  }

  salvouManejoSanitarioAntigo(): void {
    for (const hashAnimal in this.dadosManejosAnimais) {
      const animal = this.dadosManejosAnimais[hashAnimal]
      for (const tipo in animal.sanitario) {
        animal.sanitario[tipo].edited = false
      }
    }
  }

  salvouManejoLotes(): void {
    for (const hashLote in this.dadosManejosLotes) {
      const lote = this.dadosManejosLotes[hashLote]
      for (const tipo in lote.manejosLotes) {
        lote.manejosLotes[tipo].edited = false
      }
    }
  }
  
  salvouManejoSanitarioEstoque(): void {
    for (const hashAnimal in this.dadosManejosAnimais) {
      const animal = this.dadosManejosAnimais[hashAnimal]
      for (const tipo in animal.sanitarioEstoque) {
        animal.sanitarioEstoque[tipo].edited = false
      }
    }

    for (const hashLote in this.dadosManejosLotes) {
      const lote = this.dadosManejosLotes[hashLote]
      for (const tipo in lote.sanitarioEstoque) {
        lote.sanitarioEstoque[tipo].edited = false
      }
    }

    for (const hashArea in this.dadosManejosAreas) {
      const area = this.dadosManejosAreas[hashArea]
      for (const tipo in area.sanitarioEstoque) {
        area.sanitarioEstoque[tipo].edited = false
      }
    }
  }
  
  salvouManejoNutricional(): void {
    for (const hashAnimal in this.dadosManejosAnimais) {
      const animal = this.dadosManejosAnimais[hashAnimal]
      for (const tipo in animal.nutricao) {
        animal.nutricao[tipo].edited = false
      }
    }

    for (const hashLote in this.dadosManejosLotes) {
      const lote = this.dadosManejosLotes[hashLote]
      for (const tipo in lote.nutricao) {
        lote.nutricao[tipo].edited = false
      }
    }

    for (const hashArea in this.dadosManejosAreas) {
      const area = this.dadosManejosAreas[hashArea]
      for (const tipo in area.nutricao) {
        area.nutricao[tipo].edited = false
      }
    }
  }

  salvouManejosAreas(): void {
    for (const hashArea in this.dadosManejosAreas) {
      const area = this.dadosManejosAreas[hashArea]
      for (const tipo in area.areas) {
        if (area.areas[tipo]?.edited) {
          area.areas[tipo].edited = false
        }
        for (const hashPiquete in area.areas[tipo]) {
          if (area.areas[tipo]?.[hashPiquete] && area.areas[tipo][hashPiquete]?.edited) {
            area.areas[tipo][hashPiquete].edited = false
          }
        }
      }
    }
  }

  async showAlertAdicionarObservacao(opcoes: {
    dados: ManejoOrdenha | ManejoPesagem | ManejoReprodutivoUnitario | ManejoSanitarioUnitario | IManejoGeralUnitario
  }): Promise<void> {
    const { dados } = opcoes
    const alert = await this.alertCtrl.create({
      header: 'Adicionar observação',
      inputs: [
        {
          name: 'observacao',
          type: 'text',
          placeholder: 'Observação',
          value: dados.observacao || ''
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Adicionar',
          handler: (data): void => {
            if (data.observacao) {
              dados.edited = true
              dados['observacao'] = data.observacao
              this.calcularResumos()
            }
          }
        }
      ]
    })

    await alert.present()
  }

  calcularResumos(): void {
    this.calcularResumosTimeout = setTimeout(() => {
      this.calcularResumosHandle()
    }, 500)
  }

  calcularResumosHandle(): void {
    const { resumos } = this.manejosUtils.calcularResumosAnimaisHandle({
      estoqueNutricional: this.animaisEstoqueNutricional,
      estoqueSanitario: this.animaisEstoqueSanitario,
      manejosDisponiveis: this.manejosDisponiveis,
      dadosManejosAnimais: this.dadosManejosAnimais
    })

    this.resumos = resumos

    const { resumos: resumosLotes } = this.manejosUtils.calcularResumosLotesHandle({
      estoqueNutricional: this.lotesEstoqueNutricional,
      estoqueSanitario: this.lotesEstoqueSanitario,
      manejosDisponiveis: this.manejosDisponiveisLotes,
      dadosManejosAnimais: this.dadosManejosLotes
    })

    this.resumosLotes = resumosLotes

    const { resumos: resumosAreas } = this.manejosUtils.calcularResumosAreasHandle({
      estoqueNutricional: this.areasEstoqueNutricional,
      estoqueSanitario: this.areasEstoqueSanitario,
      manejosDisponiveis: this.manejosDisponiveisAreas,
      dadosManejosAreas: this.dadosManejosAreas
    })

    this.resumosAreas = resumosAreas

    this.ref.detectChanges()
  }

  async consultarExposicoes(): Promise<void> {
    if (!this.manejosDisponiveis.filter((m) => m.identificador == 'dg').length) return
    const hashsFemeas = this.animais.filter((a) => a.sexo === 'femea' && !this.exposicoes[a.hash]).map((a) => a.hash)

    if (!hashsFemeas.length) return

    const [response, error] = await this.manejosCtrl.getExposicoes({
      hash_animal: hashsFemeas,
      de: moment(this.data).subtract('9', 'months').format('YYYY-MM-DD'),
      ate: moment(this.data).subtract('1', 'day').format('YYYY-MM-DD')
    })

    if (response) {
      this.exposicoes = {
        ...this.exposicoes,
        ...response.animais
      }

      Object.values(this.exposicoes).map((item) => {
        item.map((i) => {
          i['diff'] = moment(this.data).diff(i.data, 'days')
          this.exposicoesAssociadas[i.hash] = i
        })
      })
    }
  }

  filtroTextoTimeout = null

  filtrar(): void {
    if (this.abaAtiva === 'Animais') {
      clearTimeout(this.filtroTextoTimeout)
      this.filtroTextoTimeout = setTimeout(() => {
        this.zeraPagination()
      }, 500)
    }

    if (this.abaAtiva === 'Lotes') {
      this.filtroTextoTimeout = setTimeout(() => {
        this.zeraPagination()
      }, 500)
    }

    if (this.abaAtiva === 'Áreas') {
      this.filtroTextoTimeout = setTimeout(() => {
        this.zeraPagination()
      }, 500)
    }
  }

  limparFiltros(): void {
    if (this.abaAtiva === 'Animais') {
      this.filtro.termo = ''
    }

    if (this.abaAtiva === 'Lotes') {
      this.filtro.termoLote = ''
    }

    if (this.abaAtiva === 'Áreas') {
      this.filtro.termoArea = ''
    }

    this.zeraPagination()
  }

  async selecionarLotes(): Promise<void> {
    const hashsExistentes = this.lotes.map((a) => a.hash)

    const modal = await this.modalCtrl.create({
      component: ModalListaLotesPage,
      componentProps: {
        lotes_cadastrados: true,
        multiplos: true,
        data_selecao: this.data == moment().format('YYYY-MM-DD') ? null : this.data,
        hash_not_in: this.abaAtiva == 'Lotes' ? hashsExistentes : []
      },
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.lotes) {
      if (this.abaAtiva == 'Animais') {
        this.filtro.hashsLotes = data.lotes.map((item) => item.hash)
        data.lotes.forEach((item) => {
          this.lotesPreenchidos[item.hash] = item
        })
        console.log('this.lotesPreenchidos', this.lotesPreenchidos)
        this.filtrar()
      } else {
        console.log('data?.lotes', data?.lotes)
        const novosLotes = data?.lotes.filter((a) => !hashsExistentes.includes(a.hash))
        console.log('novosLotes', novosLotes)
        this.lotes = [...novosLotes, ...this.lotes]
        console.log('this.lotes', this.lotes)
        this.formatarManejosLotes()
        this.filtrar()
      }
    }
  }

  async selecionarFiltroAnimais(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: {
        hashNotIn: this.filtro.hashsAnimais,
        // hashIn: this.animais.map((a) => a.hash),
        somenteInternos: true,
        selecionarMultiplos: true
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      console.log(data)

      const animais = data?.hashs?.length ? data.animais : [data]
      const hashs = data?.hashs?.length ? data.hashs : [data.hash]
      this.filtro.hashsAnimais = [
        ...hashs?.filter((h) => !this.filtro.hashsAnimais.includes(h)),
        ...this.filtro.hashsAnimais
      ]
      this.animais = [...animais?.filter((a) => !this.animais.map((a) => a.hash).includes(a.hash)), ...this.animais]

      this.preencherOrdemAnimais()

      this.filtrar()

      this.formatarManejos()
      this.verificarGenealogiaParto()

      setTimeout(() => {
        this.scrollTop()
      }, 300)
    }
  }

  limparFiltrosLotesEAnimais(): void {
    this.filtro.hashsLotes = []
    this.filtro.hashsAnimais = []
    this.filtrar()
  }

  removerFiltroHashLote(hash: string): void {
    this.filtro.hashsLotes = this.filtro.hashsLotes.filter((item) => item !== hash)
    this.filtrar()
  }

  removerFiltroHashAnimal(hash: string): void {
    this.filtro.hashsAnimais = this.filtro.hashsAnimais.filter((item) => item !== hash)
    this.filtrar()
  }

  async showPopoverOpcoesManejos(event: Event, manejoIdentificador = null, opcoesParaTodosManejos = false): Promise<void> {
    if (this.propriedades.length === 0) {
      this.propriedades = this.propriedadesCtrl.todasPropriedades
    }

    const hasSelecionados = {
      'Animais': this.hashsSelecionados.length > 0,
      'Lotes': this.hashsSelecionadosLotes.length > 0,
      'Áreas': this.hashsSelecionadosAreas.length > 0
    }[this.abaAtiva]
    const manejosDisponiveis = {
      'Animais': this.manejosDisponiveis,
      'Lotes': this.manejosDisponiveisLotes,
      'Áreas': this.manejosDisponiveisAreas
    }[this.abaAtiva]
    const estoqueNutricional = {
      'Animais': this.animaisEstoqueNutricional,
      'Lotes': this.lotesEstoqueNutricional,
      'Áreas': this.areasEstoqueNutricional
    }[this.abaAtiva]
    const estoqueSanitario = {
      'Animais': this.animaisEstoqueSanitario,
      'Lotes': this.lotesEstoqueSanitario,
      'Áreas': this.areasEstoqueSanitario
    }[this.abaAtiva]

    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejosSelecionadosComponent,
      componentProps: {
        procedimentoSelecionadoProp: manejoIdentificador,
        hasSelecionados,
        manejosDisponiveis,
        animaisAssociados: this.animaisAssociados,
        animaisExternosAssociados: this.animaisExternosAssociados,
        estoquesAssociados: this.estoquesAssociados,
        propriedadesProp: this.propriedades,
        lotesPorPropriedade: this.lotesPorPropriedade,
        lotesAssociados: this.lotesAssociados,
        areasAssociadas: this.areasAssociadas,
        piquetesAssociados: this.piquetesAssociados,
        estoqueNutricional,
        estoqueSanitario,
        abaAtiva: this.abaAtiva,
        opcoesParaTodosManejos,
        pessoasAssociadas: this.pessoasAssociadas
      },
      event,
      reference: 'trigger'
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()
    if (data) {
      if (data.acao === 'agendar-evento') {
        this.openModalCadastrarEvento()
        return
      } else {
        if (!manejoIdentificador) {
          data.selecionados = true
        }
        if (opcoesParaTodosManejos) {
          if (data.acao === 'limparTodos') {
            this.limparCamposTodosManejos()
          }
          if (data.acao === 'alterarData') {
            this.verificarDataParaAlterarDataManejo(data.date)
          }
        } else {
          this.acaoManejo(data)
        }
      }
    }
  }

  async acaoManejo(data: {
    date: string
    selecionados: boolean
    acao: string
    tipo: string
    procedimento: string
    campos: {
      chave: string
      chaveExtra: string
      value: string
      extra?: boolean
    }[]
    camposManejoNutricao: {
      [key: string]: {
        campos: {
          chave: string
          chaveExtra: string
          value: string
          extra?: boolean
        }[]
      }
    },
    camposManejoSanitarioNovo: {
      [key: string]: {
        campos: {
          chave: string
          chaveExtra: string
          value: string
          extra?: boolean
        }[]
      }
    },
    animaisAssociadosChange: { [key: string]: IAnimal }
    animaisExternosAssociadosChange: { [key: string]: IReprodutorExterno }
    estoquesAssociadosChange: { [key: string]: EstoqueBiologico }
    lotesAssociadosChange: { [key: string]: ILote }
    areasAssociadasChange: { [key: string]: IArea }
    piquetesAssociadosChange: { [key: string]: IAreaPiquete }
    lotesOpcoesChange: { [key: string]: { label: string, value: string }[] }
    manejosDisponiveisChange: ListaManejosProp[]
    pessoasAssociadasChange: { [key: string]: Pessoa }
  }): Promise<void> {
    const {
      date,
      acao,
      tipo,
      procedimento,
      selecionados,
      campos,
      camposManejoNutricao,
      camposManejoSanitarioNovo,
      animaisAssociadosChange,
      animaisExternosAssociadosChange,
      estoquesAssociadosChange,
      lotesAssociadosChange,
      areasAssociadasChange,
      lotesOpcoesChange,
      manejosDisponiveisChange,
      piquetesAssociadosChange,
      pessoasAssociadasChange
    } = data
    if (animaisAssociadosChange) this.animaisAssociados = animaisAssociadosChange
    if (animaisExternosAssociadosChange) this.animaisExternosAssociados = animaisExternosAssociadosChange
    if (estoquesAssociadosChange) this.estoquesAssociados = estoquesAssociadosChange
    if (lotesAssociadosChange) this.lotesAssociados = lotesAssociadosChange
    if (areasAssociadasChange) this.areasAssociadas = areasAssociadasChange
    if (piquetesAssociadosChange) this.piquetesAssociados = piquetesAssociadosChange
    if (manejosDisponiveisChange) this.manejosDisponiveis = manejosDisponiveisChange
    if (pessoasAssociadasChange) this.pessoasAssociadas = pessoasAssociadasChange
    if (lotesOpcoesChange) {
      this.lotesPorPropriedadeOpcoes = { ...lotesOpcoesChange }
    }

    const hashsEstoqueNutricional = Object.keys(camposManejoNutricao || {}).map((key) => key)
    const hashsEstoqueSanitario = Object.keys(camposManejoSanitarioNovo || {}).map((key) => key)
    const hashsAnimais = selecionados ? this.hashsSelecionados : Object.keys(this.dadosManejosAnimais)
    const hashsLotes = selecionados ? this.hashsSelecionadosLotes : Object.keys(this.dadosManejosLotes)
    const hashsAreas = selecionados ? this.hashsSelecionadosAreas : Object.keys(this.dadosManejosAreas)

    if (acao === 'alterarData') {
      this.verificarDataParaAlterarDataManejo(date, procedimento)

      return
    }

    if (this.abaAtiva === 'Animais') {
      this.preencherCamposManejos(
        hashsAnimais,
        acao,
        tipo,
        procedimento,
        campos,
        camposManejoNutricao,
        hashsEstoqueNutricional,
        camposManejoSanitarioNovo,
        hashsEstoqueSanitario
      )
    }

    if (this.abaAtiva === 'Lotes') {
      this.preencherCamposManejos(
        hashsLotes,
        acao,
        tipo,
        procedimento,
        campos,
        camposManejoNutricao,
        hashsEstoqueNutricional,
        camposManejoSanitarioNovo,
        hashsEstoqueSanitario
      )
    }

    if (this.abaAtiva === 'Áreas') {
      this.preencherCamposManejos(
        hashsAreas,
        acao,
        tipo,
        procedimento,
        campos,
        camposManejoNutricao,
        hashsEstoqueNutricional,
        camposManejoSanitarioNovo,
        hashsEstoqueSanitario
      )
    }

    this.formatarManejos()
  }

  async alterarDataManejo(novaData: string, procedimento: string = null): Promise<void> {
    const campoAlterado = {
      Animais: this.manejosCtrl.campoManejoAlterado,
      Lotes: this.manejosCtrl.campoManejoLotesAlterado,
      Áreas: this.manejosCtrl.campoManejoAreasAlterado
    }[this.abaAtiva]

    if (campoAlterado) {
      const alert = await this.alertCtrl.create({
        header: 'Existem dados não salvos',
        message: 'Deseja salvar os manejos antes de continuar com a mudança de data?',
        buttons: [
          {
            text: 'Cancelar'
          },
          {
            text: 'Salvar e continuar',
            handler: async (): Promise<void> => {
              setTimeout(async () => {
                await this.salvar()
                this.animais = []
                this.lotes = []
                this.areas = []
                this.manejosDisponiveis = []
                this.manejosDisponiveisLotes = []
                this.manejosDisponiveisAreas = []
                await this.carregarManejosSalvos()
                this.formatarPayloadManejosMudancaData(novaData, procedimento)
              }, 300)
            }
          }
        ]
      })

      await alert.present()
    } else {
      this.formatarPayloadManejosMudancaData(novaData, procedimento)
    }
  }

  formatarPayloadManejosMudancaData(novaData: string, procedimento: string): void {
    const dadosPorAba: {
      selecionados: string[]
      todos: string[]
    } = {
      Animais: {
        selecionados: this.hashsSelecionados,
        todos: this.animais.map((a) => a.hash)
      },
      Lotes: {
        selecionados: this.hashsSelecionadosLotes,
        todos: this.lotes.map((a) => a.hash)
      },
      Áreas: {
        selecionados: this.hashsSelecionadosAreas,
        todos: this.areas.map((a) => a.hash)
      }
    }[this.abaAtiva]

    const hashsSubjectSelecionados = dadosPorAba.selecionados.length ? dadosPorAba.selecionados : dadosPorAba.todos

    const hashsAMover = {
      gerais: this.listaManejos.gerais
        .filter((m) => procedimento ? m.procedimento == procedimento && hashsSubjectSelecionados.includes(m.hash_animal) : hashsSubjectSelecionados.includes(m.hash_animal))
        .map((m) => m.hash),
      manejosLotes: this.listaManejos.manejosLotes
        .filter((m) => procedimento ? m.procedimento == procedimento && hashsSubjectSelecionados.includes(m.hash_lote) : hashsSubjectSelecionados.includes(m.hash_lote))
        .map((m) => m.hash),
      manejosAreas: this.listaManejos.manejosAreas
        .filter((m) => procedimento ? m.procedimento == procedimento && hashsSubjectSelecionados.includes(m.hash_area) : hashsSubjectSelecionados.includes(m.hash_area))
        .map((m) => m.hash),
      nutricional: this.listaManejos.nutricional
        .filter(
          (m) =>
            procedimento ?
              procedimento == 'nutricao' &&
              (hashsSubjectSelecionados.includes(m.hash_animal) ||
                hashsSubjectSelecionados.includes(m.hash_area) ||
                hashsSubjectSelecionados.includes(m.hash_lote))
              : hashsSubjectSelecionados.includes(m.hash_animal) ||
              hashsSubjectSelecionados.includes(m.hash_area) ||
              hashsSubjectSelecionados.includes(m.hash_lote)
        )
        .map((m) => m.hash),
      ordenhas: this.listaManejos.ordenhas
        .filter((m) => procedimento ? procedimento == 'ordenha' && hashsSubjectSelecionados.includes(m.hash_animal) : hashsSubjectSelecionados.includes(m.hash_animal))
        .map((m) => m.hash),
      pesagens: this.listaManejos.pesagens
        .filter((m) => procedimento ? procedimento == 'pesagem' && hashsSubjectSelecionados.includes(m.hash_animal) : hashsSubjectSelecionados.includes(m.hash_animal))
        .map((m) => m.hash),
      reprodutivos: this.listaManejos.reprodutivos
        .filter((m) => procedimento ? m.procedimento == procedimento && hashsSubjectSelecionados.includes(m.hash_animal) : hashsSubjectSelecionados.includes(m.hash_animal))
        .map((m) => m.hash),
      sanitarios: this.listaManejos.sanitarios
        .filter((m) => procedimento ? m.hash_procedimento == procedimento && hashsSubjectSelecionados.includes(m.hash_animal) : hashsSubjectSelecionados.includes(m.hash_animal))
        .map((m) => m.hash),
      geneticos: this.listaManejos.genetica
        .filter((m) => procedimento ? m.procedimento == procedimento && hashsSubjectSelecionados.includes(m.hash_animal) : hashsSubjectSelecionados.includes(m.hash_animal))
        .map((m) => m.hash)
    }

    this.enviarHashsManejosMudancaData({
      hashs: hashsAMover,
      data: novaData
    })
  }

  async enviarHashsManejosMudancaData(payload: {
    hashs: {
      gerais: string[]
      manejosLotes: string[]
      manejosAreas: string[]
      nutricional: string[]
      ordenhas: string[]
      pesagens: string[]
      reprodutivos: string[]
      sanitarios: string[]
    }
    data: string
  }): Promise<void> {
    const [response, error] = await this.manejosCtrl.enviarHashsManejosMudancaData(payload)

    if (response) {
      this.animais = []
      this.lotes = []
      this.areas = []
      this.manejosDisponiveis = []
      this.manejosDisponiveisLotes = []
      this.manejosDisponiveisAreas = []
      this.hashsSelecionados = []
      this.hashsSelecionadosLotes = []
      this.hashsSelecionadosAreas = []
      this.dadosManejosAnimais = {}
      this.dadosManejosLotes = {}
      this.dadosManejosAreas = {}
      this.manejosSelecionados = []
      this.manejosSelecionadosLotes = []
      this.manejosSelecionadosAreas = []

      const toast = await this.toast.create({
        message: 'Manejos movidos com sucesso',
        duration: 3000
      })

      toast.present()
      await this.carregarManejosSalvos()
    }

    if (error) {
      console.log(error)
    }
  }

  preencherCamposManejos(
    hashs: string[],
    acao: String,
    tipo: string,
    procedimento: string,
    campos?: Partial<{ label: string; value: string; chave: string; extra?: boolean; chaveExtra?: string }>[],
    camposManejoNutricao?: {
      [key: string]: { campos: Partial<{ label: string; value: string; chave: string; extra?: boolean }>[] }
    },
    hashsEstoqueNutricional?: string[],
    camposManejoSanitario?: {
      [key: string]: { campos: Partial<{ label: string; value: string; chave: string; extra?: boolean }>[] }
    },
    hashsEstoqueSanitario?: string[]
  ): void {
    if (tipo === 'nutricao') {
      hashs.forEach((hash) => {
        hashsEstoqueNutricional.forEach((hashEstoqueNutricional) => {
          camposManejoNutricao[hashEstoqueNutricional].campos.forEach((campo) => {
            const novoValor = acao === 'limparTodos' ? '' : campo.value
            
            if (acao !== 'limparTodos' && !novoValor) {
              return
            }

            if (this.abaAtiva === 'Animais') {
              this.dadosManejosAnimais[hash][tipo][hashEstoqueNutricional][campo.chave] = novoValor
              this.dadosManejosAnimais[hash][tipo][hashEstoqueNutricional].edited = true
            }

            if (this.abaAtiva === 'Lotes') {
              this.dadosManejosLotes[hash][tipo][hashEstoqueNutricional][campo.chave] = novoValor
              this.dadosManejosLotes[hash][tipo][hashEstoqueNutricional].edited = true
            }

            if (this.abaAtiva === 'Áreas') {
              this.dadosManejosAreas[hash][tipo][hashEstoqueNutricional][campo.chave] = novoValor
              this.dadosManejosAreas[hash][tipo][hashEstoqueNutricional].edited = true
            }
          })
        })
      })
    } else if (tipo === 'sanitarioEstoque') {
      hashs.forEach((hash) => {
        hashsEstoqueSanitario.forEach((hashEstoqueSanitario) => {
          camposManejoSanitario[hashEstoqueSanitario].campos.forEach((campo) => {
            const novoValor = acao === 'limparTodos' ? '' : campo.value

            if (acao !== 'limparTodos' && !novoValor) {
              return
            }

            if (this.abaAtiva === 'Animais') {
              this.dadosManejosAnimais[hash][tipo][hashEstoqueSanitario][campo.chave] = novoValor
              this.dadosManejosAnimais[hash][tipo][hashEstoqueSanitario].edited = true
            }

            if (this.abaAtiva === 'Lotes') {
              this.dadosManejosLotes[hash][tipo][hashEstoqueSanitario][campo.chave] = novoValor
              this.dadosManejosLotes[hash][tipo][hashEstoqueSanitario].edited = true
            }

            if (this.abaAtiva === 'Áreas') {
              this.dadosManejosAreas[hash][tipo][hashEstoqueSanitario][campo.chave] = novoValor
              this.dadosManejosAreas[hash][tipo][hashEstoqueSanitario].edited = true
            }

            if (novoValor) {
              this.opcoesRapidasQuantidade[this.abaAtiva + hashEstoqueSanitario] = [...new Set([novoValor, ...(this.opcoesRapidasQuantidade[this.abaAtiva + hashEstoqueSanitario] || [])])]
            }
          })
        })
      })
    } else if (tipo === 'lote') {
      hashs.forEach((hash) => {
        campos.forEach((campo) => {
          const novoValor = acao === 'limparTodos' ? '' : campo.value

          if (campo?.extra) {
            this.dadosManejosLotes[hash]['manejosLotes'][procedimento][campo.chave] = ''
            if (campo.chaveExtra) {
              this.dadosManejosLotes[hash]['manejosLotes'][procedimento][campo.chaveExtra] = novoValor
            }
          } else {
            this.dadosManejosLotes[hash]['manejosLotes'][procedimento][campo.chave] = novoValor
            if (campo.chaveExtra) {
              this.dadosManejosLotes[hash]['manejosLotes'][procedimento][campo.chaveExtra] = ''
            }
          }

          this.dadosManejosLotes[hash]['manejosLotes'][procedimento].edited = true
        })
      })
    } else if (tipo === 'areas') {
      hashs.forEach((hash) => {
        campos.forEach((campo) => {
          const novoValor = acao === 'limparTodos' ? '': campo.value

          if(procedimento == 'altura-capim') {
            console.log('this.dadosManejosAreas[hash]', this.dadosManejosAreas[hash]['areas'][procedimento])
            for(const key in this.dadosManejosAreas[hash]['areas'][procedimento]) {

              if (campo?.extra) {
                this.dadosManejosAreas[hash]['areas'][procedimento][key][campo.chave] = novoValor.length ? Number(novoValor) : null
                if (campo.chaveExtra) {
                  this.dadosManejosAreas[hash]['areas'][procedimento][key][campo.chaveExtra] = Number(novoValor)
                }
                this.dadosManejosAreas[hash]['areas'][procedimento][key].edited = true
              } else {
                this.dadosManejosAreas[hash]['areas'][procedimento][key][campo.chave] = novoValor.length ? Number(novoValor) : null
                if (campo.chaveExtra) {
                  this.dadosManejosAreas[hash]['areas'][procedimento][key][campo.chaveExtra] = null
                }
                this.dadosManejosAreas[hash]['areas'][procedimento][key].edited = true
              }
            }

          } else {
            if (campo?.extra) {
              this.dadosManejosAreas[hash]['areas'][procedimento][campo.chave] = ''
              if (campo.chaveExtra) {
                this.dadosManejosAreas[hash]['areas'][procedimento][campo.chaveExtra] = novoValor
              }
              this.dadosManejosAreas[hash]['areas'][procedimento].edited = true
            } else {
              this.dadosManejosAreas[hash]['areas'][procedimento][campo.chave] = novoValor
              if (campo.chaveExtra) {
                this.dadosManejosAreas[hash]['areas'][procedimento][campo.chaveExtra] = ''
              }
              this.dadosManejosAreas[hash]['areas'][procedimento].edited = true
            }
          }

        })
      })
    } else {
      hashs.forEach((hash) => {
        campos.forEach((campo) => {
          const novoValor = acao === 'limparTodos' ? '' : campo.value

          if (acao !== 'limparTodos' && !novoValor) {
            return
          }

          const hasTransaferenciaPropriedadeNovoHash =
            !!this.dadosManejosAnimais[hash]?.[tipo]?.[procedimento]?.transf_propriedade_novo_hash
          const checkPodeAlterar =
            !hasTransaferenciaPropriedadeNovoHash ||
            (campo.chave != 'transf_propriedade_id' && campo.chave != 'transf_propriedade_hash_lote')

          if (this.abaAtiva === 'Animais' && checkPodeAlterar) {
            if (campo?.extra) {
              this.dadosManejosAnimais[hash][tipo][procedimento][campo.chave] = ''
              if (campo.chaveExtra) {
                this.dadosManejosAnimais[hash][tipo][procedimento][campo.chaveExtra] = novoValor
              }
            } else {
              this.dadosManejosAnimais[hash][tipo][procedimento][campo.chave] = novoValor
              if (campo.chaveExtra) {
                this.dadosManejosAnimais[hash][tipo][procedimento][campo.chaveExtra] = ''
              }
            }

            this.dadosManejosAnimais[hash][tipo][procedimento].edited = true
          }

          if (this.abaAtiva === 'Lotes') {
            if (campo?.extra) {
              this.dadosManejosLotes[hash][tipo][procedimento][campo.chave] = ''
              if (campo.chaveExtra) {
                this.dadosManejosLotes[hash][tipo][procedimento][campo.chaveExtra] = novoValor
              }
            } else {
              this.dadosManejosLotes[hash][tipo][procedimento][campo.chave] = novoValor
              if (campo.chaveExtra) {
                this.dadosManejosLotes[hash][tipo][procedimento][campo.chaveExtra] = ''
              }
            }

            this.dadosManejosLotes[hash][tipo][procedimento].edited = true
          }

          if (this.abaAtiva === 'Áreas') {
            if (campo?.extra) {
              this.dadosManejosAreas[hash][tipo][procedimento][campo.chave] = ''
              if (campo.chaveExtra) {
                this.dadosManejosAreas[hash][tipo][procedimento][campo.chaveExtra] = novoValor
              }
            } else {
              this.dadosManejosAreas[hash][tipo][procedimento][campo.chave] = novoValor
              if (campo.chaveExtra) {
                this.dadosManejosAreas[hash][tipo][procedimento][campo.chaveExtra] = ''
              }
            }

            this.dadosManejosAreas[hash][tipo][procedimento].edited = true
          }
        })
      })
    }
  }

  limparCamposTodosManejos(): void {
    const dados = {
      'Animais': this.dadosManejosAnimais,
      'Lotes': this.dadosManejosLotes,
      'Áreas': this.dadosManejosAreas
    }[this.abaAtiva]

    Object.keys(dados).forEach((hash) => {
      Object.keys(dados[hash]).forEach((tipo) => {
        if (tipo !== 'hash') {
          if (tipo === 'nutricao') {
            Object.keys(dados[hash][tipo]).forEach((procedimento) => {
              dados[hash][tipo][procedimento]['quantidade'] = null
              dados[hash][tipo][procedimento]['duracao'] = null
              dados[hash][tipo][procedimento]['edited'] = true
            })
          } else if (tipo === 'sanitarioEstoque') {
            Object.keys(dados[hash][tipo]).forEach((procedimento) => {
              dados[hash][tipo][procedimento]['quantidade'] = null
              dados[hash][tipo][procedimento]['edited'] = true
            })
          } else if (tipo === 'sanitario') {
            Object.keys(dados[hash][tipo]).forEach((procedimento) => {
              dados[hash][tipo][procedimento]['aplicado'] = false
              dados[hash][tipo][procedimento]['edited'] = true
            })
          }else if (tipo === 'areas') {
            Object.keys(dados[hash][tipo]).forEach((procedimento) => {
              if(procedimento == 'altura-capim'){
                Object.keys(dados[hash][tipo][procedimento]).forEach((hash_piquete) => {
                  dados[hash][tipo][procedimento][hash_piquete]['altura_capim'] = null
                  dados[hash][tipo][procedimento][hash_piquete]['edited'] = true
                })
              } else {
                PopoverCampos[procedimento].campos.forEach(campo => {
                  dados[hash][tipo][procedimento][campo.chave] = ''
                  dados[hash][tipo][procedimento]['edited'] = true
                })
              }
            })
          }
          else {
            Object.keys(dados[hash][tipo]).forEach((procedimento) => {
              PopoverCampos[procedimento].campos.forEach(campo => {
                dados[hash][tipo][procedimento][campo.chave] = ''
                dados[hash][tipo][procedimento]['edited'] = true
              })
            })
          }
        }
      })
    })
    this.formatarManejos()
  }

  async irParaFichaDoAnimal({ original }: DadosLinhaTabela): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: BovinoSingleComponent,
      componentProps: { isModal: true, animalProp: original },
      cssClass: 'modal-fullScreen'
    })

    await modal.present()
  }

  async getPropriedades(): Promise<void> {
    const [response, error] = await this.propriedadesCtrl.getPropriedades()
    if (response) {
      this.propriedades = response
      console.warn('# this.propriedades', this.propriedades)
    }

    if (error) {
      console.error(error)
    }
  }

  async getLotes(idPropriedade: number): Promise<void> {
    const [response, error] = await this.lotesCtrl.getLotes(null, idPropriedade)
    if (response) {
      this.lotesPorPropriedade[idPropriedade] = response.dados
      this.lotesPorPropriedade = { ...this.lotesPorPropriedade }

      this.lotesPorPropriedadeOpcoes[idPropriedade] = response.dados.map((l) => ({
        value: l.hash,
        label: l.nome
      }))
    }

    if (error) {
      console.error(error)
    }
  }

  selecionarTodos(isTodos: boolean): void {
    if (isTodos) {
      this.hashsSelecionados = this.animaisFiltrados.map((a) => a.hash)
    }
  }

  tabChange(): void { }

  manejoDeOutraAbaSelecionado(event: { aba: string; manejosSelecionados: ListaManejosProp[] }): void {
    this.abaAtiva = event.aba
    if (this.abaAtiva == 'Animais') {
      const procedimentos = this.manejosDisponiveis.map((a) => a.procedimento)
      this.manejosDisponiveis = [
        ...this.manejosDisponiveis,
        ...event.manejosSelecionados.filter((a) => !procedimentos.includes(a.procedimento))
      ]
    }
    if (this.abaAtiva == 'Lotes') {
      const procedimentos = this.manejosDisponiveisLotes.map((a) => a.procedimento)
      this.manejosDisponiveisLotes = [
        ...this.manejosDisponiveisLotes,
        ...event.manejosSelecionados.filter((a) => !procedimentos.includes(a.procedimento))
      ]
    }
    if (this.abaAtiva == 'Áreas') {
      const procedimentos = this.manejosDisponiveisAreas.map((a) => a.procedimento)
      this.manejosDisponiveisAreas = [
        ...this.manejosDisponiveisAreas,
        ...event.manejosSelecionados.filter((a) => !procedimentos.includes(a.procedimento))
      ]
    }
    this.formatarManejos()
    this.formatarManejosLotes()
    this.formatarManejosAreas()
    this.selecionaPagina(1)
  }

  filtrosManejosChange(opcoes: {
    animaisFiltrados?: Partial<{ hash?: string }>[]
    lotesFiltrados?: Partial<{ hash?: string }>[]
    areasFiltradas?: Partial<{ hash?: string }>[]
  }): IArea[] | ILote[] | IAnimal[] {
    const camposObrigatorios = {
      pesagem: 'peso',
      ordenha: 'primeira_ordenha',
      'movimentacao-lote': 'hash_lote',
      'movimentacao-confinamento': 'hash_confinamento',
      'numero-controle': 'numero_novo',
      'numero-brinco-eletronico': 'brinco_eletronico',
      'morte': 'morte_causa',
      'perda': 'perda_observacoes',
      'secagem': 'secagem_observacoes',
      'transferencia-propriedade': 'transf_propriedade_id',
      'cio': 'cio_resultado',
      'implante': 'implante_aplica',
      'implante-remocao': 'implante_remove',
      'dg': 'dg_resultado',
      'descarte': 'descarte_resultado',
      inseminacao: ['inseminacao_semen', 'inseminacao_embriao'],
      'monta-natural': ['cobertura_hash_reprodutor', 'cobertura_hash_reprodutor_externo'],
      'parto': 'parto_hash_cria1',
      'desmame': 'desmame_detalhes',
      'aborto': 'aborto_detalhes',
      'castracao': 'castracao_detalhes',
      'escore': 'escore_corporal',
      'movimentacao-entre-areas': 'hash_area_destino',
      observacao: 'observacao',
      'escore-fezes': 'escore_fezes',
      'perimetro-escrotal': 'perimetro_escrotal'
    }

    const listaAtiva = {
      'Animais': opcoes.animaisFiltrados,
      'Lotes': opcoes.lotesFiltrados,
      'Áreas': opcoes.areasFiltradas
    }[this.abaAtiva]

    const dadosManejosListaAtiva = {
      'Animais': this.dadosManejosAnimais,
      'Lotes': this.dadosManejosLotes,
      'Áreas': this.dadosManejosAreas
    }[this.abaAtiva]

    const filtros = {
      'Animais': this.filtrosManejosAnimais,
      'Lotes': this.filtrosManejosLotes,
      'Áreas': this.filtrosManejosAreas
    }[this.abaAtiva]

    const dadosFiltrados = listaAtiva.filter((a) => {
      const hash = a.hash

      const manejos = dadosManejosListaAtiva[hash]

      const manejosValidadosPeloFiltro = Object.keys(filtros).map((tipo) => {
        const procedimentos = filtros[tipo]

        const retornoMap = Object.keys(procedimentos).map((procedimento) => {
          let valorDoCampoObrigatorio = null
          if (tipo === 'sanitario') {
            valorDoCampoObrigatorio = manejos[tipo][procedimento]['aplicado']
          }
          if (tipo !== 'nutricao' && tipo !== 'sanitario') {
            if (this.abaAtiva === 'Lotes') {
              valorDoCampoObrigatorio = manejos['manejosLotes'][procedimento][camposObrigatorios[procedimento]]
            }
            if (this.abaAtiva === 'Animais') {
              if (procedimento == 'inseminacao') {
                valorDoCampoObrigatorio = manejos[tipo][procedimento]?.inseminacao_semen || manejos[tipo][procedimento]?.inseminacao_embriao
              } else if (procedimento == 'monta-natural') {
                valorDoCampoObrigatorio = manejos[tipo][procedimento]?.cobertura_hash_reprodutor || manejos[tipo][procedimento]?.cobertura_hash_reprodutor_externo
              } else {
                valorDoCampoObrigatorio = manejos[tipo][procedimento][camposObrigatorios[procedimento]]
              }
            }
          }

          let preenchido = false

          if (valorDoCampoObrigatorio && tipo !== 'nutricao') {
            preenchido = true
          }
          if (tipo === 'nutricao') {
            if (Object.keys(manejos[tipo]).length === 0) {
              preenchido = false
            }
            preenchido = Object.keys(manejos[tipo]).every(hashProcedimento => manejos[tipo][hashProcedimento].quantidade)
          }

          if (filtros[tipo][procedimento] === 'todos') {
            return true
          } else {
            return (filtros[tipo][procedimento] === 'preenchidos' && preenchido) || (filtros[tipo][procedimento] === 'nao-preenchidos' && !preenchido)
          }
        })

        return retornoMap.every(a => !!a)
      })

      return manejosValidadosPeloFiltro.every((a) => a)
    })

    if (this.abaAtiva === 'Animais') return dadosFiltrados
    if (this.abaAtiva === 'Lotes') return dadosFiltrados
    if (this.abaAtiva === 'Áreas') return dadosFiltrados
  }

  ordenacaoLocal(): void {
    const dadosOrdenados = {
      'Animais': this.tabelaService.ordenarDados(this.animais as Record<string, string | number | boolean>[], this.ordenacaoAnimais),
      'Lotes': this.tabelaService.ordenarDados(this.lotes, this.ordenacaoLotes),
      'Áreas': this.tabelaService.ordenarDados(this.areas as Record<string, string | number | boolean>[], this.ordenacaoAreas)
    }[this.abaAtiva]

    if (this.abaAtiva === 'Animais') this.animais = dadosOrdenados
    if (this.abaAtiva === 'Lotes') this.lotes = dadosOrdenados
    if (this.abaAtiva === 'Áreas') this.areas = dadosOrdenados
  }

  async openModalRelatorioManejos(): Promise<void> {
    const lotesPorPropriedade = Object.keys(this.lotesPorPropriedade).reduce((lotes, idPropriedade) => {
      this.lotesPorPropriedade[idPropriedade].map((lote) => {
        lotes[lote.hash] = lote
      })
      return lotes
    }, {})

    const lotesAssociados = { ...this.lotesAssociados, ...lotesPorPropriedade }

    const animaisAssociados = { ...this.animaisAssociados, ...this.animaisExternosAssociados }

    const listaAtiva = {
      'Animais': this.animaisFiltrados,
      'Lotes': this.lotesFiltrados,
      'Áreas': this.areasFiltradas
    }[this.abaAtiva]

    const dadosManejos = {
      'Animais': this.dadosManejosAnimais,
      'Lotes': this.dadosManejosLotes,
      'Áreas': this.dadosManejosAreas
    }[this.abaAtiva]

    const resumos = {
      'Animais': this.resumos,
      'Lotes': this.resumosLotes,
      'Áreas': this.resumosAreas
    }[this.abaAtiva]

    const manejosDisponiveis = {
      'Animais': this.manejosDisponiveis,
      'Lotes': this.manejosDisponiveisLotes,
      'Áreas': this.manejosDisponiveisAreas
    }[this.abaAtiva]

    const modal = await this.modalCtrl.create({
      component: RelatorioManejosComponent,
      componentProps: {
        dataManejo: this.data,
        dadosManejos,
        listaAtiva,
        resumos,
        manejosDisponiveis,
        lotesAssociados,
        animaisAssociados,
        propriedadesAssociadas: this.propriedades,
        lotesPorPropriedade: this.lotesPorPropriedade,
        estoquesAssociados: this.estoquesAssociados,
        estoqueNutricional: {
          animais: this.animaisEstoqueNutricional,
          lotes: this.lotesEstoqueNutricional,
          areas: this.areasEstoqueNutricional,
        },
        estoqueSanitario: {
          animais: this.animaisEstoqueSanitario,
          lotes: this.lotesEstoqueSanitario,
          areas: this.areasEstoqueSanitario,
        },
        areasAssociadas: this.areasAssociadas,
        piquetesAssociados: this.piquetesAssociados,
        abaAtiva: this.abaAtiva,
      },
      cssClass: 'modal-large'
    })

    await modal.present()
  }

  async openModalCadastrarEvento(): Promise<void> {
    const animaisSelecionados = this.hashsSelecionados.length > 0 ? this.hashsSelecionados.map(hash => this.animais.find(animal => animal.hash === hash)) : this.animais
    const modal = await this.modalCtrl.create({
      component: CalendarioEventoCadastrarPage,
      componentProps: { animaisSelecionados },
      cssClass: 'custom-modal'
    })

    await modal.present()
  }
  async verificarDataParaAlterarDataManejo(date: string, procedimento = null): Promise<void> {
    if (date === this.data) {
      const toast = await this.toast.create({
        message: 'Não é possível alterar para a data atual',
        duration: 3000
      })
      await toast.present()
      return
    }

    const alert = await this.alertCtrl.create({
      header: 'Alterar data',
      message: `Deseja alterar a data dos manejos selecionados para ${moment(date).format('DD/MM/YYYY')}?`,
      buttons: [
        {
          text: 'Não',
          handler: (): void => { }
        },
        {
          text: 'Sim',
          handler: (): void => {
            this.alterarDataManejo(date, procedimento)
          }
        }
      ]
    })

    await alert.present()
  }

  verificarDadosPiquetes(): void {
    
    const hasManejoPiquete = this.manejosDisponiveisAreas.some((manejo) => ['altura-capim'].includes(manejo.procedimento) || ['pastejo-rotacionado'].includes(manejo.procedimento))
    if (!hasManejoPiquete) {
      return
    }
    const hashsAreas = this.areas.map(a => a.hash)
    const hashsConsultadas = Object.keys(this.piquetes)
    const hashsAreasNaoConsultadas = hashsAreas.filter(hash => !hashsConsultadas.includes(hash))
    if (hashsAreasNaoConsultadas.length) {
      this.getDadosPiquetes(hashsAreasNaoConsultadas)
    }
  }

  async getDadosPiquetes(hashsAreas: string[]): Promise<void> {
    const [response, error] = await this.areasCtrl.getPiquetes({
      filtros: { hashs_areas: hashsAreas }
    })
    
    if (response) {
      response.piquetes.map(piquete => {
        this.piquetes[piquete.hash_area] = this.piquetes[piquete.hash_area] || []
        this.piquetes[piquete.hash_area].push(piquete.hash)
        this.piquetesAssociados[piquete.hash] = piquete
        this.formatarManejosAreas()
      })
    }

    if (error) {
      console.log('error:', error)
    }
  }

  atualizarPiquetes(event: IAreaPiquete): void {
    if (event) {  
      const hashArea = event?.hash_area
      const hashPiquete = event?.hash

      if (this.piquetes[hashArea]) {
        if (!this.piquetes[hashArea].includes(hashPiquete)) {
          this.piquetes[hashArea].push(hashPiquete)
        }
      } else {
        this.piquetes[hashArea] = [hashPiquete]
      }
      this.formatarManejosAreas()
  }}

  async getContas(): Promise<void> {
    const [response, erro] = await this.financasCtrl.getContas()

    if(response) {
      this.contas = response.contas
    }
  }

  async getCustosManejo(): Promise<void> {
    this.carregamentoLancamentoFinanceiros = 'carregando'

    const [response, erro] = await this.financasCtrl.getCustosManejo({
      filtros: {
        data: this.data
      }
    })

    if (response) {
      this.carregamentoLancamentoFinanceiros = 'sucesso'
      this.custosManejo = response.custos
      console.log('response', response)
      console.log('this.custosManejo', this.custosManejo)
      this.ref.detectChanges()
    }

    if (erro) {
      this.carregamentoLancamentoFinanceiros = 'erro'
    }
  }

  async abrirAdicionarCustos(custoHash?: string): Promise<void> {
    const transacao = await this.financasCtrl.getTransacao(custoHash)
    const props = transacao[0].lancamentos[0]
    const modal = await this.modalCtrl.create({
      cssClass: 'financeiro-modal',
      component: FinanceiroFormularioTransacaoPage,
      componentProps: {
        tipoTransacao: 'saida',
        contas: this.contas,
        despesaManejo: true,
        dataManejo: this.data,
        lancamentoProp: props
      }
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()

    if(data) {
      this.getCustosManejo()
    }
  }

  async excluirCustoManejo(hash: string): Promise<void> {
    const [response, erro] = await this.financasCtrl.excluirTransacao([hash])

    if(response) {
      this.getCustosManejo()
    }
  }

  async confirmarExcluirCustoManejo(hash: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Excluir custo de manejo',
      message: 'Deseja excluir o custo de manejo?',
      buttons: [
        {
          text: 'Não',
          handler: (): void => { }
        },
        {
          text: 'Sim',
          handler: (): void => {
            this.excluirCustoManejo(hash)
          }
        }
      ]
    })

    await alert.present()
  }

  async showPopoverOpcoes(event: Event, custoManejo: CustosManejo): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverSeletorComponent,
      event,
      reference: 'event',
      cssClass: 'popover-duas-opcoes',
      componentProps: {
        opcoesKeys: [
          'editar',
          'excluir'
        ],
        opcoes: {
          editar : { icone: 'pencil-outline', texto: 'Editar lançamento' },
          excluir: { icone: 'trash', texto: 'Excluir lançamento' }
        }
      }
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()

    if (data) {
      if (data == 'editar') {
        this.abrirAdicionarCustos(custoManejo.hash)
      }

      if (data == 'excluir') {
        this.confirmarExcluirCustoManejo(custoManejo.hash)
      }
    }
  }
}
