import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { CalendarioComponent } from '../calendario/calendario.component'

@Component({
  selector: 'data-seletor',
  templateUrl: './data-seletor.component.html',
  styleUrls: ['./data-seletor.component.scss']
})
export class DataSeletorComponent implements OnInit {
  @Input() label: string
  @Input() icon: string
  @Input() disabled: boolean
  @Output() selecionado: EventEmitter<string> = new EventEmitter()

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit(): void {}

  sendDate(data: string): void {
    this.selecionado.emit(data)
  }

  async abrirCalendario(event: Event): Promise<void> {
    const modal = await this.popoverCtrl.create({
      component: CalendarioComponent,
      event,
      reference: 'trigger',
      cssClass: 'popover-calendario'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      this.sendDate(data.iso)
    }
  }
}
