import { Component } from '@angular/core'
import { PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-popover-seletor-cadastro-animais',
  templateUrl: './popover-seletor-cadastro-animais.component.html',
  styleUrls: ['./popover-seletor-cadastro-animais.component.scss']
})
export class PopoverSeletorCadastroAnimaisComponent {
  constructor(private popoverCtrl: PopoverController) {}

  confirmar(tipo: string): void {
    this.popoverCtrl.dismiss({ tipo })
  }
}
