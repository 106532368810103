/* eslint-disable indent */
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertController, ModalController } from '@ionic/angular'
import { IOptionButtons } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { LotesService } from 'src/app/services/lotes.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ColunasLotes } from 'src/app/utils/configuracoes-padrao'
import { ILote, IResponseGetLotes, ITotaisLote } from 'src/app/utils/interfaces/lotes.interface'
import { ConfigTabela, IKpisCard } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalEdicaoLoteComponent } from './modal-edicao-lote/modal-edicao-lote.component'

@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.page.html',
  styleUrls: ['./lotes.page.scss']
})
export class LotesPage implements OnInit {
  public labels = ColunasLotes
  public lotes: ILote[]
  public isLoading = false

  public carregamentoLotes: StatusTabela = 'carregando'
  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  public config: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    impressaoTitulo: 'Lotes',
    buscaDados: async () => {
      const [response, error] = await this.lotesCtrl.getLotes({
        filtros: this.novoFiltro,
        ordenacao: this.novaOrdenacao,
        paginate: { offset: 0, limit: 5000 }
      })
      return {
        dados: response.dados
      }
    },
    ativarDownload: true,
    ativarImpressao: true,
    orderby: 'qtd_animais',
    orderbyDirection: 'asc',
    exibeTotais: false,
    excluirColunas: ['qtd_animais_na_data']
  }

  public totais = {}
  public observerPropriedade = null

  public optionsButtonsLotes: IOptionButtons[] = [
    {
      chave: 'visualizarLote',
      icone: 'create-outline',
      titulo: 'Visualizar',
      acao: (lote: DadosLinhaTabela): void => {
        this.irParaPaginaLoteSingle(lote.original)
      }
    },
    {
      chave: 'editarLote',
      icone: 'pencil-outline',
      titulo: 'Editar',
      acao: (lote: DadosLinhaTabela): void => {
        if (lote.original.hash === 'sem_lote') {
          return
        }
        this.openModalLote(lote.original)
      }
    },
    {
      chave: 'removerLote',
      icone: 'trash-outline',
      classe: 'permissao-escrita-animais',
      titulo: 'Remover',
      acao: (lote: DadosLinhaTabela): void => {
        if (lote.original.hash === 'sem_lote') {
          return
        }
        this.alertConfirmRemoverLote(lote.original)
      }
    }
  ]

  public optionsKpisCard: IKpisCard[] = [
    { key: 'total_peso', titulo: 'Peso Total', valor: 0, unidade: 'kg', formatacao: 'number' },
    { key: 'media_peso', titulo: 'Peso Médio', valor: 0, unidade: 'kg', formatacao: 'number' },
    { key: 'gmd_medio', titulo: 'GMD Médio dos Lotes', valor: 0, unidade: 'kg', formatacao: 'number' },
    {
      key: 'total_animais',
      titulo: 'Total de Animais',
      valor: 0,
      formatacao: 'number',
      classe: 'permissao-leitura-animais'
    }
  ]

  public novoFiltro: NovoFiltro = {}
  public novaOrdenacao: Ordenacao = { coluna: 'qtd_animais', direcao: 'desc' }

  constructor(
    private lotesCtrl: LotesService,
    private modalCtrl: ModalController,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.getLotes()
    })
  }

  async getLotes(): Promise<IResponseGetLotes> {
    this.isLoading = true
    this.carregamentoLotes = 'carregando'
    this.utilsCtrl.tick()

    const [response, error] = await this.lotesCtrl.getLotes({
      filtros: this.novoFiltro,
      ordenacao: this.novaOrdenacao,
      paginate: this.paginate
    })

    if (response) {
      this.isLoading = false
      this.carregamentoLotes = 'sucesso'
      this.lotes = response.dados as unknown as ILote[]
      this.totais = response.totais

      this.config.impressaoKpiCardConfig = this.optionsKpisCard
      this.config.impressaoKpiValores = response.totais

      this.utilsCtrl.tick()
      return response
    }

    if (error) {
      this.isLoading = false
      this.carregamentoLotes = 'erro'
      console.error(error)
    }
  }

  irParaPaginaLoteSingle(dadosLote: ILote): void {
    const state = dadosLote.hash ? dadosLote : null
    this.router.navigateByUrl(`lotes/${dadosLote.hash ? encodeURIComponent(dadosLote.hash) : 'sem_lote'}`, {
      state: { dadosLote: state }
    })
  }

  async openModalLote(loteParams?: ILote): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEdicaoLoteComponent,
      componentProps: { loteParams },
      cssClass: 'custom-modal',
      backdropDismiss: false
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (data?.success) {
      this.getLotes()
    }
  }

  async alertConfirmRemoverLote(lote: ILote): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Ao remover esse lote os manejos, custos, históricos e atribuições dos animais realizadas com esse lote serão perdidos',
      message: 'Tem certeza que deseja remover esse lote?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Remover',
          handler: async (): Promise<void> => {
            const [response, error] = await this.lotesCtrl.deletarLote({
              hashs: [lote.hash]
            })

            if (error) {
              const message = error.message ? error.message : 'Erro ao excluir lote, tente novamente mais tarde'
              this.utilsCtrl.showToast(message, 'bottom')
            }

            if (response) {
              this.getLotes()
              this.utilsCtrl.showToast('Lote excluído com sucesso', 'bottom')
            }
          }
        }
      ]
    })

    await alert.present()
  }
}
