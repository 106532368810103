<ion-split-pane contentId="main" when="(min-width: 1024px)">
  <ion-menu contentId="main">
    <header>
      <div class="logo">
        <img src="assets/imgs/logo-fertili.svg" alt="imgs/logo-fertili.svg" />
        <span>v{{APP_VERSION}}</span>

        <button class="search-button" (click)="openModalPesquisa()">
          <ion-icon name="search"></ion-icon>
        </button>
      </div>
      <div class="propriedade" (click)="openModalListaPropriedades()">
        <div class="container">
          <span class="label">Propriedade</span>
          <propriedade-nome [tipo]="'h1'"></propriedade-nome>
        </div>
        <ion-icon name="caret-down-outline"></ion-icon>
      </div>
    </header>
    <main class="menu-container">
      <div class="list-pages">
        <ng-template let-page ngFor [ngForOf]="appPages">
          <button
            *ngIf="
              !page.esconder && 
              (!planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('abasMenuLateral', page.url) || propriedadesCtrl.isPropriedadeExemploAtivada) && 
              (!page.beta || (page.beta && !usuarioCtrl?.usuarioCache?.disableChat))
            "
            class="menu-item"
            [disabled]="page.url && !permissoesUsuarioCtrl.isAcessoDoUsuarioLiberado"
            [class.separator]="page.type === 'separator'"
            [class.sub]="page.sub"
            [class.active]="page.url === navegacaoCtrl.baseAtual"
            (click)="menuClose(page.url)"
          >
            <ion-icon *ngIf="page.type !== 'separator' && page.icon" [name]="page.icon" class="icon-left"></ion-icon>
            <span [class]="page.class">{{page.title}}</span>
            <span class="beta" *ngIf="page.beta">beta</span>
            <ion-icon *ngIf="page.type === 'separator' && page.icon" [name]="page.icon"></ion-icon>
          </button>
        </ng-template>

        <button class="menu-item" (click)="openLinkWhatsapp()">
          <ion-icon name="logo-whatsapp"></ion-icon>
          <span>Ajuda</span>
        </button>

        <button class="menu-item" (click)="openModalConfiguracoes()">
          <ion-icon name="cog"></ion-icon>
          <span>Configurações</span>
        </button>

        <cta-buttons *ngIf="planoBasicoCtrl.isPlanoBasico && !propriedadesCtrl.isPropriedadeExemploAtivada" class="item-premium">
          <button class="primary" (click)="openModalPlanoBasico($event)">
            <ion-icon name="diamond"></ion-icon>
            <span>{{usuarioCtrl?.usuarioCache?.trial_iniciado === 1 ? 'TESTE ENCERRADO' : 'EXPERIMENTE O'}}
              <br>
              <strong>{{usuarioCtrl?.usuarioCache?.trial_iniciado === 1 ? 'ASSINE O PLANO PREMIUM' : 'PLANO PREMIUM'}}</strong>
            </span>
          </button>
        </cta-buttons>

        <button
          *ngIf="planoBasicoCtrl.isPlanoBasico && !propriedadesCtrl.isPropriedadeExemploAtivada"
          class="menu-item plano-basico item-premium"
          style="padding-top: 0"
          [class.separator]="true"
        >
          <span>Recursos Premium</span>
          <ion-icon name="diamond"></ion-icon>
        </button>

        <div *ngIf="planoBasicoCtrl.isPlanoBasico && !propriedadesCtrl.isPropriedadeExemploAtivada">
          <ng-template let-page ngFor [ngForOf]="appPages">
            <button
            *ngIf="!page.esconder && planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('abasMenuLateral', page.url)"
              class="menu-item"
              [disabled]="page.url && !permissoesUsuarioCtrl.isAcessoDoUsuarioLiberado"
              [class.separator]="page.type === 'separator'"
              [class.sub]="page.sub"
              [class.active]="page.url === navegacaoCtrl.baseAtual"
              [class.item-bloqueado-plano-basico]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('abasMenuLateral', page.url)"
              (click)="menuClose(page.url, $event)"
            >
              <span [class]="page.class">{{page.title}}</span>
              <ion-icon [name]="page.icon"></ion-icon>
            </button>
          </ng-template>
        </div>
      </div>
    </main>
    <footer>
      <div class="proprietario">
        <ion-icon name="person-circle-outline"></ion-icon>
        <h3>
          {{usuarioCtrl?.usuarioCache?.nome}}
          <ion-skeleton-text
            *ngIf="!usuarioCtrl?.usuarioCache?.nome"
            animated="true"
            style="width: 60%; margin-bottom: 2px"
          ></ion-skeleton-text>
          <span *ngIf="!propriedadesCtrl?.propriedadeSelecionada?.propriedade_exemplo">
            {{informacoesDoPlanoUsuario?.meuRole}}
            <ion-skeleton-text
              *ngIf="!informacoesDoPlanoUsuario?.meuRole"
              animated="true"
              style="width: 40%; margin: 0"
            >
            </ion-skeleton-text>
          </span>
        </h3>
      </div>
      <div class="stats-container" *ngIf="!propriedadesCtrl?.propriedadeSelecionada?.propriedade_exemplo">
        <div class="stats-single">
          <span class="value">{{labelPlano(usuarioCtrl?.usuarioCache?.plano_ativo) || usuarioCtrl?.usuarioCache?.plano_ativo}}</span>
          <span class="label">Plano</span>
        </div>
        <div class="stats-single" *ngIf="informacoesDoPlanoUsuario">
          <span
            class="value"
            *ngIf="!isAtualizandoCartaoUsuario"
            [class.amarelo]="informacoesDoPlanoUsuario?.percentualAnimaisCadastrados > 90 && informacoesDoPlanoUsuario?.percentualAnimaisCadastrados <= 95"
            [class.vermelho]="informacoesDoPlanoUsuario?.percentualAnimaisCadastrados > 95"
          >
            {{informacoesDoPlanoUsuario?.animaisCadastrados || 0}}/{{informacoesDoPlanoUsuario?.limiteAnimais}}
            <small>{{informacoesDoPlanoUsuario?.percentualAnimaisCadastrados | number: '1.0-0'}}%</small>
          </span>
          <ion-skeleton-text *ngIf="isAtualizandoCartaoUsuario" animated="true" style="width: 100%"></ion-skeleton-text>
          <span class="label">Animais</span>
        </div>
        <div class="stats-single" *ngIf="!informacoesDoPlanoUsuario">
          <span class="label">Animais</span>
          <ion-skeleton-text animated="true" style="width: 100%; height: 10px; margin: 1px 0 0"></ion-skeleton-text>
        </div>
      </div>
    </footer>
  </ion-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-split-pane>
