<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/animais"></ion-back-button>
    </ion-buttons>

    <ion-title>
      <propriedade-nome></propriedade-nome>
      <ng-container *ngIf="!isCarregandoLote">
        {{ lote?.nome ? (lote?.confinamento ? 'Confinamento' : 'Lote') : 'Animais Sem Lote' }}
        <b>
          {{ lote?.nome ? lote.nome : '' }}
        </b>
      </ng-container>
      <ion-skeleton-text
        *ngIf="isCarregandoLote"
        style="width: 10%; height: 15px; border-radius: 6px"
        animated
      ></ion-skeleton-text>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button disabled>
        <ion-icon name="help" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <header-tabs
    *ngIf="lote?.hash"
    [tabs]="['Animais', 'Manejos', 'Histórico']"
    [(tabActive)]="abaAtiva"
    (tabActiveChange)="tabChange()"
  ></header-tabs>
</ion-header>
<ion-content>
  <div [ngClass]="{ 'ion-hide': abaAtiva !== 'Animais' }">
    <cta-buttons *ngIf="lote?.hash">
      <data-seletor
        label="Novo Manejo"
        icon="mais"
        class="permissao-escrita-lotes acao-cadastro"
        [disabled]="!!hashsSelecionados.length"
        (selecionado)="abrirManejo($event)"
      ></data-seletor>
      <button
        [disabled]="hashsSelecionados.length"
        (click)="openModalLote()"
        class="permissao-escrita-lotes acao-cadastro"
      >
        <ion-icon name="pencil"></ion-icon>
        <span>{{ lote?.confinamento ? 'Editar Confinamento' : 'Editar Lote' }}</span>
      </button>
      <button (click)="showPopoverButtonAdicionarAnimal($event)" class="permissao-escrita-lotes">
        <ion-icon name="mais"></ion-icon>
        <span>Adicionar Animais</span>
      </button>
      <button (click)="associarLoteAArea()" class="permissao-escrita-lotes">
        <ion-icon name="location-sharp"></ion-icon>
        <span>Mover para Área</span>
      </button>
    </cta-buttons>
    <kpis-cards
      *ngIf="lote?.hash && lote?.hash !== 'sem_lote'"
      [cardsInfo]="optionsKpisCard"
      [valores]="kpisTotais"
      [wrap]="true"
    ></kpis-cards>
    <vasta-tabela
      [status]="carregamentoAnimais"
      labelsId="colunas_animais"
      [config]="config"
      [campos]="animais"
      [optionsButtons]="optionsButtonsAnimais"
      [optionsSelecionados]="optionsButtonsSelecionadosTabela"
      [filtrosFixos]="filtrosFixos"
      [lotes]="[lote]"
      [(hashsSelecionados)]="hashsSelecionados"
      [(paginate)]="paginate"
      (paginateChange)="getAnimais()"
      [(novaOrdenacao)]="novaOrdenacao"
      [(novoFiltro)]="novoFiltroAnimais"
      (novoFiltroChange)="getAnimais()"
      (retentar)="getAnimais()"
      (selecionado)="selecionouAnimal($event)"
      (hashsSelecionadosChange)="getHashsSelecionados($event)"
    ></vasta-tabela>
  </div>
  <div [ngClass]="{ 'ion-hide': abaAtiva !== 'Histórico' }">
    <vasta-tabela
      [labels]="labelsHistorico"
      [config]="configTabelaHistorico"
      [campos]="historicoLotes"
      [status]="carregamentoHistoricoLotes"
      (retentar)="getHistorico()"
      (selecionado)="abrirManejosDoDia($event)"
    ></vasta-tabela>
  </div>

  <div [ngClass]="{ 'ion-hide': abaAtiva !== 'Manejos' }">
    <cta-buttons>
      <data-seletor label="Novo Manejo" icon="mais" (selecionado)="abrirManejo($event)"></data-seletor>
    </cta-buttons>

    <vasta-tabela
      [labels]="todosManejosLabels"
      [campos]="todosManejos"
      [config]="todosManejosConfig"
      [status]="carregamentoTodosManejos"
      [totais]="totaisManejos"
      [(paginate)]="paginateTodosManejos"
      (retentar)="getTodosManejos()"
      (selecionado)="abrirManejo($event, true)"
      [(novoFiltro)]="novoFiltroManejos"
      (novoFiltroChange)="getTodosManejos()"
      [filtrosExtras]="filtrosExtras"
    >
      <ng-template #innerComponents let-linha>
        <div class="container-resumo-manejos">
          <div
            class="item-manejo"
            *ngFor="let item of linha.original.manejos"
            [ngClass]="{
              pesagem: item?.procedimento === 'pesagem',
              ordenha: item?.procedimento === 'ordenha',
              reprodutivo: listaManejosReprodutivos.includes(item?.procedimento),
              sanitario: listaManejosSanitarios.includes(item?.procedimento),
              geral: listaManejosGerais.includes(item?.procedimento)
            }"
            (click)="abrirManejo(linha, true)"
          >
            <ion-icon [name]="manejosDisponiveis[item?.procedimento]?.icon"></ion-icon>
            <span class="tipo-manejo">{{ manejosDisponiveis[item?.procedimento]?.nome }}</span>
            <span class="qtd-animais">{{ item.qtd_animais }}</span>
          </div>
        </div>
      </ng-template>
    </vasta-tabela>

    <div class="placeholder-vazio" *ngIf="carregamentoTodosManejos === 'sucesso' && !todosManejos?.length">
      <p>Nenhum manejo encontrado</p>
    </div>
  </div>
</ion-content>
