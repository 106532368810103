<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ pessoa.hash ? 'Editar Cadastro' : 'Novo Cadastro' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-input type="text" label="Nome" [(value)]="pessoa.nome" [obrigatorio]="true"></vasta-input>
  <vasta-input
    type="select"
    label="Categoria"
    placeholder="Selecionar"
    [(value)]="pessoa.categoria_pai"
    [obrigatorio]="true"
    [opcoes]="[
      { label: 'Fornecedor', value: 'fornecedor' },
      { label: 'Cliente', value: 'cliente' },
      { label: 'Funcionário', value: 'funcionario' },
      { label: 'Terceirizado', value: 'terceirizado' },
      { label: 'Inseminador', value:'inseminador'},
      { label: 'Frigorífico', value: 'frigorifico' },
    ]"
  ></vasta-input>
  <vasta-input type="textarea" label="Descrição" [(value)]="pessoa.descricao"></vasta-input>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <cta-buttons class="ion-float-right">
      <button
        class="primary permissao-escrita-financeiro"
        (click)="criaNovaPessoa()"
        [disabled]="!pessoa.nome || !pessoa.categoria_pai || loading"
      >
        <ion-spinner name="dots" color="light" *ngIf="loading"></ion-spinner>
        <ion-icon name="checkmark" *ngIf="!loading"></ion-icon>
        <span>Salvar</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
