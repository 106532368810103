import { commonEnv } from './environment.common'

const env = {
  production: false,
  name: 'staging',
  firebaseConfig: {
    apiKey: 'AIzaSyCoktmiGof2EU1AlMFTlvwsE0nwZ2jEg7Q',
    authDomain: 'fertili-staging.firebaseapp.com',
    projectId: 'fertili-staging',
    storageBucket: 'fertili-staging.appspot.com',
    messagingSenderId: '51765679990',
    appId: '1:51765679990:web:bebf88d9e452d78bac4c57',
    measurementId: 'G-HHFXCBZ95E',
    vapid: 'BEhP6cOKwkqSCbJ-ER7_cGCvYr1AKRntkwtbVF5bsHK_3J5VPWlXWoX1Wrjl5ob_WHTaBGGQWxgt69HKl8DrNYQ'
  },
  url: 'https://staging.api.fertili.com.br'
}

export const environment = {
  ...commonEnv,
  ...env
}
