import { Injectable } from '@angular/core'
import { ApiService } from './api/api.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'

export interface Notificacao {
    titulo: string
    mensagem: string
    tipo_destino: 'usuario' | 'propriedade'
    usuario?: string
    propriedade?: string
    codigo?: string
    tipo: 'link_externo' | 'link_interno' | 'informacao' | 'modal' | 'codigo_utilizado' | 'nova_aprovacao'
    link: string
    timestamp?: string
}

@Injectable({
  providedIn: 'root'
})

export class NotificacoesService {

  constructor(
    private apiCtrl: ApiService,
    private permissoesCtrl: PermissoesUsuarioService
  ) { }


  async listarNotificacoes(): Promise<[{ notificacoes: Notificacao[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('propriedade', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'notificacoes'
      })) as { notificacoes: Notificacao[] }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }
}