import { Injectable } from '@angular/core'
import { IAnimal } from '../utils/interfaces/animais.interface'
import {
  AcontecimentosDoDia,
  CalendarioAcontecimentosDoDia,
  CalendarioAcontecimentosDoMes,
  ICalendario
} from '../utils/interfaces/calendario.interface'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'
import { ApiService } from './api/api.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'
import { NovoQueryParamsModel } from '../utils/interfaces/query-params.interface'

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {
  private urlBase = 'calendario'

  constructor(private apiCtrl: ApiService, private permissoesCtrl: PermissoesUsuarioService) {}

  async salvarEvento(evento: {
    evento: ICalendario
    hashsAnimais: string[]
  }): Promise<[{ evento: ICalendario; animais: IAnimal[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('calendario', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: `${this.urlBase}/evento`,
        body: evento
      })) as { evento: ICalendario; animais: IAnimal[] }

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async detalhesDoEvento(hash: string): Promise<[{ evento: ICalendario; animais: IAnimal[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('calendario', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `${this.urlBase}/evento/${encodeURIComponent(hash)}`
      })) as { evento: ICalendario; animais: IAnimal[] }

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async listarEventosNoCalendario(dataMesAno: string): Promise<[CalendarioAcontecimentosDoMes, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('calendario', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `${this.urlBase}/${dataMesAno}`
      })) as CalendarioAcontecimentosDoMes

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async listarEventosDoDia(filtros?: NovoQueryParamsModel): Promise<[CalendarioAcontecimentosDoDia, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('calendario', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `${this.urlBase}/${filtros.filtros.dataMesAno}/${filtros.filtros.dia}`,
        filtros: filtros
      })) as CalendarioAcontecimentosDoDia

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async apagarEventoDoDia(eventoHash: string): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('calendario', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: `${this.urlBase}/evento/${eventoHash}`
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  formatarTituloEventos(acontecimentos: AcontecimentosDoDia[], isResumida?: boolean): AcontecimentosDoDia[] {
    const eventosComTitulosFormatados = acontecimentos.map((a) => {
      const fix = (n): string => Number(n).toFixed(2).replace('.', ',')
      if (a.tipo == 'pesagem') {
        if (!isResumida) {
          a.titulo =
            a.qtd == 1
              ? `Pesagem de 1 animal, peso ${fix(a.peso_total)} kg, GMD ${fix(a.gmd_medio)} kg`
              : `Pesagem de ${a.qtd} animais, peso total ${fix(a.peso_total)} kg, GMD médio ${fix(a.gmd_medio)} kg`
        } else {
          a.titulo = a.qtd == 1 ? 'Pesagem de 1 animal' : `Pesagem de ${a.qtd} animais`
        }
      }
      if (a.tipo == 'parto') {
        a.titulo = a.qtd == 1 ? 'Previsão de parto de 1 animal' : `Previsão de parto de ${a.qtd} animais`
      }
      if (a.tipo == 'desmame') {
        a.titulo = a.qtd == 1 ? 'Previsão de desmame de 1 animal' : `Previsão de desmame de ${a.qtd} animais`
      }
      if (a.tipo == 'chuva') {
        a.titulo = `Índice de chuva de ${a.qtd} mm`
      }
      if (a.tipo == 'reprodutivo') {
        a.titulo = a.qtd == 1 ? `${a.qtd} manejo reprodutivo` : `${a.qtd} manejos reprodutivos`
      }
      if (a.tipo == 'sanitario') {
        a.titulo = a.qtd == 1 ? `${a.qtd} manejo sanitário` : `${a.qtd} manejos sanitários`
      }
      if (a.tipo == 'ordenha') {
        if (!isResumida) {
          a.titulo =
            a.qtd == 1
              ? `Ordenha de 1 vaca, produção ${fix(a.ordenha_total)} kg`
              : `Ordenha de ${a.qtd} vacas, produção total ${fix(a.ordenha_total)} kg`
        } else {
          a.titulo = a.qtd == 1 ? 'Ordenha de 1 vaca' : `Ordenha de ${a.qtd} vacas`
        }
      }

      return a
    })

    return eventosComTitulosFormatados
  }

  formatarTiposECategorias(acontecimentos: AcontecimentosDoDia[]): AcontecimentosDoDia[] {
    const eventosFormatados = acontecimentos.map((evento) => {
      const agendamento: string = JSON.parse(JSON.stringify(evento.agendamento))

      // // Definir categoria
      if (evento.tipo === 'evento') {
        if (agendamento.includes('manejo')) {
          const tipoManejo = agendamento.split('-')[1] || 'padrao'
          evento['categoria'] = tipoManejo
        } else {
          evento['categoria'] = agendamento
        }
      } else {
        evento['categoria'] = evento.tipo
      }

      // Definir tipo
      if (evento.tipo === 'evento' && agendamento === 'outros') {
        evento.tipo = 'Evento'
      } else if (evento.tipo === 'evento' && agendamento?.length) {
        evento.tipo = 'Agendamento'
      } else if ((evento.tipo === 'parto' || evento.tipo === 'desmame') && !agendamento?.length) {
        evento.tipo = 'Previsão'
      } else if (evento.tipo !== 'evento' && !agendamento?.length) {
        evento.tipo = 'Registro'
      }

      return evento
    })

    return eventosFormatados
  }
}
