import { Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import * as moment from 'moment'
import { AnimaisService } from 'src/app/services/animais.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { EvolucaoAnimal, IAnimalDetalhado } from 'src/app/utils/interfaces/animais.interface'

@Component({
  selector: 'app-modal-evolucao-animal',
  templateUrl: './modal-evolucao-animal.component.html',
  styleUrls: ['./modal-evolucao-animal.component.scss']
})
export class ModalEvolucaoAnimalComponent implements OnInit {
  public evolucao: EvolucaoAnimal = {
    idade_animal: '',
    peso: null,
    link: null,
    data: moment().format('YYYY-MM-DD'),
    hash: null
  }
  public evolucaoProp: EvolucaoAnimal
  public imagemAnimal: string = null
  public animal: IAnimalDetalhado
  constructor(
    private animaisCtrl: AnimaisService,
    private utilsCtrl: IonicUtilsService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {}

  ngOnInit(): void {
    if (this.evolucaoProp) {
      this.evolucao = { ...this.evolucaoProp }
      this.imagemAnimal = this.evolucao.link
    }
  }

  async salvarEvolucaoAnimal(): Promise<void> {
    this.evolucao.idade_animal = Number(this.evolucao.idade_animal)
    const loading = await this.utilsCtrl.showLoading('circular', 'Salvando evolução...')
    const [response, error] = await this.animaisCtrl.cadastrarEvolucaoAnimal(this.evolucao)
    this.utilsCtrl.dismissLoading(loading)

    if (response) {
      this.modalCtrl.dismiss({ success: true })
    }

    if (error) {
      console.log(error)
    }
  }

  async adicionarImagem(imagemBase64: string): Promise<void> {
    const urlImagem = await this.getUrlImagem(imagemBase64)
    this.imagemAnimal = imagemBase64
    this.evolucao.link = urlImagem
    this.evolucao.hash = this.animal.hash
  }

  async getUrlImagem(imagem: string): Promise<string> {
    const loading = await this.utilsCtrl.showLoading('circular', 'Adicionando imagem...')
    const [response, error] = await this.animaisCtrl.enviarImagem({ tipo: 'evolucao', imagem })

    this.utilsCtrl.dismissLoading(loading)

    if (response) {
      return response.url
    }

    if (error) {
      this.utilsCtrl.showToast('Erro no upload das imagens', 'bottom')
      console.log(error)
      throw error
    }
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }

  removerImagemAnimal(): void {
    this.imagemAnimal = null
    this.evolucao.link = null
  }

  async alertConfirmRemoverEvolucaoAnimal(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Deseja relamente excluir a evolução do animal?',
      buttons: [
        { text: 'Cancelar' },
        {
          text: 'Confirmar',
          handler: (): void => {
            this.removerEvolucaoAnimal()
          }
        }
      ]
    })

    await alert.present()
  }

  async removerEvolucaoAnimal(): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circular', 'Removendo evolução...')
    const [response, error] = await this.animaisCtrl.removerEvolucaoAnimal(this.evolucao)
    this.utilsCtrl.dismissLoading(loading)

    if (response) {
      this.modalCtrl.dismiss({ success: true })
    }

    if (error) {
      console.log(error)
    }
  }
}
