import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { StatusTabela } from '../vasta-tabela/tabela-types'
import { ChartDataset, ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js'

export interface Card {
  chave: string
  titulo?: string
  icon?: string
  valor?: number | string
  bold?: boolean
  right?: boolean
  class?: string
  format?: 'dinheiro' | 'peso' | 'dias'
  grafico?: boolean
}

export interface CardDados {
  [key: string]: string | number
}

export interface CardResumos {
  titulo: string
  colunas: Card[]
  dados: CardDados[]
}

@Component({
  selector: 'card-resumos',
  templateUrl: './card-resumo.component.html',
  styleUrls: ['./card-resumo.component.scss']
})
export class CardResumosComponent implements OnInit {
  @Input() cards: {
    titulo: string;
    colunas: Card[];
    dados: CardDados[]
    grafico_type?: 'donut' | 'bar' | 'percentual'
    grafico_labels?: string[]
    grafico_dataset_bar?: ChartDataset
    grafico_dataset_donut?: ChartDataset
  }[] = []
  @Input() loading: StatusTabela = 'carregando'

  graficos_type?: 'donut' | 'bar' = 'bar'

  barOptions: ChartOptions = {
    animation: false,
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            family: 'Oxygen Mono'
          },
        },
        grid: { display: false }
      },
      y: {
        ticks: {
          font: {
            family: 'Oxygen Mono'
          },
        },
        grid: { display: false },
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }
  percentualOptions: ChartOptions = {
    animation: false,
    responsive: false,
    maintainAspectRatio: false,
    // indexAxis: 'y',
    scales: {
      x: {
        ticks: {
          font: {
            family: 'Oxygen Mono'
          },
        },
        grid: { display: false }
      },
      y: {
        grid: { display: false },
        ticks: {
          font: {
            family: 'Oxygen Mono'
          },
          callback: (value: string): string => {
            if (Number(value) > 1000000 || Number(value) < -1000000) {
              return `${(Number(value) / 1000000).toFixed(0)} mi %`
            } else if (Number(value) > 1000 || Number(value) < -1000) {
              return `${(Number(value) / 1000).toFixed(0)} mil %`
            } else {
              return `${Number(value).toFixed(0)} %`
            }
          }
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<keyof ChartTypeRegistry>): string => {
            return `${Number(context.raw).toFixed(1)} %`
          }
        }
      },
      legend: {
        display: false
      }
    }
  }

  donutOptions: ChartOptions = {
    animation: false,
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      }
    }
  }

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(_: SimpleChanges): void {
    this.processarCards()
  }

  processarCards(): void {
    this.cards?.map(dados => {
      const hasColunaGrafico = dados.colunas.find(c => !!c.grafico)?.chave
      const hasQuantidade = dados.colunas.find(c => c.chave == 'quantidade') 

      const labels = dados.dados.map(a => a.label) as string[]
      const dataset = dados.dados.map(a => a[hasColunaGrafico || 'quantidade']) as number[]
      const datasetPercentual = dados.dados.map(a => a.percentualNumerico) as number[]

      if (!(dataset.find(a => a > 0) || datasetPercentual.find(a => a > 0))) {
        return
      }
      
      const backgroundColor = [
        '#3e6666',
        '#3e9999',
        '#3ea7a7',
        '#3eaaaa',
        '#3eb7b7',
        '#3ebbbb',
        '#3ec7c7',
        '#3ecccc',
        '#3ed7d7',
        '#3edddd',
        '#3ef7f7',
        '#3effff',
      ]

      dados.grafico_labels = labels

      if (hasQuantidade || hasColunaGrafico) {
        dados.grafico_type = 'donut'
        dados.grafico_dataset_bar = {
          label: dados.titulo,
          data: dataset,
          type: 'bar',
          maxBarThickness: 26,
          borderWidth: 0,
          borderRadius: 3,
          backgroundColor,
        }
        dados.grafico_dataset_donut = {
          type: 'doughnut',
          data: dataset,
          backgroundColor,
        }
      } else {
        dados.grafico_type = 'percentual'
        dados.grafico_dataset_bar = {
          label: dados.titulo,
          data: datasetPercentual,
          type: 'bar',
          maxBarThickness: 26,
          borderWidth: 0,
          borderRadius: 3,
          backgroundColor,
        }
      }
    })

    window.dispatchEvent(new Event('resize'))
  }
}
