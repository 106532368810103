<div class="audio-player">
  <button (click)="isPlaying ? pause() : play()">
    <ion-icon [name]="isPlaying ? 'pause' : 'play'"></ion-icon>
  </button>
  <input type="range" [max]="duration" [(ngModel)]="currentTime" (input)="seekTo($event)">
  <div class="time-counter">{{ currentTime ? displayTime : displayDuration }}</div>
  <button class="playback-speed" (click)="togglePlaybackSpeed()" [ngClass]="{ 'active': playbackSpeed !== 1 }">
    {{ playbackSpeed }}x
  </button>
  <audio [src]="fileUrl" #audioRef (timeupdate)="updateCurrentTime()"></audio>
</div>