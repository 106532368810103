<section
  class="fertili-tabela"
  [class.selecionavel]="config && config.selecionavel"
  [class.selecionar]="config && config.selecao && config.selecao.ativar"
  [class.exibeTotais]="config && config.exibeTotais && dadosBackupFormatados.length"
  #tabela
>
  <section class="wrapper">
    <section class="header-container">
      <section
        class="new-header"
        [ngClass]="{selecionados: hashsSelecionados.length && this.optionsSelecionados?.length}"
        *ngIf="config && config.toolbar"
      >
      <div class="left">
        <div class="container-actions" *ngIf="!config?.ocultarFiltros">
        <cta-buttons class="opcoes" *ngIf="hashsSelecionados.length">
          <button class="primary" (click)="showPopoverOpcoes($event)" *ngIf="this.optionsSelecionados?.length">
            <ion-icon name="menu-outline"></ion-icon>
            <span>Opções</span>
          </button>
        </cta-buttons>
          <ng-template ngFor let-filtro [ngForOf]="filtrosExtras">
            <button
              class="btn-filtro"
              (click)="executaAcaoFiltroExtra($event, filtro)"
              [class.active]="novoFiltro[filtro.chave]"
            >
              <ion-icon [name]="filtro.icone"></ion-icon>
              <div class="titlecase">
                {{filtro.titulo}}
                <p>{{filtro.valor || novoFiltro[filtro.chave] || 'Todos'}}</p>
              </div>
              <ion-icon
                name="close"
                (click)="removerFiltroExtra($event, filtro)"
                *ngIf="novoFiltro[filtro.chave] && !filtro.obrigatorio"
              ></ion-icon>
            </button>
          </ng-template>
          <div class="divisor-filtros" *ngIf="filtrosExtras?.length && filtrosDisponiveis"></div>
          <ng-template ngFor let-coluna [ngForOf]="labels">
            <ng-template [ngIf]="novoFiltro[coluna.chave]">
              <button
                *ngIf="coluna.tipoFiltro"
                class="btn-filtro"
                [ngClass]="{fixo:filtrosFixos.includes(coluna.chave) }"
                (click)="openPopoverFiltros($event, coluna)"
              >
                <div>
                  {{coluna.titulo}}
                  <ng-template [ngIf]="coluna.tipoFiltro === 'rangeDatas'">
                    <p>{{formatarData(novoFiltro[coluna.chave])}}</p>
                  </ng-template>
                  <ng-template [ngIf]="['preenchidos', 'preenchidosMultiplos'].includes(coluna.tipoFiltro)">
                    <p
                      class="titlecase"
                      *ngIf="coluna.chave !== 'extra_lote' && coluna.chave !== 'extra_lote_matriz' && coluna.chave !== 'nome_area' && coluna.chave !== 'extra_area' && coluna.chave !== 'lotes_nomes' && coluna.chave !== 'extra_hash_lote_label'"
                    >
                      {{formatarLabel(coluna.labelFormat, novoFiltro[coluna.chave], coluna.chave)}}
                    </p>
                    <p
                      class="titlecase"
                      *ngIf="['extra_lote', 'lotes_nomes', 'extra_lote_matriz', 'extra_hash_lote_label'].includes(coluna.chave)"
                    >
                      {{formatarLabelLote(novoFiltro[coluna.chave])}}
                    </p>
                    <p *ngIf="coluna.chave === 'nome_area' || coluna.chave === 'extra_area'">
                      {{formatarLabelArea(novoFiltro[coluna.chave])}}
                    </p>
                  </ng-template>
                  <ng-template [ngIf]="['texto', 'rangeNumeros'].includes(coluna.tipoFiltro)">
                    <p class="titlecase">{{novoFiltro[coluna.chave]}}</p>
                  </ng-template>
                </div>
                <ion-icon
                  name="close"
                  (click)="removerNovoFiltro($event, coluna.chave)"
                  *ngIf="!filtrosFixos.includes(coluna.chave)"
                ></ion-icon>
              </button>
            </ng-template>
          </ng-template>
          <ng-template ngFor let-coluna [ngForOf]="labels">
            <button
              class="btn-filtro ordenacao-mobile"
              *ngIf="coluna.chave === novaOrdenacao?.coluna"
              (click)="openPopoverFiltros($event, coluna, true)"
            >
              <div>
                Ordenar por
                <p class="titlecase">{{coluna.titulo}}</p>
              </div>
              <ion-icon
                [name]="novaOrdenacao.direcao === 'asc'? 'arrow-up-outline' : 'arrow-down-outline'"
                (click)="mudarDirecaoOrdenacao($event)"
              ></ion-icon>
            </button>
          </ng-template>
          <button
            class="btn-add"
            (click)="openPopoverFiltros($event)"
            *ngIf="filtrosDisponiveis && !hashsSelecionados.length"
          >
            <ion-icon name="add-outline"></ion-icon>
            <span *ngIf="keyLength(novoFiltro) < 1">Filtrar</span>
          </button>
        </div>
        <div class="info">
          <p *ngIf="!hashsSelecionados?.length && status !== 'carregando'">
            {{paginate?.total || campos?.length}} {{keyLength(novoFiltro) ? 'filtrados' : 'itens'}}
          </p>
          <p *ngIf="hashsSelecionados.length && status !== 'carregando'">
            {{hashsSelecionados?.length}} selecionado{{hashsSelecionados?.length === 1 ? '' : 's'}},
            {{paginate?.total || campos?.length}} {{keyLength(novoFiltro) ? 'filtrados' : 'itens'}}
          </p>
          <p class="indicador-status" *ngIf="status === 'carregando'">
            <ion-spinner color="primary"></ion-spinner>
            BUSCANDO DADOS...
          </p>
        </div>
      </div>
      <div class="right">
        <ng-template ngFor let-coluna [ngForOf]="labels">
          <button
            class="btn-filtro ordenacao-desktop"
            *ngIf="coluna.chave === novaOrdenacao?.coluna"
            (click)="openPopoverFiltros($event, coluna, true)"
          >
            <div>
              Ordenar por
              <p class="titlecase">{{coluna.titulo}}</p>
            </div>
            <ion-icon
              [name]="novaOrdenacao.direcao === 'asc'? 'arrow-up-outline' : 'arrow-down-outline'"
              (click)="mudarDirecaoOrdenacao($event)"
            ></ion-icon>
          </button>
          </ng-template>

          <div class="action-buttons">
            <button 
              [class.item-bloqueado-plano-basico]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('acoes', 'exportacao')"  
              (click)="exportarConteudoPopover($event)" *ngIf="config?.ativarDownload" class="download-action"
            >
              <ion-icon name="cloud-download"></ion-icon>
            </button>
            <button [class.item-bloqueado-plano-basico]="planoBasicoCtrl.checkSeItemEstaBloqueadoEmPlanoBasico('acoes', 'impressao')" (click)="imprimirConteudo($event)" *ngIf="config?.ativarImpressao">
              <ion-icon name="print"></ion-icon>
            </button>
            <ng-template [ngIf]="labelsId">
              <button (click)="abrirConfiguracaoTabela()" [disabled]="atualizandoColunas">
                <ion-icon *ngIf="!atualizandoColunas" name="settings-sharp"></ion-icon>
                <ion-spinner style="width: 16px;height: 16px;" *ngIf="atualizandoColunas"></ion-spinner>
              </button>
            </ng-template>
          </div>
        </div>
      </section>
      <section class="header-wrapper {{config && config.filtrosEspecificos ? 'mostrar-filtros' : ''}}" #s1>
        <section
          class="linha header {{hashsSelecionados.length ? 'semi' : ''}} {{hashsSelecionados.length && hashsSelecionados.length == campos.length ? 'selecionado' : ''}}"
        >
          <div class="colunas-fixas-esquerda">
            <ng-template ngFor let-coluna [ngForOf]="labels" let-i="index">
              <section
                *ngIf="coluna.fixa"
                class="coluna {{coluna?.class ? coluna.class : ''}} coluna-{{coluna.tamanho}} coluna-min-{{coluna.tamanhoMinimo}} {{coluna.fixa ? 'fixa' : ''}}  {{coluna.fixaFinal ? 'fixa-final' : ''}} {{coluna.bold ? 'bold' : ''}} {{coluna.tipo}} col-{{coluna.chave}}"
              >
                <div (click)="clickColuna(true, coluna)" *ngIf="coluna.tipo == 'checkbox'" class="checkbox">
                  <ion-icon name="menos"></ion-icon>
                </div>
                <span class="coluna-title" (click)="clickColuna(true, coluna)" [class.has-small]="coluna.tituloSmall">
                  <small *ngIf="coluna.tituloSmall">{{coluna.tituloSmall}}</small>
                  <div
                    *ngIf="novaOrdenacao && novaOrdenacao.coluna == coluna.chave"
                    class="orderby {{novaOrdenacao.direcao == 'asc' ? 'asc' : 'desc'}}"
                  ></div>
                  {{(coluna.tipo == 'icon-only' || coluna.tituloHidden) && coluna.tituloHidden !== false ? '' : coluna.titulo}}

                  <div
                    class="filtro-rapido"
                    (click)="openPopoverFiltros($event, coluna, false, true)"
                    *ngIf="coluna.tipoFiltro"
                    [class.ativo]="!!novoFiltro[coluna.chave]"
                  >
                    <ion-icon name="funnel"></ion-icon>
                  </div>
                </span>
              </section>
            </ng-template>
          </div>
          <ng-template ngFor let-coluna [ngForOf]="labels" let-i="index">
            <section
              *ngIf="!coluna.fixa"
              class="coluna {{coluna?.classesExtra ? coluna.classesExtra : ''}} {{coluna.chip ? 'chip' : ''}} coluna-{{coluna.tamanho}} coluna-min-{{coluna.tamanhoMinimo}} {{coluna.fixa ? 'fixa' : ''}}  {{coluna.fixaFinal ? 'fixa-final' : ''}} {{coluna.bold ? 'bold' : ''}} {{coluna.tipo}} col-{{coluna.chave}}"
            >
              <div (click)="clickColuna(true, coluna)" *ngIf="coluna.tipo == 'checkbox'" class="checkbox">
                <ion-icon name="menos"></ion-icon>
              </div>
              <span class="coluna-title" (click)="clickColuna(true, coluna)" [class.has-small]="coluna.tituloSmall">
                <small *ngIf="coluna.tituloSmall">{{coluna.tituloSmall}}</small>
                <div
                  *ngIf="novaOrdenacao && novaOrdenacao.coluna == coluna.chave"
                  class="orderby {{novaOrdenacao.direcao == 'asc' ? 'asc' : 'desc'}}"
                ></div>
                {{coluna.tipo == 'icon-only' ? '' : coluna.titulo}}
                <div
                  class="filtro-rapido"
                  (click)="openPopoverFiltros($event, coluna, false, true)"
                  *ngIf="coluna.tipoFiltro"
                  [class.ativo]="!!novoFiltro[coluna.chave]"
                >
                  <ion-icon name="funnel"></ion-icon>
                </div>
              </span>
            </section>
          </ng-template>
        </section>
      </section>
    </section>
    <section
      class="scroll-sticky"
      [class.exibeTotais]="config.exibeTotais && dadosBackupFormatados.length"
      (mousedown)="scrollandoManualmente = true"
      (mouseup)="scrollandoManualmente = false; s1.scrollLeft = s3.scrollLeft; s2.elementRef.nativeElement.scrollLeft = s3.scrollLeft"
      (mouseenter)="scrollandoManualmente = true"
      (mouseleave)="scrollandoManualmente = false; s1.scrollLeft = s3.scrollLeft; s2.elementRef.nativeElement.scrollLeft = s3.scrollLeft"
      (scroll)="scrollandoManualmente ? s1.scrollLeft = s3.scrollLeft && s2.elementRef.nativeElement.scrollLeft = s3.scrollLeft : ''"
      #s3
    >
      <section class="linha header">
        <div class="colunas-fixas-esquerda">
          <ng-template ngFor let-coluna [ngForOf]="labels" let-i="index">
            <section
              *ngIf="coluna.fixa"
              class="coluna coluna-{{coluna.tamanho}} coluna-min-{{coluna.tamanhoMinimo}} {{coluna.fixa ? 'fixa' : ''}}  {{coluna.fixaFinal ? 'fixa-final' : ''}} {{coluna.bold ? 'bold' : ''}} {{coluna.tipo}}"
            >
              <div class="totais-wrapper" *ngIf="config.totais && totais">
                <span *ngFor="let total of config.totais[coluna.chave]">
                  <small>{{total.label}}</small>
                  {{tableFormat[total.format](totais[total.chave])}}
                  <span class="unidade">{{total.unidade}}</span>
                </span>
              </div>
            </section>
          </ng-template>
        </div>

        <ng-template ngFor let-coluna [ngForOf]="labels">
          <section
            *ngIf="!coluna.fixa"
            class="coluna coluna-{{coluna.tamanho}} {{coluna.chip ? 'chip' : ''}} coluna-min-{{coluna.tamanhoMinimo}} {{coluna.fixa ? 'fixa' : ''}}  {{coluna.fixaFinal ? 'fixa-final' : ''}} {{coluna.bold ? 'bold' : ''}} {{coluna.tipo}}"
          >
            <div class="totais-wrapper" *ngIf="config.totais && totais">
              <span *ngFor="let total of config.totais[coluna.chave]">
                <small>{{total.label}}</small>
                {{tableFormat[total.format](totais[total.chave])}}
                <span class="unidade">{{total.unidade}}</span>
              </span>
            </div>
          </section>
        </ng-template>
      </section>
    </section>
    <section class="linhas-wrapper" [class.heightAuto]="disableVirtualScroll || (camposFormatados.length && camposFormatados.length < 20)">
      <div class="tabela-vazia" *ngIf="status !== 'carregando' && !camposFormatados.length">
        Nenhum item encontrado.
      </div>
      <cdk-virtual-scroll-viewport itemSize="46" minBufferPx="900" maxBufferPx="1350" (scroll)="s1.scrollLeft = s2.elementRef.nativeElement.scrollLeft; s3.scrollLeft = s2.elementRef.nativeElement.scrollLeft" #s2>
        <div class="virtual-wrapper" *cdkVirtualFor="let linha of camposFormatados; let indexLinha = index">
      <!-- <ng-template ngFor let-linha [ngForOf]="camposFormatados" let-indexLinha="index"> -->
        <section
          class="linha border-top {{linha.original.hash}}"
          [attr.hash]="linha.original.hash"
          [class.selecionado]="hashsSelecionados.includes(linha.original.hash)"
          (contextmenu)="onRightClick($event, linha)"
        >
          <ng-template [ngIf]="linha.esquerda && linha.esquerda.length">
            <div class="colunas-fixas-esquerda">
              <ng-template ngFor let-coluna [ngForOf]="linha.esquerda" let-indexColunasEsquerda="index">
                <div class="break break-col-{{coluna.chave}} {{coluna.classesExtra}}"></div>
                <section
                  class="coluna {{coluna?.class ? coluna.class : ''}} {{coluna.chip ? 'chip' : ''}} col-{{coluna.chave}} {{!coluna.valor ? 'coluna-vazia' : ''}} {{coluna.classesExtra}} icone-{{coluna.icone}} {{coluna.bold ? 'bold' : ''}} {{coluna.format}}"
                  (click)="clickColuna(false, coluna, linha)"
                  [attr.title]="coluna.valor"
                >
                  <ng-template [ngIf]="linha.original.skeleton">
                    <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                  </ng-template>
                  <ng-template [ngIf]="!linha.original.skeleton">
                    <ion-icon
                      *ngIf="coluna.icone"
                      [name]="coluna.icone"
                      [attr.color]="coluna.color ? coluna.color : coluna.icone"
                    ></ion-icon>
                    {{coluna.valor}}<small *ngIf="coluna.unidade">{{coluna.unidade}}</small><small *ngIf="coluna.small">{{coluna.small}}</small>
                    <span *ngIf="coluna.placeholder" class="placeholder"> {{coluna.placeholder}} </span>
                    <div *ngIf="coluna.tipo === 'checkbox'" class="checkbox">
                      <ion-icon name="checkmark-sharp"></ion-icon>
                    </div>
                    <ion-icon *ngIf="coluna.tipo === 'opcoes'" name="ellipsis-vertical"></ion-icon>
                    <div
                      *ngIf="coluna.tipo == 'toggle'"
                      [ngClass]="{ativo: linha.original.realizado === 1}"
                      class="toggle-elemento"
                    >
                      <!-- {{coluna.valorOriginal ? 'ativo' : ''}} -->
                      <div class="indicador"></div>
                    </div>
                  </ng-template>
                </section>
              </ng-template>
            </div>
          </ng-template>
          <ng-template ngFor let-coluna [ngForOf]="linha.centro" let-indexColunasCentro="index">
            <section
              class="coluna {{coluna?.class ? coluna.class : ''}} col-{{coluna.chave}} {{!coluna.valor ? 'coluna-vazia' : ''}} {{coluna.classesExtra}} icone-{{coluna.icone}} {{coluna.bold ? 'bold' : 'nao-bold'}} {{coluna.format}}"
              [class.chip]="!!coluna.chip"
              (click)="clickColuna(false, coluna, linha, $event)"
              [attr.title]="coluna.valor"
            >
              <ng-template [ngIf]="linha.original.skeleton && coluna.tipo !== 'checkbox'">
                <ion-skeleton-text style="width: 100%"></ion-skeleton-text>
              </ng-template>
              <ng-template [ngIf]="!linha.original.skeleton">
                <span class="label">
                  {{coluna.titulo}}
                  <div (click)="openPopoverFiltros($event, null, false, true, coluna.chave)" class="filter-click"></div>
                </span>
                <!-- [attr.id]="'line_{{indexLinha}}-left_{{indexColunasCentro}}'" -->
                <ion-icon
                  *ngIf="coluna.icone"
                  [name]="coluna.icone"
                  [attr.color]="coluna.color ? coluna.color : coluna.icone"
                ></ion-icon>
                {{coluna.valor}}<small class="unidade-small" *ngIf="coluna.unidade">{{coluna.unidade}}</small><small *ngIf="coluna.small">{{coluna.small}}</small>
                <span *ngIf="coluna.placeholder" class="placeholder"> {{coluna.placeholder}} </span>
                <div
                  *ngIf="coluna.tipo == 'checkbox'"
                  class="checkbox"
                  (click)="clickColuna(false, coluna, linha, $event, false, true)"
                >
                  <ion-icon name="checkmark-sharp"></ion-icon>
                </div>
                <ion-icon *ngIf="coluna.tipo == 'opcoes'" name="ellipsis-vertical"></ion-icon>
                <div *ngIf="coluna.tipo == 'toggle'" class="toggle-elemento {{coluna.valorOriginal ? 'ativo' : ''}}">
                  <div class="indicador"></div>
                </div>
              </ng-template>
            </section>
          </ng-template>
        </section>
        <ng-container *ngTemplateOutlet="innerComponentsTmpl, context: { $implicit: linha }"></ng-container>
      <!-- </ng-template> -->
      </div>
      </cdk-virtual-scroll-viewport>
    </section>
    <section class="error" *ngIf="status === 'erro'">
      <p>Falha ao buscar informações</p>
      <cta-buttons>
        <button class="primary" (click)="emiteRetentativa()">
          <ion-icon name="reload-circle"></ion-icon>
          <span>Tentar Novamente</span>
        </button>
      </cta-buttons>
    </section>
  </section>
</section>
<app-paginate
  [totalItens]="paginate?.total"
  [(currentPage)]="paginate.currentPage"
  [(limit)]="paginate.limit"
  (paginateChange)="setPaginacao($event)"
></app-paginate>
