<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{lancamento.hash ? 'Editar Lançamento' : 'Novo Lançamento'}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-input
    label="Tipo de Lançamento"
    type="select"
    [(value)]="lancamento.movimentacao"
    (valueChange)="resetarTipoDeEntradaoAoAlterarMovimentacaoDeLancamento()"
    [obrigatorio]="true"
    [opcoes]="[
      { label: 'Entrada', value: 'entrada' },
      { label: 'Saída', value: 'saida' },
    ]"
  ></vasta-input>

  <vasta-input
    [label]="lancamento.movimentacao == 'entrada' ? 'Tipo de Entrada' : 'Tipo de Saída'"
    type="select"
    placeholder="Selecionar"
    [obrigatorio]="true"
    [(value)]="lancamento.tipo"
    (valueChange)="alterarTipoDeEntrada()"
    [opcoes]="lancamento.movimentacao == 'entrada' ? [
      { label: 'Coleta', value: 'coleta' },
      { label: 'Compra', value: 'compra' },
      { label: 'Outra', value: 'outra' }
    ] : [
      { label: 'Uso', value: 'uso' },
      { label: 'Venda', value: 'venda' },
      { label: 'Outro', value: 'outro' },
      { label: 'Perda', value: 'perda' },
      { label: 'Desperdício', value: 'desperdicio' }
    ]"
  ></vasta-input>

  <ion-row>
    <ion-col size="6" style="padding-left: 0">
      <vasta-input
        label="Quantidade"
        type="int"
        mask="separator.0"
        [obrigatorio]="true"
        [(value)]="lancamento.qtd"
        (valueChange)="getValorTotal()"
      ></vasta-input>
    </ion-col>

    <ion-col size="6" style="padding-right: 0">
      <vasta-input
        label="Data"
        type="date"
        [obrigatorio]="true"
        [(value)]="lancamento.data_lancamento"
        (valueChange)="tick()"
      ></vasta-input>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="lancamento.tipo == 'venda' || lancamento.tipo == 'compra'">
    <ion-col size="6" style="padding-left: 0">
      <vasta-input
        label="Valor Unitário"
        type="number"
        maskPrefix="R$ "
        mask="separator.4"
        [obrigatorio]="lancamento.tipo == 'venda' || lancamento.tipo == 'compra'"
        [(value)]="lancamento.valor"
        (valueChange)="getValorTotal(); tick()"
      ></vasta-input>
    </ion-col>

    <ion-col size="6" style="padding-right: 0">
      <vasta-input
        label="Valor Total"
        type="number"
        maskPrefix="R$ "
        mask="separator.2"
        [(value)]="valorTotal"
        (valueChange)="calculaValorUnitario()"
      ></vasta-input>
    </ion-col>
  </ion-row>
  <cta-buttons *ngIf="lancamento.tipo == 'venda' || lancamento.tipo == 'compra' && !planoBasicoCtrl.isPlanoBasico">
    <button class="full" (click)="verLancamentoFinanceiro()" style="height: 40px" [disabled]="!isCampoObrigatoriosValidos">
      <ion-icon name="cash"></ion-icon>
      <span> {{lancamento.hash_financeiro ? 'Editar' : 'Criar'}} Lançamento Financeiro</span>
    </button>
    <span class="span__botao-financeiro-desabilitado" *ngIf="!isCampoObrigatoriosValidos">Preencha os campos obrigatórios acima</span>
  </cta-buttons>
  <p>&nbsp;</p>
</ion-content>

<ion-footer class="ion-no-border">
  <cta-buttons>
    <button
      class="primary permissao-escrita-estoque"
      (click)="salvar()"
      [disabled]="salvando || !isCampoObrigatoriosValidos"
    >
      <ion-icon name="checkmark" *ngIf="!salvando"></ion-icon>
      <ion-spinner name="dots" *ngIf="salvando"></ion-spinner>
      <span>Salvar</span>
    </button>
  </cta-buttons>
</ion-footer>
