import { Injectable } from '@angular/core'
import {
  IAgenda,
  IEstacaoReprodutiva,
  InfoPropriedade,
  IProducaoLeiteira,
  IRebanho,
  IResponseFinancas,
  VisaoGeralArrobasProduzidas
} from '../utils/interfaces/dashboard.interface'
import { IPesquisaGeral } from '../utils/interfaces/pesquisa-geral.interface'
import { ApiService } from './api/api.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'

@Injectable({
  providedIn: 'root'
})
export class GeralService {
  constructor(private apiCtrl: ApiService, private permissoesCtrl: PermissoesUsuarioService) {}

  async pesquisaGeral(termo: string): Promise<[IPesquisaGeral, Error?]> {
    try {
      const response = await this.apiCtrl.getComPrefixoPropriedade({
        url: `pesquisa-geral/?termo=${termo}`
      })
      if (response) return [response as IPesquisaGeral]
    } catch (error) {
      return [null, error]
    }
  }

  async getArrobasProduzidas(): Promise<[VisaoGeralArrobasProduzidas, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'visao-geral/arrobas-produzidas'
      })) as VisaoGeralArrobasProduzidas
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getFinancas(): Promise<[IResponseFinancas, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'visao-geral/financas'
      })) as IResponseFinancas
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getRebanho(): Promise<[IRebanho, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'visao-geral/rebanho'
      })) as IRebanho
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getProducaoLeiteira(): Promise<[IProducaoLeiteira, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'visao-geral/producao-leiteira'
      })) as IProducaoLeiteira
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getAgenda(): Promise<[IAgenda, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('calendario', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'visao-geral/agenda'
      })) as IAgenda

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getEstacaoReprodutiva(): Promise<[IEstacaoReprodutiva, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('relatorios', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'visao-geral/estacao-reprodutiva'
      })) as IEstacaoReprodutiva
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getPropriedade(): Promise<[InfoPropriedade, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('propriedade', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'visao-geral/geral'
      })) as InfoPropriedade
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }
}
