<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ dadosAnimalExterno?.sexo === 'macho' ? 'Novo Reprodutor Externo' : 'Nova Matriz Externa' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="modal-form">
    <vasta-input
      [label]="'Sexo'"
      [type]="'select'"
      [(value)]="dadosAnimalExterno.sexo"
      [opcoes]="[
        { label: 'Macho', value: 'macho' },
        { label: 'Fêmea', value: 'femea' }
      ]"
    ></vasta-input>

    <vasta-input
      [label]="dadosAnimalExterno.sexo === 'macho' ? 'Nome do Reprodutor' : 'Nome da Matriz'"
      [type]="'text'"
      [(value)]="dadosAnimalExterno.nome"
      [obrigatorio]="true"
    ></vasta-input>
    <vasta-input
      label="Número do Reprodutor"
      type="text"
      [(value)]="dadosAnimalExterno.meta_numero"
    ></vasta-input>
    <vasta-input
      [label]="'Categoria do Registro'"
      [placeholder]="'Selecionar'"
      [type]="'select'"
      [(value)]="dadosAnimalExterno.meta_categoriaDeRegistro"
      [opcoes]="[
        { label: '1/2', value: '1/2' },
        { label: '1/4', value: '1/4' },
        { label: '3/4', value: '3/4' },
        { label: '07/8', value: '07/8' },
        { label: '5/8', value: '5/8' },
        { label: '15/16', value: '15/16' },
        { label: '31/32', value: '31/32' },
        { label: 'FL', value: 'FL' },
        { label: 'GC', value: 'GC' },
        { label: 'LA', value: 'LA' },
        { label: 'PC', value: 'PC' },
        { label: 'PCI', value: 'PCI' },
        { label: 'PCOD', value: 'PCOD' },
        { label: 'PO', value: 'PO' },
        { label: 'POI', value: 'POI' },
        { label: 'CEIP', value: 'CEIP' },
        { label: 'F', value: 'F' },
        { label: 'F/S', value: 'F/S' },
        { label: 'M', value: 'M' },
        { label: 'M1', value: 'M1' },
        { label: 'M2', value: 'M2' },
        { label: 'TS', value: 'TS' },
        { label: 'PO/CEIP', value: 'PO/CEIP' },
        { label: 'Outro', value: 'outro' }
      ]"
    ></vasta-input>

    <ion-row class="ion-justify-content-between">
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          [label]="'Registro Definitivo'"
          [type]="'text'"
          [(value)]="dadosAnimalExterno.meta_registroDefinitivo"
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          [label]="'Registro de Nascimento'"
          [type]="'text'"
          [(value)]="dadosAnimalExterno.meta_registroDeNascimento"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <ion-row>
      <h2>Composição Racial</h2>
    </ion-row>

    <ion-row>
      <ng-template ngFor let-composicao [ngForOf]="dadosAnimalExterno.meta_composicaoRacial" let-i="index">
        <ion-col [size]="dadosAnimalExterno.meta_composicaoRacial.length > 1 ? 6.2 : 6.8">
          <vasta-input
            [label]="'Raça'"
            [placeholder]="'Selecionar'"
            [type]="'button'"
            [(value)]="listaRacas[composicao.raca]"
            (buttonEvent)="openModalListaRacas(i)"
          ></vasta-input>
        </ion-col>
        <ion-col size="5">
          <vasta-input [label]="'% de Participação'" [type]="'text'" [(value)]="composicao.percentual"></vasta-input>
        </ion-col>
        <ion-col class="btn-container" size="0.6" *ngIf="dadosAnimalExterno.meta_composicaoRacial.length > 1">
          <div (click)="removerRaca(i)">
            <ion-icon name="close" color="primary"></ion-icon>
          </div>
        </ion-col>
      </ng-template>
    </ion-row>

    <cta-buttons>
      <button (click)="adicionarRaca()">
        <ion-icon name="add"></ion-icon>
        <span>Adicionar Raça</span>
      </button>
    </cta-buttons>

    <ion-row>
      <h2>Detalhes</h2>
    </ion-row>

    <vasta-input
      [label]="'Pelagem'"
      [type]="'select'"
      [(value)]="dadosAnimalExterno.meta_pelagem"
      [placeholder]="'Selecionar'"
      [opcoes]="pelagensDisponiveis"
    ></vasta-input>

    <ion-row>
      <h2>Genealogia</h2>
    </ion-row>

    <vasta-input [label]="'Nome do Pai'" [type]="'text'" [(value)]="dadosAnimalExterno.reprodutor"></vasta-input>

    <ion-row class="ion-justify-content-between">
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          [label]="'Avô Paterno'"
          [(value)]="dadosAnimalExterno.meta_avoMachoPaterno"
          [type]="'text'"
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          [label]="'Avó Paterna'"
          [(value)]="dadosAnimalExterno.meta_avoFemeaPaterna"
          [type]="'text'"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <vasta-input [label]="'Nome da Mãe'" [type]="'text'" [(value)]="dadosAnimalExterno.matriz"></vasta-input>

    <ion-row class="ion-justify-content-between">
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          [label]="'Avô Materno'"
          [(value)]="dadosAnimalExterno.meta_avoMachoMaterno"
          [type]="'text'"
        ></vasta-input>
      </ion-col>
      <ion-col size="6" style="padding-left: 0">
        <vasta-input
          [label]="'Avó Materna'"
          [(value)]="dadosAnimalExterno.meta_avoFemeaMaterna"
          [type]="'text'"
        ></vasta-input>
      </ion-col>
    </ion-row>

    <vasta-input [label]="'Observação'" [type]="'textarea'" [(value)]="dadosAnimalExterno.descricao"></vasta-input>

    <div class="msg" *ngIf="dadosAnimalExternoProp">
      <p>
        Cadastrado em <strong>{{ dadosAnimalExterno.created_at | timestamp: 'data' }}</strong>.
        <strong>atualizado em {{ dadosAnimalExterno.updated_at | timestamp: 'data' }}</strong>
      </p>
    </div>
  </div>
</ion-content>
<ion-footer class="ion-no-border">
  <cta-buttons>
    <button class="primary permissao-escrita-animais" (click)="submit()" *ngIf="!isLoading">
      <ion-icon slot="start" name="checkmark"></ion-icon>
      <span>{{ dadosAnimalExternoProp ? 'Atualizar' : 'Salvar' }}</span>
    </button>
    <button class="primary" *ngIf="isLoading" disabled>
      <ion-spinner name="dots"></ion-spinner>
      <span>Salvando</span>
    </button>
  </cta-buttons>
</ion-footer>
