import { Component } from '@angular/core'
import { PopoverController } from '@ionic/angular'

@Component({
  selector: 'app-popover-confirmacao',
  templateUrl: './popover-confirmacao.component.html',
  styleUrls: ['./popover-confirmacao.component.scss']
})
export class PopoverConfirmacaoComponent {

  constructor(private popoverCtrl: PopoverController) {}

  confirmar(): void {
    this.popoverCtrl.dismiss({
      confirmar: true
    })
  }

  cancelar(): void {
    this.popoverCtrl.dismiss({
      cancelar: true
    })
  }
}
