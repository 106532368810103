<div class="cards-wrapper">
  <ng-template ngFor let-card [ngForOf]="cards">
    <div class="custom-card" *ngIf="card?.dados?.length">
      <ng-template [ngIf]="card.grafico_labels?.length">
        <ion-segment mode="ios" [(ngModel)]="graficos_type" *ngIf="card.grafico_type !== 'percentual'">
          <ion-segment-button value="donut">
            <ion-icon name="pie-chart"></ion-icon>
          </ion-segment-button>
          <ion-segment-button value="bar">
            <ion-icon name="stats-chart"></ion-icon>
          </ion-segment-button>
        </ion-segment>

        <app-grafico *ngIf="card.grafico_labels?.length && card.grafico_type == 'percentual'" [labels]="card.grafico_labels" [dataset]="[card.grafico_dataset_bar]" [options]="percentualOptions"></app-grafico>
        <app-grafico *ngIf="card.grafico_labels?.length && card.grafico_type !== 'percentual' && graficos_type == 'bar'"   [labels]="card.grafico_labels" [dataset]="[card.grafico_dataset_bar]" [options]="barOptions"></app-grafico>
        <app-grafico *ngIf="card.grafico_labels?.length && card.grafico_type !== 'percentual' && graficos_type == 'donut'" [labels]="card.grafico_labels" [dataset]="[card.grafico_dataset_donut]" [options]="donutOptions"></app-grafico>
      </ng-template>

      <h2>{{ card.titulo }}</h2>

      <div class="tabela-simples">
        <div class="row head">
          <div
            class="col"
            *ngFor="let coluna of card.colunas"
            [ngClass]="{
              b: coluna.bold,
              right: coluna.right,
              vermelho: coluna.class === 'vermelho',
              verde: coluna.class === 'verde',
              dinheiro: coluna.format == 'dinheiro',
              peso: coluna.format == 'peso', 
              dias: coluna.format == 'dias' 
            }"
          >
            {{ coluna.titulo }}
            <ion-icon [name]="coluna.icon" *ngIf="coluna.icon"></ion-icon>
          </div>
        </div>

        <div class="row" *ngFor="let linha of card.dados">
          <ng-template let-coluna ngFor [ngForOf]="card.colunas">
            <div class="col" [ngClass]="{ mono: !coluna.bold, b: coluna.bold, right: coluna.right, dinheiro: coluna.format == 'dinheiro', peso: coluna.format == 'peso', dias: coluna.format == 'dias' }">
              <span *ngIf="linha[coluna.chave] && coluna.format !== 'dinheiro' && coluna.format !== 'peso' && coluna.format !== 'dias'">{{ linha[coluna.chave] }}</span>
              <span *ngIf="linha[coluna.chave] && coluna.format == 'dinheiro'">{{ linha[coluna.chave] | currency: 'BRL' }}</span>
              <span *ngIf="linha[coluna.chave] && coluna.format == 'peso'">{{ linha[coluna.chave] | number: '1.0-2' }} kg</span>
              <span *ngIf="linha[coluna.chave] && coluna.format == 'dias'">{{ linha[coluna.chave] | number: '1.0-2' }} dias</span>
              <span *ngIf="!linha[coluna.chave] && !coluna.icon">-</span>
              <span *ngIf="!linha[coluna.chave] && coluna.icon">-</span>
            </div>
          </ng-template>
        </div>

        <ng-container *ngIf="!card.dados.length && loading === 'carregando'">
          <div class="row">
            <ion-skeleton-text animated style="width: 100%; height: 25px; border-radius: 6px"></ion-skeleton-text>
          </div>
          <div class="row">
            <ion-skeleton-text animated style="width: 100%; height: 25px; border-radius: 6px"></ion-skeleton-text>
          </div>
          <div class="row">
            <ion-skeleton-text animated style="width: 100%; height: 25px; border-radius: 6px"></ion-skeleton-text>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
