import { Injectable } from '@angular/core'
import {
  IArea,
  IAreaPiquete,
  ICadastroArea,
  IDadosAreas,
  IDadosPluviometricos,
  IGetDadosHistoricoArea,
  IGetDadosPluviometricos,
  ITotaisAreas
} from '../utils/interfaces/areas.interface'
import { NovoQueryParamsModel, QueryParamsmModel } from '../utils/interfaces/query-params.interface'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'
import { ApiService } from './api/api.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'
import { PropriedadesService } from './propriedades.service'

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  public importacaoKMLDados: IArea[] = []

  constructor(
    private apiCtrl: ApiService,
    private propriedadesService: PropriedadesService,
    private permissoesCtrl: PermissoesUsuarioService
  ) {}

  async getAreas(filtros?: NovoQueryParamsModel): Promise<[IDadosAreas, Error?]> {
    const id_propriedade = this.propriedadesService?.propriedadeSelecionada?.id
    if (!id_propriedade) return [null, null]
    let url = 'areas'

    try {
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({ url, filtros })) as IDadosAreas
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getArea(hash: string): Promise<[{ dados: IArea[]; totais: ITotaisAreas }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'leitura')
      const filtros: NovoQueryParamsModel = { filtros: { hash } }
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'areas',
        filtros
      })) as { dados: IArea[]; totais: ITotaisAreas }

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async cadastrarArea(params: ICadastroArea): Promise<[StatusRequest, Error?]> {
    try {
      let url = 'areas'
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({ url, body: params })) as StatusRequest
      if (response) {
        this.apiCtrl.setPassoFunil('360_cadastrou_area')
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async editarArea(hashArea: string, params: ICadastroArea): Promise<[StatusRequest, Error?]> {
    try {
      let url = `areas/${encodeURIComponent(hashArea)}`
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'escrita')
      const response = (await this.apiCtrl.putComPrefixoPropriedade({
        url,
        body: {
          nome: params?.nome,
          tamanho: params?.tamanho,
          max_lotacao: params?.max_lotacao,
          observacao: params?.observacao,
          coordenadas_centrais: params?.coordenadas_centrais,
          area: params?.area,
          meta_tipo_capim: params?.meta_tipo_capim,
          meta_tipo_area: params?.meta_tipo_area,
          meta_altura_entrada_capim: params?.meta_altura_entrada_capim,
          meta_altura_saida_capim: params?.meta_altura_saida_capim,
          id_propriedade: params?.id_propriedade
        }
      })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async deletarArea(hashArea: string): Promise<[StatusRequest, Error?]> {
    try {
      let url = `areas/${encodeURIComponent(hashArea)}`
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({ url })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getDadosPluviometricos(filtroMesAno?: string): Promise<[IGetDadosPluviometricos, Error?]> {
    let url = 'areas/dados-pluviometricos'
    let queryParams: QueryParamsmModel = {}
    if (filtroMesAno) {
      queryParams = {
        mes_ano: filtroMesAno
      }
    }

    try {
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url,
        queryParams
      })) as IGetDadosPluviometricos
      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async lancamentoPluviometrico(params: IDadosPluviometricos): Promise<[StatusRequest, Error?]> {
    try {
      let url = 'areas/dados-pluviometricos'
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({ url, body: params })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getHistorico(params?: NovoQueryParamsModel): Promise<[IGetDadosHistoricoArea, Error?]> {
    if (this.propriedadesService.propriedadeSelecionada) {
      let url = `areas/${encodeURIComponent(params.filtros.hashArea)}/historico`

      try {
        this.permissoesCtrl.verificaPermissaoDeRota('areas', 'leitura')
        const response = await this.apiCtrl.getComPrefixoPropriedade({ url, filtros: params })

        if (response) return [response, null]
      } catch (error) {
        return [null, error]
      }
    }
  }

  async getPiquetes(params?: NovoQueryParamsModel): Promise<[{ piquetes: IAreaPiquete[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'areas/piquetes',
        filtros: params
      })) as { piquetes: IAreaPiquete[] }

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async setPiquete(params: IAreaPiquete[]): Promise<[StatusRequest, Error?]> {
    try {
      let url = 'areas/piquetes'
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({ url, body: params })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async editarPiquete(hashPiquete: string, params: IAreaPiquete[]): Promise<[StatusRequest, Error?]> {
    try {
      let url = `areas/piquetes/${encodeURIComponent(hashPiquete)}`
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'escrita')
      const response = (await this.apiCtrl.putComPrefixoPropriedade({ url, body: params })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async deletarPiquete(hashPiquete: string): Promise<[StatusRequest, Error?]> {
    try {
      let url = `areas/piquetes/${encodeURIComponent(hashPiquete)}`
      this.permissoesCtrl.verificaPermissaoDeRota('areas', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({ url })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async enviarKml(payload: { arquivo: string, conteudo: string }): Promise<[{ url: string }, Error?]> {
    try {
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'midias/kml',
        body: payload
      })) as { url: string }

      if (response) return [response]
    } catch (error) {
      return [error]
    }
  }
}
