// export interface ILotes {
//   aptidao: string
//   nome_area: string
//   created_at: string
//   data_entrada_area: string
//   especie: string
//   finalidade: string
//   gmd: string
//   hash: string
//   hash_area: string
//   id: number
//   id_propriedade: number
//   nome: string
//   peso_total: number
//   peso_total_arroba: number
//   producao_leiteira: null
//   qtd_animais: string
//   sistema_criacao: string
//   status: number
//   synced_at: string
//   updated_at: string
//   animais_sem_lotes?: string
// }

// export interface ITotaisLotes {
//   total_peso: number
//   media_peso: number
//   total_animais: number
//   animais_sem_lote: number
// }

// export interface IDadosLotes {
//   lotes: ILotes[]
//   totais: ITotaisLotes
// }

export interface IResponseGetLotes {
  dados: ILote[]
  totais: ITotaisLote
}

export interface ILote {
  id?: number
  hash?: string
  id_propriedade?: number
  especie?: string
  data_entrada_area?: string
  producao_leiteira?: string
  gmd?: number
  hash_area?: string
  nome?: string
  sistema_criacao?: string
  aptidao?: string
  finalidade?: string
  status?: number
  created_at?: string
  updated_at?: string
  synced_at?: string
  qtd_animais?: number | string
  total_peso?: number
  nome_area?: string
  peso_arroba?: number

  confinamento?: boolean

  meta_situacao?: string
}

export interface ITotaisLote {
  total_peso: number
  media_peso: number
  total_animais: number
  animais_sem_lote: number
}

export interface IHistoricoLote {
  acao: string
  data: string
  primeiro?: string
  segundo?: string
  mensagem: string
  item?: string
  icone: string
}
