import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Racas } from 'src/app/utils/racas'

@Component({
  selector: 'app-modal-lista-racas',
  templateUrl: './modal-lista-racas.page.html',
  styleUrls: ['./modal-lista-racas.page.scss']
})
export class ModalListaRacasPage implements OnInit {
  public racas = JSON.parse(JSON.stringify(Racas))
  public termo = ''
  public listaDeRacas = []
  public listaDeRacasAtalho = [
    'nelore',
    'aberdeen-angus',
    'mestico'
  ]

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.filtrarListaDeRacas()
  }

  selecionarRaca(raca: string): void {
    this.modalCtrl.dismiss({ raca })
  }

  closeModalListaRacas(): void {
    this.modalCtrl.dismiss()
  }

  filtrarListaDeRacas(event?: CustomEvent): void {
    this.listaDeRacas = Object.keys(this.racas)
    const termo = event?.detail?.value || ''
    const listaFiltrada = []
    if (termo && termo.trim() != '') {
      this.listaDeRacas = this.listaDeRacas.map((raca) => {
        if (raca.toLowerCase().includes(termo.toLowerCase())) listaFiltrada.push(raca)
      })
    }
    if (listaFiltrada.length > 0) this.listaDeRacas = listaFiltrada
  }
}
