import { Injectable } from '@angular/core'
import {
  EstoqueBiologico,
  EstoqueFarmacia,
  EstoqueFerramentas,
  EstoqueNutricional,
  IEstoqueBiologicoPayload,
  IEstoqueFarmaciaPayload,
  IEstoqueFerramentasPayload,
  IEstoqueLancamento,
  IEstoqueLancamentoFormulacao,
  IEstoqueNutricionalPayload
} from '../utils/interfaces/estoque.interface'
import { NovoQueryParamsModel } from '../utils/interfaces/query-params.interface'
import { ApiService } from './api/api.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'

@Injectable({
  providedIn: 'root'
})
export class EstoqueService {
  constructor(private apiCtrl: ApiService, private permissoesCtrl: PermissoesUsuarioService) {}

  async deleteEstoqueBiologicoLancamento(opcoes?: {
    hash: string
  }): Promise<[{ estoques: EstoqueBiologico[]; totais: { semens: number; embrioes: number; total: number } }, Error?]> {
    const { hash } = opcoes || {}
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: 'estoque/biologico/lancamento',
        queryParams: { hash }
      })) as { estoques: EstoqueBiologico[]; totais: { semens: number; embrioes: number; total: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async deleteEstoqueNutricionalLancamento(opcoes?: { hash: string }): Promise<[{ success: boolean }, Error?]> {
    const { hash } = opcoes || {}
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: 'estoque/nutricional/lancamento',
        queryParams: { hash }
      })) as { success: boolean }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async deleteEstoque(opcoes?: {
    hash: string
  }): Promise<[{ estoques: EstoqueBiologico[]; totais: { semens: number; embrioes: number; total: number } }, Error?]> {
    const { hash } = opcoes || {}
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: 'estoque/biologico',
        queryParams: { hash }
      })) as { estoques: EstoqueBiologico[]; totais: { semens: number; embrioes: number; total: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getEstoqueBiologico(
    filtros?: NovoQueryParamsModel
  ): Promise<[{ estoques: EstoqueBiologico[]; totais: { semens: number; embrioes: number; total: number; total_estoque?: number } }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'estoque/biologico',
        filtros
      })) as { estoques: EstoqueBiologico[]; totais: { semens: number; embrioes: number; total: number; total_estoque?: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getEstoqueFarmacia(
    filtros?: NovoQueryParamsModel
  ): Promise<[{ estoques: EstoqueFarmacia[]; totais: { semens: number; embrioes: number; qtd: number; total_estoque?: number } }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'estoque/farmacia',
        filtros
      })) as { estoques: EstoqueFarmacia[]; totais: { semens: number; embrioes: number; qtd: number; total_estoque?: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getEstoqueFarmaciaSingle(filtros?: NovoQueryParamsModel): Promise<[{ lancamentos: EstoqueFarmacia[], totais: { total: number } }, Error?]> {
    const { hash } = filtros
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'estoque/farmacia/lancamento',
        queryParams: { hash_estoque: hash },
        filtros
      })) as { lancamentos: EstoqueFarmacia[], totais: { total: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getEstoqueBiologicoSingle(filtros?: NovoQueryParamsModel): Promise<[{ lancamentos: EstoqueBiologico[], totais: { total: number }}, Error?]> {
    const { hash } = filtros
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'estoque/biologico/lancamento',
        queryParams: { hash_estoque: hash },
        filtros
      })) as { lancamentos: EstoqueBiologico[], totais: { total: number }}

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setEstoqueBiologico(opcoes: { item: IEstoqueBiologicoPayload }): Promise<[{hashs: string[]}, Error?]> {
    const { item } = opcoes
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      if (!item?.hash) {
        const response = (await this.apiCtrl.postComPrefixoPropriedade({
          url: 'estoque/biologico',
          body: [item]
        })) as {hashs: string[]}

        if (response) {
          return [response]
        }
      } else {
        const response = (await this.apiCtrl.putComPrefixoPropriedade({
          url: 'estoque/biologico',
          body: { hash_estoque: item.hash, metas: item.metadados }
        })) as {hashs: string[]}
        if (response) {
          return [response]
        }
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getEstoqueNutricional(
    filtros?: NovoQueryParamsModel
  ): Promise<[{ estoques: EstoqueNutricional[]; totais: { semens: number; embrioes: number; total: number; total_estoque?: number } }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'estoque/nutricional',
        filtros
      })) as { estoques: EstoqueBiologico[]; totais: { semens: number; embrioes: number; total: number; total_estoque?: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getEstoqueNutricionalSingle(filtros?: NovoQueryParamsModel): Promise<[{ lancamentos: EstoqueBiologico[], totais: { total: number } }, Error?]> {
    const { hash } = filtros
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'estoque/nutricional/lancamento',
        queryParams: { hash_estoque: hash },
        filtros
      })) as { lancamentos: EstoqueBiologico[], totais: { total: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setEstoqueNutricional(opcoes: { item: IEstoqueNutricionalPayload }): Promise<[{ hashs: string[] }, Error?]> {
    const { item } = opcoes
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      if (!item?.hash) {
        const response = (await this.apiCtrl.postComPrefixoPropriedade({
          url: 'estoque/nutricional',
          body: [item]
        })) as { hashs: string[] }

        if (response) {
          return [response]
        }
      } else {
        const response = (await this.apiCtrl.putComPrefixoPropriedade({
          url: 'estoque/nutricional',
          body: { hash_estoque: item.hash, titulo: item?.titulo, metas: item.metadados, formulacao: item.formulacao }
        })) as { hashs: string[] }
        if (response) {
          return [response]
        }
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setEstoqueFarmacia(opcoes: { item: IEstoqueFarmaciaPayload }): Promise<[{ hashs: string[] }, Error?]> {
    const { item } = opcoes
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      if (!item?.hash) {
        const response = (await this.apiCtrl.postComPrefixoPropriedade({
          url: 'estoque/farmacia',
          body: [item]
        })) as { hashs: string[] }

        if (response) {
          return [response]
        }
      } else {
        const response = (await this.apiCtrl.putComPrefixoPropriedade({
          url: 'estoque/farmacia',
          body: { hash_estoque: item.hash, titulo: item?.titulo, metas: item.metadados }
        })) as { hashs: string[] }
        if (response) {
          return [response]
        }
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setLancamentoEstoqueFarmacia(opcoes: {
    lancamento: IEstoqueLancamento
  }): Promise<[EstoqueBiologico[], Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'estoque/farmacia/lancamento',
        body: opcoes
      })) as EstoqueBiologico[]

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setLancamentoEstoqueNutricional(opcoes: {
    lancamento: IEstoqueLancamento
  }): Promise<[EstoqueBiologico[], Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'estoque/nutricional/lancamento',
        body: opcoes
      })) as EstoqueBiologico[]

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setLancamentoEstoqueFormulacao(opcoes: {
    lancamento: IEstoqueLancamentoFormulacao
  }): Promise<[EstoqueNutricional[], Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'estoque/nutricional/formulacao/lancamento',
        body: opcoes
      })) as EstoqueNutricional[]

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setLancamentoEstoqueBiologico(opcoes: {
    lancamento: IEstoqueLancamento
  }): Promise<[EstoqueBiologico[], Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'estoque/biologico/lancamento',
        body: opcoes
      })) as EstoqueBiologico[]

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async deleteEstoqueFarmaciaLancamento(opcoes?: {
    hash: string
  }): Promise<[{ success: boolean }, Error?]> {
    const { hash } = opcoes || {}
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: 'estoque/farmacia/lancamento',
        queryParams: { hash }
      })) as { success: boolean }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setEstoqueFerramentas(opcoes: { item: IEstoqueFerramentasPayload }): Promise<[{ hashs: string[] }, Error?]> {
    console.log('setEstoqueFerramentas', opcoes)

    const { item } = opcoes
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      if (!item?.hash) {
        const response = (await this.apiCtrl.postComPrefixoPropriedade({
          url: 'estoque/ferramentas',
          body: [item]
        })) as { hashs: string[] }

        if (response) {
          return [response]
        }
      } else {
        const response = (await this.apiCtrl.putComPrefixoPropriedade({
          url: 'estoque/ferramentas',
          body: { hash_estoque: item.hash, titulo: item?.titulo, metas: item.metadados }
        })) as { hashs: string[] }
        if (response) {
          return [response]
        }
      }
    } catch (error) {
      return [null, error]
    }
  }

  async deleteEstoqueFerramentasLancamento(opcoes?: { hash: string }): Promise<[{ success: boolean }, Error?]> {
    const { hash } = opcoes || {}
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: 'estoque/ferramentas/lancamento',
        queryParams: { hash }
      })) as { success: boolean }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getEstoqueFerramentas(
    filtros?: NovoQueryParamsModel
  ): Promise<
    [{ estoques: EstoqueFerramentas[]; totais: { itens: number; qtd: number; total_estoque: number, valor_total: number } }, Error?]
  > {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'estoque/ferramentas',
        filtros
      })) as { estoques: EstoqueFerramentas[]; totais: { itens: number; qtd: number; total_estoque: number, valor_total: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getEstoqueFerramentaSingle(filtros?: NovoQueryParamsModel): Promise<[{ lancamentos: EstoqueFerramentas[], totais: { total: number } }, Error?]> {
    const { hash } = filtros
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('estoque', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'estoque/ferramentas/lancamento',
        queryParams: { hash_estoque: hash },
        filtros
      })) as { lancamentos: EstoqueFerramentas[], totais: { total: number } }

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }
}
