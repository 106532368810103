import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { DadoGenealogia, IArvoreGenealogica } from 'src/app/utils/interfaces/genealogia.interface'

@Component({
  selector: 'genealogia',
  templateUrl: './genealogia.component.html',
  styleUrls: ['./genealogia.component.scss']
})
export class GenealogiaComponent implements OnInit {
  @Input() nomeAnimal: string
  @Input() genealogia: IArvoreGenealogica
  @Input() permitirNavegacao: boolean

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navegarPorBovinos(dadosAnimal: DadoGenealogia): void {
    if (!dadosAnimal || !this.permitirNavegacao) return

    if (dadosAnimal.hash && !dadosAnimal.externo) {
      this.router.navigateByUrl(`/animais/${encodeURIComponent(dadosAnimal.hash)}`)
    }
  }
}
