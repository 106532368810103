import { ApplicationRef, Component } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import * as moment from 'moment'

interface CalendarioOptions {
  title?: string
  label?: string
  value?: number
  dataInicio?: {
    dia: number
    mes: number
    ano: number
  }
  dataInicioManual?: string
  subTitle?: string
  cssClass?: string
  enableBackdropDismiss?: boolean
  semAno?: boolean

  dataDe?: string
  dataAte?: string

  diaMinimo?: number
  mesMinimo?: number
  anoMinimo?: number
  diaMaximo?: number
  mesMaximo?: number
  anoMaximo?: number

  range?: boolean
}
let Meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]
let MesesAbreviados = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
let Dias = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado']
let DiasAbreviados = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab']

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent {
  titulo: string = ''
  subtitulo: string = ''

  public mesesNomes = Meses
  public diasNomes = Dias

  public calendario = {
    dataAtual: '',
    mesAnoAtual: '',
    ano: 0,
    diaDaSemana: 0,
    dia: 0,
    mes: 0,
    mesQtd: 0,
    mesArray: [],
    selecao: {
      data: '',
      iso: '',
      formatado: '',
      dia: 0,
      mes: 0,
      ano: 0,
      diaDaSemana: 0,
      nomeMes: '',
      nomeDia: ''
    },
    selecaoRange: {
      de: {
        data: '',
        iso: '',
        formatado: '',
        diasEpoch: null,
        dia: 0,
        mes: 0,
        ano: 0,
        diaDaSemana: 0,
        nomeMes: '',
        nomeDia: ''
      },
      ate: {
        data: '',
        iso: '',
        formatado: '',
        diasEpoch: null,
        dia: 0,
        mes: 0,
        ano: 0,
        diaDaSemana: 0,
        nomeMes: '',
        nomeDia: ''
      }
    }
  }

  d: CalendarioOptions = {}

  dataInicio: string = ''

  blockInput: boolean = false
  blockDoubleInput: boolean = false

  dataMinima: moment.Moment = null
  dataMaxima: moment.Moment = null

  diaMinimo: number = 0
  diaMaximo: number = 0

  bloqueiaAnoAnterior: boolean = false
  bloqueiaProximoAno: boolean = false

  bloqueiaMesAnterior: boolean = false
  bloqueiaProximoMes: boolean = false

  semAno = false

  range = false
  anosDisponiveis = []

  listaAnosPopover: number[] = []
  listaSafrasPopover: string[] = []
  isSubPopoverVisible = false

  constructor(private popoverCtrl: PopoverController, private ref: ApplicationRef) {}

  dismiss(): void {
    this.popoverCtrl.dismiss()
  }

  ngOnInit(): void {
    if (this.d.semAno) this.semAno = true

    if (this.d.dataInicioManual) this.d.dataInicio = this.validaData(this.d.dataInicioManual)

    if (this.d.range) this.range = this.d.range

    const dataInicio = this.d.dataInicio
    if (dataInicio && dataInicio.dia && (dataInicio.mes || dataInicio.mes == 0) && dataInicio.ano) {
      this.calendario.dia = dataInicio.dia
      this.calendario.mes = dataInicio.mes
      this.calendario.ano = dataInicio.ano
      this.calendario.dataAtual = moment().format('DD-MM-YYYY')
      this.calendario.mesAnoAtual = dataInicio.mes + 1 + '-' + dataInicio.ano
      this.calendario.diaDaSemana = new Date(dataInicio.ano, dataInicio.mes, dataInicio.dia).getDay()
    } else {
      this.calendario.diaDaSemana = new Date().getDay()
      this.calendario.dia = new Date().getDate()
      this.calendario.mes = new Date().getMonth()
      this.calendario.ano = new Date().getFullYear()
      this.calendario.dataAtual = moment().format('DD-MM-YYYY')
      this.calendario.mesAnoAtual = new Date().getMonth() + 1 + '-' + new Date().getFullYear()
    }

    this.renderizaCalendario()

    try {
      if (this.d.anoMinimo) {
        if (this.d.mesMinimo) {
          if (this.d.diaMinimo) {
            this.dataMinima = moment().date(this.d.diaMinimo).month(this.d.mesMinimo).year(this.d.anoMinimo)
            this.diaMinimo = Number(this.d.diaMinimo)
          } else {
            this.dataMinima = moment().date(1).month(this.d.mesMinimo).year(this.d.anoMinimo)
          }
        } else {
          this.dataMinima = moment().date(1).month(0).year(this.d.anoMinimo)
        }
      }

      if (this.d.anoMaximo) {
        if (this.d.mesMaximo) {
          if (this.d.diaMaximo) {
            this.dataMaxima = moment().date(this.d.diaMaximo).month(this.d.mesMaximo).year(this.d.anoMaximo)
            this.diaMaximo = Number(this.d.diaMaximo)
          } else {
            this.dataMaxima = moment().date(1).month(this.d.mesMaximo).year(this.d.anoMaximo)
          }
        } else {
          this.dataMaxima = moment().date(1).month(11).year(this.d.anoMaximo)
        }
      }

      this.renderizaCalendario()
    } catch (err) {
      console.log('Erro ao processar datas máximas', err)
    }

    if (
      !this.dataMinima &&
      (!this.dataMaxima ||
        this.dataMaxima
          .add(1, 'day')
          .isAfter(moment().date(this.calendario.dia).month(this.calendario.mes).year(this.calendario.ano)))
    ) {
      this.selecionaDia({
        data: new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
        dia: Number(this.calendario.dia),
        mes: Number(this.calendario.mes),
        ano: Number(this.calendario.ano),
        diaDaSemana: this.calendario.diaDaSemana
      })
    }

    if (this.range && this.d.dataDe && this.d.dataAte) {
      let dataDe = moment(this.d.dataDe)
      let dataAte = moment(this.d.dataAte)

      this.selecionaDia({
        data: dataDe.format('YYYY-MM-DD'),
        dia: Number(dataDe.format('DD')),
        mes: Number(dataDe.format('MM')),
        ano: Number(dataDe.format('YYYY'))
        // diaDaSemana: 1
      })
      this.selecionaDia({
        data: dataAte.format('YYYY-MM-DD'),
        dia: Number(dataAte.format('DD')),
        mes: Number(dataAte.format('MM')),
        ano: Number(dataAte.format('YYYY'))
      })

      this.calendario.mes = this.calendario.selecaoRange.de.mes
      this.calendario.ano = this.calendario.selecaoRange.de.ano
      this.renderizaCalendario()
    }

    let a = Number(moment(this.dataMinima || '1990-01-01').format('YYYY'))
    const anoMax = Number(moment(this.dataMaxima || '2040-12-31').format('YYYY'))
    while (a <= anoMax) {
      this.anosDisponiveis.push(a)
      a++
      if (this.anosDisponiveis.length > 200) break
    }
    this.anosDisponiveis.reverse()
  }

  ngOnDestroy(): void {}

  async confirmar(): Promise<void> {
    if (this.range) {
      this.popoverCtrl.dismiss(this.calendario.selecaoRange)
    } else {
      this.popoverCtrl.dismiss(this.calendario.selecao)
    }
  }
  processaMaximos(): void {
    try {
      if (this.d.anoMinimo) {
        this.bloqueiaAnoAnterior = this.calendario.ano <= this.d.anoMinimo
        if (this.d.mesMinimo) {
          this.bloqueiaMesAnterior = this.bloqueiaAnoAnterior && this.calendario.mes <= this.d.mesMinimo
        }
      }

      if (this.d.anoMaximo) {
        this.bloqueiaProximoAno = this.calendario.ano >= this.d.anoMaximo
        if (this.d.mesMaximo) {
          this.bloqueiaProximoMes = this.bloqueiaProximoAno && this.calendario.mes >= this.d.mesMaximo
        }
      }
    } catch (err) {
      console.log('Erro ao processar datas máximas', err)
    }
  }

  renderizaCalendario(): void {
    this.calendario.mes = Number(this.calendario.mes)
    this.processaMaximos()

    this.calendario.mesQtd = new Date(this.calendario.ano, this.calendario.mes + 1, 0).getDate()
    this.calendario.mesArray = []
    let primeiroDia = new Date(this.calendario.ano, this.calendario.mes, 1).getDay()
    let ultimoDiaMesPassado = new Date(this.calendario.ano, this.calendario.mes, 0).getDate()

    let diaDaSemana = 0
    let nDaSemana = 0

    for (let dia = 1; dia <= primeiroDia; dia++) {
      this.calendario.mesArray.push({
        dia: ultimoDiaMesPassado--,
        outroMes: 1,
        diaDaSemana: diaDaSemana++
      })
      if (diaDaSemana == 7) diaDaSemana = 0
    }
    this.calendario.mesArray.reverse()

    for (let dia = 1; dia <= this.calendario.mesQtd; dia++) {
      let desabilitado = false
      desabilitado = this.bloqueiaMesAnterior && dia < this.diaMinimo ? true : desabilitado
      desabilitado = this.bloqueiaProximoMes && dia > this.diaMaximo ? true : desabilitado

      let dataHifenizada = this.geraDataHifenizada(dia, this.calendario.mes, this.calendario.ano)
      let dataISO = this.geraDataISO(dia, this.calendario.mes, this.calendario.ano)

      this.calendario.mesArray.push({
        dia: dia,
        hoje: this.calendario.dataAtual == dataHifenizada,
        data: dataHifenizada,
        diasEpoch: moment(dataISO).diff(moment('1970-01-01'), 'days'),
        iso: this.geraDataISO(dia, this.calendario.mes, this.calendario.ano),
        formatado: this.geraDataFormatada(dia, this.calendario.mes, this.calendario.ano),
        diaDaSemana: diaDaSemana++,
        semana: nDaSemana,
        desabilitado: desabilitado
      })
      if (diaDaSemana == 7) {
        nDaSemana++
        diaDaSemana = 0
      }
    }

    let diasASomar = 42 - this.calendario.mesArray.length
    for (let dia = 1; dia <= diasASomar; dia++) {
      this.calendario.mesArray.push({
        dia: dia,
        outroMes: 2,
        diaDaSemana: diaDaSemana++
      })
      if (diaDaSemana == 7) diaDaSemana = 0
    }

    // this.ref.tick()
  }

  selecionaDia(dia: {
    data?: string
    dia?: number
    mes?: number
    ano?: number
    diaDaSemana?: number
    desabilitado?: boolean
    outroMes?: number
    iso?: string
  }): void {
    if (this.range) {
      let range = this.calendario.selecaoRange
      let inicial = {
        data: '',
        iso: '',
        formatado: '',
        diasEpoch: '',
        dia: 0,
        mes: 0,
        ano: 0,
        diaDaSemana: 0,
        nomeMes: '',
        nomeDia: ''
      }

      let mes = Number(dia.mes || moment(dia.iso).format('MM')) - 1
      let ano = dia.ano || Number(moment(dia.iso).format('YYYY'))

      if (!range.de.data || (range.de.data && range.ate.data)) {
        this.calendario.selecaoRange.de = this.processar(dia.dia, mes, ano, dia.diaDaSemana)
      }
      if (range.de.data && range.ate.data) {
        this.calendario.selecaoRange.ate = inicial
      } else {
        if (range.de.data && !range.ate.data) {
          this.calendario.selecaoRange.ate = this.processar(dia.dia, mes, ano, dia.diaDaSemana)
        }
      }
    } else {
      if (dia.desabilitado) return

      if (dia.data === this.calendario.selecao.data) {
        this.confirmar()
      } else {
        if (dia.outroMes) {
          if (dia.outroMes == 1) {
            this.modificaMes(-1)
          } else {
            this.modificaMes(1)
          }
        }
        this.calendario.selecao = this.processar(dia.dia, this.calendario.mes, this.calendario.ano, dia.diaDaSemana)
      }
    }
  }

  modificaMes(valor: number): void {
    valor = this.calendario.mes + valor

    if (valor < 0) {
      this.calendario.mes = 11
      this.calendario.ano--
    } else if (valor < 12) {
      this.calendario.mes = valor
    } else {
      this.calendario.mes = 0
      this.calendario.ano++
    }

    this.renderizaCalendario()
  }

  modificaAno(valor: number): void {
    this.calendario.ano = Number(Number(this.calendario.ano) + valor)
    this.renderizaCalendario()
  }

  resetaParaEsteMes(): void {
    this.calendario.mes = new Date().getMonth()
    this.calendario.ano = new Date().getFullYear()
    this.renderizaCalendario()
  }

  processar(
    dia: number,
    mes: number,
    ano: number,
    diaDaSemana = null
  ): {
    data: string
    iso: string
    formatado: string
    diasEpoch: number
    dia: number
    mes: number
    ano: number
    diaDaSemana: number
    nomeDia: string
    nomeMes: string
  } {
    if (diaDaSemana === null) {
      diaDaSemana = new Date(ano, mes - 1, dia).getDay()
    }

    let dataISO = this.geraDataISO(dia, mes, ano)

    return {
      data: this.geraDataHifenizada(dia, mes, ano),
      iso: this.geraDataISO(dia, mes, ano),
      formatado: this.geraDataFormatada(dia, mes, ano),
      diasEpoch: moment(dataISO).diff(moment('1970-01-01'), 'days'),
      dia: dia,
      mes: mes,
      ano: ano,
      diaDaSemana: diaDaSemana,
      nomeDia: this.diasNomes[diaDaSemana],
      nomeMes: this.mesesNomes[mes]
    }
  }

  validaData(entrada: string | Date): {
    dia: number
    mes: number
    ano: number
  } {
    let dia = null
    let mes = null
    let ano = null

    let newDate = new Date()
    if (entrada) {
      if (typeof entrada == 'string' && entrada.length == 10) {
        if (entrada[2] === '/' && entrada[5] === '/') {
          let data = entrada.split('/')
          dia = data[0]
          mes = data[1]
          ano = data[2]
        }
        if (entrada[2] === '-' && entrada[5] === '-') {
          let data = entrada.split('-')
          dia = data[0]
          mes = data[1]
          ano = data[2]
        }
        if (entrada[4] === '/' && entrada[7] === '/') {
          let data = entrada.split('/')
          ano = data[0]
          mes = data[1]
          dia = data[2]
        }
        if (entrada[4] === '-' && entrada[7] === '-') {
          let data = entrada.split('-')
          ano = data[0]
          mes = data[1]
          dia = data[2]
        }
        if (dia || mes || ano) {
          dia = dia * 1
          mes = mes * 1 - 1
          ano = ano * 1
        }
      } else {
        if (entrada instanceof Date) {
          dia = entrada.getDate()
          mes = entrada.getMonth()
          ano = entrada.getFullYear()
        }
      }
    } else {
      dia = newDate.getDate()
      mes = newDate.getMonth()
      ano = newDate.getFullYear()
    }

    return {
      dia: dia,
      mes: mes,
      ano: ano
    }
  }

  geraDataFormatada(dia: number, mes: number, ano: number): string {
    return ('0' + dia).slice(-2) + '/' + ('0' + (mes + 1)).slice(-2) + '/' + ano
  }

  geraDataISO(dia: number, mes: number, ano: number): string {
    return ano + '-' + ('0' + (mes + 1)).slice(-2) + '-' + ('0' + dia).slice(-2)
  }

  geraDataHifenizada(dia = 0, mes = 0, ano = 0): string {
    if (dia && (mes || Number(mes) == 0) && ano)
      return ('0' + dia).slice(-2) + '-' + ('0' + (mes + 1)).slice(-2) + '-' + ano
    else
      return (
        ('0' + new Date().getDate()).slice(-2) +
        '-' +
        ('0' + (new Date().getMonth() + 1)).slice(-2) +
        '-' +
        new Date().getFullYear()
      )
  }

  opcoesRapidasOpen = false
  exibeOpcoesRapidas(): void {
    this.opcoesRapidasOpen = true
  }
  fechaOpcoesRapidas(event: Event): void {
    event.stopPropagation()
    this.opcoesRapidasOpen = false
    this.isSubPopoverVisible = false
  }
  selecaoRapida(event: Event, valor: string, valor1?: string | number): void {
    event.stopPropagation()

    if (valor == 'esta-semana') {
      this.calendario.mes = Number(moment().format('MM')) - 1
      this.calendario.ano = Number(moment().format('YYYY'))
      const diaDe = Number(moment().startOf('week').format('DD'))
      
      this.calendario.selecaoRange.de = this.processar(diaDe, this.calendario.mes, this.calendario.ano)
      this.calendario.selecaoRange.ate = this.processar(Number(moment().format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'semana-passada') {
      this.calendario.mes = Number(moment().subtract(1, 'week').format('MM')) - 1
      this.calendario.ano = Number(moment().subtract(1, 'week').format('YYYY'))
      const diaDe = Number(moment().subtract(1, 'week').startOf('week').format('DD'))
      const diaAte = Number(moment().subtract(1, 'week').endOf('week').format('DD'))
      
      this.calendario.selecaoRange.de = this.processar(diaDe, this.calendario.mes, this.calendario.ano)
      this.calendario.selecaoRange.ate = this.processar(diaAte, this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'este-mes') {
      this.calendario.mes = Number(moment().format('MM')) - 1
      this.calendario.ano = Number(moment().format('YYYY'))
      
      this.calendario.selecaoRange.de = this.processar(1, this.calendario.mes, this.calendario.ano)
      this.calendario.selecaoRange.ate = this.processar(Number(moment().format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'mes-passado') {
      this.calendario.mes = Number(moment().subtract(1, 'month').format('MM')) - 1
      this.calendario.ano = Number(moment().subtract(1, 'month').format('YYYY'))
      
      this.calendario.selecaoRange.de = this.processar(1, this.calendario.mes, this.calendario.ano)
      this.calendario.selecaoRange.ate = this.processar(Number(moment().subtract(1, 'month').endOf('month').format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'ultimos-3-meses') {
      this.calendario.mes = Number(moment().format('MM')) - 1
      this.calendario.ano = Number(moment().format('YYYY'))
      const tresMeses = moment().subtract(3, 'months')
      
      this.calendario.selecaoRange.de = this.processar(Number(tresMeses.format('DD')), Number(tresMeses.format('MM')) - 1, Number(tresMeses.format('YYYY')))
      this.calendario.selecaoRange.ate = this.processar(Number(moment().format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'ultimos-6-meses') {
      this.calendario.mes = Number(moment().format('MM')) - 1
      this.calendario.ano = Number(moment().format('YYYY'))
      const seisMeses = moment().subtract(6, 'months')
      
      this.calendario.selecaoRange.de = this.processar(Number(seisMeses.format('DD')), Number(seisMeses.format('MM')) - 1, Number(seisMeses.format('YYYY')))
      this.calendario.selecaoRange.ate = this.processar(Number(moment().format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'este-ano') {
      this.calendario.mes = Number(moment().format('MM')) - 1
      this.calendario.ano = Number(moment().format('YYYY'))
      const inicioDoAno = moment().startOf('year')
      
      this.calendario.selecaoRange.de = this.processar(1, 0, Number(inicioDoAno.format('YYYY')))
      this.calendario.selecaoRange.ate = this.processar(Number(moment().format('DD')), this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'ano-passado') {
      this.calendario.mes = 12 - 1
      this.calendario.ano = Number(moment().subtract(1, 'year').format('YYYY'))
      
      this.calendario.selecaoRange.de = this.processar(1, 0, this.calendario.ano)
      this.calendario.selecaoRange.ate = this.processar(31, this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'ano') {
      this.calendario.mes = 12 - 1
      this.calendario.ano = Number(valor1)
      
      this.calendario.selecaoRange.de = this.processar(1, 0, this.calendario.ano)
      this.calendario.selecaoRange.ate = this.processar(31, this.calendario.mes, this.calendario.ano)
    }
    if (valor == 'safra') {
      const [anoDe, anoAte] = String(valor1).split('/')
      this.calendario.mes = 5
      this.calendario.ano = Number(anoAte)

      this.calendario.selecaoRange.de = this.processar(1, 6, Number(anoDe))
      this.calendario.selecaoRange.ate = this.processar(30, 5, Number(anoAte))
    }
    this.opcoesRapidasOpen = false

    this.renderizaCalendario()
  }
  showSubPopover(tipoLista: 'ano' | 'safra'): void {
    this.listaAnosPopover = []
    this.listaSafrasPopover = []

    if (tipoLista == 'ano') {
      this.gerarListaDeAnosPopover()
    }

    if (tipoLista == 'safra') {
      this.gerarListaDeSafrasPopover()
    }

    this.isSubPopoverVisible = true
  }

  gerarListaDeAnosPopover(): void {
    const anoAtual = moment().year()

    for (let i = anoAtual; i > anoAtual - 10; i--) {
      this.listaAnosPopover.push(i)
    }
  }

  // this.listaSafrasPopover.push(`${anoAtual - i}/${anoAtual - i + 1}`)
  gerarListaDeSafrasPopover(): void {
    const anoAtual = moment().year()

    for (let i = 0; i < 11; i++) {
      const inicio = moment(`${anoAtual - i}-07-01`)

      if (!moment().isBefore(inicio)) {
        this.listaSafrasPopover.push(`${anoAtual - i}/${anoAtual - i + 1}`)
      }
    }
  }
}
