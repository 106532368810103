/* eslint-disable indent */
import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { NovoFiltro, Ordenacao } from '../components/vasta-tabela/vasta-tabela'
import { ColunasTabela } from '../utils/interfaces/tabela-colunas'

@Injectable({
  providedIn: 'root'
})
export class TabelaService {
  filtrarDados(
    colunas: ColunasTabela[],
    dados: Record<string, string | number | boolean>[],
    filtrosSelecionados: NovoFiltro
  ): Record<string, string | number | boolean>[] {
    let dadosFiltrados = dados

    Object.keys(filtrosSelecionados).map((key) => {
      let coluna = colunas.find((item) => item.chave === key)
      if(!coluna) return [...dadosFiltrados]
      if (coluna.tipoFiltro === 'rangeNumeros') {
        const numeros = filtrosSelecionados[key]
        dadosFiltrados = dadosFiltrados.filter(
          (item) =>
            Number(item[key]) >= numeros[0] && Number(item[key]) <= numeros[1]
        )
      } else if (coluna.tipoFiltro === 'rangeDatas') {
        const datas = filtrosSelecionados[key]
        dadosFiltrados = dadosFiltrados.filter(
          (item) =>
            moment(String(item[key])).isBetween(datas[0], datas[1], null, '[]') ||
            (item[key] === datas[0] && item[key] === datas[1])
        )
      } else {
        dadosFiltrados = dadosFiltrados.filter(
          (item) => {
            const valorColuna = this.formatarString(item[key])
            const valorFiltro = this.formatarString(filtrosSelecionados[key])
            
            const igual = valorColuna === valorFiltro
            const comeca = valorColuna?.startsWith(valorFiltro)
            const inclui = valorColuna?.includes(valorFiltro)
            let arrayInclui = false 

            try {
              arrayInclui = valorFiltro.split(',')?.includes(valorColuna)
            } catch (error) {
            }

            return igual || comeca || inclui || arrayInclui
          }
        )
      }
    })

    return [...dadosFiltrados]
  }

  ordenarDados(
    dados: Record<string, string | number | boolean>[],
    ordenacaoSelecionada: Ordenacao
  ): Record<string, string | number | boolean>[] {
    let dadosOrdenados = dados
    dadosOrdenados = dadosOrdenados.sort((a, b) => {
      return String(a[ordenacaoSelecionada.coluna]).localeCompare(String(b[ordenacaoSelecionada.coluna]), undefined, {numeric: true, sensitivity: 'base'})
    })

    if (ordenacaoSelecionada.direcao === 'desc') {
      dadosOrdenados = dadosOrdenados.reverse()
    }

    const validos = dadosOrdenados.filter(a => ![null, undefined, ''].includes(a[ordenacaoSelecionada.coluna] as unknown as string))
    const nulos = dadosOrdenados.filter(a => [null, undefined, ''].includes(a[ordenacaoSelecionada.coluna] as unknown as string))

    return [...validos, ...nulos]
  }

  formatarString(valor: string | unknown): string {
    return String(valor)
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }
}
