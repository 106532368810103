import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AlertController, MenuController, ModalController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import * as moment from 'moment'
import { IOptionButtons } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NavegacaoService } from 'src/app/services/navegacao.service'
import { PermissoesUsuarioService } from 'src/app/services/permissoes-usuario.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { UsuariosService } from 'src/app/services/usuarios.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ColunasPropriedades } from 'src/app/utils/configuracoes-padrao'
import { IGetPropriedades, IPropriedade } from 'src/app/utils/interfaces/propriedades.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { StatusPermissaoUsuario } from 'src/app/utils/interfaces/Usuarios.interface'
import { ModalPropriedadePage } from '../../geral/modal-propriedade/modal-propriedade.page'

@Component({
  selector: 'app-modal-lista-propriedades',
  templateUrl: './modal-lista-propriedades.page.html',
  styleUrls: ['./modal-lista-propriedades.page.scss']
})
export class ModalListaPropriedadesPage implements OnInit {
  public isOcultarOpcaoMais = false
  public propriedades: IPropriedade[] = []
  public labels = ColunasPropriedades
  public carregamentoPropriedades: StatusTabela = 'carregando'
  public config: ConfigTabela = {
    toolbar: false,
    filtrosEspecificos: false,
    orderby: 'nome',
    orderbyDirection: 'desc',
    impressaoTitulo: 'Áreas',
    exibeTotais: false,
    ocultarAjustes: true,
    excluirColunas: []
  }

  public optionsButtonsPropriedades: IOptionButtons[] = [
    {
      icone: 'pencil',
      titulo: 'Editar Propriedade',
      acao: async ({ original }: DadosLinhaTabela): Promise<void> => {
        const propriedadeSelecionada: IPropriedade = original
        await this.editarPropriedade(propriedadeSelecionada)
      }
    },
    {
      chave: 'apagarAnimal',
      icone: 'trash-outline',
      titulo: 'Apagar Propriedade',
      disabled: false,
      acao: async (linha: DadosLinhaTabela): Promise<void> => {
        await this.alertConfirmDeletePropriedade(linha)
      }
    }
  ]

  permiteCadastrarPropriedade = true

  constructor(
    private propriedadesCtrl: PropriedadesService,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private utilsCtrl: IonicUtilsService,
    private navegacaoCtrl: NavegacaoService,
    private usuarioCtrl: UsuariosService,
    private alertCtrl: AlertController,
    private permissoesCtrl: PermissoesUsuarioService,
    private router: Router,
    public planoBasicoCtrl: PlanoBasicoService
  ) {}

  ngOnInit(): void {
    const statusValidoEmFluxoInicialVisaoGeral: StatusPermissaoUsuario = 'listar-propriedade'
    this.permissoesCtrl.monitorarPermissaoUsuarioFecharModalIncompativel(statusValidoEmFluxoInicialVisaoGeral, this.modalCtrl)

    this.permiteCadastrarPropriedade = this.usuarioCtrl.usuarioCache?.plano_ativo && moment(this.usuarioCtrl.usuarioCache?.plano_validade).isAfter(moment())

    if (this.propriedadesCtrl?.todasPropriedades) {
      this.propriedades = this.propriedadesCtrl.todasPropriedades
      this.formatarPropriedadesParaExibirValoresColunas(this.propriedades)
      this.carregamentoPropriedades = 'sucesso'
    } 

    this.atualizarPropriedades()

    if (this.isOcultarOpcaoMais) {
      this.config.excluirColunas.push('mais')
    }
  }

  async atualizarPropriedades(): Promise<void> {
    this.propriedades = await this.getPropriedades()
  }

  async alertConfirmDeletePropriedade(event: DadosLinhaTabela): Promise<void> {
    const propriedade: IPropriedade = event.original

    const alert = await this.alertCtrl.create({
      header: 'Deseja remover esta propriedade?',
      message: 'Não é possível reverter esta operação.',
      buttons: [
        {
          text: 'Não'
        },
        {
          text: 'Sim',
          handler: async (): Promise<void> => {
            await this.deletarPropriedade(propriedade)
          }
        }
      ]
    })
    await alert.present()
  }

  async editarPropriedade(propriedadeSelecionada: IPropriedade): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalPropriedadePage,
      cssClass: 'custom-modal',
      componentProps: { propriedadeProp: propriedadeSelecionada }
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()

    if (data?.success) {
      if (this.propriedadesCtrl.propriedadeSelecionada.id === propriedadeSelecionada.id) {
        await this.atualizarPropriedadeSelecionada(propriedadeSelecionada)
      }

      this.atualizarPropriedades()
    }
  }

  async atualizarPropriedadeSelecionada(propriedadeSelecionada: IPropriedade): Promise<void> {
    await this.getPropriedades()
    this.propriedades = this.propriedadesCtrl.todasPropriedades
    const propriedadeSelecionadaAtualizada = this.propriedadesCtrl.todasPropriedades.find(
      (propriedade) => propriedade.id === propriedadeSelecionada.id
    )
    this.propriedadesCtrl.propriedadeSelecionada = propriedadeSelecionadaAtualizada
    VastaRX.setState('propriedade_selecionada', propriedadeSelecionadaAtualizada)
  }

  async openModalPropriedade(ev?: Event): Promise<void> {
    const { acessoAoItemBloqueado } = await this.planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('acoes', 'cadastro-propriedades', ev)
    if(acessoAoItemBloqueado) return

    const modal = await this.modalCtrl.create({
      component: ModalPropriedadePage,
      cssClass: 'custom-modal'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()
    if (data?.success) {
      await this.atualizarPropriedades()
      this.utilsCtrl.tick()
    }
  }

  async getPropriedades(): Promise<IGetPropriedades> {
    if (this.carregamentoPropriedades !== 'sucesso') this.carregamentoPropriedades = 'carregando'

    const [response, error] = await this.propriedadesCtrl.getPropriedades()
    if (response) {
      this.carregamentoPropriedades = 'sucesso'
      this.formatarPropriedadesParaExibirValoresColunas(response)
      this.propriedades = response
      return response
    }

    if (error) {
      this.carregamentoPropriedades = 'erro'
      console.error(error)
    }
  }

  formatarPropriedadesParaExibirValoresColunas(propriedades: IPropriedade[]): void {
    propriedades.forEach((propriedade) => {
      propriedade['producao'] = propriedade.metadados.tipo_producao
      propriedade['criacao'] = propriedade.metadados.tipo_criacao
    })
  }

  async deletarPropriedade(propriedade: IPropriedade): Promise<void> {
    if (this.propriedadesCtrl.todasPropriedades.length <= 1) {
      this.modalCtrl.dismiss()
      this.utilsCtrl.showToast('Não é possível remover esta propriedade', 'bottom')
    } else {
      this.carregamentoPropriedades = 'carregando'
      const loading = await this.utilsCtrl.showLoading('circular', 'Removendo Propriedade...')
      const [response, error] = await this.propriedadesCtrl.deletarPropriedade(propriedade.id)
      if (response) {
        this.modalCtrl.dismiss({ success: true })
        if (propriedade.id === this.propriedadesCtrl.propriedadeSelecionada.id) {
          this.router.navigateByUrl('/')
          this.propriedadesCtrl.propriedadeSelecionada = this.propriedadesCtrl.todasPropriedades[0]
          VastaRX.setState('propriedade_selecionada', this.propriedadesCtrl.propriedadeSelecionada)
        } else {
          await this.getPropriedades()
          this.carregamentoPropriedades = 'sucesso'
        }
      }

      if (error) {
        console.error(error)
        this.carregamentoPropriedades = 'erro'
      }

      this.utilsCtrl.dismissLoading(loading)
    }
  }

  async selecionarPropriedade(propriedade: IPropriedade, event?: DadosLinhaTabela): Promise<void> {
    if (!propriedade) {
      propriedade = event.original
    }

    if(propriedade.meu_role === 'proprietario') {
      const isUsuarioPossuiApenasAcessoSecundario = this.permissoesCtrl.statusPermissaoFluxoInicialVisaoGeral === 'acesso-secundario'

      if(isUsuarioPossuiApenasAcessoSecundario) {
        return await this.permissoesCtrl.openModalPlanoAssinatura({ isTrial: false, isBotaoClose: true })
      } 
    } 

    if (propriedade === this.propriedadesCtrl.propriedadeSelecionada) return
    this.propriedadesCtrl.propriedadeSelecionada = propriedade

    VastaRX.setState('propriedade_selecionada', propriedade)
    this.navegacaoCtrl.set(['/'])

    this.permissoesCtrl.verificaTopBar()
    
    this.planoBasicoCtrl.atualizarClasseCssDePlanoBasicoDoUsuario()

    this.usuarioCtrl.emitReload()

    this.modalClose()
    this.menuClose()
  }

  modalClose(): void {
    this.modalCtrl.dismiss()
  }

  menuClose(): void {
    this.menuCtrl.close()
  }

  async selecionarPropriedadeExemplo(): Promise<void> {
    try {
      this.modalCtrl.dismiss()
      const loading = await this.utilsCtrl.showLoading('circular', 'Buscando dados da propriedade')
      await this.propriedadesCtrl.selecionarPropriedadeExemplo()
      this.utilsCtrl.dismissLoading(loading)
      this.navegacaoCtrl.set(['/'])
      this.permissoesCtrl.verificaTopBar()
    } catch (erro) {
      console.error(erro)
    }
  }
}
