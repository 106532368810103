<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados?.edited">
    <div class="manejo-nome">Pastejo <br/> Rotacionado</div>
    
    <vasta-input
      *ngIf="piquetes.length > 0"
      label="Piquete Ativo"
      type="manejo-button"
      [value]="dados?.hash_piquete == 'nenhum' ? 'Nenhum' : piquetesAssociados[dados?.hash_piquete]?.nome"
      (buttonEvent)="showPopoverPiquetes($event)"
    ></vasta-input>

      <div *ngIf="piquetes.length == 0" >
        <span class="info-area">Nenhum piquete registrado nessa área</span>
      </div>
    
    <div class="container-acoes">
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>

  </section>
</div>