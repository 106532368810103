<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{contaSelecionada ? 'Atualizar Conta' : 'Nova Conta'}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <vasta-input
    label="Tipo"
    [obrigatorio]="true"
    type="select"
    [opcoes]="tiposDeConta"
    [(value)]="financasConta.conta.tipo"
  ></vasta-input>
  <vasta-input label="Título" [obrigatorio]="true" type="text" [(value)]="financasConta.conta.titulo"></vasta-input>
  <ion-row>
    <ion-col size="6" style="padding-left: 0">
      <vasta-input label="Agência" type="text" [(value)]="financasConta.conta.agencia"></vasta-input>
    </ion-col>

    <ion-col size="6" style="padding-right: 0">
      <vasta-input label="Conta" type="text" [(value)]="financasConta.conta.conta"></vasta-input>
    </ion-col>
  </ion-row>
  <vasta-input label="Decrição" type="text" [(value)]="financasConta.conta.descricao"></vasta-input>
  <vasta-input
    *ngIf="!financasConta?.conta?.hash"
    label="Saldo Inicial"
    type="number"
    maskPrefix="R$ "
    mask="separator.2"
    [(value)]="financasConta.saldo_inicial"
  ></vasta-input>
  <vasta-input
    label="Tornar Conta Padrão"
    type="checkbox"
    [class.hidden]="isContaPrincipal || contaPricipalProp"
    [valueCheckbox]="financasConta.conta_principal"
    (valueCheckboxChange)="getEventoContaPadrao($event)"
  ></vasta-input>
</ion-content>

<ion-footer class="ion-no-border">
  <cta-buttons>
    <button
      class="primary permissao-escrita-financeiro"
      (click)="cadastrarOuEditar()"
      [disabled]="isSalvadoConta || !isFormularioValido"
    >
      <ion-icon slot="start" name="checkmark" *ngIf="!isSalvadoConta"></ion-icon>
      <ion-spinner name="dots" *ngIf="isSalvadoConta"></ion-spinner>
      <span>Salvar</span>
    </button>
  </cta-buttons>

  <cta-buttons>
    <button
      class="danger permissao-escrita-financeiro"
      (click)="alertarSobreExcluirConta()"
      [disabled]="isApagandoConta || !isFormularioValido"
      *ngIf="contaSelecionada"
    >
      <ion-icon slot="start" name="close" *ngIf="!isApagandoConta"></ion-icon>
      <ion-spinner name="dots" *ngIf="isApagandoConta"></ion-spinner>
      <span>Excluir Conta</span>
    </button>
  </cta-buttons>
</ion-footer>
