<div class="componentes-manejo">
  <section class="manejo-single" [class.edited]="dados.edited">
    <div class="manejo-nome">Movimentação <br />entre Áreas</div>
    <vasta-input
      label="Área"
      type="manejo-button"
      [value]="areasAssociadas[dados.hash_area_destino]?.nome"
      (buttonEvent)="selecionarArea()"
    ></vasta-input>

    <div class="manejo-opcoes" *ngIf="opcoesRapidasAreas.length">
      <span class="label">Opções <br />Rápidas</span>
      <div class="container-opcoes">
        <ng-template ngFor let-hash [ngForOf]="opcoesRapidasAreas">
          <button
            *ngIf="areasAssociadas[hash]"
            (click)="selecionarAreaRapida(hash)"
            [class.active]="hash == dados.hash_area_destino"
          >
            {{ areasAssociadas[hash]?.nome || 'Sem nome' }}
          </button>
        </ng-template>
      </div>
    </div>

    <div class="manejo-info" *ngIf="dados?.observacao">
      <span class="label">Observação</span>
      <p class="content">{{ dados.observacao }}</p>
    </div>

    <div class="container-acoes">
      <button (click)="adicionarObservacao()" [disabled]="!dados.hash_area_destino">
        <ion-icon name="chatbox"></ion-icon>
      </button>
      <button (click)="showPopoverOpcoes($event)">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </section>
</div>
