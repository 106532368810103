import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { ManejoArea } from 'src/app/utils/interfaces/manejos.interface'
// import { AreasService } from 'src/app/services/areas.service'
import { IAreaPiquete } from 'src/app/utils/interfaces/areas.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'
import { PopoverController } from '@ionic/angular'
import { PopoverListaPiquetesComponent } from '../popover-lista-piquetes/popover-lista-piquetes.component'

@Component({
  selector: 'app-manejo-area-altura-capim',
  templateUrl: './manejo-area-altura-capim.component.html',
  styleUrls: ['./manejo-area-altura-capim.component.scss']
})
export class ManejoAreaAlturaCapimComponent implements OnInit {

  @Input() dados: Partial<ManejoArea> = {}
  @Input() piquetes: string[] = []
  @Input() piquetesAssociados: {[key: string]: IAreaPiquete} = {}
  @Input() hash_area: string

  @Output() piquetesChange = new EventEmitter<unknown>()
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  public piquetesSelecionados: string[] = []

  constructor(
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit(): void {
    const piquetesManejosSalvos = this.dados ? Object.keys(this.dados).filter(key =>  this.dados[key].altura_capim !== null) : []
    if (piquetesManejosSalvos.length > 0) {
      this.piquetesSelecionados = piquetesManejosSalvos
    }
  }

  emitChange(dado: { altura_capim: number; edited: boolean}): void {
    dado.edited = true
    this.emit()
  }

  emit(): void {
    this.dadosChange.emit(this.dados)
  }

  async showPopoverOpcaoItemManejoArea(
    event: Event,
    dado: { altura_capim: number; edited: boolean }
  ): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      componentProps: { manejoTipo: 'altura-capim' },
      event,
      reference: 'event',
      cssClass: 'popover-opcao-item-manejo-area'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      dado.altura_capim = null
      dado.edited = true
      this.emit()
    }
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      Object.keys(this.dados).forEach((key) => {
        this.dados[key].altura_capim = null
        this.dados[key].edited = true
      })
      this.emit()
    }
  }

  async showPopoverPiquetes(event: Event): Promise<void> {

    const todosPiquetesArea = Object.keys(this.piquetesAssociados).filter((key) => this.piquetesAssociados[key].hash_area === this.hash_area)
    
    const popover = await this.popoverCtrl.create({
      component: PopoverListaPiquetesComponent,
      translucent: true,
      componentProps: {
        piquetes:  todosPiquetesArea.filter((piquete) => !this.piquetesAssociados[piquete]?.piquete_selecionado_altura_capim),
        piquetesAssociados: this.piquetesAssociados,
        mensagem: 'Todos os piquetes já foram adicionados'
      },
      event
    })
    await popover.present()

    const { data } = await popover.onDidDismiss()

    if (data) {
      this.piquetesAssociados[data].piquete_selecionado_altura_capim = true
    }
  
    this.piquetesChange.emit(this.piquetesAssociados[data])
    
  }

}