<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      Selecionar Conta
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="modalClose()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-tabela
    [labels]="labels"
    [campos]="contas"
    [config]="config"
    [status]="carregamentoContas"
    [optionsButtons]="optionsButtonsPropriedades"
    [novoFiltro]="novoFiltro"
    [novaOrdenacao]="novaOrdenacao"
    (novoFiltroChange)="getContas()"
    (novaOrdenacaoChange)="getContas()"
    (retentar)="getContas()"
    (selecionado)="selecionarConta($event)"
  ></vasta-tabela>
</ion-content>
<ion-footer>
  <cta-buttons></cta-buttons>
  <cta-buttons>
    <button
      class="permissao-escrita-financeiro acao-cadastro"
      (click)="criarOuEditarConta()"
      *ngIf="carregamentoContas == 'sucesso'"
      [disabled]="carregamentoContas !== 'sucesso'"
    >
      <ion-icon name="add-circle"></ion-icon>
      <span>Nova Conta</span>
    </button>
  </cta-buttons>
</ion-footer>
