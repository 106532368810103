import { Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import { IArea, IAreaPiquete } from 'src/app/utils/interfaces/areas.interface'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { DesenharAreaComponent } from 'src/app/pages/areas/desenhar-area/desenhar-area.component'
import { AreasService } from 'src/app/services/areas.service'

@Component({
  selector: 'app-modal-cadastrar-piquete',
  templateUrl: './modal-cadastrar-piquete.component.html',
  styleUrls: ['./modal-cadastrar-piquete.component.scss']
})
export class ModalCadastrarPiqueteComponent implements OnInit {
  public propPiquete: IAreaPiquete
  public qtdPiquetes: number

  public area: IArea
  public hashAreaSelecionada: string

  public loadingExcluirAreaPiquete = false
  public loadingSalvarAreaPiquete = false

  public piquete: IAreaPiquete = {}

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private utilsCtrl: IonicUtilsService,
    private areasCtrl: AreasService
  ) {}

  async ngOnInit(): Promise<void> {
    this.piquete.hash_area = this.hashAreaSelecionada

    if (this.propPiquete) {
      this.piquete = this.propPiquete
    } else {
      this.piquete.nome = `Piquete ${this.qtdPiquetes.toString()}`
    }
  }

  salvarPiquete(): void {
    if (this.propPiquete) {
      this.editarPiquete()
    } else {
      this.cadastrarPiquete()
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  async openModalDesenharAreaPiquete(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DesenharAreaComponent,
      cssClass: 'custom-modal-desenhar-area',
      componentProps: {
        hash_not_in: this.piquete?.hash ? [this.piquete?.hash] : [],
        area: this.piquete.area,
        hashArea: this.area?.hash || [],
        areaPrincipal: this.area?.area || [],
        isPiquete: true
      }
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (data) {
      const { area, coordenadas_centrais, tamanho } = data
      this.piquete.area = area
      this.piquete.coordenadas_centrais = coordenadas_centrais
      this.piquete.tamanho = tamanho
    }
  }

  async alertConfirmDeleteAreaPiquete(piquete: IAreaPiquete): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Deseja realmente remover este piquete?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Remover',
          handler: (): void => {
            this.deletarPiquete(piquete)
          }
        }
      ]
    })

    await alert.present()
  }

  async deletarPiquete(propPiquete: IAreaPiquete): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('dots')

    const [response, error] = await this.areasCtrl.deletarPiquete(propPiquete.hash)

    if (response) {
      this.utilsCtrl.showToast('Piquete deletado', 'bottom')
    }

    if (error) {
      console.error(error)
    }

    this.utilsCtrl.dismissLoading(loading)

    this.modalCtrl.dismiss()
  }

  async cadastrarPiquete(): Promise<void> {
    const piquetePayload = [{ ...this.piquete, hash_area: this.hashAreaSelecionada }]
    this.loadingSalvarAreaPiquete = true
    const [response, error] = await this.areasCtrl.setPiquete(piquetePayload)
    this.loadingSalvarAreaPiquete = false

    if (response) {
      VastaRX.setState('updated', true)
      this.modalCtrl.dismiss({
        success: true
      })
      this.utilsCtrl.showToast('Piquete Cadastrado com sucesso', 'bottom')
    }

    if (error) {
      console.error(error)
      const message = error.message ? error.message : 'Não foi possível cadastrar o piquete'
      this.utilsCtrl.showToast(message, 'bottom')
    }
  }

  async editarPiquete(): Promise<void> {
    const piquetePayload = [{ ...this.piquete, hash_area: this.hashAreaSelecionada }]
    const hashPiquete = this.propPiquete.hash
    this.loadingSalvarAreaPiquete = true
    const [response, error] = await this.areasCtrl.editarPiquete(hashPiquete, piquetePayload)
    this.loadingSalvarAreaPiquete = false

    if (response) {
      VastaRX.setState('updated', true)
      this.modalCtrl.dismiss({
        success: true
      })
      this.utilsCtrl.showToast('Piquete Atualizado com sucesso', 'bottom')
    }

    if (error) {
      console.error(error)
      const message = error.message ? error.message : 'Não foi possível atualizar o piquete'
      this.utilsCtrl.showToast(message, 'bottom')
    }
  }
}
