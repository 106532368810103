<ion-header class="ion-no-border">
  <ion-toolbar>
    <!-- <ion-title>
      Plano Expirado
    </ion-title> -->
		<ion-buttons *ngIf="isBotaoClose" slot="end">
			<ion-button (click)="dismiss()">
				<ion-icon name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <img src="assets/SVG/plano-expirado.svg" alt="imagem plano-expirado fertili" />

  <div class="plano-expirado">
		<ng-template [ngIf]="tipoVencimento === 'trial-expirado'">
			<h2 class="titulo">O seu teste grátis do Fertili Premium chegou ao fim</h2>
			<p>Obrigado por fazer o teste grátis do plano Fertili Premium! Para continuar usando o Fertili 360 com todas as suas funcionalidades, conheça nossos planos com preços especiais e garanta sua assinatura.</p>
			<p><b>Clique no botão abaixo</b> e escolha o melhor plano para a sua fazenda! Se preferir, entre em contato conosco. Ajudaremos você a tomar a melhor decisão.</p>
		</ng-template>
		<ng-template [ngIf]="tipoVencimento === 'plano-expirado'">
			<h2 class="titulo">Assinatura expirada!</h2>
			<p>Não detectamos a renovação da sua assinatura do Fertili 360. Verifique a situação da fatura ou altere sua forma de pagamento para continuar usando o sistema.</p>
			<p>Se preferir, entre em contato conosco para tirar suas dúvidas ou obter ajuda sobre seu plano.</p>
		</ng-template>
	</div>

	<cta-buttons class="margem">
		<button class="primary" *ngIf="tipoVencimento == 'trial-expirado'" (click)="abrirAssinatura()">
      <ion-icon name="list"></ion-icon>
			<span>Ver Planos</span>
		</button>
		<button class="primary" *ngIf="tipoVencimento == 'plano-expirado'" (click)="abrirAssinatura()">
      <ion-icon name="repeat"></ion-icon>
			<span>Renovar Assinatura</span>
		</button>
		<button (click)="entrarEmContato()">
			<ion-icon name="logo-whatsapp"></ion-icon>
			<span>77 99839-1515</span>
		</button>
	</cta-buttons>
	<cta-buttons class="margem" style="margin-top: 12px;" *ngIf="tipoVencimento === 'trial-expirado'">
		<button (click)="IniciarPlanoBasicoEExibirDetalhes()">
			<ion-icon name="close"></ion-icon>
			<span>Continuar com Plano Controle</span>
		</button>
	</cta-buttons>

	<p>
		&nbsp;
	</p>
</ion-content>