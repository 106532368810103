<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/manejos" color="primary"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Manejos de <b>{{ data | timestamp : 'data' }}</b>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button disabled>
        <ion-icon name="help-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <!-- 'Áreas',  -->
  <div class="mobile-tabs-container">
    <vasta-segment
    class="cadastrar-manejos"
    [tabs]="['Animais', 'Lotes', 'Áreas']"
    [(tabActive)]="abaAtiva"
    (tabActiveChange)="selecionaPagina(1)"
    ></vasta-segment>
    
    <vasta-segment
    class="cadastrar-manejos"
    [tabs]="['Lista', 'Manejos']"
    [(tabActive)]="secaoAtiva"
    (tabActiveChange)="selecionaPagina(1)"
    ></vasta-segment>
  </div>
</ion-header>
<ion-content class="ion-no-padding">
  <main>
    <aside [class.show-mobile]="secaoAtiva == 'Manejos'">
      <header>
        <div class="logo-container">
          <ion-back-button defaultHref="/manejos" color="primary"></ion-back-button>
          <img src="assets/imgs/icon-fertili.svg" alt="logo fertili" />
        </div>

        <h4>
          <propriedade-nome></propriedade-nome>
          <b>{{ data | timestamp : 'data' }}</b>
        </h4>
      </header>

      <vasta-segment
        class="vasta-segment-manejos"
        [tabs]="['Animais', 'Lotes', 'Áreas']"
        [(tabActive)]="abaAtiva"
        [class.animaisAtivo]="manejosCtrl.campoManejoAlterado"
        [class.lotesAtivo]="manejosCtrl.campoManejoLotesAlterado"
        [class.areasAtivo]="manejosCtrl.campoManejoAreasAlterado"
        (tabActiveChange)="selecionaPagina(1)"
      ></vasta-segment>

      <section class="menu-desktop">
        <app-manejo-seletor
          *ngIf="abaAtiva === 'Animais'"
          [tipo]="abaAtiva"
          [(manejosSelecionados)]="manejosSelecionados"
          [(manejosDisponiveis)]="manejosDisponiveis"
          (manejosSelecionadosChange)="eventosAoSelecionarManejos()"
          [resumos]="resumos"
          [status]="carregamentoManejos"
          [salvando]="salvando"
          [(filtrosManejos)]="filtrosManejosAnimais"
          (filtrosManejosChange)="filtrar()"
          (acaoManejo)="showPopoverOpcoesManejos($event.ev, $event.identificador, $event.opcoesParaTodosManejos)"
          (manejoDeOutraAbaSelecionado)="manejoDeOutraAbaSelecionado($event)"
          (adicionarManejo)="openModalManejosDisponiveis()"
        >
        </app-manejo-seletor>

        <app-manejo-seletor
          *ngIf="abaAtiva === 'Lotes'"
          [tipo]="abaAtiva"
          [(manejosSelecionados)]="manejosSelecionadosLotes"
          [(manejosDisponiveis)]="manejosDisponiveisLotes"
          (manejosSelecionadosChange)="eventosAoSelecionarManejos()"
          [resumos]="resumosLotes"
          [status]="carregamentoManejos"
          [salvando]="salvando"
          [(filtrosManejos)]="filtrosManejosLotes"
          (filtrosManejosChange)="filtrar()"
          (acaoManejo)="showPopoverOpcoesManejos($event.ev, $event.identificador, $event.opcoesParaTodosManejos)"
          (manejoDeOutraAbaSelecionado)="manejoDeOutraAbaSelecionado($event)"
          (adicionarManejo)="openModalManejosDisponiveis()"
        >
        </app-manejo-seletor>

        <app-manejo-seletor
          *ngIf="abaAtiva === 'Áreas'"
          [tipo]="abaAtiva"
          [(manejosSelecionados)]="manejosSelecionadosAreas"
          [(manejosDisponiveis)]="manejosDisponiveisAreas"
          (manejosSelecionadosChange)="eventosAoSelecionarManejos()"
          [resumos]="resumosAreas"
          [status]="carregamentoManejos"
          [salvando]="salvando"
          [(filtrosManejos)]="filtrosManejosAreas"
          (filtrosManejosChange)="filtrar()"
          (acaoManejo)="showPopoverOpcoesManejos($event.ev, $event.identificador, $event.opcoesParaTodosManejos)"
          (manejoDeOutraAbaSelecionado)="manejoDeOutraAbaSelecionado($event)"
          (adicionarManejo)="openModalManejosDisponiveis()"
        >
        </app-manejo-seletor>
      </section>
      <div class="container-filtros permissao-escrita-financeiro">
        <ng-template
         ngFor
         let-custo
         [ngForOf]="custosManejo"
        >
          <div class="filtro">
            <div class="filtro-single-wrapper">
              <div class="filtro-tipo">
                <span>{{ custo.titulo }}</span>
                <div class="totais">
                  <div class="dado-single">
                    R$ {{ custo.valor | number: '1.2-2' }}
                  </div>
                  <div class="dado-single">
                    {{categoriasLabels[custo.categoria]}}
                  </div>
                </div>
              </div>
              <div class="filtro-acoes">
                <button (click)="showPopoverOpcoes($event, custo)">
                  <ion-icon name="ellipsis-vertical"></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-template>
        <cta-buttons>
          <button
            (click)="abrirAdicionarCustos()"
            [class.disabled]="!!salvando || carregamentoLancamentoFinanceiros !== 'sucesso'"
          >
          <ion-icon name="add-circle"></ion-icon>
          <span>Adicionar Custo</span>
         </button>
        </cta-buttons>
      </div>
    </aside>
    <div class="container-content" [class.show-mobile]="secaoAtiva !== 'Manejos'" [ngStyle]="{'padding-bottom': hideFooter? '0' : ''}">
      <header *ngIf="abaAtiva === 'Animais'">
        <cta-buttons class="ion-float-left">
          <button *ngIf="hashsSelecionados.length" class="primary" (click)="showPopoverOpcoesManejos($event)">
            <ion-icon name="menu-outline"></ion-icon>
            <span>Opções</span>
          </button>

          <button
            *ngIf="!hashsSelecionados.length"
            class="primary"
            [class.primary]="manejosDisponiveis.length && !animais.length"
            [disabled]="!manejosDisponiveis.length || salvando"
            (click)="selecionarAnimais()"
          >
            <ion-icon name="add-circle-sharp"></ion-icon>
            <span>Animal</span>
            <div class="container-popover vertical" *ngIf="manejosDisponiveis.length && !animais.length">
              <div class="custom-popover">
                Adicione animais
                <div class="popover-arrow-up"></div>
              </div>
            </div>
          </button>
          <button
            class="gray"
            *ngIf="!hashsSelecionados.length && manejosDisponiveis.length && animais.length"
            [disabled]="salvando"
            (click)="selecionarLotes()"
          >
            <ion-icon name="rebanho"></ion-icon>
            <span>Filtrar Lotes</span>
          </button>
          <button
            class="gray"
            *ngIf="!hashsSelecionados.length && manejosDisponiveis.length && animais.length"
            [disabled]="salvando"
            (click)="selecionarFiltroAnimais()"
          >
            <ion-icon name="bovino"></ion-icon>
            <span>Filtrar Animais</span>
          </button>
        </cta-buttons>

        <div class="resumo-animais" *ngIf="hashsSelecionados.length">
          <span>
            {{ hashsSelecionados.length }}
            {{ hashsSelecionados.length == 1 ? 'animal selecionado' : 'animais selecionados' }}
          </span>
        </div>
        <div class="spacer"></div>

        <div class="search-wrapper">
          <div class="search-animais">
            <ion-icon name="search"></ion-icon>
            <input
              class="busca-animais"
              placeholder="Filtrar animais..."
              [(ngModel)]="filtro.termo"
              (keyup)="filtrar()"
              (keyup.esc)="limparFiltros()"
            />
          </div>
        </div>
      </header>
      <header *ngIf="abaAtiva === 'Lotes'">
        <cta-buttons class="ion-float-left">
          <button *ngIf="hashsSelecionadosLotes.length" class="primary" (click)="showPopoverOpcoesManejos($event)">
            <ion-icon name="menu-outline"></ion-icon>
            <span>Opções</span>
          </button>
          <button
            *ngIf="!hashsSelecionadosLotes.length"
            class="primary"
            [class.primary]="manejosDisponiveisLotes.length && !lotes.length"
            [disabled]="!manejosDisponiveisLotes.length || salvando"
            (click)="selecionarLotes()"
          >
            <ion-icon name="add-circle-sharp"></ion-icon>
            <span>Lote</span>
            <div class="container-popover vertical" *ngIf="manejosDisponiveisLotes.length && !lotes.length">
              <div class="custom-popover">
                Adicione lotes
                <div class="popover-arrow-up"></div>
              </div>
            </div>
          </button>
        </cta-buttons>

        <div class="resumo-animais" *ngIf="hashsSelecionadosLotes.length">
          <span>
            {{ hashsSelecionadosLotes.length }}
            {{ hashsSelecionadosLotes.length == 1 ? 'lote selecionada' : 'lotes selecionados' }}
          </span>
        </div>
        <div class="spacer"></div>

        <div class="search-wrapper">
          <div class="search-animais">
            <ion-icon name="search"></ion-icon>
            <input
              class="busca-animais"
              placeholder="Filtrar lotes..."
              [(ngModel)]="filtro.termoLote"
              (keyup)="filtrar()"
              (keyup.esc)="limparFiltros()"
            />
          </div>
        </div>
      </header>
      <header *ngIf="abaAtiva === 'Áreas'">
        <cta-buttons class="ion-float-left">
          <button *ngIf="hashsSelecionadosAreas.length" class="primary" (click)="showPopoverOpcoesManejos($event)">
            <ion-icon name="menu-outline"></ion-icon>
            <span>Opções</span>
          </button>
          <button
            *ngIf="!hashsSelecionadosAreas.length"
            class="primary"
            [class.primary]="manejosDisponiveisAreas.length && !areas.length"
            [disabled]="!manejosDisponiveisAreas.length || salvando"
            (click)="selecionarAreas()"
          >
            <ion-icon name="add-circle-sharp"></ion-icon>
            <span>Área</span>
            <div class="container-popover vertical" *ngIf="manejosDisponiveisAreas.length && !areas.length">
              <div class="custom-popover">
                Adicione áreas
                <div class="popover-arrow-up"></div>
              </div>
            </div>
          </button>
        </cta-buttons>

        <div class="resumo-animais" *ngIf="hashsSelecionadosAreas.length">
          <span>
            {{ hashsSelecionadosAreas.length }}
            {{ hashsSelecionadosAreas.length == 1 ? 'área selecionada' : 'áreas selecionadas' }}
          </span>
        </div>
        <div class="spacer"></div>

        <div class="search-wrapper">
          <div class="search-animais">
            <ion-icon name="search"></ion-icon>
            <input
              class="busca-animais"
              placeholder="Filtrar áreas..."
              [(ngModel)]="filtro.termoArea"
              (keyup)="filtrar()"
              (keyup.esc)="limparFiltros()"
            />
          </div>
        </div>
      </header>

      <ng-container *ngIf="abaAtiva === 'Animais'">
        <div class="filtros-ativos" *ngIf="filtro.hashsLotes.length || filtro.hashsAnimais.length">
          <button class="limpar" (click)="limparFiltrosLotesEAnimais()">
            <ion-icon name="trash"></ion-icon>
            Limpar
          </button>
          <button *ngFor="let hash of filtro.hashsLotes" class="filtro-lote">
            <ion-icon name="rebanho"></ion-icon>
            {{ lotesPreenchidos[hash]?.nome }}
            <div class="container-close" (click)="removerFiltroHashLote(hash)">
              <ion-icon name="close"></ion-icon>
            </div>
          </button>
          <button
            *ngFor="let hash of filtro.hashsAnimais"
            class="filtro-lote"
            [class.macho]="animaisPorHash[hash]?.sexo === 'macho'"
            [class.femea]="animaisPorHash[hash]?.sexo === 'femea'"
          >
            <ion-icon name="bovino" *ngIf="!animaisPorHash[hash]?.sexo"></ion-icon>
            <ion-icon name="male" *ngIf="animaisPorHash[hash]?.sexo === 'macho'"></ion-icon>
            <ion-icon name="female" *ngIf="animaisPorHash[hash]?.sexo === 'femea'"></ion-icon>
            {{ animaisPorHash[hash]?.numeracao }} <b>{{ animaisPorHash[hash]?.nome }}</b>
            <div class="container-close" (click)="removerFiltroHashAnimal(hash)">
              <ion-icon name="close"></ion-icon>
            </div>
          </button>
        </div>
        <div class="manejo-content">
          <div #scrollTopElement></div>
          <vasta-tabela
            [disableVirtualScroll]="true"
            [labels]="labels"
            [campos]="animaisExibidos"
            [status]="carregamentoManejos"
            [(hashsSelecionados)]="hashsSelecionados"
            (selecionarTodos)="selecionarTodos($event)"
            [config]="config"
            [novaOrdenacao]="ordenacaoAnimais"
            (novoFiltroChange)="ordenacaoLocal(); filtrar()"
            (retentar)="carregarManejosSalvos()"
            (selecionado)="irParaFichaDoAnimal($event)"
          >
            <ng-template #innerComponents let-linha>
              <div class="componentes-manejo-wrapper">
                <ng-template
                  ngFor
                  let-manejo
                  [ngForOf]="manejosSelecionados.length ? manejosSelecionados : manejosDisponiveis"
                >
                  <ng-template [ngIf]="manejo.sexo == linha.original.sexo || !manejo.sexo">
                    <app-manejo-area-nutricional
                      *ngIf="manejo.procedimento === 'nutricao' && dadosManejosAnimais?.[linha.original.hash]?.nutricao"
                      [dataManejo]="data"
                      [dados]="dadosManejosAnimais?.[linha.original.hash].nutricao"
                      [(estoqueNutricional)]="animaisEstoqueNutricional"
                      (estoqueNutricionalChange)="formatarManejos({ alterouItensEstoque: true })"
                      (dadosChange)="calcularResumos()"
                    ></app-manejo-area-nutricional>
                    <app-manejo-sanitario
                      *ngIf="manejo.tipo == 'sanitario' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-sanitario>
                    <app-manejo-sanitario-novo
                      *ngIf="manejo.tipo == 'sanitarioEstoque' && dadosManejosAnimais?.[linha.original.hash]?.sanitarioEstoque"
                      [dados]="dadosManejosAnimais?.[linha.original.hash].sanitarioEstoque"
                      [dataManejo]="data"
                      (dadosChange)="calcularResumos()"
                      [(opcoesRapidasQuantidade)]="opcoesRapidasQuantidade"
                      [(estoqueSanitario)]="animaisEstoqueSanitario" (estoqueSanitarioChange)="formatarManejos({ alterouItensEstoque: true })"
                      [abaAtiva]="abaAtiva">
                    </app-manejo-sanitario-novo>
                    <app-manejo-geral-numero-controle
                      *ngIf="manejo.procedimento == 'numero-controle' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      [numerosDeControleJaUtilizados]="numerosDeControleJaUtilizados"
                      [numeroAtual]="linha?.original?.numeracao"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-geral-numero-controle>
                    <app-manejo-geral-numero-brinco-eletronico
                      *ngIf="manejo.procedimento == 'numero-brinco-eletronico' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-geral-numero-brinco-eletronico>
                    <app-manejo-geral-transferencia-propriedade
                      *ngIf="manejo.procedimento == 'transferencia-propriedade' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      [propriedades]="propriedades"
                      [lotesOpcoes]="lotesPorPropriedadeOpcoes"
                      (propriedadeSelecionadaChange)="getLotes($event)"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-geral-transferencia-propriedade>
                    <app-manejo-geral-venda
                      *ngIf="manejo.procedimento == 'venda' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento] && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]?.venda_hash_transacao"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      [animal]="linha.original"
                      [(vendas)]="vendas"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-geral-venda>
                    <app-manejo-geral-movimentacao-lote
                      *ngIf="manejo.procedimento == 'movimentacao-lote' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      [(opcoesRapidasLotes)]="lotesOpcoesRapidasLotes"
                      [(lotesAssociados)]="lotesAssociados"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-geral-movimentacao-lote>
                    <app-manejo-geral-movimentacao-confinamento
                      *ngIf="manejo.procedimento == 'movimentacao-confinamento' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      [(opcoesRapidasConfinamento)]="lotesOpcoesRapidasConfinamentos"
                      [(lotesAssociados)]="lotesAssociados"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-geral-movimentacao-confinamento>
                    <app-manejo-geral-perda
                      *ngIf="manejo.procedimento == 'perda' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-geral-perda>
                    <app-manejo-geral-secagem
                      *ngIf="manejo.procedimento == 'secagem' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-geral-secagem>
                    <app-manejo-geral-morte
                      *ngIf="manejo.procedimento == 'morte' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-geral-morte>
                    <app-manejo-pesagem
                      *ngIf="manejo.tipo == 'pesagem' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.tipo]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.tipo]"
                      [data]="data"
                      (dadosChange)="calcularResumos()"
                      [ultimoPeso]="linha.original?.pesagem_peso"
                      [ultimoPesoData]="linha.original?.pesagem_data"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.tipo]})"
                    >
                    </app-manejo-pesagem>
                    <app-manejo-ordenha
                      *ngIf="manejo.tipo == 'ordenha' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.tipo]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.tipo]"
                      (dadosChange)="calcularResumos()"
                      [ultimaOrdenha]="linha.original?.ordenha_anterior"
                      [ultimaOrdenhaData]="linha.original?.ordenha_anterior_data"
                      [ultimaOrdenha]="linha.original?.ordenha_anterior"
                      [ultimaOrdenhaData]="linha.original?.ordenha_anterior_data"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.tipo]})"
                    >
                    </app-manejo-ordenha>
                    <app-manejo-geral-observacao
                      *ngIf="manejo.procedimento == 'observacao' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-geral-observacao>
                    <app-manejo-reprodutivo-cio
                      *ngIf="manejo.procedimento == 'cio' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-reprodutivo-cio>
                    <app-manejo-reprodutivo-implante
                      *ngIf="manejo.procedimento == 'implante' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-reprodutivo-implante>
                    <app-manejo-reprodutivo-remocao-implante
                      *ngIf="manejo.procedimento == 'implante-remocao' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-reprodutivo-remocao-implante>
                    <app-manejo-reprodutivo-diagnostico-gestacao
                      *ngIf="manejo.procedimento == 'dg' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                      [exposicoes]="exposicoes"
                      [exposicoesAssociadas]="exposicoesAssociadas"
                      [data]="data"
                    ></app-manejo-reprodutivo-diagnostico-gestacao>
                    <app-manejo-reprodutivo-inseminacao
                      *ngIf="manejo.procedimento == 'inseminacao' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(opcoesRapidasEstoqueBiologico)]="inseminacaoOpcoesRapidasEstoqueBiologico"
                      [(opcoesRapidasPessoas)]="inseminacaoOpcoesRapidasInseminador"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      [(estoquesAssociados)]="estoquesAssociados"
                      [(pessoasAssociadas)]="pessoasAssociadas"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-reprodutivo-inseminacao>
                    <app-manejo-reprodutivo-monta-natural
                      *ngIf="manejo.procedimento == 'monta-natural' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      [(opcoesRapidasReprodutores)]="montaNaturalOpcoesRapidasReprodutores"
                      [(animaisAssociados)]="animaisAssociados"
                      [(animaisExternosAssociados)]="animaisExternosAssociados"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-reprodutivo-monta-natural>
                    <app-manejo-reprodutivo-parto
                      *ngIf="manejo.procedimento == 'parto' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo?.tipo][manejo?.procedimento]"
                      [(animaisAssociados)]="animaisAssociados"
                      [data]="data"
                      [animal]="animaisPorHash[linha.original.hash]"
                      [genealogiaParto]="genealogiaParto?.[linha.original?.hash]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                      [hashNotIn]="[linha.original.hash]"
                    ></app-manejo-reprodutivo-parto>
                    <app-manejo-reprodutivo-desmame
                      *ngIf="manejo.procedimento == 'desmame' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    >
                    </app-manejo-reprodutivo-desmame>
                    <app-manejo-reprodutivo-aborto
                      *ngIf="manejo.procedimento == 'aborto' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-reprodutivo-aborto>
                    <app-manejo-reprodutivo-castracao
                      *ngIf="manejo.procedimento == 'castracao' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-reprodutivo-castracao>
                    <app-manejo-reprodutivo-escore-corporal
                      *ngIf="manejo.procedimento == 'escore' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      [ultimoEscore]="linha.original?.escore_anterior"
                      [ultimoEscoreData]="linha.original?.escore_anterior_data"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-reprodutivo-escore-corporal>
                    <app-manejo-reprodutivo-descarte
                      *ngIf="manejo.procedimento == 'descarte' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                     ></app-manejo-reprodutivo-descarte>
                     <app-manejo-genetica-perimetro-escrotal
                      *ngIf="manejo.procedimento == 'perimetro-escrotal' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-perimetro-escrotal>
                     <app-manejo-genetica-temperamento
                      *ngIf="manejo.procedimento == 'temperamento' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-temperamento>
                     <app-manejo-genetica-musculosidade
                      *ngIf="manejo.procedimento == 'musculosidade' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-musculosidade>
                     <app-manejo-genetica-umbigo
                      *ngIf="manejo.procedimento == 'umbigo' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-umbigo>
                     <app-manejo-genetica-aprumos
                      *ngIf="manejo.procedimento == 'aprumos' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-aprumos>
                     <app-manejo-genetica-racial
                      *ngIf="manejo.procedimento == 'racial' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-racial>
                     <app-manejo-genetica-boca
                      *ngIf="manejo.procedimento == 'boca' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-boca>
                     <app-manejo-genetica-frame
                      *ngIf="manejo.procedimento == 'frame' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-frame>
                     <app-manejo-genetica-garupa
                      *ngIf="manejo.procedimento == 'garupa' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-garupa>
                     <app-manejo-genetica-insercao-cauda
                      *ngIf="manejo.procedimento == 'insercao-cauda' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-insercao-cauda>
                     <app-manejo-genetica-ossatura
                      *ngIf="manejo.procedimento == 'ossatura' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-ossatura>
                     <app-manejo-genetica-pelagem
                      *ngIf="manejo.procedimento == 'pelagem' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-pelagem>
                     <app-manejo-genetica-profundidade
                      *ngIf="manejo.procedimento == 'profundidade' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-profundidade>
                     <app-manejo-genetica-reproducao
                      *ngIf="manejo.procedimento == 'reproducao' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-reproducao>
                     <app-manejo-genetica-torcao-testicular
                      *ngIf="manejo.procedimento == 'torcao-testicular' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-torcao-testicular>
                     <app-manejo-genetica-casco
                      *ngIf="manejo.procedimento == 'casco' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-casco>
                     <app-manejo-genetica-chanfro
                      *ngIf="manejo.procedimento == 'chanfro' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-chanfro>
                     <app-manejo-genetica-linha-dorso
                      *ngIf="manejo.procedimento == 'linha-dorso' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-linha-dorso>
                     <app-manejo-genetica-ubere
                      *ngIf="manejo.procedimento == 'ubere' && dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                      [(dados)]="dadosManejosAnimais[linha.original.hash][manejo.tipo][manejo.procedimento]"
                      (dadosChange)="calcularResumos()"
                      (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAnimais?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                    ></app-manejo-genetica-ubere>
                  </ng-template>
                </ng-template>
              </div>
            </ng-template>
          </vasta-tabela>

          <div class="container-sem-manejos" *ngIf="!animais.length">
            <ion-text>
              <ion-icon name="bovino"></ion-icon>
              <span *ngIf="!manejosDisponiveis.length">Nenhum Manejo Adicionado</span>
              <span *ngIf="manejosDisponiveis.length && !animais.length">Nenhum Animal Adicionado</span>
            </ion-text>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="abaAtiva === 'Lotes'">
        <div class="manejo-content">
          <div #scrollTopElement></div>
          <vasta-tabela
            [disableVirtualScroll]="true"
            [labels]="labelsLotes"
            [campos]="lotesExibidos"
            [status]="'sucesso'"
            [config]="configLotes"
            [(hashsSelecionados)]="hashsSelecionadosLotes"
            >
            <!-- [novaOrdenacao]="ordenacaoLotes"
            (novaOrdenacaoChange)="ordenacaoLocal(); filtrar()" -->
            <!-- (selecionarTodos)="selecionarTodos($event)" -->
            <!-- (retentar)="carregarManejosSalvos()" -->
            <!-- (selecionado)="irParaFichaDoAnimal($event)" -->
            <ng-template #innerComponents let-linha>
              <div class="componentes-manejo-wrapper">
                <ng-template
                  ngFor
                  let-manejo
                  [ngForOf]="manejosSelecionadosLotes.length ? manejosSelecionadosLotes : manejosDisponiveisLotes"
                >
                  <app-manejo-area-movimentacao-lotes
                    *ngIf="manejo.procedimento === 'movimentacao-entre-areas' && dadosManejosLotes?.[linha.original.hash]?.manejosLotes[manejo.procedimento]"
                    [(areasAssociadas)]="areasAssociadas"
                    [(opcoesRapidasAreas)]="areasOpcoesRapidas"
                    [dados]="dadosManejosLotes?.[linha.original.hash].manejosLotes[manejo.procedimento]"
                    (dadosChange)="calcularResumos()"
                    (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosLotes?.[linha.original.hash]?.manejosLotes?.[manejo.procedimento]})"
                  ></app-manejo-area-movimentacao-lotes>
                  <app-manejo-area-nutricional
                    *ngIf="manejo.procedimento === 'nutricao' && dadosManejosLotes?.[linha.original.hash]?.nutricao"
                    [dataManejo]="data"
                    [dados]="dadosManejosLotes?.[linha.original.hash].nutricao"
                    [(estoqueNutricional)]="lotesEstoqueNutricional"
                    (estoqueNutricionalChange)="formatarManejosLotes({ alterouItensEstoque: true })"
                    (dadosChange)="calcularResumos()"
                  ></app-manejo-area-nutricional>
                  <app-manejo-sanitario-novo
                    *ngIf="manejo.tipo == 'sanitarioEstoque' && dadosManejosLotes?.[linha.original.hash]?.sanitarioEstoque"
                    [dados]="dadosManejosLotes?.[linha.original.hash].sanitarioEstoque"
                    [dataManejo]="data"
                    (dadosChange)="calcularResumos()"
                    [(opcoesRapidasQuantidade)]="opcoesRapidasQuantidade"
                    [(estoqueSanitario)]="lotesEstoqueSanitario" (estoqueSanitarioChange)="formatarManejosLotes({ alterouItensEstoque: true })"
                    [abaAtiva]="abaAtiva">
                  </app-manejo-sanitario-novo>
                  <app-manejo-geral-observacao
                    *ngIf="manejo.procedimento == 'observacao' && dadosManejosLotes?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                    [(dados)]="dadosManejosLotes[linha.original.hash][manejo.tipo][manejo.procedimento]"
                    (dadosChange)="calcularResumos()"
                    (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosLotes?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                  ></app-manejo-geral-observacao>
                  <app-manejo-lote-escore-fezes
                    *ngIf="manejo.procedimento == 'escore-fezes' && dadosManejosLotes?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                    [(dados)]="dadosManejosLotes[linha.original.hash][manejo.tipo][manejo.procedimento]"
                    (dadosChange)="calcularResumos()"
                    (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosLotes?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                  ></app-manejo-lote-escore-fezes>
                </ng-template>
                <!-- </ng-template> -->
              </div>
            </ng-template>
          </vasta-tabela>

          <div class="container-sem-manejos" *ngIf="!lotes.length">
            <ion-text>
              <ion-icon name="cerca"></ion-icon>
              <span *ngIf="!manejosDisponiveisLotes.length">Nenhum Manejo Adicionado</span>
              <span *ngIf="manejosDisponiveisLotes.length">Nenhum Lote Adicionado</span>
            </ion-text>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="abaAtiva === 'Áreas'">
        <div class="manejo-content">
          <div #scrollTopElement></div>
          <vasta-tabela
            [disableVirtualScroll]="true"
            [labels]="labelsAreas"
            [campos]="areasExibidas"
            [status]="'sucesso'"
            [config]="configAreas"
            [(hashsSelecionados)]="hashsSelecionadosAreas"
            >
            <!-- [novaOrdenacao]="ordenacaoAreas"
            (novaOrdenacaoChange)="ordenacaoLocal(); filtrar()" -->
            <!-- (selecionarTodos)="selecionarTodos($event)" -->
            <!-- (retentar)="carregarManejosSalvos()" -->
            <!-- (selecionado)="irParaFichaDoAnimal($event)" -->
            <ng-template #innerComponents let-linha>
              <div class="componentes-manejo-wrapper">
                <ng-template
                  ngFor
                  let-manejo
                  [ngForOf]="manejosSelecionadosAreas.length ? manejosSelecionadosAreas : manejosDisponiveisAreas"
                >
                  <app-manejo-area-chuva
                    *ngIf="manejo.procedimento === 'chuva' && dadosManejosAreas?.[linha.original.hash]?.areas?.[manejo.procedimento]"
                    [dados]="dadosManejosAreas?.[linha.original.hash].areas[manejo.procedimento]"
                    (dadosChange)="calcularResumos()"
                    (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAreas?.[linha.original.hash].areas[manejo.procedimento]})"
                  ></app-manejo-area-chuva>
                  <app-manejo-area-altura-capim
                    *ngIf="manejo.procedimento === 'altura-capim'"
                    [dados]="dadosManejosAreas?.[linha.original.hash]?.areas?.['altura-capim']"
                    [piquetes]="piquetes[linha?.original?.hash] || []"
                    [hash_area]="linha?.original?.hash"
                    [piquetesAssociados]="piquetesAssociados"
                    (piquetesChange)="atualizarPiquetes($event)"
                    (dadosChange)="calcularResumos()"
                  ></app-manejo-area-altura-capim>
                  <app-manejo-area-pastejo-rotacionado
                    *ngIf="manejo.procedimento === 'pastejo-rotacionado'"
                    [dados]="dadosManejosAreas?.[linha.original.hash]?.areas?.['pastejo-rotacionado']"
                    [piquetes]="piquetes[linha?.original?.hash] || []"
                    [hash_area]="linha?.original?.hash"
                    [(piquetesAssociados)]="piquetesAssociados"
                    (piquetesChange)="atualizarPiquetes($event)"
                    (dadosChange)="calcularResumos()"
                  ></app-manejo-area-pastejo-rotacionado>
                  <app-manejo-area-nutricional
                    *ngIf="manejo.procedimento === 'nutricao' && dadosManejosAreas?.[linha.original.hash]?.nutricao"
                    [dataManejo]="data"
                    [dados]="dadosManejosAreas?.[linha.original.hash].nutricao"
                    [(estoqueNutricional)]="areasEstoqueNutricional"
                    (estoqueNutricionalChange)="formatarManejosAreas({ alterouItensEstoque: true })"
                    (dadosChange)="calcularResumos()"
                  ></app-manejo-area-nutricional>
                  <app-manejo-sanitario-novo
                    *ngIf="manejo.tipo == 'sanitarioEstoque' && dadosManejosAreas?.[linha.original.hash]?.sanitarioEstoque"
                    [dados]="dadosManejosAreas?.[linha.original.hash].sanitarioEstoque"
                    [dataManejo]="data"
                    (dadosChange)="calcularResumos()"
                    [(opcoesRapidasQuantidade)]="opcoesRapidasQuantidade"
                    [(estoqueSanitario)]="areasEstoqueSanitario" (estoqueSanitarioChange)="formatarManejosAreas({ alterouItensEstoque: true })"
                    [abaAtiva]="abaAtiva">
                  </app-manejo-sanitario-novo>
                  <app-manejo-geral-observacao
                    *ngIf="manejo.procedimento == 'observacao' && dadosManejosAreas?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]"
                    [(dados)]="dadosManejosAreas[linha.original.hash][manejo.tipo][manejo.procedimento]"
                    (dadosChange)="calcularResumos()"
                    (observacaoChange)="showAlertAdicionarObservacao({dados: dadosManejosAreas?.[linha.original.hash]?.[manejo.tipo]?.[manejo.procedimento]})"
                  ></app-manejo-geral-observacao>
                </ng-template>
                <!-- </ng-template> -->
              </div>
            </ng-template>
          </vasta-tabela>

          <div class="container-sem-manejos" *ngIf="!areas.length">
            <ion-text>
              <ion-icon name="cerca"></ion-icon>
              <span *ngIf="!manejosDisponiveisAreas.length">Nenhum Manejo Adicionado</span>
              <span *ngIf="manejosDisponiveisAreas.length">Nenhuma Área Adicionada</span>
            </ion-text>
          </div>
        </div>
      </ng-container>
      <footer *ngIf="carregamentoManejos !== 'erro' && !hideFooter">
        <div class="notice" *ngIf="salvando">
          <ion-spinner color="light"></ion-spinner>
          <span> Salvando {{ textosSalvando[salvando] }} </span>
        </div>

        <ng-template [ngIf]="abaAtiva == 'Animais'">
          <app-paginate
            class="ion-float-left"
            mode="slim"
            [totalItens]="paginate.total"
            [(limit)]="paginate.limit"
            [(offset)]="paginate.offset"
            [(currentPage)]="paginate.currentPage"
            (currentPageChange)="selecionaPagina($event)"
          ></app-paginate>

          <div class="resumo-animais" *ngIf="animais.length">
            <span>{{ animais.length }} {{ animais.length == 1 ? 'animal disponível' : 'animais disponíveis' }}</span>
            <span *ngIf="animais.length !== this.paginate.total">, {{ this.paginate.total }} filtrados</span>
          </div>
        </ng-template>

        <ng-template [ngIf]="abaAtiva == 'Lotes'">
          <app-paginate
            class="ion-float-left"
            mode="slim"
            [totalItens]="paginateLotes.total"
            [(limit)]="paginateLotes.limit"
            [(offset)]="paginateLotes.offset"
            [(currentPage)]="paginateLotes.currentPage"
            (currentPageChange)="selecionaPagina($event)"
          ></app-paginate>

          <div class="resumo-animais" *ngIf="lotes.length">
            <span>{{ lotes.length }} {{ lotes.length == 1 ? 'lote disponível' : 'lotes disponíveis' }}</span>
            <span *ngIf="lotes.length !== this.paginateLotes.total">, {{ this.paginateLotes.total }} filtrados</span>
          </div>
        </ng-template>

        <ng-template [ngIf]="abaAtiva == 'Áreas'">
          <app-paginate
            class="ion-float-left"
            mode="slim"
            [totalItens]="paginateAreas.total"
            [(limit)]="paginateAreas.limit"
            [(offset)]="paginateAreas.offset"
            [(currentPage)]="paginateAreas.currentPage"
            (currentPageChange)="selecionaPagina($event)"
          ></app-paginate>

          <div class="resumo-animais" *ngIf="areas.length">
            <span>{{ areas.length }} {{ areas.length == 1 ? 'área disponível' : 'áreas disponíveis' }}</span>
            <span *ngIf="areas.length !== this.paginateAreas.total">, {{ this.paginateAreas.total }} filtrados</span>
          </div>
        </ng-template>

        <!-- <cta-buttons class="ion-float-left">
          <button
            class="primary"
            [class.primary]="manejosDisponiveis.length && !animais.length"
            [class.light]="!(manejosDisponiveis.length && !animais.length)"
            [disabled]="!manejosDisponiveis.length || salvando"
            (click)="selecionarAnimais()"
          >
            <ion-icon name="add-circle-sharp"></ion-icon>
            <span>Animal</span>
            <div class="container-popover vertical" *ngIf="manejosDisponiveis.length && !animais.length">
              <div class="custom-popover">
                Adicione animais
                <div class="popover-arrow-down"></div>
              </div>
            </div>
          </button>
        </cta-buttons> -->

        <!-- <input class="busca-animais" placeholder="Filtrar animais..." /> -->

        <cta-buttons class="ion-float-right">
          <button (click)="openModalRelatorioManejos()" class="light">
            <ion-icon name="reader-outline"></ion-icon>
            <span>Relatório</span>
          </button>
          <button
            (click)="salvar()"
            class="primary permissao-escrita-manejos"
            [class.primary]="
              (manejosDisponiveis.length && animais.length) ||
              (manejosDisponiveisLotes.length && lotes.length) ||
              (manejosDisponiveisAreas.length && areas.length)
            "
            [class.light]="
              !(
                (manejosDisponiveis.length && animais.length) ||
                (manejosDisponiveisLotes.length && lotes.length) ||
                (manejosDisponiveisAreas.length && areas.length)
              )
            "
            [disabled]="
              (!manejosDisponiveis.length && !manejosDisponiveisLotes.length && !manejosDisponiveisAreas.length) ||
              (!animais.length && !lotes.length && !areas.length) ||
              salvando ||
              (!manejosCtrl.campoManejoAlterado &&
                !manejosCtrl.campoManejoLotesAlterado &&
                !manejosCtrl.campoManejoAreasAlterado)
            "
          >
            <ion-icon name="checkmark"></ion-icon>
            <span>Salvar</span>
          </button>
        </cta-buttons>
      </footer>
    </div>
  </main>
</ion-content>
