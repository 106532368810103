import { Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import * as moment from 'moment'
import { LotesService } from 'src/app/services/lotes.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { ModalListaAreasPage } from '../../areas/modal-lista-areas/modal-lista-areas.page'

@Component({
  selector: 'app-modal-edicao-lote',
  templateUrl: './modal-edicao-lote.component.html',
  styleUrls: ['./modal-edicao-lote.component.scss']
})
export class ModalEdicaoLoteComponent implements OnInit {
  public loteParams: ILote
  public formLote = {
    id: 0,
    hash: '',
    nome: '',
    nome_area: '',
    hash_area: '',
    aptidao: '',
    finalidade: '',
    data_entrada_area: '',
    sistema_criacao: '',
    observacao: '',
    confinamento: false,
    meta_situacao: 'ativo'
  }
  public salvando = false
  public hash_area_anterior = ''
  constructor(
    private modalCtrl: ModalController,
    private lotesCtrl: LotesService,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController,
    private propriedadesCtrl: PropriedadesService
  ) {}

  ngOnInit(): void {
    if (this.loteParams) {
      this.formLote = this.utilsCtrl.cloneObject(this.loteParams)
      this.formLote.meta_situacao = this.formLote.meta_situacao || 'ativo'
    } else {
      this.formLote.aptidao = this.propriedadesCtrl.propriedadeSelecionada.metadados.tipo_producao
      this.formLote.sistema_criacao = this.propriedadesCtrl.propriedadeSelecionada.metadados.sistema_criacao
    }
  }

  async cadastrarLote(): Promise<void> {
    this.salvando = true
    const payloadLote = JSON.parse(JSON.stringify(this.formLote))

    const [response, error] = await this.lotesCtrl.cadastrarEditarLote({
      lote: payloadLote
    })

    this.salvando = false

    if (error) {
      console.error(error)
      const message = error.message ? error.message : 'Erro ao salvar lote, tente novamente mais tarde'
      this.utilsCtrl.showToast(message, 'bottom')
    }

    if (response) {
      this.closeModal({ success: true })
    }

    console.log(this.formLote)
  }

  async closeModal(params = null): Promise<void> {
    await this.modalCtrl.dismiss(params)
  }

  async openModalListaAreas(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAreasPage,
      cssClass: 'custom-modal-seletor'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      this.formLote.nome_area = data.areas[0].nome
      this.formLote.hash_area = data.areas[0].hash
      this.formLote.data_entrada_area = moment().format('YYYY-MM-DD')
    }
  }

  limpaCamposConfinamento(): void {
    if (this.formLote.confinamento) {
      this.formLote.nome_area = ''
      this.formLote.hash_area = ''
      this.formLote.finalidade = ''
      this.formLote.aptidao = ''
      this.formLote.sistema_criacao = ''
    }
  }

  async alertConfirmDeleteLote(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Ao remover esse lote os manejos, custos, históricos e atribuições dos animais realizadas com esse lote serão perdidos',
      message: 'Tem certeza que deseja remover esse lote?',
      buttons: [
        'Cancelar',
        {
          text: 'Arquivar lote',
          handler: async (): Promise<void> => {
            this.arquivarLote()
          }
        },
        {
          text: 'Remover lote e todos os dados associados',
          role: 'destructive',
          handler: (): void => {
            this.excluirLote()
          }
        }
      ]
    })

    await alert.present()
  }

  async excluirLote(): Promise<void> {
    this.salvando = true

    const [response, error] = await this.lotesCtrl.deletarLote({
      hashs: [this.formLote.hash]
    })

    this.salvando = false

    if (error) {
      const message = error.message ? error.message : 'Erro ao excluir lote, tente novamente mais tarde'
      this.utilsCtrl.showToast(message, 'bottom')
    }

    if (response) {
      this.closeModal({ loteDeletado: true })
    }
  }

  async arquivarLote(): Promise<void> {
    try {
      this.salvando = true
      await this.lotesCtrl.arquivarLote({ hash: this.formLote.hash })
      this.salvando = false
      this.closeModal({ success: true })
    } catch (error) {
      const message = error.message ? error.message : 'Erro ao arquivar lote, tente novamente mais tarde'
      this.utilsCtrl.showToast(message, 'bottom')
    }
  }
}
