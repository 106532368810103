<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Selecionar Área
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-tabela
    *ngIf="!(!areas?.length && carregamentoAreas == 'sucesso')"
    [labels]="labels"
    [campos]="areas"
    [config]="config"
    [paginate]="paginate"
    [(hashsSelecionados)]="hashsSelecionados"
    (filtrosChange)="getAreas()"
    [status]="carregamentoAreas"
    (retentar)="getAreas()"
    (selecionado)="selecionarArea($event)"
    [(novaOrdenacao)]="novaOrdenacao"
    [(novoFiltro)]="novoFiltro"
    (novoFiltroChange)="getAreas()"
  ></vasta-tabela>

  <div class="placeholder-vazio" *ngIf="!areas?.length && carregamentoAreas == 'sucesso'">Nenhuma área encontrada</div>
</ion-content>
<ion-footer>
  <cta-buttons>
    <button class="primary" (click)="confirmarAreas()" [disabled]="!hashsSelecionados.length" *ngIf="multiplos">
      <ion-icon name="checkmark-outline"></ion-icon>
      <span>Confirmar</span>
    </button>
  </cta-buttons>
  <cta-buttons>
    <button
      (click)="modalCadastrarArea()"
      [class.primary]="!multiplos && !areas?.length && carregamentoAreas == 'sucesso'"
    >
      <ion-icon name="add-circle"></ion-icon>
      <span>Cadastrar Área</span>
    </button>
  </cta-buttons>
</ion-footer>
