import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core'
import { IonContent, ModalController } from '@ionic/angular'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { FinancasService } from 'src/app/services/financas.service'
import { ColunasFinancasPessoas } from 'src/app/utils/configuracoes-padrao'
import { IDismissPropsPessoaSelecionada, Pessoa } from 'src/app/utils/interfaces/financas.interface'
import { StatusRequest } from 'src/app/utils/interfaces/statusRequest.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalPessoasComponent } from '../modal-pessoas/modal-pessoas.component'

@Component({
  selector: 'app-modal-lista-pessoas',
  templateUrl: './modal-lista-pessoas.page.html',
  styleUrls: ['./modal-lista-pessoas.page.scss']
})
export class ModalListaPessoasPage implements OnInit {
  @ViewChild(IonContent) content: IonContent
  public colunasPessoas = ColunasFinancasPessoas
  public statusCarregamentoPessoas: StatusTabela = 'carregando'
  public acontecimentosConfig: ConfigTabela = {
    toolbar: true,
    filtrosEspecificos: true,
    exibeTotais: false,
    excluirColunas: ['mais']
  }

  public filtroPessoas: NovoFiltro = {}
  public ordenacaoPessoas: Ordenacao = { coluna: 'nome', direcao: 'asc' }
  public listaPessoas: Pessoa[] = []
  private propsPessoas: IDismissPropsPessoaSelecionada = {
    pessoaSelecionada: null,
    isPessoaNovaCadastrada: false,
    listaPessoasAtualizada: []
  }

  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  constructor(private modalCtrl: ModalController, private financasCtrl: FinancasService, private ref: ApplicationRef) { }

  ngOnInit(): void {
    if (this.listaPessoas?.length) {
      this.paginate.total = this.listaPessoas.length
      this.listaPessoas = this.listaPessoas.slice(0, this.paginate.limit)
      this.statusCarregamentoPessoas = 'sucesso'
    }

    this.getPessoas()
  }

  selecionarPessoa(dadosTabela: DadosLinhaTabela): void {
    this.propsPessoas.pessoaSelecionada = dadosTabela.original
    this.closeModal()
  }

  async alterouFiltrosPessoas(): Promise<void> {
    await this.getPessoas()
  }

  async getPessoas(): Promise<void> {
    this.statusCarregamentoPessoas = 'carregando'

    const [sucesso, erro] = await this.financasCtrl.getPessoas({
      filtros: this.filtroPessoas,
      ordenacao: this.ordenacaoPessoas,
      paginate: this.paginate
    })

    if (sucesso) {
      this.statusCarregamentoPessoas = 'sucesso'
      this.listaPessoas = sucesso.centros
      const totalPessoas = sucesso.totais.totais
      this.paginate.total = Number(totalPessoas)
    }
    if (erro) {
      this.statusCarregamentoPessoas = 'erro'
      console.error(erro)
    }
  }

  async abrirModalParaCadastrarPessoa(pessoa?: DadosLinhaTabela): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalPessoasComponent,
      componentProps: { pessoaProp: pessoa },
      cssClass: 'custom-modal'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    const pessoaCadastrada: StatusRequest = data

    if (pessoaCadastrada.success) {
      await this.getPessoas()
      this.propsPessoas.isPessoaNovaCadastrada = true
      this.propsPessoas.listaPessoasAtualizada = this.listaPessoas
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss(this.propsPessoas)
  }

  paginateChange(): void {
    this.content?.scrollToTop(500)
    this.getPessoas()
  }
}
