import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { IOptionButtons } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ColunasContas } from 'src/app/utils/configuracoes-padrao'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { FinancasService } from 'src/app/services/financas.service'
import { IConta } from 'src/app/utils/interfaces/financas.interface'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { FinanceiroCadastrarNovaContaPage } from '../financeiro-cadastrar-nova-conta/financeiro-cadastrar-nova-conta.page'

@Component({
  selector: 'app-modal-financeiro-contas',
  templateUrl: './modal-financeiro-contas.page.html',
  styleUrls: ['./modal-financeiro-contas.page.scss']
})
export class ModalFinanceiroContasPage implements OnInit {
  public isOcultarOpcaoMais = false
  public contas: IConta[] = []
  public labels = ColunasContas
  public carregamentoContas: StatusTabela = 'carregando'
  public config: ConfigTabela = {
    toolbar: true,
    filtrosEspecificos: true,
    impressaoTitulo: 'Contas',
    excluirColunas: []
  }

  public novoFiltro: NovoFiltro = {}
  public novaOrdenacao: Ordenacao = { coluna: 'saldo_total', direcao: 'desc' }

  public optionsButtonsPropriedades: IOptionButtons[] = [
    {
      icone: 'pencil',
      titulo: 'Editar Conta',
      acao: async ({ original }: DadosLinhaTabela): Promise<void> => {
        const contaSelecionada: IConta = original as unknown as IConta
        await this.criarOuEditarConta(contaSelecionada)
      }
    },
    {
      chave: 'apagarAnimal',
      icone: 'trash-outline',
      titulo: 'Apagar Conta',
      disabled: false,
      acao: async ({ original }: DadosLinhaTabela): Promise<void> => {
        await this.alertarSobreExcluirConta(original as unknown as IConta)
      }
    }
  ]

  constructor(
    private financeiroCtrl: FinancasService,
    private modalCtrl: ModalController,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController,
    public planoBasicoCtrl: PlanoBasicoService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.isOcultarOpcaoMais) {
      this.config.excluirColunas.push('mais')
    }
    this.getContas()
  }

  async getContas(): Promise<void> {
    this.carregamentoContas = 'carregando'
    
    const [response, error] = await this.financeiroCtrl.getContas({
      filtros: this.novoFiltro,
      ordenacao: this.novaOrdenacao,
    })
    
    
    if (response) {
      this.contas = response.contas
      this.carregamentoContas = 'sucesso'
      this.ref.detectChanges()
      console.log('response', response)
    }
    
    if (error) {
      this.carregamentoContas = 'erro'
      console.error(error)
    }
  }

  async criarOuEditarConta(conta?: IConta): Promise<void> {
    const modal = await this.modalCtrl.create({
      cssClass: 'custom-modal',
      component: FinanceiroCadastrarNovaContaPage,
      componentProps: {
        contaSelecionada: conta,
        contas: this.contas,
        contaPricipalProp: !this.contas.length
      }
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      this.getContas()
    }
  }

  async selecionarConta(event?: DadosLinhaTabela): Promise<void> {
    const conta = event.original as unknown as IConta

    this.modalCtrl.dismiss({
      conta
    })

    this.modalClose()
  }

  modalClose(): void {
    this.modalCtrl.dismiss()
  }

  async alertarSobreExcluirConta(conta: IConta): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Excluir Conta',
      message: 'Tem certeza que deseja excluir essa conta?',
      buttons: [
        {
          text: 'Não'
        },
        {
          text: 'Sim',
          handler: async (): Promise<void> => {
            await this.excluirConta(conta)
          }
        }
      ]
    })
    await alert.present()
  }

  async excluirConta(conta: IConta): Promise<void> {
    const [response, erro] = await this.financeiroCtrl.apagarConta(conta.hash)
    if (response) {
      this.utilsCtrl.showToast('Conta apagada com sucesso', 'bottom')
      this.getContas()
    }

    if (erro) {
      this.utilsCtrl.showToast('Falha ao apagar conta', 'bottom')
      this.getContas()
      console.error(erro)
    }
  }
}
