import { ListaManejosProp } from './manejos-disponiveis.component'

// tipoManejo -> procedimento
// tipo = sanitario reprodutivo pesagem ordenha outro
// identificador -> manejo sanitário é um hash gerado na hora de criar o manejo sanitario, ou o tipo no caso dos outros

const listaManejos: ListaManejosProp[] = [
  {
    icon: 'pesagem-controle',
    nome: 'Pesagem',
    procedimento: 'pesagem',
    tipo: 'pesagem',
    cor: 'verde',
    identificador: 'pesagem',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'pesagem'
  },
  {
    icon: 'leiteiro',
    nome: 'Ordenha',
    procedimento: 'ordenha',
    tipo: 'ordenha',
    cor: 'verde',
    identificador: 'ordenha',
    sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'ordenha'
  },
  {
    icon: 'secagem',
    nome: 'Secagem',
    procedimento: 'secagem',
    tipo: 'geral',
    cor: 'verde',
    identificador: 'secagem',
    sexo: 'femea',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'secagem'
  },
  {
    icon: 'leaf',
    nome: 'Manejo Nutricional',
    procedimento: 'nutricao',
    tipo: 'nutricao',
    cor: 'azul',
    identificador: 'nutricao',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'nutricional'
  },
  {
    icon: 'rebanho',
    nome: 'Movimentação entre Lotes',
    procedimento: 'movimentacao-lote',
    tipo: 'geral',
    cor: 'azul',
    identificador: 'movimentacao-lote',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'movimentacao-entre-lotes'
  },
  {
    icon: 'boi-cerca',
    nome: 'Envio para Confinamento',
    procedimento: 'movimentacao-confinamento',
    tipo: 'geral',
    cor: 'azul',
    identificador: 'movimentacao-confinamento',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'enviar-para-confinamento'
  },
  {
    icon: 'alteracao-numero',
    nome: 'Alteração de Número',
    procedimento: 'numero-controle',
    tipo: 'geral',
    cor: 'azul',
    identificador: 'numero-controle',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'alteracao-numero'
  },
  {
    icon: 'brinco',
    nome: 'Alteração de Brinco Eletrônico',
    procedimento: 'numero-brinco-eletronico',
    tipo: 'geral',
    cor: 'azul',
    identificador: 'numero-brinco-eletronico',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'associacao-de-brinco'
  },
  {
    icon: 'remover-bovino',
    nome: 'Registro de Morte',
    procedimento: 'morte',
    tipo: 'geral',
    cor: 'azul',
    identificador: 'morte',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'registrar-morte'
  },
  {
    icon: 'bovino-perda',
    nome: 'Registro de Perda',
    procedimento: 'perda',
    tipo: 'geral',
    cor: 'azul',
    identificador: 'perda',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'registrar-perda'
  },
  {
    icon: 'arrow-forward',
    nome: 'Movimentação entre Fazendas',
    procedimento: 'transferencia-propriedade',
    tipo: 'geral',
    cor: 'azul',
    identificador: 'transferencia-propriedade'
  },
  {
    icon: 'exit',
    nome: 'Venda',
    procedimento: 'venda',
    tipo: 'geral',
    cor: 'azul',
    identificador: 'venda'
  },
  {
    icon: 'warning',
    nome: 'Observação',
    procedimento: 'observacao',
    tipo: 'geral',
    cor: 'azul',
    identificador: 'observacao',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'observacao'
  }
]

const listaManejosReprodutivos: ListaManejosProp[] = [
  {
    icon: 'cio',
    nome: 'Detecção de Cio',
    procedimento: 'cio',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'cio',
    sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'diagnostico-cio'
  },
  {
    icon: 'implante-add',
    nome: 'Aplicação de Implante',
    procedimento: 'implante',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'implante',
    sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'implante-progesterona'
  },
  {
    icon: 'implante-remove',
    nome: 'Remoção de Implante',
    procedimento: 'implante-remocao',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'implante-remocao',
    sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'remocao-implante'
  },
  {
    icon: 'dg',
    nome: 'Diagnóstico de Gestação',
    procedimento: 'dg',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'dg',
    sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'diagnostico-gestacao'
  },
  {
    icon: 'inseminacao',
    nome: 'Inseminação Artificial',
    procedimento: 'inseminacao',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'inseminacao',
    sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'inseminacao-artificial'
  },
  {
    icon: 'monta-natural',
    nome: 'Monta Natural',
    procedimento: 'monta-natural',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'monta-natural',
    sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'monta-natural'
  },
  {
    icon: 'parto',
    nome: 'Parto',
    procedimento: 'parto',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'parto',
    sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'parto'
  },
  {
    icon: 'desmame',
    nome: 'Desmame',
    procedimento: 'desmame',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'desmame',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'desmame'
  },
  {
    icon: 'aborto',
    nome: 'Aborto',
    procedimento: 'aborto',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'aborto',
    sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'aborto'
  },
  {
    icon: 'fechar',
    nome: 'Castração',
    procedimento: 'castracao',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'castracao',
    // sexo: 'femea',
    importavel: true,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'castracao'
  },
  {
    icon: 'escore',
    nome: 'Escore Corporal',
    procedimento: 'escore',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'escore',
    sexo: 'femea',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'escore-corporal'
  },
  {
    icon: 'remover-bovino',
    nome: 'Descarte',
    procedimento: 'descarte',
    tipo: 'reprodutivo',
    cor: 'vermelho',
    identificador: 'descarte',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'descarte'
  }
]

const listaManejosSanitarios: ListaManejosProp[] = [
  {
    icon: 'sanitario-estoque',
    nome: 'Itens da Farmácia',
    procedimento: 'sanitarioEstoque',
    tipo: 'sanitarioEstoque',
    cor: 'laranja',
    identificador: 'sanitarioEstoque',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitarioEstoque'
  },
  {
    icon: 'sanitario-procedimentos-veterinarios',
    nome: 'Proced. Veterinários',
    procedimento: '',
    tipo: 'sanitario',
    cor: 'laranja',
    identificador: 'procedimentos-veterinarios',
    qtd: 0,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-procedimentos-veterinarios'
  },
  {
    icon: 'search',
    nome: 'Exames',
    procedimento: '',
    tipo: 'sanitario',
    cor: 'laranja',
    identificador: 'exames',
    qtd: 0,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-exames'
  },
  // {
  //   icon: 'seringa',
  //   nome: 'Complexo Vitamínico',
  //   procedimento: '',
  //   tipo: 'sanitario',
  //   identificador: 'complexo-vitaminico',
  //   qtd: 0
  // },
  // {
  //   icon: 'seringa',
  //   nome: 'Tratamento Clínico',
  //   procedimento: '',
  //   tipo: 'sanitario',
  //   identificador: 'tratamento-clinico',
  //   qtd: 0
  // },
  {
    icon: 'checkmark-circle',
    nome: 'Diagnóstico',
    procedimento: '',
    tipo: 'sanitario',
    cor: 'laranja',
    identificador: 'diagnostico',
    qtd: 0,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-diagnostico'
  },
  {
    icon: 'sanitario-medicacao',
    nome: 'Medicação',
    procedimento: '',
    tipo: 'sanitario',
    cor: 'laranja',
    identificador: 'medicacao',
    qtd: 0,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-medicacao'
  },
  {
    icon: 'seringa',
    nome: 'Vacinação',
    procedimento: '',
    tipo: 'sanitario',
    cor: 'laranja',
    identificador: 'vacinacao',
    qtd: 0,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-vacinacao'
  },
  {
    icon: 'sanitario-vermifugacao',
    nome: 'Vermifugação',
    procedimento: '',
    tipo: 'sanitario',
    cor: 'laranja',
    identificador: 'vermifugacao',
    qtd: 0,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-vermifugacao'
  },
  {
    icon: 'sanitario-banho',
    nome: 'Banho',
    procedimento: '',
    tipo: 'sanitario',
    cor: 'laranja',
    identificador: 'banho',
    qtd: 0,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-banho'
  },
  {
    icon: 'sanitario-antiparasitario',
    nome: 'Antiparasitários',
    procedimento: '',
    tipo: 'sanitario',
    cor: 'laranja',
    identificador: 'antiparasitarios',
    qtd: 0,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-antiparasitario'
  },
  {
    icon: 'add-circle',
    nome: 'Outros',
    procedimento: '',
    tipo: 'sanitario',
    cor: 'laranja',
    identificador: 'outros',
    qtd: 0,
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-outros'
  }
]

const listaManejosLotes: ListaManejosProp[] = [
  {
    icon: 'warning',
    nome: 'Observação',
    procedimento: 'observacao',
    tipo: 'manejosLotes',
    cor: 'azul',
    identificador: 'observacao',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'observacao-lotes'
  },
  {
    icon: 'leaf',
    nome: 'Manejo Nutricional',
    procedimento: 'nutricao',
    tipo: 'nutricao',
    cor: 'azul',
    identificador: 'nutricao',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'nutricional-lotes'
  },
  {
    icon: 'cerca',
    nome: 'Movimentação entre Áreas',
    procedimento: 'movimentacao-entre-areas',
    tipo: 'lote',
    cor: 'azul',
    identificador: 'movimentacao-entre-areas',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'movimentacao-entre-areas'
  },
  {
    icon: 'escore-fezes',
    nome: 'Escore de Fezes',
    procedimento: 'escore-fezes',
    tipo: 'manejosLotes',
    cor: 'azul',
    identificador: 'escore-fezes',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'escore-fezes'
  },
  {
    icon: 'sanitario-estoque',
    nome: 'Itens da Farmácia',
    procedimento: 'sanitarioEstoque',
    tipo: 'sanitarioEstoque',
    cor: 'laranja',
    identificador: 'sanitarioEstoque',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-estoque-lotes'
  },
]

const listaManejosAreas: ListaManejosProp[] = [
  {
    icon: 'warning',
    nome: 'Observação',
    procedimento: 'observacao',
    tipo: 'areas',
    cor: 'azul',
    identificador: 'observacao',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'observacao-areas'
  },
  {
    icon: 'leaf',
    nome: 'Manejo Nutricional',
    procedimento: 'nutricao',
    tipo: 'nutricao',
    cor: 'azul',
    identificador: 'nutricao',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'nutricional-areas'
  },
  {
    icon: 'rainy',
    nome: 'Pluviometria',
    procedimento: 'chuva',
    tipo: 'areas',
    cor: 'azul',
    identificador: 'chuva',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'pluviometria'
  },
  {
    icon: 'sanitario-estoque',
    nome: 'Itens da Farmácia',
    procedimento: 'sanitarioEstoque',
    tipo: 'sanitarioEstoque',
    cor: 'laranja',
    identificador: 'sanitarioEstoque',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'sanitario-estoque-areas'
  },
  {
    icon: 'altura-capim',
    nome: 'Altura de Capim',
    procedimento: 'altura-capim',
    tipo: 'areas',
    cor: 'verde',
    identificador: 'altura-capim',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'altura-capim'
  },
  {
    icon: 'swap-horizontal',
    nome: 'Pastejo Rotacionado',
    procedimento: 'pastejo-rotacionado',
    tipo: 'areas',
    cor: 'verde',
    identificador: 'pastejo-rotacionado',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'pastejo-rotacionado'
  }
]

const listaManejosGenetica: ListaManejosProp[] = [
  {
    icon: 'genetica-aprumos',
    nome: 'Aprumos',
    procedimento: 'aprumos',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'aprumos',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'aprumos'
  },
  {
    icon: 'genetica-boca',
    nome: 'Boca',
    procedimento: 'boca',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'boca',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'boca'
  },
  {
    icon: 'genetica-cascos',
    nome: 'Casco',
    procedimento: 'casco',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'casco',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'casco'
  },
  {
    icon: 'genetica-chanfro',
    nome: 'Chanfro',
    procedimento: 'chanfro',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'chanfro',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'chanfro'
  },
  {
    icon: 'perimetro-escrotal',
    nome: 'Perímetro Escrotal',
    procedimento: 'perimetro-escrotal',
    tipo: 'genetica',
    cor: 'preto',
    sexo: 'macho',
    identificador: 'perimetro-escrotal',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'perimetro-escrotal'
  },
  {
    icon: 'genetica-frame',
    nome: 'Frame',
    procedimento: 'frame',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'frame',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'frame'
  },
  {
    icon: 'genetica-garupa',
    nome: 'Garupa',
    procedimento: 'garupa',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'garupa',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'garupa'
  },
  {
    icon: 'genetica-cauda',
    nome: 'Inserção de Cauda',
    procedimento: 'insercao-cauda',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'insercao-cauda',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'insercao-cauda'
  },
  {
    icon: 'genetica-dorso',
    nome: 'Linha Dorso',
    procedimento: 'linha-dorso',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'linha-dorso',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'linha-dorso'
  },
  {
    icon: 'genetica-musculosidade',
    nome: 'Musculosidade',
    procedimento: 'musculosidade',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'musculosidade',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'musculosidade'
  },
  {
    icon: 'genetica-ossatura',
    nome: 'Ossatura',
    procedimento: 'ossatura',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'ossatura',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'ossatura'
  },
  {
    icon: 'genetica-pelagem',
    nome: 'Pelagem',
    procedimento: 'pelagem',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'pelagem',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'pelagem'
  },
  {
    icon: 'genetica-profundidade',
    nome: 'Profundidade',
    procedimento: 'profundidade',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'profundidade',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'profundidade'
  },
  {
    icon: 'genetica-racial',
    nome: 'Racial',
    procedimento: 'racial',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'racial',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'racial'
  },
  {
    icon: 'monta-natural',
    nome: 'Reprodução',
    procedimento: 'reproducao',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'reproducao',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'reproducao'
  },
  {
    icon: 'genetica-temperamento',
    nome: 'Temperamento',
    procedimento: 'temperamento',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'temperamento',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'temperamento'
  },
  {
    icon: 'genetica-torcao-testicular',
    nome: 'Torção Testicular',
    procedimento: 'torcao-testicular',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'torcao-testicular',
    sexo: 'macho',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'torcao-testicular'
  },
  {
    icon: 'leiteiro',
    nome: 'Úbere',
    procedimento: 'ubere',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'ubere',
    sexo: 'femea',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'ubere'
  },
  {
    icon: 'genetica-umbigo',
    nome: 'Umbigo',
    procedimento: 'umbigo',
    tipo: 'genetica',
    cor: 'preto',
    identificador: 'umbigo',
    fertiliCampo: true,
    fertiliCampoIdentificador: 'umbigo'
  },
]

export const listaManejosDisponiveis = {
  manejos: listaManejos,
  manejosReprodutivos: listaManejosReprodutivos,
  manejosSanitarios: listaManejosSanitarios,
  manejosLotes: listaManejosLotes,
  manejosAreas: listaManejosAreas,
  manejosGenetica: listaManejosGenetica
}
