import { Injectable } from '@angular/core'
import { QualitasUtils } from './qualitas-utils'
import { IDadosAnimaisQualitas, IDadosMedicoesQualitas, IDadosPedigreeQualitas, IEnviarDadosMedicoesQualitas, IGetDadosQualitas } from '../utils/interfaces/animais.interface'
import { AlertController, ModalController } from '@ionic/angular'
import * as moment from 'moment'
import { PropriedadesService } from './propriedades.service'
import { ApiService } from './api/api.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'
import { ModalPropriedadePage } from '../pages/geral/modal-propriedade/modal-propriedade.page'
import { IPropriedade } from '../utils/interfaces/propriedades.interface'
import { IonicUtilsService } from './utils/ionic-utils.service'

type QualitasProprietario = {
  nomeCriador: string
  codCriador: string
  nomeProprietario: string
  codProprietario: string
}

@Injectable({
  providedIn: 'root'
})
export class QualitasService {
  constructor(
    private alertCtrl: AlertController,
    private propriedadesCtrl: PropriedadesService,
    private apiCtrl: ApiService,
    private modalCtrl: ModalController,
    private utilsCtrl: IonicUtilsService,
    private permissoesCtrl: PermissoesUsuarioService
  ) {}

  async getOutrosDadosQualitas(opcoes?: { hashs?: string[], filtros? }): Promise<[IGetDadosQualitas?, Error?]> {
    let url = 'animais/qualitas'
    const { hashs, filtros } = opcoes || { hashs: [] }

    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'leitura')

      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url,
        body: {
          hashs,
          filtros
        }
      })) as IGetDadosQualitas

      if (response) return [response]
    } catch (error) {
      return [null, new Error(error)]
    }
  }

  async editarPropriedade(propriedadeSelecionada: IPropriedade): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalPropriedadePage,
      cssClass: 'custom-modal',
      componentProps: { propriedadeProp: propriedadeSelecionada }
    })
    await modal.present()
    await modal.onDidDismiss()
  }

  async showAlertErroRaca(racas: string[]): Promise<void> {
    const header = racas.length > 1 ? 'Raças indisponíveis' : 'Raça indisponível'
    const message =
      racas.length > 1
        ? `As raças ${racas.join(', ')} não constam na lista de raças da Qualitas. Elas adicionadas à exportação como 'XX'.`
        : `A raça ${racas[0]} não consta na lista de raças da Qualitas. Ela será adicionada à exportação como 'XX'.`
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    })

    await alert.present()

    await alert.onDidDismiss()
  }

  async showAlertErroUsuarioQualitas(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Atenção',
      message: 'Existem dados não preenchidos sobre o criador e/ou proprietário',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: async (): Promise<void> => {
            throw new Error('Usuário não preencheu dados do criador e/ou proprietário')
          }
        },
        {
          text: 'Adicionar',
          handler: async (): Promise<void> => {
            await this.editarPropriedade(this.propriedadesCtrl.propriedadeSelecionada)
          }
        }
      ]
    })

    await alert.present()

    await alert.onDidDismiss()
  }

  async getDadosDoProprietario(): Promise<QualitasProprietario> {
    const {
      qualitas_nome_fornecedor: nomeCriador,
      qualitas_codigo_criador: codCriador,
      qualitas_nome_proprietario: nomeProprietario,
      qualitas_codigo_proprietario: codProprietario
    } = this.propriedadesCtrl?.propriedadeSelecionada?.metadados || {}

    if (!nomeCriador || !codCriador || !nomeProprietario || !codProprietario) {
      await this.showAlertErroUsuarioQualitas()
      await this.propriedadesCtrl.getPropriedades()
    }

    return {
      nomeCriador: this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.qualitas_nome_fornecedor,
      codCriador: this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.qualitas_codigo_criador,
      nomeProprietario: this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.qualitas_nome_proprietario,
      codProprietario: this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.qualitas_codigo_proprietario
    }
  }

  async exportacaoQualitas(opcoes?: { hashs?: string[], filtros? }): Promise<void> {
    const { hashs, filtros } = opcoes || { hashs: [] }

    const usuarioQualitas = await this.getDadosDoProprietario()
    
    const loading = await this.utilsCtrl.showLoading('circular', 'Buscando dados...')
    const [dados, erro] = await this.getOutrosDadosQualitas({ hashs, filtros })
    this.utilsCtrl.dismissLoading(loading)
    if (erro) {
      return
    }

    const { animais, pedigree, medicoes } = dados

    // const racasIndisponiveis: string[] = []
    // animais.map(async (animal) => {
    //   if (QualitasUtils.racasQueNaoConstam.includes(animal.raca) && !racasIndisponiveis.includes(Racas[animal.raca])) {
    //     racasIndisponiveis.push(Racas[animal.raca])
    //   }
    // })
    // if (racasIndisponiveis.length) {
    //   await this.showAlertErroRaca(racasIndisponiveis)
    // }

    await this.exportarAnimaisQualitas(animais, usuarioQualitas)
    await this.exportarMedicoesQualitas(medicoes)
    await this.exportarPedigree(usuarioQualitas, pedigree)
  }
  
  async exportarAnimaisQualitas(animais: IDadosAnimaisQualitas[], usuarioQualitas: QualitasProprietario): Promise<void> {
    animais.map((animal) => {
      animal.criador = usuarioQualitas.nomeCriador || ''
      animal.codCriador = usuarioQualitas.codCriador || ''
      animal.proprietario = usuarioQualitas.nomeProprietario || ''
      animal.codProprietario = usuarioQualitas.codProprietario || ''
    })

    const dadosTodosAnimais = []

    animais.map((props) => {
      for (let prop in props) {
        const valor = String(props[prop] || '')
        const tamanho = QualitasUtils.qtdCaracteres[prop]
        props[prop] = valor.length > tamanho ? valor.substring(0, tamanho) : valor.padEnd(tamanho, ' ')
      }

      dadosTodosAnimais.push(Object.values(props).join(''))
    })

    const dadosTodosAnimaisString = dadosTodosAnimais.join('\n') + '\r '
    this.baixarArquivosQualitas(dadosTodosAnimaisString, 'Animais')
  }

  async exportarPedigree(proprietario: QualitasProprietario, dadosExportacaoPedigreeQualitas: IDadosPedigreeQualitas[]): Promise<void> {
    dadosExportacaoPedigreeQualitas.map((animal) => {
      animal.criador = proprietario.nomeCriador || ''
      animal.codCriador = proprietario.codCriador || ''
      animal.proprietario = proprietario.nomeProprietario || ''
      animal.codProprietario = proprietario.codProprietario || ''
    })

    const dadosTodosPedigree = []

    dadosExportacaoPedigreeQualitas.map((props) => {
      for (let prop in props) {
        const valor = String(props[prop] || '')
        const tamanho = QualitasUtils.qtdCaracteres[prop]
        props[prop] = valor.length > tamanho ? valor.substring(0, tamanho) : valor.padEnd(tamanho, ' ')
      }

      dadosTodosPedigree.push(Object.values(props).join(''))
    })

    const dadosTodosPedigreeString = dadosTodosPedigree.join('\n') + '\r '
    this.baixarArquivosQualitas(dadosTodosPedigreeString, 'Pedigree')
  }

  async exportarMedicoesQualitas(medicoes: IDadosMedicoesQualitas[]): Promise<void> {
    const dadosExportacaoMedicoesQualitas: IEnviarDadosMedicoesQualitas[] = []

    medicoes.map((medicao) => {
      dadosExportacaoMedicoesQualitas.push({
        idInternoAnimal: String(medicao.id_animal || ''),
        numeroAnimal: medicao.numeracao || '',
        dataMedida: moment(medicao.data_medicao).format('DDMMYYYY') || '',
        valor: medicao.valor || '',
        tipoMedida: QualitasUtils.codigosMedicoes[medicao.procedimento],
        propriedade: medicao.propriedade || '',
        gmanejo: String(medicao.id_lote || ''),
      })
    })

    const dadosTodosMedicoes = []

    dadosExportacaoMedicoesQualitas.map((props) => {
      for (let prop in props) {
        const valor = String(props[prop] || '')
        const tamanho = QualitasUtils.qtdCaracteresMedicoes[prop]
        props[prop] = valor.length > tamanho ? valor.substring(0, tamanho) : valor.padEnd(tamanho, ' ')
      }

      dadosTodosMedicoes.push(Object.values(props).join(''))
    })

    const dadosTodosMedicoesString = dadosTodosMedicoes.join('\n') + '\r '
    this.baixarArquivosQualitas(dadosTodosMedicoesString, 'Medicoes')
  }



  baixarArquivosQualitas(dados: string, titulo: string): void {
    const blob = new Blob([dados], { type: 'text/plain' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    const dataAtual = moment().format('YYYYMMDD_HHmmss')
    link.download = `ExpQualitas_${titulo}_${dataAtual}.txt`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }
}
