import { Component, OnInit } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { AreasService } from 'src/app/services/areas.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { IDadosPluviometricos } from 'src/app/utils/interfaces/areas.interface'

@Component({
  selector: 'app-modal-lancamento-pluviometrico',
  templateUrl: './modal-lancamento-pluviometrico.component.html',
  styleUrls: ['./modal-lancamento-pluviometrico.component.scss']
})
export class ModalLancamentoPluviometricoComponent implements OnInit {
  public propDadosPluviometricos: IDadosPluviometricos
  public dadosPluviometricos: IDadosPluviometricos
  public salvando = false

  constructor(
    private utilsCtrl: IonicUtilsService,
    private areasService: AreasService,
    private modalCtrl: ModalController,
    private propriedadesService: PropriedadesService,
    private alertCtrl: AlertController
  ) {}

  ngOnInit(): void {
    if (!this.propDadosPluviometricos.id_propriedade) {
      this.propDadosPluviometricos.id_propriedade = this.propriedadesService.propriedadeSelecionada?.id
    }
    if (this.propDadosPluviometricos) {
      this.propDadosPluviometricos.hora = this.formatTime(this.propDadosPluviometricos.hora)
      this.dadosPluviometricos = JSON.parse(JSON.stringify(this.propDadosPluviometricos))
    }
  }

  async enviarDadosPluviometricos(): Promise<void> {
    this.salvando = true
    const [response, error] = await this.areasService.lancamentoPluviometrico(this.dadosPluviometricos)

    if (response) {
      console.log('Lançamento salvo com sucesso')
    }

    this.salvando = false

    if (error) {
      console.error(error)
      return this.utilsCtrl.showToast('Erro ao salvar lançamento pluviométrico', 'top')
    }

    this.closeModal()
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  formatTime(value: string): string {
    if (value) return `0${value}`.slice(-5)
    else return ''
  }

  async alertExcluirLancamento(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Remover Lançamento',
      message: 'Confirmar remoção desse lançamento?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Remover',
          handler: async (): Promise<void> => {
            this.excluirDadosPluviometricos()
          }
        }
      ]
    })

    await alert.present()
  }

  async excluirDadosPluviometricos(): Promise<void> {
    const payload = { ...this.dadosPluviometricos, indice: '0', hora: null }

    const [response, error] = await this.areasService.lancamentoPluviometrico(payload)

    if (response) {
      console.log('Lançamento excluído com sucesso')
    }

    if (error) {
      console.error(error)
      return this.utilsCtrl.showToast('Erro ao excluír lançamento pluviométrico', 'top')
    }

    this.closeModal()
  }
}
