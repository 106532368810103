<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="!isModal">
      <ion-back-button defaultHref="/animais"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Importar Animais
    </ion-title>
    <ion-buttons slot="end" *ngIf="isModal">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="opcoes-toolbar">
    <vasta-input
      type="checkbox"
      label="Ativar Composição Racial"
      [(valueCheckbox)]="ativarComposicao"
      (valueCheckboxChange)="initTabela()"
    ></vasta-input>
  </div>
  <div #spreadsheet></div>
</ion-content>

<ion-footer>
  <cta-buttons>
    <button class="primary permissao-escrita-animais" (click)="salvarAnimais()">
      <ion-icon name="checkmark"></ion-icon>
      <span>Cadastrar</span>
    </button>
  </cta-buttons>

  <cta-buttons>
    <button class="secondary" (click)="adicionardadosLinhas()">
      <ion-icon name="list"></ion-icon>
      <span class="hideMobile">Adicionar números</span>
      <span class="hideDesktop">Números...</span>
    </button>

    <ng-template [ngIf]="linhasDisponiveisParaAdicionar">
      <button [disabled]="!adicionarMais" (click)="adicionarMaisItens()">
        <ion-icon name="add-circle"></ion-icon>
        <span class="hideMobile">Inserir</span>
        <span class="hideDesktop">10 linhas</span>
      </button>
      <input [(ngModel)]="adicionarMais" class="linhas hideMobile" />
      <span class="label hideMobile">linhas</span>
    </ng-template>

    <ng-template [ngIf]="!linhasDisponiveisParaAdicionar">
      <span>Limite de vagas para cadastrar alcançado</span>
    </ng-template>
  </cta-buttons>
</ion-footer>
