<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Pessoas e Empresas</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <vasta-tabela
    [labels]="colunasPessoas"
    [campos]="listaPessoas"
    [config]="acontecimentosConfig"
    [status]="statusCarregamentoPessoas"
    [(novoFiltro)]="filtroPessoas"
    [(novaOrdenacao)]="ordenacaoPessoas"
    [(paginate)]="paginate"
    (paginateChange)="paginateChange()"
    (novoFiltroChange)="alterouFiltrosPessoas()"
    (selecionado)="selecionarPessoa($event)"
    (retentar)="getPessoas()"
  >
  </vasta-tabela>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <cta-buttons>
      <button class="ion-float-left" (click)="abrirModalParaCadastrarPessoa()">
        <ion-icon name="add-circle"></ion-icon>
        <span>Cadastrar Pessoa</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
