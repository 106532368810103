/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApplicationRef, EventEmitter, Injectable } from '@angular/core'
import { LoadingController, ModalController, PopoverController, ToastButton, ToastController } from '@ionic/angular'
import { ComponentRef, SpinnerTypes } from '@ionic/core'
import { PopoverConfirmacaoComponent } from 'src/app/components/popover-confirmacao/popover-confirmacao.component'

@Injectable({
  providedIn: 'root'
})
export class IonicUtilsService {
  constructor(
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private popoverCtrl: PopoverController,
    private ref: ApplicationRef
  ) {}

  async showToast(
    message: string,
    position: 'top' | 'bottom' | 'middle' = 'bottom',
    color?: string,
    duration = 3000
  ): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      position,
      color,
      duration
    })
    await toast.present()
  }

  async newToast(
    opcoes: {
      message: string
      position?: 'top' | 'bottom' | 'middle'
      color?: string
      duration?: number
      buttons?: (string | ToastButton)[]
    }
  ): Promise<void> {
    const { message, position, color, duration, buttons } = opcoes
    const toast = await this.toastCtrl.create({
      message,
      position: position || 'bottom',
      color: color || '',
      duration: duration || 5000,
      buttons: buttons || null
    })
    await toast.present()
  }

  async popoverConfirma(
    ev
  ): Promise<boolean> {
    return new Promise(async (callback) => {
      const popover = await this.popoverCtrl.create({
        component: PopoverConfirmacaoComponent,
        event: ev,
        reference: 'event'
      })

      await popover.present()
      const { data } = await popover.onWillDismiss()
      if (data) {
        callback(!!data.confirmar)
      } else {
        callback(false)
      }
    })
  }

  async showModal(
    component: ComponentRef,
    cssClass: string,
    output?: EventEmitter<unknown>,
    componentProps?: unknown,
    backdropDismiss = false
  ): Promise<void> {
    const modal = await this.modalCtrl.create({
      component,
      cssClass,
      componentProps,
      backdropDismiss
    })
    await modal.present()

    const { data } = await modal.onDidDismiss()

    if (data) output.emit(data)
  }

  async dismissModal(data?: unknown): Promise<void> {
    await this.modalCtrl.dismiss(data)
  }

  async showLoading(
    spinner: SpinnerTypes,
    message?: string,
    duration?: number,
    backdropDismiss = false
  ): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingCtrl.create({
      spinner,
      message,
      duration,
      backdropDismiss
    })
    await loading.present()
    return loading
  }

  async dismissLoading(loading?: HTMLIonLoadingElement): Promise<void> {
    if (loading) {
      await loading.dismiss()
    } else {
      await this.loadingCtrl.dismiss()
    }
  }

  tickTimeout = null
  tick(): void {
    clearTimeout(this.tickTimeout)

    this.tickTimeout = setTimeout(() => {
      this.ref.tick()
    }, 200)
  }

  cloneObject(object: any): any {
    return JSON.parse(JSON.stringify(object))
  }
}
