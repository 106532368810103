<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Atualizações</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="atualizacoes-container">
    <ng-template ngFor let-versao [ngForOf]="versionamento">
      <h3>v{{versao.versao}}<span> - {{versao.data | timestamp: 'data' }}</span></h3>
      <div class="texto" *ngIf="versao.descricao" [innerHTML]="versao.descricao"></div>
      <ul>
        <ng-template ngFor let-change [ngForOf]="versao.changelog">
          <li><span>{{change}}</span></li>
        </ng-template>
      </ul>
    </ng-template>
  </div>
</ion-content>
