import { Component, Input, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { IAreaPiquete } from 'src/app/utils/interfaces/areas.interface'

@Component({
  selector: 'app-popover-lista-piquetes',
  templateUrl: './popover-lista-piquetes.component.html',
  styleUrls: ['./popover-lista-piquetes.component.scss']
})
export class PopoverListaPiquetesComponent implements OnInit {

  @Input() piquetes: string[] = []
  @Input() piquetesAssociados: {[key: string]: IAreaPiquete} = {}
  @Input() mensagem: string
  @Input() showNenhum: boolean = false
  @Input() popoverCheck: boolean = false

  piqueteSelecionado: string | null = null

  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit(): void {
    const [filteredObjects] = Object.values(this.piquetesAssociados)
      .filter(obj => this.piquetes.includes(obj.hash))
      .filter(obj => obj.piquete_selecionado_pastejo_rotacionado === true)
  
    this.piqueteSelecionado = filteredObjects ? filteredObjects.hash : null
  }


  async selecionarPiquete(piqueteHash: string): Promise<void> {
    if (this.popoverCheck) {
      this.piqueteSelecionado = piqueteHash
    } 
    await this.popoverCtrl.dismiss(piqueteHash)
  }
}