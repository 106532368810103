import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TimestampPipe } from './timestamp.pipe'

@NgModule({
  declarations: [TimestampPipe],
  imports: [CommonModule],
  exports: [TimestampPipe]
})
export class PipesModule {}
