<main *ngIf="coluna && !ordenacao; else categorias">
  <div class="container-button-back" (click)="voltar()" *ngIf="!inlineProp">
    <ion-icon name="chevron-back" color="primary"></ion-icon>
    <span>Filtros</span>
  </div>

  <!-- Tipo Preenchidos -->
  <div class="container-buttons" *ngIf="coluna.tipoFiltro === 'preenchidos'">
    <button *ngFor="let opcao of opcoes" (click)="selecionarOpcao(opcao)">
      <span *ngIf="opcoesFiltros[coluna.labelFormat]">{{ opcoesFiltros[coluna.labelFormat][opcao] }}</span>
      <span *ngIf="!opcoesFiltros[coluna.labelFormat]">{{ formatarLabelOpcoesExtrasFiltros(opcao) }}</span>
    </button>
  </div>

  <!-- Tipo Preenchidos Múltiplos-->
  <div class="container-buttons" *ngIf="coluna.tipoFiltro === 'preenchidosMultiplos'">
    <button *ngFor="let opcao of opcoesCheckBox" (click)="opcao.isChecked = !opcao.isChecked">
      <ion-checkbox [ngModel]="opcao.isChecked"></ion-checkbox>
      <div class="conteudo">
        <span *ngIf="opcoesFiltros[coluna.labelFormat]">{{ opcoesFiltros[coluna.labelFormat][opcao.valor] }}</span>
        <span *ngIf="!opcoesFiltros[coluna.labelFormat]">{{ formatarLabelOpcoesExtrasFiltros(opcao.valor) }}</span>
      </div>
    </button>
  </div>

  <!-- Tipo Texto -->
  <vasta-input
    [label]="coluna.titulo"
    [(value)]="inputTextValue"
    *ngIf="coluna.tipoFiltro === 'texto'"
    [focus]="focus"
    (enter)="confirmar()"
  ></vasta-input>

  <!-- Tipo Número-->
  <div class="container-inputs" *ngIf="coluna.tipoFiltro === 'rangeNumeros'">
    <b class="container-title">{{coluna.titulo}}</b>
    <vasta-input
      [label]="'De'"
      [(value)]="inputRangeDeValue"
      type="number"
      mask="separator.2"
      [allowNegativeNumbers]="true"
      [focus]="focus"
    ></vasta-input>
    <vasta-input
      [label]="'Até'"
      [(value)]="inputRangeAteValue"
      mask="separator.2"
      type="number"
      [allowNegativeNumbers]="true"
      (enter)="confirmar()"
    ></vasta-input>
  </div>

  <!-- Botão Limpar/Confirmar -->
  <div
    class="container-button-confirmar"
    *ngIf="['texto', 'rangeNumeros', 'preenchidosMultiplos'].includes(coluna.tipoFiltro)"
  >
    <cta-buttons>
      <button
        class="primary"
        (click)="confirmar()"
        [disabled]="coluna.tipoFiltro === 'rangeNumeros' && !isCamposRangeNumerosPreenchidos()"
      >
        <ion-icon name="checkmark"></ion-icon>
        <span>Confirmar</span>
      </button>
      <button
        class="danger-clear"
        (click)="limparCampos()"
        *ngIf="filtros[coluna.chave] && (inputTextValue || inputRangeDeValue || inputRangeAteValue)"
      >
        <ion-icon name="trash"></ion-icon>
        <span>Limpar</span>
      </button>
    </cta-buttons>
  </div>
</main>

<ion-content>
<ng-template #categorias>
  <main>
    <div class="container-buttons">
      <button *ngFor="let categoria of colunas" (click)="selecionarCategoria($event, categoria.chave)">
        {{ categoria?.titulo }}
      </button>
    </div>
  </main>
</ng-template>
</ion-content>
