<div class="container-lista">
  <button (click)="confirmar()">
    <ion-icon color="primary" name="checkmark-circle"></ion-icon>
    <div class="conteudo">
      <span>Confirmar</span>
    </div>
  </button>
  <button (click)="cancelar()">
    <ion-icon color="danger" name="close-circle"></ion-icon>
    <div class="conteudo">
      <span>Cancelar</span>
    </div>
  </button>
</div>
