import { Injectable } from '@angular/core'
import { ListaManejosProp } from '../pages/manejos/manejos-disponiveis/manejos-disponiveis.component'
import { EstoqueFarmacia, EstoqueNutricional } from '../utils/interfaces/estoque.interface'
import {
  IManejoGeralUnitario,
  IManejoLote,
  ManejoOrdenha,
  ManejoPesagem,
  ManejoReprodutivoUnitario,
  ManejoSanitarioUnitario,
  ManejosService
} from './manejos.service'
import { ManejoArea } from '../utils/interfaces/manejos.interface'

@Injectable({
  providedIn: 'root'
})
export class ManejosUtilsService {
  constructor(private manejosCtrl: ManejosService) {}

  calcularResumosAnimaisHandle(opcoes: {
    estoqueNutricional: EstoqueNutricional[],
    estoqueSanitario: EstoqueFarmacia[],
    manejosDisponiveis: ListaManejosProp[]
    dadosManejosAnimais: {
      [key: string]: {
        hash: string
        nutricao?: { [key: string]: { quantidade: number; duracao: number; edited: boolean } }
        sanitarioEstoque?: { [key: string]: { quantidade: number; edited: boolean } }
        pesagem?: {
          pesagem?: ManejoPesagem
        }
        ordenha?: {
          ordenha?: ManejoOrdenha
        }
        reprodutivo?: {
          [key: string]: ManejoReprodutivoUnitario
        }
        sanitario?: {
          [key: string]: ManejoSanitarioUnitario
        }
        geral?: {
          [key: string]: IManejoGeralUnitario
        }
      }
    }
  }): {
    resumos: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } }
  } {
    const { manejosDisponiveis, dadosManejosAnimais, estoqueNutricional, estoqueSanitario } = opcoes

    const resumos: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } } = {}

    this.manejosCtrl.campoManejoAlterado = false
    manejosDisponiveis.map((m) => {
      if (m.procedimento == 'pesagem') {
        const totais: { chave: string; valor: unknown }[] = []

        let pesoTotal = 0
        let animaisPesados = 0
        let gmdSoma = 0
        let animaisComGMD = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['pesagem']?.pesagem.peso) {
            animaisPesados++
            pesoTotal += Number(d?.['pesagem']?.pesagem.peso || '0')
            if (d?.['pesagem']?.pesagem.gmd) {
              animaisComGMD++
              gmdSoma += Number(d?.['pesagem']?.pesagem.gmd || '0')
            }
          }
        })

        totais.push({ chave: 'Total', valor: `${pesoTotal.toFixed(1).replace('.', ',').replace(',0', '')} kg` })
        // totais.push({ chave: 'Animais Pesados', valor: animaisPesados })

        if (animaisPesados > 1) {
          totais.push({
            chave: 'Média',
            valor: `${(pesoTotal / animaisPesados).toFixed(1).replace('.', ',').replace(',0', '')} kg`
          })
        }

        if (animaisComGMD > 1) {
          totais.push({
            chave: 'GMD Médio',
            valor: `${(gmdSoma / animaisPesados).toFixed(3).replace('.', ',').replace(',000', '')} kg`
          })
        }

        resumos['pesagem'] = {
          qtd: animaisPesados,
          totais
        }
      }

      if (m.procedimento == 'nutricao') {
        const totais: { chave: string; valor: unknown }[] = []
        const estoquesPorHash = {}
        estoqueNutricional.map((a) => {
          estoquesPorHash[a.hash] = a.extra_custo_na_data
        })

        let producaoTotal = 0
        let manejados = 0
        let custoTotal = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const dados = d?.nutricao || {}
          let preenchido = false
          Object.keys(dados).map((hash) => {
            const a = dados[hash]
            if (a.quantidade) {
              preenchido = true
              producaoTotal += Number(a.quantidade)
              if (estoquesPorHash[hash]) {
                custoTotal += estoquesPorHash[hash] * a.quantidade
              }
            }
          })
          if (preenchido) {
            manejados++
          }
        })

        totais.push({ chave: 'Total', valor: `${producaoTotal.toFixed(1).replace('.', ',')} kg` })
        totais.push({ chave: 'Custo Total', valor: `R$ ${custoTotal.toFixed(2).replace('.', ',')}` })

        resumos['nutricao'] = {
          qtd: manejados,
          totais
        }
      }

      if (m.procedimento == 'sanitarioEstoque') {
        const totais: { chave: string; valor: unknown, qtd?: number }[] = []
        const custoPorHash = {}
        const nomePorHash = {}
        estoqueSanitario.map((a) => {
          custoPorHash[a.hash] = a.extra_custo_na_data
          nomePorHash[a.hash] = a.titulo
        })

        const qtdECustoPorItem: { [key: string]: { qtd: number, custo: number, nome: string } } = {}

        let producaoTotal = 0
        let manejados = 0
        let custoTotal = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const dados = d?.sanitarioEstoque || {}
          let preenchido = false
          Object.keys(dados).map((hash) => {
            const a = dados[hash]
            if (a.quantidade) {

              preenchido = true
              producaoTotal += Number(a.quantidade)
              if (custoPorHash[hash]) {
                // atribui ao total
                custoTotal += custoPorHash[hash] * a.quantidade

                // atribui ao item individual
                qtdECustoPorItem[hash] = qtdECustoPorItem[hash] || { qtd: 0, custo: 0, nome: nomePorHash[hash] }
                qtdECustoPorItem[hash].custo += custoPorHash[hash] * a.quantidade
                qtdECustoPorItem[hash].qtd += 1
              }
            }
          })
          if (preenchido) {
            manejados++
          }
        })

        Object.keys(qtdECustoPorItem).map(hash => {
          totais.push({
            chave: qtdECustoPorItem[hash].nome,
            qtd: qtdECustoPorItem[hash].qtd,
            valor: `R$ ${qtdECustoPorItem[hash].custo.toFixed(2).replace('.', ',')}`
          })
        })

        totais.push({ chave: 'Qtd Total', valor: `${producaoTotal.toFixed(1).replace('.', ',')}` })
        totais.push({ chave: 'Custo Total', valor: `R$ ${custoTotal.toFixed(2).replace('.', ',')}` })

        resumos['sanitarioEstoque'] = {
          qtd: manejados,
          totais
        }
      }

      if (m.procedimento == 'ordenha') {
        const totais: { chave: string; valor: unknown }[] = []

        let producaoTotal = 0
        let vacasOrdenhadas = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const { primeira_ordenha, segunda_ordenha, terceira_ordenha } = d?.['ordenha']?.ordenha || {}
          if (primeira_ordenha || segunda_ordenha || terceira_ordenha) {
            vacasOrdenhadas++
            producaoTotal += Number(primeira_ordenha || '0')
            producaoTotal += Number(segunda_ordenha || '0')
            producaoTotal += Number(terceira_ordenha || '0')
          }
        })

        totais.push({ chave: 'Total', valor: `${producaoTotal.toFixed(3).replace('.', ',')} kg` })
        // totais.push({ chave: 'Vacas Ordenhadas', valor: vacasOrdenhadas })

        if (vacasOrdenhadas > 1) {
          totais.push({
            chave: 'Produção Média',
            valor: `${(producaoTotal / vacasOrdenhadas).toFixed(3).replace('.', ',')} kg`
          })
        }

        resumos['ordenha'] = {
          qtd: vacasOrdenhadas,
          totais
        }
      }

      if (m.procedimento == 'cio') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let positivos = 0
        let negativos = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['reprodutivo']?.cio.cio_resultado
          if (resultado) {
            animaisManejados++
            if (resultado == 'positivo') {
              positivos++
            }
            if (resultado == 'negativo') {
              negativos++
            }
          }
        })

        if (animaisManejados) {
          // totais.push({ chave: 'Detecções', valor: animaisManejados })
          totais.push({ chave: 'Positivo', valor: positivos })
          totais.push({ chave: 'Negativo', valor: negativos })
        }

        resumos['cio'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'dg') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let positivos = 0
        let negativos = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['reprodutivo']?.dg.dg_resultado
          if (resultado) {
            animaisManejados++
            if (resultado == 'positivo') {
              positivos++
            }
            if (resultado == 'negativo') {
              negativos++
            }
          }
        })

        if (animaisManejados) {
          // totais.push({ chave: 'Detecções', valor: animaisManejados })
          totais.push({ chave: 'Positivo', valor: positivos })
          totais.push({ chave: 'Negativo', valor: negativos })
        }

        resumos['dg'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'descarte') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let positivos = 0
        let negativos = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['reprodutivo']?.descarte.descarte_resultado
          if (resultado) {
            animaisManejados++
            if (resultado == 'realizado') {
              positivos++
            }
            if (resultado == 'nao-realizado') {
              negativos++
            }
          }
        })

        if (animaisManejados) {
          totais.push({ chave: 'Descartes', valor: positivos })
        }

        resumos['descarte'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'implante') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['reprodutivo']?.implante.implante_aplica
          if (resultado == 'realizado') {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Total', valor: animaisManejados })

        resumos['implante'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'implante-remocao') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['reprodutivo']?.['implante-remocao'].implante_remove
          if (resultado == 'realizado') {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Total', valor: animaisManejados })

        resumos['implante-remocao'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'inseminacao') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        const semensDiferentes = {}

        Object.values(dadosManejosAnimais).map((d) => {
          const semen =
            d?.['reprodutivo']?.['inseminacao'].inseminacao_semen ||
            d?.['reprodutivo']?.['inseminacao'].inseminacao_embriao
          if (semen) {
            animaisManejados++
            semensDiferentes[semen] = null
          }
        })

        totais.push({ chave: 'Doses', valor: animaisManejados })
        totais.push({ chave: 'Touros', valor: Object.keys(semensDiferentes).length })

        resumos['inseminacao'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'monta-natural') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let internoQtd = 0
        let externoQtd = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const interno = d?.['reprodutivo']?.['monta-natural'].cobertura_hash_reprodutor
          const externo = d?.['reprodutivo']?.['monta-natural'].cobertura_hash_reprodutor_externo
          if (interno || externo) {
            animaisManejados++
            if (interno) {
              internoQtd++
            }
            if (externo) {
              externoQtd++
            }
          }
        })

        totais.push({ chave: 'Vacas Expostas', valor: animaisManejados })
        totais.push({ chave: 'Touro da fazenda', valor: internoQtd })
        totais.push({ chave: 'Touro externo', valor: externoQtd })

        resumos['monta-natural'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'parto') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisNascidos = 0
        let natimortos = 0
        let animaisManejados = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const cria1 = d?.['reprodutivo']?.['parto'].parto_hash_cria1
          const cria2 = d?.['reprodutivo']?.['parto'].parto_hash_cria2

          if (cria1) {
            if (cria1 == 'natimorto') {
              natimortos++
            } else {
              animaisNascidos++
            }
          }
          if (cria2) {
            if (cria2 == 'natimorto') {
              natimortos++
            } else {
              animaisNascidos++
            }
          }

          const cria = d?.['reprodutivo']?.['parto'].parto_hash_cria1 || d?.['reprodutivo']?.['parto'].parto_hash_cria2
          if (cria) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Vacas', valor: animaisManejados })
        if (animaisNascidos) totais.push({ chave: 'Bezerros', valor: animaisNascidos })
        if (natimortos) totais.push({ chave: 'Natimortos', valor: natimortos })

        resumos['parto'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'desmame') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const cria = d?.['reprodutivo']?.['desmame']?.desmame_detalhes == 'realizado'
          if (cria) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Total', valor: animaisManejados })

        resumos['desmame'] = {
          qtd: animaisManejados,
          totais
        }
      }

      // aborto
      if (m.procedimento == 'aborto') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['reprodutivo']?.aborto?.aborto_detalhes
          if (resultado && resultado == 'realizado') {
            animaisManejados++
          }
        })

        if (animaisManejados) {
          // totais.push({ chave: 'Total', valor: animaisManejados })

          resumos['aborto'] = {
            qtd: animaisManejados,
            totais
          }
        }
      }

      if (m.procedimento == 'castracao') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['reprodutivo']?.castracao?.castracao_detalhes
          if (resultado && resultado == 'realizado') {
            animaisManejados++
          }
        })

        if (animaisManejados) {
          // totais.push({ chave: 'Total', valor: animaisManejados })

          resumos['castracao'] = {
            qtd: animaisManejados,
            totais
          }
        }
      }

      if (m.procedimento == 'escore') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['reprodutivo']?.escore?.escore_corporal) {
            animaisComEscore++
            escoreSoma += Number(d?.['reprodutivo']?.escore?.escore_corporal || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['escore'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.tipo == 'sanitario') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['sanitario']?.[m.procedimento]?.aplicado) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })
        resumos[m.procedimento] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'movimentacao-lote') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let lotesDiferentes = 0
        let lotesHashs = []

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['geral']?.['movimentacao-lote'].hash_lote
          if (resultado) lotesHashs.push(resultado)
          if (resultado) {
            animaisManejados++
          }
        })

        lotesHashs = [...new Set(lotesHashs)]
        lotesDiferentes = lotesHashs.length

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })
        totais.push({ chave: 'Lotes Diferentes', valor: lotesDiferentes })

        resumos['movimentacao-lote'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'numero-controle') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['geral']?.['numero-controle'].numero_novo
          if (resultado) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })

        resumos['numero-controle'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'numero-brinco-eletronico') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['geral']?.['numero-brinco-eletronico'].brinco_eletronico
          if (resultado) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })

        resumos['numero-brinco-eletronico'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'movimentacao-confinamento') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let lotesDiferentes = 0
        let lotesHashs = []

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.['geral']?.['movimentacao-confinamento'].hash_confinamento
          if (resultado) lotesHashs.push(resultado)
          if (resultado) {
            animaisManejados++
          }
        })

        lotesHashs = [...new Set(lotesHashs)]
        lotesDiferentes = lotesHashs.length

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })
        totais.push({ chave: 'Confinamentos', valor: lotesDiferentes })

        resumos['movimentacao-confinamento'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'perda') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisPerdidos = 0

        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['geral']?.['perda'].perda_observacoes === 'perdido') {
            animaisPerdidos++
          }
        })

        // totais.push({ chave: 'Animais Perdidos', valor: animaisPerdidos })

        resumos['perda'] = {
          qtd: animaisPerdidos,
          totais
        }
      }

      if (m.procedimento == 'secagem') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisEmSecagem = 0

        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['geral']?.['secagem'].secagem_observacoes === 'secagem' || d?.['geral']?.['secagem'].secagem_observacoes === 'secar') {
            animaisEmSecagem++
          }
        })

        resumos['secagem'] = {
          qtd: animaisEmSecagem,
          totais
        }
      }

      if (m.procedimento == 'morte') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisMortos = 0

        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['geral']?.['morte'].morte_observacoes == 'morto') {
            animaisMortos++
          }
        })

        // totais.push({ chave: 'Animais Mortos', valor: animaisMortos })

        resumos['morte'] = {
          qtd: animaisMortos,
          totais
        }
      }

      if (m.procedimento == 'transferencia-propriedade') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisMovidos = 0

        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['geral']?.['transferencia-propriedade'].novo_status === 'movido') {
            animaisMovidos++
          }
        })

        // totais.push({ chave: 'Animais Movidos', valor: animaisMovidos })

        resumos['transferencia-propriedade'] = {
          qtd: animaisMovidos,
          totais
        }
      }

      if (m.procedimento == 'venda') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisVendidos = 0

        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['geral']?.['venda'].novo_status === 'vendido') {
            animaisVendidos++
          }
        })

        // totais.push({ chave: 'Animais Vendidos', valor: animaisVendidos })

        resumos['venda'] = {
          qtd: animaisVendidos,
          totais
        }
      }

      if (m.procedimento == 'observacao') {
        const totais: { chave: string; valor: unknown }[] = []

        let observacao = 0

        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['geral']?.['observacao'].observacao !== '' && d?.['geral']?.['observacao'].observacao?.length > 0) {
            observacao++
          }
        })

        resumos['observacao'] = {
          qtd: observacao,
          totais
        }
      }

      if (m.procedimento == 'perimetro-escrotal') {
        const totais: { chave: string; valor: unknown }[] = []

        let perimetroSoma = 0
        let animaisComPerimetro = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['perimetro-escrotal']?.perimetro_escrotal) {
            animaisComPerimetro++
            perimetroSoma += Number(d?.['genetica']?.['perimetro-escrotal']?.perimetro_escrotal || '0')
          }
        })

        if (animaisComPerimetro) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComPerimetro })
          totais.push({ chave: 'Perimetro Médio', valor: (perimetroSoma / animaisComPerimetro).toFixed(1).replace('.', ',') })
          resumos['perimetro-escrotal'] = {
            qtd: animaisComPerimetro,
            totais
          }
        }
      }

      if (m.procedimento == 'aprumos') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['aprumos']?.aprumos) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['aprumos']?.aprumos || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['aprumos'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'boca') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['boca']?.boca) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['boca']?.boca || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['boca'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'casco') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['casco']?.casco) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['casco']?.casco || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['casco'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'chanfro') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['chanfro']?.chanfro) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['chanfro']?.chanfro || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['chanfro'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'frame') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['frame']?.frame) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['frame']?.frame || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['frame'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'garupa') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['garupa']?.garupa) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['garupa']?.garupa || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['garupa'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'insercao-cauda') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['insercao-cauda']?.insercao_cauda) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['insercao-cauda']?.insercao_cauda || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['insercao-cauda'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'linha-dorso') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['linha-dorso']?.linha_dorso) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['linha-dorso']?.linha_dorso || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['linha-dorso'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }


      if (m.procedimento == 'musculosidade') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['musculosidade']?.musculosidade) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['musculosidade']?.musculosidade || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['musculosidade'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'ossatura') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['ossatura']?.ossatura) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['ossatura']?.ossatura || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['ossatura'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'pelagem') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['pelagem']?.pelagem) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['pelagem']?.pelagem || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['pelagem'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'profundidade') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['profundidade']?.profundidade) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['profundidade']?.profundidade || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['profundidade'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'racial') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['racial']?.racial) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['racial']?.racial || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['racial'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'reproducao') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['reproducao']?.reproducao) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['reproducao']?.reproducao || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['reproducao'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }


      if (m.procedimento == 'temperamento') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['temperamento']?.temperamento) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['temperamento']?.temperamento || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['temperamento'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'torcao-testicular') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['torcao-testicular']?.torcao_testicular) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['torcao-testicular']?.torcao_testicular || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['torcao-testicular'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'ubere') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['ubere']?.ubere) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['ubere']?.ubere || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['ubere'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.procedimento == 'umbigo') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['genetica']?.['umbigo']?.umbigo) {
            animaisComEscore++
            escoreSoma += Number(d?.['genetica']?.['umbigo']?.umbigo || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['umbigo'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }
    })

    let dadosAlterados = false

    for (const hashAnimal in dadosManejosAnimais) {
      for (const tipo in dadosManejosAnimais[hashAnimal]) {
        if (tipo !== 'hash') {
          for (const procedimento in dadosManejosAnimais[hashAnimal][tipo]) {
            if (procedimento == 'nutricao') {
              if ((Object.values(dadosManejosAnimais[hashAnimal].nutricao) || []).filter((a) => !!a.edited).length) {
                dadosAlterados = true
              }
            } else {
              if (dadosManejosAnimais[hashAnimal][tipo][procedimento]?.edited) {
                dadosAlterados = true
              }
            }
          }
        }
      }
    }

    this.manejosCtrl.campoManejoAlterado = dadosAlterados

    return {
      resumos
    }
  }

  calcularResumosLotesHandle(opcoes: {
    estoqueNutricional: EstoqueNutricional[]
    estoqueSanitario: EstoqueFarmacia[]
    manejosDisponiveis: ListaManejosProp[]
    dadosManejosAnimais: {
      [key: string]: {
        hash: string
        nutricao?: { [key: string]: { quantidade: number; duracao: number; edited: boolean } }
        sanitarioEstoque?: { [key: string]: { quantidade: number; edited: boolean } }
        manejosLotes?: {
          [key: string]: IManejoLote
        }
      }
    }
  }): {
    resumos: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } }
  } {
    const { manejosDisponiveis, dadosManejosAnimais, estoqueNutricional, estoqueSanitario } = opcoes

    const resumos: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } } = {}

    this.manejosCtrl.campoManejoLotesAlterado = false
    manejosDisponiveis.map((m) => {
      if (m.procedimento == 'nutricao') {
        const totais: { chave: string; valor: unknown }[] = []
        const estoquesPorHash = {}
        estoqueNutricional.map((a) => {
          estoquesPorHash[a.hash] = a.extra_custo_na_data
        })

        let producaoTotal = 0
        let manejados = 0
        let custoTotal = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const dados = d?.nutricao || {}
          let preenchido = false
          Object.keys(dados).map((hash) => {
            const a = dados[hash]
            if (a.quantidade) {
              preenchido = true
              producaoTotal += Number(a.quantidade)
              if (estoquesPorHash[hash]) {
                custoTotal += estoquesPorHash[hash] * a.quantidade
              }
            }
          })
          if (preenchido) {
            manejados++
          }
        })

        totais.push({ chave: 'Total', valor: `${producaoTotal.toFixed(1).replace('.', ',')} kg` })
        totais.push({ chave: 'Custo Total', valor: `R$ ${custoTotal.toFixed(2).replace('.', ',')}` })

        resumos['nutricao'] = {
          qtd: manejados,
          totais
        }
      }

      if (m.procedimento == 'sanitarioEstoque') {
        const totais: { chave: string; valor: unknown, qtd?: number }[] = []
        const custoPorHash = {}
        const nomePorHash = {}
        estoqueSanitario.map((a) => {
          custoPorHash[a.hash] = a.extra_custo_na_data
          nomePorHash[a.hash] = a.titulo
        })

        const qtdECustoPorItem: { [key: string]: { qtd: number, custo: number, nome: string } } = {}

        let producaoTotal = 0
        let manejados = 0
        let custoTotal = 0

        Object.values(dadosManejosAnimais).map((d) => {
          const dados = d?.sanitarioEstoque || {}
          let preenchido = false
          Object.keys(dados).map((hash) => {
            const a = dados[hash]
            if (a.quantidade) {
              preenchido = true
              producaoTotal += Number(a.quantidade)
              if (custoPorHash[hash]) {
                custoTotal += custoPorHash[hash] * a.quantidade

                // atribui ao item individual
                qtdECustoPorItem[hash] = qtdECustoPorItem[hash] || { qtd: 0, custo: 0, nome: nomePorHash[hash] }
                qtdECustoPorItem[hash].custo += custoPorHash[hash] * a.quantidade
                qtdECustoPorItem[hash].qtd += 1
              }
            }
          })
          if (preenchido) {
            manejados++
          }
        })

        Object.keys(qtdECustoPorItem).map(hash => {
          totais.push({
            chave: qtdECustoPorItem[hash].nome,
            qtd: qtdECustoPorItem[hash].qtd,
            valor: `R$ ${qtdECustoPorItem[hash].custo.toFixed(2).replace('.', ',')}`
          })
        })

        totais.push({ chave: 'Qtd Total', valor: `${producaoTotal.toFixed(1).replace('.', ',')}` })
        totais.push({ chave: 'Custo Total', valor: `R$ ${custoTotal.toFixed(2).replace('.', ',')}` })

        resumos['sanitarioEstoque'] = {
          qtd: manejados,
          totais
        }
      }

      if (m.procedimento == 'movimentacao-entre-areas') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let areasDiferentes = 0
        let areasHashs = []

        Object.values(dadosManejosAnimais).map((d) => {
          const resultado = d?.manejosLotes?.['movimentacao-entre-areas'].hash_area_destino
          if (resultado) areasHashs.push(resultado)
          if (resultado) {
            animaisManejados++
          }
        })

        areasHashs = [...new Set(areasHashs)]
        areasDiferentes = areasHashs.length

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })
        totais.push({ chave: 'Lotes Diferentes', valor: areasDiferentes })

        resumos['movimentacao-entre-areas'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'observacao') {
        const totais: { chave: string; valor: unknown }[] = []

        let observacao = 0

        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['geral']?.['observacao'].observacao !== '') {
            observacao++
          }
        })

        resumos['observacao'] = {
          qtd: observacao,
          totais
        }
      }

      if (m.procedimento == 'escore-fezes') {

        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let lotesComEscore = 0
        Object.values(dadosManejosAnimais).map((d) => {
          if (d?.['manejosLotes']?.['escore-fezes'].escore_fezes) {
            lotesComEscore++
            escoreSoma += Number(d?.['manejosLotes']?.['escore-fezes'].escore_fezes || '0')
          }


        })

        if (lotesComEscore) {
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / lotesComEscore).toFixed(1).replace('.', ',') })
          resumos['escore-fezes'] = {
            qtd: lotesComEscore,
            totais
          }
        }
      }
    })

    let dadosAlterados = false

    for (const hashAnimal in dadosManejosAnimais) {
      for (const tipo in dadosManejosAnimais[hashAnimal]) {
        if (tipo !== 'hash') {
          for (const procedimento in dadosManejosAnimais[hashAnimal][tipo]) {
            if (procedimento == 'nutricao') {
              if ((Object.values(dadosManejosAnimais[hashAnimal].nutricao) || []).filter((a) => !!a.edited).length) {
                dadosAlterados = true
              }
            } else {
              if (dadosManejosAnimais[hashAnimal][tipo][procedimento]?.edited) {
                dadosAlterados = true
              }
            }
          }
        }
      }
    }

    this.manejosCtrl.campoManejoLotesAlterado = dadosAlterados

    return {
      resumos
    }
  }

  calcularResumosAreasHandle(opcoes: {
    estoqueNutricional: EstoqueNutricional[]
    estoqueSanitario: EstoqueFarmacia[]
    manejosDisponiveis: ListaManejosProp[]
    dadosManejosAreas: {
      [key: string]: {
        hash: string
        nutricao?: { [key: string]: { quantidade: number; duracao: number; edited: boolean } }
        areas?: {
          [key: string]: ManejoArea
        }
        sanitarioEstoque?: { [key: string]: { quantidade: number; edited: boolean } }
        pesagem?: {
          pesagem?: ManejoPesagem
        }
        ordenha?: {
          ordenha?: ManejoOrdenha
        }
        reprodutivo?: {
          [key: string]: ManejoReprodutivoUnitario
        }
        sanitario?: {
          [key: string]: ManejoSanitarioUnitario
        }
        geral?: {
          [key: string]: IManejoGeralUnitario
        }
      }
    }
  }): {
    resumos: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } }
  } {
    const { manejosDisponiveis, dadosManejosAreas, estoqueNutricional, estoqueSanitario } = opcoes

    const resumos: { [key: string]: { qtd: number; totais: { chave: string; valor: unknown }[] } } = {}

    this.manejosCtrl.campoManejoAreasAlterado = false
    manejosDisponiveis.map((m) => {
      if (m.procedimento == 'pesagem') {
        const totais: { chave: string; valor: unknown }[] = []

        let pesoTotal = 0
        let animaisPesados = 0
        let gmdSoma = 0
        let animaisComGMD = 0
        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['pesagem']?.pesagem.peso) {
            animaisPesados++
            pesoTotal += Number(d?.['pesagem']?.pesagem.peso || '0')
            if (d?.['pesagem']?.pesagem.gmd) {
              animaisComGMD++
              gmdSoma += Number(d?.['pesagem']?.pesagem.gmd || '0')
            }
          }
        })

        totais.push({ chave: 'Total', valor: `${pesoTotal.toFixed(1).replace('.', ',').replace(',0', '')} kg` })
        // totais.push({ chave: 'Animais Pesados', valor: animaisPesados })

        if (animaisPesados > 1) {
          totais.push({
            chave: 'Média',
            valor: `${(pesoTotal / animaisPesados).toFixed(1).replace('.', ',').replace(',0', '')} kg`
          })
        }

        if (animaisComGMD > 1) {
          totais.push({
            chave: 'GMD Médio',
            valor: `${(gmdSoma / animaisPesados).toFixed(3).replace('.', ',').replace(',000', '')} kg`
          })
        }

        resumos['pesagem'] = {
          qtd: animaisPesados,
          totais
        }
      }

      if (m.procedimento == 'nutricao') {
        const totais: { chave: string; valor: unknown }[] = []
        const estoquesPorHash = {}
        estoqueNutricional.map((a) => {
          estoquesPorHash[a.hash] = a.extra_custo_na_data
        })

        let producaoTotal = 0
        let manejados = 0
        let custoTotal = 0

        Object.values(dadosManejosAreas).map((d) => {
          const dados = d?.nutricao || {}
          let preenchido = false
          Object.keys(dados).map((hash) => {
            const a = dados[hash]
            if (a.quantidade) {
              preenchido = true
              producaoTotal += Number(a.quantidade)
              if (estoquesPorHash[hash]) {
                custoTotal += estoquesPorHash[hash] * a.quantidade
              }
            }
          })
          if (preenchido) {
            manejados++
          }
        })

        totais.push({ chave: 'Total', valor: `${producaoTotal.toFixed(1).replace('.', ',')} kg` })
        totais.push({ chave: 'Custo Total', valor: `R$ ${custoTotal.toFixed(2).replace('.', ',')}` })

        resumos['nutricao'] = {
          qtd: manejados,
          totais
        }
      }

      if (m.procedimento == 'chuva') {
        const totais: { chave: string; valor: unknown }[] = []

        let total = 0
        let qtd = 0

        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['areas']?.['chuva']?.chuva_quantidade) {
            total += Number(d?.['areas']?.['chuva']?.chuva_quantidade)
            qtd++
          }
        })

        if (qtd) {
          totais.push({
            chave: 'Média',
            valor: `${(total / qtd).toFixed(1).replace('.', ',')}`
          })
        }

        resumos['chuva'] = {
          qtd,
          totais
        }
      }

      if (m.procedimento == 'altura-capim') {
        const totais: { chave: string; valor: unknown }[] = []

        let alturaCapimSoma = 0
        let areasComAlturaCapim = 0
        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['areas']?.['altura-capim']) {
            for (const hashPiquete in d?.['areas']?.['altura-capim']) {
              const piquete = d?.['areas']?.['altura-capim'][hashPiquete]

              if (piquete.altura_capim) {
                areasComAlturaCapim++
                alturaCapimSoma += Number(piquete.altura_capim || '0')
              }
            }
          }
        })

        if (areasComAlturaCapim) {
          totais.push({ chave: 'Média', valor: (alturaCapimSoma / areasComAlturaCapim).toFixed(1).replace('.', ',') })
          resumos['altura-capim'] = {
            qtd: areasComAlturaCapim,
            totais
          }
        }
      }

      if (m.procedimento == 'pastejo-rotacionado') {
        const totais: { chave: string; valor: unknown }[] = []

        let areasComPastejoRotacionado = 0
        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['areas']?.['pastejo-rotacionado']?.hash_piquete) {
            areasComPastejoRotacionado++
          }
        })

        if (areasComPastejoRotacionado) {
          resumos['pastejo-rotacionado'] = {
            qtd: areasComPastejoRotacionado,
            totais
          }
        }
      }

      if (m.procedimento == 'observacao') {
        const totais: { chave: string; valor: unknown }[] = []

        let observacao = 0

        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['areas']?.['observacao'].observacao !== '') {
            observacao++
          }
        })

        resumos['observacao'] = {
          qtd: observacao,
          totais
        }
      }

      if (m.procedimento == 'sanitarioEstoque') {
        const totais: { chave: string; valor: unknown, qtd?: number }[] = []
        const custoPorHash = {}
        const nomePorHash = {}
        estoqueSanitario.map((a) => {
          custoPorHash[a.hash] = a.extra_custo_na_data
          nomePorHash[a.hash] = a.titulo
        })

        const qtdECustoPorItem: { [key: string]: { qtd: number, custo: number, nome: string } } = {}

        let producaoTotal = 0
        let manejados = 0
        let custoTotal = 0

        Object.values(dadosManejosAreas).map((d) => {
          const dados = d?.sanitarioEstoque || {}
          let preenchido = false
          Object.keys(dados).map((hash) => {
            const a = dados[hash]
            if (a.quantidade) {
              preenchido = true
              producaoTotal += Number(a.quantidade)
              if (custoPorHash[hash]) {
                custoTotal += custoPorHash[hash] * a.quantidade

                // atribui ao item individual
                qtdECustoPorItem[hash] = qtdECustoPorItem[hash] || { qtd: 0, custo: 0, nome: nomePorHash[hash] }
                qtdECustoPorItem[hash].custo += custoPorHash[hash] * a.quantidade
                qtdECustoPorItem[hash].qtd += 1
              }
            }
          })
          if (preenchido) {
            manejados++
          }
        })

        Object.keys(qtdECustoPorItem).map(hash => {
          totais.push({
            chave: qtdECustoPorItem[hash].nome,
            qtd: qtdECustoPorItem[hash].qtd,
            valor: `R$ ${qtdECustoPorItem[hash].custo.toFixed(2).replace('.', ',')}`
          })
        })

        totais.push({ chave: 'Qtd Total', valor: `${producaoTotal.toFixed(1).replace('.', ',')}` })
        totais.push({ chave: 'Custo Total', valor: `R$ ${custoTotal.toFixed(2).replace('.', ',')}` })

        resumos['sanitarioEstoque'] = {
          qtd: manejados,
          totais
        }
      }

      if (m.procedimento == 'ordenha') {
        const totais: { chave: string; valor: unknown }[] = []

        let producaoTotal = 0
        let vacasOrdenhadas = 0

        Object.values(dadosManejosAreas).map((d) => {
          const { primeira_ordenha, segunda_ordenha, terceira_ordenha } = d?.['ordenha']?.ordenha || {}
          if (primeira_ordenha || segunda_ordenha || terceira_ordenha) {
            vacasOrdenhadas++
            producaoTotal += Number(primeira_ordenha || '0')
            producaoTotal += Number(segunda_ordenha || '0')
            producaoTotal += Number(terceira_ordenha || '0')
          }
        })

        totais.push({ chave: 'Total', valor: `${producaoTotal.toFixed(1).replace('.', ',')} kg` })
        // totais.push({ chave: 'Vacas Ordenhadas', valor: vacasOrdenhadas })

        if (vacasOrdenhadas > 1) {
          totais.push({
            chave: 'Produção Média',
            valor: `${(producaoTotal / vacasOrdenhadas).toFixed(1).replace('.', ',')} kg`
          })
        }

        resumos['ordenha'] = {
          qtd: vacasOrdenhadas,
          totais
        }
      }

      if (m.procedimento == 'cio') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let positivos = 0
        let negativos = 0

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['reprodutivo']?.cio.cio_resultado
          if (resultado) {
            animaisManejados++
            if (resultado == 'positivo') {
              positivos++
            }
            if (resultado == 'negativo') {
              negativos++
            }
          }
        })

        if (animaisManejados) {
          // totais.push({ chave: 'Detecções', valor: animaisManejados })
          totais.push({ chave: 'Positivo', valor: positivos })
          totais.push({ chave: 'Negativo', valor: negativos })
        }

        resumos['cio'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'dg') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let positivos = 0
        let negativos = 0

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['reprodutivo']?.dg.dg_resultado
          if (resultado) {
            animaisManejados++
            if (resultado == 'positivo') {
              positivos++
            }
            if (resultado == 'negativo') {
              negativos++
            }
          }
        })

        if (animaisManejados) {
          // totais.push({ chave: 'Detecções', valor: animaisManejados })
          totais.push({ chave: 'Positivo', valor: positivos })
          totais.push({ chave: 'Negativo', valor: negativos })
        }

        resumos['dg'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'implante') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['reprodutivo']?.implante.implante_aplica
          if (resultado == 'realizado') {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Total', valor: animaisManejados })

        resumos['implante'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'implante-remocao') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['reprodutivo']?.['implante-remocao'].implante_remove
          if (resultado == 'realizado') {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Total', valor: animaisManejados })

        resumos['implante-remocao'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'inseminacao') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        const semensDiferentes = {}

        Object.values(dadosManejosAreas).map((d) => {
          const semen =
            d?.['reprodutivo']?.['inseminacao'].inseminacao_semen ||
            d?.['reprodutivo']?.['inseminacao'].inseminacao_embriao
          if (semen) {
            animaisManejados++
            semensDiferentes[semen] = null
          }
        })

        totais.push({ chave: 'Doses', valor: animaisManejados })
        totais.push({ chave: 'Touros', valor: Object.keys(semensDiferentes).length })

        resumos['inseminacao'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'monta-natural') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let internoQtd = 0
        let externoQtd = 0

        Object.values(dadosManejosAreas).map((d) => {
          const interno = d?.['reprodutivo']?.['monta-natural'].cobertura_hash_reprodutor
          const externo = d?.['reprodutivo']?.['monta-natural'].cobertura_hash_reprodutor_externo
          if (interno || externo) {
            animaisManejados++
            if (interno) {
              internoQtd++
            }
            if (externo) {
              externoQtd++
            }
          }
        })

        totais.push({ chave: 'Vacas Expostas', valor: animaisManejados })
        totais.push({ chave: 'Touro da fazenda', valor: internoQtd })
        totais.push({ chave: 'Touro externo', valor: externoQtd })

        resumos['monta-natural'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'parto') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisNascidos = 0
        let natimortos = 0
        let animaisManejados = 0

        Object.values(dadosManejosAreas).map((d) => {
          const cria1 = d?.['reprodutivo']?.['parto'].parto_hash_cria1
          const cria2 = d?.['reprodutivo']?.['parto'].parto_hash_cria2

          if (cria1) {
            if (cria1 == 'natimorto') {
              natimortos++
            } else {
              animaisNascidos++
            }
          }
          if (cria2) {
            if (cria2 == 'natimorto') {
              natimortos++
            } else {
              animaisNascidos++
            }
          }

          const cria = d?.['reprodutivo']?.['parto'].parto_hash_cria1 || d?.['reprodutivo']?.['parto'].parto_hash_cria2
          if (cria) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Vacas', valor: animaisManejados })
        if (animaisNascidos) totais.push({ chave: 'Bezerros', valor: animaisNascidos })
        if (natimortos) totais.push({ chave: 'Natimortos', valor: natimortos })

        resumos['parto'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'desmame') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAreas).map((d) => {
          const cria = d?.['reprodutivo']?.['desmame']?.desmame_detalhes == 'realizado'
          if (cria) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Total', valor: animaisManejados })

        resumos['desmame'] = {
          qtd: animaisManejados,
          totais
        }
      }

      // aborto
      if (m.procedimento == 'aborto') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['reprodutivo']?.aborto?.aborto_detalhes
          if (resultado && resultado == 'realizado') {
            animaisManejados++
          }
        })

        if (animaisManejados) {
          // totais.push({ chave: 'Total', valor: animaisManejados })

          resumos['aborto'] = {
            qtd: animaisManejados,
            totais
          }
        }
      }

      if (m.procedimento == 'castracao') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['reprodutivo']?.castracao?.castracao_detalhes
          if (resultado && resultado == 'realizado') {
            animaisManejados++
          }
        })

        if (animaisManejados) {
          // totais.push({ chave: 'Total', valor: animaisManejados })

          resumos['castracao'] = {
            qtd: animaisManejados,
            totais
          }
        }
      }

      if (m.procedimento == 'escore') {
        const totais: { chave: string; valor: unknown }[] = []

        let escoreSoma = 0
        let animaisComEscore = 0
        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['reprodutivo']?.escore?.escore_corporal) {
            animaisComEscore++
            escoreSoma += Number(d?.['reprodutivo']?.escore?.escore_corporal || '0')
          }
        })

        if (animaisComEscore) {
          // totais.push({ chave: 'Animais Avaliados', valor: animaisComEscore })
          totais.push({ chave: 'Escore Médio', valor: (escoreSoma / animaisComEscore).toFixed(1).replace('.', ',') })
          resumos['escore'] = {
            qtd: animaisComEscore,
            totais
          }
        }
      }

      if (m.tipo == 'sanitario') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['sanitario']?.[m.procedimento]?.aplicado) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })
        resumos[m.procedimento] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'movimentacao-lote') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let lotesDiferentes = 0
        let lotesHashs = []

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['geral']?.['movimentacao-lote'].hash_lote
          if (resultado) lotesHashs.push(resultado)
          if (resultado) {
            animaisManejados++
          }
        })

        lotesHashs = [...new Set(lotesHashs)]
        lotesDiferentes = lotesHashs.length

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })
        totais.push({ chave: 'Lotes Diferentes', valor: lotesDiferentes })

        resumos['movimentacao-lote'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'numero-controle') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['geral']?.['numero-controle'].numero_novo
          if (resultado) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })

        resumos['numero-controle'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'numero-brinco-eletronico') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['geral']?.['numero-brinco-eletronico'].brinco_eletronico
          if (resultado) {
            animaisManejados++
          }
        })

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })

        resumos['numero-brinco-eletronico'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'movimentacao-confinamento') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisManejados = 0
        let lotesDiferentes = 0
        let lotesHashs = []

        Object.values(dadosManejosAreas).map((d) => {
          const resultado = d?.['geral']?.['movimentacao-confinamento'].hash_confinamento
          if (resultado) lotesHashs.push(resultado)
          if (resultado) {
            animaisManejados++
          }
        })

        lotesHashs = [...new Set(lotesHashs)]
        lotesDiferentes = lotesHashs.length

        // totais.push({ chave: 'Animais Manejados', valor: animaisManejados })
        totais.push({ chave: 'Confinamentos', valor: lotesDiferentes })

        resumos['movimentacao-confinamento'] = {
          qtd: animaisManejados,
          totais
        }
      }

      if (m.procedimento == 'perda') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisPerdidos = 0

        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['geral']?.['perda'].perda_observacoes === 'perdido') {
            animaisPerdidos++
          }
        })

        // totais.push({ chave: 'Animais Perdidos', valor: animaisPerdidos })

        resumos['perda'] = {
          qtd: animaisPerdidos,
          totais
        }
      }

      if (m.procedimento == 'morte') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisMortos = 0

        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['geral']?.['morte'].morte_observacoes == 'morto') {
            animaisMortos++
          }
        })

        // totais.push({ chave: 'Animais Mortos', valor: animaisMortos })

        resumos['morte'] = {
          qtd: animaisMortos,
          totais
        }
      }

      if (m.procedimento == 'transferencia-propriedade') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisMovidos = 0

        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['geral']?.['transferencia-propriedade'].novo_status === 'movido') {
            animaisMovidos++
          }
        })

        // totais.push({ chave: 'Animais Movidos', valor: animaisMovidos })

        resumos['transferencia-propriedade'] = {
          qtd: animaisMovidos,
          totais
        }
      }

      if (m.procedimento == 'venda') {
        const totais: { chave: string; valor: unknown }[] = []

        let animaisVendidos = 0

        Object.values(dadosManejosAreas).map((d) => {
          if (d?.['geral']?.['venda'].novo_status === 'vendido') {
            animaisVendidos++
          }
        })

        // totais.push({ chave: 'Animais Vendidos', valor: animaisVendidos })

        resumos['venda'] = {
          qtd: animaisVendidos,
          totais
        }
      }
    })

    let dadosAlterados = false

    for (const hashArea in dadosManejosAreas) {
      for (const tipo in dadosManejosAreas[hashArea]) {
        if (tipo !== 'hash') {
          for (const procedimento in dadosManejosAreas[hashArea][tipo]) {
            if (procedimento == 'nutricao') {
              if ((Object.values(dadosManejosAreas[hashArea].nutricao) || []).filter((a) => !!a.edited).length) {
                dadosAlterados = true
              }
            } else if (procedimento == 'altura-capim') {
              if ((Object.values(dadosManejosAreas[hashArea].areas[procedimento]) || []).filter((a) => !!a.edited).length) {
                dadosAlterados = true
              }
            } else {
              if (dadosManejosAreas[hashArea][tipo][procedimento]?.edited) {
                dadosAlterados = true
              }
            }
          }
        }
      }
    }

    this.manejosCtrl.campoManejoAreasAlterado = dadosAlterados

    return {
      resumos
    }
  }
}
