import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { ModalListaAreasPage } from 'src/app/pages/areas/modal-lista-areas/modal-lista-areas.page'
import { IManejoLote } from 'src/app/services/manejos.service'
import { IArea } from 'src/app/utils/interfaces/areas.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-area-movimentacao-lotes',
  templateUrl: './manejo-area-movimentacao-lotes.component.html',
  styleUrls: ['./manejo-area-movimentacao-lotes.component.scss']
})
export class ManejoAreaMovimentacaoLotesComponent implements OnInit {
  @Input('dados') dados: Partial<IManejoLote> = {}
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  @Input('areasAssociadas') areasAssociadas: { [key: string]: IArea } = {}
  @Output() areasAssociadasChange = new EventEmitter<{ [key: string]: IArea }>()

  @Input('opcoesRapidasAreas') opcoesRapidasAreas: string[] = []
  @Output() opcoesRapidasAreasChange = new EventEmitter<string[]>()

  constructor(private popoverCtrl: PopoverController, private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  emit(): void {
    this.opcoesRapidasAreas = [...new Set(this.opcoesRapidasAreas)].slice(0, 3)
    this.opcoesRapidasAreasChange.emit(this.opcoesRapidasAreas)
    this.areasAssociadasChange.emit(this.areasAssociadas)
    this.dadosChange.emit(this.dados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.hash_area_destino = ''
      this.dados.edited = true
      this.dados.observacao = ''
      this.emit()
    }
  }

  async selecionarArea(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAreasPage,
      componentProps: { tabela: true },
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    console.log('data', data)
    console.log('data?.areas', data?.areas)
    if (data?.areas) {
      const [area] = data.areas

      console.log('area', area)

      this.opcoesRapidasAreas.unshift(area.hash)
      this.areasAssociadas[area.hash] = area

      this.selecionarAreaRapida(area.hash)
    }
  }

  selecionarAreaRapida(hash: string): void {
    this.dados.edited = true
    this.dados.hash_area_destino = hash
    this.emit()
  }
}
