<ion-content>
<header *ngIf="(manejoSelecionado && !procedimentoSelecionadoProp) || acaoSelecionada">
  <div class="container-button-back" (click)="voltar()">
    <ion-icon name="chevron-back" color="primary"></ion-icon>
    <span>Opções</span>
  </div>
</header>

<div class="container-lista" *ngIf="!manejoSelecionado && manejosDisponiveis.length && !opcoesParaTodosManejos">
  <ng-template let-filtro ngFor [ngForOf]="manejosDisponiveis">
    <button
      *ngIf="popoverCampos[filtro.identificador] || filtro.tipo == 'sanitario'"
      (click)="manejoSelecionado = filtro"
    >
      <ion-icon [name]="filtro.icon"></ion-icon>
      <div class="conteudo">
        <span class="procedimento" [class.small]="filtro.tipo == 'sanitario'">{{ filtro.nome }}</span>
        <span class="procedimento" *ngIf="filtro.tipo == 'sanitario'">{{ filtro.descricao }}</span>
      </div>
    </button>
  </ng-template>
</div>

<div class="container-lista" *ngIf="manejoSelecionado && !acaoSelecionada">
  <button
    (click)="acaoSelecionada = 'preencherDescricao'"
    *ngIf="manejoSelecionado?.tipo === 'sanitario' && procedimentoSelecionadoProp"
  >
    <ion-icon name="create"></ion-icon>
    <div class="conteudo">
      <span *ngIf="procedimentoSelecionadoProp">Alterar Descrição</span>
    </div>
  </button>
  <button (click)="limparTodos(manejoSelecionado)">
    <ion-icon name="trash"></ion-icon>
    <div class="conteudo">
      <span *ngIf="procedimentoSelecionadoProp && !hasSelecionados">Limpar Todos</span>
      <span *ngIf="!procedimentoSelecionadoProp || hasSelecionados">Limpar Selecionados</span>
    </div>
  </button>
  <button (click)="ativaPreencherTodos(manejoSelecionado)" *ngIf="manejoSelecionado.procedimento !== 'parto' && !popoverCampos[procedimentoSelecionadoProp]?.ocultarPreencherTodos">
    <ion-icon name="document-text"></ion-icon>
    <div class="conteudo">
      <span *ngIf="procedimentoSelecionadoProp && !hasSelecionados">Preencher Todos</span>
      <span *ngIf="!procedimentoSelecionadoProp || hasSelecionados">Preencher Selecionados</span>
    </div>
  </button>
  <button (click)="alterarData($event, manejoSelecionado)">
    <ion-icon name="calendar"></ion-icon>
    <div class="conteudo">
      <span *ngIf="procedimentoSelecionadoProp && !hasSelecionados">Mover Todos para Data</span>
      <span *ngIf="!procedimentoSelecionadoProp || hasSelecionados">Mover Selecionados para Data</span>
    </div>
  </button>
</div>

<div class="container-lista" *ngIf="opcoesParaTodosManejos">
  <button (click)="limparTodos()">
    <ion-icon name="trash"></ion-icon>
    <div class="conteudo">
      <span>Limpar Todos</span>
    </div>
  </button>
  <button (click)="alterarData($event)">
    <ion-icon name="calendar"></ion-icon>
    <div class="conteudo">
      <span>Mover Todos para Data</span>
    </div>
  </button>
</div>

<ng-template [ngIf]="!manejoSelecionado && !acaoSelecionada">
  <div class="divisor"></div>

<div class="container-lista" *ngIf="abaAtiva === 'Animais'">
  <button (click)="acaoAgendarEvento()">
  <ion-icon name="reader-outline"></ion-icon>
  <div class="conteudo">
    <span>Agendar Evento</span>
  </div>
</button>
</div>
</ng-template>

<div class="container-inputs-manejo" *ngIf="acaoSelecionada">
  <div class="componentes-manejo">
    <ng-template
      let-campo
      ngFor
      [ngForOf]="camposDoManejoSelecionado?.campos?.length ? camposDoManejoSelecionado.campos : []"
    >
      <vasta-input
        *ngIf="campo?.tipoCampo === 'manejo-number'"
        expand="full"
        type="manejo-number"
        mask="separator.2"
        [label]="campo.label"
        [(value)]="campo.value"
        (buttonEvent)="acaoClick(manejoSelecionado, campo)"
      ></vasta-input>

      <vasta-input
        *ngIf="campo?.tipoCampo === 'manejo-input'"
        expand="full"
        type="manejo-input"
        [label]="campo.label"
        [(value)]="campo.value"
        (buttonEvent)="acaoClick(manejoSelecionado, campo)"
      ></vasta-input>

      <vasta-input
        *ngIf="campo?.tipoCampo === 'manejo-segment'"
        expand="full"
        type="manejo-segment"
        [label]="campo.label"
        [opcoes]="camposDoManejoSelecionado.opcoes"
        [(value)]="campo.value"
      ></vasta-input>

      <ng-template [ngIf]="campo?.tipoCampo === 'manejo-button'">
        <vasta-input
          expand="full"
          type="manejo-button"
          [label]="campo.label"
          *ngIf="manejoSelecionado.procedimento == 'monta-natural'"
          [value]="
            animaisExternosAssociados[campo.value]?.nome ||
            animaisAssociados[campo.value]?.nome ||
            animaisAssociados[campo.value]?.numeracao
          "
          (buttonEvent)="acaoClick(manejoSelecionado, campo)"
        ></vasta-input>
        <vasta-input
          expand="full"
          type="manejo-button"
          [label]="campo.label"
          *ngIf="manejoSelecionado.procedimento == 'inseminacao'"
          [value]="campo.chave == 'inseminacao_inseminador' ? pessoasAssociadas[campo.value]?.nome : estoquesAssociados[campo.value]?.touro_nome || ''"
          (buttonEvent)="acaoClick(manejoSelecionado, campo)"
        ></vasta-input>

        <vasta-input
          expand="full"
          type="manejo-button"
          [label]="campo.label"
          *ngIf="['movimentacao-lote', 'movimentacao-confinamento'].includes(manejoSelecionado.procedimento)"
          [value]="lotesAssociados[campo.value]?.nome"
          (buttonEvent)="acaoClick(manejoSelecionado, campo)"
        ></vasta-input>

        <vasta-input
          expand="full"
          type="manejo-button"
          [label]="campo.label"
          *ngIf="manejoSelecionado.procedimento === 'movimentacao-entre-areas'"
          [value]="areasAssociadas[campo.value]?.nome"
          (buttonEvent)="acaoClick(manejoSelecionado, campo)"
        ></vasta-input>

        <vasta-input
          expand="full"
          type="manejo-button"
          [label]="campo.label"
          *ngIf="
            ![
              'movimentacao-lote',
              'movimentacao-confinamento',
              'inseminacao',
              'monta-natural',
              'movimentacao-entre-areas'
            ].includes(manejoSelecionado.procedimento)
          "
          [value]="campo.value"
          (buttonEvent)="acaoClick(manejoSelecionado, campo)"
        ></vasta-input>
      </ng-template>

      <vasta-input
        *ngIf="
          campo?.tipoCampo === 'manejo-select' &&
          manejoSelecionado.procedimento === 'transferencia-propriedade' &&
          camposDoManejoSelecionado?.campos[0]?.value == 'movido'
          && campo.chave === 'transf_propriedade_id'
        "
        expand="full"
        [label]="campo?.label"
        type="manejo-select"
        [(value)]="campo.value"
        (valueChange)="carregarLotesPropriedade(campo.value)"
        [opcoes]="propriedadesOpcoes"
      ></vasta-input>

      <vasta-input
        *ngIf="
          campo?.tipoCampo === 'manejo-select' &&
          manejoSelecionado.procedimento === 'transferencia-propriedade' &&
          camposDoManejoSelecionado?.campos[0]?.value == 'movido' &&
          camposDoManejoSelecionado?.campos[1]?.value !== '' &&
          campo.chave === 'transf_propriedade_hash_lote'
        "
        expand="full"
        [label]="campo?.label"
        type="manejo-select"
        [(value)]="campo.value"
        [opcoes]="lotesOpcoes[camposDoManejoSelecionado?.campos[1].value] || []"
      ></vasta-input>
    </ng-template>

    <vasta-input
      *ngIf="manejoSelecionado?.tipo === 'sanitario' && acaoSelecionada == 'preencherTodos'"
      expand="full"
      type="manejo-segment"
      [label]="campoManejoSanitario.campos[0].label"
      [opcoes]="campoManejoSanitario.opcoes"
      [(value)]="campoManejoSanitario.campos[0].value"
    ></vasta-input>

    <vasta-input
      *ngIf="manejoSelecionado?.tipo === 'sanitario' && acaoSelecionada === 'preencherDescricao'"
      expand="full"
      type="manejo-input"
      [label]="campoManejoSanitario.campos[1].label"
      [(value)]="campoManejoSanitario.campos[1].value"
    ></vasta-input>

    <ng-template [ngIf]="manejoSelecionado.identificador === 'nutricao'">
      <ng-template [ngIf]="!estoqueNutricionalHashs.length">
        <span class="erro-nenhum-item">
          Nenhum Item Adicionado
        </span>
      </ng-template>
      <ng-template ngFor let-hash [ngForOf]="estoqueNutricionalHashs">
        <div class="nutricao-single-componente">
          <span>{{ popoverCampos['nutricao'].camposManejoNutricional[hash].titulo }}</span>
          <div class="container-inputs-popover-nutricional">
            <vasta-input
              [label]="popoverCampos['nutricao'].camposManejoNutricional[hash].campos[0].label"
              [(value)]="popoverCampos['nutricao'].camposManejoNutricional[hash].campos[0].value"
              mask="separator.2"
              maskSuffix=" kg"
              expand="full"
              type="manejo-number"
            ></vasta-input>
            <vasta-input
              [label]="popoverCampos['nutricao'].camposManejoNutricional[hash].campos[1].label"
              [(value)]="popoverCampos['nutricao'].camposManejoNutricional[hash].campos[1].value"
              [opcional]="true"
              mask="separator.1"
              maskSuffix=" Dias"
              expand="full"
              type="manejo-number"></vasta-input>
          </div>
        </div>
      </ng-template>
    </ng-template>
    <ng-template [ngIf]="manejoSelecionado.identificador === 'sanitarioEstoque'">
      <ng-template [ngIf]="!estoqueSanitarioHashs.length">
        <span class="erro-nenhum-item">
          Nenhum Item Adicionado
        </span>
      </ng-template>
      <ng-template ngFor let-hash [ngForOf]="estoqueSanitarioHashs">
        <div class="nutricao-single-componente">
          <span>{{ popoverCampos['sanitarioEstoque'].camposManejoSanitarioNovo[hash].titulo }}</span>
          <div class="container-inputs-popover-nutricional">
            <vasta-input
              [label]="popoverCampos['sanitarioEstoque'].camposManejoSanitarioNovo[hash].campos[0].label"
              [(value)]="popoverCampos['sanitarioEstoque'].camposManejoSanitarioNovo[hash].campos[0].value"
              mask="separator.2"
              [maskSuffix]="popoverCampos['sanitarioEstoque'].camposManejoSanitarioNovo[hash].campos[0].unidade ?
               ' ' + popoverCampos['sanitarioEstoque'].camposManejoSanitarioNovo[hash].campos[0].unidade : ''"
              expand="full"
              type="manejo-number"></vasta-input>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>


  <cta-buttons>
    <button
      class="primary"
      [disabled]="manejoSelecionado?.identificador === 'nutricao' && !estoqueNutricionalHashs?.length"
      (click)="
        manejoSelecionado?.tipo === 'sanitario' && acaoSelecionada == 'preencherDescricao'
          ? confirmaAlteracaoDescricao(manejoSelecionado.tipo, manejoSelecionado.procedimento)
          : confirmar('preencherTodos', manejoSelecionado.tipo, manejoSelecionado.procedimento)
      "
    >
      <ion-icon name="checkmark"></ion-icon>
      <span>Confirmar</span>
    </button>
  </cta-buttons>
</div>
</ion-content>