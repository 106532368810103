import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { ModalController, PopoverController } from '@ionic/angular'
import { ModalListaLotesPage } from 'src/app/pages/bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { ILote } from 'src/app/utils/interfaces/lotes.interface'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'

@Component({
  selector: 'app-manejo-geral-movimentacao-confinamento',
  templateUrl: './manejo-geral-movimentacao-confinamento.component.html',
  styleUrls: ['./manejo-geral-movimentacao-confinamento.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManejoGeralMovimentacaoConfinamentoComponent implements OnInit {
  @Input('dados') dados = { hash_confinamento: '', observacao: '', edited: false }
  @Input('data') data: string

  @Input('lotesAssociados') lotesAssociados: { [key: string]: ILote } = {}
  @Output() lotesAssociadosChange = new EventEmitter<{ [key: string]: ILote }>()

  @Input('opcoesRapidasConfinamento') opcoesRapidasConfinamento: string[] = []
  @Output() opcoesRapidasConfinamentoChange = new EventEmitter<string[]>()

  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()

  constructor(private popoverCtrl: PopoverController, private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  emit(): void {
    this.opcoesRapidasConfinamento = [...new Set(this.opcoesRapidasConfinamento)].slice(0, 3)
    this.opcoesRapidasConfinamentoChange.emit(this.opcoesRapidasConfinamento)
    this.lotesAssociadosChange.emit(this.lotesAssociados)
    this.dadosChange.emit(this.dados)
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.hash_confinamento = ''
      this.emit()
    }
  }

  async selecionarConfinamento(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaLotesPage,
      componentProps: { tabela: true, confinamento: true },
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    console.log('data', data)
    console.log('data?.lotes', data?.lotes)
    if (data?.lotes) {
      const [lote] = data.lotes

      console.log('lote', lote)

      this.opcoesRapidasConfinamento.unshift(lote.hash)
      this.lotesAssociados[lote.hash] = lote

      this.selecionarConfinamentoRapido(lote.hash)
    }
  }

  selecionarConfinamentoRapido(hash: string): void {
    this.dados.edited = true
    this.dados.hash_confinamento = hash
    this.emit()
  }
}
