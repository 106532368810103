const qtdCaracteres = {
  criador: 50,
  codCriador: 4,
  proprietario: 50,
  codProprietario: 4,
  numeroAnimal: 12,
  idInternoAnimal: 9,
  numeroPai: 12,
  nomePai: 20,
  rgdPai: 12,
  numeroMae: 12,
  nomeMae: 20,
  rgdMae: 12,
  dataNascimentoAnimal: 8,
  sexoAnimal: 1,
  tempoDeGestacao: 3,
  intervaloEntrePartos: 4,
  situacao: 7,
  nomeAnimal: 50,
  rgnAnimal: 20,
  rgdAnimal: 20,
  livroAnimal: 10,
  racaAnimal: 3,
  situacaoReprodutivaAnimal: 7,
  numeroReceptora: 12,
  nomeReceptora: 20,
  rgdReceptora: 12
}

const codigosMedicoes = {
  'aprumos': 'APR',
  'perimetro-escrotal': 'CE',
  'musculosidade': 'MUS',
  'pesagem': 'PES',
  'reproducao': 'REP',
  'temperamento': 'TEM',
  'umbigo': 'UMB',
  'umbere': 'UBE',
  'frame': 'FRA',
  'ossatura': 'OSS',
  'casco': 'CAS',
  'profundidade': 'PRO',
  'linha-dorso': 'DOR',
  'pelagem': 'PLA',
  'garupa': 'GAR',
  'insercao-cauda':'CAU',
  'boca':'BOC',
  'espessura-couro': 'COU',
  'chanfro': 'CHA',
  'torcao-testicular': 'TTE',
  'descarte': 'DES',
  'racial': 'RAC'
}

const qtdCaracteresMedicoes = {
  numeroAnimal: 12,
  idInternoAnimal: 9,
  dataMedida: 8,
  valor: 10,
  tipoMedida: 3,
  propriedade: 50,
  gmanejo: 20
}

export const QualitasUtils = {
  qtdCaracteres,
  qtdCaracteresMedicoes,
  codigosMedicoes
}