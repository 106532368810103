<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{tipo == 'venda' ? 'Selecionar Venda' : 'Selecionar Compra'}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <vasta-tabela
    *ngIf="tipo == 'venda'"
    [labels]="labelsVendas"
    [campos]="lancamentos"
    [config]="config"
    [status]="statusCarregamentoLancamentos"
    (selecionado)="selecionarLancamento($event)"
    (retentar)="getLancamentos()"
  ></vasta-tabela>
  <vasta-tabela
    *ngIf="tipo == 'compra'"
    [labels]="labelsCompras"
    [campos]="lancamentos"
    [config]="config"
    [status]="statusCarregamentoLancamentos"
    (selecionado)="selecionarLancamento($event)"
    (retentar)="getLancamentos()"
  ></vasta-tabela>
</ion-content>

<ion-footer>
  <cta-buttons></cta-buttons>
  <cta-buttons>
    <button class="primary" (click)="cadastrar()">
      <ion-icon name="add"></ion-icon>
      <span>{{tipo == 'venda' ? 'Cadastrar Venda' : 'Cadastrar Compra' }}</span>
    </button>
  </cta-buttons>
</ion-footer>
