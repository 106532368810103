import { Injectable } from '@angular/core'
import {
  CentrosDeCustoEPessoa,
  IFinancasCompraEVenda,
  IFinancasConta,
  IFinancasTransacao,
  IPesagensParaTransacao,
  IResponseAnimaisAreasTransacao,
  IResponseDetalhesCompraEVenda,
  IRetornoContas,
  IRetornoLancamentos,
  ResponseCentrosDeCustoEPessoas,
  ResponseCompraEVenda,
  ResponseCustosManejo,
  ResponseSubcategorias,
  Subcategoria
} from '../utils/interfaces/financas.interface'
import { NovoQueryParamsModel, QueryParamsmModel } from '../utils/interfaces/query-params.interface'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'
import { ApiService } from './api/api.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'

type SuccessLancamento = { success: boolean; hashs: string[] }

@Injectable({
  providedIn: 'root'
})
export class FinancasService {
  private urlBase = 'financas/conta'

  constructor(private apiCtrl: ApiService, private permissoesCtrl: PermissoesUsuarioService) {}

  async criarTransacao(opcoes: {
    transacao: IFinancasTransacao | IFinancasCompraEVenda
    centros_de_custo?: { [key: string]: number }
    hashsAtribuirValor?: string[]
  }): Promise<[SuccessLancamento, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: `${this.urlBase}/lancamentos`,
        body: opcoes
      })) as SuccessLancamento

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async criarNovaConta(novaConta: IFinancasConta): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: `${this.urlBase}`,
        body: novaConta
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getContas(filtros?: NovoQueryParamsModel): Promise<[IRetornoContas, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `${this.urlBase}`,
        filtros
      })) as IRetornoContas

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getPesagensParaTransacoes(): Promise<[{ pesagens: IPesagensParaTransacao[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'pesagens-para-transacoes'
      })) as { pesagens: IPesagensParaTransacao[] }

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async editarConta(contaEditada: IFinancasConta): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: `${this.urlBase}`,
        body: contaEditada
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async apagarConta(contaHash: string): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: `${this.urlBase}/${encodeURIComponent(contaHash)}`
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getTransacoes(hash: string, filtros?: NovoQueryParamsModel): Promise<[IRetornoLancamentos, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `${this.urlBase}/${encodeURIComponent(hash)}/lancamentos`,
        filtros
      })) as IRetornoLancamentos

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getTransacao(eventoHash: string): Promise<[IRetornoLancamentos, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `${this.urlBase}/lancamentos/${eventoHash}`
      })) as IRetornoLancamentos

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async excluirTransacao(hashs: string[]): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: `${this.urlBase}/lancamentos/remover`,
        body: { hashs }
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getCentrosDeCusto(filtros?: NovoQueryParamsModel): Promise<[ResponseCentrosDeCustoEPessoas, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'financas/centros-de-custo',
        filtros
      })) as ResponseCentrosDeCustoEPessoas

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async statusTransacao(body: { transacoes: string[]; status: number; categorias?: string[] }): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'financas/conta/lancamentos/status',
        body
      })) as StatusRequest

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }
  async criarCentrosDeCusto(body: { centro: Partial<CentrosDeCustoEPessoa> }): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'financas/centros-de-custo',
        body
      })) as StatusRequest

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async excluirCentrosDeCusto(hash: string): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: `financas/centros-de-custo/${encodeURIComponent(hash)}`
      })) as StatusRequest

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getPessoas(filtros?: NovoQueryParamsModel): Promise<[ResponseCentrosDeCustoEPessoas, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'financas/pessoas',
        filtros
      })) as ResponseCentrosDeCustoEPessoas

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async criarPessoa(body: { centro: Partial<CentrosDeCustoEPessoa> }): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'financas/pessoas',
        body
      })) as StatusRequest

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async excluirPessoa(hash: string): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: `financas/pessoas/${encodeURIComponent(hash)}`
      })) as StatusRequest

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getCompras(filtros?: NovoQueryParamsModel): Promise<[ResponseCompraEVenda, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'financas/compras',
        filtros
      })) as ResponseCompraEVenda

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getVendas(filtros?: NovoQueryParamsModel): Promise<[ResponseCompraEVenda, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'financas/vendas',
        filtros
      })) as ResponseCompraEVenda

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async setCompraEVenda(payload: {
    transacao: IFinancasCompraEVenda
    hashsAnimais: string[]
  }): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'financas/compras-e-vendas',
        body: payload
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async updateRemoverAnimaisDoRebanho(payload: {
    hashTransacao: string
    retirarAnimaisDoRebanho: number
  }): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'financas/vendas-arquivar-animais',
        body: payload
      })) as StatusRequest

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getDetalhesCompras(hash: string): Promise<[IResponseDetalhesCompraEVenda, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `financas/compras/detalhes?hash=${encodeURIComponent(hash)}`
      })) as IResponseDetalhesCompraEVenda

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getDetalhesVendas(hash: string): Promise<[IResponseDetalhesCompraEVenda, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `financas/vendas/detalhes?hash=${encodeURIComponent(hash)}`
      })) as IResponseDetalhesCompraEVenda

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async enviarPdf(payload: { tipo: string; pdf: string, titulo: string }): Promise<[{ url: string }, Error?]> {
    try {
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'midias/pdf',
        body: payload
      })) as { url: string }

      if (response) return [response]
    } catch (error) {
      return [error]
    }
  }

  async enviarXml(payload: { arquivo: string, conteudo: string }): Promise<[{ url: string }, Error?]> {
    try {
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'midias/xml',
        body: payload
      })) as { url: string }

      if (response) return [response]
    } catch (error) {
      return [error]
    }
  }

  async updateIdentificadorPesagem(payload: { hashs: string[] }): Promise<[StatusRequest, Error?]> {
    try {
      const response = (await this.apiCtrl.putComPrefixoPropriedade({
        url: 'pesagens/troca-identificador',
        body: payload
      })) as StatusRequest

      if (response) return [response]
    } catch (error) {
      return [error]
    }
  }

  async getAnimaisAreasTransacao(hash: string, tipo: 'animais'| 'areas'): Promise<[IResponseAnimaisAreasTransacao, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `${this.urlBase}/lancamentos/${encodeURIComponent(hash)}/${tipo}`,
      })) as IResponseAnimaisAreasTransacao

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getCustosManejo(filtros?: QueryParamsmModel): Promise<[ResponseCustosManejo, Error?]> {
    try {
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'financeiro/custos-manejo',
        filtros
      })) as ResponseCustosManejo

      return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getSubcategorias(filtros?: NovoQueryParamsModel): Promise<[ResponseSubcategorias, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'financas/subcategorias',
        filtros
      })) as ResponseSubcategorias

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async criarSubcategoria(body: { subcategoria: Partial<Subcategoria> }): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'financas/subcategorias',
        body
      })) as StatusRequest

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async excluirSubcategoria(hash: string): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('financeiro', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: `financas/subcategorias/${encodeURIComponent(hash)}`
      })) as StatusRequest

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }
}
