<ion-header class="ion-no-border">
  <ion-toolbar>
    <strong>
      Fertili Premium
      <ion-icon name="diamond" color="primary"></ion-icon>
    </strong>
    <!-- 
    <div class="container-seletor-planos">
      <span>Mensal</span>
      <ion-toggle mode="ios" [value]="planoPeriodo" [checked]="planoPeriodo === 'anual'" (ionChange)="onChangeTogglePeriodo($event)"></ion-toggle>
      <span>
        Anual
      </span>
      <span class="desconto">2 meses grátis</span>
    </div>
    <p *ngIf="informacoesDoPlanoUsuario">Você tem <span>{{informacoesDoPlanoUsuario?.animaisCadastrados}} animais</span> cadastrados</p>
  -->
    <ion-buttons>
      <ion-button (click)="fecharModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="container">
    <div class="carregamento-centralizado" *ngIf="isCarregandoPlanos; else escolherAssinatura">
      <ion-spinner color="primary"></ion-spinner>
    </div>

    <ng-template #escolherAssinatura>
      <div class="container-seletor-precos" *ngIf="isIOS">
        <h2 class="dropdown">
          {{productIOS.displayName}}
        </h2> 
        <p>
          <strong>{{productIOS.price}}</strong>
          / ano
        </p>
      </div>
      <div class="container-seletor-precos" *ngIf="!isIOS">
        <h2 class="dropdown" (click)="openPopoverPlanosDisponiveis($event)">
          {{planoSelecionado?.nome}}
          <ion-icon name="caret-down-outline" color="primary"></ion-icon> 
        </h2> 
        <p>
          <strong>{{planoSelecionado?.valor | currency}}</strong>
          {{planoPeriodo === 'anual' ? '/ ano em até ' : 'por mês'}}
          <strong *ngIf="planoPeriodo === 'anual'">12x</strong>
        </p>
        <p *ngIf="planoPeriodo === 'anual'">= <span class="desconto-vermelho">{{planoSelecionado?.valor_antigo / 12 | currency}}</span> <strong class="desconto-verde"> {{planoSelecionado?.valor / 12 | currency}}</strong> por mês</p>
      </div>
    
      <div lines="none" class="container-vantagens-plano">
        <div *ngFor="let v of vantagensPlano">
          <ion-icon name="checkmark-outline" color="primary"></ion-icon>
          <span>{{v}}</span>
        </div>
    
        
      </div>
    </ng-template>
  </section>
   <!--
  <ng-template #escolherAssinatura>
    <p class="futuro">Você está prestes a fazer um investimento no futuro da fazenda</p>

    <h2 class="divisor">Selecione o tamanho do seu rebanho</h2>

    <div class="titulos-opcoes-container">
      <ng-template ngFor let-tituloPlano [ngForOf]="titulosDosPlanosDisponiveis">
        <button
          (click)="selecionarTituloDoPlano(tituloPlano)"
          class="titulo-single"
          [class.selecionado]="tituloPlano === tituloDoPlanoSelecionado"
        >
          {{ tituloPlano }}
        </button>
      </ng-template>
      <button
        (click)="selecionarTituloDoPlano(tituloMaisDeDezMil)"
        class="titulo-single"
        [class.selecionado]="tituloMaisDeDezMil === tituloDoPlanoSelecionado"
      >
        {{tituloMaisDeDezMil}}
      </button>
    </div>

    <ng-template [ngIf]="tituloDoPlanoSelecionado && tituloMaisDeDezMil !== tituloDoPlanoSelecionado">
      <h2 class="divisor">Selecione o plano</h2>
      <div class="nenhuma-quantidade-selecionada vazio" *ngIf="!tituloDoPlanoSelecionado">
        Selecione a quantidade acima
      </div>
      <p *ngIf="tituloDoPlanoSelecionado && mensagemDesconto" class="anual-notice">{{ mensagemDesconto }}</p>

      <div class="planos-container">
        <ng-template ngFor let-plano [ngForOf]="planosAssinatura">
          <button
            class="plano-single"
            *ngIf="plano.nome === tituloDoPlanoSelecionado"
            (click)="selecionarPlano(plano)"
            [class.selecionado]="planoSelecionado && planoSelecionado?.id === plano?.id"
          >
            <div class="periodo">Plano {{ plano.periodo }}</div>
            <h3 class="plano-nome">{{ plano.nome }}</h3>
            <div class="valor-antigo" *ngIf="plano.valor_antigo">
              <small>R$</small> {{ plano.valor_antigo.toFixed(2).replace('.',',') }}
            </div>
            <div class="valor">
              <small>R$</small> {{ plano.valor.toFixed(0).replace('.',',') }}
              <span *ngIf="plano.periodo == 'anual'" class="em12x">em 12x</span>
            </div>
            <b *ngIf="plano.desconto" class="desconto">{{ plano.desconto }}</b>
          </button>
        </ng-template>
      </div>
    </ng-template>

    <ng-template [ngIf]="tituloMaisDeDezMil === tituloDoPlanoSelecionado && mensagemPlanoCustom">
      <p class="custom-plan-notice">{{mensagemPlanoCustom}}</p>
    </ng-template>

    <ng-template [ngIf]="tituloMaisDeDezMil !== tituloDoPlanoSelecionado">
      <p class="ajuda-notice" *ngIf="mensagemAjuda">{{mensagemAjuda}}</p>
    </ng-template>

    <cta-buttons>
      <button (click)="entrarEmContato()" [class.primary]="tituloMaisDeDezMil === tituloDoPlanoSelecionado">
        <ion-icon name="logo-whatsapp"></ion-icon>
        <span>77 99839-1515</span>
      </button>
    </cta-buttons>
  </ng-template> -->
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <cta-buttons>
      <button class="primary full" (click)="isIOS ? assinarIOS() : assinarPlano()">
        <span>Assinar Fertili Premium</span>
      </button>
    </cta-buttons>
    <p>
      <ion-icon name="logo-whatsapp" color="primary"></ion-icon>
      Precisa de ajuda? <span (click)="entrarEmContato()">Clique aqui</span>
    </p>
  </ion-toolbar>
  <!-- <cta-buttons>
    <button class="primary" (click)="assinarPlano()" [disabled]="!isProntoParaAssinar()">
      <ion-icon name="checkmark-circle"></ion-icon>
      <span>Informações de Pagamento</span>
    </button>
  </cta-buttons>
  <cta-buttons></cta-buttons> -->
</ion-footer>
