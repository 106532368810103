import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertController, IonContent, ModalController, PopoverController } from '@ionic/angular'
import { IOptionButtons, OpcoesBotoesComponent } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao, TabelaFiltroExtra } from 'src/app/components/vasta-tabela/vasta-tabela'
import {
  manejoAreaTipos,
  manejoGeralTipos,
  manejoLoteTipos,
  manejoReprodutivoTipos,
  manejoSanitarioTipos,
  ManejosService
} from 'src/app/services/manejos.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { ColunasListaTodosManejosAnimais, ColunasManejosTodos } from 'src/app/utils/configuracoes-padrao'
import { IListaTodosManejos, ResumoManejos } from 'src/app/utils/interfaces/manejos.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalListaAnimaisPage } from '../../bovinos/modal-lista-animais/modal-lista-animais.page'
import { ModalListaLotesPage } from '../../bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { listaManejosDisponiveis } from '../manejos-disponiveis/lista-manejos-disponiveis'
import { Device } from '@capacitor/device'
import { IAnimal } from 'src/app/utils/interfaces/animais.interface'
import { AnimaisService } from 'src/app/services/animais.service'
import { CalendarioEventoCadastrarPage } from '../../calendario/calendario-evento-cadastrar/calendario-evento-cadastrar.page'
import { ModalCompraVendaComponent } from '../../financas/modal-compra-venda/modal-compra-venda.component'
import { opcoesFiltros } from '../../../components/vasta-tabela/opcoes-filtros'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { BovinoSingleComponent } from '../../bovinos/bovino-single/bovino-single.page'

export const ManejosTodosColunas = {
  'numero-controle': ['geral_numero_controle'],
  'numero-brinco-eletronico': ['geral_brinco_eletronico'],
  'transferencia-propriedade': ['geral_transferencia_propriedade'],
  'movimentacao-lote': ['geral_movimentacao_lote'],
  'movimentacao-confinamento': ['geral_envio_confinamento'],
  'morte': ['geral_morte_causa'],
  'escore': ['reprodutivo_escore'],

  'parto': ['reprodutivo_parto_cria1', 'reprodutivo_parto_cria2'],
  'inseminacao': ['reprodutivo_inseminacao_touro', 'reprodutivo_inseminacao_doadora', 'reprodutivo_inseminacao_inseminador'],
  'dg': ['reprodutivo_dg_resultado', 'reprodutivo_dg_periodo_gestacional', 'reprodutivo_dg_previsao_de_parto', 'reprodutivo_dg_ovario_direito', 'reprodutivo_dg_ovario_esquerdo', 'reprodutivo_dg_tonus_uterino'],
  'monta-natural': ['reprodutivo_monta_touro'],
  'cio': ['reprodutivo_cio_resultado'],

  'pesagem': ['pesagem_peso', 'pesagem_gmd'],

  'ordenha': ['ordenha_total','ordenha_primeira','ordenha_segunda','ordenha_terceira'],

  'nutricao': ['nutricional_estoque_titulo', 'nutricional_estoque_qtd', 'nutricional_estoque_duracao'],

  'sanitario': ['sanitario_sem_estoque_tipo', 'sanitario_sem_estoque_titulo'],
  'sanitarioEstoque' : ['sanitario_estoque_titulo', 'sanitario_estoque_qtd'],

  'perimetro-escrotal': ['genetica_ce'],
  'deca': ['genetica_deca'],
  'iabcz': ['genetica_iabcz'],
  'temperamento': ['genetica_temperamento'],
  'musculosidade': ['genetica_musculosidade'],
  'umbigo': ['genetica_umbigo'],
  'aprumos': ['genetica_aprumos'],
  'racial': ['genetica_racial'],
  'boca': ['genetica_boca'],
  'frame': ['genetica_frame'],
  'garupa': ['genetica_garupa'],
  'insercao-cauda': ['genetica_ic'],
  'ossatura': ['genetica_ossatura'],
  'pelagem': ['genetica_pelagem'],
  'profundidade': ['genetica_profundidade'],
  'reproducao': ['genetica_reproducao'],
  'torcao-testicular': ['genetica_tt'],
  'casco': ['genetica_casco'],
  'chanfro': ['genetica_chanfro'],
  'linha-dorso': ['genetica_ld'],
  'ubere': ['genetica_ubere'],

  'escore-fezes': ['lotes_escore_fezes'],
  'movimentacao-entre-areas': ['lotes_movimentacao_area'],

  'chuva': ['areas_chuva_qtd', 'areas_chuva_hora'],
  'altura-capim': ['areas_altura_capim'],
  'pastejo-rotacionado': ['areas_pastejo_rotacionado'],
}

@Component({
  selector: 'app-manejos-todos',
  templateUrl: './manejos-todos.page.html',
  styleUrls: ['./manejos-todos.page.scss']
})
export class ManejosTodosPage implements OnInit {
  @ViewChild(IonContent) content: IonContent

  tabFiltro = 'Todos'

  public novoFiltro: NovoFiltro = {}

  public filtrosExtras: TabelaFiltroExtra[] = [
    {
      chave: 'hash_animal',
      titulo: 'Animais',
      tipo: 'lista',
      icone: 'bovino',
      valor: 'Todos',
      lista: [
        { chave: 'hash_animal_1', valor: 'Numero do Animal 1' },
        { chave: 'hash_animal_2', valor: 'Numero do Animal 2' }
      ],
      acao: async (event: Event, filtro: TabelaFiltroExtra): Promise<void> => {
        console.log({ event, filtro })
        const modal = await this.modalCtrl.create({
          component: ModalListaAnimaisPage,
          cssClass: 'custom-modal-animais',
          componentProps: {
            somenteInternos: true,
            selecionarMultiplos: true
          }
        })
        await modal.present()
        const { data } = await modal.onDidDismiss()
        if (data) {
          let animaisSelecionados = []
          if (data?.hashs?.length) {
            animaisSelecionados = data?.animais
          } else {
            animaisSelecionados = [data]
          }
          this.novoFiltro['hash_animal'] = animaisSelecionados.map((a) => a.hash)

          filtro.valor = animaisSelecionados.length == 1 ? '1 Animal' : `${animaisSelecionados.length} animais`

          this.paginateDiasComManejo.offset = 0
          this.paginateDiasComManejo.total = 0
          this.paginateDiasComManejo.currentPage = 1

          this.getTodosManejos()
        }
      }
    },
    {
      chave: 'hash_lote',
      titulo: 'Lotes',
      tipo: 'lista',
      icone: 'rebanho',
      acao: async (event: Event, filtro: TabelaFiltroExtra): Promise<void> => {
        console.log({ event, filtro })
        const modal = await this.modalCtrl.create({
          component: ModalListaLotesPage,
          cssClass: 'custom-modal-animais'
        })
        await modal.present()

        const { data } = await modal.onDidDismiss()

        if (data?.lotes) {
          this.novoFiltro['hash_lote'] = data?.lotes.map((lote) => lote.hash)

          filtro.valor = data?.lotes[0].nome

          this.paginateDiasComManejo.offset = 0
          this.paginateDiasComManejo.total = 0
          this.paginateDiasComManejo.currentPage = 1

          this.getTodosManejos()
        }
      }
    },
    {
      chave: 'procedimento',
      titulo: 'Manejos',
      tipo: 'lista',
      icone: 'pesagem-controle',
      acao: async (event: Event, filtro: TabelaFiltroExtra): Promise<void> => {
        console.log(event)
        const selecionado = await this.selecionarTipoDeManejo(event, false)
        if (selecionado) {
          this.novoFiltro['procedimento'] = selecionado.chave

          filtro.valor = selecionado.titulo

          this.paginateDiasComManejo.offset = 0
          this.paginateDiasComManejo.total = 0
          this.paginateDiasComManejo.currentPage = 1

          this.getTodosManejos()
        }
      }
    }
  ]

  /*
   * Todos Manejos
   */

  public todosManejosLabels = ColunasManejosTodos
  public todosManejosConfig: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    selecao: {
      ativar: false
    },
    exibeTotais: false
  }
  public optionsButtonsSelecionadosTabela: IOptionButtons[] = [
    {
      icone: 'pencil-outline',
      titulo: 'Editar Manejos',
      acao: (dadosTabela: DadosLinhaTabela): void => {
        const dataManejo = dadosTabela.original.data as string

        this.novoManejo(dataManejo, true)
      }
    },
    {
      icone: 'reader-outline',
      titulo: 'Gerar Relatório',
      acao: (dadosTabela: DadosLinhaTabela): void => {
        const dataManejo = dadosTabela.original.data as string

        this.novoManejo(dataManejo, true, true)
      }
    },
  ]
  public carregamentoTodosManejos: StatusTabela = 'carregando'
  public todosManejos: ResumoManejos[] = []
  public paginateDiasComManejo: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }
  public totaisManejos: Record<string, string | number>

  public manejosDisponiveis = listaManejosDisponiveis.manejos
    .concat(
      listaManejosDisponiveis.manejosReprodutivos,
      listaManejosDisponiveis.manejosSanitarios,
      listaManejosDisponiveis.manejosAreas,
      listaManejosDisponiveis.manejosLotes,
      listaManejosDisponiveis.manejosGenetica
    )
    .reduce((acc, item) => {
      acc[item.identificador] = { icon: item.icon, nome: item.nome, cor: item.cor }
      return acc
    }, {})

  rxState = null

  abasDisponiveis = ['Dias com Manejos', 'Todos os Manejos']
  public abaAtiva = 'Dias com Manejos'

  public novaOrdenacao: Ordenacao = { coluna: 'data', direcao: 'desc' }
  public labels = ColunasListaTodosManejosAnimais
  public opcoesFiltros: { [key: string]: { [key: string]: string } } = {
    estoque_item_nome: {}
  }



  public hashsSelecionados: string[] = []
  public animaisSelecionados: IAnimal[] = []
  public dadosListaTodosManejos: IListaTodosManejos[] = []
  public dadosObjetoTodosManejos: {[key: string]: IListaTodosManejos} = {}

  public optionsButtonsSelecionadosListaTodosManejos: IOptionButtons[] = [
    {
      icone: 'add-circle-outline',
      chave: 'novo-manejo',
      titulo: 'Novo Manejo',
      exibirCalendario: true,
      acao: ({ hashsSelecionados, date }: DadosLinhaTabela): void => {
        const hashsAnimais = [];
        (hashsSelecionados as string[]).map((hash) => {
          const hash_animal = this.dadosObjetoTodosManejos[hash]?.hash_animal
          if (hash_animal) {
            hashsAnimais.push(hash_animal)
          }
        })
        if (hashsAnimais.length) {
          this.novoManejoMultiplosAnimais(hashsAnimais as string[], date as string)
        } else {
          this.utilsCtrl.showToast('Nenhum animal selecionado', 'bottom')
        }
      }
    },
    {
      icone: 'cash',
      chave: 'registrar-venda',
      titulo: 'Registrar Venda',
      acao: ({ hashsSelecionados }: DadosLinhaTabela): void => {
        const hashsAnimais = [];
        (hashsSelecionados as string[]).map((hash) => {
          const hash_animal = this.dadosObjetoTodosManejos[hash].hash_animal
          if (hash_animal) {
            hashsAnimais.push(hash_animal)
          }
        })

        this.registrarVenda(hashsAnimais as string[])
      }
    },
    {
      chave: 'agendarEvento',
      icone: 'calendar-outline',
      titulo: 'Agendar Evento',
      classe: 'permissao-escrita-animais',
      disabled: false,
      acao: async (): Promise<void> => {
        const hashsAnimais = []
        this.hashsSelecionados.map((hash) => {
          const hash_animal = this.dadosObjetoTodosManejos[hash].hash_animal
          if (hash_animal) {
            hashsAnimais.push(hash_animal)
          }
        })

        const [{ dados }] = await this.animaisCtrl.getAnimais({ filtros: { hash_in: hashsAnimais } })
        const modal = await this.modalCtrl.create({
          component: CalendarioEventoCadastrarPage,
          componentProps: { animaisSelecionados: dados },
          cssClass: 'custom-modal'
        })

        await modal.present()
        const { data } = await modal.onWillDismiss()
        if (data?.sucesso) {
          this.hashsSelecionados = []
          this.content.scrollToTop()
        }
      }
    },
    {
      icone: 'trash',
      chave: 'remover-manejo',
      titulo: 'Remover Manejo',
      acao: ({ hashsSelecionados }: DadosLinhaTabela): void => {

        const original = this.dadosListaTodosManejos.filter(item => (hashsSelecionados as string[]).includes(item.hash))

        this.confirmarRemoverManejo(original)
      }
    }
  ]

  public labelsManejosAnimais = opcoesFiltros.labelsListaTodosManejos
  public carregamentoListaTodosManejos: StatusTabela = 'carregando'
  tabFiltroListaTodosManejos = 'Todos'
  manejosResposta: { [key: string]: number } = {}
  public config: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    impressaoTitulo: 'Manejos',
    selecao: {
      ativar: true
    },
    buscaDados: async () => {
      const [response, error] = await this.manejosCtrl.getListaTodosManejos({
        filtros: this.novoFiltroTodos,
        ordenacao: this.novaOrdenacaoTodos,
        paginate: { offset: 0, limit: 5000 }
      })
      return {
        dados: response?.manejos
      }
    },
    ativarDownload: true,
    ativarImpressao: true,
    selecionavel: true,
    excluirColunas: ['hash_lote'],
    exibeTotais: false
  }

  public optionsButtonsTodosManejos: IOptionButtons[] = [
    {
      icone: 'trash',
      chave: 'remover-manejo',
      titulo: 'Remover Manejo',
      acao: ({ original }: DadosLinhaTabela): void => {
        this.confirmarRemoverManejo([original])
      }
    },
    {
      icone: 'arrow-forward-circle-outline',
      chave: 'ver-manejo',
      titulo: 'Ver Manejo',
      acao: ({ original }: DadosLinhaTabela): void => {
        this.selecionouManejo({ original })
      }
    },
    {
      chave: 'verAnimal',
      icone: 'eye',
      titulo: 'Ver ficha do Animal',
      disabled: false,
      acao: async ({ original }: DadosLinhaTabela): Promise<void> => {
        const { hash_animal } = original
        if (!hash_animal) {
          this.utilsCtrl.showToast('Selecione um animal', 'bottom')
          return
        }
        const [{ dados }] = await this.animaisCtrl.getAnimais({ filtros: { hash_in: [hash_animal] } })
        const animal = dados[0]
        if (!animal) {
          this.utilsCtrl.showToast('Animal não encontrado', 'bottom')
          return
        }
        this.irParaFichaDoAnimal(animal)
      }
    }
  ]

  public paginateTodosManejos: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }
  
  public novoFiltroTodos: NovoFiltro = {}
  public novaOrdenacaoTodos: Ordenacao = { coluna: 'data', direcao: 'desc' }
  public filtrosExtrasTodos: TabelaFiltroExtra[] = [
    {
      chave: 'hash_animal',
      titulo: 'Animais',
      tipo: 'lista',
      icone: 'bovino',
      valor: 'Todos',
      lista: [
        { chave: 'hash_animal_1', valor: 'Numero do Animal 1' },
        { chave: 'hash_animal_2', valor: 'Numero do Animal 2' }
      ],
      acao: async (event: Event, filtro: TabelaFiltroExtra): Promise<void> => {
        console.log({ event, filtro })
        const modal = await this.modalCtrl.create({
          component: ModalListaAnimaisPage,
          cssClass: 'custom-modal-animais',
          componentProps: {
            somenteInternos: true,
            selecionarMultiplos: true
          }
        })
        await modal.present()
        const { data } = await modal.onDidDismiss()
        if (data) {
          let animaisSelecionados = []
          if (data?.hashs?.length) {
            animaisSelecionados = data?.animais
          } else {
            animaisSelecionados = [data]
          }
          this.novoFiltroTodos['hash_animal'] = animaisSelecionados.map((a) => a.hash)

          filtro.valor = animaisSelecionados.length == 1 ? '1 Animal' : `${animaisSelecionados.length} animais`

          this.paginateTodosManejos.offset = 0
          this.paginateTodosManejos.total = 0
          this.paginateTodosManejos.currentPage = 1

          this.getListaTodosManejos()
        }
      }
    },
    {
      chave: 'hash_lote',
      titulo: 'Lotes',
      tipo: 'lista',
      icone: 'rebanho',
      acao: async (event: Event, filtro: TabelaFiltroExtra): Promise<void> => {
        console.log({ event, filtro })
        const modal = await this.modalCtrl.create({
          component: ModalListaLotesPage,
          cssClass: 'custom-modal-animais'
        })
        await modal.present()

        const { data } = await modal.onDidDismiss()

        if (data?.lotes) {
          this.novoFiltroTodos['hash_lote'] = data?.lotes.map((lote) => lote.hash)

          filtro.valor = data?.lotes[0].nome

          this.paginateTodosManejos.offset = 0
          this.paginateTodosManejos.total = 0
          this.paginateTodosManejos.currentPage = 1

          this.getListaTodosManejos()
        }
      }
    },
    {
      chave: 'procedimento',
      titulo: 'Manejos',
      tipo: 'lista',
      icone: 'pesagem-controle',
      acao: async (event: Event, filtro: TabelaFiltroExtra): Promise<void> => {
        console.log(event)
        const selecionado = await this.selecionarTipoDeManejoTodos(event)
        if (selecionado) {
          this.novoFiltroTodos['procedimento'] = selecionado.chave

          filtro.valor = selecionado.titulo

          this.paginateTodosManejos.offset = 0
          this.paginateTodosManejos.total = 0
          this.paginateTodosManejos.currentPage = 1

          this.getListaTodosManejos()
        }
      }
    }
  ]

  constructor(
    private alertCtrl: AlertController,
    private router: Router,
    private popoverCtrl: PopoverController,
    private manejosCtrl: ManejosService,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private animaisCtrl: AnimaisService,
    private utilsCtrl: IonicUtilsService,
    public planoBasicoCtrl: PlanoBasicoService
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.paginaAlterada()
    })
  }

  async selecionarTipoDeManejoImportacao(ev: Event): Promise<void> {
    const bloqueiaImportacao = await this.alertaImportacaoDesktop()
    if (bloqueiaImportacao) return
    
    const tipo = await this.selecionarTipoDeManejo(ev)
    if (tipo) this.router.navigate(['/manejos/importar/manejos'], { queryParams: { tipoManejo: tipo.identificador } })
  }

  async alertaImportacaoDesktop(): Promise<boolean> {
    const { platform } = await Device.getInfo()
    if (window.innerWidth < 768 || platform !== 'web') {
      const alert = await this.alertCtrl.create({
        header: 'Ação Indisponível no dispositivo',
        message: 'Para realizar a importação de planilhas, acesse o Fertili 360 através de um computador',
        buttons: ['Ok']
      })
      alert.present()
      return true
    }
    return false
  }

  async selecionarTipoDeManejo(ev: Event, importacao = true): Promise<{ chave: string; titulo: string; identificador: string }> {
    let options = []

    listaManejosDisponiveis.manejos
      .concat(listaManejosDisponiveis.manejosReprodutivos, listaManejosDisponiveis.manejosSanitarios)
      .map((manejo) => {
        options.push({
          chave: importacao ? `importar-manejo-${manejo.identificador}` : manejo.identificador,
          titulo: manejo.nome,
          icone: manejo.icon,
          cor: manejo.cor,
          importavel: manejo.importavel,
          identificador: manejo.identificador
        })
      }, {})

    if (importacao) {
      options = options.filter((o) => o.importavel)
    }

    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: { options },
      event: ev,
      reference: 'trigger'
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()
    if (data) {
      return data
    }
  }

  /*
   * Todos os manejos
   */

  novoManejo(dataParam: string | DadosLinhaTabela, editar = false, abrirRelatorio = false): void {
    const data = typeof dataParam === 'string' ? dataParam : dataParam.original.data
    this.router.navigateByUrl(`/manejos/${data}`, {
      state: {
        abaAtivaParams: this.tabFiltro !== 'Todos' ? this.tabFiltro : '',
        data,
        editar,
        abrirRelatorio
      }
    })
  }

  async getTodosManejos(): Promise<void> {
    this.carregamentoTodosManejos = 'carregando'
    this.novoFiltro.tipo = this.tabFiltro
    const [response, error] = await this.manejosCtrl.getTodosManejos({
      paginate: this.paginateDiasComManejo,
      filtros: this.novoFiltro
    })

    if (response) {
      this.totaisManejos = { total_resultados: response.datasDisponiveis }
      this.paginateDiasComManejo.total = response.datasDisponiveis || 0
      this.todosManejos = response.manejos
      this.carregamentoTodosManejos = 'sucesso'
    }

    if (error) {
      console.log(error)
      this.carregamentoTodosManejos = 'erro'
    }
  }
  
  async tabFiltroChange(): Promise<void> {
    this.paginateDiasComManejo.offset = 0
    this.paginateDiasComManejo.total = 0
    this.paginateDiasComManejo.currentPage = 1
    this.paginaAlterada()
  }

  async paginaAlterada(): Promise<void> {
    this.content?.scrollToTop(500)

    if (this.abaAtiva == 'Dias com Manejos') {
      this.getTodosManejos()
    } else {
      this.getListaTodosManejos()
    }
  }

  tabChange(tab: string): void {
    if (tab === 'Dias com Manejos') {
      this.getTodosManejos()
    }

    if (tab === 'Todos os Manejos') {
      this.getListaTodosManejos()
    }
  }

  getHashsSelecionados(selecionados: string[]): void {
    this.hashsSelecionados = selecionados

    if (!this.hashsSelecionados.length) {
      this.animaisSelecionados = []
    } else {
      const novosAnimais = this.animaisSelecionados.filter(
        (a) => this.hashsSelecionados.includes(a.hash) && !this.animaisSelecionados.map((a) => a.hash).includes(a.hash)
      )
      this.animaisSelecionados = [...this.animaisSelecionados, ...novosAnimais]

      this.animaisSelecionados = this.animaisSelecionados.filter((a) => this.hashsSelecionados.includes(a.hash))
    }
  }

  async getListaTodosManejos(): Promise<void> {
    this.content?.scrollToTop(500)
    this.carregamentoListaTodosManejos = 'carregando'

    const [response, error] = await this.manejosCtrl.getListaTodosManejos({
      filtros: this.novoFiltroTodos,
      ordenacao: this.novaOrdenacaoTodos,
      paginate: this.paginateTodosManejos
    })

    if (response) {
      const colunasChaves = ColunasListaTodosManejosAnimais.filter(coluna => !coluna.fixa && !coluna.fixaFinal).map((coluna) => coluna.chave)
      this.manejosResposta = response.manejosDisponiveis
      const colunasManejosExistentes = []
      Object.keys(this.manejosResposta).map((manejo) => {
        if (ManejosTodosColunas[manejo]) {
          colunasManejosExistentes.push(...ManejosTodosColunas[manejo])
        }
      })
      let colunasEsconder = colunasChaves.filter((coluna) => !colunasManejosExistentes.includes(coluna) && !['procedimento', 'data', 'geral_observacao', 'created_at', 'updated_at'].includes(coluna))
      this.config.excluirColunas = colunasEsconder

      this.dadosListaTodosManejos = response?.manejos
      this.dadosListaTodosManejos.map((manejo) => {
        this.dadosObjetoTodosManejos[manejo.hash] = manejo
      })
      this.paginateTodosManejos.total = response?.totais?.total
      this.carregamentoListaTodosManejos = 'sucesso'
    }

    if (error) {
      this.carregamentoListaTodosManejos = 'erro'
      console.error(error)
    }
  }

  async novoManejoMultiplosAnimais(hashsAnimaisSelecionados: string[], data: string): Promise<void> {
    const [{ dados }] = await this.animaisCtrl.getAnimais({ filtros: { hash_in: hashsAnimaisSelecionados } })
    this.router.navigateByUrl(`/manejos/${data}`, {
      state: {
        data,
        hashsAnimais: hashsAnimaisSelecionados,
        animais: dados
      }
    })
  }

  async registrarVenda(hashsAnimaisSelecionados: string[]): Promise<void> {
    const [animais] = await this.animaisCtrl.getAnimais({ filtros: { hash_in: hashsAnimaisSelecionados } })

    const modal = await this.modalCtrl.create({
      component: ModalCompraVendaComponent,
      componentProps: {
        animaisProp: animais.dados,
        tipoTransacao: 'venda'
      },
      cssClass: 'modal-compra-venda'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.success) {
      this.hashsSelecionados = []
      await this.getListaTodosManejos()
    }
  }

  async tabActiveChange(): Promise<void> {
    this.novoFiltroTodos['categoria'] ={
      'Animais': 'animais',
      'Lotes': 'lotes',
      'Áreas': 'areas'
    }[this.tabFiltroListaTodosManejos] || ''

    if (this.tabFiltroListaTodosManejos == 'Todos') {
      delete this.novoFiltroTodos.categoria
    }

    this.filtrosExtrasTodos[0].valor = 'Todos'
    delete this.novoFiltroTodos['hash_animal']
    this.filtrosExtrasTodos[1].valor = 'Todos'
    delete this.novoFiltroTodos['hash_lote']

    this.hashsSelecionados = []

    this.novaOrdenacao = { coluna: 'data', direcao: 'desc' }

    this.paginateTodosManejos.offset = 0
    this.paginateTodosManejos.total = 0
    this.paginateTodosManejos.currentPage = 1

    await this.getListaTodosManejos()
  }

  async selecionouManejo(dado: DadosLinhaTabela): Promise<void> {
    const data = dado.original.data

    this.router.navigateByUrl(`/manejos/${data}`, {
      state: {
        data,
        abaAtivaParams: {
          macho: 'Animais',
          femea: 'Animais',
          lotes: 'Lotes',
          areas: 'Áreas'
        }[String(dado.original.icone)] || 'Animais'
      }
    })
  }

  async removerManejo(dados: IListaTodosManejos[]): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('circular', 'Removendo manejo(s)...')

    const [response, error] = await this.manejosCtrl.deleteManejo(dados)

    if (response) {
      await this.getListaTodosManejos()
      this.hashsSelecionados = []
      this.utilsCtrl.showToast('Manejo(s) removido(s) com sucesso', 'bottom')
    }

    if (error) {
      console.error(error)
      this.utilsCtrl.showToast('Erro ao remover manejo(s)', 'bottom')
    }
    this.utilsCtrl.dismissLoading(loading)
  }

  async confirmarRemoverManejo(dado: IListaTodosManejos[]): Promise<void> {

    const alert = await this.alertCtrl.create({
      header: 'Remover Manejo',
      message: 'Deseja remover esse(s) manejo(s)?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Remover',
          handler: async (): Promise<void> => {
            await this.removerManejo(dado)
          }
        }
      ]
    })

    await alert.present()
  }
  
  async selecionarTipoDeManejoTodos(ev: Event): Promise<{ chave: string; titulo: string; identificador: string }> {
    let options = []

    const allManejos = listaManejosDisponiveis.manejos.concat(
      listaManejosDisponiveis.manejosReprodutivos,
      listaManejosDisponiveis.manejosSanitarios,
      listaManejosDisponiveis.manejosAreas,
      listaManejosDisponiveis.manejosLotes,
      listaManejosDisponiveis.manejosGenetica
    )

    Object.keys(opcoesFiltros.labelsListaTodosManejos).map((chave) => { 
      const manejo = opcoesFiltros.labelsListaTodosManejos[chave]

      const icone = allManejos.find((manejo) => manejo.identificador === chave || manejo.tipo === chave)?.icon
      const cor = allManejos.find((manejo) => manejo.identificador === chave || manejo.tipo === chave)?.cor

      if (this.manejosResposta[chave]) {
        options.push({
          chave: chave,
          titulo: manejo,
          identificador: chave,
          qtd: this.manejosResposta[chave],
          cor: cor,
          icone
        })
      }
    })

    options.sort((a, b) => b.qtd - a.qtd)

    if(!options.length) {
      this.utilsCtrl.showToast('Carregando manejos cadastrados...', 'bottom')
      return
    }


    const popover = await this.popoverCtrl.create({
      component: OpcoesBotoesComponent,
      componentProps: { options },
      event: ev,
      reference: 'trigger'
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()
    if (data) {
      return data
    }
  }

  async irParaFichaDoAnimal(animal: IAnimal): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: BovinoSingleComponent,
      componentProps: { isModal: true, animalProp: animal },
      cssClass: 'modal-fullScreen'
    })

    await modal.present()
  }
}
