import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { IonContent, ModalController, PopoverController } from '@ionic/angular'
import { PopoverSeletorCadastroAnimaisComponent } from 'src/app/components/popover-seletor-cadastro-animais/popover-seletor-cadastro-animais.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { AnimaisService } from 'src/app/services/animais.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { ColunasAnimaisExternos } from 'src/app/utils/configuracoes-padrao'
import {
  IAnimal,
  IGetAnimais,
  IGetReprodutoresExternos,
  IReprodutorExterno
} from 'src/app/utils/interfaces/animais.interface'
import { ConfigTabela } from 'src/app/utils/interfaces/tabela-colunas'
import { ImportacaoPage } from '../../importacao/importacao.page'
import { ModalBovinoComponent } from '../modal-bovino/modal-bovino.component'
import { ModalReprodutorExternoPage } from '../modal-reprodutor-externo/modal-reprodutor-externo.page'

@Component({
  selector: 'app-modal-lista-animais',
  templateUrl: './modal-lista-animais.page.html',
  styleUrls: ['./modal-lista-animais.page.scss']
})
export class ModalListaAnimaisPage implements OnInit {
  @ViewChild(IonContent) content: IonContent

  public termoBusca = ''
  timeoutAnimais = null

  public animais: IAnimal[]
  public totais: Record<string, string | number>
  public novaOrdenacao: Ordenacao = { coluna: 'numeracao', direcao: 'desc' }

  public carregamentoAnimais: StatusTabela = 'carregando'
  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }
  public filtrosFixos: string[] = []

  public config: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    buscaDados: async () => {
      if (!this.novoFiltro.numeracao) {
        delete this.novoFiltro.numeracao
      }
      const [response, _] = await this.animaisCtrl.getAnimais({
        filtros: this.novoFiltro,
        ordenacao: this.novaOrdenacao,
        paginate: { offset: 0, limit: 5000 }
      })
      
      const currentHashs = this.animaisSelecionados.map(a => a.hash)
      this.animaisSelecionados = [...this.animaisSelecionados, ...response.dados.filter(animal => !currentHashs.includes(animal.hash))]

      return {
        dados: response.dados
      }
    },
    selecao: {
      ativar: true
    },
    impressaoTitulo: 'Animais',
    orderby: 'numeracao',
    orderbyDirection: 'asc',
    excluirColunas: ['mais']
  }
  public novoFiltro: NovoFiltro = {
    situacao: 'vivo'
  }
  public novoFiltroReprodutores: NovoFiltro = {}
  public novaOrdenacaoReprodutores: Ordenacao = { coluna: 'nome', direcao: 'desc' }

  public selecionarMultiplos: boolean
  public modalFiltro: NovoFiltro

  public termo = ''

  public animaisExternos: IReprodutorExterno[]
  public abaAtiva: string = 'Animais da Fazenda'

  public hashsSelecionados = []
  public animaisSelecionados: IAnimal[] = []

  // Tabela Reprodutores Externos
  public labelsReprodutoresExternos = ColunasAnimaisExternos
  public configReprodutoresExternos: ConfigTabela = {
    preload: true,
    toolbar: true,
    excluirColunas: ['mais'],
    filtrosEspecificos: true
  }
  public reprodutoresExternos: IGetReprodutoresExternos
  public carregamentoReprodutoresExternos: StatusTabela = 'carregando'
  public modalFiltroReprodutorExterno: { chave: string; valor: string }

  public hashNotIn: string[] = []
  public hashIn: string[] = []

  public modalOverlay1: HTMLIonModalElement

  public permitirCadastro = false

  public selecionarTodos = false

  @Input() exibeAbas = false
  @Input() valorFiltro: { chave: string; valor: string }
  @Input() exibeCheckbox: boolean
  @Input() somenteInternos: boolean = false
  @Input() somenteExternos: boolean = false
  constructor(
    private modalCtrl: ModalController,
    private animaisCtrl: AnimaisService,
    private propriedadesCtrl: PropriedadesService,
    private popoverCtrl: PopoverController,
    public planoBasicoCtrl: PlanoBasicoService
  ) {}

  async ngOnInit(): Promise<void> {
    this.modalOverlay1 = await this.modalCtrl.getTop()
    const controleNomeNumero = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.controle_animais
    const exibirNBrinco = this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.mostra_brinco

    if (!exibirNBrinco) {
      this.config.excluirColunas.push('meta_nbrinco')
    }

    if (controleNomeNumero == 'numero') {
      this.config.excluirColunas.push('nome')
    }

    if (controleNomeNumero == 'nome') {
      this.config.excluirColunas.push('numeracao')
    }

    if (!this.selecionarMultiplos) {
      this.config.excluirColunas.push('checkbox')
    }

    if (this.modalFiltro) {
      this.novoFiltro = this.modalFiltro
      this.novoFiltroReprodutores = this.modalFiltro
      this.filtrosFixos.push(this.modalFiltro.chave)
    }

    if (this.somenteExternos) {
      this.abaAtiva = 'Animais Externos'
    }

    if (!this.somenteExternos) {
      await this.getAnimais()
    }

    if (!this.somenteInternos) {
      this.getReprodutoresExternos()
    }

    if (this.selecionarTodos) {
      this.hashsSelecionados = this.animais.map((item) => item.hash)

      const novosAnimais = this.animais.filter(
        (a) => this.hashsSelecionados.includes(a.hash) && !this.animaisSelecionados.map((a) => a.hash).includes(a.hash)
      )
      this.animaisSelecionados = [...this.animaisSelecionados, ...novosAnimais]
      this.animaisSelecionados = this.animaisSelecionados.filter((a) => this.hashsSelecionados.includes(a.hash))
    }
  }

  enviarDadosAnimal(linha: DadosLinhaTabela): void {
    if(this.hashsSelecionados.length) return
    const dadosAnimal: IAnimal = linha.original
    this.modalCtrl.dismiss({ ...dadosAnimal, externo: false })
  }

  enviarDadosAnimalExterno(linha: DadosLinhaTabela): void {
    if(this.hashsSelecionados.length) return
    const { original: dadosAnimal } = linha
    this.modalCtrl.dismiss({ ...dadosAnimal, externo: true })
  }

  limparPaginate(): void {
    this.paginate.offset = 0
    this.paginate.total = 0
  }

  limparBusca(ev: Event): void {
    ev.stopPropagation()
    this.novoFiltro.numeracao = ''
    this.getAnimaisTimeout()
  }

  getAnimaisTimeout(): void {
    clearTimeout(this.timeoutAnimais)
    this.timeoutAnimais = setTimeout(() => {
      this.getAnimais()
    }, 500)
  }

  async getAnimais(): Promise<IGetAnimais> {
    this.content?.scrollToTop(500)

    if (this.hashIn?.length) {
      this.novoFiltro['hash_in'] = this.hashIn
    }

    if (this.hashNotIn?.length) {
      this.novoFiltro['hash_not_in'] = this.hashNotIn
    }

    this.carregamentoAnimais = 'carregando'

    if (!this.novoFiltro.numeracao) {
      delete this.novoFiltro.numeracao
    }

    const [response, error] = await this.animaisCtrl.getAnimais({
      filtros: {
        ...this.novoFiltro,
        numeracao: this.novoFiltro.numeracao || this.termoBusca
      },
      ordenacao: this.novaOrdenacao,
      paginate: this.selecionarTodos ? { offset: 0, limit: 5000 } : this.paginate
    })

    if (response) {
      this.carregamentoAnimais = 'sucesso'
      this.animais = response.dados
      this.totais = response.totais as {}
      this.paginate.total = Number(response.totais.total_resultados || '0')
      return response
    }

    if (error) {
      this.carregamentoAnimais = 'erro'
      console.log(error)
    }
  }

  async getReprodutoresExternos(): Promise<void> {
    this.carregamentoReprodutoresExternos = 'carregando'
    console.log('novoFiltroReprodutores: ', this.novoFiltroReprodutores)

    const [response, error] = await this.animaisCtrl.getReprodutoresExternos({
      filtros: this.novoFiltroReprodutores,
      ordenacao: this.novaOrdenacaoReprodutores,
      paginate: {}
    })
    if (response) {
      this.reprodutoresExternos = response
      this.carregamentoReprodutoresExternos = 'sucesso'
    }
    if (error) {
      this.carregamentoReprodutoresExternos = 'erro'
      console.log(error)
    }
  }

  trocarAba(aba: string): void {
    this.abaAtiva = aba
  }

  filtrarAnimaisExternos(animais: IReprodutorExterno[]): IReprodutorExterno[] {
    if (this.modalFiltroReprodutorExterno?.valor) {
      const animaisFiltrados = animais.filter((item) => item.sexo === this.modalFiltroReprodutorExterno?.valor)

      return animaisFiltrados
    }

    return animais
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  pesquisar(termo: string): void {
    if (termo.length === 0) {
      this.termo = null
    } else {
      this.termo = termo
    }
  }

  enviarHashsAnimaisSelecionados(): void {
    let lotesFormatados = []
    this.modalCtrl.dismiss({ hashs: this.hashsSelecionados, animais: this.animaisSelecionados, lotes: lotesFormatados })
  }

  async openModalCadastrarBovino(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalBovinoComponent,
      showBackdrop: true,
      cssClass: 'custom-modal',
      backdropDismiss: false
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()
    if (data) {
      if (data.success) {
        this.getAnimais()
        this.modalOverlay1.dismiss(data.response)
      }
    }
  }

  async openModalNovoReprodutorExterno(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalReprodutorExternoPage,
      cssClass: 'custom-modal'
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()
    if (data) {
      if (data.success) {
        this.getReprodutoresExternos()
      }
    }
  }

  async openModalCadastrarMultiplos(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ImportacaoPage,
      componentProps: { isModal: true },
      cssClass: 'modal-full-screen',
      backdropDismiss: false
    })
    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      this.getAnimais()
      this.modalOverlay1.dismiss({ hashs: data.hashs })
    }
  }

  getHashsSelecionados(selecionados: string[]): void {
    this.hashsSelecionados = selecionados

    if (!this.hashsSelecionados.length) {
      this.animaisSelecionados = []
    } else {
      const novosAnimais = this.animais.filter(
        (a) => this.hashsSelecionados.includes(a.hash) && !this.animaisSelecionados.map((a) => a.hash).includes(a.hash)
      )
      this.animaisSelecionados = [...this.animaisSelecionados, ...novosAnimais]
      this.animaisSelecionados = this.animaisSelecionados.filter((a) => this.hashsSelecionados.includes(a.hash))
    }
  }

  async seletorOpcaoCadastro(ev: Event): Promise<void> {
    const { acessoAoItemBloqueado } = await this.planoBasicoCtrl.verificarSeAssinaturaPermiteAcessoAoItem('acoes', 'cadastro-animais-atalho', ev)
    if(acessoAoItemBloqueado) return

    const popover = await this.popoverCtrl.create({
      component: PopoverSeletorCadastroAnimaisComponent,
      event: ev,
      reference: 'trigger'
    })

    await popover.present()
    const { data } = await popover.onWillDismiss()
    if (data) {
      if (data.tipo === 'cadastro') {
        this.openModalCadastrarBovino()
      }

      if (data.tipo === 'cadastro-multiplo') {
        this.openModalCadastrarMultiplos()
      }
    }
  }
}
