import { Injectable } from '@angular/core'

import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { ApiService } from './api/api.service'
import { Capacitor } from '@capacitor/core'
import { environment } from 'src/environments/environment'
import { Notificacao } from './notificacoes.service'
import { VastaRX } from '@vasta/rx'
import { PropriedadesService } from './propriedades.service'
import { Router } from '@angular/router'

export interface PushNotificationReceveid {
  data: Record<string, string | number>
  from: string
  priority: string
  notification: Notification
  fcmMessageId: string
  collapse_key: string
  isFirebaseMessaging: boolean
  messageType: string
}

export interface Notification {
  title: string
  body: string
  tag: string
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private propriedadesCtrl: PropriedadesService
  ) { }
  
  public tokenPushNotification: string

  async initialize(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      const permission = await FirebaseMessaging.requestPermissions()
  
      if (permission.receive !== 'granted') {
        return
      }

      FirebaseMessaging.getToken({
        vapidKey: environment.firebaseConfig.vapid
      }).then((result) => {
        this.tokenPushNotification = result.token
        this.sendTokenToServer('subscribe')
      })

      FirebaseMessaging.addListener('notificationReceived', (notification) => {
        console.log({ notification })
      })

      FirebaseMessaging.addListener('notificationActionPerformed', (notification) => {
        console.log('click')
        console.log({ notification })
      })
    } else {
      navigator.serviceWorker.addEventListener('message', (event) => {
        const pushNotificationReceveid = event.data as PushNotificationReceveid
        console.log(pushNotificationReceveid)

        const notificationData = pushNotificationReceveid.data as unknown as Notificacao
        if (notificationData.tipo === 'codigo_utilizado') {
          VastaRX.setState('codigo_utilizado', notificationData.codigo)
        }
        let notificacaoAprovacao = false
        if ( notificationData.tipo === 'nova_aprovacao' ) {
          notificacaoAprovacao = true

          if (Number(notificationData.propriedade) !== Number(this.propriedadesCtrl?.propriedadeSelecionada?.id)) {
            return
          }
        }

        if (pushNotificationReceveid.messageType === 'push-received') {
          const notificacao = new Notification(pushNotificationReceveid.notification.title, {
            icon: '../assets/imgs/logo-fertili-512x512.png',
            badge: '../assets/imgs/logo-fertili-128x128.png',
            silent: true,
            body: pushNotificationReceveid.notification.body,
          })

          notificacao.onclick = (): void => {
            console.log('ação click')

            notificacao.close()

            if (notificationData.tipo === 'link_interno') {
              window.focus()
              this.router.navigateByUrl(notificationData.link)
            }
          }
        }

        if (notificacaoAprovacao && notificationData.link === '/aprovacoes') {
          VastaRX.setState('firestore_aprovacoes_update', 'true')
        }

        if (pushNotificationReceveid.messageType === 'notification-clicked') {
          if (notificationData.tipo === 'link_interno') {
            window.focus()
            this.router.navigateByUrl(notificationData.link)
          }
        }
      })
    }
  }

  async sendTokenToServer(acao: 'subscribe' | 'unsubscribe'): Promise<void> {
    if (!this.tokenPushNotification) {
      return
    }
    await this.apiService.post({
      url: `usuario/notificacoes/set-token?acao=${acao}`,
      body: {
        token: this.tokenPushNotification,
        tipo: 'gestor'
      }
    })
  }
}
