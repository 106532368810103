<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      {{ subcategoria.hash ? 'Editar subcategoria' : 'Nova subcategoria' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-input type="text" label="Nome" [obrigatorio]="true" [(value)]="subcategoria.nome"></vasta-input>
  <vasta-input type="textarea" label="Descrição" [(value)]="subcategoria.descricao"></vasta-input>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <cta-buttons class="ion-float-left">
      <button
        *ngIf="subcategoria.hash"
        class="danger-clear"
        (click)="alertExcluirSubcategoria()"
        [disabled]="loading"
      >
        <ion-icon name="trash"></ion-icon>
        <span>Excluir</span>
      </button>
    </cta-buttons>
    <cta-buttons class="ion-float-right">
      <button
        class="primary permissao-escrita-financeiro"
        (click)="criarNovaSubcategoria()"
        [disabled]="!subcategoria.nome || loading"
      >
        <ion-icon name="checkmark" *ngIf="!loading"></ion-icon>
        <ion-spinner name="dots" color="light" *ngIf="loading"></ion-spinner>
        <span>Salvar</span>
      </button>
    </cta-buttons>
  </ion-toolbar>
</ion-footer>
