import { ApplicationRef, Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { EstoqueService } from 'src/app/services/estoque.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { EstoqueBiologico, IEstoqueBiologicoPayload } from 'src/app/utils/interfaces/estoque.interface'
import { ModalListaAnimaisPage } from '../../bovinos/modal-lista-animais/modal-lista-animais.page'
import { Router } from '@angular/router'
import { Capacitor } from '@capacitor/core'

@Component({
  selector: 'app-estoque-cadastrar',
  templateUrl: './estoque-cadastrar.page.html',
  styleUrls: ['./estoque-cadastrar.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EstoqueCadastrarPage implements OnInit {
  public itemProp: EstoqueBiologico
  salvando = false
  item: IEstoqueBiologicoPayload = {
    hash: '',
    titulo: '',
    descricao: '',
    tipo: 'biologico',
    metadados: {
      tipo: 'semen',
      touro: '',
      touro_externo: '',
      sexado: 'nao',
      fornecedor: '',
      fornecedor_predefinido: '',
      codigo_no_rack: '',
      caneca: '',
      botijao: '',
      tipo_de_palheta: '',
      doadora_externa: ''
    }
  }
  touro = ''
  doadora = ''

  constructor(
    private modalCtrl: ModalController,
    private estoqueCtrl: EstoqueService,
    private utilsCtrl: IonicUtilsService,
    public planoBasicoCtrl: PlanoBasicoService,
    private router: Router,
    private ref: ApplicationRef
  ) {}

  ngOnInit(): void {
    console.log('itemProp', this.itemProp)
    if (this.itemProp) {
      const {
        hash,
        tipo,
        botijao,
        caneca,
        tipoDoEstoque,
        codigo_no_rack,
        sexado,
        tipo_de_palheta,
        touro,
        touro_externo,
        touro_nome,
        doadora,
        doadora_externa,
        doadora_nome,
        fornecedor_nome,
        fornecedor_customizado,
        fornecedor_predefinido
      } = this.itemProp
      this.touro = touro_nome
      this.doadora = doadora_nome

      this.item = {
        hash,
        titulo: '',
        descricao: '',
        tipo: 'biologico',
        metadados: {
          tipo: tipoDoEstoque,
          touro,
          touro_externo,
          sexado,
          fornecedor_customizado,
          fornecedor_predefinido,
          codigo_no_rack,
          caneca,
          botijao,
          tipo_de_palheta,
          doadora,
          doadora_externa
        }
      }
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  async salvar(): Promise<void> {
    this.salvando = true

    const [response, error] = await this.estoqueCtrl.setEstoqueBiologico({ item: this.item })

    if (response) {
      const buttons = []

      if (!this.itemProp?.hash) {
        buttons.push({
          text: 'Abrir',
          handler: async (): Promise<void> => {
            const hash = response.hashs[0]
            const url = `/estoque/${encodeURIComponent(hash)}`
            if (Capacitor.getPlatform() === 'web') {
              window.open(url, '_blank')
            } else {
              const [resposta] = await this.estoqueCtrl.getEstoqueBiologico({ hash })

              if (resposta) {
                const item = resposta.estoques[0]
                this.router.navigateByUrl(url, {
                  state: {
                    item
                  }
                })
              }
            }
          }
        })
      }

      this.salvando = false
      this.modalCtrl.dismiss({ success: true })
      this.utilsCtrl.newToast({
        message: this.itemProp?.hash ? 'Item atualizado com sucesso' : 'Item salvo com sucesso',
        duration: this.itemProp?.hash ? 3000 : 5000,
        buttons
      })
    }

    if (error) {
      this.salvando = false
      this.utilsCtrl.showToast('Erro ao atualizar item. Tente Novamente!', 'bottom')
      console.log(error)
    }
  }

  tick(): void {
    this.ref.tick()
  }

  get isCampoObrigatoriosValidos(): boolean {
    const isValidos =
      (this.item.metadados.touro || this.item.metadados.touro_externo) &&
      this.item.metadados.fornecedor_predefinido

    if (!this.planoBasicoCtrl.isPlanoBasico) {
      return !!isValidos
    }
    return !!isValidos
  }

  async openModalReprodutores(filtro: { sexo: string }): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: {
        abaAtiva: 'Animais Externos',
        filtrosFixos: ['sexo'],
        modalFiltro: { ...filtro, situacao: 'vivo' },
        selecionarMultiplos: false,
        modalFiltroReprodutorExterno: filtro
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()
    if (data) {
      if (filtro.sexo === 'macho') {
        this.touro = data.nome || data.numeracao

        if (data.externo) {
          this.item.metadados.touro_externo = data.hash
          this.item.metadados.touro = null
        } else {
          this.item.metadados.touro = data.hash
          this.item.metadados.touro_externo = null
        }
      } else {
        this.doadora = data.nome || data.numeracao

        if (data.externo) {
          this.item.metadados.doadora_externa = data.hash
          this.item.metadados.doadora = null
          return
        } else {
          this.item.metadados.doadora = data.hash
          this.item.metadados.doadora_externa = null
        }
      }
    }
  }
}
