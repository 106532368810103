import { CurrencyPipe } from '@angular/common'
import { ApplicationRef, Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ModalController } from '@ionic/angular'
import * as moment from 'moment'
import { EstoqueService } from 'src/app/services/estoque.service'
import { FinancasService } from 'src/app/services/financas.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { EstoqueBiologico, IEstoqueLancamento } from 'src/app/utils/interfaces/estoque.interface'
import { FinanceiroFormularioTransacaoPage } from '../../financas/financeiro-formulario-transacao/financeiro-formulario-transacao.page'

@Component({
  selector: 'app-estoque-lancamento',
  templateUrl: './estoque-lancamento.page.html',
  styleUrls: ['./estoque-lancamento.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EstoqueLancamentoPage implements OnInit {
  public lancamentoProp: IEstoqueLancamento
  public estoqueProp: EstoqueBiologico
  public hashEstoqueProp: string
  salvando = false
  lancamento: IEstoqueLancamento = {
    hash: '',
    hash_estoque: '',
    data_lancamento: '',
    movimentacao: 'entrada',
    tipo: '',
    qtd: '',
    valor: '',
    obs: '',
    hash_financeiro: ''
  }
  public valorTotal = 0

  constructor(
    private modalCtrl: ModalController,
    private estoqueCtrl: EstoqueService,
    private financasCtrl: FinancasService,
    private ref: ApplicationRef,
    private currencyPipe: CurrencyPipe,
    public planoBasicoCtrl: PlanoBasicoService,
    private utilsCtrl: IonicUtilsService
  ) {}

  ngOnInit(): void {
    console.log('this.estoqueProp', this.estoqueProp)
    if (this.lancamentoProp) {
      const { hash, hash_estoque, data_lancamento, movimentacao, tipo, qtd, valor, obs, hash_financeiro } =
        this.lancamentoProp

      this.lancamento = {
        hash,
        hash_estoque,
        data_lancamento,
        movimentacao,
        tipo,
        qtd,
        valor,
        obs,
        hash_financeiro
      }

      if (this.lancamento.valor && this.lancamento.qtd) {
        this.getValorTotal()
      }
    } else {
      if (this.hashEstoqueProp) {
        this.lancamento.data_lancamento = moment().format('YYYY-MM-DD')
        this.lancamento.hash_estoque = this.hashEstoqueProp
      }
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  resetarTipoDeEntradaoAoAlterarMovimentacaoDeLancamento(): void {
    this.lancamento.tipo = ''
    this.valorTotal = 0
    this.tick()
  }

  alterarTipoDeEntrada(): void {
    this.lancamento.valor = ''
    this.valorTotal = 0
    this.tick()
  }

  async salvar(): Promise<void> {
    this.salvando = true
    const [response, error] = await this.estoqueCtrl.setLancamentoEstoqueBiologico({ lancamento: this.lancamento })

    if (response) {
      this.salvando = false
      this.modalCtrl.dismiss({ success: true })
      this.utilsCtrl.showToast('Item salvo com sucesso', 'bottom')
    }

    if (error) {
      this.salvando = false
      this.utilsCtrl.showToast('Erro ao atualizar item. Tente Novamente!', 'bottom')
      console.log(error)
    }
  }

  tick(): void {
    this.ref.tick()
  }

  getValorTotal(): string {
    this.valorTotal = Number(this.lancamento.valor) * Number(this.lancamento.qtd)
    return this.currencyPipe.transform(this.valorTotal, 'BRL')
  }

  get isCampoObrigatoriosValidos(): boolean {
    if (this.lancamento?.tipo === 'venda' || this.lancamento?.tipo === 'compra') {
      return this.verificarCamposObrigatoriosCompraEVendaValidos()
    } else {
      return this.verificarCamposObrigatoriosColetaValidos()
    }
  }

  verificarCamposObrigatoriosCompraEVendaValidos(): boolean {
    const isValidos =
      Number(this.lancamento?.qtd) > 0 &&
      this.lancamento.data_lancamento &&
      this.lancamento.hash_estoque &&
      this.lancamento.movimentacao &&
      this.lancamento?.tipo &&
      this.lancamento.valor

    if (!this.planoBasicoCtrl.isPlanoBasico) {
      return !!isValidos
    }

    return !!isValidos
  }

  verificarCamposObrigatoriosColetaValidos(): boolean {
    const isValidos =
      Number(this.lancamento.qtd) > 0 &&
      this.lancamento.data_lancamento &&
      this.lancamento?.tipo
    return !!isValidos
  }

  async verLancamentoFinanceiro(): Promise<void> {
    let lancamento
    if (this.lancamento.hash_financeiro) {
      const [sucesso, erro] = await this.financasCtrl.getTransacao(this.lancamento.hash_financeiro)

      if (sucesso) {
        lancamento = sucesso.lancamentos[0]
      }
    } else {
      let meta_categorias = ''
      if (this.estoqueProp?.tipoDoEstoque == 'semen') {
        meta_categorias = this.lancamento?.tipo == 'venda' ? 'receitas_pecuarias_venda_de_semen' : 'despesas_reproducao_semens'
      } else {
        meta_categorias = this.lancamento?.tipo == 'venda' ? 'receitas_pecuarias_venda_de_embriao' : 'despesas_reproducao_embrioes'
      }
      lancamento = {
        titulo: `${this.lancamento?.tipo == 'venda' ? 'Venda' : 'Compra'} de ${this.lancamento.qtd} ${this.estoqueProp?.tipoDoEstoque == 'semen' ? `dose${Number(this.lancamento.qtd) !== 1 ? 's' : ''} de sêmen` : (Number(this.lancamento.qtd) == 1 ? 'embrião' : 'embriões')}`,
        descricao: 'Lançamento criado pelo módulo de estoque biológico',
        data: this.lancamento?.data_lancamento,
        valor: this.valorTotal,
        qtd: this.lancamento?.qtd,
        meta_categorias,
        realizado: 1
      }
    }

    const modal = await this.modalCtrl.create({
      cssClass: 'tall-modal',
      component: FinanceiroFormularioTransacaoPage,
      componentProps: {
        tipoTransacao: this.lancamento?.tipo == 'venda' ? 'entrada' : 'saida',
        lancamentoProp: lancamento
      }
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data?.sucesso) {
      this.lancamento.hash_financeiro = data.hash
    }
  }

  calculaValorUnitario(): void {
    this.lancamento.valor = String(this.valorTotal / Number(this.lancamento.qtd || 1))
  }
}
