/* eslint-disable indent */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertController, ModalController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import { IOptionButtons } from 'src/app/components/opcoes-botoes/opcoes-botoes.component'
import { DadosLinhaTabela, StatusTabela } from 'src/app/components/vasta-tabela/tabela-types'
import { NovoFiltro, Ordenacao } from 'src/app/components/vasta-tabela/vasta-tabela'
import { AreasService } from 'src/app/services/areas.service'
import { GeralService } from 'src/app/services/geral.service'
import { LotesService } from 'src/app/services/lotes.service'
import { PlanoBasicoService } from 'src/app/services/plano-basico.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { ColunasAreas, ColunasPastejosRotacionados } from 'src/app/utils/configuracoes-padrao'
import { IArea, IDadosAreas, ITotaisAreas } from 'src/app/utils/interfaces/areas.interface'
import { InfoPropriedade } from 'src/app/utils/interfaces/dashboard.interface'
import { ConfigTabela, IKpisCard } from 'src/app/utils/interfaces/tabela-colunas'
import { ModalListaLotesPage } from '../bovinos/modal-lista-lotes/modal-lista-lotes.page'
import { ModalCadastrarAreaComponent } from './modal-cadastrar-area/modal-cadastrar-area.component'
import { ImportarKMLPage } from './importar-kml/importar-kml.page'
import { IManejoAreaPastejoRotacionado } from 'src/app/utils/interfaces/manejos.interface'
import { ManejosService } from 'src/app/services/manejos.service'

@Component({
  selector: 'app-areas',
  templateUrl: './areas.page.html',
  styleUrls: ['./areas.page.scss']
})
export class AreasPage implements OnInit {
  public observerAreas: string
  public areas: IArea[]
  public isLoading = false
  public maximize = false
  public abaAtiva = 'Geral'

  public optionsKpisCard: IKpisCard[] = [
    { key: 'total_tamanho', titulo: 'Área total', valor: 0, unidade: 'ha', formatacao: 'number' },
    { key: 'total_utilizavel', titulo: 'Área Utilizável', valor: 0, unidade: 'ha', formatacao: 'number' },
    { key: 'total_preservada', titulo: 'Área de Reserva', valor: 0, unidade: 'ha', formatacao: 'number' },
    {
      key: 'lotacao',
      titulo: 'Taxa de lotação',
      valor: 0,
      unidade: 'UA/ha',
      formatacao: 'number'
    },
    {
      key: 'total_animais',
      titulo: 'Total de animais',
      valor: 0,
      formatacao: 'number',
      classe: 'permissao-leitura-animais'
    },
    {
      key: 'lotes_qtd',
      titulo: 'Total de Lotes',
      valor: 0,
      formatacao: 'number',
      classe: 'permissao-leitura-animais'
    },
    { key: 'total_peso', titulo: 'Peso total', valor: 0, unidade: 'Kg', formatacao: 'number' },
    { key: 'total_ua', titulo: 'Total UA', valor: 0, formatacao: 'number' },
    {
      key: 'ocupacao',
      titulo: '% de ocupação',
      valor: 0,
      unidade: '%',
      formatacao: 'number'
    },
    {
      key: 'cabecaHectare',
      titulo: 'Cabeça / ha',
      valor: 0,
      formatacao: 'number'
    },
    { key: 'total_lotacao', titulo: 'Lotação Máxima', valor: 0, formatacao: 'number' }
  ]
  public kpisValores: { [key: string]: number } = {
    total_tamanho: 0,
    total_utilizavel: 0,
    total_preservada: 0,
    lotacao: 0,
    total_animais: 0,
    total_peso: 0,
    total_ua: 0,
    ocupacao: 0,
    cabecaHectare: 0,
    total_lotacao: 0
  }

  public config: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    orderby: 'nome',
    orderbyDirection: 'desc',
    impressaoTitulo: 'Áreas',
    ativarImpressao: true,
    ativarDownload: true,
    buscaDados: async () => {
      const [response, error] = await this.areasCtrl.getAreas({
        filtros: this.novoFiltro,
        ordenacao: this.novaOrdenacao,
        paginate: { offset: 0, limit: 5000 }
      })

      return {
        dados: response.dados
      }
    },
    exibeTotais: false
  }

  public labels = ColunasAreas
  public carregamentoAreas: StatusTabela = 'carregando'
  public paginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 150,
    offset: 0,
    total: 0,
    currentPage: 1
  }
  public inited = false
  public observerPropriedade

  public optionsButtonsTabela: IOptionButtons[] = [
    {
      chave: 'editarArea',
      icone: 'pencil',
      titulo: 'Editar área',
      classe: 'permissao-escrita-areas',
      acao: (linha: DadosLinhaTabela): void => {
        this.openModalCadastrarArea(linha.original as unknown as IArea)
      }
    },
    {
      chave: 'associarLote',
      icone: 'add',
      titulo: 'Associar lote',
      classe: 'permissao-escrita-areas',
      acao: (linha: DadosLinhaTabela): void => {
        this.associarLoteAArea(linha.original as unknown as IArea)
      }
    },
    {
      chave: 'removerArea',
      icone: 'trash',
      titulo: 'Excluir área',
      classe: 'permissao-escrita-areas',
      acao: (linha: DadosLinhaTabela): void => {
        this.alertConfirmDeleteArea(linha.original as unknown as IArea)
      }
    }
  ]

  public novoFiltro: NovoFiltro = {}
  public novaOrdenacao: Ordenacao = { coluna: 'nome', direcao: 'desc' }

  public manejosPastejoRotacionadoLabels = ColunasPastejosRotacionados
  public manejosPastejoRotacionadoFiltro: NovoFiltro = {}
  public manejosPastejoRotacionadoNovaOrdenacao: Ordenacao = { coluna: 'data', direcao: 'desc' }
  public manejosPastejoRotacionadoCarregamento: StatusTabela = 'carregando'
  public manejosPastejoRotacionado: IManejoAreaPastejoRotacionado[] = [] 
  public manejosPastejosRotacionadosConfig: ConfigTabela = {
    preload: true,
    toolbar: true,
    filtrosEspecificos: true,
    buscaDados: async () => {
      const [response, error] = await this.manejosService.getManejosPastejoRotacionado({
        filtros: this.manejosPastejoRotacionadoFiltro,
        ordenacao: this.manejosPastejoRotacionadoNovaOrdenacao,
        paginate: { offset: 0, limit: 5000 }
      })
      return { dados: response.manejos }
    },
    orderby: 'data',
    orderbyDirection: 'desc',
    impressaoTitulo: 'Movimentações entre piquetes',
    ativarImpressao: true,
    ativarDownload: true,
    exibeTotais: false
  }

  public manejosPastejoRotacionadoPaginate: { limit: number; offset: number; total: number; currentPage: number } = {
    limit: 50,
    offset: 0,
    total: 0,
    currentPage: 1
  }

  public inicializado = false
  public loadingDeletarArea = false
  constructor(
    private areasCtrl: AreasService,
    private modalCtrl: ModalController,
    private utilsCtrl: IonicUtilsService,
    private alertCtrl: AlertController,
    private geralCtrl: GeralService,
    private lotesService: LotesService,
    private cd: ChangeDetectorRef,
    private planoBasicoCtrl: PlanoBasicoService,
    private propriedadesCtrl: PropriedadesService,
    private route: ActivatedRoute,
    private router: Router,
    private manejosService: ManejosService,
  ) {}

  ngOnInit(): void {
    VastaRX.getState('atualizar-plano-basico', async (isAtualizado: boolean) => {
      if(isAtualizado && this.inicializado) {
        this.getAreas()
      }
    })
    this.route.url.subscribe(() => {
      this.tabChange()
    })
  }

  ionViewDidEnter(): void {
    this.getAreas()
  }

  async getAreas(): Promise<IDadosAreas> {
    this.isLoading = true
    this.carregamentoAreas = 'carregando'
    this.utilsCtrl.tick()

    const [response, error] = await this.areasCtrl.getAreas({
      filtros: this.novoFiltro,
      ordenacao: this.novaOrdenacao,
      paginate: this.paginate
    })

    console.log('response', response)

    if (response) {
      this.inicializado = true
      this.isLoading = false
      this.carregamentoAreas = 'sucesso'
      this.areas = response.dados
      this.paginate.total = response.totais.total_areas
      this.calcularTotaisCards(response.totais as ITotaisAreas)
      this.utilsCtrl.tick()
      return response
    }

    if (error) {
      this.isLoading = false
      this.carregamentoAreas = 'erro'
      console.log(error)
    }
  }

  async openModalCadastrarArea(propArea?: IArea): Promise<void> {
    let modal: HTMLIonModalElement
    if (propArea) {
      modal = await this.modalCtrl.create({
        component: ModalCadastrarAreaComponent,
        cssClass: 'custom-modal',
        backdropDismiss: false,
        componentProps: {
          propArea
        }
      })
    } else {
      modal = await this.modalCtrl.create({
        component: ModalCadastrarAreaComponent,
        cssClass: 'custom-modal',
        backdropDismiss: false
      })
    }

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (data?.success) {
      this.getAreas()
    }
  }

  async calcularTotaisCards(totais: ITotaisAreas): Promise<void> {
    if (this.planoBasicoCtrl.isPlanoBasico && !this.propriedadesCtrl.isPropriedadeExemploAtivada) {
      this.planoBasicoCtrl.removerChavesEValoresDosIndicadoresSePlanoForBasico(this.optionsKpisCard, this.kpisValores)
    }

    const dadosPropriedade = await this.getDadosPropriedade()
    const { cabecaHectare, cabeca_hectare_classe, taxa_lotacao, taxa_ocupacao } = dadosPropriedade

    this.optionsKpisCard.forEach((option) => {
      this.kpisValores[option.key] = totais[option.key] || 0
    })

    this.kpisValores['ocupacao'] = taxa_ocupacao

    this.optionsKpisCard.find((item) => item.key === 'cabecaHectare').indicador = cabeca_hectare_classe
    this.kpisValores['cabecaHectare'] = cabecaHectare

    this.kpisValores['lotacao'] = taxa_lotacao

    this.config.impressaoKpiCardConfig = this.optionsKpisCard
    this.config.impressaoKpiValores = this.kpisValores
  }

  irParaDetalhesArea(dados: DadosLinhaTabela): void {
    this.router.navigateByUrl(`areas/${encodeURIComponent(dados.original.hash)}`, {
      state: { ...dados.original }
    })
  }

  async alertConfirmDeleteArea(area: IArea): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Deseja realmente remover esta área?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Remover',
          role: 'confirm',
          handler: (): void => {
            this.deletarArea(area)
          }
        }
      ]
    })

    await alert.present()
  }

  async deletarArea(area: IArea): Promise<void> {
    const loading = await this.utilsCtrl.showLoading('dots')

    const [response, error] = await this.areasCtrl.deletarArea(area.hash)

    if (response) {
      this.utilsCtrl.showToast('Área deletada', 'bottom')
      await this.getAreas()
      this.cd.detectChanges()
    }

    if (error) {
      console.error(error)
    }

    this.utilsCtrl.dismissLoading(loading)
  }

  async getDadosPropriedade(): Promise<InfoPropriedade> {
    const [response, error] = await this.geralCtrl.getPropriedade()

    if (response) {
      return response
    }

    if (error) {
      // this.exibirMsgErroPropriedade = true
      console.error(error)
    }
  }

  async associarLoteAArea(area: IArea): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaLotesPage,
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()

    const { data } = await modal.onWillDismiss()

    if (!data?.lotes) return

    const [lote] = data?.lotes

    if (lote.hash_area == area.hash) {
      return this.utilsCtrl.showToast('O lote já está na área selecionada', 'bottom')
    }

    const [sucesso, error] = await this.lotesService.moverLoteParaArea({
      lote,
      hash_area: area.hash
    })

    if (sucesso) {
      this.utilsCtrl.showToast('Lote adicionado à com sucesso', 'bottom')
      await this.getAreas()
    }

    if (error) {
      this.utilsCtrl.showToast('Erro ao adicionar lote à área', 'bottom')
    }
  }

  async abrirImportacaoKML(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ImportarKMLPage,
      cssClass: 'modal-fullScreen'
    })
    modal.present()
    await modal.onWillDismiss()
    this.getAreas()
  }

  async tabChange(): Promise<void> {
    if (this.abaAtiva == 'Pastejo Rotacionado') await this.getManejosPastejoRotacionado()
  }

  async getManejosPastejoRotacionado(): Promise<void> {
    this.manejosPastejoRotacionadoCarregamento = 'carregando'
    this.utilsCtrl.tick()

    const [response, error] = await this.manejosService.getManejosPastejoRotacionado({
      filtros: this.manejosPastejoRotacionadoFiltro,
      ordenacao: this.manejosPastejoRotacionadoNovaOrdenacao,
      paginate: this.manejosPastejoRotacionadoPaginate
    })

    if (response) {
      this.manejosPastejoRotacionadoPaginate.total = response.totais.total
      this.manejosPastejoRotacionado = response.manejos
      this.manejosPastejoRotacionadoCarregamento = 'sucesso'
    }

    if (error) {
      this.manejosPastejoRotacionadoCarregamento = 'erro'
      console.log('error: ', error)
    }
    this.utilsCtrl.tick()
  }

  abrirManejosDoDia(dados: DadosLinhaTabela): void {
    const data = dados.original.data
    this.router.navigateByUrl(`/manejos/${data}`, {
      state: {
        data,
        abaAtivaParams: 'Áreas'
      }
    })
  }
}
