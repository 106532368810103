import { Injectable } from '@angular/core'
import {
  AnimaisPorNumeracao,
  EvolucaoAnimal,
  HistoricoAnimal,
  IAnimalPayload,
  IGetAnimais,
  IAnimalDetalhado,
  IGetReprodutoresExternos,
  IPesagem,
  ParamsPesagem,
  IReprodutorExterno,
  PayloadAnimalMorteOuPerda,
  PayloadAtualizarVariosAnimais
} from '../utils/interfaces/animais.interface'
import { IArvoreGenealogica } from '../utils/interfaces/genealogia.interface'
import { NovoQueryParamsModel, QueryParamsmModel } from '../utils/interfaces/query-params.interface'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'
import { ApiService } from './api/api.service'
import { ApiError } from './importacao.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'
import { VastaRX } from '@vasta/rx'
import { PropriedadesService } from './propriedades.service'
import { ColunasAnimais } from '../utils/configuracoes-padrao'

@Injectable({
  providedIn: 'root'
})
export class AnimaisService {
  constructor(
    private apiCtrl: ApiService,
    private permissoesCtrl: PermissoesUsuarioService,
    private propriedadesCtrl: PropriedadesService
  ) { }

  async getAnimais(data?: NovoQueryParamsModel): Promise<[IGetAnimais, Error?]> {
    let url = 'animais'
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'leitura')

      const filtros = this.apiCtrl.formatarFiltros(data)

      const ColunasAnimaisCopy = JSON.parse(JSON.stringify(ColunasAnimais))
      
      let colunas = ColunasAnimaisCopy.filter(c => c.exibida !== false).map(a => a.chave)

      if (this.propriedadesCtrl?.propriedadeSelecionada?.metadados['colunas_animais']) {
        const config = JSON.parse(this.propriedadesCtrl?.propriedadeSelecionada?.metadados['colunas_animais'])
        if (config?.ocultas?.length) {
          colunas = ColunasAnimaisCopy.map(a => a.chave).filter(c => !config.ocultas.includes(c))
        }
      }

      colunas = colunas.filter(c => !['checkbox','mais'].includes(c))

      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url,
        body: {
          filtros,
          colunas
        }
      })) as IGetAnimais

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getAnimal(hash: string): Promise<[IAnimalDetalhado, Error?]> {
    let queryParams: QueryParamsmModel = { hash }
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'leitura')
      const dados = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'animal',
        queryParams
      })) as { animal: IAnimalDetalhado }
      return [dados.animal]
    } catch (error) {
      return [null, error]
    }
  }

  async getHistoricoAnimal(hash: string): Promise<[{ historico: HistoricoAnimal[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `animal/historico?hash=${encodeURIComponent(hash)}`
      })) as { historico: HistoricoAnimal[] }

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getEvolucaoAnimal(hash: string): Promise<[{ evolucao: EvolucaoAnimal[] }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `animal/evolucao?hash=${encodeURIComponent(hash)}`
      })) as { evolucao: EvolucaoAnimal[] }

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async removerEvolucaoAnimal(body: EvolucaoAnimal): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: `animal/evolucao?hash=${body.hash}`,
        body
      })) as StatusRequest

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async novoAnimal(bovino: IAnimalPayload): Promise<[StatusRequest, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'animal',
        body: bovino
      })) as StatusRequest

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async atualizarAnimal(
    bovino: IAnimalPayload,
    multiplos?: PayloadAtualizarVariosAnimais
  ): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.putComPrefixoPropriedade({
        url: 'animal',
        body: multiplos ? multiplos : bovino
      })) as StatusRequest

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async atualizarVariosAnimais(payload: PayloadAtualizarVariosAnimais): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.putComPrefixoPropriedade({
        url: 'animal',
        body: payload
      })) as StatusRequest

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async deletarAnimal(hashs: string[]): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'animal/remover',
        body: { hashs }
      })) as StatusRequest

      VastaRX.setState('atualizar-cartao-usuario', true)

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getArvoreGenealogica(hash: string): Promise<[IArvoreGenealogica, Error?]> {
    try {
      const queryParams: QueryParamsmModel = { hash }
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'animal/genealogia',
        queryParams
      })) as IArvoreGenealogica

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getReprodutoresExternos(filtros?: NovoQueryParamsModel): Promise<[IGetReprodutoresExternos, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'animal/reprodutores',
        filtros
      })) as IGetReprodutoresExternos

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async novoReprodutorExterno(params: IReprodutorExterno): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'animal/reprodutor',
        body: params
      })) as StatusRequest

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async atualizarReprodutorExterno(idAnimal: number, params: IReprodutorExterno): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.putComPrefixoPropriedade({
        url: `animal/reprodutor/${idAnimal}`,
        body: params
      })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async deletarReprodutorExterno(idAnimal: number): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: `animal/reprodutor/${idAnimal}`
      })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getPesagens(params?: QueryParamsmModel): Promise<[IPesagem[], Error?]> {
    let url = 'pesagens'
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('pesagem', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url,
        queryParams: { filtros: params.filtros || [] }
      })) as IPesagem[]
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getPesagensAnimal(hashAnimal: string): Promise<[IPesagem[], Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('pesagem', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `pesagens?hash_animal=${encodeURIComponent(hashAnimal)}`
      })) as IPesagem[]
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async novaPesagem(params: ParamsPesagem[]): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('pesagem', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'pesagens',
        body: params
      })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async atualizarPesagem(params: ParamsPesagem[]): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('pesagem', 'escrita')
      const response = (await this.apiCtrl.putComPrefixoPropriedade({
        url: 'pesagens',
        body: params
      })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async deletarPesagem(idPesagem: number): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('pesagem', 'escrita')
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({
        url: `pesagens/${idPesagem}`
      })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async registrarMorteOuPerdaAnimal(dadosAnimal: PayloadAnimalMorteOuPerda): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: `animais/${encodeURIComponent(dadosAnimal.dados.animal)}/morte`,
        body: dadosAnimal
      })) as StatusRequest
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async enviarImagem(payload: { tipo: string; imagem: string }): Promise<[{ url: string }, Error?]> {
    try {
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'midias/imagem',
        body: payload
      })) as { url: string }

      if (response) return [response]
    } catch (error) {
      return [error]
    }
  }

  async duplicarAnimal(dadosAnimal: {
    hashAnimal: string
    novoNome?: string
    novaNumeracao?: number
  }): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'animal/duplicar',
        body: { ...dadosAnimal }
      })) as StatusRequest

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async cadastrarEvolucaoAnimal(dadosAnimal: EvolucaoAnimal): Promise<[StatusRequest, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'animal/evolucao',
        body: dadosAnimal
      })) as StatusRequest

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getAnimaisPorNumeracao(numeros: string[], sexo?: string, buscarExternos?: boolean): Promise<[AnimaisPorNumeracao, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('animais', 'leitura')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'animais/numeracao',
        body: {
          numeros,
          sexo,
          buscarExternos
        }
      })) as AnimaisPorNumeracao

      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }
}
