import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { IHistoricoLote, ILote, IResponseGetLotes } from '../utils/interfaces/lotes.interface'
import { NovoQueryParamsModel } from '../utils/interfaces/query-params.interface'
import { ApiService } from './api/api.service'
import { ApiError } from './importacao.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'
import { PropriedadesService } from './propriedades.service'

@Injectable({
  providedIn: 'root'
})
export class LotesService {
  constructor(
    private apiCtrl: ApiService,
    private propriedadeService: PropriedadesService,
    private permissoesCtrl: PermissoesUsuarioService
  ) {}

  async getLoteByHash(hash: string): Promise<ILote> {
    let lote: ILote

    const [response, error] = await this.getLotes({
      filtros: { hash }
    })

    if (error) {
      console.error(error)
      throw error
    }

    if (response) {
      lote = response.dados[0]
      return lote
    }
  }

  async getHistorico(params?: { hashLote: string }): Promise<[{ dados: IHistoricoLote[] }, Error?]> {
    if (this.propriedadeService.propriedadeSelecionada) {
      let url = `lotes/${encodeURIComponent(params.hashLote)}/historico`

      try {
        this.permissoesCtrl.verificaPermissaoDeRota('lotes', 'leitura')
        const response = (await this.apiCtrl.getComPrefixoPropriedade({ url })) as { dados: IHistoricoLote[] }
        console.log({ response })

        if (response) return [response, null]
      } catch (error) {
        return [null, error]
      }
    }
  }

  async getLotes(filtros?: NovoQueryParamsModel, idPropriedade?: number, dataAnimais?: string): Promise<[IResponseGetLotes, Error?]> {
    let url = 'lotes'
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('lotes', 'leitura')
      if (idPropriedade) {
        url = `propriedade/${idPropriedade}/lotes`
        const response = (await this.apiCtrl.get({ url, filtros, dataConsulta: dataAnimais || '' })) as IResponseGetLotes
        if (response) return [response, null]
      } else {
        const response = (await this.apiCtrl.getComPrefixoPropriedade({ url, filtros, dataConsulta: dataAnimais || '' })) as IResponseGetLotes
        if (response) return [response, null]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async moverLoteParaArea(opcoes: {
    lote: ILote
    hash_area: string
  }): Promise<[{ success: boolean; id: number }, Error?]> {
    const { lote, hash_area } = opcoes
    lote.hash_area = hash_area || ''
    lote.data_entrada_area = moment().format('YYYY-MM-DD')

    return await this.cadastrarEditarLote({
      lote
    })
  }

  async cadastrarEditarLote(opcoes: {
    lote?: ILote
    lotes?: ILote[]
  }): Promise<[{ success: boolean; id: number }, ApiError?]> {
    try {
      const { lote, lotes } = opcoes
      let url = 'lotes'
      this.permissoesCtrl.verificaPermissaoDeRota('lotes', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({ url, body: { lote, lotes } })) as {
        success: boolean
        id: number
      }
      if (response) {
        this.apiCtrl.setPassoFunil('360_cadastrou_lote')
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async deletarLote(opcoes: { hashs: string[] }): Promise<[{ success: boolean }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('lotes', 'escrita')
      let url = `lotes?hashs=${encodeURIComponent(JSON.stringify(opcoes.hashs))}`
      const response = (await this.apiCtrl.deleteComPrefixoPropriedade({ url })) as { success: boolean }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async arquivarLote(opcoes: { hash: string }): Promise<[{ success: boolean }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('lotes', 'escrita')
      let url = `lotes/arquivar?hash=${encodeURIComponent(JSON.stringify(opcoes.hash))}`
      const response = (await this.apiCtrl.postComPrefixoPropriedade({ url })) as { success: boolean }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async desarquivarLote(opcoes: { hash: string }): Promise<[{ success: boolean }, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('lotes', 'escrita')
      let url = `lotes/desarquivar?hash=${encodeURIComponent(JSON.stringify(opcoes.hash))}`
      const response = (await this.apiCtrl.postComPrefixoPropriedade({ url })) as { success: boolean }
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }
}
