<ion-content>
  <h2 class="titulo">Plano Controle</h2>

  <p>O plano Controle oferece limitações em relação ao plano Pro. O plano Pro oferece acesso completo a todas as funcionalidades do sistema. Enquanto o plano Controle oferece funcionalidades básicas.</p>

	<p>
		&nbsp;
	</p>

  <p>Experimente o plano Controle e atualize para o plano Pro quando precisar de mais recursos. O plano Pro é a melhor opção para garantir o sucesso do seu negócio.</p>

  <cta-buttons class="margem">
    <button class="primary" (click)="fecharModal()">
      <span>Continuar</span>
    </button>
  </cta-buttons>
</ion-content>