import { Component, OnInit } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { ModalController } from '@ionic/angular'
import { ColunasTabela } from 'src/app/utils/interfaces/tabela-colunas'

interface ColunasCheck extends ColunasTabela {
  checked: boolean
}

@Component({
  selector: 'app-modal-colunas-impressao',
  templateUrl: './modal-colunas-impressao.component.html',
  styleUrls: ['./modal-colunas-impressao.component.scss']
})
export class ModalColunasImpressaoComponent implements OnInit {
  public colunasProp: ColunasTabela[] = []
  public colunas: ColunasCheck[] = []
  public layout: 'portrait' | 'landscape' = 'landscape'
  platform = ''
  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.colunas = this.colunasProp.filter(item => item.titulo !== 'Mais').map((item) => {
      return {
        ...item,
        checked: true
      }
    })

    this.platform = Capacitor.getPlatform()
    if (this.platform === 'android') {
      this.layout = 'portrait'
    }
  }

  async enviarColunasSelecionadas(): Promise<void> {
    const colunasSelecionadas = this.colunas.filter((item) => item.checked)

    await this.modalCtrl.dismiss({ colunasSelecionadas, layout: this.layout })
  }

  selecionarLayout(layout: 'landscape' | 'portrait'): void {
    this.layout = layout
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }
}
