import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import * as moment from 'moment'

@Component({
  selector: 'app-modal-alterar-situacao',
  templateUrl: './modal-alterar-situacao.component.html',
  styleUrls: ['./modal-alterar-situacao.component.scss']
})
export class ModalAlterarSituacaoComponent implements OnInit {
  public dadosSituacao = {
    tipoSituacaoSelecionada: 'situacao',
    situacao: 'vivo',
    data_alteracao: moment().format('YYYY-MM-DD'),
    situacao_sanitaria: 'saudavel',
    alterarValorAtual: false,
    observacao: ''
  }
  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  async closeModal(): Promise<void> {
    this.modalCtrl.dismiss()
  }

  salvarSituacao(): void {
    this.modalCtrl.dismiss({ dadosSituacao: this.dadosSituacao })
  }
}
